import React, { Component, useReducer } from "react";
import {
  Button,
  FormGroup,
  TextField,
  Checkbox,
  FormControlLabel,
  Slide,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

import "assets/css/login.css";
import logo from "assets/img/front-logo.png";

import {
  basePath,
  baseRoutes,
  dpBaseRoutes,
  providerUserBaseRoutes,
  providerBaseRoutes,
} from "base-routes";
import { FormErrors } from "./FormErrors";
import fetch from "isomorphic-fetch";

import { apiPath } from "api";

import { store } from "react-notifications-component";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  EMAIL_REGEX,
  MAX_PASSWORD_LENGTH,
  ADMIN_USER,
  DP_USER,
  PROVIDER_USER,
  PROVIDER,
} from "__helpers/constants";
import { clientTokenHeader } from "__helpers/auth-header";
import { NotificationContainer } from "react-notifications";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import ReactNotifications from "react-browser-notifications";
import { CLIENT_TOKEN_LIFETIME } from "__helpers/constants";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import {
  addUSerUInfo,
  categoryList,
  insuranceList,
  patientStatusList,
  hospitalList,
  addTimeStamp,
  reduxLoad,
} from "../../js/actions/index";
import { GOOGLE_API_KEY } from "__helpers/constants";
import { DATA_LOADING } from "__helpers/constants";
import { USER_INFO } from "__helpers/constants";

class LocationDetect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationBlock: this.props.locationBlock,
    };
  }
  componentDidMount() {}

  render() {
    if (this.state.locationBlock === false) {
      return (
        <div
          style={{
            height: "1px",
            width: "1px",
            visiblity: "none",
            pointerEvents: "none",
          }}
          className="adBanner"
        />
      );
    }
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={true}
        {...this.state.other}
        TransitionComponent={Transition}
        maxWidth={"lg"}
        keepMounted
        className={
          "change-pass-dialog profile-content addBlockPopup LocationBlockedModal"
        }
      >
        <DialogTitle id="customized-dialog-title">
          <div className="green-header warning-header" style={{ top: "0" }}>
            Location blocked
          </div>
        </DialogTitle>
        <DialogContent className="addBlockContent">
          <p className="addBlockDesc">
            This application is only available in US region
          </p>
          <Button
            color="primary"
            className={this.state.loading ? "btn1 buttonSuccess" : "btn1"}
            type="button"
            onClick={() => {
              window.location.reload(true);
            }}
          >
            Reload
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: (addUSerUInfoVal) => dispatch(addUSerUInfo(addUSerUInfoVal)),
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class LoginClass extends Component {
  constructor(props) {
    super(props);
    let userInfo = JSON.parse(localStorage.getItem("user"));
    let tokenTimeStamp = localStorage.getItem("tokenTimeStamp");
    let currentTimeStamp = new Date().getTime();
    let timeFlag = false;
    if (!userInfo || !tokenTimeStamp) {
      userService.logout();
    } else {
      timeFlag = currentTimeStamp - tokenTimeStamp < CLIENT_TOKEN_LIFETIME;
    }
    userService.logout();
    this._isMounted = false;
    let spinner = document.getElementById("loadingSpinner");
    this.state = {
      email: "",
      username: "",
      password: "",
      grantType: "",
      scope: "",
      resetEmail: "",
      formErrors: { username: "", password: "" },
      emailValid: false,
      passwordValid: false,
      resetFormErrors: { email: "" },
      resetEmailValid: false,
      formValid: false,
      apiPath: apiPath.login,
      apiPathForgot: apiPath.forgotPassword,
      loading: false,
      showNotification: {},
      open: true,
      other: undefined,
      accessToken: userInfo && userInfo.accessToken ? userInfo.accessToken : [],
      tokenTimeStamp: tokenTimeStamp ? tokenTimeStamp : currentTimeStamp,
      timeFlag: timeFlag,
      isRemember: false,
      forgotModal: false,
      resendModal: false,
      passwordType: "password",
      city: "",
      query: "",
      reduxLoadFlag: false,
      spinner: spinner,
      profileLoading: false,
      locationBlock: false,
    };
    this.clientAuthToken = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendMailApi = this.sendMailApi.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.showNotifications = this.showNotifications.bind(this);
    this.checkRememberMe = this.checkRememberMe.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.backModal = this.backModal.bind(this);
    this.openForgotModal = this.openForgotModal.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.resendMail = this.resendMail.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
  }

  handleChange = (event) => {};

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };
  handleUserInputReset = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value }, () => {
      this.validateFieldReset(name, value);
    });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(EMAIL_REGEX);
        fieldValidationErrors.email = emailValid ? "" : enMsg.inValidUser;

        emailValid = value.trim().length > 0 ? true : false;
        fieldValidationErrors.email = emailValid ? "" : enMsg.inValidUser;
        break;
      case "password":
        let error = "";
        passwordValid = true;
        if (!value.trim().length) {
          passwordValid = false;
          error = enMsg.passwordRequired;
        } else if (value.length < 1) {
          passwordValid = false;
          error = "Password too short";
        }
        fieldValidationErrors.password = error;
        break;
      default:
        emailValid = true;
        passwordValid = true;
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }
  validateFieldReset(fieldName, value) {
    let fieldValidationErrors = this.state.resetFormErrors;
    let emailValid = this.state.resetEmailValid;
    switch (fieldName) {
      case "resetEmail":
        emailValid = value.match(EMAIL_REGEX);
        fieldValidationErrors.email = emailValid ? "" : enMsg.inValidEmail;
        break;
      default:
        emailValid = true;
        break;
    }
    this.setState(
      {
        resetFormErrors: fieldValidationErrors,
        resetEmailValid: emailValid,
      },
      this.validateFormReset
    );
  }
  validateFormReset() {
    return this.state.resetEmailValid;
  }
  validateForm() {
    return this.state.emailValid && this.state.passwordValid;
  }
  showNotifications() {}
  createCookie(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
  }
  eraseCookie(name) {
    this.createCookie(name, "", -1);
  }
  componentDidMount = () => {
    this._isMounted = true;
    this.eraseCookie("_max_mrj");
    const spinner = document.getElementById("loadingSpinner");
    if (spinner && !spinner.hasAttribute("hidden")) {
      spinner.setAttribute("hidden", "true");
    }
    this.showNotifications();
    let currentTimeStamp = new Date().getTime();
    let timeFlag =
      currentTimeStamp - this.state.tokenTimeStamp < CLIENT_TOKEN_LIFETIME;
    if (this.state.accessToken && this.state.timeFlag) {
    }
    this.getLocationData();
  };
  async getLocationData() {
    let resp = false;
    const response = await fetch("https://ipapi.co/json/", {
      method: "GET",
    })
      .then((resp) => {
        if (!resp.ok) {
          this.setState({ locationBlock: true });
        }
        return resp.json();
      })
      .then((data) => {
        if (data.country_code == "US" || data.country_code == "IN" || data.country_code == "PR") {
          this.setState({ locationBlock: false });
          resp = false;
          return false;
        }
        this.setState({ locationBlock: false });
        resp = true;
        return true;
      })
      .catch((error) => {
        this.setState({ locationBlock: false });
        resp = false;
        return false;
      });
    return resp;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillMount = () => {
    const req = null;
    const protocol = process.env.NODE_ENV === "production" ? "https" : "http";
  };
  handleChange(event) {
    this.setState({ username: event.target.value });
  }

  componentDidCatch(error, info) {}
  handleCloseDialog = (e) => {
    window.location.reload();
  };

  async handleSubmit(event) {
    this.props.reduxLoad(false);
    this.setState({ loading: true });
    event.preventDefault();
    console.log(await this.getLocationData());
    if (await this.getLocationData()) {
      this.props.reduxLoad(false);
      this.setState({ loading: false });
      return false;
    }
    const data = {
      username: this.state.email,
      // username: 'admin',
      password: this.state.password,
      // password: 'admin',
      mail: "fronted@mailinator.com",
      // rememberMe: this.state.isRemember
    };

    let showNotification = {};

    try {
      const response = await fetch(this.state.apiPath, {
        method: "POST",
        hheaders: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        headers: clientTokenHeader(),
        body: JSON.stringify(data),
        data: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: enMsg.loginFailedTitle,
              message: enMsg.inValidCredentials,
              type: "danger",
            };
          } else if (response.status === 401) {
            showNotification = {
              title: enMsg.loginFailedTitle,
              message: "Login Access Denied.",
              type: "danger",
            };
          } else if (response.ok) {
          } else {
            showNotification = {
              title: enMsg.loginFailedTitle,
              message: enMsg.inValidCredentials,
              type: "danger",
            };
            let error = new Error(response.statusText);
          }
          return response.json();
          // return response.text();
        })
        .then((data) => {
          if (data.status == 401) {
            showNotification = {
              title: enMsg.loginFailedTitle,
              message: data.detail,
              type: "danger",
            };
          }
          let accessToken = data.access_token;
          let jti = data.jti;
          let refreshToken = data.refresh_token;
          let tokenType = data.token_type;
          if (accessToken !== undefined) {
            let userRole = "other";
            if (
              this.state.email == "admin" ||
              this.state.email == "maxmrj.admin@yopmail.com" ||
              this.state.email == "maxadmin" ||
              this.state.email == "maxadmin@yopmail.com"
            ) {
              userRole = "admin";
            }
            let user = {
              jti: jti,
              // accessToken: tokenType+";"+accessToken,
              accessToken: accessToken,
              refreshToken: refreshToken,
              tokenType: tokenType,
              userRole: userRole,
            };
            localStorage.setItem("user", JSON.stringify(user));
            this.getProfileData(user);
            return;
          }
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };

          return response;
        });
    } catch (error) {
      showNotification = {
        title: enMsg.loginFailedTitle,
        message: enMsg.inValidCredentials,
        type: "danger",
      };
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type,
        };
        notifiaciton = Object.assign(NotificationOptions, notifiaciton);
        this.notificationID = store.addNotification(notifiaciton);
      }
      userService.showNotification(showNotification);
    }

    this._isMounted && this.setState({ loading: false });
  }
  async getProfileData(userToken) {
    let showNotification = {};
    if (userToken) {
      this.setState({ loading: true });
      this.setState({ reduxLoadFlag: false });
      let userResp = await userService.fetchProfile();
      if (userResp && userResp.firstName) {
        let firstName =  (userResp.provider) ? userResp.provider.providerName:userResp.firstName;
        let lastName = (userResp.provider) ? '' : userResp.lastName;
        let imageRef = userResp.imageUrl;
        let createdById = userResp.id;
        let newUser = userResp.newUser;
        let usrType = userResp.authorities;
        let bbaCondition = userResp.bbaCondition;
        let termNcondition = userResp.termNcondition;
        let userInfo = {
          firstName: firstName,
          lastName: lastName,
          imageRef: imageRef,
          newUser: newUser,
          createdById: createdById,
          usrType: usrType[0],
          termNcondition: termNcondition,
          bbaCondition: bbaCondition,
          provider: userResp.provider ? userResp.provider : [],
          dischargePlanner: userResp.dischargeplanner
            ? userResp.dischargeplanner
            : [],
        };
        this.props.addUSerUInfo(userInfo);
        localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
        if (usrType[0] == ADMIN_USER) {
          this.createCookie("_max_mrj", "_login_max_mrj_a", 0);
          this.props.history.replace(baseRoutes.dashboard.path);
          window.location.replace(baseRoutes.dashboard.path);
        } else if (usrType[0] == DP_USER) {
          this.createCookie("_max_mrj", "_login_max_mrj_dp", 0);
          this.props.history.replace(dpBaseRoutes.dashboard.path, "/");
          window.location.replace(dpBaseRoutes.dashboard.path);
        } else if (usrType[0] == PROVIDER_USER) {
          this.createCookie("_max_mrj", "_login_max_mrj_pu", 0);
          this.props.history.replace(providerUserBaseRoutes.dashboard.path);
          window.location.replace(providerUserBaseRoutes.dashboard.path);
        } else if (usrType[0] == PROVIDER) {
          this.createCookie("_max_mrj", "_login_max_mrj_p", 0);
          this.props.history.replace(providerBaseRoutes.dashboard.path);
          window.location.replace(providerBaseRoutes.dashboard.path);
        } else {
          showNotification = {
            title: "Login",
            message: "Login Access Denied",
            type: "dander",
          };
        }
        this.props.reduxLoad(true);
        this.state.spinner.setAttribute("hidden", "true");
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      } else {
        showNotification = {
          title: "Login",
          message: "Login Access Denied",
          type: "dander",
        };
        this.state.spinner.setAttribute("hidden", "true");
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      }
      showNotification = {
        title: "Login",
        message: "Login Access Denied",
        type: "dander",
      };
      this.state.spinner.setAttribute("hidden", "true");
      userService.showNotification(showNotification);
    }
    // this.props.addUSerUInfo(users);
  }
  async sendMailApi(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const data = {
      mail: this.state.resetEmail,
    };
    let showNotification = {};
    try {
      const response = await fetch(
        this.state.apiPathForgot +
          "?mail=" +
          encodeURIComponent(this.state.resetEmail),
        {
          method: "GET",
          headers: clientTokenHeader(),
          // body: JSON.stringify(data),
          // data: JSON.stringify(data)
        }
      )
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: enMsg.forgotPasswordTitleFail,
              message: enMsg.forgotPasswordMsgFail,
              type: "danger",
            };
          } else if (response.ok) {
            showNotification = {
              title: enMsg.forgotPasswordTitle,
              message: enMsg.forgotPasswordMsg,
              type: "success",
            };
            this.setState({
              forgotModal: false,
              resendModal: true,
            });
          } else {
            showNotification = {
              title: enMsg.forgotPasswordTitleFail,
              message: enMsg.forgotPasswordMsgFail,
              type: "danger",
            };
            let error = new Error(response.statusText);
          }
          // return response.json();
          return response.text();
        })
        .then((data) => {
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: enMsg.forgotPasswordTitleFail,
            message: enMsg.forgotPasswordMsgFail,
            type: "danger",
          };

          return response;
        });
    } catch (error) {
      showNotification = {
        title: enMsg.forgotPasswordTitleFail,
        message: enMsg.forgotPasswordMsgFail,
        type: "danger",
      };
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type,
        };
        notifiaciton = Object.assign(NotificationOptions, notifiaciton);
        this.notificationID = store.addNotification(notifiaciton);
      }
      userService.showNotification(showNotification);
    }

    this._isMounted && this.setState({ loading: false });
  }
  checkRememberMe = (e) => {
    let checkBoxVal = this.state.isRemember ? false : true;
    this.setState({ isRemember: checkBoxVal });
  };
  closeModal = (e) => {
    this.setState({
      forgotModal: false,
      resendModal: false,
    });
  };
  backModal = (e) => {
    this.setState({
      forgotModal: true,
      resendModal: false,
    });
  };
  sendMail(e) {
    e.preventDefault();
    this.sendMailApi(e);
  }
  resendMail(e) {
    e.preventDefault();
    this.sendMailApi(e);
  }
  openForgotModal = (e) => {
    this.setState({
      forgotModal: true,
    });
  };
  changePasswordType = (e) => {
    let passwordType =
      this.state.passwordType == "password" ? "text" : "password";
    this.setState({
      passwordType: passwordType,
    });
  };
  render() {
    console.log(this.state.locationBlock);
    return (
      <div className="login-outer-cover">
        <ReactNotifications
          onRef={(ref) => (this.n = ref)} // Required
          title="ASG" // Required
          body="Welcome as ASG application"
          icon="icon.png"
          tag="welcome"
          timeout="1000"
          onClick={(event) => this.handleClick(event)}
        />
        <div className="login-cover gray-bg-color">
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={this.state.forgotModal}
            {...this.state.other}
            TransitionComponent={Transition}
            maxWidth={"lg"}
            keepMounted
            className={"change-pass-dialog profile-content addBlockPopup"}
          >
            <DialogTitle id="customized-dialog-title">
              <div className="green-header warning-header" style={{ top: "0" }}>
                Forgot Your Password?
              </div>
            </DialogTitle>
            <DialogContent className="addBlockContent">
              <p className="addBlockDesc">
                Don't worry Resetting your password is easy.<br></br> Just tell
                us the email ID you registered with MAXMRJ.
              </p>
              <form onSubmit={this.sendMail} noValidate>
                <FormGroup style={{ width: "100%" }}>
                  <TextField
                    style={{ width: "100%" }}
                    label="User ID/Email Address *"
                    type="email"
                    name="resetEmail"
                    data-validators="isRequired,isAlpha"
                    onChange={(event) => this.handleUserInputReset(event)}
                    value={this.state.resetEmail}
                  />
                  <FormErrors
                    show={!this.state.resetEmailValid}
                    formErrors={this.state.resetFormErrors}
                    fieldName="email"
                  />
                </FormGroup>
                <div className="form-button-cover d-flex justify-space-evenly">
                  <Button
                    color="primary"
                    className={
                      this.state.loading ? "btn1 buttonSuccess" : "btn1"
                    }
                    type="submit"
                    disabled={this.state.loading || !this.validateFormReset()}
                  >
                    {this.state.loading && (
                      <CircularProgress size={24} className="buttonProgress" />
                    )}
                    Send
                  </Button>
                  <Button
                    //disabled={!this.validateForm()}
                    onClick={this.closeModal}
                    type="button"
                    className="btn2"
                  >
                    Close
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={this.state.resendModal}
            {...this.state.other}
            TransitionComponent={Transition}
            maxWidth={"md"}
            keepMounted
            className={"change-pass-dialog profile-content addBlockPopup"}
          >
            <DialogTitle id="customized-dialog-title">
              <div className="green-header warning-header" style={{ top: "0" }}>
                Set Your Passsword?
              </div>
            </DialogTitle>
            <DialogContent className="addBlockContent">
              <p className="addBlockDesc">
                We have sent a password reset mail to{" "}
                <b>{this.state.resetEmail}</b>
                <br></br> Please click on the reset password link to set a new
                password.
              </p>
              <br></br>
              <p>Didn't Receive your email yet?</p>
              <p>Please check your spam folder or Resend the Email.</p>
              <div className="form-button-cover d-flex justify-space-evenly">
                <Button
                  className={this.state.loading ? "buttonSuccess btn1" : "btn1"}
                  type="button"
                  onClick={this.resendMail}
                >
                  {this.state.loading && (
                    <CircularProgress size={24} className="buttonProgress" />
                  )}
                  Re-Send
                </Button>
                <Button onClick={this.backModal} type="button" className="btn3">
                  Back
                </Button>
                <Button
                  onClick={this.closeModal}
                  type="button"
                  className="btn2"
                >
                  Close
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          {this.state.locationBlock ? (
            <LocationDetect locationBlock={true} />
          ) : null}
          <div className="logo-content loginLogoMain">
            <div className="loginLogoInner">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="login-inner-cover">
            <div className="login-content">
              <div className="Login">
                <form onSubmit={this.handleSubmit} noValidate>
                  <FormGroup>
                    {/* <InputLabel>Email Address</InputLabel> */}
                    <TextField
                      label="User ID/Email Address *"
                      type="email"
                      name="email"
                      data-validators="isRequired,isAlpha"
                      onChange={(event) => this.handleUserInput(event)}
                      value={this.state.email}
                    />
                    <FormErrors
                      show={!this.state.emailValid}
                      formErrors={this.state.formErrors}
                      fieldName="email"
                    />
                  </FormGroup>

                  <FormGroup className="possword-input">
                    <TextField
                      label="Password *"
                      type={this.state.passwordType}
                      name="password"
                      data-validators="isRequired,isAlpha"
                      onChange={(event) => this.handleUserInput(event)}
                      value={this.state.password}
                      inputProps={{ maxLength: MAX_PASSWORD_LENGTH }}
                    />
                    <FormErrors
                      show={!this.state.passwordValid}
                      formErrors={this.state.formErrors}
                      fieldName="password"
                    />
                  </FormGroup>
                  <div className="action-btns d-flex justify-content-between">
                    <div className="loginPageLink forgot-link">
                      <Link to="#" onClick={this.openForgotModal}>
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="action-btns">
                    <Button
                      color="primary"
                      className={
                        this.state.loading ? "buttonSuccess btn1" : "btn1"
                      }
                      disabled={this.state.loading || !this.validateForm()}
                      type="submit"
                    >
                      Login
                      {this.state.loading && (
                        <CircularProgress
                          size={24}
                          className="buttonProgress"
                        />
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginClass);
export default Login;
