import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { FormErrors } from "../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import PhoneNumber from "awesome-phonenumber";
import ConfirmModal from "components/Modal/modal";
import Icon from "@material-ui/core/Icon";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import {
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
  MAX_INPUT_LENGTH_RATE,
} from "__helpers/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  Slide,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";
import SelectStatus from "components/SelectStatus/selectStatus";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  addDaysToDate,
  subsDaysToDate,
  daysBetweenTwoDate,
} from "__helpers/util";
import baseRoutes from "base-routes";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const headCells = [
  {
    id: "facilityId",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Facility Id",
  },
  {
    id: "facilityName",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Facility Name",
  },
  {
    id: "orgUUID",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "OrgUUID",
  },
  {
    id: "facilityCode",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Facility Code",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Address",
  },
  {
    id: "prim_phone",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Primary Phone",
  },
  {
    id: "sec_phone",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Secondary Phone",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Action",
  },
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
class FacilityIndex extends React.Component {
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    this.dateUtility = new DateFnsUtils();
    let initialDays = 30;
    let endDate = new Date();
    let startDate = subsDaysToDate(endDate, initialDays);
    this.addressInputRef = React.createRef();
    this.state = {
      loading: false,
      modalStatus: false,
      confirmModalStatus: false,
      facilityName: "",
      address: "",
      primaryPhone: "",
      secondaryPhone: "",
      status: true,
      confirmModalMessage: "",
      dataId: "",
      facilities: [],
      perPage: DEFAULT_PER_PAGE_RECORDS,
      total: "",
      currentPage: 0,
      orderBy: "facilityId",
      spinner: spinner,
      isDelete: false,
      searchKeyword: "",
      providers: [],
      apiPartner: "",
      facilityCode: "",
      orgUUID: "",
      addAutoAdmit: false,
      dischargeStartDate: startDate,
      dischargeEndDate: endDate,
      dischargeDays: initialDays,
      formErrors: {
        facilityName: "",
        address: "",
        primaryPhone: "",
        status: "",
        secondaryPhone: "",
        apiPartner: "",
        facilityCode: "",
        orgUUID: "",
        addAutoAdmit: "",
        dischargeStartDate: "",
        dischargeEndDate: "",
        dischargeDays: "",
        latitude: "",
        longitude: "",
      },
      facilityNameValid: false,
      addressValid: false,
      primaryPhoneValid: false,
      statusValid: true,
      secondaryPhoneValid: true,
      apiPartnerValid: true,
      facilityCodeValid: true,
      orgUUIDValid: true,
      addAutoAdmitValid: true,
      dischargeStartDateValid: true,
      dischargeEndDateValid: true,
      dischargeDaysValid: true,
      partnerList: [],
      latitudeValid: false,
      longitudeValid: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateFacility = this.updateFacility.bind(this);
    this.createFacility = this.createFacility.bind(this);
    this.fetchFacilities = this.fetchFacilities.bind(this);
    this.confirmModalSubmit = this.confirmModalSubmit.bind(this);
    this.search = this.search.bind(this);
    this.handleDischageStartDate = this.handleDischageStartDate.bind(this);
    this.handleDischageStartDateError = this.handleDischageStartDateError.bind(
      this
    );
  }

  componentDidMount() {
    this.getApiPartner();
    this.fetchFacilities();
    const addressInputElement = document.querySelector('input[name="address"]');
    console.log(addressInputElement);
    const autocomplete = new window.google.maps.places.Autocomplete(
      addressInputElement
    );

    // Add event listener for place selection
    autocomplete.addListener("place_changed", () => {
      //console.log(autocomplete);
      //this.handlePlaceSelection(autocomplete);
    });

    // Store the Autocomplete object in the component's state
    this.setState({ addressAutocomplete: autocomplete });
  }
  handlePlaceSelection = (autocomplete) => {
    // Get the selected place details from the Autocomplete object
    const selectedPlace = autocomplete.getPlace();

    if (selectedPlace && selectedPlace.formatted_address) {
      this.setState({
        latitude: "",
        longitude: "",
      });
      // Extract the address components from the selected place
      const address = selectedPlace.formatted_address;
      const latitude = selectedPlace.geometry.location.lat();
      const longitude = selectedPlace.geometry.location.lng();
      // console.log(selectedPlace.geometry.location);
      // Update the state with the selected address components

      this.setState({
        address: address,
        latitude: latitude,
        longitude: longitude,
      });
      if (address) this.validateField("address", address);
      if (latitude) this.validateField("latitude", latitude);
      if (longitude) this.validateField("longitude", longitude);
    }
  };
  async getApiPartner() {
    let apiPartnerList = await userService.fetchGlobalApisSilent(
      apiPath.getApiPartnerList
    );
    // console.log(apiPartnerList)
    if (apiPartnerList && apiPartnerList.length) {
      this.setState({
        partnerList: apiPartnerList,
      });
    }
  }
  async fetchFacilities(searchKeyword) {
    this.state.spinner.removeAttribute("hidden", "true");
    let showNotification = {};
    let apiUrl = "";
    if (searchKeyword) {
      apiUrl =
        apiPath.getFacilities +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "&search=" +
        searchKeyword;
    } else {
      apiUrl =
        apiPath.getFacilities +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "";
    }
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Facility",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          this.setState({
            total: response.headers.get("X-Total-Count"),
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Facility",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          facilities: data,
        });
      })
      .catch((error) => {
        showNotification = {
          title: "Facility",
          message: "Bad response from server",
          type: "danger",
        };
        return response;
      });

    userService.showNotification(showNotification);
    this.state.spinner.setAttribute("hidden", "true");
  }
  modalOpen = (dataId) => {
    if (!isNaN(dataId)) {
      this.state.facilities.map((facility) => {
        if (facility.facilityId == dataId) {
          this.setState({
            facilityName: facility.facility_name,
            address: facility.address,
            primaryPhone: facility.prim_phone,
            secondaryPhone: facility.sec_phone,
            status: facility.status,
            dataId: dataId,
            apiPartner: facility.apiPartnerId ? facility.apiPartnerId : "",
            facilityCode: facility.facilityCode ? facility.facilityCode : "",
            orgUUID: facility.orgUUID ? facility.orgUUID : "",
            addAutoAdmit: facility.autoAdmit ? facility.autoAdmit : false,
            dischargeDays: facility.pccDischargePatientDays
              ? facility.pccDischargePatientDays
              : "30",
            dischargeEndDate: new Date(),
            dischargeStartDate: facility.pccStartDate
              ? subsDaysToDate(
                  new Date(),
                  facility.pccDischargePatientDays
                    ? facility.pccDischargePatientDays
                    : 30
                )
              : subsDaysToDate(new Date(), 30),
            facilityNameValid: true,
            addressValid: true,
            primaryPhoneValid: true,
            secondaryPhoneValid: true,
            statusValid: true,
            dischargeStartDateValid: true,
            dischargeEndDateValid: true,
            dischargeDaysValid: true,
            latitude: facility.latitude ? facility.latitude : "",
            longitude: facility.longitude ? facility.longitude : "",
            latitudeValid: true,
            longitudeValid: true,
          });
        }
      });
    } else {
      this.setState({
        facilityName: "",
        address: "",
        primaryPhone: "",
        secondaryPhone: "",
        status: true,
        dataId: "",
        apiPartner: "",
        facilityCode: "",
        orgUUID: "",
        addAutoAdmit: false,
        dischargeDays: "30",
        dischargeEndDate: new Date(),
        dischargeStartDate: subsDaysToDate(new Date(), 30),
        facilityNameValid: false,
        addressValid: false,
        primaryPhoneValid: false,
        secondaryPhoneValid: true,
        facilityCodeValid: true,
        orgUUIDValid: true,
        apiPartnerValid: true,
        statusValid: true,
        dischargeStartDateValid: true,
        dischargeEndDateValid: true,
        dischargeDaysValid: true,
        latitude: "",
        longitude: "",
        latitudeValid: false,
        longitudeValid: false,
      });
    }
    this.setState({
      modalStatus: true,
    });
    this.getAddress();
    console.log();
  };
  getAddress = () => {
    setTimeout(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("addressMap")
      );
      autocomplete.addListener("place_changed", () => {
        this.handlePlaceSelection(autocomplete);
      });
    }, 2000);
  };
  modalClose = () => {
    this.setState({
      modalStatus: false,
    });
  };
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
    // if (name === "dischargeDays") {
    //     let startDate = subsDaysToDate(this.state.dischargeEndDate, parseInt(value));
    //     console.log(this.state.dischargeEndDate, startDate, parseInt(value), value)
    //     this.setState({
    //         "dischargeStartDate": startDate
    //     }, () => { this.validateField("dischargeStartDate", startDate) })
    // }
  };
  handleChangeInputInt = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const re = /^\d+$/;
    if (value === "" || re.test(value)) {
      value = value;
    } else {
      if (re.test(this.state[name])) value = this.state[name];
      else value = 1;
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  handleChangeBox() {
    let addAutoAdmit = this.state.addAutoAdmit;
    this.setState({
      addAutoAdmit: !addAutoAdmit,
    });
  }
  handlephoneNumber = (phoneNumber) => {
    const phoneNumberValidation = new PhoneNumber(phoneNumber).toJSON();
    let phoneNumberValid = this.state.primaryPhoneValid;
    let error = "";
    let data = [];

    if (phoneNumber.trim().length) {
      switch (phoneNumberValidation.possibility) {
        case "invalid-country-code":
          phoneNumberValid = false;
          error = enMsg.phoneInvalidCountryCode;
          break;
        case "too-long":
          phoneNumberValid = false;
          error = enMsg.phoneTooLong;
          break;
        case "too-short":
          phoneNumberValid = false;
          error = enMsg.phoneTooShort;
          break;
        case "unknown":
          phoneNumberValid = false;
          error = enMsg.phoneUnknown;
          break;
        default:
          phoneNumberValid = true;
      }
    }
    data["is_valid"] = phoneNumberValid;
    data["error"] = error;
    return data;
  };
  handleSubmit(event) {
    event.preventDefault();
    let dischargeDays = this.state.dischargeDays;
    let endDate = new Date();
    let startDate = subsDaysToDate(endDate, parseInt(dischargeDays));
    if (this.state.dataId) {
      let dischargeStartDate = startDate
        ? this.dateUtility.format(new Date(startDate), "yyyy-MM-dd")
        : null;
      let dischargeEndDate = endDate
        ? this.dateUtility.format(new Date(endDate), "yyyy-MM-dd")
        : null;
      const data = {
        address: this.state.address,
        facility_name:
          this.state.facilityName.charAt(0).toUpperCase() +
          this.state.facilityName.slice(1),
        prim_phone: this.state.primaryPhone,
        prim_phone_code: "+1",
        sec_phone: this.state.secondaryPhone,
        sec_phone_code: "+1",
        status: this.state.status,
        facilityId: this.state.dataId,
        facilityCode: this.state.facilityCode,
        orgUUID: this.state.orgUUID,
        apiPartnerId: this.state.apiPartner,
        autoAdmit: this.state.addAutoAdmit,
        pccStartDate: dischargeStartDate,
        pccEndDate: dischargeEndDate,
        pccDischargePatientDays: this.state.dischargeDays,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
      };
      this.updateFacility(data, false);
    } else {
      this.createFacility();
    }
  }
  async createFacility() {
    let showNotification = {};
    let dischargeStartDate = this.state.dischargeStartDate
      ? this.dateUtility.format(
          new Date(this.state.dischargeStartDate),
          "yyyy-MM-dd"
        )
      : null;
    let dischargeEndDate = this.state.dischargeEndDate
      ? this.dateUtility.format(
          new Date(this.state.dischargeEndDate),
          "yyyy-MM-dd"
        )
      : null;
    const data = {
      address: this.state.address,
      facility_name:
        this.state.facilityName.charAt(0).toUpperCase() +
        this.state.facilityName.slice(1),
      prim_phone: this.state.primaryPhone,
      prim_phone_code: "+1",
      sec_phone: this.state.secondaryPhone,
      sec_phone_code: "+1",
      facilityCode: this.state.facilityCode,
      orgUUID: this.state.orgUUID,
      apiPartnerId: this.state.apiPartner,
      autoAdmit: this.state.addAutoAdmit,
      status: this.state.status,
      pccStartDate: dischargeStartDate,
      pccEndDate: dischargeEndDate,
      pccDischargePatientDays: this.state.dischargeDays,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };
    this.setState({
      loading: true,
    });
    const response = await fetch(apiPath.getFacilities, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Facility",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Facility",
            message: "Bad response from server.",
            type: "danger",
          };
        }
        // userService.showNotification(showNotification);
        // this.modalClose();
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        if (data.facilityId) {
          showNotification = {
            title: "Success",
            message: "Facility created successfully",
            type: "success",
          };
          // userService.showNotification(showNotification);
          this.modalClose();
          this.fetchFacilities();
          return true;
        }
        if (data.title) {
          showNotification = {
            title: "Facility",
            message: data.title,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
      })
      .catch((error) => {
        showNotification = {
          title: "Facility",
          message: "Something went wrong.Please try after sometime..",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        this.modalClose();
      });
  }
  updateFacility(data, isOnlyStatusChange) {
    let showNotification = {};
    this.setState({ loading: true });
    const response = fetch(apiPath.getFacilities, {
      method: "PUT",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
          let error = new Error(response.statusText);
        }
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        if (data.facilityId) {
          this.fetchFacilities(this.state.searchKeyword);
          showNotification = {
            title: "Success",
            message: isOnlyStatusChange
              ? "Status updated successfully"
              : "Facility updated successfully",
            type: "success",
          };

          // userService.showNotification(showNotification);
          this.modalClose();
          this.setState({ loading: false });
          return true;
        }
        if (data.title) {
          showNotification = {
            title: "Facility",
            message: data.title,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.loginFailedTitle,
          message: enMsg.inValidCredentials,
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      });
  }
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let facilityNameValid = this.state.facilityNameValid;
    let addressValid = this.state.addressValid;
    let primaryPhoneValid = this.state.primaryPhoneValid;
    let statusValid = this.state.statusValid;
    let secondaryPhoneValid = this.state.secondaryPhoneValid;
    let apiPartnerValid = this.state.apiPartnerValid;
    let facilityCodeValid = this.state.facilityCodeValid;
    let orgUUIDValid = this.state.orgUUIDValid;
    let dischargeStartDateValid = this.state.dischargeStartDateValid;
    let dischargeEndDateValid = this.state.dischargeEndDateValid;
    let dischargeDaysValid = this.state.dischargeDaysValid;
    let latitudeValid = this.state.latitudeValid;
    let longitudeValid = this.state.longitudeValid;
    switch (fieldName) {
      case "facilityName":
        let facilityErrMsg = "";
        if (fieldValue.trim().length == 0) {
          facilityNameValid = false;
          facilityErrMsg = enMsg.facilityNameRequiredErrorMsg;
        } else if (fieldValue.trim().length > 100) {
          facilityNameValid = false;
          facilityErrMsg = "Facility name cannot exceed 100 characters.";
        } else {
          facilityNameValid = true;
          facilityErrMsg = "";
        }
        // facilityNameValid = fieldValue.trim().length > 0;
        fieldValidationErrors.facilityName = facilityNameValid
          ? ""
          : facilityErrMsg;
        break;
      case "address":
        addressValid = fieldValue.trim().length > 0;
        fieldValidationErrors.address = addressValid
          ? ""
          : enMsg.addressRequiredErrorMsg;
        break;
        case "primaryPhone":
          let errMsg = "";
          const onlyDigits = fieldValue.replace(/[^\d]/g, ""); // Remove all non-digit characters
          if (onlyDigits.length == 0) {
              primaryPhoneValid = false;
              errMsg = "Phone number is required.";
          } else if (!/^\(\d{3}\)\d{3}-\d{4}$/.test(fieldValue) && !/^\d{10}$/.test(onlyDigits)) {
              primaryPhoneValid = false;
              errMsg = "Invalid number. Use format (111)111-1111 or a 10-digit number.";
          } else if (onlyDigits.length != 10) {
              primaryPhoneValid = false;
              errMsg = enMsg.phoneNumberDigit;
          } else {
              primaryPhoneValid = true;
          }
          fieldValidationErrors.primaryPhone = primaryPhoneValid ? "" : errMsg;
          break;
          case "secondaryPhone":
            let errMsgs = "";
            const onlyDigit = fieldValue.replace(/[^\d]/g, ""); // Remove all non-digit characters
            if (onlyDigit.length == 0) {
              secondaryPhoneValid = false;
              errMsgs = "Phone number is required.";
            } else if (!/^\(\d{3}\)\d{3}-\d{4}$/.test(fieldValue) && !/^\d{10}$/.test(onlyDigit)) {
              secondaryPhoneValid = false;
              errMsgs = "Invalid number. Use format (111)111-1111 or a 10-digit number.";
            } else if (onlyDigit.length != 10) {
              secondaryPhoneValid = false;
              errMsgs = enMsg.phoneNumberDigit;
            } else {
              secondaryPhoneValid = true;
            }
            fieldValidationErrors.secondaryPhone = secondaryPhoneValid ? "" : errMsgs;
            break;
      
      // case "secondaryPhone":
      //   let secondaryPhoneErrMsg = "";
      //   if (
      //     fieldValue.length > 0 &&
      //     (isNaN(fieldValue.trim()) ||
      //       !ONLY_NUMBER_REGEX.test(fieldValue.trim()))
      //   ) {
      //     secondaryPhoneErrMsg = "Invalid number";
      //     secondaryPhoneValid = false;
      //   } else if (fieldValue.length > 0 && fieldValue.trim().length != 10) {
      //     secondaryPhoneValid = false;
      //     secondaryPhoneErrMsg = enMsg.phoneNumberDigit;
      //   } else {
      //     secondaryPhoneValid = true;
      //   }
      //   fieldValidationErrors.secondaryPhone = secondaryPhoneValid
      //     ? ""
      //     : secondaryPhoneErrMsg;
      //   break;
      case "facilityCode":
        facilityCodeValid = fieldValue.trim().length
          ? true
          : this.state.apiPartner
          ? false
          : true;
        fieldValidationErrors.facilityCode = facilityCodeValid
          ? ""
          : "Facility code is required";
        apiPartnerValid = this.state.apiPartner
          ? true
          : fieldValue.trim().length
          ? false
          : true;
        fieldValidationErrors.apiPartner = apiPartnerValid
          ? ""
          : "API partner is required";
        dischargeDaysValid = this.state.dischargeDays
          ? true
          : fieldValue.trim().length
          ? false
          : this.state.apiPartner
          ? false
          : true;
        orgUUIDValid = this.state.orgUUID.trim().length
          ? true
          : fieldValue
          ? false
          : true;
        fieldValidationErrors.orgUUID = orgUUIDValid
          ? ""
          : "OrgUUID is required";
        break;

      case "orgUUID":
        facilityCodeValid = this.state.facilityCode.trim().length
          ? true
          : fieldValue
          ? false
          : true;
        orgUUIDValid = fieldValue.trim().length
          ? true
          : this.state.apiPartner
          ? false
          : true;
        fieldValidationErrors.orgUUID = orgUUIDValid
          ? ""
          : "OrgUUID is required";
        apiPartnerValid = this.state.apiPartner
          ? true
          : fieldValue.trim().length
          ? false
          : true;
        fieldValidationErrors.apiPartner = apiPartnerValid
          ? ""
          : "API partner is required";
        dischargeDaysValid = this.state.dischargeDays
          ? true
          : fieldValue.trim().length
          ? false
          : this.state.apiPartner
          ? false
          : true;
        fieldValidationErrors.facilityCode = facilityCodeValid
          ? ""
          : "Facility code is required";
        break;

      case "apiPartner":
        apiPartnerValid = fieldValue
          ? true
          : this.state.facilityCode.trim().length
          ? false
          : true;
        fieldValidationErrors.apiPartner = apiPartnerValid
          ? ""
          : "API partner is required";
        facilityCodeValid = this.state.facilityCode.trim().length
          ? true
          : fieldValue
          ? false
          : true;
        orgUUIDValid = this.state.orgUUID.trim().length
          ? true
          : fieldValue
          ? false
          : true;
        fieldValidationErrors.facilityCode = facilityCodeValid
          ? ""
          : "Facility code is required";
        dischargeDaysValid = this.state.dischargeDays
          ? true
          : fieldValue
          ? false
          : this.state.facilityCode
          ? false
          : true;
        fieldValidationErrors.orgUUID = orgUUIDValid
          ? ""
          : "OrgUUID is required";
        break;
      case "status":
        statusValid = fieldValue ? true : false;
        fieldValidationErrors.status = statusValid ? "" : "Status is required";
        break;
      case "dischargeStartDate":
        dischargeStartDateValid = fieldValue && fieldValue != "" ? true : false;
        fieldValidationErrors.dischargeStartDate = !dischargeStartDateValid
          ? "Discharge start date required"
          : "";
        break;
      case "dischargeEndDate":
        dischargeEndDateValid = fieldValue && fieldValue != "" ? true : false;
        fieldValidationErrors.dischargeEndDate = !dischargeEndDateValid
          ? "Discharge end date required"
          : "";
        break;
      case "dischargeDays":
        dischargeDaysValid = fieldValue && fieldValue != "" ? true : false;
        fieldValidationErrors.dischargeDays = !dischargeDaysValid
          ? "Discharge days required"
          : "";
        break;
      case "latitude":
        latitudeValid = fieldValue && fieldValue != "" ? true : false;
        fieldValidationErrors.latitude = !latitudeValid
          ? "Latitude days required"
          : "";
        break;
      case "longitude":
        if (fieldValue == "") {
          longitudeValid = true;
          fieldValidationErrors.longitude = "Longitude is required";
        } else {
          longitudeValid = true;
          fieldValidationErrors.longitude = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        facilityNameValid: facilityNameValid,
        addressValid: addressValid,
        primaryPhoneValid: primaryPhoneValid,
        statusValid: statusValid,
        secondaryPhoneValid: secondaryPhoneValid,
        apiPartnerValid: apiPartnerValid,
        facilityCodeValid: facilityCodeValid,
        orgUUIDValid: orgUUIDValid,
        dischargeStartDateValid: dischargeStartDateValid,
        dischargeEndDateValid: dischargeEndDateValid,
        dischargeDaysValid: dischargeDaysValid,
        longitudeValid: longitudeValid,
        latitudeValid: latitudeValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return (
      this.state.facilityNameValid &&
      this.state.addressValid &&
      this.state.primaryPhoneValid &&
      this.state.secondaryPhoneValid &&
      this.state.apiPartnerValid &&
      this.state.facilityCodeValid &&
      this.state.orgUUIDValid &&
      this.state.dischargeStartDateValid &&
      this.state.dischargeEndDateValid &&
      this.state.dischargeDaysValid &&
      this.state.longitudeValid &&
      this.state.latitudeValid
    );
  }

  confirmModalOpen = (message, dataId, isDelete) => {
    this.setState({
      dataId: dataId,
      confirmModalMessage: message,
      confirmModalStatus: true,
      isDelete: isDelete,
    });
  };
  confirmModalClose = () => {
    this.setState({
      confirmModalStatus: false,
    });
  };
  async confirmModalSubmit() {
    if (!this.state.isDelete) {
      Promise.all(
        this.state.facilities.map((facility) => {
          if (facility.facilityId == this.state.dataId) {
            const data = {
              facility_name: facility.facility_name,
              address: facility.address,
              prim_phone: facility.prim_phone,
              sec_phone: facility.sec_phone,
              prim_phone_code: "+1",
              sec_phone_code: "+1",
              status: !facility.status,
              facilityId: this.state.dataId,
              facilityCode: this.state.facilityCode,
              orgUUID: this.state.orgUUID,
              apiPartnerId: this.state.apiPartner,
              autoAdmit: this.state.addAutoAdmit,
            };
            this.updateFacility(data, true);
            this.confirmModalClose();
          }
        })
      );
    } else {
      this.setState({ loading: true });
      let showNotification = {};
      const data = {
        id: this.state.dataId,
      };
      const response = await fetch(
        apiPath.getFacilities + "/" + this.state.dataId,
        {
          method: "DELETE",
          headers: generalAuthTokenHeader(),
          body: JSON.stringify(data),
          data: JSON.stringify(data),
        }
      ).then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Delete",
            message: "Something went wrong.Please try after sometime.",
            type: "danger",
          };
        } else if (response.status === 204) {
          showNotification = {
            title: "Deletion",
            message: "Facility deleted successfully",
            type: "success",
          };
          this.fetchFacilities();
        } else if (response.ok) {
          showNotification = {
            title: "Deletion",
            message: "Facility deleted successfully",
            type: "success",
          };
          this.fetchFacilities();
        } else {
          showNotification = {
            title: "Delete",
            message: "Something went wrong.Please try after sometime.",
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        this.confirmModalClose();
      });
      this.setState({ loading: false });
    }
  }
  handleChangePage = (event, newPage) => {
    this.setState(
      {
        currentPage: newPage,
      },
      () => this.fetchFacilities()
    );
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        perPage: parseInt(event.target.value),
        currentPage: 0,
      },
      () => this.fetchFacilities(this.state.searchKeyword)
    );
  };
  tableSortHandler = (property) => (event) => {
    this.setState(
      {
        orderBy: property,
        direction: !this.state.direction,
      },
      () => this.fetchFacilities(this.state.searchKeyword)
    );
  };
  search(event) {
    let searchKeyword = event.target.value.trim();
    if (searchKeyword.length > 0) {
      this.setState({ currentPage: 0 }, () =>
        this.fetchFacilities(searchKeyword)
      );
    } else {
      this.fetchFacilities();
    }
    this.setState({ searchKeyword: searchKeyword });
    return true;
  }
  handleDischageStartDate = (value) => {
    let dischargeStartDate = true;
    let dischargeDays = this.state.dischargeDays;
    if (value == null || !this.dateUtility.isValid(value)) {
      dischargeStartDate = false;
    } else {
      dischargeDays = daysBetweenTwoDate(value, this.state.dischargeEndDate);
    }
    this.setState(
      {
        dischargeStartDate: value,
        dischargeStartDateValid: dischargeStartDate,
        dischargeDays: dischargeDays,
      },
      () => {
        this.validateField("dischargeStartDate", value);
      }
    );
  };
  handleDischageStartDateError(error, value) {
    this.setState({
      dischargeStartDateValid:
        error || value == "" || value == null ? false : true,
    });
  }
  render() {
    const {
      apiPartner,
      facilityCode,
      orgUUID,
      dataId,
      partnerList,
    } = this.state;
    // console.log(partnerList)
    return (
      <div id="facility-index">
        <ConfirmModal
          title="Alert"
          message={this.state.confirmModalMessage}
          confirmModalStatus={this.state.confirmModalStatus}
          confirmModalClose={this.confirmModalClose}
          submit={this.confirmModalSubmit}
          loading={this.state.loading}
        />
        <Dialog
          open={this.state.modalStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            {this.state.dataId ? "Edit Facility" : "Add Facility"}
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.handleSubmit} noValidate>
            <DialogContent>
              <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                <Box pr={1} width={1}>
                  <TextField
                    InputLabelProps={{ className: "required-label" }}
                    name="facilityName"
                    value={this.state.facilityName}
                    onChange={this.handleChangeInput}
                    data-validators="isRequired"
                    label="Facility Name"
                  />
                  <FormErrors
                    show={!this.state.facilityNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="facilityName"
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={2}>
                <Box width={1 / 2} pr={1}>
                  <TextField
                    id="addressMap"
                    name="address"
                    value={this.state.address}
                    InputLabelProps={{ className: "required-label" }}
                    label="Address Street"
                    onChange={this.handleChangeInput}
                    inputRef={this.addressInputRef} // Ref for the input field
                  />
                  <FormErrors
                    show={!this.state.addressValid}
                    formErrors={this.state.formErrors}
                    fieldName="address"
                  />
                </Box>
                <Box width={1 / 2} pl={1}>
                  <TextField
                    InputLabelProps={{ className: "required-label" }}
                    name="primaryPhone"
                    label="Primary Phone"
                    value={this.state.primaryPhone}
                    onChange={this.handleChangeInput}
                  />
                  <FormErrors
                    show={!this.state.primaryPhoneValid}
                    formErrors={this.state.formErrors}
                    fieldName="primaryPhone"
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={2}>
                <Box width={1 / 2} pr={1}>
                  <TextField
                    name="secondaryPhone"
                    label="Secondary Phone"
                    value={this.state.secondaryPhone}
                    onChange={this.handleChangeInput}
                  />
                  <FormErrors
                    show={!this.state.secondaryPhoneValid}
                    formErrors={this.state.formErrors}
                    fieldName="secondaryPhone"
                  />
                </Box>
                <Box width={1 / 2} pl={1}>
                  <SelectStatus
                    id="status-select-label"
                    value={this.state.status}
                    name="status"
                    handleChange={this.handleChangeInput}
                  />
                  <FormErrors
                    show={!this.state.status}
                    formErrors={this.state.formErrors}
                    fieldName="status"
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={2}>
                <Box width={1 / 2} pr={1}>
                  <TextField
                    // InputLabelProps={{ className: "required-label" }}
                    name="orgUUID"
                    value={this.state.orgUUID}
                    onChange={this.handleChangeInput}
                    data-validators="isRequired"
                    label="OrgUUID"
                  />
                  <FormErrors
                    show={!this.state.orgUUIDValid}
                    formErrors={this.state.formErrors}
                    fieldName="orgUUID"
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={2}>
                <Box width={1 / 2} pr={1}>
                  <TextField
                    name="facilityCode"
                    placeholder="Avaible Ids for testing 12, 22, 44"
                    // disabled={dataId ? true : false}
                    label="Facility Code"
                    value={this.state.facilityCode}
                    onChange={this.handleChangeInput}
                  />
                  <FormErrors
                    show={!this.state.facilityCodeValid}
                    formErrors={this.state.formErrors}
                    fieldName="facilityCode"
                  />
                </Box>
                <Box width={1 / 2} pl={1}>
                  <InputLabel htmlFor="facility-select-label" className="">
                    API Vendor Partner
                  </InputLabel>
                  <Select
                    value={this.state.apiPartner ? this.state.apiPartner : ""}
                    labelProps={{ className: "" }}
                    onChange={this.handleChangeInput}
                    inputProps={{
                      name: "apiPartner",
                      id: "facility-select-label",
                      // disabled: dataId ? true : false,
                    }}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      },
                      variant: "menu",
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="">No Partner</MenuItem>
                    {this.state.partnerList && this.state.partnerList.length ? (
                      this.state.partnerList.map((partner, key) => (
                        <MenuItem key={key} value={partner.id}>
                          {partner.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value=" ">Not Available</MenuItem>
                    )}
                  </Select>
                  <FormErrors
                    show={!this.state.apiPartnerValid}
                    formErrors={this.state.formErrors}
                    fieldName="apiPartner"
                  />
                </Box>
              </Box>
              {/* <Box display="flex" justifyContent="flex-start" mb={2} >
                                <Box width={1 / 2} pr={1} >
                                    <FormControl >
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                            moment={moment}
                                        >
                                            <KeyboardDatePicker
                                                label="Discharge Start Date"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="start-date-picker-dialog"
                                                InputLabelProps={{
                                                    className: "required-label"
                                                }}
                                                InputProps={{ autoComplete: "off" }}
                                                name="dischargeStartDate"
                                                animateYearScrolling={true}
                                                value={this.state.dischargeStartDate}
                                                minDate={new Date("01/01/1900")}
                                                // minDateMessage={enMsg.estMinDate}
                                                maxDate={this.state.dischargeEndDate}
                                                onChange={this.handleDischageStartDate}
                                                onError={this.handleDischageStartDateError}
                                                className="KeyboardDatePicker"
                                                invalidDateMessage={enMsg.invalidDate}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                    className: "date-picker-span"
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <FormErrors
                                            show={!this.state.dischargeStartDateValid}
                                            formErrors={this.state.formErrors}
                                            fieldName="dischargeStartDate"
                                        />
                                    </FormControl>
                                </Box>
                                <Box width={1 / 2} pl={1}  >
                                    <FormControl >
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                            moment={moment}
                                        >
                                            <KeyboardDatePicker
                                                label="Discharge End Date"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="start-date-picker-dialog"
                                                InputLabelProps={{
                                                    className: "required-label"
                                                }}
                                                InputProps={{ autoComplete: "off" }}
                                                name="dischargeEndDate"
                                                animateYearScrolling={true}
                                                value={this.state.dischargeEndDate}
                                                // minDate={this.minDate}
                                                // minDateMessage={enMsg.estMinDate}
                                                // onChange={this.handleEstDate}
                                                // onError={this.handleEstDateError}
                                                className="KeyboardDatePicker"
                                                invalidDateMessage={enMsg.invalidDate}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                    className: "date-picker-span"
                                                }}
                                                disabled={true}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <FormErrors
                                            show={!this.state.dischargeEndDateValid}
                                            formErrors={this.state.formErrors}
                                            fieldName="dischargeEndDate"
                                        />
                                    </FormControl>
                                </Box>
                            </Box> */}
              <Box display="flex" justifyContent="flex-start" mb={2}>
                <Box width={1 / 2} pr={1}>
                  {apiPartner && facilityCode ? (
                    <div>
                      <TextField
                        name="dischargeDays"
                        label="Discharge Days"
                        InputLabelProps={{ className: "required-label" }}
                        placeholder="Enter discharge days"
                        value={this.state.dischargeDays}
                        inputProps={{ maxLength: 4, pattern: "^(d|10).d{2}$" }}
                        onChange={this.handleChangeInputInt}
                      />
                      <FormErrors
                        show={!this.state.dischargeDaysValid}
                        formErrors={this.state.formErrors}
                        fieldName="dischargeDays"
                      />
                    </div>
                  ) : null}
                </Box>
                <Box width={1 / 2} pr={1}>
                  <FormGroup
                    aria-label="position"
                    row
                    className="check-input admitted-checkbox-auto"
                  >
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          disabled={apiPartner && facilityCode ? false : true}
                          checked={this.state.addAutoAdmit ? true : false}
                          color="primary"
                          className="check-input"
                          onChange={(event) => this.handleChangeBox()}
                        />
                      }
                      label="Add Admitted Patients Automatically"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-start" mb={2}>
                <Box width={1 / 2} pr={1}>
                  <TextField
                    name="latitude"
                    value={this.state.latitude}
                    InputLabelProps={{ className: "required-label" }}
                    label="Latitude"
                    onChange={this.handleChangeInput}
                    tabIndex="5"
                    InputProps={{
                      readOnly: true, // Make the field read-only based on the field name
                    }}
                  />
                  <FormErrors
                    show={!this.state.latitudeValid}
                    formErrors={this.state.formErrors}
                    fieldName="latitude"
                  />
                </Box>
                <Box width={1 / 2} pl={1}>
                  <TextField
                    name="longitude"
                    value={this.state.longitude}
                    InputLabelProps={{ className: "required-label" }}
                    label="Longitude"
                    onChange={this.handleChangeInput}
                    tabIndex="5"
                    InputProps={{
                      readOnly: true, // Make the field read-only based on the field name
                    }}
                  />
                  <FormErrors
                    show={!this.state.longitudeValid}
                    formErrors={this.state.formErrors}
                    fieldName="longitude"
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className="modal-actions" justify="center">
              <Button
                type="submit"
                className="btn1"
                disabled={!this.validateForm() || this.state.loading}
              >
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                {this.state.dataId ? "Update Facility" : "Add Facility"}
              </Button>
              <Button onClick={this.modalClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <GridContainer>
          <Card className={"advert-cover"}>
            <GridItem xs={12} sm={12} md={12}>
              <CardHeader
                color="success"
                className={"cusCardheader cusCardTitle"}
              >
                <h4>Facility</h4>
              </CardHeader>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div className="search-btn-cover">
                <input
                  type="text"
                  title="Search by facility name/address/primary phone/secondary phone."
                  placeholder="Search by facility name/address/primary phone/secondary phone."
                  className="search-btn"
                  onChange={this.search}
                />
                <i className="material-icons">search</i>
              </div>
              <div className="button-cover">
                <button className="pointer btn1" onClick={this.modalOpen}>
                  Add New Facility
                </button>
              </div>
            </GridItem>
            <div className={"custom-container"}>
              <div className="tableResponsive">
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "default"}
                        >
                          {headCell.sorting ? (
                            <TableSortLabel
                              active={this.state.orderBy == headCell.id}
                              direction={this.state.direction ? "asc" : "desc"}
                              onClick={this.tableSortHandler(headCell.id)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          ) : (
                            headCell.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.facilities.length ? (
                      this.state.facilities.map((facility) => (
                        <TableRow key={facility.facilityId}>
                          <TableCell>{facility.facilityId}</TableCell>
                          <TableCell>{facility.facility_name}</TableCell>
                          <TableCell>
                            {facility.orgUUID ? facility.orgUUID : "N/A"}
                          </TableCell>
                          <TableCell>
                            {facility.facilityCode
                              ? facility.facilityCode
                              : "N/A"}
                          </TableCell>
                          <TableCell>{facility.address}</TableCell>
                          <TableCell>
                          {(() => {
                          const onlyDigits = facility.prim_phone.replace(/[^\d]/g, ""); // Remove all non-digit characters
                          if (onlyDigits.length === 10) {
                            return `(${onlyDigits.slice(0, 3)}) ${onlyDigits.slice(3, 6)}-${onlyDigits.slice(6)}`;
                          } else {
                            return "";
                          }
                           })()}
                        </TableCell>
                          <TableCell>
                          {(() => {
                          const onlyDigits = facility.sec_phone.replace(/[^\d]/g, ""); // Remove all non-digit characters
                          if (onlyDigits.length === 10) {
                            return `(${onlyDigits.slice(0, 3)}) ${onlyDigits.slice(3, 6)}-${onlyDigits.slice(6)}`;
                          } else {
                            return "";
                          }
                           })()}
                          </TableCell>
                          <TableCell>
                            <SwitchToggle
                              id={facility.facilityId}
                              status={facility.status}
                              clickHandler={() =>
                                this.confirmModalOpen(
                                  "Do you want to change the status?",
                                  facility.facilityId,
                                  false
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <span
                              className="edit-action"
                              onClick={() =>
                                this.modalOpen(facility.facilityId)
                              }
                            >
                              <Icon
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              />
                            </span>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan="7">
                          <div className="not-found-text">
                            Facilities not found
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>

              <TablePagination
                rowsPerPageOptions={PER_PAGE_OPTIONS}
                component="div"
                count={parseInt(this.state.total)}
                rowsPerPage={this.state.perPage}
                page={this.state.currentPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </Card>
        </GridContainer>
      </div>
    );
  }
}

export default FacilityIndex;
