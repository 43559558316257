import React, { Component } from 'react'
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Table, TableHead, TableBody, TableCell, TableRow, TableSortLabel, Button, TablePagination } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Select,
    DialogTitle,
    IconButton,
    TextField,
    MenuItem,
    InputLabel,
    Slide,
    Box
} from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import { EMAIL_REGEX, MAX_INPUT_LENGTH, ONLY_AlPHABETS_REGEX } from "__helpers/constants";
import CloseIcon from '@material-ui/icons/Close';

import { FormErrors } from "../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ConfirmModal from 'components/Modal/modal';
import { apiPath } from 'api'
import { generalAuthTokenHeader } from "__helpers/auth-header"
import { PER_PAGE_OPTIONS, DEFAULT_PER_PAGE_RECORDS, ONLY_NUMBER_REGEX } from "__helpers/constants"
import SelectStatus from 'components/SelectStatus/selectStatus'
import PhoneNumber from "awesome-phonenumber"
import SwitchToggle from 'components/SwitchToggle/SwitchToggle';
import CircularProgress from "@material-ui/core/CircularProgress";
import baseRoutes from 'base-routes';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
const AntSwitch = withStyles((theme) => ({
    root: {
        width: 38,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(22px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: '#1fb76d',
                borderColor: '#1fb76d',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);
const headCells = [
    { id: 'id', numeric: false, disablePadding: false, sorting: true, label: 'DP ID' },
    { id: 'user.firstName', numeric: false, disablePadding: false, sorting: true, label: 'Name' },
    { id: 'facility.facilityName', numeric: false, disablePadding: false, sorting: true, label: 'Facility' },
    // { id: 'address', numeric: false, disablePadding: false, sorting: true, label: 'Address' },
    { id: 'phone', numeric: false, disablePadding: false, sorting: false, label: 'Phone' },
    { id: 'user.activated', numeric: false, disablePadding: false, sorting: false, label: 'Status' },
    { id: 'action', numeric: false, disablePadding: false, sorting: false, label: 'Action' },
];

class DischargePlannerIndex extends Component {

    constructor(props) {
        super(props)
        let spinner = document.getElementById('loadingSpinner');
        this.state = {
            loading: false,
            modalStatus: false,
            confirmModalStatus: false,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            dischargePlanners: [],
            status: true,
            checked: false,
            confirmModalMessage: '',
            dataId: '',
            spinner: spinner,
            perPage: DEFAULT_PER_PAGE_RECORDS,
            total: '',
            currentPage: 0,
            orderBy: 'id',
            facilities: [],
            facility: '',
            isDelete: false,
            searchKeyword: '',
            formErrors: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                facility: '',
                status: '',
            },
            firstNameValid: false,
            lastNameValid: false,
            emailValid: false,
            phoneNumberValid: false,
            facilityValid: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.confirmModalSubmit = this.confirmModalSubmit.bind(this);
        this.search = this.search.bind(this);
    }
    componentDidMount() {
        this.fetchDischargePlanners();
        let showNotification = {};
        const response = fetch(apiPath.getActiveFacilities, {
            method: "GET",
            headers: generalAuthTokenHeader()
        })
            .then(response => {
                if (response.status === 400) {
                } else if (response.ok) {
                }
                else {
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    facilities: data
                })
            })
            .catch(error => {

                return response;
            });
    }

    async fetchDischargePlanners(searchKeyword) {
        this.state.spinner.removeAttribute('hidden', 'true');
        let showNotification = {};
        let apiUrl = '';
        if (searchKeyword) {
            apiUrl = apiPath.getDischargePlanners + '?page=' + this.state.currentPage + '&size=' + this.state.perPage + '&sort=' + this.state.orderBy + ',' + (this.state.direction ? 'asc' : 'desc') + '&search=' + searchKeyword;
        } else {
            apiUrl = apiPath.getDischargePlanners + '?page=' + this.state.currentPage + '&size=' + this.state.perPage + '&sort=' + this.state.orderBy + ',' + (this.state.direction ? 'asc' : 'desc') + '';
        }
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: generalAuthTokenHeader()
        })
            .then(response => {
                if (response.status === 400) {
                    showNotification = {
                        title: 'Discharge Planner',
                        message: 'Bad response from server',
                        type: "danger"
                    };
                } else if (response.ok) {
                    this.setState({
                        total: response.headers.get('X-Total-Count')
                    })
                } else if (response.status === 401) {
                    userService.logout();
                    //window.location.reload(true);
                    window.location.replace(baseRoutes.login.path);
                }
                else {
                    showNotification = {
                        title: 'Discharge Planner',
                        message: 'Bad response from server',
                        type: "danger"
                    };
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    dischargePlanners: data
                })
            })
            .catch(error => {
                showNotification = {
                    title: 'Discharge Planner',
                    message: 'Bad response from server',
                    type: "danger"
                };
                return response;
            });

        userService.showNotification(showNotification);
        this.state.spinner.setAttribute('hidden', 'true');
    }
    modalOpen = (dataId) => {
        if (!isNaN(dataId)) {
            this.state.dischargePlanners.map(dischargePlanner => {
                if (dischargePlanner.id == dataId) {
                    this.setState({
                        facility: dischargePlanner.facilityId,
                        address: dischargePlanner.user.address,
                        firstName: dischargePlanner.user.firstName,
                        lastName: dischargePlanner.user.lastName,
                        phoneNumber: dischargePlanner.user.phoneNumber,
                        status: dischargePlanner.user.activated,
                        email: dischargePlanner.user.email,
                        dataId: dataId,
                        firstNameValid: true,
                        lastNameValid: true,
                        emailValid: true,
                        phoneNumberValid: true,
                        facilityValid: true
                    });
                }
            });
        } else {
            this.setState({
                facility: '',
                address: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                status: true,
                dataId: '',
                firstNameValid: false,
                lastNameValid: false,
                emailValid: false,
                phoneNumberValid: false,
                facilityValid: false
            })
        }
        this.setState({
            modalStatus: true
        })

    }

    modalClose = () => {
        this.setState({
            modalStatus: false
        })
    };

    confirmModalOpen = (message, dataId, isDelete) => {
        this.setState({
            dataId: dataId,
            confirmModalMessage: message,
            confirmModalStatus: true,
            isDelete: isDelete
        })
    }
    confirmModalClose = () => {
        this.setState({
            confirmModalStatus: false
        })
    }
    async confirmModalSubmit() {

        if (!this.state.isDelete) {
            Promise.all(
                this.state.dischargePlanners.map(dischargePlanner => {
                    if (dischargePlanner.id == this.state.dataId) {
                        const data = {
                            firstName: dischargePlanner.user.firstName,
                            middleName: '',
                            lastName: dischargePlanner.user.lastName,
                            email: dischargePlanner.user.email,
                            address: dischargePlanner.user.address,
                            phoneCode: "+1",
                            phoneNumber: dischargePlanner.user.phoneNumber,
                            activated: !dischargePlanner.user.activated,
                            facility_id: dischargePlanner.facility.facilityId,
                            imageUrl: "string",
                            langKey: "string",
                            login: "1",
                            id: this.state.dataId,
                        };
                        this.updateDischargePlanner(data, true);
                        this.confirmModalClose();
                    }
                })
            )
        } else {
            this.setState({ loading: true });
            let showNotification = {};
            const data = {
                id: this.state.dataId,
            };
            const response = await fetch(apiPath.getDischargePlanners + '/' + this.state.dataId, {
                method: "DELETE",
                headers: generalAuthTokenHeader(),
            })
                .then(response => {
                    if (response.status === 400) {
                        showNotification = {
                            title: 'Deletion',
                            message: "Something went wrong.Please try after sometime.",
                            type: "danger"
                        };
                    } else if (response.status === 204) {
                        showNotification = {
                            title: 'Deletion',
                            message: 'Discharge Planner deleted successfully',
                            type: "success"
                        };
                        this.fetchDischargePlanners();
                    }
                    else if (response.ok) {
                        showNotification = {
                            title: 'Deletion',
                            message: 'Discharge Planner deleted successfully',
                            type: "success"
                        };
                        this.fetchDischargePlanners();
                    } else {
                        showNotification = {
                            title: 'Deletion',
                            message: "Something went wrong.Please try after sometime.",
                            type: "danger"
                        };
                    }
                    userService.showNotification(showNotification);
                    this.confirmModalClose();
                });
        }
        this.setState({ loading: false });
    }
    handleChangeInput = event => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        }, () => { this.validateField(name, value) })
    }

    validateField = (fieldName, fieldValue) => {
        let fieldValidationErrors = this.state.formErrors;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        let emailValid = this.state.emailValid;
        let phoneNumberValid = this.state.phoneNumberValid;
        let facilityValid = this.state.facilityValid;
        switch (fieldName) {
            case 'firstName':
                if (fieldValue.trim().length == 0) {
                    firstNameValid = false;
                    fieldValidationErrors.firstName = enMsg.firstNameRequiredMsg;
                }
                else if (!fieldValue.trim().match(ONLY_AlPHABETS_REGEX)) {
                    firstNameValid = false;
                    fieldValidationErrors.firstName = 'Only alphabets are allowed';
                }
                else {
                    firstNameValid = true;
                    fieldValidationErrors.firstName = '';
                }
                break;
            case 'lastName':
                if (fieldValue.trim().length == 0) {
                    lastNameValid = false;
                    fieldValidationErrors.lastName = enMsg.lastNameRequiredMsg;
                }
                else if (!fieldValue.trim().match(ONLY_AlPHABETS_REGEX)) {
                    lastNameValid = false;
                    fieldValidationErrors.lastName = 'Only alphabets are allowed';
                }
                else {
                    lastNameValid = true;
                    fieldValidationErrors.lastName = '';
                }
                break;
            case 'email':
                if (fieldValue.trim().length == 0) {
                    emailValid = false;
                    fieldValidationErrors.email = enMsg.emailRequiredMsg;
                }
                else if (!fieldValue.trim().match(EMAIL_REGEX)) {
                    emailValid = false;
                    fieldValidationErrors.email = 'Invalid email';
                }
                else {
                    emailValid = true;
                    fieldValidationErrors.email = '';
                }
                break;
            case "phoneNumber":
                    let errMsg = "";
                    const onlyDigits = fieldValue.replace(/[^\d]/g, ""); // Remove all non-digit characters
                    if (onlyDigits.length == 0) {
                        phoneNumberValid = false;
                        errMsg = "Phone number is required.";
                    } else if (!/^\(\d{3}\)\d{3}-\d{4}$/.test(fieldValue) && !/^\d{10}$/.test(onlyDigits)) {
                        phoneNumberValid = false;
                        errMsg = "Invalid number. Use format (111)111-1111 or a 10-digit number.";
                    } else if (onlyDigits.length != 10) {
                        phoneNumberValid = false;
                        errMsg = enMsg.phoneNumberDigit;
                    } else {
                        phoneNumberValid = true;
                    }
                    fieldValidationErrors.phoneNumber = phoneNumberValid ? "" : errMsg;
                    break;
            case 'facility':
                facilityValid = fieldValue > 0;
                fieldValidationErrors.facility = facilityValid ? '' : enMsg.facilityDropDownRequiredMsg;
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            emailValid: emailValid,
            phoneNumberValid: phoneNumberValid,
            facilityValid: facilityValid,
        }, this.validateForm);
    }

    validateForm() {
        return (
            this.state.firstNameValid &&
            this.state.lastNameValid &&
            this.state.emailValid &&
            this.state.phoneNumberValid &&
            this.state.facilityValid
        )
    }

    handleSubmit(event) {
        event.preventDefault();
        let data = {
            firstName: this.state.firstName,
            middleName: '',
            lastName: this.state.lastName,
            email: this.state.email,
            address: this.state.address,
            phoneCode: "+1",
            phoneNumber: this.state.phoneNumber,
            activated: this.state.status,
            facility_id: this.state.facility,
            imageUrl: "string",
            langKey: "string",
            login: "1",
        };
        if (this.state.dataId) {
            data.id = this.state.dataId;
            let isFacilityExist = false;
            this.state.facilities.map(facility => {
                if (facility.facilityId === this.state.facility) {
                    isFacilityExist = true;
                }
            })
            if (isFacilityExist) {
                this.validateField('facility', this.state.facility);
                this.updateDischargePlanner(data, false);
            } else {
                this.validateField('facility', '');
            }
        } else {
            this.createDischargePlanner(data);
        }
    }
    async createDischargePlanner(data) {
        this.setState({ loading: true });
        let showNotification = {};
        let modalCloseStatus = 1;
        const response = await fetch(apiPath.getDischargePlanners, {
            method: "POST",
            headers: generalAuthTokenHeader(),
            body: JSON.stringify(data),
            data: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 400) {
                    showNotification = {
                        title: 'Discharge Planner',
                        message: enMsg.badResponseFromServer,
                        type: "danger"
                    };
                } else if (response.ok) {
                } else {
                    showNotification = {
                        title: 'Discharge Planner',
                        message: enMsg.badResponseFromServer,
                        type: "danger"
                    };
                }
                return response.json();
            })
            .then(data => {
                if (data.status == '400' && data.message == 'error.idexists') {
                    modalCloseStatus = 0;
                    showNotification = {
                        title: "Discharge Planner",
                        message: data.title,
                        type: 'danger'
                    };
                } else if (data.id) {
                    showNotification = {
                        title: "Success",
                        message: "Discharge Planner created successfully",
                        type: "success"
                    };
                    this.fetchDischargePlanners();
                    return true;
                }
            })
            .catch(error => {
                showNotification = {
                    title: 'Discharge Planner',
                    message: enMsg.clientSideError,
                    type: "danger"
                };
            });
        userService.showNotification(showNotification);
        if (modalCloseStatus) {
            this.modalClose();
        }
        this.setState({ loading: false });
    }
    async updateDischargePlanner(data, isOnlyStatusChange) {
        this.setState({ loading: true });
        let showNotification = {};
        let modalCloseStatus = 1;
        const response = await fetch(apiPath.getDischargePlanners, {
            method: "PUT",
            headers: generalAuthTokenHeader(),
            body: JSON.stringify(data),
            data: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 400) {
                    showNotification = {
                        title: 'Discharge Planner',
                        message: 'Bad response from server',
                        type: "danger"
                    };
                } else if (response.ok) {
                } else {
                    showNotification = {
                        title: 'Discharge Planner',
                        message: 'Bad response from server.',
                        type: "danger"
                    };
                }
                return response.json();
            })
            .then(data => {
                if (data.status == '400' && data.message == 'error.idexists') {
                    modalCloseStatus = 0;
                    showNotification = {
                        title: "Discharge Planner",
                        message: data.title,
                        type: 'danger'
                    };
                } else if (data.id) {
                    showNotification = {
                        title: "Success",
                        message: (isOnlyStatusChange ? "Status updated successfully" : "Discharge Planner updated successfully"),
                        type: "success"
                    };
                    this.fetchDischargePlanners(this.state.searchKeyword);
                    return true;
                }
            })
            .catch(error => {
                showNotification = {
                    title: 'Discharge Planner',
                    message: 'Something went wrong.Please try after sometime..',
                    type: "danger"
                };
            });
        userService.showNotification(showNotification);
        if (modalCloseStatus) {
            this.modalClose();
        }
        this.setState({ loading: false });
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            currentPage: newPage,
        }, () => this.fetchDischargePlanners());
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({
            perPage: parseInt(event.target.value),
            currentPage: 0
        }, () => this.fetchDischargePlanners(this.state.searchKeyword));
    };
    tableSortHandler = (property) => (event) => {
        this.setState({
            orderBy: property,
            direction: !this.state.direction
        }, () => this.fetchDischargePlanners(this.state.searchKeyword))
    }
    search(event) {
        let searchKeyword = event.target.value.trim();
        if (searchKeyword.length > 0) {
            this.setState({ currentPage: 0 }, () => this.fetchDischargePlanners(searchKeyword));
        } else {
            this.fetchDischargePlanners();
        }
        this.setState({ searchKeyword: searchKeyword });
        return true;
    }
    render() {
        return (
            <div id='discharge-planner-list'>
                <ConfirmModal
                    title='Alert'
                    message={this.state.confirmModalMessage}
                    confirmModalStatus={this.state.confirmModalStatus}
                    confirmModalClose={this.confirmModalClose}
                    submit={this.confirmModalSubmit}
                    loading={this.state.loading}
                />
                <Dialog
                    open={this.state.modalStatus}
                    maxWidth={'sm'}
                    onClose={this.modalClose}
                    TransitionComponent={Transition}
                    aria-labelledby="discharge-planner-dialog-title"
                    className="add-modal"
                >
                    <DialogTitle className="add-modal-title" id="discharge-planner-dialog-title">
                        {this.state.dataId ? 'Edit Discharge Planner' : 'Add Discharge Planner'}
                        <IconButton className="closeButton" aria-label="close" onClick={this.modalClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <form onSubmit={this.handleSubmit} noValidate>
                        <DialogContent >

                            <Box display="flex" justifyContent="flex-start" mb={2} >
                                <Box pr={1} width={1 / 2} >
                                    <TextField
                                        InputLabelProps={{ className: "required-label" }}
                                        label='First Name'
                                        name='firstName'
                                        value={this.state.firstName}
                                        onChange={this.handleChangeInput}
                                        inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                                    />
                                    <FormErrors
                                        show={!this.state.firstNameValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="firstName"
                                    />
                                </Box>
                                <Box pl={1} width={1 / 2} >
                                    <TextField
                                        InputLabelProps={{ className: "required-label" }}
                                        label='Last Name'
                                        name='lastName'
                                        value={this.state.lastName}
                                        onChange={this.handleChangeInput}
                                        inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                                    />
                                    <FormErrors
                                        show={!this.state.lastNameValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="lastName"
                                    />
                                </Box>
                            </Box>

                            <Box display="flex" justifyContent="flex-start" mb={2} >
                                <Box pr={1} width={1 / 2}>
                                    <TextField
                                        InputLabelProps={{ className: "required-label" }}
                                        label='Email Address'
                                        name='email'
                                        value={this.state.email}
                                        onChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.emailValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="email"
                                    />
                                </Box>
                                <Box pl={1} width={1 / 2} >
                                    <TextField
                                        InputLabelProps={{ className: "required-label" }}
                                        label='Phone Number'
                                        name='phoneNumber'
                                        value={this.state.phoneNumber}
                                        onChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.phoneNumberValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="phoneNumber"
                                    />
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="flex-start" mb={2} >
                                <Box pr={1} width={1 / 2}>
                                    <InputLabel htmlFor="facility-select-label" className="required-label">Facility</InputLabel>
                                    <Select
                                        value={(this.state.facility ? this.state.facility : '')}
                                        labelProps={{ className: "required-label" }}
                                        onChange={this.handleChangeInput}
                                        inputProps={{
                                            name: 'facility',
                                            id: 'facility-select-label',
                                        }}
                                        displayEmpty
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 250
                                                }
                                            },
                                            variant: "menu",
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value="">Select Facility</MenuItem>
                                        {this.state.facilities.length
                                            ? this.state.facilities.map(facility =>
                                                <MenuItem value={facility.facilityId}>{facility.facility_name}</MenuItem>
                                            ) : <MenuItem value=' '>Not Available</MenuItem>}
                                    </Select>
                                    <FormErrors
                                        show={!this.state.facilityValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="facility"
                                    />
                                </Box>
                                <Box pl={1} width={1 / 2}>
                                    <SelectStatus
                                        id='status-select-label'
                                        value={this.state.status}
                                        name='status'
                                        handleChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.accessValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="status"
                                    />
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions className="modal-actions" justify="center" >
                            <Button type="submit" className="btn1" disabled={!this.validateForm() || this.state.loading}>
                                {this.state.loading && (
                                    <CircularProgress
                                        size={24}
                                        className="buttonProgress"
                                    />
                                )}
                                {this.state.dataId ? 'Update Discharge Planner' : 'Add Discharge Planner'}
                            </Button>
                            <Button onClick={this.modalClose} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                        <div className="note-text" style={{ display: "none" }}>NOTE : On Adding Discharge Planner,  system will send him an email with Login details</div>
                    </form>
                </Dialog>
                <GridContainer>
                    <Card>
                        <CardHeader color="success" className={"cusCardTitle"}>
                            <h4>Discharge Planner List</h4>
                        </CardHeader>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="search-btn-cover">
                                <input
                                    type="text"
                                    title="Search by name/facility/phone."
                                    placeholder="Search by name/facility/phone."
                                    className="search-btn"
                                    onChange={this.search}
                                />
                                <i className="material-icons">search</i>
                            </div>
                            <div className="button-cover">
                                <button className="pointer btn1" onClick={this.modalOpen}>Add Discharge Planner</button>
                            </div>
                        </GridItem>
                        <CardBody  >
                            <div className="tableResponsive">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align={headCell.numeric ? 'right' : 'left'}
                                                    padding={headCell.disablePadding ? 'none' : 'default'}
                                                >{headCell.sorting ?
                                                    (<TableSortLabel
                                                        active={this.state.orderBy == headCell.id}
                                                        direction={(this.state.direction ? 'asc' : 'desc')}
                                                        onClick={this.tableSortHandler(headCell.id)}
                                                    >{headCell.label}
                                                    </TableSortLabel>) :
                                                    headCell.label
                                                    }
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.dischargePlanners.length
                                            ? this.state.dischargePlanners.map(dischargePlanner =>
                                                <TableRow key={dischargePlanner.id}>
                                                    <TableCell>{dischargePlanner.id}</TableCell>
                                                    <TableCell>{dischargePlanner.user.firstName} {dischargePlanner.user.middleName} {dischargePlanner.user.lastName}</TableCell>
                                                    <TableCell>{dischargePlanner.facility.facility_name}</TableCell>
                                                    <TableCell>
                                                    {(() => {
                                                    const onlyDigits = dischargePlanner.user.phoneNumber.replace(/[^\d]/g, ""); // Remove all non-digit characters
                                                    if (onlyDigits.length === 10) {
                                                        return `(${onlyDigits.slice(0, 3)}) ${onlyDigits.slice(3, 6)}-${onlyDigits.slice(6)}`;
                                                    } else {
                                                        return "";
                                                    }
                                                    })()}
                                                    </TableCell>
                                                    <TableCell>
                                                        <SwitchToggle
                                                            id={dischargePlanner.id}
                                                            status={dischargePlanner.user.activated}
                                                            clickHandler={() => this.confirmModalOpen('Do you want to change the status?', dischargePlanner.id, false)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="edit-action" onClick={() => this.modalOpen(dischargePlanner.id)}>
                                                            <Icon className="fa fa-pencil-square-o" aria-hidden="true" />
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            :
                                            <TableRow>
                                                <TableCell colspan='7'>
                                                    <div className="not-found-text">Discharge planners not found</div>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={PER_PAGE_OPTIONS}
                                component="div"
                                count={parseInt(this.state.total)}
                                rowsPerPage={this.state.perPage}
                                page={this.state.currentPage}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </CardBody>
                    </Card>
                </GridContainer>
            </div>
        )
    }
}

export default DischargePlannerIndex
