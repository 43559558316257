import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

import "perfect-scrollbar/css/perfect-scrollbar.css";

import withStyles from "@material-ui/core/styles/withStyles";

import Navbar from "components/Navbars/Navbar.jsx";

import Sidebar from "components/Sidebar/Sidebar.jsx";

import { dpRoutes } from "admin-routes.js";
import { projectAssets } from "base-routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import {
  DEFAULT_PROFILE_IMG,
  NO_USERNAME,
  FACILITY_NAME,
} from "__helpers/constants";
import "react-notifications-component/dist/theme.css";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import { userService } from "_services/user.service";
import moment from "moment";
import GlobalApi from "components/Sidebar/GlobalApis";

// custom css
import "assets/css/frontui.css?v=1.0.0";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.saveDataToLocalStorage = this.saveDataToLocalStorage.bind(this);

    let userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let selectedAdnetwork = JSON.parse(
      localStorage.getItem(SELECTED_ADNETWORK)
    );
    let profileImage = DEFAULT_PROFILE_IMG;
    let facilityName = FACILITY_NAME;
    if (userDetail && userDetail.imageRef) {
      profileImage = userDetail.imageRef;
    }
    let tokenTimeStamp = localStorage.getItem("tokenTimeStamp");
    var dateTimeVal = new Date(+tokenTimeStamp);
    var validDateBoolean = moment(dateTimeVal);
    if (!validDateBoolean.isValid()) {
      // userService.logout();
    }
    let username =
      userDetail && userDetail.firstName
        ? `${userDetail.firstName} ${userDetail.lastName}`
        : NO_USERNAME;

    facilityName =
      userDetail && userDetail.facilityName
        ? `${userDetail.facilityName}`
        : NO_USERNAME;

    this.state = {
      userDetail: {
        profileImage: profileImage,
        username: username,
        facilityName: facilityName,
      },
      selectedAdnetwork: selectedAdnetwork ? selectedAdnetwork : [],
      localStorageData: {},
      hasError: "",
      error: "",
      errorInfo: "",
      time: {},
      seconds: 1500,
      tokenTimeStamp: tokenTimeStamp,
    };

    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  state = {
    image: projectAssets.blur_image,
    color: "blue",
    hasImage: true,
    fixedClasses: "dropdown show",
    mobileOpen: false,
    loggedIn: false,
  };

  adminRoutes = {};
  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  saveDataToLocalStorage = (storageName) => {};
  updateUserInfo = (userDetail) => {
    let username = NO_USERNAME;
    let facilityName = FACILITY_NAME;
    let profileImage = DEFAULT_PROFILE_IMG;
    if (!userDetail) {
      const userDetail = JSON.parse(localStorage.getItem("userDetail"));
      username =
        userDetail && userDetail.firstName
          ? `${userDetail.firstName} ${userDetail.lastName}`
          : NO_USERNAME;
      facilityName =
        userDetail && userDetail.facilityName
          ? `${userDetail.facilityName}`
          : NO_USERNAME;
      if (userDetail.imageRef) {
        profileImage = userDetail.imageRef;
      }
    } else {
      if (userDetail && userDetail.profileImage) {
        profileImage = userDetail.profileImage;
      }

      username =
        userDetail && userDetail.firstName
          ? `${userDetail.firstName} ${userDetail.lastName}`
          : NO_USERNAME;
      facilityName =
        userDetail && userDetail.facilityName
          ? `${userDetail.facilityName}`
          : NO_USERNAME;
    }
    this.setState({
      userDetail: {
        profileImage: profileImage,
        username: username,
        facilityName: facilityName,
      },
    });
  };

  mainPanel = React.createRef();
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return window.location.pathname !== "/admin/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  countDown() {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    let currentTimeStamp = new Date().getTime();
    if (currentTimeStamp - this.state.tokenTimeStamp > 1700000) {
      // userService.logout();
      clearInterval(this.timer);
      // window.location.reload(true);
    }
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.resizeFunction);
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }

  componentDidCatch(error, errorInfo) {
    this._isMounted &&
      this.setState({
        hasError: true,
        error: error,
        errorInfo: errorInfo,
      });
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.mainPanel && this.mainPanel.current) {
        this.mainPanel.current.scrollTop = 0;
      }
      if (this.state.mobileOpen) {
        this._isMounted && this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.resizeFunction);
    Object.getPrototypeOf(this).constructor.STATE = this.state;
  }
  render() {
    const { classes, match, ...rest } = this.props;
    const {
      mobileOpen,
      color,
      userDetail,
      hasError,
      localStorageData,
      selectedAdnetwork,
    } = this.state;
    return (
      <div
        className={classes.wrapper + " front-main-layout bg-new"}
        style={{ backgroundColor: "rgb(239, 235, 235)" }}
      >
        <div className="display-none storage-observation">no change</div>
        <GlobalApi />
        <div
          className={classes.mainPanel + " ProviderHeader"}
          ref={this.mainPanel}
          style={{ width: "100%" }}
        >
          <Navbar
            logoText={projectAssets.brandShortName}
            logo={projectAssets.logo}
            role="dp"
            routes={dpRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            updateUserInfo={this.updateUserInfo}
            userDetail={userDetail}
            sendAdnetworkData={this.sendAdnetworkData}
            {...rest}
          />
          {this.getRoute() && !hasError ? (
            <div className={classes.content + " front-layout-cover"}>
              <div className={classes.container}>
                {" "}
                {
                  <Switch>
                    {dpRoutes.map((prop, key) => {
                      return (
                        <Route
                          path={prop.basePath + prop.path}
                          key={key}
                          render={(props) => (
                            <prop.component
                              {...props}
                              updateUserInfo={this.updateUserInfo}
                              key={this.props.location.key}
                            />
                          )}
                        ></Route>
                      );
                    })}
                    {<Redirect from="/admin" to="/admin/dashboard" />}
                  </Switch>
                }
              </div>
            </div>
          ) : (
            "Error During"
          )}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  userDetail: PropTypes.object,
};

export default withStyles(dashboardStyle)(Dashboard);
