import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
} from "@material-ui/core";
import { FormErrors } from "../../../components/Login/FormErrors";
import enMsg from "__helpers/locale/en/en";
import Tooltip from "@material-ui/core/Tooltip";
import {
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
  MAX_INPUT_LENGTH,
  MAX_INPUT_LENGTH_LONG,
  PRIORITY_LIST,
  TASK_STATUS,
} from "__helpers/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormGroup, FormControl, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";
import { LocalizedUtils, UTCUtils } from "__helpers/util";
import Chip from "@material-ui/core/Chip";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import infoIcon from "assets/img/icons/info-icon.svg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";

import { apiPath } from "api";
import {
  generalAuthTokenHeader,
  multimediaAuthTokenHeader,
} from "__helpers/auth-header";
import { userService } from "_services/user.service";
import { PATIENT_LIST } from "__helpers/constants";
import { formatDateToServerFormat } from "__helpers/util";
import { ALLOWED_PROFILE_IMAGE_SIZE } from "__helpers/constants";
import { ALLOWED_IMAGE_EXTENTION } from "__helpers/constants";
import { ALLOWED_FACESHEET_SIZE } from "__helpers/constants";
import { ALLOWED_FACESHEET_EXTENTION } from "__helpers/constants";
import Files from "react-files";
import { ReactUTCDatepicker } from "react-utc-datepicker";
import { formatDateToLocalFormat } from "__helpers/util";
import { localDateFormat } from "__helpers/util";
import { localServerFormat } from "__helpers/util";
import { localServerFormats } from "__helpers/util";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
//moment.tz.setDefault("UTC");
export class PatientTasksClass extends Component {
  inputOpenImageFileRef = React.createRef(null);
  inputOpenFaceSheetFileRef = React.createRef(null);
  maxDate = new Date();
  minDate = new Date();
  constructor(props) {
    super(props);
    this.dateUtility = new DateFnsUtils();
    this.state = {
      formErrors: {
        taskName: "",
        priority: "",
        description: "",
        changeStatus: "",
        notesStatus: "",
        dueDate: "",
      },
      taskNameValid: false,
      priorityValid: true,
      descriptionValid: true,
      changeStatusValid: true,
      notesStatusValid: true,
      dueDateValid: true,
      taskName: "",
      priority: "2",
      description: "",
      changeStatus: "4",
      notesStatus: "",
      gender: "",
      dueDate: null,
      patientDetail: this.props.patientDetail,
      patientModalStatus: this.props.openTaskModal,
      patientTaskId: this.props.patientTaskId,
      taskUpdate: false,
    };
    this.handleDueDate = this.handleDueDate.bind(this);
    this.updateTasks = this.updateTasks.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchTasks = this.fetchTasks.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.patientDetail && nextProps.patientDetail.patientId) {
      this.setState({
        patientDetail: nextProps.patientDetail,
        patientTaskId: nextProps.patientTaskId,
        taskName: "",
        priority: "2",
        description: "",
        changeStatus: "4",
        notesStatus: "",
        gender: "",
        dueDate: null,
      });
      if (nextProps.patientTaskId) {
        this.setState({
          dueDate: null,
          taskNameValid: true,
          priorityValid: true,
          descriptionValid: true,
          changeStatusValid: true,
          notesStatusValid: true,
          dueDateValid: true,
        });
      } else {
        this.setState({
          taskNameValid: false,
          priorityValid: true,
          descriptionValid: true,
          changeStatusValid: true,
          notesStatusValid: true,
          dueDateValid: true,
        });
      }
      if (nextProps.openTaskModal) {
        if (nextProps.patientTaskId && nextProps.patientTaskId != "") {
          this.setState(
            {
              taskUpdate: true,
            },
            () => this.fetchTasks()
          );
        } else {
          this.setState({
            taskUpdate: false,
          });
        }
      }
    }
  }
  componentDidMount() {
    this.setState({
      taskName: "",
      priority: "",
      description: "",
      changeStatus: "",
      notesStatus: "",
      gender: "",
      dueDate: null,
    });
  }
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let addressValid = this.state.addressValid;
    let statusValid = this.state.statusValid;

    let taskNameValid = this.state.taskNameValid;
    let priorityValid = this.state.priorityValid;
    let descriptionValid = this.state.descriptionValid;
    let genderValid = this.state.genderValid;
    let changeStatusValid = this.state.changeStatusValid;
    let notesStatusValid = this.state.notesStatusValid;
    let dueDateValid = this.state.dueDateValid;
    let estDateValid = this.state.estDateValid;
    let patientCategotiesValid = this.state.patientCategotiesValid;

    switch (fieldName) {
      case "taskName":
        taskNameValid = fieldValue.trim().length > 0;
        fieldValidationErrors.taskName = taskNameValid
          ? ""
          : enMsg.taskNameRequiredMsg;
        break;
      case "description":
        //descriptionValid = (fieldValue && fieldValue.trim().length > 0) || fieldValue == "" ? true : false;
        //fieldValidationErrors.description = descriptionValid ? '' : enMsg.descriptionRequiredMsg;
        break;
      case "priority":
        priorityValid = fieldValue == "" ? false : true;
        fieldValidationErrors.priority = priorityValid
          ? ""
          : enMsg.priorityRequiredMsg;
        break;
      case "changeStatus":
        changeStatusValid = fieldValue == "" ? false : true;
        fieldValidationErrors.changeStatus = !changeStatusValid
          ? enMsg.changeStatusRequiredMsg
          : "";
        break;
      case "notesStatus":
        //notesStatusValid = (fieldValue || fieldValue == "") ? true : false;
        //fieldValidationErrors.notesStatus = !notesStatusValid
        // ? enMsg.notesStatusRequiredMsg
        // : "";
        break;
      case "dueDate":
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        taskNameValid: taskNameValid,
        priorityValid: priorityValid,
        descriptionValid: descriptionValid,
        changeStatusValid: changeStatusValid,
        notesStatusValid: notesStatusValid,
        dueDateValid: dueDateValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return (
      this.state.taskNameValid &&
      this.state.priorityValid &&
      this.state.descriptionValid &&
      this.state.changeStatusValid &&
      this.state.notesStatusValid &&
      this.state.dueDateValid
    );
  }
  async fetchTasks() {
    this.setState({
      loading: true,
    });
    let showNotification = {};
    let url = apiPath.patientTasks + "/" + this.state.patientTaskId;
    const response = await fetch(url, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.ok) {
        } else {
          showNotification = {
            title: "Task Fetch",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.id) {
          let localDueDateMoment =  data.dueDate
           ? moment
          .utc(data.dueDate, "YYYY-MM-DD")
          .format("MM/DD/YYYY")
      : null;
          this.setState({
            loading: false,
            taskName: data.taskName,
            priority: data.taskPriority,
            description: data.taskDescription,
            changeStatus: data.changeStatus,
            notesStatus: data.statusDescription,
            dueDate:localDueDateMoment,
            patientTaskId: data.id,
          });
        } else {
          showNotification = {
            title: "Task Fetch",
            message: "Bad response from server",
            type: "danger",
          };
          this.props.updatePatientModalClose();
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Task Fetch",
          message: "Bad response from server",
          type: "danger",
        };

        this.setState({
          loading: false,
        });
        return response;
      });
    userService.showNotification(showNotification);
  }
  handleSubmit = (event) => {
    this.setState({
      loading: true,
    });
    event.preventDefault();
    this.updateTasks();
  };
  async updateTasks() {
    let showNotification = {};
    this.props.loaderStatus(true);
    let localDueDateMoment = null;
    // if (this.state.dueDate) {
    //   localDueDateMoment = this.dateUtility.format(
    //     new Date(this.state.dueDate),
    //     "yyyy-MM-dd"
    //   );
    // }
   
    localDueDateMoment = this.state.dueDate
    ? this.dateUtility.format(new Date(this.state.dueDate), "yyyy-MM-dd")
    : null;

    const data = {
      patientId: this.props.patientDetail.patientId,
      taskName: this.state.taskName,
      dueDate: localDueDateMoment,
      changeStatus: this.state.changeStatus,
      statusDescription: this.state.notesStatus,
      taskPriority: this.state.priority,
      taskDescription: this.state.description,
    };
    let methodType = "POST";
    if (this.state.taskUpdate && this.state.patientTaskId) {
      data.id = this.state.patientTaskId;
      methodType = "PUT";
    }
    const response = await fetch(apiPath.patientTasks, {
      method: methodType,
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.taskFailedTitle,
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          showNotification = {
            title: this.state.taskUpdate
              ? enMsg.taskUpdateTitle
              : enMsg.taskCreateTitle,
            message: this.state.taskUpdate
              ? enMsg.taskUpdateDes
              : enMsg.taskCreateDes,
            type: "success",
          };
          this.props.updatePatient();
          this.props.updatePatientModalClose();
        } else {
          showNotification = {
            title: enMsg.taskFailedTitle,
            message: "Bad response from server",
            type: "danger",
          };
        }
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        this.props.loaderStatus(false);
        if (data.status == 400) {
          showNotification = {
            title: enMsg.taskFailedTitle,
            message: "Bad response from server",
            type: "danger",
          };
        }
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.taskFailedTitle,
          message: "Bad response from server",
          type: "danger",
        };
        this.props.loaderStatus(false);
      });
    userService.showNotification(showNotification);
    this.setState({ loading: false });
  }
  handleDueDate = (value) => {
    let dueDate = true;
    if (value && !this.dateUtility.isValid(value)) {
      dueDate = false;
    }

    this.setState(
      {
        dueDate: value,
        dueDateValid: dueDate,
      },
      () => {
        this.validateField("dueDate", value);
      }
    );
  };
  handleDueDateError = (error, value) => {
    /* this.setState({
      dueDateValid: error ? false : true,
    }); */
  };
  render() {
    return (
      <Dialog
        open={this.props.openTaskModal}
        maxWidth={"sm"}
        onClose={this.props.updatePatientModalClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        className="add-modal front-modal "
      >
        <DialogTitle className="add-modal-title" id="form-dialog-title">
          Task
          <IconButton
            className="closeButton"
            aria-label="close"
            onClick={this.props.updatePatientModalClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {this.state.loading && <LinearProgress size={24} />}
        <form onSubmit={this.handleSubmit} noValidate>
          <DialogContent>
            <div className="task-for">
              {this.props.patientDetail.lastName +
                ", " +
                this.props.patientDetail.firstName}
            </div>
            <Box display="flex" justifyContent="flex-start" mb={1} mt={1}>
              <Box width={1 / 2} pr={2}>
                <TextField
                  InputLabelProps={{ className: "required-label" }}
                  name="taskName"
                  label="Task Name"
                  value={this.state.taskName}
                  onChange={this.handleChangeInput}
                  inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                />
                <FormErrors
                  show={!this.state.taskNameValid}
                  formErrors={this.state.formErrors}
                  fieldName="taskName"
                />
              </Box>
              <Box width={1 / 2} pl={2}>
                <FormControl variant="outlined">
                  <MuiPickersUtilsProvider
                    variant="outlined"
                    utils={DateFnsUtils}
                    moment={moment}
                  >
                    <KeyboardDatePicker
                      label="Due Date"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="start-date-picker-dialog"
                      name="dueDate"
                      animateYearScrolling={true}
                      value={this.state.dueDate}
                      minDateMessage={enMsg.taskMinDate}
                      onChange={this.handleDueDate}
                      onError={this.handleDueDateError}
                      className="KeyboardDatePicker"
                      invalidDateMessage={enMsg.invalidDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        className: "date-picker-span",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <FormErrors
                    show={!this.state.dueDateValid}
                    formErrors={this.state.formErrors}
                    fieldName="dueDate"
                  />
                </FormControl>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start" mb={1}>
              <Box width={1 / 2} pr={2}>
                <FormControl>
                  <InputLabel className={"required-label"}>Priority</InputLabel>
                  <Select
                    id="priority"
                    color="secondary"
                    name="priority"
                    value={this.state.priority}
                    onChange={this.handleChangeInput}
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    {PRIORITY_LIST
                      // .sort((a, b) => a.insuranceId > b.insuranceId ? 1 : -1)
                      .map((pList, key) => {
                        return (
                          <MenuItem key={key} value={pList.id}>
                            {pList.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormErrors
                    show={!this.state.priorityValid}
                    formErrors={this.state.formErrors}
                    fieldName="priority"
                  />
                </FormControl>
              </Box>
              <Box width={1 / 2} pl={2}>
                <TextField
                  label="Description"
                  rows={4}
                  multiline={true}
                  fullWidth={true}
                  aria-label="validators"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChangeInput}
                  inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                  className="comment-TextField"
                />
                <FormErrors
                  show={!this.state.descriptionValid}
                  formErrors={this.state.formErrors}
                  fieldName="description"
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start" mb={1}>
              <Box width={1 / 2} pr={2}>
                <FormControl>
                  <InputLabel className={"required-label"}>
                    Change Status
                  </InputLabel>
                  {/* <InputLabel>Insurance</InputLabel> */}
                  <Select
                    id="changeStatus"
                    color="secondary"
                    name="changeStatus"
                    value={this.state.changeStatus}
                    onChange={this.handleChangeInput}
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    {TASK_STATUS
                      // .sort((a, b) => a.insuranceId > b.insuranceId ? 1 : -1)
                      .map((pList, key) => {
                        return (
                          <MenuItem key={key} value={pList.id}>
                            {pList.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormErrors
                    show={!this.state.changeStatusValid}
                    formErrors={this.state.formErrors}
                    fieldName="changeStatus"
                  />
                </FormControl>
              </Box>
              <Box width={1 / 2} pl={2}>
                <TextField
                  value={this.state.notesStatus}
                  label="Status Change Notes"
                  rows={4}
                  multiline={true}
                  fullWidth={true}
                  aria-label="validators"
                  name="notesStatus"
                  onChange={this.handleChangeInput}
                  inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                  className="comment-TextField"
                />
                <FormErrors
                  show={!this.state.notesStatusValid}
                  formErrors={this.state.formErrors}
                  fieldName="notesStatus"
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className="modal-actions" justify="center">
            <Button
              type="submit"
              className="btn1"
              disabled={!this.validateForm() || this.state.loading}
            >
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  className="buttonProgress"
                  color="secondary"
                />
              )}
              {this.state.taskUpdate ? "Update Task" : "New Task"}
            </Button>
            <Button
              onClick={this.props.updatePatientModalClose}
              className="cancel-link"
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
export const PatientTasks = connect(mapStateToProps)(PatientTasksClass);
export default PatientTasks;
