import React from 'react'
import {
    MenuItem,
    InputLabel,
    Select,
} from "@material-ui/core";

function selectStatus(props) {
    const { id, value, name, handleChange} = props;
    return (
       <div>
            <InputLabel id={id}>Status</InputLabel>
            <Select
                labelid={id}
                value={value}
                name={name}
                onChange={handleChange}
            >
                <MenuItem value='true'>Active</MenuItem>
                <MenuItem value='false'>In Active</MenuItem>
            </Select>
       </div>
    )
}

export default selectStatus
