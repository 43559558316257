import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const formatDate = (date) => {
  return moment(date)
    .local()
    .format("YYYY-MM-DD");
};

const getMonthFirstAndLastDate = (fromDate) => {
  var firstDay = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
  var lastDay = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
  return [formatDate(firstDay), formatDate(lastDay)];
};

const getPreviousMonthFirstDateAndNextMonthLastDate = (fromDate) => {
  var firstDay = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, 1);
  var lastDay = new Date(fromDate.getFullYear(), fromDate.getMonth() + 2, 0);

  return [formatDate(firstDay), formatDate(lastDay)];
};

const getWeek = (fromDate) => {
  var date = new Date(fromDate.getTime());
  var sunday = new Date(date.setDate(date.getDate() - date.getDay())),
    result = [new Date(sunday)];
  while (sunday.setDate(sunday.getDate() + 1) && sunday.getDay() !== 0) {
    result.push(new Date(sunday));
  }
  return result;
};

const utcToLocal = (date) => {
  return new Date(
    moment.utc(new Date(date + "T00:00:00Z")).format("YYYY-MM-DD")
  );
};

const getEventDate = (events) => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  return events?.map((value) => {
    return moment(value.date)
      .add(offset, "minutes")
      .toDate();
  });
};

// eslint-disable-next-line react/prop-types
export default function SideBar({
  seletedDate,
  setSelectedDate,
  calendarApi,
  isMontly,
  isWeekly,
  setIsMontly,
  setIsWeekly,
  events,
  filter,
  fetchCalendarData,
  patientIds,
}) {
  const [eventDate, setEventsDate] = useState([]);

  useEffect(() => {
    const [startDate, endDate] = getMonthFirstAndLastDate(seletedDate);
    fetchCalendarData({
      endDate,
      startDate,
    }).then((response) => {
      const events = getEventDate(response.data);
      setEventsDate(events);
    });
  }, [seletedDate, filter, patientIds]);

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-week": getWeek(seletedDate),
    },
    // {
    //   "react-datepicker__day--highlighted": events?.map((value) => value.date),
    // },
  ];

  const highlightEvents = [
    {
      "react-datepicker__day--highlighted-event": eventDate,
    },
    // {
    //   "react-datepicker__day--highlighted": events?.map((value) => value.date),
    // },
  ];

  return (
    <div>
      <DatePicker
        inline
        selected={seletedDate}
        onChange={(dt) => {
          const date = new Date(dt.getTime());
          setSelectedDate(date);
          //  console.log(calendarApi);
          calendarApi.gotoDate(dt);
        }}
        highlightDates={
          isWeekly
            ? [...highlightWithRanges, ...highlightEvents]
            : highlightEvents
        }
        onMonthChange={(event) => {
          //debugger;
          const date = new Date(event.getTime());

          // add a day
          date.setDate(1);
          setSelectedDate(date);
          //  console.log(calendarApi);
          calendarApi.gotoDate(event);
          const [
            startDate,
            endDate,
          ] = getPreviousMonthFirstDateAndNextMonthLastDate(event);
          fetchCalendarData({
            endDate,
            startDate,
          }).then((response) => {
            const events = getEventDate(response.data);
            setEventsDate(events);
          });
        }}

        // // onChange={(date) => setStartDate(date)}
        // portalId="root-portal"
        // className="form-control p-0 border-0 shadow-none"
        // placeholderText="Select"
      />
    </div>
  );
}
