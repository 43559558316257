/*eslint-disable*/
import React from "react";
import ReactDOM from "react-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

import { FormErrors } from "../../../components/Login/FormErrors";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Box,
  TextField,
  Avatar,
  Checkbox,
  LinearProgress,
} from "@material-ui/core";

import { providerBaseRoutes } from "base-routes";
import { userService } from "_services/user.service";

import {
  EMAIL_REGEX,
  ONLY_NUMBER_REGEX,
  ONLY_AlPHABETS_REGEX,
  URL_PATTERN,
  MAX_INPUT_LENGTH_LONG_TEXT,
} from "__helpers/constants";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";
import enMsg from "__helpers/locale/en/en";

import { apiPath } from "api";

import { USER_INFO, ZIP_CODE_LENGTH, DIGIT_ONLY } from "__helpers/constants";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import { basePath } from "base-routes";
const mat_select = "material-select disabled-dropdown-black";
const cust_label = "custom-label";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF",
    },
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  disabledDropdownBlack: {
    color: "#000 !important",
  },
};

class OrganizationDetails extends React.Component {
  maxDate = new Date();
  minDate = new Date();
  constructor(props) {
    super(props);
    this.dateUtility = new DateFnsUtils();
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    this.addressStreetInputRef = React.createRef();
    this.state = {
      organizationalDetails: "",
      stateList: "",
      addressStreet: "",
      addressSuite: "",
      city: "",
      dateEstablishment: null,
      email: "",
      fax: "",
      orgName: "",
      phone: "",
      state: "",
      websiteUrl: "",
      zipcode: "",
      primaryEmail: "",
      primaryFirstName: "",
      primaryLastName: "",
      primaryPhone: "",
      userid: "",
      providerId: "",
      oldUserId: "",
      providerAboutUs: "",
      latitude: "",
      longitude: "",
      formErrors: {
        addressStreet: "",
        addressSuite: "",
        city: "",
        dateEstablishment: "",
        email: "",
        fax: "",
        orgName: "",
        phone: "",
        state: "",
        websiteUrl: "",
        zipcode: "",
        primaryEmail: "",
        primaryFirstName: "",
        primaryLastName: "",
        primaryPhone: "",
        userid: "",
        providerAboutUs: "",
        latitude: "",
        longitude: "",
      },
      addressStreetValid: false,
      addressSuiteValid: true,
      cityValid: false,
      dateEstablishmentValid: false,
      emailValid: false,
      faxValid: true,
      orgNameValid: false,
      phoneValid: false,
      stateValid: false,
      websiteUrlValid: true,
      zipcodeValid: false,
      primaryEmailValid: true,
      primaryFirstNameValid: false,
      primaryLastNameValid: false,
      primaryPhoneValid: false,
      useridValid: true,
      providerAboutUsValid: true,
      remainingCount: MAX_INPUT_LENGTH_LONG_TEXT,
      userInfo: userInfo,
      latitudeValid: false,
      longitudeValid: false,
    };
  }
  componentDidMount() {
    this.getStateList();
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    this.setState(
      {
        providerId: userInfo.provider.id,
      },
      () => {
        this.fetchOrganizationalDetail();
      }
    );

    const autocomplete = new window.google.maps.places.Autocomplete(
      this.addressStreetInputRef.current
    );
    // Add event listener for place selection
    autocomplete.addListener("place_changed", () => {
      this.handlePlaceSelection(autocomplete);
    });

    // Store the Autocomplete object in the component's state
    this.setState({ addressAutocomplete: autocomplete });
  }
  handlePlaceSelection = (autocomplete) => {
    // Get the selected place details from the Autocomplete object
    const selectedPlace = autocomplete.getPlace();

    if (selectedPlace && selectedPlace.formatted_address) {
      this.setState({
        city: "",
        zipcode: "",
        latitude: "",
        longitude: "",
      });
      // Extract the address components from the selected place
      const addressStreet = selectedPlace.formatted_address;
      const city = selectedPlace.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const state = selectedPlace.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.short_name;
      const zipcode = selectedPlace.address_components.find((component) =>
        component.types.includes("postal_code")
      )?.long_name;
      const latitude = selectedPlace.geometry.location.lat();
      const longitude = selectedPlace.geometry.location.lng();
      // console.log(selectedPlace.geometry.location);
      // Update the state with the selected address components

      this.setState({
        addressStreet: addressStreet,
        city: city,
        state: state,
        zipcode: zipcode,
        latitude: latitude,
        longitude: longitude,
      });
      if (addressStreet) this.validateField("addressStreet", addressStreet);
      if (city) this.validateField("city", city);
      if (state) this.validateField("state", state);
      if (zipcode) this.validateField("zipcode", zipcode);
      if (latitude) this.validateField("latitude", latitude);
      if (longitude) this.validateField("longitude", longitude);
    }
  };
  getStateList = async () => {
    let stateList = await userService.getStateList();
    this.setState({
      stateList: stateList,
    });
  };
  handleZipCodeInput = (e) => {
    const value = e.target.value;
    if (value.length > ZIP_CODE_LENGTH) {
      return true;
    }
    if (value.trim() == "" || value.match(DIGIT_ONLY)) {
      this.setState({ zipcode: value }, () => {
        this.validateField("zipcode", value);
      });
    }
  };
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let remainingCount = this.state.remainingCount;
    if (name == "providerAboutUs") {
      let aboutUsCount = value.length;
      let remainingCount = MAX_INPUT_LENGTH_LONG_TEXT - aboutUsCount;
      this.setState({
        remainingCount: remainingCount,
      });
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let addressStreetValid = this.state.addressStreetValid;
    let addressSuiteValid = this.state.addressSuiteValid;
    let cityValid = this.state.cityValid;
    let dateEstablishmentValid = this.state.dateEstablishmentValid;
    let emailValid = this.state.emailValid;
    let faxValid = this.state.faxValid;
    let orgNameValid = this.state.orgNameValid;
    let phoneValid = this.state.phoneValid;
    let stateValid = this.state.stateValid;
    let websiteUrlValid = this.state.websiteUrlValid;
    let zipcodeValid = this.state.zipcodeValid;
    let primaryEmailValid = this.state.primaryEmailValid;
    let primaryFirstNameValid = this.state.primaryFirstNameValid;
    let primaryLastNameValid = this.state.primaryLastNameValid;
    let primaryPhoneValid = this.state.primaryPhoneValid;
    let useridValid = this.state.useridValid;
    let latitudeValid = this.state.latitudeValid;
    let longitudeValid = this.state.longitudeValid;
    switch (fieldName) {
      case "orgName":
        if (fieldValue.trim().length == 0) {
          orgNameValid = false;
          fieldValidationErrors.orgName = "Organization name is required";
        } else {
          orgNameValid = true;
          fieldValidationErrors.orgName = "";
        }
        break;
      case "addressStreet":
        if (fieldValue.trim().length == 0) {
          addressStreetValid = false;
          fieldValidationErrors.addressStreet = "Address street is required";
        } else {
          addressStreetValid = true;
          fieldValidationErrors.addressStreet = "";
        }
        break;
      case "addressSuite":
        if (fieldValue.trim().length == 0) {
          addressSuiteValid = true;
          fieldValidationErrors.addressSuite = "";
        } else {
          addressSuiteValid = true;
          fieldValidationErrors.addressSuite = "";
        }
        break;
      case "city":
        if (fieldValue.trim().length == 0) {
          cityValid = false;
          fieldValidationErrors.city = "City is required";
        } else {
          cityValid = true;
          fieldValidationErrors.city = "";
        }
        break;
      case "primaryFirstName":
        if (fieldValue.trim().length == 0) {
          primaryFirstNameValid = false;
          fieldValidationErrors.primaryFirstName = "First name is required";
        } else if (!fieldValue.trim().match(ONLY_AlPHABETS_REGEX)) {
          primaryFirstNameValid = false;
          fieldValidationErrors.primaryFirstName = "Only alphabets are allowed";
        } else {
          primaryFirstNameValid = true;
          fieldValidationErrors.primaryFirstName = "";
        }
        break;
      case "primaryLastName":
        if (fieldValue.trim().length == 0) {
          primaryLastNameValid = false;
          fieldValidationErrors.primaryLastName = "Last name is required";
        } else if (!fieldValue.trim().match(ONLY_AlPHABETS_REGEX)) {
          primaryLastNameValid = false;
          fieldValidationErrors.primaryLastName = "Only alphabets are allowed";
        } else {
          primaryLastNameValid = true;
          fieldValidationErrors.primaryLastName = "";
        }
        break;
      case "dateEstablishment":
        if (!fieldValue) {
          dateEstablishmentValid = false;
          fieldValidationErrors.dateEstablishment =
            "Date of establishment is required";
        } else {
          dateEstablishmentValid = true;
          fieldValidationErrors.dateEstablishment = "";
        }
        break;
      case "fax":
        let faxErrMsg = "";
        if (fieldValue.trim().length == 0) {
          faxValid = true;
          faxErrMsg = "";
        } else if (
          isNaN(fieldValue.trim()) ||
          !ONLY_NUMBER_REGEX.test(fieldValue)
        ) {
          faxValid = false;
          faxErrMsg = "Invalid number";
        } else if (fieldValue.trim().length != 10) {
          faxValid = false;
          faxErrMsg = "Fax should have only 10 digits";
        } else {
          faxValid = true;
        }
        fieldValidationErrors.fax = faxValid ? "" : faxErrMsg;
        break;
      case "userid":
        if (fieldValue.trim().length == 0) {
          useridValid = false;
          fieldValidationErrors.userid = "User login id is required";
        } else if (!fieldValue.trim().match(ONLY_AlPHABETS_REGEX)) {
          useridValid = false;
          fieldValidationErrors.userid = "Only alphabets are allowed";
        } else {
          useridValid = true;
          fieldValidationErrors.userid = "";
        }
        break;
      case "state":
        if (fieldValue == "") {
          stateValid = false;
          fieldValidationErrors.state = "State is required";
        } else {
          stateValid = true;
          fieldValidationErrors.state = "";
        }
        break;
      case "websiteUrl":
        if (fieldValue.trim().length == 0) {
          websiteUrlValid = true;
          fieldValidationErrors.websiteUrl = "";
        } else if (
          fieldValue.trim().length &&
          !fieldValue.trim().match(URL_PATTERN)
        ) {
          websiteUrlValid = false;
          fieldValidationErrors.websiteUrl = "Invalid url";
        } else {
          websiteUrlValid = true;
          fieldValidationErrors.websiteUrl = "";
        }
        break;
      case "zipcode":
        if (fieldValue.trim().length == 0) {
          zipcodeValid = false;
          fieldValidationErrors.zipcode = "Zipcode is required";
        } else {
          zipcodeValid = true;
          fieldValidationErrors.zipcode = "";
        }
        break;
      case "email":
        if (fieldValue.trim().length == 0) {
          emailValid = false;
          fieldValidationErrors.email = "Email is required";
        } else if (!fieldValue.trim().match(EMAIL_REGEX)) {
          emailValid = false;
          fieldValidationErrors.email = "Invalid email";
        } else {
          emailValid = true;
          fieldValidationErrors.email = "";
        }
        break;
      case "phone":
        let errMsg = "";
        if (fieldValue.trim().length == 0) {
          phoneValid = false;
          errMsg = "Phone number is required";
        } else if (
          isNaN(fieldValue.trim()) ||
          !ONLY_NUMBER_REGEX.test(fieldValue)
        ) {
          phoneValid = false;
          errMsg = "Invalid number";
        } else if (fieldValue.trim().length != 10) {
          phoneValid = false;
          errMsg = enMsg.phoneNumberDigit;
        } else {
          phoneValid = true;
        }
        fieldValidationErrors.phone = phoneValid ? "" : errMsg;
        break;
      case "primaryEmail":
        if (fieldValue.trim().length == 0) {
          primaryEmailValid = true;
          fieldValidationErrors.primaryEmail = "";
        } else if (!fieldValue.trim().match(EMAIL_REGEX)) {
          primaryEmailValid = false;
          fieldValidationErrors.primaryEmail = "Invalid email";
        } else {
          primaryEmailValid = true;
          fieldValidationErrors.primaryEmail = "";
        }
        break;
      case "primaryPhone":
        let perrMsg = "";
        if (fieldValue.trim().length == 0) {
          primaryPhoneValid = false;
          perrMsg = "Phone number is required";
        } else if (
          isNaN(fieldValue.trim()) ||
          !ONLY_NUMBER_REGEX.test(fieldValue)
        ) {
          primaryPhoneValid = false;
          perrMsg = "Invalid number";
        } else if (fieldValue.trim().length != 10) {
          primaryPhoneValid = false;
          perrMsg = enMsg.phoneNumberDigit;
        } else {
          primaryPhoneValid = true;
        }
        fieldValidationErrors.primaryPhone = primaryPhoneValid ? "" : perrMsg;
        break;
      case "latitude":
        if (fieldValue == "") {
          latitudeValid = true;
          fieldValidationErrors.latitude = "Latitude is required";
        } else {
          latitudeValid = true;
          fieldValidationErrors.latitude = "";
        }
        break;
      case "longitude":
        if (fieldValue == "") {
          longitudeValid = true;
          fieldValidationErrors.longitude = "Longitude is required";
        } else {
          longitudeValid = true;
          fieldValidationErrors.longitude = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        addressStreetValid: addressStreetValid,
        addressSuiteValid: addressSuiteValid,
        cityValid: cityValid,
        dateEstablishmentValid: dateEstablishmentValid,
        emailValid: emailValid,
        faxValid: faxValid,
        orgNameValid: orgNameValid,
        phoneValid: phoneValid,
        stateValid: stateValid,
        websiteUrlValid: websiteUrlValid,
        zipcodeValid: zipcodeValid,
        primaryEmailValid: primaryEmailValid,
        primaryFirstNameValid: primaryFirstNameValid,
        primaryLastNameValid: primaryLastNameValid,
        primaryPhoneValid: primaryPhoneValid,
        useridValid: useridValid,
        latitudeValid: latitudeValid,
        longitudeValid: longitudeValid,
      },
      this.validateForm
    );
  };

  validateForm() {
    return (
      this.state.addressStreetValid &&
      this.state.addressSuiteValid &&
      this.state.cityValid &&
      this.state.dateEstablishmentValid &&
      this.state.emailValid &&
      this.state.faxValid &&
      this.state.orgNameValid &&
      this.state.phoneValid &&
      this.state.stateValid &&
      this.state.websiteUrlValid &&
      this.state.zipcodeValid &&
      this.state.primaryPhoneValid
    );
  }
  handleEstDate = (value) => {
    let dateEstablishment = true;
    if (value == null || !this.dateUtility.isValid(value)) {
      dateEstablishment = false;
    }
    this.setState(
      {
        dateEstablishment: value,
        dateEstablishmentValid: dateEstablishment,
      },
      () => {
        this.validateField("dateEstablishment", value);
      }
    );
  };
  handleEstDateError = (error, value) => {
    this.setState({
      dateEstablishmentValid:
        error || value == "" || value == null ? false : true,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      addressStreet: this.state.addressStreet,
      addressSuite: this.state.addressSuite,
      city: this.state.city,
      dateEstablishment: this.state.dateEstablishment,
      email: this.state.email,
      fax: this.state.fax,
      orgName: this.state.orgName,
      phone: this.state.phone,
      state: this.state.state,
      websiteUrl: this.state.websiteUrl,
      zipcode: this.state.zipcode,
      primaryEmail: this.state.primaryEmail,
      primaryFirstName: this.state.primaryFirstName,
      primaryLastName: this.state.primaryLastName,
      primaryPhone: this.state.primaryPhone,
      providerId: this.state.providerId,
      providerAboutUs: this.state.providerAboutUs,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };
    if (this.state.oldUserId != this.state.userid) {
      // data.userLoginId = this.state.userid;
    }
    this.saveOrganizationalData(data);
  };
  saveOrganizationalData = async (data) => {
    this.setState({ loading: true });
    let showNotification = {};
    try {
      const response = await fetch(apiPath.saveOrganizationDetail, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Provider User",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
          } else if (response.ok) {
          } else {
            showNotification = {
              title: "Details",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
          }
          return response.json();
        })
        .then((data) => {
          if (data.status == "400" && data.message == "error.userManagement") {
            modalCloseStatus = 0;
            showNotification = {
              title: "Details",
              message: "User login id is already exist.",
              type: "danger",
            };
          } else if (data.providerId) {
            showNotification = {
              title: "Details",
              message: "Organizational details saved successfully",
              type: "success",
            };
            window.location.replace(
              basePath + providerBaseRoutes.profile.path + "?tab=personal"
            );
            return true;
          }
        })
        .catch((error) => {
          showNotification = {
            title: "Details",
            message: enMsg.networkFailedError,
            type: "danger",
          };
          return response;
        });
    } catch (error) {
      showNotification = {
        title: "Details",
        message: enMsg.networkFailedError,
        type: "danger",
      };
    }
    userService.showNotification(showNotification);
    this.setState({ loading: false });
  };
  fetchOrganizationalDetail = () => {
    const response = fetch(
      apiPath.saveOrganizationDetail + "?id=" + this.state.providerId,
      {
        method: "GET",
        headers: generalAuthTokenHeader(),
      }
    )
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        if (data.providerId) {
          let remainingCount = this.state.remainingCount;
          if (data.providerAboutUs) {
            let aboutUsCount = data.providerAboutUs.length;
            let remainingCount = MAX_INPUT_LENGTH_LONG_TEXT - aboutUsCount;
            this.setState({
              remainingCount: remainingCount,
            });
          }
          this.setState({
            addressStreet: data.addressStreet,
            addressSuite: data.addressSuite,
            city: data.city,
            dateEstablishment: data.dateEstablishment,
            email: data.email,
            fax: data.fax,
            orgName: data.orgName
              ? data.orgName
              : this.state.userInfo.provider.providerName,
            phone: data.phone,
            state: data.state,
            websiteUrl: data.websiteUrl,
            zipcode: data.zipcode,
            primaryEmail: data.primaryEmail,
            primaryFirstName: data.primaryFirstName,
            primaryLastName: data.primaryLastName,
            primaryPhone: data.primaryPhone,
            providerAboutUs: data.providerAboutUs,
            userid: data.userLoginId,
            oldUserId: data.userLoginId,
            addressStreetValid: true,
            addressSuiteValid: true,
            cityValid: true,
            dateEstablishmentValid: true,
            emailValid: true,
            faxValid: true,
            orgNameValid: true,
            phoneValid: true,
            stateValid: true,
            websiteUrlValid: true,
            zipcodeValid: true,
            primaryEmailValid: true,
            primaryFirstNameValid: true,
            primaryLastNameValid: true,
            primaryPhoneValid: true,
            longitudeValid: true,
            latitudeValid: true,
            useridValid: true,
            longitude: data.longitude,
            latitude: data.latitude,
          });
        } else {
          this.setState({
            orgName: this.state.userInfo.provider.providerName,
            orgNameValid: true,
          });
        }
      })
      .catch((error) => {
        return response;
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className="front-modal">
        {this.state.loading ? <LinearProgress color="primary" /> : ""}
        <div className="tab-heading tab-heading-text">Organization Details</div>

        <div className="service-info-text">
          <p>
            Please enter your organization's details. This is shown to discharge
            planners and may also be sent to patients and/or their families.
          </p>
        </div>
        <form onSubmit={this.handleSubmit} noValidate>
          <Box
            display="flex"
            flexDirection="row"
            width={1}
            mt={2}
            className={"org-form-cover"}
          >
            <Box
              mt={3}
              width={1 / 3}
              className={""}
              display="flex"
              flexDirection="column"
            >
              <div>
                <TextField
                  name="orgName"
                  value={this.state.orgName}
                  InputLabelProps={{ className: "required-label" }}
                  label="Name of organization"
                  onChange={this.handleChangeInput}
                  tabIndex="1"
                />
                <FormErrors
                  show={!this.state.orgNameValid}
                  formErrors={this.state.formErrors}
                  fieldName="orgName"
                />
              </div>
              <div>
                <TextField
                  name="addressStreet"
                  value={this.state.addressStreet}
                  InputLabelProps={{ className: "required-label" }}
                  label="Address Street"
                  onChange={this.handleChangeInput}
                  inputRef={this.addressStreetInputRef} // Ref for the input field
                  tabIndex="2"
                />
                <FormErrors
                  show={!this.state.addressStreetValid}
                  formErrors={this.state.formErrors}
                  fieldName="addressStreet"
                />
              </div>
              <div>
                <TextField
                  name="addressSuite"
                  value={this.state.addressSuite}
                  label="Address Suite #"
                  onChange={this.handleChangeInput}
                  tabIndex="11"
                />
                <FormErrors
                  show={!this.state.addressSuiteValid}
                  formErrors={this.state.formErrors}
                  fieldName="addressSuite"
                />
              </div>
              <div>
                <TextField
                  name="city"
                  value={this.state.city}
                  InputLabelProps={{ className: "required-label" }}
                  label="City"
                  onChange={this.handleChangeInput}
                  tabIndex="3"
                />
                <FormErrors
                  show={!this.state.cityValid}
                  formErrors={this.state.formErrors}
                  fieldName="city"
                />
              </div>
              <div>
                <TextField
                  name="state"
                  value={this.state.state}
                  InputLabelProps={{ className: "required-label" }}
                  label="State"
                  onChange={this.handleChangeInput}
                  tabIndex="3"
                />
                <FormErrors
                  show={!this.state.stateValid}
                  formErrors={this.state.formErrors}
                  fieldName="state"
                />
                {/* <FormControl>
                  <InputLabel className={"required-label"}>State</InputLabel>
                  <Select
                    value={this.state.state ? this.state.state : ""}
                    onChange={this.handleChangeInput}
                    inputProps={{
                      name: "state",
                      id: "facility-select-label",
                    }}
                    displayEmpty
                    InputLabelProps={{ className: "required-label" }}
                    tabIndex="4"
                  >
                    
                    {this.state.stateList.length ? (
                      this.state.stateList.map((st, key) => (
                        <MenuItem value={st.id} key={key}>
                          {st.stateName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value=" ">Not Available</MenuItem>
                    )}
                  </Select>
                  <FormErrors
                    show={!this.state.stateValid}
                    formErrors={this.state.formErrors}
                    fieldName="state"
                  />
                </FormControl> */}
              </div>

              <div>
                <TextField
                  name="latitude"
                  value={this.state.latitude}
                  label="Latitude"
                  onChange={this.handleChangeInput}
                  tabIndex="5"
                  disabled={true}
                />
                <FormErrors
                  show={!this.state.latitudeValid}
                  formErrors={this.state.formErrors}
                  fieldName="latitude"
                />
              </div>
              <div>
                <TextField
                  name="longitude"
                  value={this.state.longitude}
                  label="Longitude"
                  onChange={this.handleChangeInput}
                  tabIndex="5"
                  disabled={true}
                />
                <FormErrors
                  show={!this.state.longitudeValid}
                  formErrors={this.state.formErrors}
                  fieldName="longitude"
                />
              </div>
              <div>
                <FormControl className="dateEstablished">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} moment={moment}>
                    <KeyboardDatePicker
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="start-date-picker-dialog"
                      InputLabelProps={{
                        className: "required-label",
                      }}
                      name="dateEstablishment"
                      value={this.state.dateEstablishment}
                      label="Date Established"
                      onChange={this.handleEstDate}
                      animateYearScrolling={true}
                      onError={this.handleEstDateError}
                      className="KeyboardDatePicker"
                      invalidDateMessage={enMsg.invalidDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        className: "date-picker-span",
                      }}
                      tabIndex="6"
                    />
                  </MuiPickersUtilsProvider>
                  <FormErrors
                    show={!this.state.dateEstablishmentValid}
                    formErrors={this.state.formErrors}
                    fieldName="dateEstablishment"
                  />
                </FormControl>
              </div>
            </Box>
            <Box
              width={1 / 3}
              mt={3}
              className={""}
              display="flex"
              flexDirection="column"
            >
              <div>
                <TextField
                  name="phone"
                  value={this.state.phone}
                  InputLabelProps={{ className: "required-label" }}
                  label="Phone"
                  onChange={this.handleChangeInput}
                  tabIndex="7"
                />
                <FormErrors
                  show={!this.state.phoneValid}
                  formErrors={this.state.formErrors}
                  fieldName="phone"
                />
              </div>
              <div>
                <TextField
                  name="fax"
                  value={this.state.fax}
                  label="Fax"
                  onChange={this.handleChangeInput}
                  tabIndex="8"
                />
                <FormErrors
                  show={!this.state.faxValid}
                  formErrors={this.state.formErrors}
                  fieldName="fax"
                />
              </div>
              <div>
                <TextField
                  name="email"
                  value={this.state.email}
                  InputLabelProps={{ className: "required-label" }}
                  label="Email"
                  onChange={this.handleChangeInput}
                  tabIndex="9"
                />
                <FormErrors
                  show={!this.state.emailValid}
                  formErrors={this.state.formErrors}
                  fieldName="email"
                />
              </div>
              <div>
                <TextField
                  name="websiteUrl"
                  value={this.state.websiteUrl}
                  label="Website URL"
                  onChange={this.handleChangeInput}
                  tabIndex="10"
                />
                <FormErrors
                  show={!this.state.websiteUrlValid}
                  formErrors={this.state.formErrors}
                  fieldName="websiteUrl"
                />
              </div>
              <div>
                <TextField
                  name="zipcode"
                  value={this.state.zipcode}
                  InputLabelProps={{ className: "required-label" }}
                  label="Zipcode"
                  onChange={this.handleZipCodeInput}
                  tabIndex="5"
                />
                <FormErrors
                  show={!this.state.zipcodeValid}
                  formErrors={this.state.formErrors}
                  fieldName="zipcode"
                />
              </div>
            </Box>
            <Box
              width={1 / 3}
              className={""}
              display="flex"
              flexDirection="column"
            >
              <div className="heading-line">
                Primary Contact of Organization
              </div>

              <div>
                <TextField
                  name="primaryFirstName"
                  value={this.state.primaryFirstName}
                  InputLabelProps={{ className: "required-label" }}
                  label="First Name"
                  onChange={this.handleChangeInput}
                  tabIndex="12"
                />
                <FormErrors
                  show={!this.state.primaryFirstNameValid}
                  formErrors={this.state.formErrors}
                  fieldName="primaryFirstName"
                />
              </div>
              <div>
                <TextField
                  name="primaryLastName"
                  value={this.state.primaryLastName}
                  InputLabelProps={{ className: "required-label" }}
                  label="Last Name"
                  onChange={this.handleChangeInput}
                  tabIndex="13"
                />
                <FormErrors
                  show={!this.state.primaryLastNameValid}
                  formErrors={this.state.formErrors}
                  fieldName="primaryLastName"
                />
              </div>
              <div>
                <TextField
                  name="primaryEmail"
                  value={this.state.primaryEmail}
                  label="Email"
                  onChange={this.handleChangeInput}
                  tabIndex="14"
                />
                <FormErrors
                  show={!this.state.primaryEmailValid}
                  formErrors={this.state.formErrors}
                  fieldName="primaryEmail"
                />
              </div>
              <div>
                <TextField
                  name="primaryPhone"
                  value={this.state.primaryPhone}
                  InputLabelProps={{ className: "required-label" }}
                  label="Phone"
                  onChange={this.handleChangeInput}
                  tabIndex="15"
                />
                <FormErrors
                  show={!this.state.primaryPhoneValid}
                  formErrors={this.state.formErrors}
                  fieldName="primaryPhone"
                />
              </div>
            </Box>
          </Box>
          <Box width={1} display="flex" flexDirection="row">
            <Box
              width={1 / 3}
              className="form-button-cover form-actions"
              tabIndex="17"
            >
              <Button
                className={this.state.loading ? "buttonSuccess btn1" : "btn1"}
                disabled={this.state.loading || !this.validateForm()}
                type="submit"
              >
                Save and Next
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button className="back-btn btn2" type="button" tabIndex="18">
                <Link underline="none" to={providerBaseRoutes.dashboard.path}>
                  Cancel
                </Link>
              </Button>
            </Box>

            <Box width={2 / 3} className="settop" tabIndex="16">
              <FormControl className="w-100">
                <TextField
                  rows={9}
                  multiline={true}
                  fullWidth={true}
                  name="providerAboutUs"
                  placeholder="Enter about us"
                  label={
                    <p>
                      About Us &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                      {this.state.remainingCount} characters remainings
                    </p>
                  }
                  value={this.state.providerAboutUs}
                  onChange={this.handleChangeInput}
                  inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG_TEXT }}
                  className="comment-TextField"
                />
              </FormControl>
              <FormErrors
                show={!this.state.providerAboutUsValid}
                formErrors={this.state.formErrors}
                fieldName="providerAboutUs"
              />
            </Box>
          </Box>
        </form>
      </div>
    );
  }
}

OrganizationDetails.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(styles)(OrganizationDetails);
