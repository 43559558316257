import { Subject } from "rxjs";
import MomentUtils from "@date-io/moment";
import moment from "moment";
// import AWS from 'aws-sdk';

export const getCurrentDateTime = () => {
  var dateObj = new Date();
  var month = dateObj.getMonth() + 1;
  var day = dateObj.getDate();
  var year = dateObj.getFullYear();
  var time = dateObj.toLocaleTimeString();
  return month + "/" + day + "/" + year + " | " + time;
};

export const getDateValue = (date, dateType) => {
  var dateObj = date ? new Date(date) : new Date();
  var month = dateObj.getMonth() + 1;
  var day = dateObj.getDate();
  var year = dateObj.getFullYear();
  var time = dateObj.toLocaleTimeString();
  if (dateType) {
    if (dateType == "d") {
      return day;
    } else if (dateType == "m") {
      return month;
    } else if (dateType == "y") {
      return year;
    } else {
      return month + "/" + day + "/" + year;
    }
  } else {
    return month + "/" + day + "/" + year;
  }
};
export const converToLocalTime = (serverDate) => {
  var dt = new Date(Date.parse(serverDate));
  var localDate = dt;

  var gmt = localDate;
  var min = gmt.getTime() / 1000 / 60; // convert gmt date to minutes
  var localNow = new Date().getTimezoneOffset(); // get the timezone
  // offset in minutes
  var localTime = min - localNow; // get the local time

  var dateStr = new Date(localTime * 1000 * 60);
  // dateStr = dateStr.toISOString("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"); // this will return as just the server date format i.e., yyyy-MM-dd'T'HH:mm:ss.SSS'Z'
  dateStr = dateStr.toString("YYYY-MM-DDTHH:MM:SS.sssZ");
  return dateStr;
};
export const formatDateToLocalFormat = (serverDate) => {
  var dateObj = new Date(serverDate);
  var month = dateObj.getMonth() + 1;
  var day = dateObj.getDate();
  var year = dateObj.getFullYear();
  return month + "/" + day + "/" + year;
};
export const formatDateToServerFormat = (localDate) => {
  var dateObj = new Date(localDate);
  var month = dateObj.getMonth() + 1;
  month = month < 10 ? "0" + month : "" + month;
  var day = dateObj.getDate();
  day = day < 10 ? "0" + day : "" + day;
  var year = dateObj.getFullYear();
  return year + "-" + month + "-" + day;
};
export const downloadMedia = (fileUrl, fileName) => {
  fetch(fileUrl, {
    // method: "GET",
    // headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "http://app.maxmrj.com",
    // },
  }).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.target = "_blank";
      a.rel = "noopener noreferrer";
      a.click();
    });
    //window.location.href = response.url;
  });
};

const subject = new Subject();

export const findProviderService = {
  setFindProvidersList: (message) => subject.next(message),
  clearFindProvidersList: () => subject.next(),
  getFindProvidersList: () => subject.asObservable(),
};
export class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date).format("l");
  }
}
export class UTCUtils extends MomentUtils {
  format(value, formatString) {
    return moment(value)
      .utc()
      .format(formatString);
  }
}
export const treatAsUTC = (date) => {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

export const daysBetweenTwoDate = (startDate, endDate) => {
  var millisecondsPerDay = 24 * 60 * 60 * 1000;
  return parseInt(
    (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay
  );
};
export const minsBetweenTwoDate = (startDate, endDate) => {
  if (!startDate) {
    return -1;
  }
  var startTime = new Date(startDate);
  var endTime = new Date();
  var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
  return Math.round(difference / 60000);
};

export const addDaysToDate = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const subsDaysToDate = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

export const localDateFormat = (date, format) => {
  if (!date || date == null || date == "") {
    return "N/A";
  }
  moment.tz.setDefault("UTC");
  if (format === "UTC") {
    return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss") + "Z";
  } else if (format) {
    return moment.utc(date).format(format);
  } else {
    return moment.utc(date).format("MM/DD/YYYY");
  }
};
export const localDateFormatYear = (date, format) => {
  if (!date || date == null || date == "") {
    return "N/A";
  }
  moment.tz.setDefault("UTC");
  if (format === "UTC") {
    return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss") + "Z";
  } else if (format) {
    return moment.utc(date).format(format);
  } else {
    return moment.utc(date).format("MM/DD/YYYY");
  }
};
export const localServerFormat = (date) => {
  var dateObj = new Date(date);
  var month = dateObj.getMonth() + 1;
  var day = dateObj.getDate();
  var year = dateObj.getFullYear();
  return new Date(year + "-" + month + "-" + day);
};
export const localServerFormats = (date) => {
  var dateObj = new Date(date);
  var month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
  var day = String(dateObj.getDate()).padStart(2, "0"); // Ensure 2-digit day
  var year = dateObj.getFullYear();
  return year + "-" + month + "-" + day;
};
export const formatFileSize = (fileSize) => {
  if (fileSize < 1024) {
    return fileSize + " KB";
  } else if (fileSize >= 1024 && fileSize < 1024 * 1024) {
    return (fileSize / 1024).toFixed(2) + " MB";
  } else {
    return (fileSize / (1024 * 1024)).toFixed(2) + " GB";
  }
};
