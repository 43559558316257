/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Box,
  TextField,
  Avatar,
  Checkbox,
  LinearProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";
import PhoneNumber from "awesome-phonenumber";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  genderType,
  EMAIL_REGEX,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ZIPCODE_REGEX,
  ZIP_CODE_LENGTH,
  MIN_AGE_YEARS,
  ALLOWED_IMAGE_EXTENTION,
  DEFAULT_PROFILE_IMG,
  DIGIT_ONLY,
  PHONE_NO_INVALID_LENGTH,
  OK_SUCCESS_STATUS,
  STATUS_CREATED,
  INVALID_DATA_POST,
  SECURITY_ERROR,
} from "__helpers/constants";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import baseRoutes from "base-routes";
import ChangePassword from "./ChangePassword";
import fileExtension from "file-extension";
import { providerGeneralAuthTokenHeader } from "__helpers/auth-header";

import { withRouter } from "react-router";

import { connect } from "react-redux";
import { addUSerUInfo, reduxLoad } from "js/actions";
import { USER_INFO } from "__helpers/constants";
import { dpBaseRoutes } from "base-routes";
import { basePath } from "base-routes";
import backArrow from "assets/img/icons/arrow-back.svg";
import BasicProfile from "./BasicProfile";
import InDevelopment from "./InDevlopment";
import commingSoonImg from "assets/img/comming_soon.png";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { MAX_INPUT_LENGTH_RATE } from "__helpers/constants";
import { enGB } from "date-fns/esm/locale";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroller";
import { providerBaseRoutes } from "base-routes";

function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: (addUSerUInfoVal) => dispatch(addUSerUInfo(addUSerUInfoVal)),
    addTimeStamp: (addTimeStampVal) => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    getPatientLanNInsListRedux: state.getPatientLanNInsList,
    hospitalListRedux: state.hospitalList,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const styles = (theme) => ({});

class ProfileCriteriaClass extends React.Component {
  constructor(props) {
    super(props);
    /* create Ref for file input  */
    let masterLanguages = [];
    if (
      props.getPatientLanNInsListRedux &&
      props.getPatientLanNInsListRedux.masterLanguages
    ) {
      masterLanguages = props.getPatientLanNInsListRedux.masterLanguages;
    }
    this.state = {
      loading: false,
      userInfo: JSON.parse(localStorage.getItem("provider-user")),
      getPatientLanNInsList: props.getPatientLanNInsListRedux,
      reduxLoadFlag: false,
      selectedInsuranceList: [],
      selectedLanguageList: [],
      selectedInfectious: null,
      masterLanguages: [],
      loadMore: false,
      currentItemPage: 0,
      displayItems: 50,
    };

    // this.selectProfileTab = this.selectProfileTab.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleLangaugeBox = this.handleLangaugeBox.bind(this);
    this.handleInsuranceBox = this.handleInsuranceBox.bind(this);
    this.selectInfectiousBox = this.selectInfectiousBox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadMoreData = this.loadMoreData.bind(this);
  }
  componentDidMount() {
    this.fetchMoreData();
    this.setState(
      {
        loading: true,
      },
      () => this.fetchFn()
    );
  }
  fetchMoreData() {
    const {
      loadMore,
      getPatientLanNInsList,
      currentItemPage,
      displayItems,
      masterLanguages,
    } = this.state;
    let totalItems =
      getPatientLanNInsList.masterLanguages &&
      getPatientLanNInsList.masterLanguages.length
        ? getPatientLanNInsList.masterLanguages.length
        : 0;
    let totalItemList =
      getPatientLanNInsList.masterLanguages &&
      getPatientLanNInsList.masterLanguages.length
        ? getPatientLanNInsList.masterLanguages
        : [];
    let currentItems = masterLanguages.lenght;

    const startIndex = currentItemPage * displayItems;
    const endIndex = startIndex + displayItems;
    let currentItemsList = [];
    totalItemList.map((itemList, key) => {
      if (key >= startIndex && key < endIndex) {
        currentItemsList.push(itemList);
        // currentItemsList = masterLanguages.concat(itemList);
      }
    });
    currentItemsList = masterLanguages.concat(currentItemsList);

    this.setState({
      loadMore: false,
      masterLanguages: currentItemsList,
      currentItemPage: currentItemPage + 1,
    });
  }
  loadMoreData() {
    const {
      loadMore,
      getPatientLanNInsList,
      currentItemPage,
      displayItems,
      masterLanguages,
    } = this.state;
    let totalItems =
      getPatientLanNInsList.masterLanguages &&
      getPatientLanNInsList.masterLanguages.length
        ? getPatientLanNInsList.masterLanguages.length
        : 0;
    let currentItems = masterLanguages.length;

    if (totalItems > currentItems) {
      setTimeout(
        function() {
          this.setState(
            {
              loadMore: false,
            },
            () => this.fetchMoreData()
          );
        }.bind(this),
        1000
      );
    }
  }
  async fetchFn() {
    let providerCriteria = await userService.fetchGlobalApisProvider(
      apiPath.providerCriteria
    );
    if (providerCriteria) {
      this.fetchData(providerCriteria);
    }
  }
  fetchData(providerCriteria) {
    let selectedInsuranceList = [];
    let selectedLanguageList = [];
    let selectedInfectious = false;
    if (providerCriteria) {
      selectedInfectious = providerCriteria.infectiousStatus;
      let providerInsuranceEntity = providerCriteria.providerInsuranceEntity;
      let providerLanguagesEntity = providerCriteria.providerLanguagesEntity;

      typeof providerInsuranceEntity === "object" &&
      providerInsuranceEntity.length
        ? providerInsuranceEntity.map((iList, key) => {
            selectedInsuranceList.push(iList.insuranceId);
          })
        : null;
      typeof providerLanguagesEntity === "object" &&
      providerLanguagesEntity.length
        ? providerLanguagesEntity.map((lList, key) => {
            selectedLanguageList.push(lList.languageId);
          })
        : null;

      this.setState({
        selectedInsuranceList: selectedInsuranceList,
        selectedLanguageList: selectedLanguageList,
        selectedInfectious: selectedInfectious,
        loading: false,
      });
    }
  }
  stopLoading() {
    this.setState({
      loading: false,
    });
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let getPatientLanNInsList = [];
      let userInfo = {};
      let masterLanguages = [];
      if (this.props.getPatientLanNInsListRedux) {
        let selectedgetPatientLanNInsList = this.props
          .getPatientLanNInsListRedux;
        getPatientLanNInsList = selectedgetPatientLanNInsList
          ? selectedgetPatientLanNInsList
          : [];
        if (getPatientLanNInsList && getPatientLanNInsList.masterLanguages) {
          masterLanguages = getPatientLanNInsList.masterLanguages;
        }
      }
      // if (this.props.userInfo) {
      //   userInfo = this.props.userInfo;
      // }
      userInfo = JSON.parse(localStorage.getItem("provider-user"));
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        getPatientLanNInsList: getPatientLanNInsList,
        userInfo: userInfo,
      });
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    let data = {};
    let providerInsuranceEntity = [];
    let providerLanguagesEntity = [];

    this.state.selectedInsuranceList.map((iList, key) => {
      let localObj = {};
      localObj.insuranceId = iList;
      localObj.providerId = this.state.userInfo.id;
      providerInsuranceEntity.push(localObj);
    });
    this.state.selectedLanguageList.map((lList, key) => {
      let localObj = {};
      localObj.languageId = lList;
      localObj.providerId = this.state.userInfo.id;
      providerLanguagesEntity.push(localObj);
    });
    data.infectiousStatus = this.state.selectedInfectious;
    data.providerInsuranceEntity = providerInsuranceEntity;
    data.providerLanguagesEntity = providerLanguagesEntity;

    let showNotification = {};
    // return
    this.setState({
      loading: true,
    });
    const response = fetch(apiPath.providerCriteria, {
      method: "POST",
      headers: providerGeneralAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.criteriaFailedTitle,
            message: enMsg.criteriaFailedDes,
            type: "danger",
          };
        } else if (response.ok) {
          showNotification = {
            title: "Success",
            message: enMsg.criteriaSuccessDes,
            type: "success",
          };
          // window.location.replace(basePath + providerBaseRoutes.dashboard.path);
        } else {
          showNotification = {
            title: enMsg.criteriaFailedTitle,
            message: enMsg.criteriaFailedDes,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.criteriaFailedTitle,
          message: "Something went wrong.Please try after sometime..",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      });
  }
  handleInsuranceBox = (insuranceId) => {
    const name = insuranceId;
    let selectedInsuranceList = this.state.selectedInsuranceList;
    if (!selectedInsuranceList.includes(name)) {
      selectedInsuranceList.push(name);
    } else {
      const index = selectedInsuranceList.indexOf(name);
      if (index > -1) {
        selectedInsuranceList.splice(index, 1);
      }
    }
    this.setState({
      selectedInsuranceList: selectedInsuranceList,
    });
  };
  handleLangaugeBox = (languageId) => {
    const name = languageId;
    let selectedLanguageList = this.state.selectedLanguageList;
    if (!selectedLanguageList.includes(name)) {
      selectedLanguageList.push(name);
    } else {
      const index = selectedLanguageList.indexOf(name);
      if (index > -1) {
        selectedLanguageList.splice(index, 1);
      }
    }
    this.setState({
      selectedLanguageList: selectedLanguageList,
    });
  };
  selectInfectiousBox = (radioFlag) => {
    this.setState({
      selectedInfectious: radioFlag,
    });
  };
  render() {
    const { getPatientLanNInsList, masterLanguages } = this.state;
    let masterInsurance = getPatientLanNInsList
      ? getPatientLanNInsList.masterInsurance
      : "";
    return (
      <div className="ProviderProfile PaterintCriteria">
        {this.state.loading ? <LinearProgress color="primary" /> : ""}
        <div className="tab-heading tab-heading-text">Patient Criteria</div>

        <div className="service-info-text">
          <p>
            Please select the patient criteria your organization will accept.
            This is CRTICAL to getting the right patient referrals.
          </p>
        </div>
        <form onSubmit={this.handleSubmit} noValidate>
          <Box
            display="flex"
            flexDirection="row"
            width={1}
            mt={2}
            className={"all-cover"}
          >
            <Box
              width={1 / 3}
              className={"setcat-criteria Infectious-cover"}
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection="row"
                width={1}
                className="caption-switch"
              >
                <div className="caption">Infectious Disease Care</div>
              </Box>

              <Scrollbars
                style={{ height: "calc(100vh - 293px)" }}
                autoHide={false}
                universal={true}
                className="criteriaScroll"
              >
                <Box
                  width={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  className="form-box"
                  pr={2}
                  mt={2}
                >
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={this.state.selectedInfectious}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" />}
                      label="Yes"
                      onClick={(event) => this.selectInfectiousBox(true)}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label="No"
                      onClick={(event) => this.selectInfectiousBox(false)}
                    />
                  </RadioGroup>
                </Box>
              </Scrollbars>
            </Box>

            <Box
              width={1 / 3}
              className={"setcat-criteria Language-cover"}
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection="row"
                width={1}
                className="caption-switch"
              >
                <div className="caption">Patient Language</div>
                <div className="sm-text">
                  {this.state.selectedLanguageList.length} Selected
                </div>
              </Box>

              <Scrollbars
                style={{ height: "calc(100vh - 293px)" }}
                autoHide={false}
                universal={true}
              >
                <Box
                  key={"language"}
                  width={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  className="form-box"
                  pr={2}
                  mt={2}
                >
                  {typeof masterLanguages === "object" &&
                  masterLanguages.length ? (
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.loadMoreData}
                      threshold={50}
                      hasMore={this.state.loadMore}
                      loader={
                        <div className="loader" key={0}>
                          Loading ...
                          <LinearProgress color="primary" />
                        </div>
                      }
                      useWindow={false}
                      getScrollParent={() => this.scrollParentRef}
                    >
                      {masterLanguages.map((lList, key) => {
                        return (
                          <Box key={key}>
                            <div className="checkbox-input-cover">
                              <Checkbox
                                checked={
                                  this.state.selectedLanguageList.includes(
                                    lList.languageId
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(event) =>
                                  this.handleLangaugeBox(lList.languageId)
                                }
                                key={key}
                              />
                              <span className="rate-text">
                                {lList.languageName
                                  ? lList.languageName
                                  : "Unknown"}
                              </span>
                            </div>
                          </Box>
                        );
                      })}
                    </InfiniteScroll>
                  ) : (
                    "no languages.."
                  )}
                </Box>
              </Scrollbars>
            </Box>

            <Box
              width={1 / 3}
              className={"setcat-criteria Insurance-cover"}
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection="row"
                width={1}
                className="caption-switch"
              >
                <div className="caption">Insurance</div>
                <div className="sm-text">
                  {this.state.selectedInsuranceList.length} Selected
                </div>
              </Box>

              <Scrollbars
                style={{ height: "calc(100vh - 293px)" }}
                autoHide={false}
                universal={true}
                className="criteriaScroll"
              >
                <Box
                  key={"hospice"}
                  width={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  className="form-box"
                  pr={2}
                  mt={2}
                >
                  <Box>
                    <div className="checkbox-input-cover">
                      <Checkbox
                        checked={
                          this.state.selectedInsuranceList.includes(0)
                            ? true
                            : false
                        }
                        onChange={(event) => this.handleInsuranceBox(0)}
                      />
                      <span className="rate-text">No Insurance</span>
                    </div>
                  </Box>
                  {typeof masterInsurance === "object" && masterInsurance.length
                    ? masterInsurance
                        // .sort((a, b) => a.insuranceId > b.insuranceId ? 1 : -1)
                        .map((iList, key) => {
                          if (iList.insuranceId != 0) {
                            return (
                              <Box key={key}>
                                <div className="checkbox-input-cover">
                                  <Checkbox
                                    checked={
                                      this.state.selectedInsuranceList.includes(
                                        iList.insuranceId
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(event) =>
                                      this.handleInsuranceBox(iList.insuranceId)
                                    }
                                    key={key}
                                  />
                                  <span className="rate-text">
                                    {iList.insuranceName
                                      ? iList.insuranceName
                                      : "Unknown"}
                                  </span>
                                </div>
                              </Box>
                            );
                          }
                        })
                    : "no other insurance.."}
                </Box>
              </Scrollbars>
            </Box>
          </Box>

          <Box>
            <div className="form-button-cover form-actions">
              <Button
                className={this.state.loading ? "buttonSuccess btn1" : "btn1"}
                disabled={
                  this.state.loading ||
                  this.state.selectedInsuranceList.length <= 0
                }
                type="submit"
              >
                Save And Done
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button className="back-btn btn2" type="button">
                <Link underline="none" to={providerBaseRoutes.dashboard.path}>
                  Cancel
                </Link>
              </Button>
            </div>
          </Box>
        </form>
      </div>
    );
  }
}

ProfileCriteriaClass.propTypes = {
  classes: PropTypes.object,
};
const ProfileCriteria = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileCriteriaClass);
export default withStyles(styles)(ProfileCriteria);
