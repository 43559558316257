import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
} from "@material-ui/core";
import { FormErrors } from "../../../components/Login/FormErrors";
import enMsg from "__helpers/locale/en/en";
import Tooltip from "@material-ui/core/Tooltip";
import {
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
  MAX_INPUT_LENGTH,
} from "__helpers/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormGroup, FormControl, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import "./patientDashboardCss.css";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";
import { LocalizedUtils, UTCUtils } from "__helpers/util";
import Chip from "@material-ui/core/Chip";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import infoIcon from "assets/img/icons/info-icon.svg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";

import { apiPath } from "api";
import {
  generalAuthTokenHeader,
  generalMimeAuthTokenHeader,
} from "__helpers/auth-header";
import { userService } from "_services/user.service";
import { PATIENT_LIST } from "__helpers/constants";
import { formatDateToServerFormat } from "__helpers/util";
import { ALLOWED_PROFILE_IMAGE_SIZE } from "__helpers/constants";
import { ALLOWED_IMAGE_EXTENTION } from "__helpers/constants";
import { ALLOWED_FACESHEET_SIZE } from "__helpers/constants";
import {
  ALLOWED_FACESHEET_EXTENTION,
  MAXMRJ_FACILITY,
  VENDOR_FACILITY,
  USER_INFO,
} from "__helpers/constants";
import Files from "react-files";
import { ReactUTCDatepicker } from "react-utc-datepicker";
import { formatDateToLocalFormat } from "__helpers/util";
import Axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
moment.tz.setDefault("UTC");
// moment.tz.setDefault('America/Los_Angeles');
export class PatientForm extends Component {
  inputOpenImageFileRef = React.createRef(null);
  inputOpenFaceSheetFileRef = React.createRef(null);
  maxDate = new Date();
  minDate = new Date();
  constructor(props) {
    super(props);
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    let dpFacilityCode =
      userInfo &&
      userInfo.dischargePlanner &&
      userInfo.dischargePlanner.facility &&
      userInfo.dischargePlanner.facility.facilityCode
        ? userInfo.dischargePlanner.facility.facilityCode
        : null;
    this.dateUtility = new DateFnsUtils();
    this.state = {
      formErrors: {
        firstName: "",
        lastName: "",
        middleName: "",
        gender: "",
        insurance: "",
        hospital: "",
        dob: "",
        estDate: "",
        actualEstDate: "",
        patientCategoties: "",
        patientImage: "",
        patientFaceSheet: "",
      },
      firstNameValid: false,
      lastNameValid: false,
      middleNameValid: true,
      genderValid: false,
      insuranceValid: true,
      hospitalValid: true,
      dobValid: false,
      estDateValid: true,
      actualEstDateValid: true,
      patientCategotiesValid: true,
      patientImageValid: true,
      patientFaceSheetValid: true,
      firstName: this.props.patientDetail.firstName,
      lastName: "",
      middleName: "",
      insurance: "",
      hospital: "",
      gender: "",
      dob: null,
      estDate: null,
      actualEstDate: null,
      patientCategories: [],
      categoryList: props.categoryListRedux ? props.categoryListRedux : [],
      insuranceList: props.insuranceListRedux ? props.insuranceListRedux : [],
      hospitalList: props.hospitalListRedux ? props.hospitalListRedux : [],
      patientImage: "",
      patientFaceSheet: "",
      patientImageName: "",
      patientFaceSheetName: "",
      isImage: false,
      isFaceSheet: false,
      isImageAdded: false,
      isFaceSheetAdded: false,
      patientImages: [],
      patientFaceSheets: [],
      patientImagesStr: [],
      patientFaceSheetsStr: [],
      patientUploadHistory: [],
      patientStatusId: "1",
      facilityType: dpFacilityCode ? VENDOR_FACILITY : MAXMRJ_FACILITY,
      dpFacilityCode: dpFacilityCode,
      isPatientImported: false,
      isUpdating: false,
    };
    this.handleImageSelectedFile = this.handleImageSelectedFile.bind(this);
    this.handleFaceSheetSelectedFile = this.handleFaceSheetSelectedFile.bind(
      this
    );
    this.imageFilesRemoveAll = this.imageFilesRemoveAll.bind(this);
    this.imageFilesRemoveOne = this.imageFilesRemoveOne.bind(this);
    this.onImageFilesChange = this.onImageFilesChange.bind(this);
    this.onImageFilesError = this.onImageFilesError.bind(this);
    this.onFaceSheetFilesChange = this.onFaceSheetFilesChange.bind(this);
    this.onFaceSheetFilesError = this.onFaceSheetFilesError.bind(this);
    this.fileSelectClick = this.fileSelectClick.bind(this);
    this.middleHandleFn = this.middleHandleFn.bind(this);
    this.handleEstDateError = this.handleEstDateError.bind(this);
    this.handleDobError = this.handleDobError.bind(this);
    this.handleSingleDpDChange = this.handleSingleDpDChange.bind(this);
    this.uploadImages = this.uploadImages.bind(this);
    this.deleteImages = this.deleteImages.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.patientDetail.patientId) {
      this.setState({
        firstNameValid: true,
        lastNameValid: true,
        genderValid: true,
        dobValid: true,
        estDateValid: true,
      });
    }
    const patientCategoryList = [];
    nextProps.patientDetail.patientCatagoryMapping &&
      nextProps.patientDetail.patientCatagoryMapping.map((el) => {
        return patientCategoryList.push(el.categoryId);
      });
    let localDobMoment = nextProps.patientDetail.patientDob
      ? moment
          .utc(nextProps.patientDetail.patientDob, "YYYY-MM-DD")
          .format("MM/DD/YYYY")
      : null;
    let localEstMoment = nextProps.patientDetail.eastimatedDischargeDate
      ? moment
          .utc(nextProps.patientDetail.eastimatedDischargeDate, "YYYY-MM-DD")
          .format("MM/DD/YYYY")
      : null;
    let actualEstDate = nextProps.patientDetail.actualDischargeDate
      ? moment
          .utc(nextProps.patientDetail.actualDischargeDate, "YYYY-MM-DD")
          .format("MM/DD/YYYY")
      : null;
    let isFaceSheetAdded =
      nextProps.patientDetail.faceSheet &&
      nextProps.patientDetail.faceSheet != ""
        ? true
        : false;
    let isImageAdded =
      nextProps.patientDetail.photo && nextProps.patientDetail.photo != ""
        ? true
        : false;
    this.setState({
      formErrors: {
        patientImage: "",
        patientFaceSheet: "",
      },
      patientImageValid: true,
      patientFaceSheetValid: true,
      patientImage: "",
      patientFaceSheet: "",
      patientImageName: "",
      patientFaceSheetName: "",
      isImage: false,
      isFaceSheet: false,
    });
    this.setState({
      firstName: nextProps.patientDetail.firstName,
      middleName: nextProps.patientDetail.middleName,
      lastName: nextProps.patientDetail.lastName,
      gender: nextProps.patientDetail.gender,
      isPatientImported: nextProps.patientDetail.thirdPartyPatientId
        ? true
        : false,
      dob: localDobMoment,
      estDate: localEstMoment,
      actualEstDate: actualEstDate,
      insurance: nextProps.patientDetail.insuranceId,
      hospital: nextProps.patientDetail.previousHospitalId,
      patientCategories: patientCategoryList,
      isImageAdded: isImageAdded,
      isFaceSheetAdded: isFaceSheetAdded,
      patientImages: [],
      patientFaceSheets: [],
      patientImagesStr: [],
      patientFaceSheetsStr: [],
      patientStatusId:
        nextProps.patientDetail && nextProps.patientDetail.patientStatusId
          ? nextProps.patientDetail.patientStatusId
          : "1",
      patientUploadHistory: nextProps.patientDetail.patientUploadHistory
        ? nextProps.patientDetail.patientUploadHistory
        : [],
    });
    this.maxDate = new Date();
    this.minDate = new Date();
  }
  handleChangeInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    value = value ? value.replace(/[^A-Za-z ]/gi, "") : "";
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  handleSingleDpDChange(value, name) {
    if (!value || value == "") {
      // return;
    }
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let addressValid = this.state.addressValid;
    let statusValid = this.state.statusValid;

    let firstNameValid = this.state.firstNameValid;
    let lastNameValid = this.state.lastNameValid;
    let middleNameValid = this.state.middleNameValid;
    let genderValid = this.state.genderValid;
    let insuranceValid = this.state.insuranceValid;
    let hospitalValid = this.state.hospitalValid;
    let dobValid = this.state.dobValid;
    let estDateValid = this.state.estDateValid;
    let patientCategotiesValid = this.state.patientCategotiesValid;

    switch (fieldName) {
      case "firstName":
        firstNameValid = fieldValue.trim().length > 0;
        fieldValidationErrors.firstName = firstNameValid
          ? ""
          : enMsg.firstNameRequiredMsg;
        break;
      case "middleName":
        middleNameValid =
          (fieldValue && fieldValue.trim().length > 0) || fieldValue == ""
            ? true
            : false;
        fieldValidationErrors.middleName = middleNameValid
          ? ""
          : enMsg.middleNameRequiredMsg;
        break;
      case "lastName":
        lastNameValid = fieldValue.trim().length > 0;
        fieldValidationErrors.lastName = lastNameValid
          ? ""
          : enMsg.lastNameRequiredMsg;
        break;
      case "gender":
        genderValid = fieldValue && fieldValue.trim().length > 0 ? true : false;
        fieldValidationErrors.gender = !genderValid
          ? enMsg.genderRequiredMsg
          : "";
        break;
      case "insurance":
        insuranceValid = fieldValue || fieldValue == "" ? true : false;
        fieldValidationErrors.insurance = !insuranceValid
          ? enMsg.insuranceRequiredMsg
          : "";
        break;
      case "hospital":
        hospitalValid = fieldValue || fieldValue == "" ? true : false;
        fieldValidationErrors.hospital = !hospitalValid
          ? enMsg.hospitalRequiredMsg
          : "";
        break;
      case "dob":
        dobValid = fieldValue && fieldValue != "" ? true : false;
        fieldValidationErrors.dob = !dobValid ? enMsg.dobRequiredMsg : "";
        break;
      // case "estDate":
      //     estDateValid = (fieldValue && fieldValue != "") ? true : false;
      //     fieldValidationErrors.estDate = !estDateValid
      //         ? enMsg.estDateRequiredMsg
      //         : "";
      //     break;
      case "patientCategoties":
        patientCategotiesValid =
          fieldValue || fieldValue.length == 0 ? true : false;
        fieldValidationErrors.patientCategoties = !patientCategotiesValid
          ? enMsg.categoryRequiredMsg
          : "";
        break;
      case "address":
        addressValid = fieldValue.trim().length > 0;
        fieldValidationErrors.address = addressValid
          ? ""
          : enMsg.addressRequiredErrorMsg;
        break;
      case "status":
        statusValid = fieldValue ? true : false;
        fieldValidationErrors.status = statusValid ? "" : "Status is required";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        firstNameValid: firstNameValid,
        lastNameValid: lastNameValid,
        middleNameValid: middleNameValid,
        genderValid: genderValid,
        insuranceValid: insuranceValid,
        hospitalValid: hospitalValid,
        dobValid: dobValid,
        estDateValid: estDateValid,
        patientCategotiesValid: patientCategotiesValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return (
      this.state.firstNameValid &&
      this.state.lastNameValid &&
      this.state.middleNameValid &&
      this.state.genderValid &&
      this.state.insuranceValid &&
      this.state.hospitalValid &&
      this.state.dobValid &&
      this.state.estDateValid &&
      this.state.patientCategotiesValid
    );
  }
  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  handleSubmit = async (event) => {
    event.preventDefault();
    // return
    this.setState({
      loading: true,
      // patientImagesStr: [],
      // patientFaceSheetsStr: [],
    });
    this.updatePatient();
    return;
    let imageKeyCount = 0;
    if (this.state.patientFaceSheets && this.state.patientFaceSheets.length) {
      this.state.patientFaceSheets.map((file, key) => {
        if (typeof file !== undefined && file) {
          // const fileReader = new FileReader();
          // fileReader.addEventListener("load", () => {
          //     let result = fileReader.result;
          //     let patientFaceSheetsStr = this.state.patientFaceSheetsStr
          //     let fileName = file.name;
          //     let filesObj = {};
          //     filesObj.base64Data = result;
          //     filesObj.uploadName = fileName;
          //     patientFaceSheetsStr.push(filesObj)
          //     if (imageKeyCount == key) {
          //         this.setState({
          //             patientFaceSheetsStr: patientFaceSheetsStr,
          //         }, () => this.middleHandleFn());
          //     } else {
          //         this.setState({
          //             patientFaceSheetsStr: patientFaceSheetsStr,
          //         });
          //     }
          //     imageKeyCount = imageKeyCount + 1;
          // });
          // if (file) {
          //     // fileReader.readAsDataURL(file);
          //     let result = this.toBase64(file)
          //     let patientFaceSheetsStr = this.state.patientFaceSheetsStr
          //     let fileName = file.name;
          //     let filesObj = {};
          //     filesObj.base64Data = result;
          //     filesObj.uploadName = fileName;
          //     patientFaceSheetsStr.push(filesObj)
          //     if (this.state.patientFaceSheets.length == key + 1) {
          //         this.setState({
          //             patientFaceSheetsStr: patientFaceSheetsStr,
          //         }, () => this.middleHandleFn());
          //     } else {
          //         this.setState({
          //             patientFaceSheetsStr: patientFaceSheetsStr,
          //         });
          //     }
          //     imageKeyCount = imageKeyCount + 1;
          // }
        }
      });
      let base94 = await Promise.all(
        this.state.patientFaceSheets.map(async (file, key) => {
          let result = await this.toBase64(file);
          let patientFaceSheetsStr = this.state.patientFaceSheetsStr;
          let fileName = file.name;
          let filesObj = {};
          filesObj.base64Data = result;
          filesObj.uploadName = fileName;
          patientFaceSheetsStr.push(filesObj);
          // this.setState({
          //     patientFaceSheetsStr: patientFaceSheetsStr,
          // });
          // if (this.state.patientFaceSheetsStr.length == imageKeyCount + 1) {
          //     this.setState({
          //         patientFaceSheetsStr: patientFaceSheetsStr,
          //     });
          //     return true;
          // } else {
          //     this.setState({
          //         patientFaceSheetsStr: patientFaceSheetsStr,
          //     });
          //     imageKeyCount = imageKeyCount + 1;
          //     return false;
          // }
          this.setState({
            patientFaceSheetsStr: patientFaceSheetsStr,
          });
          return true;
        })
      );
      setTimeout(
        function() {
          this.middleHandleFn();
        }.bind(this),
        1000
      );
    } else {
      this.setState(
        {
          patientFaceSheetsStr: [],
        },
        () => this.middleHandleFn()
      );
    }
    let imageArr = [];
    let facesheetKeyCount = 0;
    if (this.state.patientImages && this.state.patientImages.length) {
      this.state.patientImages.map((file) => {
        if (typeof file !== undefined && file) {
          const fileReader = new FileReader();
          fileReader.addEventListener("load", () => {
            let result = fileReader.result;
            let patientImagesStr = this.state.patientImagesStr;
            patientImagesStr.push(result);
            this.setState({
              patientImagesStr: patientImagesStr,
            });
            facesheetKeyCount = facesheetKeyCount + 1;
          });
          if (file) {
            fileReader.readAsDataURL(file);
          }
        }
      });
    }
    let totalSec =
      (this.state.patientImages.length + this.state.patientFaceSheets.length) *
      50;
  };
  middleHandleFn() {
    if (
      this.state.patientFaceSheetsStr.length ==
      this.state.patientFaceSheets.length
    ) {
      this.updatePatient();
    }
  }
  async updatePatient() {
    let showNotification = {};
    this.setState({ isUpdating: true });
    let localDobMoment = this.state.dob
      ? this.dateUtility.format(new Date(this.state.dob), "yyyy-MM-dd")
      : null;
    let localEstMoment = this.state.estDate
      ? this.dateUtility.format(new Date(this.state.estDate), "yyyy-MM-dd")
      : null;
    let localActualEstMoment = this.state.actualEstDate
      ? this.dateUtility.format(
          new Date(this.state.actualEstDate),
          "yyyy-MM-dd"
        )
      : null;
    let faceSheet = this.state.isFaceSheet ? this.state.patientFaceSheet : null;
    let image = this.state.isImage ? this.state.patientImage : null;

    const data = {
      patientId: this.props.patientDetail.patientId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      middleName: this.state.middleName,
      insuranceId: this.state.insurance,
      previousHospitalId: this.state.hospital,
      gender: this.state.gender,
      dob: localDobMoment,
      estimatedDischargedate: localEstMoment,
      actualDischargeDate: localActualEstMoment,
      // actualDischargeDate: this.state.actualEstDate ? new Date(this.state.actualEstDate) : null,
      // patientStatusId: "7",
      patientStatusId: this.state.patientStatusId,
      // categoryId: this.state.patientCategories,
      categoryId: this.state.patientCategories.sort(function(a, b) {
        return a - b;
      }),
      createdById: this.props.patientDetail.createdById,
    };
    // if (this.state.patientFaceSheetsStr && this.state.patientFaceSheetsStr.length) {
    //     data.faceSheet = this.state.patientFaceSheetsStr;
    // }
    // if (this.state.patientImagesStr && this.state.patientImagesStr.length) {
    //     data.photo = this.state.patientImagesStr;
    // }
    // return;
    showNotification = {
      title: "Update Patient",
      message: "Updating Patient, Please Wait",
      type: "info",
    };
    userService.showNotification(showNotification);
    showNotification = {};
    this.setState({
      loading: true,
      isUpdating: true,
    });
    Axios.put(apiPath.createPatient, data, {
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        const { data } = response;
        if (data.length > 0 && data[0] && data[0].patientId) {
          let finalData = data[0];
          showNotification = {
            title: "Success",
            message: "Patient updated",
            type: "success",
          };
          let patientList = JSON.parse(sessionStorage.getItem(PATIENT_LIST));
          let localPatientList = [];
          localPatientList = patientList.map((pList, key) => {
            if (pList.patientId == data[0].patientId) {
              pList = data[0];
            }
            return pList;
          });
          sessionStorage.setItem(
            PATIENT_LIST,
            JSON.stringify(localPatientList)
          );
          this.uploadImages(data[0].patientId);
          userService.showNotification(showNotification);
          // this.setState({ loading: false });
          this.setState({ isUpdating: false });
          return true;
        } else {
          this.setState({ loading: false });
          this.props.fetchPatient();
          this.props.updatePatientModalClose();
          return true;
        }
      })
      .catch((error) => {
        console.log(error.response, "Error");
        showNotification = {
          title: enMsg.updatePatientFailedTitle,
          message: error.response.data?.title || enMsg.updatePatientFailedDesc,
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false, isUpdating: false });
      });

    // const response = fetch(apiPath.createPatient, {
    //   method: "PUT",
    //   headers: generalAuthTokenHeader(),
    //   body: JSON.stringify(data),
    //   data: JSON.stringify(data),
    // })
    //   .then((response) => {
    //     // debugger;
    //     // if (response.status === 400) {
    //     //   showNotification = {
    //     //     title: enMsg.updatePatientFailedTitle,
    //     //     message: "Bad response from server",
    //     //     type: "danger",
    //     //   };
    //     // } else if (response.ok) {
    //     // } else {
    //     //   showNotification = {
    //     //     title: enMsg.updatePatientFailedTitle,
    //     //     message: enMsg.updatePatientFailedDesc,
    //     //     type: "danger",
    //     //   };
    //     //   let error = new Error(response.statusText);
    //     // }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     // debugger;
    //     // if (data.status == 400) {
    //     // }
    //     if (data.status >= 400) {
    //       showNotification = {
    //         title: enMsg.updatePatientFailedTitle,
    //         message: data.title,
    //         type: "danger",
    //       };
    //       throw new Error(showNotification);
    //     }
    //     if (data.length > 0 && data[0] && data[0].patientId) {
    //       let finalData = data[0];
    //       showNotification = {
    //         title: "Success",
    //         message: "Patient updated",
    //         type: "success",
    //       };
    //       let patientList = JSON.parse(sessionStorage.getItem(PATIENT_LIST));
    //       let localPatientList = [];
    //       localPatientList = patientList.map((pList, key) => {
    //         if (pList.patientId == data[0].patientId) {
    //           pList = data[0];
    //         }
    //         return pList;
    //       });
    //       sessionStorage.setItem(
    //         PATIENT_LIST,
    //         JSON.stringify(localPatientList)
    //       );
    //       this.uploadImages(data[0].patientId);
    //       userService.showNotification(showNotification);
    //       // this.setState({ loading: false });
    //       this.setState({ isUpdating: false });
    //       return true;
    //     } else {
    //       this.setState({ loading: false });
    //       this.props.fetchPatient();
    //       this.props.updatePatientModalClose();
    //       return true;
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(JSON.parse(error));
    //     debugger;
    //     showNotification = {
    //       title: enMsg.updatePatientFailedTitle,
    //       message: enMsg.updatePatientFailedDesc,
    //       type: "danger",
    //     };
    //     userService.showNotification(showNotification);
    //     this.setState({ loading: false });
    //   });
  }
  async uploadImages(patientId) {
    let showNotification = {};
    if (this.state.patientFaceSheets && !this.state.patientFaceSheets.length) {
      this.setState({ loading: false });
      this.props.updatePatientModalClose();
      this.props.fetchPatient();
      return true;
    } else {
      showNotification = {
        title: "Uploading Files",
        message: "Uploading Files, Please Wait",
        type: "info",
      };
      userService.showNotification(showNotification);
      showNotification = {};
    }
    const data = {
      files: this.state.patientFaceSheets.length
        ? this.state.patientFaceSheets
        : [],
    };
    this.setState({
      loading: true,
    });
    const datas = new FormData();
    this.state.patientFaceSheets.map((files, key) => {
      datas.append("files", files);
    });
    const response = await fetch(
      apiPath.uploadPatientFiles1 + "/" + patientId,
      {
        method: "POST",
        headers: generalMimeAuthTokenHeader(),
        body: datas,
        data: datas,
      }
    )
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Patient Files",
            message: "Patient Files Not Uploaded, Please try again",
            type: "danger",
          };
        } else if (response.ok) {
          showNotification = {
            title: "Patient Files",
            message: "Patient Files Uploaded",
            type: "success",
          };
          return response.text();
        } else {
          showNotification = {
            title: "Patient Files",
            message: "Patient Files Not Uploaded, Please try again",
            type: "danger",
          };
        }
        this.setState({ loading: false });
        this.props.updatePatientModalClose();
        return response.json();
      })
      .then((data) => {
        this.props.fetchPatient();
        this.props.updatePatientModalClose();
        this.setState({ loading: false });
        userService.showNotification(showNotification);
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: "Patient Files",
          message: "Patient Files Not Uploaded, Please try again",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        this.props.updatePatientModalClose();
        this.props.fetchPatient();
      });
  }
  async deleteImages(facesheetId) {
    let showNotification = {};
    this.setState({
      loading: true,
    });
    const response = await fetch(
      apiPath.deleteFaceSheeet + "?facesheetId=" + facesheetId,
      {
        method: "DELETE",
        headers: generalAuthTokenHeader(),
      }
    )
      .then((response) => {
        console.log(response);
        if (response.status === 400) {
          showNotification = {
            title: "Delete File",
            message: "Bad response from server",
            type: "danger",
          };
          this.setState({
            loading: false,
          });
        } else if (response.ok) {
          showNotification = {
            title: "Success",
            message: "File Successfully Deleted",
            type: "success",
          };
          let faceArr = [];
          faceArr = this.state.patientUploadHistory.filter((v, i) => {
            return v.id != facesheetId;
          });
          this.setState({
            patientUploadHistory: faceArr,
          });
          this.props.fetchPatient();
          return response.text();
        } else {
          showNotification = {
            title: "Delete File",
            message: "Bad response from server",
            type: "danger",
          };
          this.setState({
            loading: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          loading: false,
        });
        userService.showNotification(showNotification);
      })
      .catch((error) => {
        showNotification = {
          title: "Delete File",
          message: "Something went wrong.Please try after sometime..",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        this.modalClose();
      });
  }
  selectPatientCategory = (catagoryId) => {
    let patientCategories = this.state.patientCategories;
    if (patientCategories.includes(catagoryId)) {
      const index = patientCategories.indexOf(catagoryId);
      if (index > -1) {
        patientCategories.splice(index, 1);
      }
    } else {
      patientCategories.push(catagoryId);
    }
    this.setState(
      {
        patientCategories: patientCategories,
      },
      () => {
        this.validateField("patientCategoties", patientCategories);
      }
    );
  };
  handleDob = (value) => {
    let dob = true;
    if (value == null || !this.dateUtility.isValid(value)) {
      dob = false;
    }
    let cValue = moment(value).format();
    this.setState(
      {
        dob: value,
        dobValid: dob,
      },
      () => {
        this.validateField("dob", value);
      }
    );
  };
  handleActualEstDate = (value) => {
    let actualEstDate = true;
    if (value && !this.dateUtility.isValid(value)) {
      actualEstDate = false;
    }
    this.setState(
      {
        actualEstDate: value,
        actualEstDateValid: actualEstDate,
      },
      () => {
        this.validateField("actualEstDate", value);
      }
    );
  };
  handleEstDate = (value) => {
    let estDate = true;
    if (value && !this.dateUtility.isValid(value)) {
      estDate = false;
    }
    this.setState(
      {
        estDate: value,
        estDateValid: estDate,
      },
      () => {
        this.validateField("estDate", value);
      }
    );
  };
  handleDobError(error, value) {
    this.setState({
      dobValid: error || value == "" || value == null ? false : true,
    });
  }
  handleActualEstDateError(error, value) {
    // console.log(error, value)
    // this.setState({
    //     estDateValid: error ? false : true
    // });
  }
  handleEstDateError(error, value) {
    // this.setState({
    //     estDateValid: error || value == "" || value == null ? false : true
    // });
  }
  onImageFileBrowse = (event) => {
    this.inputOpenImageFileRef.current.click();
  };
  onFaceSheetFileBrowse = (event) => {
    this.inputOpenFaceSheetFileRef.current.click();
  };
  handleImageSelectedFile = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    let fieldValidationErrors = this.state.formErrors;
    let error = "";
    let patientImageValid = false;
    if (typeof file == "object" && file.size > ALLOWED_PROFILE_IMAGE_SIZE) {
      error = enMsg.allowedProfileImageSize;
    } else if (
      typeof file == "object" &&
      !ALLOWED_IMAGE_EXTENTION.includes(file.type)
    ) {
      error = enMsg.allowedProfileImageType;
    } else {
      patientImageValid = true;
    }
    if (typeof file !== undefined && file && patientImageValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        let patientImages = this.state.patientImages;
        let fileArr = {};
        fileArr["fileName"] = file.name;
        fileArr["fileStr"] = result;
        patientImages.push(fileArr);
        this.setState({
          patientImage: result,
          patientImageName: file.name,
          patientImages: patientImages,
          isImage: true,
        });
        this.patientImageBlob = file;
        this.patientImageName = file.name;
      });
      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
    fieldValidationErrors.patientImage = patientImageValid ? "" : error;
    this.setState(
      {
        patientImageValid: patientImageValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };
  imageFilesRemoveAll(fileType) {
    let fieldValidationErrors = this.state.formErrors;
    if (fileType == "facesheet") {
      fieldValidationErrors.patientFaceSheet = "";
      this.setState({
        isFaceSheet: false,
        patientFaceSheetValid: true,
        formErrors: fieldValidationErrors,
      });
      this.refs.patientFaceSheets.removeFiles();
    } else {
      fieldValidationErrors.patientImage = "";
      this.setState({
        isImage: false,
        patientImageValid: true,
        formErrors: fieldValidationErrors,
      });
      this.refs.patientImages.removeFiles();
    }
  }
  imageFilesRemoveOne(file, fileType) {
    let fieldValidationErrors = this.state.formErrors;
    if (fileType == "facesheet") {
      fieldValidationErrors.patientFaceSheet = "";
      this.setState({
        patientFaceSheetValid: true,
        formErrors: fieldValidationErrors,
      });
      this.refs.patientFaceSheets.removeFile(file);
    } else {
      fieldValidationErrors.patientImage = "";
      this.setState({
        patientImageValid: true,
        formErrors: fieldValidationErrors,
      });
      this.refs.patientImages.removeFile(file);
    }
  }
  handleFaceSheetSelectedFile = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    let fieldValidationErrors = this.state.formErrors;
    let error = "";
    let patientFaceSheetValid = false;
    if (typeof file == "object" && file.size > ALLOWED_FACESHEET_SIZE) {
      error = enMsg.allowedFaceSheetSize;
    } else if (
      typeof file == "object" &&
      !ALLOWED_FACESHEET_EXTENTION.includes(file.type)
    ) {
      error = enMsg.allowedFaceSheetType;
    } else {
      patientFaceSheetValid = true;
    }
    if (typeof file !== undefined && file && patientFaceSheetValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        let patientFaceSheets = this.state.patientFaceSheets;
        let fileArr = {};
        fileArr["fileName"] = file.name;
        fileArr["fileStr"] = result;
        patientFaceSheets.push(fileArr);
        this.setState({
          patientFaceSheet: result,
          patientFaceSheetName: file.name,
          isFaceSheet: true,
          patientFaceSheets: patientFaceSheets,
        });
        this.patientFaceSheetBlob = file;
        this.patientFaceSheetName = file.name;
      });
      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
    fieldValidationErrors.patientFaceSheet = patientFaceSheetValid ? "" : error;
    this.setState(
      {
        patientFaceSheetValid: patientFaceSheetValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };
  onImageFilesChange = (patientImages) => {
    this.setState({
      patientImages,
    });
  };
  fileSelectClick = (fileType) => {
    let fieldValidationErrors = this.state.formErrors;
    if (fileType == "facesheet") {
      fieldValidationErrors.patientFaceSheet = "";
      this.setState({
        isFaceSheet: false,
        patientFaceSheetValid: true,
        formErrors: fieldValidationErrors,
        patientFaceSheetsStr: [],
      });
    } else {
      fieldValidationErrors.patientImage = "";
      this.setState({
        isImage: false,
        patientImageValid: true,
        formErrors: fieldValidationErrors,
        patientImagesStr: [],
      });
    }
  };
  onFaceSheetFilesChange = (patientFaceSheets) => {
    this.setState({
      patientFaceSheets,
    });
  };
  onImageFilesError = (error, file) => {
    let errorMsg = "";
    switch (error.code) {
      case 1:
        errorMsg = error.message;
        break;
      case 2:
        errorMsg = error.message;
        break;
      case 3:
        errorMsg = error.message;
        break;
      case 4:
        errorMsg = error.message;
        break;
      default:
        errorMsg = "File not found";
        break;
    }
    let fieldValidationErrors = this.state.formErrors;
    let patientImageValid = false;
    fieldValidationErrors.patientImage = patientImageValid ? "" : errorMsg;
    this.setState(
      {
        patientImageValid: patientImageValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };
  onFaceSheetFilesError = (error, file) => {
    let errorMsg = "";
    switch (error.code) {
      case 1:
        errorMsg = error.message;
        break;
      case 2:
        errorMsg = error.message;
        break;
      case 3:
        errorMsg = error.message;
        break;
      case 4:
        errorMsg = error.message;
        break;
      default:
        errorMsg = "File not found";
        break;
    }
    let fieldValidationErrors = this.state.formErrors;
    let patientFaceSheetValid = false;
    fieldValidationErrors.patientFaceSheet = patientFaceSheetValid
      ? ""
      : errorMsg;
    this.setState(
      {
        patientFaceSheetValid: patientFaceSheetValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };
  async testApi() {
    let url =
      "https://connect.pointclickcare.com/auth/login?client_id=eN2VehceGkQAe9XfDKZfl9WfAtoeYyaQ&response_type=code&redirect_uri=https://stage.maxmrj.com/maxmrj/pccauth&state=1101";
    // let url = "http://localhost:3000/maxmrj/pccauth?code=ASxDKFEc&state=1101";
    var left = window.screen.width / 2 - 500 / 2;
    var top = window.screen.height / 2 - 500 / 2;
    let newwindow = window.open(
      url,
      "testing",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        500 +
        ", height=" +
        500 +
        ", top=" +
        top +
        ", left=" +
        left
    );
    if (window.focus) {
      newwindow.focus();
    }
    return false;
    let response = await fetch(url, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
        return response;
      });
  }
  render() {
    const {
      total,
      sorting,
      filter,
      patientCategoryList,
      loading,
      patientName,
      selectedPatientId,
      patientList,
      hospital,
      insurance,
      gender,
      insuranceList,
      categoryList,
      hospitalList,
      patientCategories,
      selectionCount,
      totalPatientShowing,
      facilityType,
      isPatientImported,
    } = this.state;
    return (
      <React.Fragment>
        <Dialog
          open={this.props.patientModalStatus}
          maxWidth={"sm"}
          onClose={this.props.updatePatientModalClose}
          scroll="body"
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal modalFitOnScreen"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Edit Patient
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.props.updatePatientModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.handleSubmit} noValidate>
            <DialogContent>
              <Box display="flex" justifyContent="flex-start" mb={1} mt={1}>
                <Box width={1 / 2} pr={2}>
                  <TextField
                    InputLabelProps={{ className: "required-label" }}
                    name="firstName"
                    label="First Name"
                    value={this.state.firstName}
                    onChange={this.handleChangeInput}
                    inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                    disabled={isPatientImported ? true : false}
                  />
                  <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  />
                </Box>
                <Box width={1 / 2} pl={2}>
                  <TextField
                    InputLabelProps={{ className: "required-label" }}
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChangeInput}
                    data-validators="isRequired"
                    label="Last Name"
                    inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                    disabled={isPatientImported ? true : false}
                  />
                  <FormErrors
                    show={!this.state.lastNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="lastName"
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <TextField
                    // InputLabelProps={{ className: "required-label" }}
                    name="middleName"
                    value={this.state.middleName}
                    onChange={this.handleChangeInput}
                    // data-validators="isRequired"
                    label="Middle Name"
                    inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                    disabled={isPatientImported ? true : false}
                  />
                  <FormErrors
                    show={!this.state.middleNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="middleName"
                  />
                </Box>
                <Box width={1 / 2} pl={2}>
                  <FormControl>
                    <InputLabel className={"required-label"}>Gender</InputLabel>
                    <Select
                      id="gender"
                      color="secondary"
                      name="gender"
                      value={this.state.gender}
                      onChange={(event) =>
                        this.handleSingleDpDChange(event.target.value, "gender")
                      }
                      disabled={isPatientImported ? true : false}
                    >
                      <MenuItem value="">Select Gender</MenuItem>
                      <MenuItem key={"male"} value={"male"}>
                        Male
                      </MenuItem>
                      <MenuItem key={"female"} value={"female"}>
                        Female
                      </MenuItem>
                      <MenuItem key={"other"} value={"other"}>
                        Other
                      </MenuItem>
                    </Select>
                    <FormErrors
                      show={!this.state.genderValid}
                      formErrors={this.state.formErrors}
                      fieldName="gender"
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <FormControl variant="outlined">
                    <MuiPickersUtilsProvider
                      variant="outlined"
                      utils={DateFnsUtils}
                      // utils={UTCUtils}
                      moment={moment}
                    >
                      <KeyboardDatePicker
                        label="Date Of Birth"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="start-date-picker-dialog"
                        InputLabelProps={{
                          className: "required-label",
                        }}
                        name="dob"
                        animateYearScrolling={true}
                        // value={this.state.dob}
                        value={this.state.dob}
                        maxDate={this.maxDate}
                        // minDate={this.minDate}
                        maxDateMessage={enMsg.dobMaxDate}
                        onChange={this.handleDob}
                        onError={this.handleDobError}
                        className="KeyboardDatePicker"
                        invalidDateMessage={enMsg.invalidDate}
                        // closeAfterSelect={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          className: "date-picker-span",
                        }}
                        disabled={isPatientImported ? true : false}
                      />
                    </MuiPickersUtilsProvider>
                    <FormErrors
                      show={!this.state.dobValid}
                      formErrors={this.state.formErrors}
                      fieldName="dob"
                    />
                  </FormControl>
                </Box>
                <Box width={1 / 2} pl={2}>
                  <FormControl>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      moment={moment}
                    >
                      <KeyboardDatePicker
                        label="Estimate Discharge Date"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="start-date-picker-dialog"
                        InputLabelProps={
                          {
                            // className: "required-label"
                          }
                        }
                        InputProps={{ autoComplete: "off" }}
                        name="estDate"
                        animateYearScrolling={true}
                        value={this.state.estDate}
                        // minDate={this.minDate}
                        // minDateMessage={enMsg.estMinDate}
                        onChange={this.handleEstDate}
                        onError={this.handleEstDateError}
                        className="KeyboardDatePicker"
                        invalidDateMessage={enMsg.invalidDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          className: "date-picker-span",
                        }}
                        // disabled={isPatientImported ? true : false}
                      />
                    </MuiPickersUtilsProvider>
                    <FormErrors
                      show={!this.state.estDateValid}
                      formErrors={this.state.formErrors}
                      fieldName="estDate"
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box width={1} display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="Discharge Date"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="start-date-picker-dialog"
                        InputLabelProps={
                          {
                            // className: "required-label"
                          }
                        }
                        InputProps={{ autoComplete: "off" }}
                        name="actualEstDate"
                        animateYearScrolling={true}
                        value={this.state.actualEstDate}
                        maxDate={new Date()}
                        maxDateMessage={enMsg.maxDate}
                        onChange={this.handleActualEstDate}
                        onError={this.handleActualEstDateError}
                        className="KeyboardDatePicker"
                        invalidDateMessage={enMsg.invalidDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          className: "date-picker-span",
                        }}
                        disabled={isPatientImported ? true : false}
                      />
                    </MuiPickersUtilsProvider>
                    <FormErrors
                      show={!this.state.actualEstDateValid}
                      formErrors={this.state.formErrors}
                      fieldName="actualEstDate"
                    />
                  </FormControl>
                </Box>
                <Box width={1 / 2} pl={2}></Box>
              </Box>
              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <FormControl>
                    <InputLabel>Insurance</InputLabel>
                    <Select
                      id="insurance"
                      color="secondary"
                      name="insurance"
                      value={this.state.insurance}
                      onChange={(event) =>
                        this.handleSingleDpDChange(
                          event.target.value,
                          "insurance"
                        )
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                        variant: "menu",
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={0}>No Insurance</MenuItem>
                      {typeof insuranceList === "object" && insuranceList.length
                        ? insuranceList
                            // .sort((a, b) => a.insuranceId > b.insuranceId ? 1 : -1)
                            .map((iList, key) => {
                              if (iList.insuranceId != 0) {
                                return (
                                  <MenuItem
                                    key={"insurance_" + iList.insuranceId}
                                    value={iList.insuranceId}
                                  >
                                    {iList.insuranceName}
                                  </MenuItem>
                                );
                              }
                            })
                        : null}
                    </Select>
                    <FormErrors
                      show={!this.state.insuranceValid}
                      formErrors={this.state.formErrors}
                      fieldName="insurance"
                    />
                  </FormControl>
                </Box>
                <Box width={1 / 2} pl={2}>
                  <FormControl className="pos-rel">
                    <InputLabel>Hospital Restriction </InputLabel>
                    <Select
                      id="hospital"
                      color="secondary"
                      name="hospital"
                      value={this.state.hospital}
                      onChange={(event) =>
                        this.handleSingleDpDChange(
                          event.target.value,
                          "hospital"
                        )
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                        variant: "menu",
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="">Select Hospital</MenuItem>
                      {typeof this.state.hospitalList === "object" &&
                      this.state.hospitalList.length
                        ? this.state.hospitalList.map((hList, key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={hList.previousHospitalId}
                              >
                                {hList.hospitalName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                    <FormErrors
                      show={!this.state.hospitalValid}
                      formErrors={this.state.formErrors}
                      fieldName="hospital"
                    />
                    <Tooltip title="Select Hospital" placement="top" arrow>
                      <img className="info-icon" src={infoIcon} />
                    </Tooltip>
                  </FormControl>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                mb={1}
                style={{ display: "" }}
              >
                <Box width={1 / 2} pr={2} className="upload-links">
                  <FormControl>
                    <div className="form-caption">Face Sheet</div>
                    <Files
                      ref="patientFaceSheets"
                      className="files-dropzone-list"
                      // style={{ height: '100px' }}
                      onChange={this.onFaceSheetFilesChange}
                      onError={this.onFaceSheetFilesError}
                      accepts={[
                        "image/png",
                        "image/x-png",
                        "image/jpg",
                        "image/jpeg",
                        "application/pdf",
                      ]}
                      multiple
                      maxFiles={20}
                      maxFileSize={1024 * 1024 * 20}
                      minFileSize={0}
                      clickable
                    >
                      <Button
                        className="btn1"
                        onClick={() => this.fileSelectClick("facesheet")}
                      >
                        <MaterialLink className="pointer">
                          <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                          Upload
                        </MaterialLink>
                      </Button>
                    </Files>
                    <FormErrors
                      show={!this.state.patientFaceSheetValid}
                      formErrors={this.state.formErrors}
                      fieldName="patientFaceSheet"
                    />
                    {this.state.patientFaceSheets.length > 0 ? (
                      <List>
                        <span
                          className="pemoveAllLink"
                          onClick={() => this.imageFilesRemoveAll("facesheet")}
                        >
                          Remove All Files
                          <IconButton edge="end" aria-label="delete all">
                            <DeleteIcon />
                          </IconButton>
                        </span>

                        <Scrollbars
                          className="uploadFilesList"
                          style={{ height: "60px" }}
                          universal={true}
                        >
                          {this.state.patientFaceSheets.map((file, key) => (
                            <ListItem className="files-list-item" key={key}>
                              <ListItemText>
                                <div className="files-list-item-content">
                                  <div className="files-list-item-content-item files-list-item-content-item-1">
                                    {file.name}
                                  </div>
                                </div>
                              </ListItemText>
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() =>
                                    this.imageFilesRemoveOne(file, "facesheet")
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </Scrollbars>
                      </List>
                    ) : null}
                    <p
                      className={`imageName ${
                        this.state.isFaceSheetAdded ? "imageAttached" : ""
                      }`}
                    >
                      {this.state.isFaceSheetAdded ? "FaceSheet Attched" : ""}
                    </p>
                  </FormControl>
                </Box>
                <Box width={1 / 2} pl={2} className="upload-links">
                  {this.state.patientUploadHistory &&
                  this.state.patientUploadHistory.length > 0 ? (
                    <List>
                      <div className="form-caption">Uploaded Face Sheet</div>
                      <Scrollbars
                        className="uploadFilesList"
                        autoHide={false}
                        universal={true}
                        autoHeight={true}
                        autoHeightMin={0}
                        autoHeightMax={60}
                      >
                        {this.state.patientUploadHistory.map((file, key) => (
                          <ListItem className="files-list-item" key={key}>
                            <ListItemText>
                              <div className="files-list-item-content">
                                <div className="files-list-item-content-item files-list-item-content-item-1">
                                  {file.uploadName}
                                </div>
                              </div>
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="blocked"
                                onClick={() => this.deleteImages(file.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </Scrollbars>
                    </List>
                  ) : null}
                  {this.state.loading ? (
                    <div class="modal_loader">
                      <div class="preloader">
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              <Box>
                <div className={"form-caption"}>Category Criteria</div>

                <FormControl>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexWrap={"wrap"}
                  >
                    {typeof categoryList === "object" && categoryList.length
                      ? categoryList.map((cList, key) => {
                          const {
                            categoryValue,
                            catagoryId,
                            status,
                            parent,
                          } = cList;
                          if (parent == 0) {
                            return (
                              <Chip
                                key={key}
                                icon={
                                  <Checkbox
                                    key={key}
                                    icon={<CircleUnchecked />}
                                    checkedIcon={<CircleCheckedFilled />}
                                    checked={
                                      this.state.patientCategories.includes(
                                        catagoryId
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={categoryValue}
                                onClick={this.selectPatientCategory.bind(
                                  this,
                                  catagoryId
                                )}
                                className={
                                  this.state.patientCategories.includes(
                                    catagoryId
                                  )
                                    ? "chipinput activeCat"
                                    : "chipinput inactiveCat"
                                }
                              />
                            );
                          }
                        })
                      : "No category available..."}
                  </Box>

                  <FormErrors
                    show={!this.state.patientCategotiesValid}
                    formErrors={this.state.formErrors}
                    fieldName="patientCategoties"
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions className="modal-actions" justify="center">
              <Button
                type="submit"
                className="btn1"
                disabled={
                  !this.validateForm() ||
                  this.state.loading ||
                  this.state.isUpdating !== false
                }
              >
                {this.state.loading ||
                  (this.state.isUpdating !== false && (
                    <CircularProgress
                      size={24}
                      className="buttonProgress"
                      color="secondary"
                    />
                  ))}
                Update Patient
              </Button>
              <Button
                onClick={this.props.updatePatientModalClose}
                className="cancel-link"
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }
}
export const consPatientForm = connect(mapStateToProps)(PatientForm);
export default consPatientForm;
