import React from 'react'

function Banner(props) {
    return (
        <div className="banner-box type-1">
            <p>{props.message}</p>
        </div>
    )
}

export default Banner
