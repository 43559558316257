import React from "react"
import GridItem from "components/Grid/GridItem.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from "@material-ui/core"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import { FormErrors } from "../../components/Login/FormErrors"
import { userService } from "_services/user.service"
import enMsg from "__helpers/locale/en/en"
import ConfirmModal from 'components/Modal/modal'
import Icon from '@material-ui/core/Icon'
import { apiPath } from 'api'
import { generalAuthTokenHeader } from "__helpers/auth-header"
import { Table, TableHead, TableBody, TableCell, TableRow, TableSortLabel, TablePagination,Button } from '@material-ui/core';
import { PER_PAGE_OPTIONS, DEFAULT_PER_PAGE_RECORDS, ONLY_NUMBER_REGEX, EMAIL_REGEX, ONLY_AlPHABETS_REGEX } from "__helpers/constants"
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    InputLabel,
    Select,
    Slide
} from "@material-ui/core";
import SelectStatus from 'components/SelectStatus/selectStatus'
import SwitchToggle from 'components/SwitchToggle/SwitchToggle'
import DialogAction from "components/DialogActions/DialogAction"

import CircularProgress from "@material-ui/core/CircularProgress";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
  });
const headCells = [
    { id: 'id', numeric: false, disablePadding: false, sorting: true, label: 'Id' },
    { id: 'user.firstName', numeric: false, disablePadding: false, sorting: true, label: 'User Name' },
    { id: 'provider', numeric: false, disablePadding: false, sorting: true, label: 'Assosciated Provider' },
    //{ id: 'user.address', numeric: false, disablePadding: false, sorting: true, label: 'Address' },
    { id: 'phone', numeric: false, disablePadding: false, sorting: false, label: 'Phone' },
    { id: 'user.activated', numeric: false, disablePadding: false, sorting: false, label: 'Status' },
    { id: 'action', numeric: false, disablePadding: false, sorting: false, label: 'Action' },
];
class ProviderUserIndex extends React.Component {

    constructor(props) {
        super(props)
        let spinner = document.getElementById('loadingSpinner');

        this.state = {
            loading: false,
            modalStatus: false,
            confirmModalStatus: false,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            address: '',
            status: true,
            checked: false,
            confirmModalMessage: '',
            dataId:'',
            spinner: spinner,
            perPage: DEFAULT_PER_PAGE_RECORDS,
            total: '',
            currentPage: 0,
            orderBy: 'id',
            providerUsers: [],
            assosciatedProvider: [],
            provider: '',
            isDelete: false,
            searchKeyword: '',
            formErrors: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                provider: '',
                status: '',
                address: '',
            },
            firstNameValid: false,
            lastNameValid: false,
            emailValid: false,
            phoneNumberValid: false,
            providerValid: false,
            addressvalid: false,

        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchProviderUsers = this.fetchProviderUsers.bind(this);
        this.confirmModalSubmit = this.confirmModalSubmit.bind(this);
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        this.fetchProviderUsers();
        const response = fetch(apiPath.getActiveProviders, {
            method: "GET",
            headers: generalAuthTokenHeader()
        })
            .then(response => {
                if (response.status === 400) {
                } else if (response.ok) {
                }
                else {
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    assosciatedProvider: data
                })
            })
            .catch(error => {
                
                return response;
            });
    }

    async fetchProviderUsers(searchKeyword) {
        this.state.spinner.removeAttribute('hidden', 'true');
        let showNotification = {};
        let apiUrl= '';
        if(searchKeyword){
            apiUrl = apiPath.getProviderUsers + '?page=' + this.state.currentPage + '&size=' + this.state.perPage + '&sort=' + this.state.orderBy + ',' + (this.state.direction ? 'asc' : 'desc') + '&search='+searchKeyword;
            
        }else{
            apiUrl = apiPath.getProviderUsers + '?page=' + this.state.currentPage + '&size=' + this.state.perPage + '&sort=' + this.state.orderBy + ',' + (this.state.direction ? 'asc' : 'desc') + '';
        }
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: generalAuthTokenHeader()
        })
            .then(response => {
                if (response.status === 400) {
                    showNotification = {
                        title: 'Provider User',
                        message: 'Bad response from server',
                        type: "danger"
                    };
                } else if (response.ok) {
                    this.setState({
                        total: response.headers.get('X-Total-Count')
                    })
                } else if (response.status === 401) {
                    userService.logout();
                    window.location.reload(true);
                }
                else {
                    showNotification = {
                        title: 'Provider User',
                        message: 'Bad response from server',
                        type: "danger"
                    };
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    providerUsers: data
                })
            })
            .catch(error => {
                showNotification = {
                    title: 'Provider User',
                    message: 'Bad response from server',
                    type: "danger"
                };
                return response;
            });

        userService.showNotification(showNotification);
        this.state.spinner.setAttribute('hidden', 'true');
    }
    modalOpen = (dataId) => {
        if (!isNaN(dataId)) {
            this.state.providerUsers.map(providerUser => {
                if (providerUser.id == dataId) {
                    this.setState({
                        provider: providerUser.provider.id,
                         address: "providerUser.user.address",
                        firstName: providerUser.user.firstName,
                        lastName: providerUser.user.lastName,
                        phoneNumber: providerUser.user.phoneNumber,
                        status: providerUser.user.activated,
                        email: providerUser.user.email,
                        dataId: dataId,
                        firstNameValid: true,
                        lastNameValid: true,
                        emailValid: true,
                        phoneNumberValid: true,
                        providerValid: true,
                       // addressValid: true,
                    });
                }
            });
        } else {
            this.setState({
                provider: '',
                address: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                status: true,
                dataId: '',
                firstNameValid: false,
                lastNameValid: false,
                emailValid: false,
                phoneNumberValid: false,
                providerValid: false
            })
        }
        this.setState({
            modalStatus: true
        })

    }

    modalClose = () => {
        this.setState({
            modalStatus: false
        })
    };
    handleChangeInput = event => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        }, () => { this.validateField(name, value) })
    }

    handleSubmit(event) {
        event.preventDefault();
        let data = {
            firstName: this.state.firstName,
            middleName: '',
            lastName: this.state.lastName,
            email: this.state.email,
            address: "",
            phoneCode: "+1",
            phoneNumber: this.state.phoneNumber,
            activated: this.state.status,
            providerId: this.state.provider,
            imageUrl: "string",
            langKey: "string",
            login: "1",
        };
        if (this.state.dataId) {
            data.id = this.state.dataId;
            this.updateProviderUser(data, false);
        } else {
            this.createProviderUser(data);
        }
    }

    async createProviderUser(data){
        this.setState({loading: true});
        let showNotification = {};
        let modalCloseStatus = 1;
        const response = await fetch(apiPath.getProviderUsers, {
            method: "POST",
            headers: generalAuthTokenHeader(),
            body: JSON.stringify(data),
            data: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 400) {
                    showNotification = {
                        title: 'Provider User',
                        message: enMsg.badResponseFromServer,
                        type: "danger"
                    };
                } else if (response.ok) {
                } else {
                    showNotification = {
                        title: 'Provider User',
                        message: enMsg.badResponseFromServer,
                        type: "danger"
                    };
                }
                return response.json();
            })
            .then(data => {
                if(data.status == '400' && data.message == 'error.idexists'){
                    modalCloseStatus = 0;
                    showNotification = {
                        title: "Provider User",
                        message: data.title,
                        type: 'danger'
                    };
                }else if (data.id) {
                    showNotification = {
                        title: "Provider User",
                        message: "Provider User created successfully",
                        type: "success"
                    };
                    this.fetchProviderUsers();
                    return true;
                }
            })
            .catch(error => {
                showNotification = {
                    title: 'Provider User',
                    message: enMsg.clientSideError,
                    type: "danger"
                };
            });
        
        userService.showNotification(showNotification);
        if(modalCloseStatus){
            this.modalClose();
        }
        
        this.setState({loading: false});
    }
    async updateProviderUser(data, isOnlyStatusChange){
        this.setState({loading: true});
        let showNotification = {};
        let modalCloseStatus = 1;
        const response =  await fetch(apiPath.getProviderUsers, {
            method: "PUT",
            headers: generalAuthTokenHeader(),
            body: JSON.stringify(data),
            data: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 400) {
                    showNotification = {
                        title: 'Provider User',
                        message: enMsg.badResponseFromServer,
                        type: "danger"
                    };
                } else if (response.ok) {
                } else {
                    showNotification = {
                        title: 'Provider User',
                        message: enMsg.badResponseFromServer,
                        type: "danger"
                    };
                }
                return response.json();
            })
            .then(data => {
                if(data.status == '400' && data.message == 'error.idexists'){
                    modalCloseStatus = 0;
                    showNotification = {
                        title: "Provider User",
                        message: data.title,
                        type: 'danger'
                    };
                }else if (data.id) {
                    showNotification = {
                        title: "Success",
                        message: (isOnlyStatusChange ? "Status updated successfully" : "Provider user updated successfully"),
                        type: "success"
                    };
                    this.fetchProviderUsers(this.state.searchKeyword);
                    return true;
                }
            })
            .catch(error => {
                showNotification = {
                    title: 'Provider User',
                    message: enMsg.clientSideError,
                    type: "danger"
                };
            });
        userService.showNotification(showNotification);
        if(modalCloseStatus){
            this.modalClose();
        }
        this.setState({loading: false});
    }

    validateField = (fieldName, fieldValue) => {
        let fieldValidationErrors = this.state.formErrors;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        let emailValid = this.state.emailValid;
        let phoneNumberValid = this.state.phoneNumberValid;
        let providerValid = this.state.providerValid;
       // let addressValid = this.state.addressValid;
        switch (fieldName) {
            case 'firstName':
                if (fieldValue.trim().length == 0) {
                    firstNameValid = false;
                    fieldValidationErrors.firstName = enMsg.firstNameRequiredMsg;
                }
                else if (!fieldValue.trim().match(ONLY_AlPHABETS_REGEX)) {
                    firstNameValid = false;
                    fieldValidationErrors.firstName = 'Only alphabets are allowed';
                }
                else {
                    firstNameValid = true;
                    fieldValidationErrors.firstName = '';
                }
                break;
            case 'lastName':
                if (fieldValue.trim().length == 0) {
                    lastNameValid = false;
                    fieldValidationErrors.lastName = enMsg.lastNameRequiredMsg;
                }
                else if (!fieldValue.trim().match(ONLY_AlPHABETS_REGEX)) {
                    lastNameValid = false;
                    fieldValidationErrors.lastName = 'Only alphabets are allowed';
                }
                else {
                    lastNameValid = true;
                    fieldValidationErrors.lastName = '';
                }
                break;
            // case 'address':
            //     addressValid = fieldValue.trim().length > 0;
            //     fieldValidationErrors.address = addressValid ? '' : enMsg.addressRequiredErrorMsg;
            //     break;
            case 'email':
                if (fieldValue.trim().length == 0) {
                    emailValid = false;
                    fieldValidationErrors.email = enMsg.emailRequiredMsg;
                }
                else if (!fieldValue.trim().match(EMAIL_REGEX)) {
                    emailValid = false;
                    fieldValidationErrors.email = 'Invalid email';
                }
                else {
                    emailValid = true;
                    fieldValidationErrors.email = '';
                }
                break;
                case "phoneNumber":
                    let errMsg = "";
                    const onlyDigits = fieldValue.replace(/[^\d]/g, ""); // Remove all non-digit characters
                    if (onlyDigits.length == 0) {
                        phoneNumberValid = false;
                        errMsg = "Phone number is required.";
                    } else if (!/^\(\d{3}\)\d{3}-\d{4}$/.test(fieldValue) && !/^\d{10}$/.test(onlyDigits)) {
                        phoneNumberValid = false;
                        errMsg = "Invalid number. Use format (111)111-1111 or a 10-digit number.";
                    } else if (onlyDigits.length != 10) {
                        phoneNumberValid = false;
                        errMsg = enMsg.phoneNumberDigit;
                    } else {
                        phoneNumberValid = true;
                    }
                    fieldValidationErrors.phoneNumber = phoneNumberValid ? "" : errMsg;
                    break;
            case 'provider':
                providerValid = fieldValue > 0;
                fieldValidationErrors.provider = providerValid ? '' : enMsg.providerRequiredMsg;
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            //addressValid: addressValid,
            emailValid: emailValid,
            phoneNumberValid: phoneNumberValid,
            providerValid: providerValid,
        }, this.validateForm);
    }

    validateForm() {
        return (
            this.state.firstNameValid &&
            this.state.lastNameValid &&
            this.state.emailValid &&
            this.state.phoneNumberValid &&
            this.state.providerValid 
          //  this.state.addressValid
        )
    }
    confirmModalOpen = (message, dataId, isDelete) => {
        this.setState({
            dataId: dataId,
            confirmModalMessage: message,
            confirmModalStatus: true,
            isDelete: isDelete
        });
    }
    confirmModalClose = () => {
        this.setState({
            confirmModalStatus: false
        })
    }
    async confirmModalSubmit() {
        
        if (!this.state.isDelete) {
            Promise.all(
                this.state.providerUsers.map(providerUser => {
                    if (providerUser.id == this.state.dataId) {
                        const data = {
                            firstName: providerUser.user.firstName,
                            middleName: providerUser.user.middleName,
                            lastName: providerUser.user.lastName,
                            email: providerUser.user.email,
                            // address: "providerUser.user.address",
                            phoneCode: "+1",
                            phoneNumber: providerUser.user.phoneNumber,
                            activated: !providerUser.user.activated,
                            providerId: providerUser.provider.id,
                            imageUrl: "string",
                            langKey: "string",
                            login: "1",
                            id: this.state.dataId,
                        };
                        this.updateProviderUser(data, true);
                        this.confirmModalClose();
                    }
                })
            )
        } else {
            this.setState({loading: true});
            let showNotification = {};
            const data = {
                id: this.state.dataId,
            };
            const response = await fetch(apiPath.getProviderUsers + '/' + this.state.dataId, {
                method: "DELETE",
                headers: generalAuthTokenHeader(),
            })
                .then(response => {
                    if (response.status === 400) {
                        showNotification = {
                            title: 'Provider User',
                            message: enMsg.badResponseFromServer,
                            type: "danger"
                        };
                    } else if (response.status === 204) {
                        showNotification = {
                            title: 'Provider User',
                            message: 'Provider User deleted successfully',
                            type: "success"
                        };
                        this.fetchProviderUsers();
                    }
                    else if (response.ok) {
                        showNotification = {
                            title: 'Provider User',
                            message: 'Provider User deleted successfully',
                            type: "success"
                        };
                        this.fetchProviderUsers();
                    } else {
                        showNotification = {
                            title: 'Provider User',
                            message: enMsg.clientSideError,
                            type: "danger"
                        };
                    }
                    userService.showNotification(showNotification);
                    this.confirmModalClose();
                });
        }
        this.setState({loading: false});
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            currentPage: newPage,
        }, () => this.fetchProviderUsers());
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({
            perPage: parseInt(event.target.value),
            currentPage: 0
        }, () => this.fetchProviderUsers(this.state.searchKeyword));
    };
    tableSortHandler = (property) => (event) => {
        this.setState({
            orderBy: property,
            direction: !this.state.direction
        }, () => this.fetchProviderUsers(this.state.searchKeyword))
    }

    search(event){
        let searchKeyword = event.target.value.trim();
        if(searchKeyword.length > 0){
            this.setState({currentPage: 0}, () => this.fetchProviderUsers(searchKeyword));
        }else{
            this.fetchProviderUsers();
        }
        this.setState({searchKeyword: searchKeyword});
        return true;
    }
    render() {
        return (
            <div id="provider-user-index">
                <ConfirmModal
                    title='Alert'
                    message={this.state.confirmModalMessage}
                    confirmModalStatus={this.state.confirmModalStatus}
                    confirmModalClose={this.confirmModalClose}
                    submit={this.confirmModalSubmit}
                    loading={this.state.loading}
                />
                <Dialog
                    open={this.state.modalStatus}
                    maxWidth={'sm'}
                    onClose={this.modalClose}
                    TransitionComponent={Transition}
                    aria-labelledby="form-dialog-title"
                    className="add-modal"
                >
                    <DialogTitle className="add-modal-title" id="form-dialog-title">
                        {this.state.dataId ? 'Edit Provider User' : 'Add Provider User'}
                        <IconButton className="closeButton" aria-label="close" onClick={this.modalClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <form onSubmit={this.handleSubmit} noValidate>
                        <DialogContent >
                            <Box display="flex" justifyContent="flex-start" mb={2} >
                                <Box pr={1} width={1/2} >
                                    <TextField
                                        InputLabelProps={{ className: "required-label" }}
                                        label='First Name'
                                        name='firstName'
                                        value={this.state.firstName}
                                        onChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.firstNameValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="firstName"
                                    />
                                </Box>
                                <Box pl={1} width={1/2} >
                                    <TextField
                                        InputLabelProps={{ className: "required-label" }}
                                        label='Last Name'
                                        name='lastName'
                                        value={this.state.lastName}
                                        onChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.lastNameValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="lastName"
                                    />
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="flex-start" mb={2} mt={2} >
                                <Box pr={1} width={1/2}>
                                    <InputLabel htmlFor="facility-select-label" className= "required-label">Assosciated Provider</InputLabel>
                                    <Select
                                        value={(this.state.provider ? this.state.provider : '')}
                                        labelProps={{ className: "required-label" }}
                                        onChange={this.handleChangeInput}
                                        inputProps={{
                                            name: 'provider',
                                            id: 'facility-select-label',
                                        }}
                                        displayEmpty
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 250
                                                }
                                            },
                                            variant: "menu",
                                            getContentAnchorEl: null
                                        }}
                                    >
                                    <MenuItem value="">Select provider</MenuItem>
                                    { this.state.assosciatedProvider.length
                                      ? this.state.assosciatedProvider.map(provider =>
                                        <MenuItem value={provider.id}>{provider.providerName}</MenuItem>
                                        ) : <MenuItem value=' '>Not Available</MenuItem>} 
                                    </Select>
                                    <FormErrors
                                        show={!this.state.providerValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="provider"
                                    />
                                </Box>
                                <Box width={1 / 2} pl={1}  >
                                    <TextField
                                        InputLabelProps={{ className: "required-label" }}
                                        name='phoneNumber'
                                        label='Primary Phone'
                                        value={this.state.phoneNumber}
                                        onChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.phoneNumberValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="phoneNumber"
                                    />
                                </Box>
                            </Box>

                            <Box display="flex" justifyContent="flex-start" mb={2} >
                                {/* <Box width={1 / 2} pr={1}  >
                                    <TextField
                                        InputLabelProps={{ className: "required-label" }}
                                        name='address'
                                        label='Address'
                                        value={this.state.address}
                                        onChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.addressValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="address"
                                    />
                                </Box> */}
                                <Box width={1 / 2} pr={1}  >
                                    <SelectStatus 
                                        id='status-select-label'
                                        value={this.state.status}
                                        name='status'
                                        handleChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.status}
                                        formErrors={this.state.formErrors}
                                        fieldName="status"
                                    />
                                </Box>
                                <Box width={1 / 2} pl={1}  >
                                    <TextField
                                        InputLabelProps={{ className: "required-label" }}
                                        label='Email Address'
                                        name='email'
                                        value={this.state.email}
                                        onChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.emailValid}
                                        formErrors={this.state.formErrors}
                                        fieldName="email"
                                    />
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="flex-start" mb={2} >
                                {/* <Box width={1 / 2} pr={1}  >
                                    <SelectStatus 
                                        id='status-select-label'
                                        value={this.state.status}
                                        name='status'
                                        handleChange={this.handleChangeInput}
                                    />
                                    <FormErrors
                                        show={!this.state.status}
                                        formErrors={this.state.formErrors}
                                        fieldName="status"
                                    />
                                </Box> */}
                            </Box>
                        </DialogContent>
                        <DialogActions className="modal-actions" justify="center" >
                            <Button type="submit" className="btn1" disabled={!this.validateForm() || this.state.loading}>
                                {this.state.loading && (
                                    <CircularProgress
                                        size={24}
                                        className="buttonProgress"
                                    />
                                )}
                                {this.state.dataId ? 'Update Provider User' : 'Add Provider User'}
                            </Button>
                            <Button onClick={this.modalClose} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <GridContainer>
                    <Card className={"advert-cover"}>
                        <GridItem xs={12} sm={12} md={12}>
                            <CardHeader
                                color="success"
                                className={"cusCardheader cusCardTitle"}
                            >
                                <h4>Providers Users</h4>
                            </CardHeader>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="search-btn-cover">
                                <input
                                    type="text"
                                    placeholder="Search by user name/address/phone/assosciated provider."
                                    title="Search by user name/address/phone/assosciated provider."
                                    className="search-btn"
                                    onChange={this.search}
                                />
                                <i className="material-icons">search</i>
                            </div>
                            <div className="button-cover">
                                <button className="pointer btn1" onClick={this.modalOpen}>Add Provider User</button>
                            </div>
                        </GridItem>
                        <div className={"custom-container"}>
                                <div className="tableResponsive" >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align={headCell.numeric ? 'right' : 'left'}
                                                    padding={headCell.disablePadding ? 'none' : 'default'}
                                                >{headCell.sorting ?
                                                        (<TableSortLabel
                                                            active={this.state.orderBy == headCell.id}
                                                            direction={(this.state.direction ? 'asc' : 'desc')}
                                                            onClick={this.tableSortHandler(headCell.id)}
                                                        >{headCell.label}
                                                        </TableSortLabel>) :
                                                        headCell.label
                                                    }
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {this.state.providerUsers.length
                                      ? this.state.providerUsers.map(providerUser =>
                                        <TableRow key={providerUser.id}>
                                            <TableCell>{providerUser.id}</TableCell>
                                            <TableCell>{providerUser.user.firstName} {providerUser.user.middleName} {providerUser.user.lastName}</TableCell>
                                            <TableCell>{providerUser.provider.providerName}</TableCell>
                                             {/* <TableCell>{providerUser.user.address}</TableCell>  */}
                                            <TableCell>
                                                    {(() => {
                                                    const onlyDigits = providerUser.user.phoneNumber.replace(/[^\d]/g, ""); // Remove all non-digit characters
                                                    if (onlyDigits.length === 10) {
                                                        return `(${onlyDigits.slice(0, 3)}) ${onlyDigits.slice(3, 6)}-${onlyDigits.slice(6)}`;
                                                    } else {
                                                        return ""
                                                    }
                                                    })()}
                                             </TableCell>
                                            <TableCell>
                                                <SwitchToggle  
                                                        id = {providerUser.id} 
                                                        status = {providerUser.user.activated}
                                                        clickHandler = {() => this.confirmModalOpen('Do you want to change the status?', providerUser.id, false)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <span className="edit-action" onClick={() => this.modalOpen(providerUser.id)}>
                                                    <Icon className="fa fa-pencil-square-o" aria-hidden="true"/>
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                        )
                                        : 
                                        <TableRow>
                                            <TableCell colspan='7'>
                                                <div className="not-found-text">Provider user not found</div>
                                            </TableCell>
                                        </TableRow>
                                       }
                                    </TableBody>
                                </Table>
                                </div>       
                            
                            <TablePagination
                                rowsPerPageOptions={PER_PAGE_OPTIONS}
                                component="div"
                                count={parseInt(this.state.total)}
                                rowsPerPage={this.state.perPage}
                                page={this.state.currentPage}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </div>
                    </Card>
                </GridContainer>
            </div>
        );
    }
}


export default ProviderUserIndex;
