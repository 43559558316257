import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
} from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { FormErrors } from "../../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import PhoneNumber from "awesome-phonenumber";
import ConfirmModal from "components/Modal/modal";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { apiPath } from "api";
import {
  generalAuthTokenHeader,
  generalMimeAuthTokenHeader,
} from "__helpers/auth-header";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import {
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
  MAX_INPUT_LENGTH,
  ALLOWED_FACESHEET_EXTENTION,
  ALLOWED_FACESHEET_SIZE,
  MULTIPLE_PATIENT_LIST,
  PATIENT_ORDERBY,
  DP_DASHBOARD_FILTERS,
  MAXMRJ_FACILITY,
  VENDOR_FACILITY,
} from "__helpers/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import SelectStatus from "components/SelectStatus/selectStatus";
import CircularProgress from "@material-ui/core/CircularProgress";
import CategoriesList from "./CategoriesList";
import ImportPatientModal from "../ImportPatients";
import { basePath, baseRoutes, dpBaseRoutes } from "base-routes";
import { FormGroup, FormControl, InputLabel } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { PATIENT_SORT, PATIENT_FILTER } from "__helpers/constants";
import done from "assets/img/done.svg";

import Container from "@material-ui/core/Container";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import logo from "assets/img/front-logo.png";
import ScrollMenu from "react-horizontal-scrolling-menu";
import "./patientDashboardCss.css";
import InfiniteScroll from "react-infinite-scroller";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { reduxLoad } from "js/actions/index";

import Chip from "@material-ui/core/Chip";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import leftArrow from "assets/img/icons/arrorw1.svg";
import rightArrow from "assets/img/icons/arrow2.svg";
import { ALLOWED_PROFILE_IMAGE_SIZE } from "__helpers/constants";
import { ALLOWED_IMAGE_EXTENTION } from "__helpers/constants";
import fileExtension from "file-extension";
import infoIcon from "assets/img/icons/info-icon.svg";
import tasksImg from "assets/img/icons/tasksImg.png";
import { REDUX_STATE_DATA } from "__helpers/constants";

// own carosoul
// import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { PATIENT_LIST } from "__helpers/constants";
import { USER_INFO } from "__helpers/constants";
import ChangePassword from "views/Profile/ChangePassword";
import BAAComponent from "views/CmsPages/BAAComponent";
import TermsComponent from "views/CmsPages/TermsComponent";
// import 'react-owl-carousel2/style.css';
import { Scrollbars } from "react-custom-scrollbars";
import { SELECTED_PATIENT_LIST } from "__helpers/constants";
import Files from "react-files";
import moment from "moment-timezone";
import TasksList from "./TasksList";
import PatientTasks from "../SinglePatientDetail/PatientTasks";
import WarningIcon from "@material-ui/icons/Warning";
import { daysBetweenTwoDate } from "__helpers/util";
import { localDateFormat } from "__helpers/util";
import { localServerFormat } from "__helpers/util";

function mapDispatchToProps(dispatch) {
  return {
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    patientFilterListRedux: state.patientFilterList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

// One item component
// selected prop will be passed
const CategotyMenuItem = ({ keyVals, cListVals }) => {
  const { masterCategories, catagoryId, status } = cListVals;
  return (
    <Box className="item menu-item" key={keyVals} border={1}>
      <p className="category-value">{masterCategories.categoryValue}</p>
      <p className="category-status">{masterCategories.status}Awaiting</p>
    </Box>
  );
};
// All items component
// Important! add unique key
export const CategotyMenu = (list, selected, categoryList) =>
  list.map((el, key) => {
    const { name } = el;

    return (
      <CategotyMenuItem
        cListVals={el}
        text={name}
        key={key}
        selected={selected}
        categoryList={categoryList}
      />
    );
  });

const CategotyArrow = ({ text, className }) => {
  return (
    <div className={className}>
      <img src={text} />
    </div>
  );
};

const ArrowLeft = CategotyArrow({ text: leftArrow, className: "arrow-prev" });
const ArrowRight = CategotyArrow({ text: rightArrow, className: "arrow-next" });

const selected = "item1";
const options = {
  //items: 3,
  nav: true,
  rewind: true,
  autoplay: false,
};
const tooltipStyles = {
  tooltip: {
    width: "auto !important",
    // height: "36px",
    borderRadius: "18px",
    boxShadow: "0 20px 80px 0",
  },
};
const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

moment.tz.setDefault("UTC");
class PatientListClass extends React.Component {
  inputOpenImageFileRef = React.createRef(null);
  inputOpenFaceSheetFileRef = React.createRef(null);
  maxDate = new Date();
  minDate = new Date();
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    // let patientList = JSON.parse(localStorage.getItem(PATIENT_LIST));
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    let patientList = JSON.parse(sessionStorage.getItem('_patient_list'));
    //console.log("datapatientList",patientList[0].patientId);
    let dpFacilityCode =
      userInfo &&
      userInfo.dischargePlanner &&
      userInfo.dischargePlanner.facility &&
      userInfo.dischargePlanner.facility.facilityCode
        ? userInfo.dischargePlanner.facility.facilityCode
        : null;
    const patientCategoryList = ["1", "3"];
    this.dateUtility = new DateFnsUtils();
    this.patientImageName = "";
    this.patientImageBlob = null;
    this.patientFaceSheetName = "";
    this.patientFaceSheetBlob = null;
    this.state = {
      loading: false,
      modalStatus: false,
      confirmModalStatus: false,
      confirmModalMessage: "",
      perPage: DEFAULT_PER_PAGE_RECORDS,
      total: 0,
      selectionCount: 0,
      currentPage: 0,
      totalPatientShowing: 0,
      // sorting: "desc",
      sorting: "1",
      // filter: "createdDate",
      filter: "",
      loadingPatient: true,
      loadingState: false,
      items: 10,
      patientSearchKeyword: "",
      direction: true, //true means asc and false means desc
      spinner: spinner,
      isDelete: false,
      tasks: "",
      formErrors: {
        firstName: "",
        lastName: "",
        middleName: "",
        gender: "",
        insurance: "",
        hospital: "",
        dob: "",
        estDate: "",
        actualEstDate: "",
        patientCategoties: "",
        patientImage: "",
        patientFaceSheet: "",
      },
      firstNameValid: false,
      lastNameValid: false,
      middleNameValid: true,
      genderValid: false,
      insuranceValid: true,
      hospitalValid: true,
      dobValid: false,
      estDateValid: true,
      actualEstDateValid: true,
      patientCategotiesValid: true,
      patientImageValid: true,
      patientFaceSheetValid: true,

      patientName: "unknown",
      selectedPatientId: "",
      patientCategoryList: [],
      patientList: [],
 //     patientId:patientList[0].patientId,
      categoryList: props.categoryListRedux ? props.categoryListRedux : [],
      insuranceList: props.insuranceListRedux ? props.insuranceListRedux : [],
      hospitalList: props.hospitalListRedux ? props.hospitalListRedux : [],
      patientFilterList: props.patientFilterListRedux
        ? props.patientFilterListRedux
        : [],
      patientStatusList: props.patientStatusListRedux
        ? props.patientStatusListRedux
        : [],
      userInfo: props.userInfoRedux ? props.userInfoRedux : {},
      loadMore: false,
      firstName: "",
      lastName: "",
      middleName: "",
      insurance: "0",
      hospital: "",
      gender: "",
      dob: null,
      estDate: null,
      actualEstDate: null,
      patientCategories: [],
      reduxLoadFlag: false,
      patientTaskCount: 0,
      loadPatient: true,
      selectedPatientData: {},
      owlCarouselOptions: {
        loop: false,
        dots: false,
        margin: 4,
        nav: true,
        //mergeFit:true,
        //autoWidth:true,
        stagePadding: 1,
        items: 10,
        navText: [
          "<i class='fa fa-chevron-left'></i>",
          "<i class='fa fa-chevron-right'></i>",
        ],
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          576: {
            items: 3,
          },
          768: {
            items: 5,
          },
          850: {
            items: 2,
          },
          1100: {
            items: 2,
          },
          1200: {
            items: 5,
          },
          1400: {
            items: 6,
          },
          1600: {
            items: 8,
          },
          1800: {
            items: 10,
          },
        },
      },
      openModal: false,
      bbaCondition: false,
      termNcondition: false,
      openTaskModal: false,
      patientImage: "",
      patientFaceSheet: "",
      patientImageName: "",
      patientFaceSheetName: "",
      isImage: false,
      isFaceSheet: false,
      isImageAdded: false,
      isFaceSheetAdded: false,
      patientImages: [],
      patientFaceSheets: [],
      patientImagesStr: [],
      patientFaceSheetsStr: [],
      patientUploadHistory: [],
      patientTaskId: "",
      patientTaskList: [],
      patientTaskGroup: [],
      multiPatientMode: false,
      multiplePatientObj: [],
      multiplePatientList: [],
      selectRemovePatient: [],
      dpFacilityCode: dpFacilityCode,
      facilityType: dpFacilityCode ? VENDOR_FACILITY : MAXMRJ_FACILITY,
      importModal: false,
      isUpdating: false,
    };
    this.owlCarouselRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updatePatient = this.updatePatient.bind(this);
    this.createPatient = this.createPatient.bind(this);
    this.fetchPatients = this.fetchPatients.bind(this);
    // this.confirmModalSubmit = this.confirmModalSubmit.bind(this);
    this.search = this.search.bind(this);
    this.searchReturn = this.searchReturn.bind(this);
    this.loadMorePatient = this.loadMorePatient.bind(this);
    this.handlePatientSorting = this.handlePatientSorting.bind(this);
    this.handleSingleDpDChange = this.handleSingleDpDChange.bind(this);
    this.handleImageSelectedFile = this.handleImageSelectedFile.bind(this);
    this.handleFaceSheetSelectedFile = this.handleFaceSheetSelectedFile.bind(
      this
    );
    this.handleEstDateError = this.handleEstDateError.bind(this);
    this.handleDobError = this.handleDobError.bind(this);
    this.imageFilesRemoveAll = this.imageFilesRemoveAll.bind(this);
    this.imageFilesRemoveOne = this.imageFilesRemoveOne.bind(this);
    this.onImageFilesChange = this.onImageFilesChange.bind(this);
    this.onImageFilesError = this.onImageFilesError.bind(this);
    this.onFaceSheetFilesChange = this.onFaceSheetFilesChange.bind(this);
    this.onFaceSheetFilesError = this.onFaceSheetFilesError.bind(this);
    this.fileSelectClick = this.fileSelectClick.bind(this);
    this.middleHandleFn = this.middleHandleFn.bind(this);
    this.fetchPatientInfo = this.fetchPatientInfo.bind(this);
    this.fetchPatientList = this.fetchPatientList.bind(this);
    this.loaderStatus = this.loaderStatus.bind(this);
    this.handleBAAAgree = this.handleBAAAgree.bind(this);
    this.handleTNCAgree = this.handleTNCAgree.bind(this);
    this.uploadImages = this.uploadImages.bind(this);
    this.deleteImages = this.deleteImages.bind(this);
    this.callFetchPateintMethod = this.callFetchPateintMethod.bind(this);
    this.confirmModalClose = this.confirmModalClose.bind(this);
    this.confirmModalSubmit = this.confirmModalSubmit.bind(this);
    this.closeImportModal = this.closeImportModal.bind(this);
    this.handleAssignedSuccess = this.handleAssignedSuccess.bind(this);
    this.handleRemovedSuccess = this.handleRemovedSuccess.bind(this);
    this.categorySelection = this.categorySelection.bind(this);
    this.openPatientPopup = this.openPatientPopup.bind(this);
    this.fetchTasks = this.fetchTasks.bind(this);
  }
  handleClose() {}
  componentDidMount() {
 
    localStorage.removeItem(SELECTED_PATIENT_LIST);
    
    this.setState({
      selectedPatientId: "",
      selectedPatientData: {},
      selectionCount: 0,
      multiPatientMode: false,
      multiplePatientList: [],
    });
    let user = JSON.parse(localStorage.getItem(USER_INFO));
    if (!user.termNcondition) {
      this.setState({
        openModal: false,
        termNcondition: true,
        bbaCondition: false,
      });
    } else if (!user.termNcondition) {
      this.setState({
        openModal: false,
        termNcondition: false,
        bbaCondition: true,
      });
    } else {
      if (user.newUser) {
        this.setState({
          openModal: true,
          termNcondition: false,
          bbaCondition: false,
        });
      } else {
        this.setState({
          openModal: false,
          termNcondition: false,
          bbaCondition: false,
        });
      }
    }
    let dpDashboardFilter = JSON.parse(
      localStorage.getItem(DP_DASHBOARD_FILTERS)
    );
    this.setState(
      {
        sorting:
          dpDashboardFilter && dpDashboardFilter.sorting
            ? dpDashboardFilter.sorting
            : "1",
        filter:
          dpDashboardFilter && dpDashboardFilter.filter
            ? dpDashboardFilter.filter
            : "",
        patientSearchKeyword:
          dpDashboardFilter && dpDashboardFilter.patientSearchKeyword
            ? dpDashboardFilter.patientSearchKeyword
            : "",
      },
      () => this.fetchPatients()
    );
    // if (this.props.location && this.props.location.state && this.props.location.state.resetDashboard) {
    //     this.fetchPatients();
    // }
  }
  handleBAAAgree() {
    let user = JSON.parse(localStorage.getItem(USER_INFO));
    user.termNcondition = true;
    user.bbaCondition = true;
    localStorage.setItem(USER_INFO, JSON.stringify(user));
    if (user.newUser) {
      this.setState({
        openModal: true,
        termNcondition: false,
        bbaCondition: false,
      });
    } else {
      this.setState({
        openModal: false,
        termNcondition: false,
        bbaCondition: false,
      });
    }
  }
  showImportModalLock = () => {
    if (!this.state.selectedPatientId || this.state.multiPatientMode) {
      this.setState({
        confirmModalMessage:
          this.state.selectionCount === this.state.selectRemovePatient.length
            ? "Do you want to remove selected " +
              this.state.selectRemovePatient.length +
              " patient(s)."
            : "Only the " +
              this.state.selectRemovePatient.length +
              " imported patient(s) will be removed. The MAXMRJ created patient(s) cannot removed.",
      });
      if (this.state.selectRemovePatient.length) {
        this.setState({
          confirmModalStatus: true,
        });
      } else {
        let showNotification = {
          title: "Remove Patients",
          message: "Only imported patiens can be remove",
          type: "warning",
        };
        userService.showNotification(showNotification);
      }
    } else {
      this.setState({ importModal: true });
    }
  };
  openPatientPopup() {
    const patientCategoryList = [];
    this.state.selectedPatientData.patientCatagoryMapping &&
      this.state.selectedPatientData.patientCatagoryMapping.map((el) => {
        return patientCategoryList.push(el.categoryId);
      });
    let isFaceSheetAdded =
      this.state.selectedPatientData.faceSheet &&
      this.state.selectedPatientData.faceSheet != ""
        ? true
        : false;
    let isImageAdded =
      this.state.selectedPatientData.photo &&
      this.state.selectedPatientData.photo != ""
        ? true
        : false;
    // let localDobLocal = this.dateUtility.format(
    //     new Date(this.state.selectedPatientData.patientDob),
    //     "MM/dd/yyyy"
    // );
    let estDate = this.state.selectedPatientData.eastimatedDischargeDate
      ? moment
          .utc(
            this.state.selectedPatientData.eastimatedDischargeDate,
            "YYYY-MM-DD"
          )
          .format("MM/DD/YYYY")
      : null;
    let actualEstDate = this.state.selectedPatientData.actualDischargeDate
      ? moment
          .utc(this.state.selectedPatientData.actualDischargeDate, "YYYY-MM-DD")
          .format("MM/DD/YYYY")
      : null;
    let dobDate = moment
      .utc(this.state.selectedPatientData.patientDob, "YYYY-MM-DD")
      .format("MM/DD/YYYY");
    this.setState({
      firstName: this.state.selectedPatientData.firstName,
      lastName: this.state.selectedPatientData.lastName,
      middleName: this.state.selectedPatientData.middleName,
      insurance: this.state.selectedPatientData.insuranceId,
      hospital: this.state.selectedPatientData.previousHospitalId,
      gender: this.state.selectedPatientData.gender
        ? this.state.selectedPatientData.gender.toLowerCase()
        : "",
      dob: dobDate,
      estDate: estDate,
      actualEstDate: actualEstDate,
      patientCategories: patientCategoryList,
      modalStatus: true,
      patientImage: "",
      patientFaceSheet: "",
      patientImageName: "",
      patientFaceSheetName: "",
      isImage: false,
      isFaceSheet: false,
      isImageAdded: isImageAdded,
      isFaceSheetAdded: isFaceSheetAdded,
      patientImages: [],
      patientFaceSheets: [],
      patientImagesStr: [],
      patientFaceSheetsStr: [],
      patientUploadHistory: this.state.selectedPatientData.patientUploadHistory
        ? this.state.selectedPatientData.patientUploadHistory
        : [],
    });
    this.setState(
      {
        firstNameValid: true,
        lastNameValid: true,
        middleNameValid: true,
        genderValid: true,
        insuranceValid: true,
        hospitalValid: true,
        dobValid: true,
        estDateValid: true,
        actualEstDateValid: true,
        patientCategotiesValid: true,
      },
      this.validateForm
    );
  }
  showImportModal = () => {
    if (
      this.state.selectedPatientData &&
      this.state.selectedPatientId &&
      !this.state.multiPatientMode
    ) {
      this.setState({ importModal: true });
    } else {
      this.setState({ importModal: true });
    }
  };
  confirmModalClose() {
    this.setState({
      confirmModalMessage: "",
      confirmModalStatus: false,
    });
  }
  async confirmModalSubmit() {
    // this.setState({
    //     confirmModalMessage: "",
    //     confirmModalStatus: false,
    // })
    let showNotification = {};
    const {
      multiPatientMode,
      multiplePatientList,
      selectedPatientId,
      selectRemovePatient,
    } = this.state;
    let multiplePatientListArr = selectRemovePatient;
    if (multiplePatientListArr || multiplePatientListArr.length) {
      const data = {
        id: multiplePatientListArr,
      };
      this.setState({
        loading: true,
      });
      const response = await fetch(apiPath.unassignPatientToDash, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Remove Patients",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
            showNotification = {
              title: "Remove Patients",
              message: "Patients removed from facility successfully",
              type: "success",
            };
            this.setState(
              {
                currentPage: 0,
                confirmModalMessage: "",
                confirmModalStatus: false,
                multiPatientMode: false,
                multiplePatientList: [],
                selectedPatientId: "",
                selectionCount: 0,
                selectRemovePatient: [],
              },
              () => this.fetchPatients()
            );
          } else {
            showNotification = {
              title: "Remove Patients",
              message: "Bad response from server. ",
              type: "danger",
            };
          }
          this.setState({ loading: false });
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.title) {
            showNotification = {
              title: "Remove Patients",
              message: data.title,
              type: "danger",
            };
          }
          userService.showNotification(showNotification);
        })
        .catch((error) => {
          console.log(error);
          showNotification = {
            title: "Remove Patients",
            message: "Something went wrong.Please try after sometime.." + error,
            type: "danger",
          };
          userService.showNotification(showNotification);
          this.setState({ loading: false });
        });
    } else {
      showNotification = {
        title: "Remove Patients",
        message: "Please select patients to remove",
        type: "warning",
      };
      userService.showNotification(showNotification);
    }
  }
  handleTNCAgree() {
    this.setState({
      openModal: false,
      termNcondition: false,
      bbaCondition: true,
    });
  }
  displayItems() {
    var items = [];
    for (var i = 0; i < this.state.items; i++) {
      items.push(<li key={i}>Item {i}</li>);
    }
    return items;
  }
  loadMoreItems() {
    this.setState({ loadingState: true });
    setTimeout(() => {
      this.setState({ items: this.state.items + 10, loadingState: false });
    }, 3000);
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let categoryList = [];
      let insuranceList = [];
      let hospitalList = [];
      let patientFilterList = [];
      let patientStatusList = [];
      let userInfo = {};
      if (this.props.categoryListRedux) {
        let selectedcategoryList = this.props.categoryListRedux;
        categoryList = selectedcategoryList ? selectedcategoryList : [];
      }
      if (this.props.insuranceListRedux) {
        let selectedinsuranceList = this.props.insuranceListRedux;
        insuranceList = selectedinsuranceList ? selectedinsuranceList : [];
      }
      if (this.props.hospitalListRedux) {
        let selectedhospitalList = this.props.hospitalListRedux;
        hospitalList = selectedhospitalList ? selectedhospitalList : [];
      }
      if (this.props.patientFilterListRedux) {
        let selectedpatientFilterList = this.props.patientFilterListRedux;
        patientFilterList = selectedpatientFilterList
          ? selectedpatientFilterList
          : [];
      }
      if (this.props.patientStatusListRedux) {
        let selectedpatientStatusList = this.props.patientStatusListRedux;
        patientStatusList = selectedpatientStatusList
          ? selectedpatientStatusList
          : [];
      }
      if (this.props.userInfoRedux) {
        let userInfoRedux = this.props.userInfoRedux;
        userInfo = userInfoRedux ? userInfoRedux : {};
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        categoryList: categoryList,
        insuranceList: insuranceList,
        hospitalList: hospitalList,
        patientFilterList: patientFilterList,
        patientStatusList: patientStatusList,
        userInfo: userInfo,
      });
    }
    if ("onorientationchange" in window) {
      window.addEventListener(
        "orientationchange",
        function() {
          // `this` is now pointing to `window`, not the component. So use `self`.
          window.dispatchEvent(new Event("resize"));
          console.log("orientationchange change...");
        },
        false
      );
    }
  }
  componentWillUnmount() {
    Object.getPrototypeOf(this).constructor.STATE = this.state;
  }
  UNSAFE_componentWillMount() {
    // // this.setState(Object.getPrototypeOf(this).constructor.STATE || {});
    // if (Object.getPrototypeOf(this).constructor.STATE) {
    //     // let patientList = JSON.parse(sessionStorage.getItem(PATIENT_LIST));
    //     // let selectedPatientId = JSON.parse(localStorage.getItem(SELECTED_PATIENT_LIST));
    //     // localStorage.removeItem(SELECTED_PATIENT_LIST)
    //     // this.setState({ patientList: patientList })
    //     var stataData = Object.getPrototypeOf(this).constructor.STATE;
    //     // var loadPatient = false;
    //     // this.fetchPatientInfo(selectedPatientId);
    //     let totalStateLen = 0;
    //     Object.keys(stataData).map((v, i) => {
    //         //     loadPatient = stataData[v];
    //         console.log("pateintInfo", stataData[v], v, i)
    //         if (v == "filter" || v == "sorting") {
    //             totalStateLen++;
    //             this.setState({
    //                 [v]: stataData[v],
    //             }, () => this.callFetchPateintMethod(totalStateLen))
    //         }
    //         // if (v == "loadPatient")
    //         // if (v == "patientList" || v == "selectedPatientId" || v == "loading" || v == "selectedPatientData") {
    //         //     console.log(stataData[v], v, i)
    //         // } else {
    //         //     this.setState({
    //         //         [v]: stataData[v],
    //         //     })
    //         // }
    //     });
    // }
  }
  callFetchPateintMethod(totalStateLen) {
    if (
      totalStateLen == 2 &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.resetDashboard == false
    ) {
      this.fetchPatients();
    }
  }
  async fetchPatients() {
    this.setState({
      loadingPatient: true,
    });
    let showNotification = {};
    let apiUrl = "";
    let searchKeyword = this.state.patientSearchKeyword;
    if (searchKeyword && searchKeyword.length > 0) {
      apiUrl =
        apiPath.getPatientList +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        (this.state.filter && this.state.filter != ""
          ? "&filterBy=" + this.state.filter
          : "") +
        "&orderBy=" +
        (this.state.sorting && this.state.sorting != ""
          ? this.state.sorting
          : "1") +
        "&search=" +
        searchKeyword;
    } else {
      apiUrl =
        apiPath.getPatientList +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        (this.state.filter && this.state.filter != ""
          ? "&filterBy=" + this.state.filter
          : "") +
        "&orderBy=" +
        (this.state.sorting && this.state.sorting != ""
          ? this.state.sorting
          : "1") +
        "";
    }
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Patient List",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          let totalPatientCount = response.headers.get("X-Total-Count");
          this.setState({
            total: totalPatientCount,
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Patient List",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        let localPatientList = this.state.patientList;
        if (this.state.currentPage == 0) {
          localPatientList = data;
        } else {
          localPatientList = localPatientList.concat(data);
        }
        let totalPatientShowing = localPatientList.length;
        let loadMore = false;
        let loadingState = false;
        let currentPage = this.state.currentPage;
        if (this.state.total > totalPatientShowing) {
          loadMore = true;
          loadingState = true;
          currentPage = currentPage + 1;
        }
        sessionStorage.setItem(PATIENT_LIST, JSON.stringify(localPatientList));
        this.setState({
          patientList: localPatientList,
          totalPatientShowing: totalPatientShowing,
          loadMore: loadMore,
          currentPage: currentPage,
          loadingState: loadingState,
          loadingPatient: false,
        });
        userService.showNotification(showNotification);
        window.dispatchEvent(new Event("resize"));
      })
      .catch((error) => {
        showNotification = {
          title: "Patient List",
          message: "Bad response from server",
          type: "danger",
        };
        this.setState({
          loadingPatient: false,
        });
        userService.showNotification(showNotification);
        return response;
      });
  }
  categorySelection = (patientId, patientInfo) => {
    this.fetchPatientInfo(patientId, patientInfo);
    console.log(patientId, patientInfo);
    var selectedPatientId = this.state.selectedPatientId;
    let selectedPatients = this.state.multiplePatientList;
    if (selectedPatients.includes(patientId)) {
      const index = selectedPatients.indexOf(patientId);
      if (index > -1) {
        selectedPatients.splice(index, 1);
      }
    } else {
      selectedPatients.push(patientId);
    }
    sessionStorage.setItem(MULTIPLE_PATIENT_LIST, JSON.stringify([patientId]));
    const {
      firstName,
      middleName,
      lastName,
      patientCatagoryMapping,
    } = patientInfo;
    const patientCategoryList = [];
    patientCatagoryMapping &&
      patientCatagoryMapping.map((el) => {
        return patientCategoryList.push(el.categoryId);
      });
    this.setState(
      {
        selectedPatientId: patientId,
        patientName:
          (lastName ? lastName : "") +
          ", " +
          firstName +
          " " +
          (middleName ? middleName : ""),
        patientCategoryList: patientCategoryList,
        selectionCount: 1,
        selectedPatientData: patientInfo,
        loading: false,
        patientTaskList: patientInfo.patientTask ? patientInfo.patientTask : [],
        multiplePatientList: [patientId],
        multiPatientMode: false,
        selectRemovePatient: patientInfo.thirdPartyPatientId
          ? [patientInfo.thirdPartyPatientId]
          : [],
      },
      () => this.modalOpen()
    );
  };
  modalOpen = () => {
    if (
      this.state.selectedPatientData &&
      this.state.selectedPatientId &&
      !this.state.multiPatientMode
    ) {
      const patientCategoryList = [];
      this.state.selectedPatientData.patientCatagoryMapping &&
        this.state.selectedPatientData.patientCatagoryMapping.map((el) => {
          return patientCategoryList.push(el.categoryId);
        });
      let isFaceSheetAdded =
        this.state.selectedPatientData.faceSheet &&
        this.state.selectedPatientData.faceSheet != ""
          ? true
          : false;
      let isImageAdded =
        this.state.selectedPatientData.photo &&
        this.state.selectedPatientData.photo != ""
          ? true
          : false;
      // let localDobLocal = this.dateUtility.format(
      //     new Date(this.state.selectedPatientData.patientDob),
      //     "MM/dd/yyyy"
      // );
      let estDate = this.state.selectedPatientData.eastimatedDischargeDate
        ? moment
            .utc(
              this.state.selectedPatientData.eastimatedDischargeDate,
              "YYYY-MM-DD"
            )
            .format("MM/DD/YYYY")
        : null;
      let actualEstDate = this.state.selectedPatientData.actualDischargeDate
        ? moment
            .utc(
              this.state.selectedPatientData.actualDischargeDate,
              "YYYY-MM-DD"
            )
            .format("MM/DD/YYYY")
        : null;
      let dobDate = moment
        .utc(this.state.selectedPatientData.patientDob, "YYYY-MM-DD")
        .format("MM/DD/YYYY");
      this.setState({
        firstName: this.state.selectedPatientData.firstName,
        lastName: this.state.selectedPatientData.lastName,
        middleName: this.state.selectedPatientData.middleName,
        insurance: this.state.selectedPatientData.insuranceId,
        hospital: this.state.selectedPatientData.previousHospitalId,
        gender: this.state.selectedPatientData.gender
          ? this.state.selectedPatientData.gender.toLowerCase()
          : "",
        dob: dobDate,
        estDate: estDate,
        actualEstDate: actualEstDate,
        patientCategories: patientCategoryList,
        modalStatus: true,
        patientImage: "",
        patientFaceSheet: "",
        patientImageName: "",
        patientFaceSheetName: "",
        isImage: false,
        isFaceSheet: false,
        isImageAdded: isImageAdded,
        isFaceSheetAdded: isFaceSheetAdded,
        patientImages: [],
        patientFaceSheets: [],
        patientImagesStr: [],
        patientFaceSheetsStr: [],
        patientUploadHistory: this.state.selectedPatientData
          .patientUploadHistory
          ? this.state.selectedPatientData.patientUploadHistory
          : [],
      });
      this.setState(
        {
          firstNameValid: true,
          lastNameValid: true,
          middleNameValid: true,
          genderValid: true,
          insuranceValid: true,
          hospitalValid: true,
          dobValid: true,
          estDateValid: true,
          actualEstDateValid: true,
          patientCategotiesValid: true,
        },
        this.validateForm
      );
    } else {
      this.setState({
        firstName: "",
        lastName: "",
        middleName: "",
        insurance: "0",
        hospital: "",
        gender: "",
        dob: null,
        estDate: null,
        actualEstDate: null,
        patientCategories: [],
        modalStatus: true,
        patientImage: "",
        patientFaceSheet: "",
        patientImageName: "",
        patientFaceSheetName: "",
        isImage: false,
        isFaceSheet: false,
        isImageAdded: false,
        isFaceSheetAdded: false,
        patientImages: [],
        patientFaceSheets: [],
        patientImagesStr: [],
        patientFaceSheetsStr: [],
        patientUploadHistory: [],
        formErrors: {
          firstName: "",
          lastName: "",
          middleName: "",
          gender: "",
          insurance: "",
          hospital: "",
          dob: "",
          estDate: "",
          actualEstDate: "",
          patientCategoties: "",
          patientImage: "",
          patientFaceSheet: "",
        },
      });
      this.setState(
        {
          firstNameValid: false,
          lastNameValid: false,
          middleNameValid: true,
          genderValid: false,
          insuranceValid: true,
          hospitalValid: true,
          dobValid: false,
          estDateValid: true,
          actualEstDateValid: true,
          patientCategotiesValid: true,
        },
        this.validateForm
      );
    }
  };
  modalClose = () => {
    this.setState({
      modalStatus: false,
    });
  };
  handleChangeInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    value = value.replace(/[^A-Za-z ]/gi, "");
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };


  async handleSubmit(event) {
    this.setState({
      loading: true,
      patientImagesStr: [],
      patientFaceSheetsStr: [],
    });
    event.preventDefault();
    this.middleHandleFn();
    return;
    let imageKeyCount = 0;
    if (this.state.patientFaceSheets && this.state.patientFaceSheets.length) {
      this.state.patientFaceSheets.map((file, key) => {
        if (typeof file !== undefined && file) {
          const fileReader = new FileReader();
          fileReader.addEventListener("load", () => {
            let result = fileReader.result;
            let patientFaceSheetsStr = this.state.patientFaceSheetsStr;
            let fileName = file.name;
            let filesObj = {};
            filesObj.base64Data = result;
            filesObj.uploadName = fileName;
            patientFaceSheetsStr.push(filesObj);
            if (imageKeyCount == key) {
              this.setState(
                {
                  patientFaceSheetsStr: patientFaceSheetsStr,
                },
                () => this.middleHandleFn()
              );
            } else {
              this.setState({
                patientFaceSheetsStr: patientFaceSheetsStr,
              });
            }
            imageKeyCount = imageKeyCount + 1;
          });
          if (file) {
            fileReader.readAsDataURL(file);
          }
        }
      });
    } else {
      this.setState(
        {
          patientFaceSheetsStr: [],
        },
        () => this.middleHandleFn()
      );
    }
    let imageArr = [];
    let facesheetKeyCount = 0;
    if (this.state.patientImages && this.state.patientImages.length) {
      this.state.patientImages.map((file) => {
        if (typeof file !== undefined && file) {
          const fileReader = new FileReader();
          fileReader.addEventListener("load", () => {
            let result = fileReader.result;
            let patientImagesStr = this.state.patientImagesStr;
            let fileName = file.name;
            let filesObj = {};
            filesObj.base64Data = result;
            filesObj.uploadName = fileName;
            patientImagesStr.push(filesObj);
            this.setState({
              patientImagesStr: patientImagesStr,
            });
            facesheetKeyCount = facesheetKeyCount + 1;
          });
          if (file) {
            fileReader.readAsDataURL(file);
          }
        }
      });
    }
    this.setState({
      // loading: false
    });
    let totalSec =
      (this.state.patientImages.length + this.state.patientFaceSheets.length) *
      100;
  }
  middleHandleFn() {
    if (this.state.selectedPatientId && this.state.selectedPatientData) {
      this.updatePatient(false);
    } else {
      this.createPatient();
    }
    return;
    if (
      this.state.patientFaceSheetsStr.length ==
      this.state.patientFaceSheets.length
    ) {
      if (this.state.selectedPatientId && this.state.selectedPatientData) {
        this.updatePatient(false);
      } else {
        this.createPatient();
      }
    }
  }
  async createPatient() {
    let showNotification = {};
    let localDobLocal = this.dateUtility.format(
      new Date(this.state.dob),
      "yyyy-MM-dd"
    );
    let estDateLocal = this.dateUtility.format(
      new Date(this.state.estDate),
      "yyyy-MM-dd"
    );
    let localDobMoment = this.state.dob
      ? this.dateUtility.format(new Date(this.state.dob), "yyyy-MM-dd")
      : null;
    let localEstMoment = this.state.estDate
      ? this.dateUtility.format(new Date(this.state.estDate), "yyyy-MM-dd")
      : null;
    let localActualEstMoment = this.state.actualEstDate
      ? this.dateUtility.format(
          new Date(this.state.actualEstDate),
          "yyyy-MM-dd"
        )
      : null;
    let faceSheet = this.state.isFaceSheet ? this.state.patientFaceSheet : null;
    let image = this.state.isImage ? this.state.patientImage : null;
    const data = {
      firstName: this.state.firstName ? this.state.firstName : "",
      lastName: this.state.lastName ? this.state.lastName : "",
      middleName: this.state.middleName ? this.state.middleName : "",
      insuranceId: this.state.insurance ? this.state.insurance : "",
      previousHospitalId: this.state.hospital ? this.state.hospital : "",
      gender: this.state.gender ? this.state.gender : "",
      dob: localDobMoment,
      estimatedDischargedate: localEstMoment,
      actualDischargeDate: localActualEstMoment,
      // actualDischargeDate: this.state.actualEstDate ? new Date(this.state.actualEstDate) : null,
      patientStatusId: "7",
      categoryId: this.state.patientCategories.sort(function(a, b) {
        return a - b;
      }),
      createdById: this.state.userInfo.createdById,
    };
    // if (this.state.patientFaceSheetsStr && this.state.patientFaceSheetsStr.length) {
    //     data.faceSheet = this.state.patientFaceSheetsStr;
    // } else {
    //     data.faceSheet = null;
    // }
    // if (this.state.patientImagesStr && this.state.patientImagesStr.length) {
    //     data.photo = this.state.patientImagesStr;
    // } else {
    //     data.photo = null;
    // }
    showNotification = {
      title: "New Patient",
      message: "Create Patient, Please Wait",
      type: "info",
    };
    userService.showNotification(showNotification);
    showNotification = {};
    this.setState({
      loading: true,
    });
    const response = await fetch(apiPath.createPatient, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "New Patient",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "New Patient",
            message: "Bad response from server. " + response.status,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        // this.modalClose();
        // this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        if (data.patientId) {
          showNotification = {
            title: "Success",
            message: "Patient Successfully Created",
            type: "success",
          };
          userService.showNotification(showNotification);
          // this.modalClose();
          this.uploadImages(data.patientId, false);
          return true;
        } else {
          this.modalClose();
          this.setState({ loading: false });
          return true;
        }
      })
      .catch((error) => {
        showNotification = {
          title: "New Patient",
          message: "Something went wrong.Please try after sometime.." + error,
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        this.modalClose();
      });
  }
  async updatePatient() {
    let showNotification = {};
    this.setState({ isUpdating: true });
    let localDobLocal = this.state.dob
      ? this.formatDateToServerFormat(this.state.dob)
      : null;
    let estDateLocal = this.state.estDate
      ? this.formatDateToServerFormat(this.state.estDate)
      : null;
    let localDobMoment = this.state.dob
      ? this.dateUtility.format(new Date(this.state.dob), "yyyy-MM-dd")
      : null;
    let localEstMoment = this.state.estDate
      ? this.dateUtility.format(new Date(this.state.estDate), "yyyy-MM-dd")
      : null;
    let localActualEstMoment = this.state.actualEstDate
      ? this.dateUtility.format(
          new Date(this.state.actualEstDate),
          "yyyy-MM-dd"
        )
      : null;
    // let localActualEstMoment = this.state.actualEstDate ? localServerFormat(this.state.actualEstDate, 'UTC') : null;
    let faceSheet = this.state.isFaceSheet ? this.state.patientFaceSheet : null;
    let image = this.state.isImage ? this.state.patientImage : null;
    const data = {
      patientId: this.state.selectedPatientId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      middleName: this.state.middleName,
      insuranceId: this.state.insurance,
      previousHospitalId: this.state.hospital,
      gender: this.state.gender,
      dob: localDobMoment,
      estimatedDischargedate: localEstMoment,
      // actualDischargeDate: this.state.actualEstDate ? new Date(this.state.actualEstDate) : null,
      actualDischargeDate: localActualEstMoment,
      patientStatusId:
        this.state.selectedPatientData &&
        this.state.selectedPatientData.patientStatusId
          ? this.state.selectedPatientData.patientStatusId
          : "1",
      // categoryId: this.state.patientCategories,
      categoryId: this.state.patientCategories.sort(function(a, b) {
        return a - b;
      }),
      createdById: this.state.userInfo.createdById,
    };
    // console.log(this.state.actualEstDate, localActualEstMoment, data)
    // return false
    // if (this.state.patientFaceSheetsStr && this.state.patientFaceSheetsStr.length) {
    //     data.faceSheet = this.state.patientFaceSheetsStr;
    // }
    // if (this.state.patientImagesStr && this.state.patientImagesStr.length) {
    //     data.photo = this.state.patientImagesStr;
    // }
    showNotification = {
      title: "Update Patient",
      message: "Updating Patient, Please Wait",
      type: "info",
    };
    userService.showNotification(showNotification);
    showNotification = {};
    this.setState({
      loading: true,
      isUpdating: true,
    });
    // return
    const response = await fetch(apiPath.createPatient, {
      method: "PUT",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.updatePatientFailedTitle,
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: enMsg.updatePatientFailedTitle,
            message: enMsg.updatePatientFailedDesc,
            type: "danger",
          };
          let error = new Error(response.statusText);
        }
        // this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        if (data.status == 400) {
          showNotification = {
            title: enMsg.updatePatientFailedTitle,
            message: data.title,
            type: "danger",
          };
          userService.showNotification(showNotification);
        }
        if (data[0] && data[0].patientId) {
          let finalData = data[0];
          showNotification = {
            title: "Success",
            message: "Patient updated",
            type: "success",
          };
          let patientList = [];
          patientList = this.state.patientList.map((pList, key) => {
            if (pList.patientId == data[0].patientId) {
              pList = data[0];
            }
            return pList;
          });
          const patientCategoryList = [];
          finalData.patientCatagoryMapping &&
            finalData.patientCatagoryMapping.length &&
            finalData.patientCatagoryMapping.map((el) => {
              return patientCategoryList.push(el.categoryId);
            });
          sessionStorage.setItem(PATIENT_LIST, JSON.stringify(patientList));

          // this.modalClose();
          this.setState({
            // loading: false,
            patientName:
              (finalData.lastName ? finalData.lastName : "") +
              ", " +
              finalData.firstName +
              " " +
              (finalData.middleName ? finalData.middleName : ""),
            selectedPatientData: data[0],
            patientCategoryList: patientCategoryList,
            patientList: patientList,
            isUpdating: false,
          });
          window.dispatchEvent(new Event("resize"));
          userService.showNotification(showNotification);
          this.uploadImages(this.state.selectedPatientId, true);

          return true;
        } else {
          this.modalClose();
          this.setState({ loading: false });
          return true;
        }
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.updatePatientFailedTitle,
          message: enMsg.updatePatientFailedDesc,
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      });
  }
  async uploadImages(patientId, patienFlag) {
    let showNotification = {};
    if (this.state.patientFaceSheets && !this.state.patientFaceSheets.length) {
      this.modalClose();
      this.setState(
        {
          loading: false,
        },
        () => this.fetchPatients()
      );
      return true;
    } else {
      showNotification = {
        title: "Uploading Files",
        message: "Uploading Files, Please Wait",
        type: "info",
      };
      userService.showNotification(showNotification);
      showNotification = {};
    }
    const data = {
      files: this.state.patientFaceSheets.length
        ? this.state.patientFaceSheets
        : [],
    };
    this.setState({
      loading: true,
    });
    const datas = new FormData();
    this.state.patientFaceSheets.map((files, key) => {
      datas.append("files", files);
    });
    const response = await fetch(
      apiPath.uploadPatientFiles1 + "/" + patientId,
      {
        method: "POST",
        headers: generalMimeAuthTokenHeader(),
        body: datas,
        data: datas,
      }
    )
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Patient Files",
            message: "Patient Files Not Uploaded, Please try again",
            type: "danger",
          };
        } else if (response.ok) {
          showNotification = {
            title: "Patient Files",
            message: "Patient Files Uploaded",
            type: "success",
          };
          return response.text();
        } else {
          showNotification = {
            title: "Patient Files",
            message: "Patient Files Not Uploaded, Please try again",
            type: "danger",
          };
        }
        this.modalClose();
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        this.modalClose();
        this.setState({ loading: false });
        userService.showNotification(showNotification);
        if (patienFlag) {
          this.fetchPatientInfo();
        } else {
          this.setState(
            {
              // loading: false,
              currentPage: 0,
              patientSearchKeyword: "",
              sorting: "2",
              filter: "",
            },
            () => this.fetchPatients()
          );
        }
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: "Patient Files",
          message: "Patient Files Not Uploaded, Please try again",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        this.modalClose();
      });
  }
  async deleteImages(facesheetId) {
    let showNotification = {};
    this.setState({
      loading: true,
    });
    const response = await fetch(
      apiPath.deleteFaceSheeet + "?facesheetId=" + facesheetId,
      {
        method: "DELETE",
        headers: generalAuthTokenHeader(),
      }
    )
      .then((response) => {
        console.log(response);
        if (response.status === 400) {
          showNotification = {
            title: "Delete File",
            message: "Bad response from server",
            type: "danger",
          };
          this.setState({
            loading: false,
          });
        } else if (response.ok) {
          showNotification = {
            title: "Success",
            message: "File Successfully Deleted",
            type: "success",
          };
          let faceArr = [];
          faceArr = this.state.patientUploadHistory.filter((v, i) => {
            return v.id != facesheetId;
          });
          this.setState({
            patientUploadHistory: faceArr,
          });
          return response.text();
        } else {
          showNotification = {
            title: "Delete File",
            message: "Bad response from server",
            type: "danger",
          };
          this.setState({
            loading: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          loading: false,
        });
        userService.showNotification(showNotification);
      })
      .catch((error) => {
        showNotification = {
          title: "Delete File",
          message: "Something went wrong.Please try after sometime..",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        this.modalClose();
      });
  }
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let facilityNameValid = this.state.facilityNameValid;
    let addressValid = this.state.addressValid;
    let primaryPhoneValid = this.state.primaryPhoneValid;
    let statusValid = this.state.statusValid;
    let secondaryPhoneValid = this.state.secondaryPhoneValid;

    let firstNameValid = this.state.firstNameValid;
    let lastNameValid = this.state.lastNameValid;
    let middleNameValid = this.state.middleNameValid;
    let genderValid = this.state.genderValid;
    let insuranceValid = this.state.insuranceValid;
    let hospitalValid = this.state.hospitalValid;
    let dobValid = this.state.dobValid;
    let estDateValid = this.state.estDateValid;
    let actualEstDateValid = this.state.actualEstDateValid;
    let patientCategotiesValid = this.state.patientCategotiesValid;

    switch (fieldName) {
      case "firstName":
        firstNameValid = fieldValue.trim().length > 0;
        fieldValidationErrors.firstName = firstNameValid
          ? ""
          : enMsg.firstNameRequiredMsg;
        break;
      case "middleName":
        middleNameValid =
          (fieldValue && fieldValue.trim().length > 0) || fieldValue == ""
            ? true
            : false;
        fieldValidationErrors.middleName = middleNameValid
          ? ""
          : enMsg.middleNameRequiredMsg;
        break;
      case "lastName":
        lastNameValid = fieldValue.trim().length > 0;
        fieldValidationErrors.lastName = lastNameValid
          ? ""
          : enMsg.lastNameRequiredMsg;
        break;
      case "gender":
        genderValid = fieldValue && fieldValue.trim().length > 0 ? true : false;
        fieldValidationErrors.gender = !genderValid
          ? enMsg.genderRequiredMsg
          : "";
        break;
      case "insurance":
        insuranceValid = fieldValue || fieldValue == "" ? true : false;
        fieldValidationErrors.insurance = !insuranceValid
          ? enMsg.insuranceRequiredMsg
          : "";
        break;
      case "hospital":
        hospitalValid = fieldValue || fieldValue == "" ? true : false;
        fieldValidationErrors.hospital = !hospitalValid
          ? enMsg.hospitalRequiredMsg
          : "";
        break;
      case "dob":
        dobValid = fieldValue && fieldValue != "" ? true : false;
        fieldValidationErrors.dob = !dobValid ? enMsg.dobRequiredMsg : "";
        break;
      // case "estDate":
      //     estDateValid = (fieldValue && fieldValue != "") ? true : false;
      //     fieldValidationErrors.estDate = !estDateValid
      //         ? enMsg.estDateRequiredMsg
      //         : "";
      //     break;
      case "patientCategoties":
        patientCategotiesValid =
          fieldValue || fieldValue.length == 0 ? true : false;
        fieldValidationErrors.patientCategoties = !patientCategotiesValid
          ? enMsg.categoryRequiredMsg
          : "";
        break;
      case "address":
        addressValid = fieldValue.trim().length > 0;
        fieldValidationErrors.address = addressValid
          ? ""
          : enMsg.addressRequiredErrorMsg;
        break;
      case "status":
        statusValid = fieldValue ? true : false;
        fieldValidationErrors.status = statusValid ? "" : "Status is required";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        firstNameValid: firstNameValid,
        lastNameValid: lastNameValid,
        middleNameValid: middleNameValid,
        genderValid: genderValid,
        insuranceValid: insuranceValid,
        hospitalValid: hospitalValid,
        dobValid: dobValid,
        estDateValid: estDateValid,
        actualEstDateValid: actualEstDateValid,
        patientCategotiesValid: patientCategotiesValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return (
      this.state.firstNameValid &&
      this.state.lastNameValid &&
      this.state.middleNameValid &&
      this.state.genderValid &&
      this.state.insuranceValid &&
      this.state.hospitalValid &&
      this.state.dobValid &&
      this.state.estDateValid &&
      this.state.actualEstDateValid &&
      this.state.patientCategotiesValid
    );
  }
  search(event) {
    event.preventDefault();
    if (event.target.keyCode === 13) {
      return;
    }
    let searchKeyword = event.target.value.trim();
    let dpDashboardFilter = JSON.parse(
      localStorage.getItem(DP_DASHBOARD_FILTERS)
    );
    dpDashboardFilter = dpDashboardFilter ? dpDashboardFilter : {};
    dpDashboardFilter.patientSearchKeyword = searchKeyword;
    localStorage.setItem(
      DP_DASHBOARD_FILTERS,
      JSON.stringify(dpDashboardFilter)
    );
    this.setState({
      patientSearchKeyword: searchKeyword,
      currentPage: 0,
      selectedPatientId: "",
      selectRemovePatient: [],
      patientName: "",
      patientCategoryList: [],
      multiPatientMode: false,
      multiplePatientList: [],
    });
    return true;
  }
  searchReturn(event) {
    if (event.target.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();
      this.fetchPatients();
    }
  }
  handlePatientSorting = (event) => {
    let dpDashboardFilter = JSON.parse(
      localStorage.getItem(DP_DASHBOARD_FILTERS)
    );
    dpDashboardFilter = dpDashboardFilter ? dpDashboardFilter : {};
    const name = event.target.name;
    const sortByValue = event.target.value;
    dpDashboardFilter[name] = sortByValue;
    localStorage.setItem(
      DP_DASHBOARD_FILTERS,
      JSON.stringify(dpDashboardFilter)
    );
    this.setState(
      {
        [name]: sortByValue,
        currentPage: 0,
        selectedPatientId: "",
        selectRemovePatient: [],
        selectedPatientData: {},
        selectionCount: 0,
        multiPatientMode: false,
        multiplePatientList: [],
      },
      () => this.fetchPatients()
    );
  };
  fetchPatientInfo = (patientId, patientInfo) => {
    
    try {
      if (patientId == this.state.selectedPatientId) {
        this.setState({
          selectedPatientId: "",
          selectedPatientData: {},
          selectionCount: 0,
          multiPatientMode: false,
          multiplePatientList: [],
          selectRemovePatient: [],
        });
        return;
      }
      sessionStorage.setItem(MULTIPLE_PATIENT_LIST, JSON.stringify([]));
      this.setState({ loading: true });
      if (!patientId) {
        patientId = this.state.selectedPatientId;
        patientInfo = this.state.patientInfo;
      }
      let showNotification = {};
      let apiUrl = apiPath.getPatientList + "/" + patientId;
      const response = fetch(apiUrl, {
        method: "GET",
        headers: generalAuthTokenHeader(),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
          } else if (response.status === 401) {
            userService.logout();
            //window.location.reload(true);
            window.location.replace(baseRoutes.login.path);
          } else {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server",
              type: "danger",
            };
          }
         
          return response.json();
        })
        .then((data) => {
          const {
            patientId,
            firstName,
            middleName,
            lastName,
            patientCatagoryMapping,
            thirdPartyPatientId,
          } = data[0];
          const patientCategoryList = [];
          let referralSent = false;
          patientCatagoryMapping.map((el) => {
            if (el.refferalId) {
              referralSent = true;
            }
            return patientCategoryList.push(el.categoryId);
          });
          let patientList = [];
          patientList = this.state.patientList.map((pList, key) => {
            if (pList.patientId == data[0].patientId) {
              pList = data[0];
            }
            return pList;
          });
          sessionStorage.setItem(PATIENT_LIST, JSON.stringify(patientList));
          this.setState({
            selectedPatientId: patientId,
            patientName:
              (lastName ? lastName : "") +
              ", " +
              firstName +
              " " +
              (middleName ? middleName : ""),
            patientCategoryList: patientCategoryList,
            selectionCount: 1,
            selectedPatientData: data[0],
            loading: false,
            multiPatientMode: false,
            patientList: patientList,
            patientTaskList: data[0].patientTask ? data[0].patientTask : [],
            multiplePatientList: [patientId],
            selectRemovePatient:
              thirdPartyPatientId && !referralSent ? [thirdPartyPatientId] : [],
          });
          window.dispatchEvent(new Event("resize"));
          this.fetchTasks(patientId);
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: "Patient Detail",
            message: "Bad response from server",
            type: "danger",
          };
          this.setState({ loading: false });
          return response;
        });
      userService.showNotification(showNotification);
    } catch (error) {
      this.setState({ loading: false });
    }
  };
  fetchPatientList = (patientId, patientInfo) => {
    let selectedPatients = this.state.multiplePatientList;
    if (selectedPatients.includes(patientId)) {
      const index = selectedPatients.indexOf(patientId);
      if (index > -1) {
        selectedPatients.splice(index, 1);
      }
    } else {
      selectedPatients.push(patientId);
    }
    let selectRemovePatient = [...this.state.selectRemovePatient];
    if (
      patientInfo &&
      patientInfo.thirdPartyPatientId &&
      !patientInfo.referralSent
    ) {
      console.log(
        "remove top",
        patientInfo.thirdPartyPatientId,
        selectRemovePatient
      );
      if (selectRemovePatient.includes(patientInfo.thirdPartyPatientId)) {
        const index = selectRemovePatient.indexOf(
          patientInfo.thirdPartyPatientId
        );
        if (index > -1) {
          selectRemovePatient.splice(index, 1);
        }
      } else {
        selectRemovePatient.push(patientInfo.thirdPartyPatientId);
      }
      console.log(
        "remove bottom",
        patientInfo.thirdPartyPatientId,
        selectRemovePatient
      );
    }
    this.setState({
      selectedPatientId: "",
      selectedPatientData: {},
    });
    selectedPatients = selectedPatients.sort(function(a, b) {
      return b - a;
    });
    if (selectedPatients.length) {
      if (selectedPatients.length > 1) {
        try {
          this.setState({ loading: true });
          let data = {
            ids: selectedPatients,
          };
          let showNotification = {};
          let apiUrl = apiPath.multiSelectPatientCat;
          const response = fetch(apiUrl, {
            method: "POST",
            headers: generalAuthTokenHeader(),
            body: JSON.stringify(data),
            data: JSON.stringify(data),
          })
            .then((response) => {
              if (response.status === 400) {
                showNotification = {
                  title: "Patient Detail",
                  message: "Bad response from server",
                  type: "danger",
                };
              } else if (response.ok) {
              } else if (response.status === 401) {
                userService.logout();
                //window.location.reload(true);
                window.location.replace(baseRoutes.login.path);
              } else {
                showNotification = {
                  title: "Patient Detail",
                  message: "Bad response from server",
                  type: "danger",
                };
              }
              this.setState({ loading: false });
              return response.json();
            })
            .then((data) => {
              if (data && data.length) {
                this.setState({
                  patientCategoryList: data,
                });
              } else {
                this.setState({
                  patientCategoryList: [],
                });
              }
              return true;
            })
            .catch((error) => {
              showNotification = {
                title: "Patient Detail",
                message: "Bad response from server",
                type: "danger",
              };
              this.setState({ loading: false });
              this.setState({
                patientCategoryList: [],
              });
              return response;
            });
          userService.showNotification(showNotification);
        } catch (error) {
          this.setState({ loading: false });
        }
      }
      sessionStorage.setItem(
        MULTIPLE_PATIENT_LIST,
        JSON.stringify(selectedPatients)
      );
      if (selectedPatients.length > 1) {
        this.setState({
          multiPatientMode: true,
          multiplePatientList: selectedPatients,
          selectionCount: selectedPatients.length,
          selectRemovePatient: selectRemovePatient,
        });
      } else {
        this.setState({
          multiPatientMode: false,
          multiplePatientList: selectedPatients,
          selectionCount: selectedPatients.length,
          selectRemovePatient: selectRemovePatient,
        });
        if (this.state.selectedPatientId != selectedPatients[0]) {
          this.fetchPatientInfo(selectedPatients[0], patientInfo);
        }
      }
    } else {
      this.setState({
        multiPatientMode: false,
        multiplePatientList: [],
        selectionCount: 0,
        selectedPatientId: "",
        selectRemovePatient: [],
        selectedPatientData: {},
      });
    }
  };
  fetchPatientTasks = (patientId) => {
    try {
      this.setState({ loading: true });
      let showNotification = {};
      
      let apiUrl = apiPath.getPatientTasks + "/" + patientId;
      const response = fetch(apiUrl, {
        method: "GET",
        headers: generalAuthTokenHeader(),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Patient Tasks",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
          } else if (response.status === 401) {
            userService.logout();
            //window.location.reload(true);
            window.location.replace(baseRoutes.login.path);
          } else {
            showNotification = {
              title: "Patient Tasks",
              message: "Bad response from server",
              type: "danger",
            };
          }
         
          return response.json();
        })
        .then((data) => {
          this.setState({
            tasks: data,
          });
          window.dispatchEvent(new Event("resize"));
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: "Patient Tasks",
            message: "Bad response from server",
            type: "danger",
          };
          this.setState({ loading: false });
          // this.state.spinner.setAttribute('hidden', 'true');
          return response;
        });
      userService.showNotification(showNotification);
    } catch (error) {
      this.setState({ loading: false });
    }
  };
  loadMorePatient() {
    let localPatientList = this.state.patientList;
    this.setState(
      {
        loadMore: false,
      },
      () => this.fetchPatients()
    );
  }
  formatDateToLocalFormat(serverDate) {
    var dateObj = new Date(serverDate);
    var month = dateObj.getMonth() + 1; //months from 1-12
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();
    return month + "/" + day + "/" + year;
  }
  formatDateToServerFormat(localDate) {
    var dateObj = new Date(localDate);
    var month = dateObj.getMonth() + 1; //months from 1-12
    month = month < 10 ? "0" + month : "" + month;
    var day = dateObj.getDate();
    day = day < 10 ? "0" + day : "" + day;
    var year = dateObj.getFullYear();
    return year + "-" + month + "-" + day;
  }
  patientListRender = (pList, key) => {
    const {
      patientId,
      firstName,
      middleName,
      lastName,
      eastimatedDischargeDate,
      actualDischargeDate,
      formattedPatientDob,
      formattedEstimatedDischargeDate,
      insuranceId,
      patientStatusId,
      patientStatus,
      notificationCount,
      previousHospitalId,
      patientCatagoryMapping,
      patientDob,
      patientTask,
      pccPatientsEntity,
    } = pList;
    const { facilityType } = this.state;
    let localEstMoment = eastimatedDischargeDate
      ? moment.utc(eastimatedDischargeDate).format("MM/DD/YYYY")
      : "N/A";
    let localActualEstMoment = actualDischargeDate
      ? moment.utc(actualDischargeDate).format("MM/DD/YYYY")
      : null;
    let patientDischargedWarning =
      localActualEstMoment &&
      daysBetweenTwoDate(localActualEstMoment, new Date()) >= 30
        ? true
        : false;
    let localDobMoment = patientDob
      ? moment.utc(patientDob).format("MM/DD/YYYY")
      : "N/A";
    let admissionDate =
      pccPatientsEntity && pccPatientsEntity.admissionDate
        ? moment.utc(pccPatientsEntity.admissionDate).format("MM/DD/YYYY")
        : "N/A";
    return (
      <Box display="flex" flexDirection="row" width={1} key={key}>
        <Box pr={1} onClick={() => this.fetchPatientInfo(patientId, pList)}>
          <div className="user-infox name-status-task-insurance">
            <div className="name">
              {lastName +
                ", " +
                firstName +
                " " +
                (middleName ? middleName : "")}
              {notificationCount !== 0 && (
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                  >
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx="4"
                      cy="4"
                      r="4"
                      fill="red"
                    />
                  </svg>
                </span>
              )}
            </div>
            <div className="date-insurance">
              <span className="dob">DOB:&nbsp;{localDobMoment + " "}</span>
              {localActualEstMoment ? (
                <span className="date-dc warning-sign">
                  DC:&nbsp;{localActualEstMoment}
                  {patientDischargedWarning ? (
                    <CustomTooltip title="This patient is discharged more than 30 days ago.">
                      <WarningIcon />
                    </CustomTooltip>
                  ) : null}
                </span>
              ) : (
                <span className="date-dc">Est DC:&nbsp;{localEstMoment}</span>
              )}
            </div>

            <div className="date-insurance align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.127"
                height="10"
                viewBox="0 0 11.127 10"
              >
                <path
                  id="medical_bag"
                  data-name="medical bag"
                  d="M6.438,3,5.327,4.111V5.222H3.66A1.181,1.181,0,0,0,2.549,6.333l-.556,5.556A1,1,0,0,0,3.1,13h8.889A1,1,0,0,0,13.1,11.889l-.556-5.556a1.226,1.226,0,0,0-1.111-1.111H9.771V4.111L8.66,3Zm0,1.111H8.66V5.222H6.438Zm.556,2.778H8.1V8.556H9.771V9.667H8.1v1.667H6.994V9.667H5.327V8.556H6.994Z"
                  transform="translate(-1.986 -3)"
                  fill="#666"
                />
              </svg>
              Insurance:{" "}
              {insuranceId == 0 ||
              (insuranceId &&
                typeof this.state.insuranceList === "object" &&
                this.state.insuranceList.length)
                ? this.state.insuranceList.map((el) => {
                    if (el.insuranceId == insuranceId) {
                      return el.insuranceName;
                    }
                  })
                : "none"}
            </div>
            {pList.pccPatientsEntity ? (
              <div className="date-insurance align-center">
                <svg
                  version="1.1"
                  width="13"
                  height="13"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 129 129"
                  enable-background="new 0 0 129 129"
                >
                  <g>
                    <g>
                      <path d="m121.3,66.4l-58.7-58.7c-0.9-0.9-2.2-1.3-3.4-1.2l-44,5c-1.9,0.2-3.5,1.7-3.7,3.7l-5,44c-0.1,1.3 0.3,2.5 1.2,3.4l58.7,58.7c0.8,0.8 1.9,1.2 3,1.2 1.1,0 2.1-0.4 3-1.2l49-49c0.8-0.8 1.2-1.8 1.2-3-0.1-1-0.5-2.1-1.3-2.9zm-106.2-8.3l4.4-38.6 38.6-4.4 39.9,39.9-43,43-39.9-39.9zm54.3,54.3l-8.4-8.4 43-43 8.4,8.4-43,43z" />
                      <path d="m54.7,32.2c-3-3-7-4.7-11.3-4.7s-8.2,1.7-11.3,4.7c-6.2,6.2-6.2,16.3 0,22.5 3,3 7,4.7 11.3,4.7s8.2-1.7 11.3-4.7c3-3 4.7-7 4.7-11.3-7.10543e-15-4.2-1.7-8.2-4.7-11.2zm-5.9,16.6c-2.8,2.8-7.8,2.8-10.7,0-2.9-2.9-2.9-7.7 0-10.7 1.4-1.4 3.3-2.2 5.3-2.2s3.9,0.8 5.3,2.2c1.4,1.4 2.2,3.3 2.2,5.3 0.1,2.1-0.7,3.9-2.1,5.4z" />
                    </g>
                  </g>
                </svg>
                EMR Status:{" "}
                {pList.pccPatientsEntity.patientStatus
                  ? pList.pccPatientsEntity.patientStatus
                  : "N/A"}
              </div>
            ) : (
              ""
            )}
          </div>
        </Box>
        {facilityType && facilityType == VENDOR_FACILITY ? (
          <div className="oval_box">
            <Box display="flex" flexDirection="row" className="tags-cover-box">
              <div className="tags-cover">
                <p
                  className={`tag status3 ${
                    patientStatus && patientStatus.patientStatusValue
                      ? patientStatus.patientStatusValue
                      : "noStatus"
                  }`}
                >
                  {patientStatus && patientStatus.patientStatusValue
                    ? patientStatus.patientStatusValue
                    : "none"}
                </p>
              </div>
              <div className="tasks-cover">
                <p className={`tag ${this.getTaskClass(patientTask)}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="7.665"
                    viewBox="0 0 10 7.665"
                  >
                    <path
                      id="check"
                      d="M13.5,6.394,6.643,13.251,3.5,10.108,4.308,9.3l2.335,2.335,6.049-6.049Z"
                      transform="translate(-3.5 -5.586)"
                      fill="#f8f8f8"
                    />
                  </svg>
                  &nbsp; Tasks:{" "}
                  {patientTask && patientTask.length ? patientTask.length : 0}
                </p>
              </div>
            </Box>
            <Box display="flex" flexDirection="row" className="tags-cover-box">
              <CustomTooltip
                title={
                  pccPatientsEntity && pccPatientsEntity.floorDesc
                    ? "Location: " +
                      pccPatientsEntity.floorDesc +
                      "-" +
                      pccPatientsEntity.roomDesc +
                      "-" +
                      pccPatientsEntity.bedDesc
                    : "Location: N/A"
                }
                placement="top"
                arrow
              >
                <div className="tags-cover">
                  <p
                    className={`tag1 mr-10 status3 ${
                      patientStatus && patientStatus.patientStatusValue
                        ? patientStatus.patientStatusValue
                        : "noStatus"
                    }`}
                  >
                    Location:{" "}
                    {pccPatientsEntity && pccPatientsEntity.floorDesc
                      ? pccPatientsEntity.floorDesc +
                        "-" +
                        pccPatientsEntity.roomDesc +
                        "-" +
                        pccPatientsEntity.bedDesc
                      : "N/A"}
                  </p>
                </div>
              </CustomTooltip>
              <div className="tasks-cover">
                <p
                  className={`tag1 ${
                    patientTask && patientTask.length ? "task1" : "task2"
                  }`}
                >
                  Admitted: {admissionDate}
                </p>
              </div>
            </Box>
          </div>
        ) : (
          <div className="oval_box">
            <Box display="flex" flexDirection="row" className="tags-cover-box">
              <div className="tags-cover">
                <p
                  className={`tag status3 ${
                    patientStatus && patientStatus.patientStatusValue
                      ? patientStatus.patientStatusValue
                      : "noStatus"
                  }`}
                >
                  {patientStatus && patientStatus.patientStatusValue
                    ? patientStatus.patientStatusValue
                    : "none"}
                </p>
              </div>
              <div className="tasks-cover">
                <p className={`tag ${this.getTaskClass(patientTask)}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="7.665"
                    viewBox="0 0 10 7.665"
                  >
                    <path
                      id="check"
                      d="M13.5,6.394,6.643,13.251,3.5,10.108,4.308,9.3l2.335,2.335,6.049-6.049Z"
                      transform="translate(-3.5 -5.586)"
                      fill="#f8f8f8"
                    />
                  </svg>
                  &nbsp; Tasks:{" "}
                  {patientTask && patientTask.length ? patientTask.length : 0}
                </p>
              </div>
            </Box>
          </div>
        )}
        <Box className="list-scrollxx category-value-status-list pateint_box">
          {patientCatagoryMapping && patientCatagoryMapping.length ? (
            <OwlCarousel
              className={`owl-theme`}
              ref={this.owlCarouselRef}
              key={`carousel_${patientCatagoryMapping.length}`}
              {...this.state.owlCarouselOptions}
            >
              {patientCatagoryMapping
                // .sort((a, b) => a.categoryId > b.categoryId ? 1 : -1)
                .map((el, index) => {
                  const { masterCategories, catagoryId, status } = el;

                  return (
                    <NavLink
                      key={index}
                      to={`${basePath}${
                        dpBaseRoutes.singlePatientInfo.path
                      }?key=${el.categoryId}&mode=${btoa(
                        btoa(this.state.multiPatientMode)
                      )}&facility=${btoa(btoa(this.state.facilityType))}`}
                      onClick={() => this.categorySelection(patientId, pList)}
                    >
                      <Box
                        className="itemx menu-itemx owl-item-box "
                        key={index}
                        border={1}
                        id="refidoOwl"
                      >
                        <p className="category-value">
                          {masterCategories.categoryValue}
                        </p>
                        <p
                          className={`category-status ${
                            el &&
                            el.refferalEntity &&
                            el.refferalEntity.masterRefferalStatusEntity
                              ? el.refferalEntity.masterRefferalStatusEntity
                                  .refferalStatusValue
                              : "Finding"
                          }`}
                        >
                          {el &&
                          el.refferalEntity &&
                          el.refferalEntity.masterRefferalStatusEntity
                            ? el.refferalEntity.masterRefferalStatusEntity
                                .refferalStatusValue
                            : "Finding"}
                        </p>
                      </Box>
                    </NavLink>
                  );
                })}
            </OwlCarousel>
          ) : (
            <div
              className="no-data-text pointer"
              onClick={() => this.categorySelection(patientId, pList)}
            >
              Select categories/Edit Patient
            </div>
          )}
        </Box>
      </Box>
    );
  };
  handleDob = (value) => {
    let dob = true;
    if (value == null || !this.dateUtility.isValid(value)) {
      dob = false;
    }
    this.setState(
      {
        dob: value,
        dobValid: dob,
      },
      () => {
        this.validateField("dob", value);
      }
    );
  };
  handleActualEstDate = (value) => {
    let actualEstDate = true;
    if (value && !this.dateUtility.isValid(value)) {
      actualEstDate = false;
    }
    console.log(value);
    this.setState(
      {
        actualEstDate: value,
        actualEstDateValid: actualEstDate,
      },
      () => {
        this.validateField("actualEstDate", value);
      }
    );
  };
  handleEstDate = (value) => {
    let estDate = true;
    if (value && !this.dateUtility.isValid(value)) {
      estDate = false;
    }
    this.setState(
      {
        estDate: value,
        estDateValid: estDate,
      },
      () => {
        this.validateField("estDate", value);
      }
    );
  };
  handleDobError(error, value) {
    this.setState({
      dobValid: error || value == "" || value == null ? false : true,
    });
  }
  handleEstDateError(error, value) {
    // console.log(error, value)
    // this.setState({
    //     estDateValid: error ? false : true
    // });
  }
  handleActualEstDateError(error, value) {
    // console.log(error, value)
    // this.setState({
    //     estDateValid: error ? false : true
    // });
  }
  handleSingleDpDChange(value, name) {
    if (!value || value == "") {
      // return;
    }
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }
  onImageFileBrowse = (event) => {
    this.inputOpenImageFileRef.current.click();
  };
  onFaceSheetFileBrowse = (event) => {
    this.inputOpenFaceSheetFileRef.current.click();
  };
  handleImageSelectedFile = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    let fieldValidationErrors = this.state.formErrors;
    let error = "";
    let patientImageValid = false;
    if (typeof file == "object" && file.size > ALLOWED_PROFILE_IMAGE_SIZE) {
      error = enMsg.allowedProfileImageSize;
    } else if (
      typeof file == "object" &&
      !ALLOWED_IMAGE_EXTENTION.includes(file.type)
    ) {
      error = enMsg.allowedProfileImageType;
    } else {
      patientImageValid = true;
    }
    if (typeof file !== undefined && file && patientImageValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        this.setState({
          patientImage: result,
          patientImageName: file.name,
          isImage: true,
        });
        this.patientImageBlob = file;
        this.patientImageName = file.name;
      });
      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
    fieldValidationErrors.patientImage = patientImageValid ? "" : error;
    this.setState(
      {
        patientImageValid: patientImageValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };
  handleFaceSheetSelectedFile = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    let fieldValidationErrors = this.state.formErrors;
    let error = "";
    let patientFaceSheetValid = false;
    if (typeof file == "object" && file.size > ALLOWED_FACESHEET_SIZE) {
      error = enMsg.allowedFaceSheetSize;
    } else if (
      typeof file == "object" &&
      !ALLOWED_FACESHEET_EXTENTION.includes(file.type)
    ) {
      error = enMsg.allowedFaceSheetType;
    } else {
      patientFaceSheetValid = true;
    }
    if (typeof file !== undefined && file && patientFaceSheetValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        this.setState({
          patientFaceSheet: result,
          patientFaceSheetName: file.name,
          isFaceSheet: true,
        });
        this.patientFaceSheetBlob = file;
        this.patientFaceSheetName = file.name;
      });
      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
    fieldValidationErrors.patientFaceSheet = patientFaceSheetValid ? "" : error;
    this.setState(
      {
        patientFaceSheetValid: patientFaceSheetValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {};
  }
  selectPatientCategory = (catagoryId) => {
    let patientCategories = this.state.patientCategories;
    if (patientCategories.includes(catagoryId)) {
      const index = patientCategories.indexOf(catagoryId);
      if (index > -1) {
        patientCategories.splice(index, 1);
      }
    } else {
      patientCategories.push(catagoryId);
    }
    this.setState(
      {
        patientCategories: patientCategories,
      },
      () => {
        this.validateField("patientCategoties", patientCategories);
      }
    );
  };
  imageFilesRemoveAll(fileType) {
    let fieldValidationErrors = this.state.formErrors;
    if (fileType == "facesheet") {
      fieldValidationErrors.patientFaceSheet = "";
      this.setState({
        isFaceSheet: false,
        patientFaceSheetValid: true,
        formErrors: fieldValidationErrors,
      });
      this.refs.patientFaceSheets.removeFiles();
    } else {
      fieldValidationErrors.patientImage = "";
      this.setState({
        isImage: false,
        patientImageValid: true,
        formErrors: fieldValidationErrors,
      });
      this.refs.patientImages.removeFiles();
    }
  }
  imageFilesRemoveOne(file, fileType) {
    let fieldValidationErrors = this.state.formErrors;
    if (fileType == "facesheet") {
      fieldValidationErrors.patientFaceSheet = "";
      this.setState({
        patientFaceSheetValid: true,
        formErrors: fieldValidationErrors,
      });
      this.refs.patientFaceSheets.removeFile(file);
    } else {
      fieldValidationErrors.patientImage = "";
      this.setState({
        patientImageValid: true,
        formErrors: fieldValidationErrors,
      });
      this.refs.patientImages.removeFile(file);
    }
  }
  onImageFilesChange = (patientImages) => {
    this.setState({
      patientImages,
    });
  };
  onFaceSheetFilesChange = (patientFaceSheets) => {
    this.setState({
      patientFaceSheets,
    });
  };
  fileSelectClick = (fileType) => {
    let fieldValidationErrors = this.state.formErrors;
    if (fileType == "facesheet") {
      fieldValidationErrors.patientFaceSheet = "";
      this.setState({
        isFaceSheet: false,
        patientFaceSheetValid: true,
        formErrors: fieldValidationErrors,
        patientFaceSheetsStr: [],
      });
    } else {
      fieldValidationErrors.patientImage = "";
      this.setState({
        isImage: false,
        patientImageValid: true,
        formErrors: fieldValidationErrors,
        patientImagesStr: [],
      });
    }
  };
  onImageFilesError = (error, file) => {
    let errorMsg = "";
    switch (error.code) {
      case 1:
        errorMsg = error.message;
        break;
      case 2:
        errorMsg = error.message;
        break;
      case 3:
        errorMsg = error.message;
        break;
      case 4:
        errorMsg = error.message;
        break;
      default:
        errorMsg = "File not found";
        break;
    }
    let fieldValidationErrors = this.state.formErrors;
    let patientImageValid = false;
    fieldValidationErrors.patientImage = patientImageValid ? "" : errorMsg;
    this.setState(
      {
        patientImageValid: patientImageValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };
  onFaceSheetFilesError = (error, file) => {
    let errorMsg = "";
    switch (error.code) {
      case 1:
        errorMsg = error.message;
        break;
      case 2:
        errorMsg = error.message;
        break;
      case 3:
        errorMsg = error.message;
        break;
      case 4:
        errorMsg = error.message;
        break;
      default:
        errorMsg = "File not found";
        break;
    }
    let fieldValidationErrors = this.state.formErrors;
    let patientFaceSheetValid = false;
    fieldValidationErrors.patientFaceSheet = patientFaceSheetValid
      ? ""
      : errorMsg;
    this.setState(
      {
        patientFaceSheetValid: patientFaceSheetValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };
  loaderStatus = (status) => {
    this.setState({
      loading: status,
    });
  };
  closeImportModal = () => {
    this.setState({
      importModal: false,
    });
  };
  handleAssignedSuccess = () => {
    // this.setState({
    //     importModal: false,
    // }, () => this.fetchPatients())
    this.fetchPatients();
  };
  handleRemovedSuccess = () => {
    this.setState(
      {
        selectRemovePatient: [],
      },
      () => this.fetchPatients()
    );
    // this.fetchPatients()
  };
  getTaskClass = (patientTasks) => {
    if (patientTasks.length == 0) return "task2";
    const patientTasksStatus = patientTasks.map((value) => value.changeStatus);
    var patientTasksStatusSet = new Set(patientTasksStatus);

    if (patientTasksStatusSet.size == 1 && patientTasksStatusSet.has(6)) {
      return "task2";
    }
    return "task1";
  };
  async fetchTasks(patientId, selectedPatientId) {
   
    try {

      let apiUrl = apiPath.GetGroupPatient + '/' + patientId;

      console.log('Fetching tasks from API:', apiUrl); // Log API endpoint URL

      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: generalAuthTokenHeader()
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch patient tasks. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response: patientTaskGroup', data); // Log API response data

      this.setState({
        patientTaskGroup: data, 
       
        
      });
    } catch (error) {
      console.log(error); // Log any errors
    } finally {
      this.setState({ loading: false });
      
    }
  }


  render() {
    const {
      currentPage,
      total,
      sorting,
      filter,
      patientCategoryList,
      loading,
      patientName,
      selectedPatientId,
      patientList,
      hospital,
      insurance,
      gender,
      insuranceList,
      categoryList,
      hospitalList,
      patientCategories,
      selectionCount,
      totalPatientShowing,
      tasks,
      facilityType,
      patientFilterList,
      patientTaskGroup
    } = this.state;
    const { classes } = this.props;
    // const menu = this.menuItems;
    return (
      <div id="facility-index">
        {/* <iframe src="https://connect.pointclickcare.com/auth/login?client_id=eN2VehceGkQAe9XfDKZfl9WfAtoeYyaQ&response_type=OAuth2&redirect_uri=http://localhost:3000/maxmrj/login&state=5455"/> */}
        <ChangePassword
          id="change-pssword-dialog"
          keepMounted
          open={this.state.openModal}
          handleChangePasswordDialog={this.handleClose}
          onClose={this.handleClose}
          value={""}
          allowCloseModal={false}
        />
        <BAAComponent
          open={this.state.bbaCondition}
          handleBAAAgree={this.handleBAAAgree}
        />
        <TermsComponent
          open={this.state.termNcondition}
          handleTNCAgree={this.handleTNCAgree}
        />
        <ImportPatientModal
          showModal={this.state.importModal}
          handleCancel={this.closeImportModal}
          handleAssignedSuccess={this.handleAssignedSuccess}
          handleRemovedSuccess={this.handleRemovedSuccess}
          patientFilterList={patientFilterList}
          reduxLoadFlag={this.state.reduxLoadFlag}
          selectRemovePatient={this.state.selectRemovePatient}
        />
        <ConfirmModal
          title="Confirm"
          message={this.state.confirmModalMessage}
          confirmModalStatus={this.state.confirmModalStatus}
          confirmModalClose={this.confirmModalClose}
          submit={this.confirmModalSubmit}
          loading={this.state.loading}
        />
        <Dialog
          open={this.state.modalStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          scroll="body"
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal modalFitOnScreen new-modal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            {this.state.selectedPatientId ? "Edit Patient" : "Add New Patient"}
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.handleSubmit} noValidate>
            <DialogContent>
              <Box
                width={1}
                display="flex"
                justifyContent="flex-start"
                mb={1}
                mt={1}
              >
                <Box width={1 / 2} pr={2}>
                  <TextField
                    InputLabelProps={{ className: "required-label" }}
                    name="firstName"
                    label="First Name"
                    value={this.state.firstName}
                    onChange={this.handleChangeInput}
                    inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                    disabled={
                      this.state.selectedPatientData &&
                      this.state.selectedPatientData.thirdPartyPatientId
                        ? true
                        : false
                    }
                    // inputProps={{ maxLength: MAX_INPUT_LENGTH, pattern: "/[^A-Za-z\d]/" }}
                  />
                  <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  />
                </Box>
                <Box width={1 / 2} pl={2}>
                  <TextField
                    InputLabelProps={{ className: "required-label" }}
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChangeInput}
                    data-validators="isRequired"
                    label="Last Name"
                    inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                    disabled={
                      this.state.selectedPatientData &&
                      this.state.selectedPatientData.thirdPartyPatientId
                        ? true
                        : false
                    }
                  />
                  <FormErrors
                    show={!this.state.lastNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="lastName"
                  />
                </Box>
              </Box>
              <Box width={1} display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <TextField
                    // InputLabelProps={{ className: "required-label" }}
                    name="middleName"
                    value={this.state.middleName ? this.state.middleName : ""}
                    onChange={this.handleChangeInput}
                    // data-validators="isRequired"
                    label="Middle Name"
                    placeholder="Middle Name"
                    inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                    disabled={
                      this.state.selectedPatientData &&
                      this.state.selectedPatientData.thirdPartyPatientId
                        ? true
                        : false
                    }
                  />
                  <FormErrors
                    show={!this.state.middleNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="middleName"
                  />
                </Box>
                <Box width={1 / 2} pl={2}>
                  <FormControl>
                    <InputLabel className={"required-label"}>Gender</InputLabel>
                    <Select
                      id="gender"
                      color="secondary"
                      name="gender"
                      value={gender}
                      disabled={
                        this.state.selectedPatientData &&
                        this.state.selectedPatientData.thirdPartyPatientId
                          ? true
                          : false
                      }
                      onChange={(event) =>
                        this.handleSingleDpDChange(event.target.value, "gender")
                      }
                    >
                      <MenuItem value="">Select Gender</MenuItem>
                      <MenuItem key={"male"} value={"male"}>
                        Male
                      </MenuItem>
                      <MenuItem key={"female"} value={"female"}>
                        Female
                      </MenuItem>
                      <MenuItem key={"other"} value={"other"}>
                        Other
                      </MenuItem>
                    </Select>
                    <FormErrors
                      show={!this.state.genderValid}
                      formErrors={this.state.formErrors}
                      fieldName="gender"
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box width={1} display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <FormControl variant="outlined">
                    <MuiPickersUtilsProvider
                      variant="outlined"
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        // variant="inline"

                        label="Date Of Birth"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="start-date-picker-dialog"
                        // InputProps={{ autoComplete: "off" }}
                        InputLabelProps={{
                          className: "required-label",
                        }}
                        name="dob"
                        animateYearScrolling={true}
                        value={this.state.dob}
                        maxDate={this.maxDate}
                        // minDate={this.minDate}
                        maxDateMessage={enMsg.dobMaxDate}
                        onChange={this.handleDob}
                        onError={this.handleDobError}
                        className="KeyboardDatePicker"
                        invalidDateMessage={enMsg.invalidDate}
                        // closeAfterSelect={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          className: "date-picker-span",
                        }}
                        disabled={
                          this.state.selectedPatientData &&
                          this.state.selectedPatientData.thirdPartyPatientId
                            ? true
                            : false
                        }
                      />
                    </MuiPickersUtilsProvider>
                    <FormErrors
                      show={!this.state.dobValid}
                      formErrors={this.state.formErrors}
                      fieldName="dob"
                    />
                  </FormControl>
                </Box>
                <Box width={1 / 2} pl={2}>
                  <FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        // variant="inline"

                        label="Estimated Discharge Date"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="start-date-picker-dialog"
                        InputLabelProps={
                          {
                            // className: "required-label"
                          }
                        }
                        InputProps={{ autoComplete: "off" }}
                        name="estDate"
                        animateYearScrolling={true}
                        value={this.state.estDate}
                        // minDate={this.minDate}
                        // minDateMessage={enMsg.estMinDate}
                        onChange={this.handleEstDate}
                        onError={this.handleEstDateError}
                        className="KeyboardDatePicker"
                        invalidDateMessage={enMsg.invalidDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          className: "date-picker-span",
                        }}
                        // disabled={this.state.selectedPatientData && this.state.selectedPatientData.thirdPartyPatientId ? true : false}
                      />
                    </MuiPickersUtilsProvider>
                    <FormErrors
                      show={!this.state.estDateValid}
                      formErrors={this.state.formErrors}
                      fieldName="estDate"
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box width={1} display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="Discharge Date"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="start-date-picker-dialog"
                        InputLabelProps={
                          {
                            // className: "required-label"
                          }
                        }
                        InputProps={{ autoComplete: "off" }}
                        name="actualEstDate"
                        animateYearScrolling={true}
                        value={this.state.actualEstDate}
                        // minDate={this.minDate}
                        maxDate={new Date()}
                        maxDateMessage={enMsg.maxDate}
                        onChange={this.handleActualEstDate}
                        onError={this.handleActualEstDateError}
                        className="KeyboardDatePicker"
                        invalidDateMessage={enMsg.invalidDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          className: "date-picker-span",
                        }}
                        disabled={
                          this.state.selectedPatientData &&
                          this.state.selectedPatientData.thirdPartyPatientId
                            ? true
                            : false
                        }
                      />
                    </MuiPickersUtilsProvider>
                    <FormErrors
                      show={!this.state.actualEstDateValid}
                      formErrors={this.state.formErrors}
                      fieldName="actualEstDate"
                    />
                  </FormControl>
                </Box>
                <Box width={1 / 2} pl={2}></Box>
              </Box>
              <Box width={1} display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <FormControl>
                    <InputLabel>Insurance</InputLabel>
                    <Select
                      id="insurance"
                      color="secondary"
                      name="insurance"
                      value={insurance}
                      onChange={(event) =>
                        this.handleSingleDpDChange(
                          event.target.value,
                          "insurance"
                        )
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                        variant: "menu",
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={0}>No Insurance</MenuItem>
                      {typeof insuranceList === "object" && insuranceList.length
                        ? insuranceList
                            // .sort((a, b) => a.insuranceId > b.insuranceId ? 1 : -1)
                            .map((iList, key) => {
                              if (iList.insuranceId != 0) {
                                return (
                                  <MenuItem
                                    key={"insurance_" + iList.insuranceId}
                                    value={iList.insuranceId}
                                  >
                                    {iList.insuranceName}
                                  </MenuItem>
                                );
                              }
                            })
                        : null}
                    </Select>
                    <FormErrors
                      show={!this.state.insuranceValid}
                      formErrors={this.state.formErrors}
                      fieldName="insurance"
                    />
                  </FormControl>
                </Box>
                <Box width={1 / 2} pl={2}>
                  <FormControl className="pos-rel">
                    <InputLabel>Hospital Restriction </InputLabel>
                    <Select
                      id="hospital"
                      color="secondary"
                      name="hospital"
                      value={hospital}
                      onChange={(event) =>
                        this.handleSingleDpDChange(
                          event.target.value,
                          "hospital"
                        )
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                        variant: "menu",
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="">Select Hospital</MenuItem>
                      {typeof hospitalList === "object" && hospitalList.length
                        ? hospitalList.map((hList, key) => {
                            return (
                              <MenuItem
                                className={
                                  filter !== key ? "sorting-items" : ""
                                }
                                key={key}
                                value={hList.previousHospitalId}
                              >
                                {hList.hospitalName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                    <FormErrors
                      show={!this.state.hospitalValid}
                      formErrors={this.state.formErrors}
                      fieldName="hospital"
                    />
                    <Tooltip title="Select Hospital" placement="top" arrow>
                      <img className="info-icon" src={infoIcon} />
                    </Tooltip>
                  </FormControl>
                </Box>
              </Box>
              <Box
                width={1}
                display="flex"
                justifyContent="flex-start"
                mb={1}
                style={{ display: "" }}
              >
                <Box width={1 / 2} pr={2} className="upload-links">
                  <FormControl>
                    <div className="form-caption">Face Sheet</div>
                    <Files
                      ref="patientFaceSheets"
                      className="files-dropzone-list"
                      onChange={this.onFaceSheetFilesChange}
                      onError={this.onFaceSheetFilesError}
                      accepts={[
                        "image/png",
                        "image/x-png",
                        "image/jpg",
                        "image/jpeg",
                        "application/pdf",
                      ]}
                      multiple
                      maxFiles={20}
                      maxFileSize={1024 * 1024 * 20}
                      minFileSize={0}
                      clickable
                    >
                      <Button
                        className="btn1"
                        onClick={() => this.fileSelectClick("facesheet")}
                      >
                        <MaterialLink className="pointer">
                          <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                          Upload
                        </MaterialLink>
                      </Button>
                    </Files>
                    <FormErrors
                      show={!this.state.patientFaceSheetValid}
                      formErrors={this.state.formErrors}
                      fieldName="patientFaceSheet"
                    />
                    {this.state.patientFaceSheets.length > 0 ? (
                      <List>
                        <span
                          className="pemoveAllLink"
                          onClick={() => this.imageFilesRemoveAll("facesheet")}
                        >
                          Remove All Files
                          <IconButton edge="end" aria-label="delete all">
                            <DeleteIcon />
                          </IconButton>
                        </span>

                        <Scrollbars
                          className="uploadFilesList"
                          autoHide={false}
                          universal={true}
                          autoHeight={true}
                          autoHeightMin={0}
                          autoHeightMax={60}
                        >
                          {this.state.patientFaceSheets.map((file, key) => (
                            <ListItem className="files-list-item" key={key}>
                              <ListItemText>
                                <div className="files-list-item-content">
                                  <div className="files-list-item-content-item files-list-item-content-item-1">
                                    {file.name}
                                  </div>
                                </div>
                              </ListItemText>
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() =>
                                    this.imageFilesRemoveOne(file, "facesheet")
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </Scrollbars>
                      </List>
                    ) : null}
                  </FormControl>
                </Box>
                <Box width={1 / 2} pl={2} className="upload-links">
                  {this.state.patientUploadHistory &&
                  this.state.patientUploadHistory.length > 0 ? (
                    <List>
                      <div className="form-caption">Uploaded Face Sheet</div>
                      <Scrollbars
                        className="uploadFilesList"
                        style={{ height: "60px" }}
                        universal={true}
                      >
                        {this.state.patientUploadHistory.map((file, key) => (
                          <ListItem className="files-list-item" key={key}>
                            <ListItemText>
                              <div className="files-list-item-content">
                                <div className="files-list-item-content-item files-list-item-content-item-1">
                                  {file.uploadName}
                                </div>
                              </div>
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => this.deleteImages(file.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </Scrollbars>
                    </List>
                  ) : null}
                  {this.state.loading ? (
                    <div class="modal_loader">
                      <div class="preloader">
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                        <span class="loader_span"></span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              <Box width={1}>
                <div className={"form-caption"}>Category Criteria</div>

                <FormControl>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexWrap={"wrap"}
                  >
                    {typeof categoryList === "object" && categoryList.length
                      ? categoryList.map((cList, key) => {
                          const {
                            categoryValue,
                            catagoryId,
                            status,
                            parent,
                          } = cList;
                          if (parent == 0) {
                            return (
                              <Chip
                                key={key}
                                icon={
                                  <Checkbox
                                    key={key}
                                    icon={<CircleUnchecked />}
                                    checkedIcon={<CircleCheckedFilled />}
                                    checked={
                                      patientCategories.includes(catagoryId)
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={categoryValue}
                                onClick={this.selectPatientCategory.bind(
                                  this,
                                  catagoryId
                                )}
                                className={
                                  patientCategories.includes(catagoryId)
                                    ? "chipinput activeCat"
                                    : "chipinput inactiveCat"
                                }
                              />
                            );
                          }
                        })
                      : "No categories available"}
                  </Box>

                  <FormErrors
                    show={!this.state.patientCategotiesValid}
                    formErrors={this.state.formErrors}
                    fieldName="patientCategoties"
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions className="modal-actions" justify="center">
              <Button
                type="submit"
                className="btn1"
                disabled={
                  !this.validateForm() ||
                  this.state.loading ||
                  this.state.isUpdating !== false
                }
              >
                {this.state.loading ||
                  (this.state.isUpdating !== false && (
                    <CircularProgress
                      size={24}
                      className="buttonProgress"
                      color="secondary"
                    />
                  ))}
                {this.state.selectedPatientId
                  ? "Update Patient"
                  : "Create Patient"}
              </Button>
              <Button onClick={this.modalClose} className="cancel-link">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        {this.state.loadingPatient ||
        this.state.loading ||
        !this.state.reduxLoadFlag ? (
          <div className="LinearProgress-ref">
            <LinearProgress />
          </div>
        ) : (
          <div className="LinearProgress-ref"></div>
        )}
        {!this.state.openModal &&
        !this.state.bbaCondition &&
        !this.state.termNcondition ? (
          <Box
            display="flex"
            flexDirection="row"
            width={1}
            className="patient-list-page patient-list-page-new"
          >
            <Box pr={1.5} className="left-content-box">
              <div className="left-content-header">
                <Box
                  display="flex"
                  className="divcontainer"
                  mb={2}
                  ref="dropzone"
                >
                  <Box pr={1} className="title-box alignCenterTextCenter">
                    <h2 className="titlename">
                      {dpBaseRoutes.patientList.pathName}
                    </h2>
                    <div className="show-text">
                      Showing {totalPatientShowing}/{total} Patient (
                      {selectionCount} selected)
                    </div>
                  </Box>

                  <Box
                    className="action-box patientListFilters"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Box pl={1} pr={1}>
                      {facilityType && facilityType == MAXMRJ_FACILITY ? (
                        <button
                          className="pointer btn1"
                          onClick={this.modalOpen}
                        >
                          {!this.state.selectedPatientId ||
                          this.state.multiPatientMode
                            ? "+ Add New Patient"
                            : "Update Patient"}
                        </button>
                      ) : (
                        <button
                          className="pointer btn1 import_btn"
                          onClick={this.showImportModal}
                        >
                          {// !this.state.selectedPatientId || this.state.multiPatientMode ? "Import Patient List" : "Edit Patient"
                          !this.state.selectedPatientId ||
                          this.state.multiPatientMode
                            ? "Import Patient List"
                            : "Import Patient List"}
                        </button>
                      )}
                    </Box>
                    <Box pl={1} pr={1}>
                      <Tooltip title="Search By Name" placement="top" arrow>
                        <Paper component="form" className="search-box-cover">
                          <InputBase
                            className="search-box"
                            placeholder="Search Patient..."
                            value={this.state.patientSearchKeyword}
                            onChange={this.search}
                            onKeyPress={this.searchReturn}
                          />
                          <IconButton
                            className=""
                            aria-label="search"
                            onClick={this.fetchPatients}
                          >
                            <SearchIcon />
                          </IconButton>
                        </Paper>
                      </Tooltip>
                    </Box>
                    <Box pl={1} pr={1} className="box-filter filter-label">
                      <FormGroup>
                        <FormControl variant="outlined">
                          <InputLabel>Filter By</InputLabel>
                          <Select
                            id="filter"
                            color="secondary"
                            name="filter"
                            className="filter-select"
                            value={filter}
                            // displayEmpty
                            onChange={this.handlePatientSorting}
                          >
                            <MenuItem value={""}>{"None"}</MenuItem>
                            {this.state.patientFilterList &&
                            this.state.patientFilterList.length
                              ? this.state.patientFilterList.map(
                                  (fList, key) => {
                                    return (
                                      <MenuItem key={key} value={fList.id}>
                                        {fList.filterName}
                                      </MenuItem>
                                    );
                                  }
                                )
                              : null}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Box>
                    <Box pl={1} className="box-filter filter-orderby">
                      <FormGroup>
                        <FormControl
                          variant="outlined"
                          className={"mat_select"}
                        >
                          <InputLabel htmlFor="filter">Order By</InputLabel>
                          <Select
                            id="sorting"
                            color="secondary"
                            name="sorting"
                            className="filter-select"
                            value={sorting}
                            onChange={this.handlePatientSorting}
                          >
                            {PATIENT_ORDERBY
                              ? Object.keys(PATIENT_ORDERBY).map((key) => {
                                  return (
                                    <MenuItem key={key} value={key}>
                                      {PATIENT_ORDERBY[key]}
                                    </MenuItem>
                                  );
                                })
                              : null}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Box>
                  </Box>
                </Box>
              </div>

              <Box
                width={1}
                className="left-content-list left-content-list-New"
              >
                <div className="PatientList">
                  <Scrollbars
                    className="patientlist-scroll"
                    style={{ height: "calc(100vh - 156px)" }}
                    universal={true}
                    autoHide={false}
                  >
                    {patientList &&
                    typeof patientList === "object" &&
                    patientList.length ? (
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMorePatient}
                        threshold={50}
                        hasMore={this.state.loadMore}
                        loader={
                          <div className="loader" key={0}>
                            Loading ...
                          </div>
                        }
                        useWindow={false}
                        getScrollParent={() => this.scrollParentRef}
                        style={{ width: "calc(100% - 16px)" }}
                      >
                        {patientList.map((pList, key) => {
                          return (
                            <Box
                              width={1}
                              key={key}
                              display="flex"
                              flexDirection="row"
                              mb={2}
                              className={
                                selectedPatientId == pList.patientId
                                  ? "selectedPatient"
                                  : ""
                              }
                            >
                              <div className="list-item-checkbox">
                                <Checkbox
                                  className="check-input"
                                  checked={
                                    pList.patientId == selectedPatientId ||
                                    this.state.multiplePatientList.includes(
                                      pList.patientId
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    this.fetchPatientList(
                                      pList.patientId,
                                      pList
                                    )
                                  }
                                />
                              </div>
                              <div
                                className={`list-item-content ${
                                  selectedPatientId == pList.patientId ||
                                  this.state.multiplePatientList.includes(
                                    pList.patientId
                                  )
                                    ? "selectedlist"
                                    : ""
                                }`}
                              >
                                {this.patientListRender(pList, key)}
                              </div>
                            </Box>
                          );
                        })}
                      </InfiniteScroll>
                    ) : this.state.loadingPatient ? (
                      <div className="no-data-div">
                        <div>Loading...</div>
                      </div>
                    ) : (
                      <div className="no-data-div">
                        {" "}
                        <div>No patient found </div>
                      </div>
                    )}
                  </Scrollbars>
                </div>
              </Box>
            </Box>

            <Box pl={1.5} className="right-box-cover">
              <CategoriesList
                patientCategoryList={patientCategoryList}
                loading={loading}
                patientName={patientName}
                patientId={selectedPatientId}
                multiPatientMode={this.state.multiPatientMode}
                facilityType={facilityType}
                openPatientPopup={this.openPatientPopup}
              />
              <TasksList
                tasks={tasks}
                loading={loading}
                patientName={patientName}
                patientId={selectedPatientId}
                patientDetail={this.state.selectedPatientData}
                updatePatient={this.fetchPatientInfo}
                patientTaskGroup={this.state.patientTaskGroup}
                multiPatientMode={this.state.multiPatientMode}
              />
            </Box>
          </Box>
        ) : null}
      </div>
    );
  }
}
export const PatientList = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientListClass);
export default PatientList;
