/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import {
    USER_INFO,
    AUTO_LOGOUT_TIME
} from "__helpers/constants";
import { FormErrors } from "components/Login/FormErrors";
import IdleTimer from 'react-idle-timer';
import { userService } from "_services/user.service";
import {
    IconButton,
    Link as MaterialLink,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Box,
    Link,
    LinearProgress
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import backArrow from "assets/img/icons/arrow-back.svg";
import enMsg from "__helpers/locale/en/en"
import { apiPath } from 'api'
import { generalAuthTokenHeader } from "__helpers/auth-header"
import logo from "assets/img/asglogo.png";
import { baseRoutes, basePath, providerBaseRoutes, providerUserBaseRoutes, dpBaseRoutes } from "base-routes";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
class Dashboard extends React.Component {
    constructor(props) {
 
        super(props)
        this.state = {
            timeout: AUTO_LOGOUT_TIME,
            logoutTime: 15,
            time: {},
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            modalOpen: props.open ? props.open : false,
            formErrors: {
                disAgreeService: "",
            },
            disAgreeService: false,
        }
        this.disAgreeService = this.disAgreeService.bind(this);
        this.agreeService = this.agreeService.bind(this);
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            modalOpen: nextProps.open ? nextProps.open : false,
        });
    }
    agreeService() {
        let showNotification = {};
        this.setState({ loading: true });
        let data = {};
        const response = fetch(apiPath.cmsServiceSave + "?bbaCondition=true&termAndCondition=true", {
            method: "GET",
            headers: generalAuthTokenHeader(),
            // body: JSON.stringify(data),
            // data: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 400) {
                    showNotification = {
                        title: enMsg.termsServiceTitle,
                        message: "Bad response from server",
                        type: "danger"
                    };
                } else if (response.ok) {
                    this.props.handleBAAAgree();
                } else {
                    showNotification = {
                        title: enMsg.termsServiceTitle,
                        message: "Bad response from server",
                        type: "danger"
                    };
                }
                userService.showNotification(showNotification);
                this.setState({ loading: false });
                return response.text();
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                showNotification = {
                    title: enMsg.termsServiceTitle,
                    message: "Bad response from server",
                    type: "danger"
                };
                userService.showNotification(showNotification);
                this.setState({ loading: false });
            });
    }
    disAgreeService() {
        let formErrors = this.state.formErrors;
        formErrors.disAgreeService = "Please Approve Business Associate Agreement to proceed";
        this.setState({
            disAgreeService: true,
            formErrors: formErrors,
        })
    }
    handleLogout = () => {
        userService.logout();
        window.location.replace(baseRoutes.login.path);
        return true;
    };

    handleClose=()=>{
    this.setState({
        modalOpen:false

    })
    this.props.openBaaHandler("close")
    }
    render() {
            
        return (
            <div className="logout-section">
                <Dialog
                    PaperProps={{
                        className: "change-password-dialogue"
                    }}
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="lg"
                    PaperProps={{
                        className: "change-password-dialogue"
                    }}
                    onEntering={this.handleEntering}
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.modalOpen}
                    {...this.state.other}
                    className={"change-pass-dialog profile-content add-modal CMSModal"}
                // fullScreen
                >
                    <DialogTitle className="add-modal-title" id="confirmation-dialog-title">
                        <div className="tab-heading">
                            <Box display="flex" flexDirection="row" className="cms-title-cover">
                                <img src={logo} alt="logo" className="cms-logo" />
                                <div className="cms-title">Business Associate Agreement</div>
                                {
                                    this.props.baaFromPage === "Admin Nav" ? 
                                    
 <Link to="#" onClick={this.handleClose} className="cms-link">
 <span><i className="fa fa-close"></i></span>&nbsp;<span>Close</span>
</Link> :  <Link to="#" onClick={this.handleLogout} className="cms-link">
 <span><i className="fa fa-power-off"></i></span>&nbsp;<span>Logout</span>
</Link>
                                }
                               
                            </Box>
                        </div>
                        {this.state.loading && (
                            <LinearProgress
                                size={24}
                            />
                        )}
                    </DialogTitle>
                    <DialogContent /* dividers */ className="cms-text">
                        <div className="baa-cover">
                        <b>
                            Business Associate Agreement
                            
                        </b>
                        <p dir="ltr">
                            This Business Associate Agreement ("BAA") is entered into between MAX MRJ,
                            Inc. ("MAX") and the customer agreeing to the terms below ("Customer"), and
                            amends, supplements and is incorporated into the Terms of Service (defined
                            below) solely with respect to Services (defined below). This BAA will be
                            effective as of the date electronically Terms of Service accepted by
                            Customer (the "BAA Effective Date").
                        </p>
                        <p dir="ltr">
                            Customer must have agreed to Terms of Service for BAA to be valid and
                            effective. Together with the Terms of Service, BAA will govern each party's
                            respective obligations regarding Protected Health Information (see below).
                        </p>
                        <p dir="ltr">
                            You represent and warrant that (i) you have the full legal authority to
                            bind Customer to BAA, (ii) you have read and understand this BAA, and (iii)
                            you agree, on behalf of Customer, to the terms of BAA. Do not agree to
                            these terms if you do not have legal authority to bind Customer.
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr">
                                    Definitions. Capitalized terms used but not otherwise defined in
                                    BAA will have the meaning given to them in HIPAA and the HITECH
                                    Act.
                                </p>
                                <ol type="a">
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "HIPAA" means the Health Insurance Portability and
                                        Accountability Act of 1996 and the rules and the regulations
                                        thereunder, as amended.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        “HIPAA Rules” means the privacy, security, breach notification
                                        and enforcement rules of 45 CFR § Parts 160 and 164.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "HITECH Act" means the Health Information Technology for
                                        Economic and Clinical Health Act enacted in the United States
                                        Congress, which is Title XIII of the American Recovery &amp;
                                        Reinvestment Act, and the regulations thereunder, as amended.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "Business Associate" has the definition given to it under HIPAA
                                        and shall be used to refer to the entity described in the first
                                        paragraph of this Agreement.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "Breach" has the definition given to it under HIPAA. Breach
                                        will not include an access, acquisition, use, or disclosure of
                                        PHI with respect to which MAX has determined in accordance with
                                        45 C.F.R. § 164.402 that there is a low probability that the
                                        PHI has been compromised.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "Covered Entity" has the definition given to it under HIPAA.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        “CFR means the Code of Federal Regulations.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        “Designated Record Set” has the same meaning as the term
                                        “Designated Record Set” in 45 CFR § 164.501.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "Services" means the MAX products and services stated at
                                        www.maxmrj.com/terms_of_service.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "Protected Health Information" or "PHI" has the definition
                                        given to it under HIPAA and for purposes of this BAA is limited
                                        to PHI within Customer Data to which MAX has access through the
                                        Services in connection with Customer's permitted use of
                                        Services.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        “Required By Law” has the same meaning as the term “required by
                                        law” in 45 CFR § 164.103.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "Security Breach" means any Breach of Unsecured PHI or Security
                                        Incident of which MAX becomes aware.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "Security Incident" has the definition given to it under HIPAA.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        "Terms of Service" means the written agreement(s) entered into
                                        between MAX and Customer for provision of the Services, which
                                        agreement(s) may be in the form of online terms of service.
                                    </p>
                                </li>
                            </ol>
                            </li>
                            
                            <li dir="ltr">
                                <p dir="ltr">
                                    Application. BAA applies to the extent Customer is acting as a
                                    Covered Entity or a Business Associate to create, receive,
                                    maintain, or transmit PHI via a Service and to the extent MAX, as a
                                    result, is deemed under HIPAA to be acting as a Business Associate.
                                    Customer acknowledges that BAA only applies to Service and does not
                                    apply to, or govern, any other MAX product, service, or feature
                                    that is not Service.
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    Disclosure and Use of PHI.
                                </p>
                                <ol type="a">
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX may use or disclose Protected Health Information to perform
                                        functions, activities or services for, or on behalf of, Covered
                                        Entity as specified in the underlying service agreement between
                                        Plan Sponsor and MAX with respect to the Health Plan(s),
                                        provided that such use or disclosure would not violate the
                                        HIPAA Rules if done by Covered Entity. If there is no
                                        underlying service agreement between Plan Sponsor and MAX with
                                        respect to the Health Plan(s), MAX may use or disclose
                                        Protected Health Information to perform functions, activities
                                        or services for, or on behalf of, Covered Entity for the
                                        purposes of payment, treatment or health care operations as
                                        those terms are defined in the HIPAA Rules, provided that such
                                        use or disclosure would not violate the HIPAA Rules if done by
                                        Covered Entity.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX is authorized to use Protected Health Information to
                                        de-identify the information in accordance with 45 CFR §
                                        164.514(a)-(c). Before proceeding with any such
                                        de-identification, MAX shall inform Covered Entity in writing
                                        of the manner in which it will de-identify the Protected Health
                                        Information and the proposed use and disclosure by the MAX of
                                        the de-identified information.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX may use or disclose Protected Health Information as
                                        Required by Law.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX agrees to make uses and disclosures and requests for
                                        Protected Health Information consistent with Covered Entity’s
                                        minimum necessary policies and procedures.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX may not use or disclose Protected Health Information in a
                                        manner that would violate Subpart E of 45 CFR § Part 164 if
                                        done by Covered Entity, except for the specific uses and
                                        disclosures set forth in this Article.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX may use Protected Health Information for the proper
                                        management and administration of MAX or to carry out the legal
                                        responsibilities of MAX.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX may disclose Protected Health Information for the proper
                                        management and administration of MAX or to carry out the legal
                                        responsibilities of MAX, provided that disclosures are Required
                                        by Law, or MAX obtains reasonable assurances in writing from
                                        the person to whom the information is disclosed that it will
                                        remain confidential and used or further disclosed only as
                                        Required by Law or for the purpose for which it was disclosed
                                        to the person, and the person notifies MAX of any instances of
                                        which it is aware in which the confidentiality of the
                                        information has been breached.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX may use Protected Health Information to provide data
                                        aggregation services relating to the health care operations of
                                        the Covered Entity.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX has no obligations under BAA with respect to any PHI that
                                        Customer creates, receives, maintains, or transmits outside of
                                        Services including Customer's use of its offline or on-premise
                                        storage tools or third-party applications and BAA will not
                                        apply to any PHI created, received, maintained or transmitted
                                        outside of Services.
                                    </p>
                                </li>
                            </ol>
                            </li>
                            
                            <li dir="ltr">
                                <p dir="ltr">
                                    Customer Obligations.
                                </p>
                                <ol type="a">
                                <li dir="ltr">
                                    <p dir="ltr">
                                        Customer may only use Services to create, receive, maintain, or
                                        transmit PHI. Customer is solely responsible for managing
                                        whether Customer's end users are authorized to share, disclose,
                                        create, and/or use PHI within the Services.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        Customer will take appropriate measures to limit use of PHI to
                                        Services and will limit its use within Services to the minimum
                                        extent necessary for Customer to carry out its authorized use
                                        of PHI.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        Customer will not request that MAX or Services use or disclose
                                        PHI in any manner that would be impermissible under HIPAA if
                                        done by Customer (if Customer is a Covered Entity) or by the
                                        Covered Entity to which Customer is a Business Associate
                                        (unless expressly permitted under HIPAA for a Business
                                        Associate).
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        For End Users that use Services in connection with PHI,
                                        Customer will use controls available within Services to ensure
                                        its PHI usage is limited to Services. Customer acknowledges and
                                        agrees that Customer is solely responsible for ensuring that
                                        its and its End Users' use of the Services complies with HIPAA
                                        and HITECH.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        Customer warrants that it has obtained and will obtain any
                                        consents, authorizations and/or other legal permissions
                                        required under HIPAA and/or other applicable law for the
                                        disclosure of PHI to MAX. Customer will notify MAX of any
                                        changes in, or revocation of, the permission by an Individual
                                        to use or disclose his or her PHI, to the extent that such
                                        changes may affect MAX's use or disclosure of PHI. Customer
                                        will not agree to any restriction on the use or disclosure of
                                        PHI under 45 CFR § 164.522 that restricts MAX's use or
                                        disclosure of PHI under the Agreement unless such restriction
                                        is required by law.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        Customer shall notify MAX of any limitation(s) in its notice of
                                        privacy practices of Covered Entity under 45 CFR § 164.520, to
                                        the extent that such limitation may affect MAX’s use or
                                        disclosure of Protected Health Information. Further, Customer
                                        shall notify MAX of a Breach regarding MAX PHI.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        Customer shall notify MAX of any changes in, or revocation of,
                                        permission by an Individual to use or disclose Protected Health
                                        Information, to the extent that such changes may affect MAX’s
                                        use or disclosure of Protected Health Information.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        Customer shall notify MAX of any restriction to the use or
                                        disclosure of Protected Health Information that Covered Entity
                                        has agreed to or is required to abide by under 45 CFR §
                                        164.522, to the extent that such restriction may affect MAX’s
                                        use or disclosure of Protected Health Information.
                                    </p>
                                </li>
                            </ol>
                            </li>
                            
                            <li dir="ltr">
                                <p dir="ltr">
                                    Appropriate Safeguards. MAX and Customer will each use appropriate
                                    safeguards designed to prevent unauthorized use or disclosure of
                                    PHI, and as otherwise required under HIPAA, with respect to the
                                    Services.
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    Reporting.
                                </p>
                                <ol type="a">
                                <li dir="ltr">
                                    <p dir="ltr">
                                        Subject to Section 6(d), MAX will promptly notify Customer
                                        following MAX's Discovery of a Security Breach in accordance
                                        with HIPAA and in the most expedient time possible under the
                                        circumstances, consistent with the legitimate needs of
                                        applicable law enforcement and applicable laws, and after
                                        taking any measures MAX deems necessary to determine the scope
                                        of the Security Breach and to restore the reasonable integrity
                                        of MAX's systems.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        To the extent practicable, MAX will use commercially reasonable
                                        efforts to mitigate any further harmful effects of a Security
                                        Breach caused by MAX.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        MAX will send any applicable Security Breach notifications to
                                        the notification email address provided by Customer in the
                                        Terms of Service or via direct communication with the Customer.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        Notwithstanding Section 6(a), this Section 6(d) will be deemed
                                        as notice to Customer that MAX periodically receives
                                        unsuccessful attempts for unauthorized access, use, disclosure,
                                        modification or destruction of information, or interference
                                        with the general operation of MAX's information systems and the
                                        Services. Customer acknowledges and agrees that even if such
                                        events constitute a Security Incident as that term is defined
                                        under HIPAA, MAX will not be required to provide any notice
                                        under this BAA regarding such unsuccessful attempts other than
                                        this Section 6(d).
                                    </p>
                                </li>
                            </ol>
                            </li>
                            
                            <li dir="ltr">
                                <p dir="ltr">
                                    Subcontractors. MAX will take appropriate measures to ensure that
                                    any Subcontractors used by MAX to perform its obligations under the
                                    Terms of Service that require access to PHI on behalf of MAX are
                                    bound by written obligations that provide the same material level
                                    of protection for PHI as this BAA. To the extent MAX uses
                                    Subcontractors in its performance of obligations hereunder, MAX
                                    will remain responsible for their performance as if performed by
                                    MAX.
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    Access and Amendment. Customer acknowledges and agrees that
                                    Customer is solely responsible for the form and content of PHI
                                    maintained by Customer within the Services, including whether
                                    Customer maintains such PHI in a Designated Record Set within the
                                    Services. MAX will provide Customer with access to Customer's PHI
                                    via the Services so that Customer may fulfill its obligations under
                                    HIPAA with respect to Individuals' rights of access and amendment,
                                    but will have no other obligations to Customer or any Individual
                                    with respect to the rights afforded to Individuals by HIPAA with
                                    respect to Designated Record Sets, including rights of access or
                                    amendment of PHI. Customer is responsible for managing its use of
                                    the Services to appropriately respond to such Individual requests.
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    Accounting of Disclosures. MAX will document disclosures of PHI by
                                    MAX and provide an accounting of such disclosures to Customer as
                                    and to the extent required of a Business Associate under HIPAA and
                                    in accordance with the requirements applicable to a Business
                                    Associate under HIPAA.
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    Access to Records. To the extent required by law, and subject to
                                    applicable attorney client privileges, MAX will make its internal
                                    practices, books, and records concerning the use and disclosure of
                                    PHI received from Customer, or created or received by MAX on behalf
                                    of Customer, available to the Secretary of the U.S. Department of
                                    Health and Human Services (the "Secretary") for the purpose of the
                                    Secretary determining compliance with this BAA.
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    Expiration and Termination.
                                </p>
                                <ol type="a">
                                <li dir="ltr">
                                    <p dir="ltr">
                                        This BAA will terminate on the earlier of (i) a permitted
                                        termination in accordance with Section 11(b) below, or (ii) the
                                        expiration or termination of all Terms of Service under which
                                        Customer has access to a Service.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        If either party materially breaches this BAA, the non-breaching
                                        party may terminate this BAA on 10 days' written notice to the
                                        breaching party unless the breach is cured within the 10-day
                                        period. If a cure under this Section 11(b) is not reasonably
                                        possible, the non-breaching party may immediately terminate
                                        this BAA, or if neither termination nor cure is reasonably
                                        possible under this Section 11(b), the non-breaching party may
                                        report the violation to the Secretary, subject to all
                                        applicable legal privileges.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        If this BAA is terminated earlier than the Terms of Service,
                                        Customer may continue to use the Services in accordance with
                                        the Terms of Service, but must delete any PHI it maintains in
                                        the Services and cease to further create, receive, maintain, or
                                        transmit such PHI to MAX.
                                    </p>
                                </li>
                            </ol>
                            </li>
                            
                            <li dir="ltr">
                                <p dir="ltr">
                                    Destruction/Return of Information. On termination of the Terms of
                                    Service, MAX will return or destroy all PHI received from Customer,
                                    or created or received by MAX on behalf of Customer; provided,
                                    however, that if such return or destruction is not feasible, MAX
                                    will extend the protections of this BAA to the PHI not returned or
                                    destroyed and limit further uses and disclosures to those purposes
                                    that make the return or destruction of the PHI infeasible. This
                                    section Destruction/Return will survive termination or expiration
                                    of this BAA.
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    Counterparts. The parties may execute BAA in counterparts,
                                    including facsimile, PDF or other electronic copies, which taken
                                    together will constitute one instrument.
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr">
                            Effects of Addendum. To the extent BAA conflicts with the remainder of the
                            Terms of Service agreement, BAA will govern. BAA is subject to the
                            "GOVERNING LAW AND JURISDICTION" section in the Terms of Service. Except as
                            expressly modified or amended under BAA, the Terms of Service remain in
                            full force and effect.
                        </p>
                        <br/>
                        <br/>

                        </div>

                        {
                                this.props.baaFromPage === "Admin Nav" ? "" : 

                                <DialogActions className="cms-links ">
                                <FormErrors
                                    show={this.state.disAgreeService}
                                    formErrors={this.state.formErrors}
                                    fieldName="disAgreeService"
                                    className="cms-error"
                                />
    
                               
                                <Button className="aggree-link btn btn1" onClick={() => this.agreeService()}>I&nbsp;Agree</Button>
                                <Button className="aggree-link btn btn2" onClick={() => this.disAgreeService()}>I&nbsp;Disagree</Button>
                                
                            </DialogActions>
                            }
                        
                 
                    </DialogContent>
                    
                </Dialog>

            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetail: PropTypes.object
};

export default withStyles(dashboardStyle)(Dashboard);