import React from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Link as MaterialLink, MenuList } from "@material-ui/core";
import { FormErrors } from "../../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import { apiPath } from "api";
import {
  generalAuthTokenHeader,
  generalMimeAuthTokenHeader,
} from "__helpers/auth-header";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  Chip,
  Input,
  Checkbox,
  ListSubheader,
  LinearProgress,
  ClickAwayListener,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import { basePath, dpBaseRoutes } from "base-routes";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FormGroup, FormControl, InputLabel } from "@material-ui/core";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ProviderSection from "views/FindProvider";
import { formatDateToLocalFormat } from "__helpers/util";
import PatientForm from "views/dpViews/PatientDashboard/PatientForm";
import Sidebar from "react-sidebar";
import { findProviderService } from "__helpers/util";
import Tooltip from "@material-ui/core/Tooltip";
import infoIconW from "assets/img/icons/informationIcon-w.svg";
import notesArrow from "assets/img/icons/notes-arrow.svg";
import { Scrollbars } from "react-custom-scrollbars";
import {
  REFERRAL_TYPE,
  MAX_INPUT_LENGTH,
  MAX_INPUT_LENGTH_LONG,
  MULTIPLE_PATIENT_LIST,
  SELECTED_PATIENT_LIST,
  PATIENT_LIST,
  USER_INFO,
  VENDOR_FACILITY,
  MAXMRJ_FACILITY,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ALLOWED_FACESHEET_EXTENTION,
} from "__helpers/constants";
import fileExtension from "file-extension";
import { getCurrentDateTime, getDateValue } from "__helpers/util";
import { PCC_AUTH_DATA } from "__helpers/constants";
import { minsBetweenTwoDate } from "__helpers/util";
import CryptoJS from "crypto-js";

const mapStateToProps = (state) => {
  return {
    insuranceListRedux: state.insuranceList,
    hospitalListRedux: state.hospitalList,
    languageAndInsuranceList: state.getPatientLanNInsList,
    categoryListRedux: state.categoryList,
  };
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
class PatientNotes extends React.Component {
  inputOpenFileRef = React.createRef(null);
  constructor(props) {
    super(props);
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    let dpFacilityCode =
      userInfo &&
      userInfo.dischargePlanner &&
      userInfo.dischargePlanner.facility &&
      userInfo.dischargePlanner.facility.facilityCode
        ? userInfo.dischargePlanner.facility.facilityCode
        : null;
    this.state = {
      languageAndInsuranceList: this.props.languageAndInsuranceList,
      hospitalList: this.props.hospitalListRedux
        ? this.props.hospitalListRedux
        : [],
      categoryList: this.props.categoryListRedux,
      patientInfo: this.props.patientInfo,
      selectCategoryId: this.props.selectCategoryId
        ? this.props.selectCategoryId
        : "",
      categoryName: "",
      selectedCategory: "",
      selectedService: [],
      selectSubService: [],
      subService: false,
      subServices: [],
      insurance:
        this.props.patientInfo && this.props.patientInfo.insuranceId
          ? "" + this.props.patientInfo.insuranceId
          : "0",
      userInsurance:
        this.props.patientInfo && this.props.patientInfo.insuranceId
          ? "" + this.props.patientInfo.insuranceId
          : "0",
      userHospital:
        this.props.patientInfo && this.props.patientInfo.previousHospitalId
          ? this.props.patientInfo.previousHospitalId
          : "",
      hospital:
        this.props.patientInfo && this.props.patientInfo.previousHospitalId
          ? [this.props.patientInfo.previousHospitalId]
          : [""],
      language: [],
      infectiousDisease: "",
      patientCriteria: [],
      patientNotesModalStatus: false,
      reduxLoadFlag: false,
      modalStatus: false,
      selectedCategoryId: "",
      isInsuranceFiltered: true,
      patientNote: "",
      patientSummary: "",
      formErrors: {
        patientSummary: "",
      },
      patientSummaryValid: false,
      notesLoading: false,
      summaryLoading: false,
      autoSaveMsg: "Summary",
      patientNoteValid: false,
      notesFormErrors: {
        patientNote: "",
      },
      fileName: "",
      fileData: "",
      notesData: [],
      summaryData: [],
      summaryId: null,
      fileDataF: null,
      confirmNotesBoxStatus: false,
      pushNotesLoading: false,
      noteText: "",
      notesType: "",
      notesSection: "",
      noteCreatedDatetime: "",
      noteIdForPush: null,
      pushNoteFormError: {
        noteText: "",
        notesType: "",
        notesSection: "",
      },
      noteTextValid: true,
      notesTypeValid: false,
      notesSectionValid: false,
      noteTime: new Date(),
      noteTimeFormat: getCurrentDateTime(),
      facilityUser: userInfo.firstName + " " + userInfo.lastName,
      dpFacilityCode: dpFacilityCode,
      facilityType: dpFacilityCode ? VENDOR_FACILITY : MAXMRJ_FACILITY,
      notesTypeList: [],
      notesSectionsList: [],
      auth3Legged: false,
    };
    this.patientNotesOpen = this.patientNotesOpen.bind(this);
    this.patientNotesClose = this.patientNotesClose.bind(this);
    this.handleChangeInputSummary = this.handleChangeInputSummary.bind(this);
    this.addNote = this.addNote.bind(this);
    this.getNotes = this.getNotes.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.getNoteType = this.getNoteType.bind(this);
    this.handlePushNoteSubmit = this.handlePushNoteSubmit.bind(this);
    this.get3LeggedVals = this.get3LeggedVals.bind(this);
  }
  componentDidMount() {
    // this.getFacilities();
    this.getNotes();
    this.getSummary();
    this.getNoteType();
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let hospitalList = [];
      if (this.props.hospitalListRedux) {
        let selectedhospitalList = this.props.hospitalListRedux;
        hospitalList = selectedhospitalList ? selectedhospitalList : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        hospitalList: hospitalList,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.patientInfo) {
      this.setState({
        patientInfo: nextProps.patientInfo,
        selectCategoryId: nextProps.selectCategoryId,
        modalStatus: nextProps.modalStatus ? nextProps.modalStatus : false,
      });
    }
  }
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let patientSummaryValid = this.state.patientSummaryValid;
    switch (fieldName) {
      case "patientSummary":
        patientSummaryValid = fieldValue.trim().length > 0;
        fieldValidationErrors.patientSummary = patientSummaryValid
          ? ""
          : enMsg.patientSummaryRequiredMsg;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        patientSummaryValid: patientSummaryValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return this.state.patientSummaryValid;
  }
  validateFieldNotes = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let notesTypeValid = this.state.notesTypeValid;
    let noteList = this.state.notesSectionsList;
    let notesSectionValid = this.state.notesSectionValid;
    switch (fieldName) {
      case "notesSection":
        notesSectionValid = fieldValue ? true : false;
        fieldValidationErrors.notesSection =
          notesSectionValid || noteList.length == 0
            ? ""
            : "Please select note section";
        break;
      case "notesType":
        notesTypeValid = fieldValue ? true : false;
        fieldValidationErrors.notesType = notesTypeValid
          ? ""
          : "Please select note type";
        fieldValidationErrors.notesSection =
          noteList.length == 0 ? "" : "Please select note section";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        notesSectionValid: notesSectionValid,
        notesTypeValid: notesTypeValid,
      },
      this.validateNotesForm
    );
  };
  validateNotesForm() {
    let noteList = this.state.notesSectionsList;
    if (noteList.length > 0) {
      return (
        this.state.noteTextValid &&
        this.state.notesSectionValid &&
        this.state.notesTypeValid
      );
    } else {
      return (
        this.state.noteTextValid &&
        // this.state.notesSectionValid &&
        this.state.notesTypeValid
      );
    }
    // return (
    //   this.state.noteTextValid &&
    //   this.state.notesSectionValid &&
    //   this.state.notesTypeValid
    // );
  }
  patientNotesClose() {
    this.setState({
      modalStatus: false,
      patientNotesModalStatus: false,
    });
  }
  patientNotesOpen() {
    let notesStatus = this.state.modalStatus ? false : true;
    let patientNotesModalStatus = notesStatus;
    this.setState({
      modalStatus: notesStatus,
      patientNotesModalStatus: patientNotesModalStatus,
    });
  }
  handleChangeInputPushNote = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const noteTextValid = value ? true : false;
    const pushNoteFormError = this.state.pushNoteFormError;
    pushNoteFormError.noteText = noteTextValid ? "" : "Please enter note text";
    this.setState(
      {
        [name]: value,
        noteTextValid: noteTextValid,
        pushNoteFormError: pushNoteFormError,
      },
      this.validateNotesForm
    );
  };
  handleChangeInputSelect = (event, noteValue) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value, noteValue);
    // return
    if (name == "notesType") {
      this.setState(
        {
          [name]: value,
          notesSectionsList: value && value.sections ? value.sections : [],
          notesSection: "",
          notesSectionValid: false,
        },
        () => {
          this.validateFieldNotes(name, value);
        }
      );
    } else {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.validateFieldNotes(name, value);
        }
      );
    }
  };
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  handleChangeInputSummary = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );

    return true;

    let data = {
      base64Data: this.state.fileName ? this.state.fileData : null,
      description: this.state.patientNote,
      fileName: this.state.fileName,
      patientId: this.state.patientInfo.patientId
        ? this.state.patientInfo.patientId
        : "",
    };
    this.setState({
      summaryLoading: true,
      autoSaveMsg: "Auto Saving",
    });
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(apiPath.sendSummary, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          this.setState({
            autoSaveMsg: "Auto Saving Failed",
          });
        } else if (response.ok) {
          this.setState({
            autoSaveMsg: "Auto Saved",
          });
        } else {
          this.setState({
            autoSaveMsg: "Auto Saving Failed",
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ summaryLoading: false });
      })
      .catch((error) => {
        this.setState({
          summaryLoading: false,
          autoSaveMsg: "Auto Saving Failed",
        });
      });
  };
  onFileBrowse = (event) => {
    this.inputOpenFileRef.current.click();
  };
  handleSelectedFile = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    if (typeof file === "undefined" && this.state.fileName == "") {
      this.setState({
        patientNoteValid: false,
      });
      return;
    }
    let fieldValidationErrors = this.state.notesFormErrors;
    let error = "";
    let patientNoteValid = false;
    if (typeof file == "object" && file.size > ALLOWED_PROFILE_IMAGE_SIZE) {
      error = enMsg.allowedFaceSheetSize + " File will not upload";
    } else if (
      typeof file == "object" &&
      !ALLOWED_FACESHEET_EXTENTION.includes(file.type)
    ) {
      error = enMsg.allowedFaceSheetType + " File will not upload";
    } else {
      patientNoteValid = true;
    }

    if (typeof file !== undefined && patientNoteValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        this.setState({
          fileName: file.name,
          fileData: result,
        });
        this.fileBlob = file;
        this.filename = new Date().getTime() + "." + fileExtension(file.name);
      });
      if (file) {
        fileReader.readAsDataURL(file);
        this.setState({
          fileDataF: file,
        });
      }
    }
    if (!patientNoteValid) {
      this.setState({
        fileName: "",
      });
    }
    fieldValidationErrors.patientNote = patientNoteValid ? "" : error;
    this.setState(
      {
        patientNoteValid: patientNoteValid,
        formErrors: fieldValidationErrors,
      },
      this.validateNotesForm
    );
    event.target.value = null;
  };
  async get3LeggedVals() {
    console.log("3logged work");
    let auth3LeggedData = await userService.fetchGlobalApisSilent(
      apiPath.get3LeggedValues
    );
    if (auth3LeggedData && auth3LeggedData.clientId) {
      // auth3LeggedData.clientId = btoa(auth3LeggedData.clientId)
      // localStorage.setItem(PCC_AUTH_DATA, JSON.stringify(auth3LeggedData));
      // if (auth3LeggedData.accessTokan){
      //     this.setState({
      //         auth3Legged: true,
      //     })
      //     // this.openPopupWindow(auth3LeggedData)
      // }else{
      //     this.openPopupWindow(auth3LeggedData)
      // }
      let auth3LeggedDataStr = JSON.parse(localStorage.getItem(PCC_AUTH_DATA));
      if (auth3LeggedDataStr && auth3LeggedDataStr.accessTokan) {
        let expireTimeVal = auth3LeggedDataStr.curTime
          ? auth3LeggedDataStr.curTime
          : null;
        let minsBetweenTwoDateObj = minsBetweenTwoDate(expireTimeVal, null);
        console.log("minsBetweenTwoDateObj", minsBetweenTwoDateObj);
        if (minsBetweenTwoDateObj >= 0 && minsBetweenTwoDateObj <= 15) {
          this.setState({
            auth3Legged: true,
          });
        } else {
          // remove time
          localStorage.removeItem(PCC_AUTH_DATA);
          this.openPopupWindow(auth3LeggedData);
        }
      } else {
        // remove time
        localStorage.removeItem(PCC_AUTH_DATA);
        this.openPopupWindow(auth3LeggedData);
      }
    } else {
      this.setState({
        auth3Legged: false,
      });
    }
  }
  async openPopupWindow(auth3LeggedData) {
    var encryptedBase64Key = "cGNjbWF4cmV5c2VjcmV0OA==";
    var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    var encryptedCipherText = auth3LeggedData.clientId; // or encryptedData;
    var decryptedData = CryptoJS.AES.decrypt(
      encryptedCipherText,
      parsedBase64Key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    let url =
      apiPath.auth3LeggedLogin +
      "client_id=" +
      decryptedText +
      "&response_type=" +
      auth3LeggedData.responseType +
      "&redirect_uri=" +
      apiPath.basePathFull +
      auth3LeggedData.redirectUrl +
      "&state=" +
      auth3LeggedData.state;
    // let url = "https://connect.pointclickcare.com/auth/login?client_id=eN2VehceGkQAe9XfDKZfl9WfAtoeYyaQ&response_type=OAuth2&redirect_uri=http://52.70.73.201:9092/services/externalpatients/api/receive-webhook&state=1101";
    var left = window.screen.width / 2 - 500 / 2;
    var top = window.screen.height / 2 - 500 / 2;
    let newwindow = window.open(
      url,
      "pcc3LeggedAuth",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        500 +
        ", height=" +
        500 +
        ", top=" +
        top +
        ", left=" +
        left
    );
    if (window.focus) {
      newwindow.focus();
    }
    return false;
  }
  async getNoteType() {
    let noteTypeArr = await userService.fetchGlobalApisSilent(
      apiPath.getNoteType
    );
    this.setState({
      notesTypeList: noteTypeArr,
    });
  }
  async getSummary() {
    this.setState({ summaryLoading: true });
    let showNotification = {};
    let modalCloseStatus = 1;
    let apiUrl =
      apiPath.patientNotes +
      "?notesType=1" +
      "&patientId=" +
      this.state.patientInfo.patientId;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
      // body: JSON.stringify(data),
      // data: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        if (data.detail) {
          showNotification = {
            title: "Summary",
            message: data.detail,
            type: "danger",
          };
        }
        if (data.length) {
          let dataObj = data[0];
          this.setState({
            summaryLoading: false,
            summaryData: data,
            summaryId: dataObj.id,
            patientSummary: dataObj.description,
          });
        } else {
          this.setState({
            summaryLoading: false,
          });
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Summary",
          message: enMsg.clientSideError,
          type: "danger",
        };
        this.setState({ summaryLoading: false });
      });
    userService.showNotification(showNotification);
  }
  async getNotes() {
    // return false;
    this.setState({ notesLoading: true });
    let showNotification = {};
    let modalCloseStatus = 1;
    let apiUrl =
      apiPath.patientNotes +
      "?notesType=0-3" +
      "&patientId=" +
      this.state.patientInfo.patientId;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
      // body: JSON.stringify(data),
      // data: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        if (data.detail) {
          showNotification = {
            title: "Notes",
            message: data.detail,
            type: "danger",
          };
        }
        console.log(data, "ccc");
        this.setState({
          notesLoading: false,
          notesData: data,
        });
      })
      .catch((error) => {
        showNotification = {
          title: "Notes",
          message: enMsg.clientSideError,
          type: "danger",
        };
        this.setState({ notesLoading: false });
      });
    userService.showNotification(showNotification);
  }
  async handlePushNoteSubmit() {
    let auth3LeggedDataStr = JSON.parse(localStorage.getItem(PCC_AUTH_DATA));
    let showNotification = {};
    if (auth3LeggedDataStr && auth3LeggedDataStr.accessTokan) {
      let expireTimeVal = auth3LeggedDataStr.curTime
        ? auth3LeggedDataStr.curTime
        : null;
      let minsBetweenTwoDateObj = minsBetweenTwoDate(expireTimeVal, null);
      console.log("minsBetweenTwoDateObj", minsBetweenTwoDateObj);
      if (minsBetweenTwoDateObj >= 0 && minsBetweenTwoDateObj <= 15) {
        // no nothing
      } else {
        // remove time
        localStorage.removeItem(PCC_AUTH_DATA);
        showNotification = {
          title: "Authorize",
          message:
            "Session expire, please login in PCC to authorize your session",
          type: "warning",
        };
        userService.showNotification(showNotification);
        this.get3LeggedVals();
        return true;
      }
    } else {
      // remove time
      localStorage.removeItem(PCC_AUTH_DATA);
      showNotification = {
        title: "Authorize",
        message: "Please login in PCC to authorize your session",
        type: "warning",
      };
      userService.showNotification(showNotification);
      this.get3LeggedVals();
      return true;
    }
    //effectiveDate: this.state.noteCreatedDatetime,
    //effectiveDate: this.state.noteTime,
    let data = {
      effectiveDate: this.state.noteCreatedDatetime,
      isEncounterNote: false,
      noteId: this.state.noteIdForPush,
      noteText: this.state.noteText,
      pName: this.state.facilityUser,
      noteType: this.state.notesType.noteType,
      sectionName: this.state.notesSection,
      accessToken: auth3LeggedDataStr.accessTokan,
    };
    this.setState({ pushNotesLoading: true });
    if (!this.validateNotesForm) {
      showNotification = {
        title: "Notes",
        message: "Please enter note text",
        type: "warning",
      };
      return true;
    }
    const response = await fetch(apiPath.pushNote, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Notes",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
          this.getNotes();
        } else {
          showNotification = {
            title: "Notes",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ pushNotesLoading: false });
        if (data.id) {
          // this.fetchComments(this.props.referralId);
          this.setState({
            noteText: "",
            noteIdForPush: null,
            noteTime: new Date(),
            noteTimeFormat: getCurrentDateTime(),
            noteTextValid: false,
            confirmNotesBoxStatus: false,
          });
          showNotification = {
            title: "Notes",
            message: "Notes has been pushed.",
            type: "success",
          };
        }
        if (data.title) {
          showNotification = {
            title: "Notes",
            message: data.title,
            type: "danger",
          };
          if (data.title == "Invalid Access Token") {
            this.get3LeggedVals();
          }
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Notes",
          message: enMsg.clientSideError,
          type: "danger",
        };
        this.setState({ notesLoading: false });
      });
    userService.showNotification(showNotification);
  }
  async addNote() {
    let notesLoading = this.state.notesLoading ? false : true;
    if (this.state.patientNoteValid || this.state.patientNote) {
      let patientNoteValid = true;
      let fieldValidationErrors = this.state.notesFormErrors;
      fieldValidationErrors.patientNote = patientNoteValid
        ? ""
        : "Please enter note or attach valid file.";
      this.setState(
        {
          patientNoteValid: patientNoteValid,
          formErrors: fieldValidationErrors,
        },
        this.validateNotesForm
      );
      // return true;

      let dataLock = {
        uploadFileUrl: this.state.fileName ? this.state.fileData : "",
        description: this.state.patientNote,
        uploadFileName: this.state.fileName,
        patientId: this.state.patientInfo.patientId
          ? this.state.patientInfo.patientId
          : "",
        // categoryId: this.state.selectCategoryId,
        notesType: 0,
      };
      const data = new FormData();
      if (this.state.fileDataF) {
        data.append("uploadFileUrl", this.state.fileDataF);
        data.append("uploadFileName", this.state.fileName);
      }
      data.append("description", this.state.patientNote);
      data.append(
        "patientId",
        this.state.patientInfo.patientId ? this.state.patientInfo.patientId : ""
      );
      data.append("notesType", 0);
      this.setState({ notesLoading: true });
      let showNotification = {};
      let modalCloseStatus = 1;
      const response = await fetch(apiPath.patientNotes, {
        method: "POST",
        headers: generalMimeAuthTokenHeader(),
        body: data,
        data: data,
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Notes",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
          } else if (response.ok) {
            this.getNotes();
          } else {
            showNotification = {
              title: "Notes",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
          }
          return response.json();
        })
        .then((data) => {
          this.setState({ notesLoading: false });
          if (data.id) {
            // this.fetchComments(this.props.referralId);
            this.setState({
              patientNote: "",
              fileName: "",
              fileData: "",
              fileDataF: null,
              patientNoteValid: false,
            });
            showNotification = {
              title: "Notes",
              message: "Notes has been sent.",
              type: "success",
            };
          }
          if (data.detail) {
            showNotification = {
              title: data.title,
              message: data.detail,
              type: "danger",
            };
          }
        })
        .catch((error) => {
          showNotification = {
            title: "Notes",
            message: enMsg.clientSideError,
            type: "danger",
          };
          this.setState({ notesLoading: false });
        });
      userService.showNotification(showNotification);
    } else {
      let patientNoteValid = false;
      let fieldValidationErrors = this.state.notesFormErrors;
      fieldValidationErrors.patientNote = patientNoteValid
        ? ""
        : "Please enter note or attach valid file.";
      this.setState(
        {
          patientNoteValid: patientNoteValid,
          formErrors: fieldValidationErrors,
        },
        this.validateNotesForm
      );
    }
  }
  async sendSummary() {
    let dataLock = {
      uploadFileUrl: "",
      description: this.state.patientSummary,
      uploadFileName: "",
      patientId: this.state.patientInfo.patientId
        ? this.state.patientInfo.patientId
        : "",
      // categoryId: this.state.selectCategoryId,
      notesType: 1,
    };
    const data = new FormData();
    if (this.state.fileDataF) {
      data.append("uploadFileUrl", "");
      data.append("uploadFileName", "");
    }
    data.append("description", this.state.patientSummary);
    data.append(
      "patientId",
      this.state.patientInfo.patientId ? this.state.patientInfo.patientId : ""
    );
    data.append("notesType", 1);
    let methodType = "POST";
    if (this.state.summaryId) {
      methodType = "PUT";
      // data.id = this.state.summaryId;
      data.append("id", this.state.summaryId);
    }
    this.setState({
      summaryLoading: true,
      autoSaveMsg: "Saving",
    });
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(apiPath.patientNotes, {
      method: methodType,
      headers: generalMimeAuthTokenHeader(),
      body: data,
      data: data,
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Summary",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
          this.setState({
            autoSaveMsg: "Not Saved",
          });
        } else if (response.ok) {
          this.setState({
            autoSaveMsg: "Saved",
          });
        } else {
          showNotification = {
            title: "Summary",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
          this.setState({
            autoSaveMsg: "Not Saved",
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ summaryLoading: false });
        if (data.id) {
          // this.fetchComments(this.props.referralId);
          showNotification = {
            title: "Summary",
            message: "Summary has been saved.",
            type: "success",
          };
        } else if (data.detail) {
          showNotification = {
            title: data.title,
            message: data.detail,
            type: "danger",
          };
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Summary",
          message: enMsg.clientSideError,
          type: "danger",
        };
        this.setState({ summaryLoading: false });
      });
    userService.showNotification(showNotification);
  }
  async sendMail() {
    let summaryLoading = this.state.summaryLoading ? false : true;
    this.setState({
      summaryLoading: summaryLoading,
    });

    let data = {
      base64Data: this.state.fileName ? this.state.fileData : null,
      description: this.state.patientNote,
      fileName: this.state.fileName,
      patientId: this.state.patientInfo.patientId
        ? this.state.patientInfo.patientId
        : "",
    };
    this.setState({ summaryLoading: true });
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(apiPath.sendSummaryNotes, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Summary Mail",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Summary Mail",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ summaryLoading: false });
        if (data.id) {
          this.setState({
            patientNote: "",
            fileName: "",
            fileData: "",
            patientNoteValid: false,
          });
          showNotification = {
            title: "Summary Mail",
            message: "Summary mail has been sent.",
            type: "success",
          };
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Summary Mail",
          message: enMsg.clientSideError,
          type: "danger",
        };
        this.setState({ summaryLoading: false });
      });
    userService.showNotification(showNotification);
  }
  downloadFile = async (fileName, fileUrl) => {
    let showNotification = {};
    try {
      this.setState({
        notesLoading: true,
      });
      let showNotification = {
        title: "Facesheet",
        message: "Please wait, Downloading files",
        type: "info",
      };
      userService.showNotification(showNotification);
      let urlArr = fileUrl.split("/");
      let urlArrEndPoint =
        urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1];
      let sheetData = await userService.fetchCommentSheetsData(
        apiPath.downloadCommentFile + "?key=" + urlArrEndPoint,
        fileName
      );
    } catch (error) {
      showNotification = {
        title: "Facesheet",
        message: "No facesheet available.",
        type: "danger",
      };
      userService.showNotification(showNotification);
      this.setState({
        notesLoading: false,
      });
    }
    this.setState({
      notesLoading: false,
    });
    return false;
  };
  handleNotesConfirmBox = () => {
    this.setState({
      confirmNotesBoxStatus: false,
    });
  };
  openNotesConfirmBox = (noteData) => {
    this.setState({
      confirmNotesBoxStatus: true,
      noteIdForPush: noteData.id,
      noteText: noteData.description,
      noteCreatedDatetime: noteData.createdDatetime
        ? noteData.createdDatetime + ".000Z"
        : "",
      noteTextValid: noteData.description ? true : false,
    });
    this.get3LeggedVals();
  };
  // inner HTML Example...
  createMarkup() {
    return {
      __html: `<TextField
                                            rowsMax={4}
                                            multiline=${true}
                                            fullWidth=${true}
                                            name='noteText'
                                            label="Note"
                                            placeholder="Enter note"
                                            data-validators="isRequired"
                                            value=${this.state.noteText}
                                            onChange=${
                                              this.handleChangeInputPushNote
                                            }
                                            inputProps=${{
                                              maxLength: MAX_INPUT_LENGTH_LONG,
                                            }}
                                            className='comment-TextField'
                                        />`,
    };
  }
  render() {
    const {
      insurance,
      facilityType,
      patientInfo,
      notesTypeList,
      notesSectionsList,
      notesSection,
      notesType,
    } = this.state;
    const { classes } = this.props;
    return (
      <div className="pos-rel NotesDiv send-emr-note-modal">
        <div
          className={`notes-div ${this.state.patientNotesModalStatus &&
            `change-right-position-notes`}`}
          onClick={this.patientNotesOpen}
        >
          <img src={notesArrow} /> Notes
        </div>
        <Dialog
          open={this.state.confirmNotesBoxStatus}
          maxWidth={"xs"}
          onClose={this.handleNotesConfirmBox}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal setZ send-emr-note-modal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Send to EMRss
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleNotesConfirmBox}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {this.state.pushNotesLoading ? (
              <LinearProgress color="primary" />
            ) : (
              ""
            )}
            <Box
              width={1}
              display="flex"
              justifyContent="flex-start"
              mb={1}
              mt={1}
              pr={2}
            >
              <div className="send-emr-note">
                <div className="form-control">
                  <FormControl>
                    <TextField
                      rowsMax={4}
                      multiline={true}
                      fullWidth={true}
                      name="noteText"
                      InputLabelProps={{ className: "required-label" }}
                      label="Note"
                      placeholder="Enter note"
                      data-validators="isRequired"
                      value={this.state.noteText}
                      onChange={this.handleChangeInputPushNote}
                      inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                      className="comment-TextField"
                    />
                    {/* <div dangerouslySetInnerHTML={this.createMarkup()} /> */}
                  </FormControl>
                  <FormErrors
                    show={!this.state.noteTextValid}
                    formErrors={this.state.pushNoteFormError}
                    fieldName="noteText"
                  />
                </div>
                <div className="form-control">
                  <FormControl>
                    <InputLabel className={"required-label"}>
                      Notes type
                    </InputLabel>
                    <Select
                      id="notesType"
                      color="secondary"
                      name="notesType"
                      value={this.state.notesType}
                      onChange={(event) =>
                        this.handleChangeInputSelect(event, "notesType")
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                            xIndex: 999999,
                          },
                        },
                        variant: "menu",
                        getContentAnchorEl: null,
                        style: { zIndex: 999999 },
                      }}
                    >
                      <MenuItem value="">Select Note Type</MenuItem>
                      {notesTypeList && notesTypeList.length ? (
                        notesTypeList.map((noteObj, key) => {
                          return (
                            <MenuItem key={key} value={noteObj}>
                              {noteObj.noteType}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value="">Note types not available</MenuItem>
                      )}
                    </Select>
                    <FormErrors
                      show={!this.state.notesTypeValid}
                      formErrors={this.state.formErrors}
                      fieldName="notesType"
                    />
                  </FormControl>
                </div>
                {this.state.notesType ? (
                  <div className="form-control">
                    <FormControl>
                      {notesSectionsList.length > 0 ? (
                        <InputLabel className={"required-label"}>
                          Notes section
                        </InputLabel>
                      ) : (
                        <InputLabel>Notes section</InputLabel>
                      )}

                      <Select
                        id="notesSection"
                        color="secondary"
                        name="notesSection"
                        value={this.state.notesSection}
                        onChange={(event) =>
                          this.handleChangeInputSelect(event, "notesSection")
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 4.5 + 8,
                              width: 250,
                            },
                          },
                          variant: "menu",
                          getContentAnchorEl: null,
                          style: { zIndex: 999999 },
                        }}
                      >
                        <MenuItem value="">Select Note Section</MenuItem>
                        {notesSectionsList && notesSectionsList.length ? (
                          notesSectionsList.map((noteObj, key) => {
                            return (
                              <MenuItem key={key} value={noteObj}>
                                {noteObj}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem value="">
                            Note sections not available
                          </MenuItem>
                        )}
                      </Select>
                      <FormErrors
                        show={!this.state.notesSectionValid}
                        formErrors={this.state.formErrors}
                        fieldName="notesSection"
                      />
                    </FormControl>
                  </div>
                ) : null}
                <div className="form-control">
                  <label>User</label>
                  <h5>{this.state.facilityUser}</h5>
                </div>
                <div className="form-control">
                  <label>Date</label>
                  <h5>{this.state.noteTimeFormat}</h5>
                </div>
              </div>
            </Box>
          </DialogContent>
          <DialogActions className="modal-actions" justify="center">
            <Button
              type="button"
              className="btn1"
              disabled={
                !this.validateNotesForm() || this.state.pushNotesLoading
              }
              onClick={this.handlePushNoteSubmit}
            >
              Send To EMR
            </Button>
            <Button
              onClick={this.handleNotesConfirmBox}
              className="cancel-link"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.modalStatus}
          maxWidth={"sm"}
          onClose={this.patientNotesClose}
          aria-labelledby="provider-dialog-title"
          className="add-modal front-modal  NotesModal"
        >
          <DialogContent>
            <Box
              className="list-item-content-data finding-box "
              width={1}
              display="flex"
              flexDirection="row"
            >
              <Box width={515} pr={1} className="pointer comments-section">
                <div className="notes-title mb-24">
                  Notes{" "}
                  <i
                    className="fa fa-refresh"
                    onClick={() => this.getNotes()}
                  ></i>
                </div>
                {this.state.notesLoading ? (
                  <LinearProgress color="primary" />
                ) : (
                  ""
                )}
                <Scrollbars
                  autoHide={false}
                  universal={true}
                  autoHeight={true}
                  autoHeightMin={0}
                  autoHeightMax={240}
                  className="notesTableScroll"
                >
                  <table>
                    <tbody>
                      {this.state.notesData && this.state.notesData.length ? (
                        this.state.notesData.map((noteData, key) => {
                          return (
                            <tr key={key}>
                              {patientInfo.thirdPartyPatientId ? (
                                <td>
                                  {noteData.progressNoteId ? (
                                    <span className="font-11 note-pushed-text">
                                      Note Pushed{" "}
                                      {getDateValue(
                                        noteData.progressNoteAddedDate,
                                        "m"
                                      )}
                                      /
                                      {getDateValue(
                                        noteData.progressNoteAddedDate,
                                        "d"
                                      )}
                                    </span>
                                  ) : (
                                    <Button
                                      className="btn1 font-11"
                                      onClick={() =>
                                        this.openNotesConfirmBox(noteData)
                                      }
                                    >
                                      Send to EMR
                                    </Button>
                                  )}
                                </td>
                              ) : null}
                              <td>
                                {noteData.notesType == 3
                                  ? "System"
                                  : "Discharge Planner"}
                              </td>
                              {/* <td>
                                                                    <div style={{ whiteSpace: "pre-line" }} className="commentdescription" ng-bind-html="demo.body|lineBreak" dangerouslySetInnerHTML={{ __html: noteData.description ? noteData.description : "N/A" }} />
                                                                </td> */}
                              <td>
                                <div
                                  style={{ whiteSpace: "pre-line" }}
                                  className="commentdescription"
                                  ng-bind-html="demo.body|lineBreak"
                                >
                                  {noteData.description
                                    ? noteData.description
                                    : "N/A"}
                                </div>
                              </td>
                              <td>
                                {noteData.uploadFileName ? (
                                  <i
                                    className="fa fa-download pointer"
                                    aria-hidden="true"
                                    onClick={() =>
                                      this.downloadFile(
                                        noteData.uploadFileName,
                                        noteData.uploadFileUrl
                                      )
                                    }
                                  ></i>
                                ) : null}
                              </td>
                              <td>
                                Posted at:
                                <br />
                                {noteData.formatedCreatedDatetime}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="no-td">No Notes!</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Scrollbars>
                <Box display="flex" flexDirection="row" mt={2}>
                  <Box pr={1} width={371} className="noteinput">
                    <FormControl>
                      <TextField
                        rows={2}
                        multiline={true}
                        fullWidth={true}
                        name="patientNote"
                        placeholder="Enter note"
                        value={this.state.patientNote}
                        onChange={this.handleChangeInput}
                        inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                        className="comment-TextField"
                      />
                    </FormControl>
                    <FormErrors
                      show={!this.state.patientNoteValid}
                      formErrors={this.state.formErrors}
                      fieldName="patientNote"
                    />
                    <p className="wb w-100">{this.state.fileName}</p>
                  </Box>
                  <Box pl={1} display="flex" flexDirection="column">
                    <div className="image-ref-img">
                      <input
                        type="file"
                        id="file"
                        ref={this.inputOpenFileRef}
                        onChange={(event) => this.handleSelectedFile(event)}
                        accept="image/x-png, image/jpg, image/jpeg, application/pdf"
                        style={{ display: "none" }}
                      />
                    </div>
                    <Button
                      type="button"
                      className="btn1 mb-10"
                      onClick={(event) => this.onFileBrowse(event)}
                    >
                      <i className="fa fa-upload" aria-hidden="true"></i>
                      &nbsp;Upload&nbsp;File
                    </Button>
                    <Button className="btn1" onClick={() => this.addNote()}>
                      Add Note
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box width={318} pl={1} className="ExecutiveSummary">
                <div className="notes-title">Executive Summary</div>
                {this.state.summaryLoading ? (
                  <LinearProgress color="primary" />
                ) : (
                  ""
                )}
                <div className="auto-saved">{this.state.autoSaveMsg}</div>
                <Box>
                  <Box className="pointer">
                    <FormControl>
                      <TextField
                        rows={4}
                        multiline={true}
                        fullWidth={true}
                        name="patientSummary"
                        placeholder="Enter summary"
                        value={this.state.patientSummary}
                        onChange={this.handleChangeInputSummary}
                        className="comment-TextField"
                      />
                      <FormErrors
                        show={!this.state.patientSummaryValid}
                        formErrors={this.state.formErrors}
                        fieldName="patientSummary"
                      />
                    </FormControl>
                  </Box>
                  <Box
                    className="pointer"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      className="btn1 mr-10"
                      onClick={() => this.sendSummary()}
                      disabled={
                        !this.validateForm() || this.state.summaryLoading
                      }
                    >
                      {this.state.summaryLoading && (
                        <CircularProgress
                          size={24}
                          className="buttonProgress"
                          color="secondary"
                        />
                      )}
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export const PatientNotesClass = connect(mapStateToProps)(PatientNotes);
export default PatientNotesClass;
