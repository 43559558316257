import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import InfiniteScroll from "react-infinite-scroller";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import FindProviderTabPanel from "./FindProviderTabPanel";
import AwaitingProviderTabPanel from "./AwaitingProviderTabPanel";
import FinalizedProviderTabPanel from "./FinalizedProviderTabPanel";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
} from "@material-ui/core";
import {
  PATIENT_LIST,
  MULTIPLE_PATIENT_LIST_CATEGORIES,
} from "__helpers/constants";

function MultipleFindProvider(props) {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [size] = useState(6);
  const [fetchMore, setFetchMore] = useState(true);
  const [defaultTabIndex, setDefaultTabIndex] = useState(
    props.selectCategoryIndex
  );
  const [tabIndex, setTabIndex] = useState(props.selectCategoryIndex);
  const [tabtatus, setTabtatus] = useState(props.selectCategoryStatus);
  const [patientInfo, setPatientInfo] = useState(props.patientInfo);
  const [loader, setLoader] = useState(false);
  const [providerDetailModal, setProvider] = useState(
    props.providerDetailModal
  );
  const patientCategoryList = props.patientCommonCategoryList;
  return (
    <div className="find-provider-tabs  ">
      {typeof props.categoryList === "object" && props.categoryList.length ? (
        <Tabs defaultIndex={props.selectCategoryIndex}>
          <TabList className="provider-tab">
            {props.categoryList
              .sort((a, b) => (a.catagoryId > b.catagoryId ? 1 : -1))
              .map((category, key) => {
                if (
                  category.parent == 0 &&
                  patientCategoryList.includes(category.catagoryId)
                ) {
                  return (
                    <Tab key={key} variant="scrollable" scrollbuttons="auto">
                      <Box
                        className="category-value-status"
                        display="flex"
                        flexDirection="column"
                      >
                        <div className="categoryValue">
                          {category.categoryValue}
                        </div>
                        <div className={`categoryStatus Finding`}>
                          Find Provider
                        </div>
                      </Box>
                    </Tab>
                  );
                }
              })}
          </TabList>
          {props.categoryList
            .sort((a, b) => (a.catagoryId > b.catagoryId ? 1 : -1))
            .map((category, key) => {
              if (
                category.parent == 0 &&
                patientCategoryList.includes(category.catagoryId)
              ) {
                return (
                  <TabPanel key={key}>
                    <FindProviderTabPanel
                      categoryId={category.catagoryId}
                      selectedProviderDataCallback={
                        props.selectedProviderDataCallback
                      }
                      loaderStatus={props.loaderStatus}
                      updatePatient={props.updatePatient}
                      setLoader={setLoader}
                      patientInfo={props.patientInfo}
                      multiPatientMode={true}
                    />
                  </TabPanel>
                );
              }
            })}
        </Tabs>
      ) : props.loading ? (
        "Loading tabs"
      ) : (
        "No Categories Selected, Please Add a New Care Category"
      )}
    </div>
  );
}
export default MultipleFindProvider;
