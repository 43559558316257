import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    Select,
    DialogTitle,
    IconButton,
    TextField,
    MenuItem,
    InputLabel,
    Button
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
const modal = (props) => {
    const  { title, message, confirmModalStatus, confirmModalClose, submit, loading} = props;
    return (
        <Dialog
            className="add-modal"
            maxWidth={'sm'}
            open={confirmModalStatus}
        >
            <DialogTitle className="add-modal-title" id="discharge-planner-dialog-title">
                {title}
                <IconButton aria-label="close">
                    <CloseIcon onClick={confirmModalClose}/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions className="modal-actions" justify="center" >
                <Button type="button" className="btn1" onClick={submit} disabled={loading}>
                    {loading && (
                        <CircularProgress
                            size={24}
                            className="buttonProgress"
                        />
                    )}
                    Yes
                </Button>
                <Button color="primary" onClick={confirmModalClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default modal
