import dummy from "assets/img/default-profile.gif";
import dummyPng from "assets/img/dummy.png";
import loader from "assets/img/Infinity-loader.svg";

export const JWT_SECRET_KEY = "json-server-auth-123456";
export const JWT_EXPIRES_IN = "1h";
export const SALT_LENGTH = 10;
export const GOOGLE_API_KEY = "AIzaSyDBL40kndpI2Yr8mnz3PTXYqPd5qQPWP9Y";
// export const EMAIL_REGEX = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
export const EMAIL_REGEX = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const ONLY_AlPHABETS_REGEX = new RegExp(/^[a-z]+$/i);
export const MIN_PASSWORD_LENGTH = 10;
export const MAX_PASSWORD_LENGTH = 15;
export const MAX_INPUT_LENGTH = 24;
export const MAX_INPUT_LENGTH_TEXT_BOX = 100;
export const MAX_INPUT_LENGTH_LONG = 300;
export const MAX_INPUT_LENGTH_LONG_TEXT = 1000;
export const MAX_INPUT_LENGTH_RATE = 8;
export const PASSWORD_PATTERN = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{${MIN_PASSWORD_LENGTH},})`
);
export const URL_PATTERN_LOCK = new RegExp(
  /^(http:\/\/|https:\/\/|www\.)(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(?::\d{1,5})?(?:$|[?\/#])/i
);
export const URL_PATTERN = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
);

export const PHONE_REGEX = new RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);
export const ONLY_NUMBER_REGEX = /^[0-9\b]+$/;
export const OK_SUCCESS_STATUS = 200;
export const STATUS_CREATED = 201;
export const NO_CONTENT_STATUS = 204;
export const INVALID_DATA_POST = 400;
export const SECURITY_ERROR = 401;
export const FORBIDDEN_STATUS = 403;
export const NO_DATA_FOUND = 404;
export const SERVER_ERROR = 500;
export const DIGIT_ONLY = new RegExp(`^[0-9]+$`);
export const IMAGE_MAX_HEIGHT_WIDTH_LENTH = 4;
export const OPT_LENGTH = 6;
export const ZIP_CODE_LENGTH = 5;
export const PHONE_NO_INVALID_LENGTH = 7;
export const ZIPCODE_REGEX = new RegExp(/^\d{5}([\-]?\d{4})?$/i);
export const ALLOWED_PROFILE_IMAGE_SIZE = 1024 * 1000 * 5; // 5MB
export const ALLOWED_FACESHEET_SIZE = 1024 * 1000 * 5; // 5MB
export const MIN_AGE_YEARS = 18; // 18 Year minimum for DOB
export const DEFAULT_PROFILE_IMG = dummy;
export const DEFAULT_PROFILE_IMG_PNG = dummyPng;
export const NO_USERNAME = "Anonymous_MaxMRJ";
export const FACILITY_NAME = "Facility_MaxMRJ";

export const SHOW_LOADER = loader;
// export const CLIENT_TOKEN_LIFETIME = 900; // cleint auth token life time in seconds : 900 i.e. 15 Mins
export const CLIENT_TOKEN_LIFETIME = 15 * 60 * 1000; // cleint auth token life time in miliseconds : 900000 i.e. 15 Mins
export const AUTO_LOGOUT_TIME = 1000 * 60 * 5; //1000 * 60 * 5 cleint auth token life time in miliseconds : 900000 i.e. 15 Mins
export const APPLICATION_ROLES = ["merchant", "user"];
export const RECORD_PER_PAGE = 10;
export const CREDIT_CARD_MAX_YEAR = 10;
export const MAX_CREDIT_CARD_LENGTH = 18;
export const STORED_ADNETWORK = "_adNetworks";
export const SELECTED_ADNETWORK = "_selected_adnetwork";

export const DATA_LOADING = "_data_loading";
export const REDUX_STATE_DATA = "_redux_state_data";

export const PCC_AUTH_DATA = "_pcc_auth_data";

// globals apis redux constant
export const FETCH_CATEGORY_LIST = "_fetch_category_list";
export const FETCH_INSURANCE_LIST = "_fetch_insurance_list";
export const FETCH_PATIENT_STATUS_LIST = "_fetch_patient_status_list";
export const FETCH_HOSPITAL_LIST = "_fetch_hospital_list";

export const USER_INFO = "_user_info";
export const PATIENT_LIST = "_patient_list";
export const MULTIPLE_PATIENT_LIST = "_multiple_patient_list";
export const MULTIPLE_PATIENT_LIST_CATEGORIES =
  "_multiple_patient_list_categories";
export const SELECTED_PATIENT_LIST = "_selected_patient_list";
export const DP_DASHBOARD_FILTERS = "_dp_dashboard_filters";

export const PER_PAGE_OPTIONS = [10, 20, 50, 100];
export const DEFAULT_PER_PAGE_RECORDS = 10;
/* slugs use in routing */
export const ADNETWORK_ID_SLUG = ":adnetworkID";
export const CAMPAIGN_ID_SLUG = ":campaignID";
export const ADVERTISEMENT_ID_SLUG = ":advertisementID";
export const VENDOR_ID_SLUG = ":vendorID";
export const ASSET_ID_SLUG = ":assetID";
export const PATIENT_SORT = {
  asc: "ASC",
  desc: "DESC",
};
export const PATIENT_ORDERBY = {
  1: "Estimated Discharge Date",
  2: "Date Created",
  3: "Insurance",
  4: "Encounter State",
  5: "Last Name (ascending)",
  6: "Last name (descending)",
  7: "Transport Date",
  9: "Task Date",
};
export const PATIENT_FILTER = {
  firstName: "Name",
  createdDate: "Date Created",
  // patientId: "Id"
};
export const IMPORT_PATIENT_ORDERBY = {
  LAST_NAME_ASC: "Last name(asc)",
  LAST_NAME_DESC: "Last name(desc)",
  FIRST_NAME_ASC: "First name(asc)",
  FIRST_NAME_DESC: "First name(desc)",
  DISCHARGE_DATE_ASC: "Discharge date(asc)",
  DISCHARGE_DATE_DESC: "Discharge date(desc)",
};
export const IMPORT_PATIENT_FILTERBY = {
  CURRENT: "Current",
  DISCHARGED: "Discharged",
  NEW: "New",
  DECEASED: "Deceased",
};

export const TRASPORT_NEEDS = {
  WHEELCHAIR: "Wheelchair",
  GURNEY: "Gurney",
  WALKER: "Walker",
  NONE: "None",
};

export const TRASPORT_PAYOR = {
  Patient: "Patient",
  FACILITY: "Facility",
  INSURANCE: "Insurance",
  OTHER: "Other",
};

// export const CLIENT_ID = "4731b9f8-df33-4d7d-80db-42cb5f7485b1";
export const CLIENT_ID = window._env_.CLIENT_ID;
// export const CLIENT_SECRET = "password";
export const CLIENT_SECRET = window._env_.CLIENT_SECRET;
export const GRAND_TYPE = "client_credentials";
/* use to show drop down & change status at list of campaign */

export const assetsTypesValues = {
  image: "Image",
  text: "Text",
  video: "Video",
  audio: "Audio",
};

export const genderType = {
  MALE: "Male",
  FEMALE: "Female",
};
export const REFERRAL_TYPE = {
  0: "Rapid Referral",
  1: "Prelim Referral",
};
export const REFERRAL_TYPES = {
  0: "RAPID REFERRAL",
  1: "PRELIMINARY REFERRAL",
};
export const REFERRAL_STATUS = [
  {
    name: "Find Provider",
    id: 1,
  },
  {
    name: "Awaiting",
    id: 2,
  },
  {
    name: "Finalized",
    id: 3,
  },
  {
    name: "Pending",
    id: 4,
  },
  {
    name: "Decline",
    id: 5,
  },
  {
    name: "Acknowledged",
    id: 6,
  },
  {
    name: "Drop",
    id: 7,
  },
];
export const PRIORITY_LIST = [
  {
    name: "Low",
    id: 3,
  },
  {
    name: "Medium",
    id: 2,
  },
  {
    name: "High",
    id: 1,
  },
];
export const PRIORITY_LIST_OBJ = {
  3: "Low",
  2: "Medium",
  1: "High",
};
export const TASK_STATUS_OBJ = {
  4: "Incomplete",
  5: "Issue",
  6: "Complete",
};
// 4: "clr3",
// 5: "clr1",
// 6: "clr2"
export const TASK_STATUS = [
  {
    name: "Incomplete",
    id: 4,
  },
  {
    name: "Issue",
    id: 5,
  },
  {
    name: "Complete",
    id: 6,
  },
];

export const ALLOWED_IMAGE_EXTENTION = ["image/jpeg", "image/png", "image/jpg"];
export const ALLOWED_FACESHEET_EXTENTION = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/pdf",
];
export const NotificationOptions = {
  insert: "bottom",
  container: "top-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
  showIcon: true,
  dismiss: {
    duration: 5000,
    onScreen: true,
    // pauseOnHover: true
  },
};

/* Response Status errorCode*/
export const SECURITY_ERROR_CODE = 1208;

// user roles
export const ADMIN_USER = "ROLE_ADMIN";
export const DP_USER = "ROLE_DISCHANRGE_PLANNER";
export const PROVIDER_USER = "ROLE_USER_PROVIDER";
export const PROVIDER = "ROLE_PROVIDER";

export const MAXMRJ_FACILITY = "_maxmrj_facility";
export const VENDOR_FACILITY = "_vendor_facility";
