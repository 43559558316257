import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import axios from "axios";
import moment from "moment";
import CardBody from "components/Card/CardBody.jsx";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Button,
  TablePagination,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  DialogTitle,
  IconButton,
  TextField,
  MenuItem,
  InputLabel,
  Slide,
  Box,
  FormControl,
  Checkbox,
  Chip,
} from "@material-ui/core";

import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import Icon from "@material-ui/core/Icon";
import {
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
  EMAIL_REGEX,
  ONLY_AlPHABETS_REGEX,
} from "__helpers/constants";
import CloseIcon from "@material-ui/icons/Close";

import { FormErrors } from "../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import ConfirmModal from "components/Modal/modal";

import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";

import { connect } from "react-redux";
import { reduxLoad } from "js/actions/index";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import baseRoutes from "base-routes";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function mapDispatchToProps(dispatch) {
  return {
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
    providerStatusList: state.providerStatusList,
  };
};
const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Id",
  },
  {
    id: "providerName",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Provider Name",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Primary Contact",
  },

  {
    id: "subscriptionAmount",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Amount",
  },
  {
    id: "subscriptionType",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Type",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Start Date",
  },
  {
    id: "totalDataUsagesKb",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Total Usages",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Action",
  },
];
class InvoiceClass extends Component {
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    this.dateUtility = new DateFnsUtils();
    this.state = {
      loading: false,
      providerInvoices: [],
      spinner: spinner,
      perPage: DEFAULT_PER_PAGE_RECORDS,
      total: "",
      currentPage: 0,
      orderBy: "id",
    };
    this.fetchProviderInvoices = this.fetchProviderInvoices.bind(this);
    //this.totalDataUsagesKb = this.totalDataUsagesKb.bind(this);
  }
  componentDidMount() {
    this.fetchProviderInvoices();
  }
  handleChangePage = (event, newPage) => {
    this.setState(
      {
        currentPage: newPage,
      },
      () => this.fetchProviderInvoices()
    );
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        perPage: parseInt(event.target.value),
        currentPage: 0,
      },
      () => this.fetchProviderInvoices(this.state.searchKeyword)
    );
  };
  tableSortHandler = (property) => (event) => {
    this.setState(
      {
        orderBy: property,
        direction: !this.state.direction,
      },
      () => this.fetchProviderInvoices(this.state.searchKeyword)
    );
  };
  modalOpen = (dataId) => {
    if (!isNaN(dataId)) {
      Promise.all(
        this.state.providerInvoices.map((invoice) => {
          if (invoice.id == dataId) {
            console.log(JSON.parse(invoice.dataUsage));
            this.setState({
              // facility: provider.facility.facilityId,
              providerName: invoice.providerName,
              emailId: invoice.emailId,
              name: invoice.firstName + " " + invoice.lastName,
              startDate: moment(invoice.startDate)
                .utc()
                .format("MM/DD/YYYY"),
              endDate: moment(invoice.endDate)
                .utc()
                .format("MM/DD/YYYY"),
              status: invoice.status,
              subscriptionAmount: invoice.subscriptionAmount,
              subscriptionType: invoice.subscriptionType.replace(/_/g, " "),
              description: invoice.description,
              receiptUrl: invoice.receiptUrl,
              payAsYouList:
                invoice.dataUsage && invoice.dataUsage ? invoice.dataUsage : [],
            });
          }
        })
      );
    }

    this.setState({
      modalStatus: true,
    });
  };

  modalClose = () => {
    this.setState({
      modalStatus: false,
    });
  };
  async fetchProviderInvoices() {
    this.state.spinner.removeAttribute("hidden", "true");
    let showNotification = {};
    let apiUrl =
      apiPath.getProviderInvoices +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "";

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider Invoice",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          this.setState({
            total: response.headers.get("X-Total-Count"),
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Provider Invoice",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          providerInvoices: data,
        });
      })
      .catch((error) => {
        showNotification = {
          title: "Provider Invoice",
          message: "Bad response from server",
          type: "danger",
        };
        return response;
      });

    userService.showNotification(showNotification);
    this.state.spinner.setAttribute("hidden", "true");
  }
  formatFileSize = (fileSize) => {
    if (fileSize < 1024) {
      return fileSize + " KB";
    } else if (fileSize >= 1024 && fileSize < 1024 * 1024) {
      return (fileSize / 1024).toFixed(2) + " MB";
    } else {
      return (fileSize / (1024 * 1024)).toFixed(2) + " GB";
    }
  };
  render() {
    const { startDate, endDate } = this.state;

    return (
      <div id="provider-index">
        <Dialog
          open={this.state.modalStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Invoice Details
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {this.state.name ? (
              <>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">
                      Provider Name
                    </InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.providerName}
                    </InputLabel>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={1}>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">
                      Primary Contact
                    </InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.name}
                    </InputLabel>
                  </Box>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">Email</InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.emailId}
                    </InputLabel>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">Start Date</InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.startDate}
                    </InputLabel>
                  </Box>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">End Date</InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.endDate}
                    </InputLabel>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">
                      Subscription Amount
                    </InputLabel>
                    <InputLabel className="view-label value">
                      ${this.state.subscriptionAmount}
                    </InputLabel>
                  </Box>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">
                      Subscription Type
                    </InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.subscriptionType}
                    </InputLabel>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">Details</InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.description}
                    </InputLabel>
                  </Box>
                </Box>
                {this.state.receiptUrl ? (
                  <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                    <Box pr={1} width={1 / 2}>
                      <InputLabel className="view-label">Receipt</InputLabel>
                      <InputLabel className="view-label value">
                        <a
                          href={this.state.receiptUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </InputLabel>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                {this.state.payAsYouList.length > 10 ? (
                  <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                    <Box pr={1} width={1}>
                      <Box display="flex" justifyContent="space-between">
                        <Box width={1 / 3}>
                          <InputLabel className="view-label">
                            Category name
                          </InputLabel>
                        </Box>
                        <Box width={1 / 3}>
                          <InputLabel className="view-label">
                            Data Usage
                          </InputLabel>
                        </Box>
                        <Box width={1 / 3}>
                          <InputLabel className="view-label">
                            Free Data Downloaded
                          </InputLabel>
                        </Box>
                      </Box>

                      {(() => {
                        try {
                          const parsedData = JSON.parse(
                            this.state.payAsYouList
                          );

                          if (parsedData && Array.isArray(parsedData.data)) {
                            return parsedData.data.map((item, index) => (
                              <Box
                                key={index}
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box width={1 / 3}>
                                  <InputLabel className="view-label value">
                                    {item.category_name}
                                  </InputLabel>
                                </Box>
                                <Box width={1 / 3}>
                                  <InputLabel className="view-label value">
                                    {item.data_usage}
                                  </InputLabel>
                                </Box>
                                <Box width={1 / 3}>
                                  <InputLabel className="view-label value">
                                    {item.free_data_downloaded}
                                  </InputLabel>
                                </Box>
                              </Box>
                            ));
                          } else {
                            console.error(
                              "Invalid data structure in this.state.payAsYouList"
                            );
                          }
                        } catch (error) {
                          console.error(
                            "Error parsing this.state.payAsYouList as JSON",
                            error
                          );
                        }
                      })()}
                      {/* Add more rows as needed */}
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions
            className="modal-actions"
            justify="center"
          ></DialogActions>
        </Dialog>
        <GridContainer>
          <Card className={"advert-cover"}>
            <GridItem xs={12} sm={12} md={12}>
              <CardHeader
                color="success"
                className={"cusCardheader cusCardTitle"}
              >
                <h4>Invoice</h4>
              </CardHeader>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}></GridItem>
            <div className={"custom-container"}>
              <div className="tableResponsive">
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "default"}
                        >
                          {headCell.sorting ? (
                            <TableSortLabel
                              active={this.state.orderBy == headCell.id}
                              direction={this.state.direction ? "asc" : "desc"}
                              onClick={this.tableSortHandler(headCell.id)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          ) : (
                            headCell.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.providerInvoices.length ? (
                      this.state.providerInvoices.map((providerInvoice) => (
                        <TableRow key={providerInvoice.id}>
                          <TableCell>{providerInvoice.id}</TableCell>
                          <TableCell>{providerInvoice.providerName}</TableCell>
                          <TableCell>
                            {providerInvoice.firstName}{" "}
                            {providerInvoice.lastName}{" "}
                          </TableCell>

                          <TableCell>
                            ${providerInvoice.subscriptionAmount}
                          </TableCell>
                          <TableCell>
                            {providerInvoice.subscriptionType.replace(
                              /_/g,
                              " "
                            )}
                          </TableCell>
                          <TableCell>
                            {moment(providerInvoice.startDate)
                              .utc()
                              .format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            {providerInvoice.subscriptionType == "MONTH"
                              ? "N/A"
                              : this.formatFileSize(
                                  providerInvoice.totalDataUsagesKb
                                )}
                          </TableCell>
                          <TableCell>
                            <span
                              className="edit-action"
                              onClick={() => this.modalOpen(providerInvoice.id)}
                            >
                              <Icon className="fa fa-eye" aria-hidden="true" />
                            </span>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colspan="7">
                          <div className="not-found-text">
                            Provider invoice not found
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>

              <TablePagination
                rowsPerPageOptions={PER_PAGE_OPTIONS}
                component="div"
                count={parseInt(this.state.total)}
                rowsPerPage={this.state.perPage}
                page={this.state.currentPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </Card>
        </GridContainer>
      </div>
    );
  }
}

export const Invoice = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceClass);
export default Invoice;
