import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InfiniteScroll from 'react-infinite-scroller';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { apiPath } from 'api'
import { generalAuthTokenHeader } from "__helpers/auth-header"
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
import FindProviderTabPanel from './FindProviderTabPanel';
import AwaitingProviderTabPanel from './AwaitingProviderTabPanel';
import FinalizedProviderTabPanel from './FinalizedProviderTabPanel';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, Link as MaterialLink, LinearProgress, } from "@material-ui/core";

function FindProvider(props) {
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [size] = useState(6);
    const [fetchMore, setFetchMore] = useState(true);
    const [defaultTabIndex, setDefaultTabIndex] = useState(props.selectCategoryIndex);
    const [tabIndex, setTabIndex] = useState(props.selectCategoryIndex);
    const [tabtatus, setTabtatus] = useState(props.selectCategoryStatus);
    const [patientInfo, setPatientInfo] = useState(props.patientInfo);
    const [loader, setLoader] = useState(false);
    const [providerDetailModal, setProvider] = useState(props.providerDetailModal)

    function selectTab(categoryId, categoryStatus) {
        let categoryStatusLocal = categoryStatus ? categoryStatus : props.selectCategoryStatus;
        setTabIndex(categoryId)
        setTabtatus(categoryStatusLocal)
    }
    return (
        <div className="find-provider-tabs  ">
            {
                (typeof props.patientInfo.patientCatagoryMapping === "object" && props.patientInfo.patientCatagoryMapping.length)
                    ?
                    <Tabs defaultIndex={props.selectCategoryIndex}>
                        <TabList className="provider-tab" >
                                {
                                    props.patientInfo.patientCatagoryMapping
                                        .sort((a, b) => a.categoryId > b.categoryId ? 1 : -1)
                                        .map((category, key) =>
                                            (
                                                <Tab key={key} variant="scrollable"
                                                    scrollbuttons="auto" >
                                                    <Box className="category-value-status" display="flex" flexDirection="column" >
                                                        <div className="categoryValue">{category.masterCategories.categoryValue}</div>
                                                        <div className={`categoryStatus ${category && category.refferalEntity && category.refferalEntity.masterRefferalStatusEntity ? category.refferalEntity.masterRefferalStatusEntity.refferalStatusValue : "Finding"}`}>
                                                            {category && category.refferalEntity && category.refferalEntity.masterRefferalStatusEntity
                                                                ?
                                                                category.refferalEntity.masterRefferalStatusEntity.refferalStatusValue
                                                                :
                                                                "Find Provider"
                                                            }
                                                        </div>
                                                    </Box>
                                                </Tab>)
                                        )
                                }
                        </TabList>
                        {(props.patientInfo.patientCatagoryMapping
                            .sort((a, b) => a.categoryId > b.categoryId ? 1 : -1)
                            .map((category, key) =>
                                (
                                    <TabPanel key={key}>
                                        {category && category.refferalEntity && category.refferalEntity.refferalStatus
                                            ?
                                            {
                                                1:
                                                    <FindProviderTabPanel
                                                        categoryId={category.categoryId}
                                                        selectedProviderDataCallback={
                                                            props.selectedProviderDataCallback
                                                        }
                                                        loaderStatus={
                                                            props.loaderStatus
                                                        }
                                                        updatePatient={
                                                            props.updatePatient
                                                        }
                                                        setLoader={
                                                            setLoader
                                                        }
                                                        patientInfo={props.patientInfo}
                                                        multiPatientMode={false}
                                                    />,
                                                2:
                                                    <AwaitingProviderTabPanel
                                                        categoryId={category.categoryId}
                                                        refferalId={category.refferalId}
                                                        selectedProviderDataCallback={
                                                            props.selectedProviderDataCallback
                                                        }
                                                        loaderStatus={
                                                            props.loaderStatus
                                                        }
                                                        updatePatient={
                                                            props.updatePatient
                                                        }
                                                        setLoader={
                                                            setLoader
                                                        }
                                                        patientInfo={props.patientInfo}
                                                        multiPatientMode={false}
                                                    />,
                                                3:
                                                    <FinalizedProviderTabPanel
                                                        categoryId={category.categoryId}
                                                        refferalId={category.refferalId}
                                                        selectedProviderDataCallback={
                                                            props.selectedProviderDataCallback
                                                        }
                                                        loaderStatus={
                                                            props.loaderStatus
                                                        }
                                                        updatePatient={
                                                            props.updatePatient
                                                        }
                                                        setLoader={
                                                            setLoader
                                                        }
                                                        patientInfo={props.patientInfo}
                                                        multiPatientMode={false}
                                                    />,
                                                billing: <div className="tab-heading">Comming Soon</div>,
                                            }[category.refferalEntity.refferalStatus]
                                            ||
                                            <FindProviderTabPanel
                                                categoryId={category.categoryId}
                                                selectedProviderDataCallback={
                                                    props.selectedProviderDataCallback
                                                }
                                                loaderStatus={
                                                    props.loaderStatus
                                                }
                                                updatePatient={
                                                    props.updatePatient
                                                }
                                                setLoader={
                                                    setLoader
                                                }
                                                patientInfo={props.patientInfo}
                                                multiPatientMode={false}
                                            />
                                            :
                                            <FindProviderTabPanel
                                                categoryId={category.categoryId}
                                                selectedProviderDataCallback={
                                                    props.selectedProviderDataCallback
                                                }
                                                loaderStatus={
                                                    props.loaderStatus
                                                }
                                                updatePatient={
                                                    props.updatePatient
                                                }
                                                setLoader={
                                                    setLoader
                                                }
                                                patientInfo={props.patientInfo}
                                                multiPatientMode={false}
                                            />
                                        }
                                    </TabPanel>
                                )
                            ))}
                    </Tabs>
                    :
                    props.loading
                        ?
                        "Loading tabs"
                        :
                        "No Categories Selected, Please Add a New Care Category"
            }
        </div>
    );
}
export default FindProvider