import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, Link } from "@material-ui/core"
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { FormGroup, TextField, CircularProgress } from "@material-ui/core";
import { FormErrors } from "components/Login/FormErrors";
import {
  MIN_PASSWORD_LENGTH,
  PASSWORD_PATTERN,
  NotificationOptions,
  FORBIDDEN_STATUS,
  INVALID_DATA_POST,
  NO_DATA_FOUND,
  SECURITY_ERROR,
  MAX_PASSWORD_LENGTH,
  NO_CONTENT_STATUS
} from "__helpers/constants";
import enMsg from "__helpers/locale/en/en";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { apiPath } from "api";
import { authHeader } from "__helpers/auth-header";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import { userService } from "_services/user.service";
import { USER_INFO } from "__helpers/constants";
import PowerOff from "@material-ui/icons/PowerSettingsNew";
import { NavLink } from "react-router-dom";

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      open: false,
      other: undefined,
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
      loading: false,
      formErrors: {
        newPassword: "",
        oldPassword: "",
        confirmPassword: ""
      },
      oldPasswordValid: false,
      newPasswordValid: false,
      confirmPasswordValid: false,
      formValid: false
    };
    this.apiPath = apiPath.profileChangePassword;

    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleSubmitApi = this.handleSubmitApi.bind(this);
  }

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    let newPasswordValid = this.state.newPasswordValid;
    let oldPasswordValid = this.state.oldPasswordValid;
    let confirmPasswordValid = this.state.confirmPasswordValid;
    let errorMessage = "";

    switch (fieldName) {
      case "oldPassword":
        errorMessage = "";
        oldPasswordValid = true;
        if (value.trim().length < 5) {
          errorMessage = enMsg.shortPassword;
          oldPasswordValid = false;
        } else if (value.length > MAX_PASSWORD_LENGTH) {
          oldPasswordValid = false;
          errorMessage = enMsg.longPassword;
        }
        fieldValidationErrors.oldPassword = errorMessage;
        break;
      case "newPassword":
         errorMessage = "";
        newPasswordValid = true;
        if (value.trim().length < MIN_PASSWORD_LENGTH) {
          errorMessage = enMsg.shortPassword;
          newPasswordValid = false;
        } else if (value.length > MAX_PASSWORD_LENGTH) {
          newPasswordValid = false;
          errorMessage = enMsg.longPassword;
        } else if (!value.trim().match(PASSWORD_PATTERN)) {
          errorMessage = enMsg.passwordPatternValidation;
          newPasswordValid = false;
        } else if (this.state.confirmPassword === value) {
          confirmPasswordValid = true;
        }
        fieldValidationErrors.newPassword = errorMessage;

        let confirmErrorMessage = "";
        confirmPasswordValid = true;
        if (value.trim().length && this.state.confirmPassword.trim().length && this.state.confirmPassword !== value) {
          confirmPasswordValid = false;
          confirmErrorMessage = enMsg.confirmPasswordValidation;
        }
        fieldValidationErrors.confirmPassword = confirmErrorMessage;
        break;

      case "confirmPassword":
        errorMessage = "";
        confirmPasswordValid = true;
        if (value.trim().length && this.state.newPassword !== value) {
          confirmPasswordValid = false;
          errorMessage = enMsg.confirmPasswordValidation;
        }
        fieldValidationErrors.confirmPassword = errorMessage;
        break;
      default:
    }
    const formValid = newPasswordValid && confirmPasswordValid;
    this.setState(
      {
        formErrors: fieldValidationErrors,
        formValid: formValid,
        newPasswordValid: newPasswordValid,
        oldPasswordValid: oldPasswordValid,
        confirmPasswordValid: confirmPasswordValid
      },
      this.validateForm
    );
  };
  validateForm() {
    return (
      this.state.formValid &&
      this.state.newPasswordValid &&
      this.state.oldPasswordValid &&
      this.state.confirmPasswordValid
    );
  }
  handleEntering = () => {
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open
      });
    }
  }
  componentDidMount() { }
  componentWillReceiveProps(nextProps) {
    let allowCloseModal = nextProps.allowCloseModal
    this.setState({
      allowCloseModal: allowCloseModal
    })
  }
  handleCloseDialog = e => {
    if (!this.state.allowCloseModal) {
    } else {
      this.props.handleChangePasswordDialog();
    }
  };
  handleLogout = () => {
    userService.logout();
    return true;
  };
  resetForm = () => {
    const node = this.formRef.current;
    node.reset();
    const labelNodes = node.querySelectorAll(
      ".MuiInputLabel-shrink.MuiFormLabel-filled:not(.Mui-disabled)"
    );

    this.setState({
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
      loading: false,
      formErrors: {
        newPassword: "",
        oldPassword: "",
        confirmPassword: ""
      },
      oldPasswordValid: false,
      newPasswordValid: false,
      confirmPasswordValid: false,
      formValid: false,
      allowCloseModal: false,
    });
  };
  async handleSubmitApi(event) {
    this.setState({ loading: true });
    event.preventDefault();
    const data = {
      currentPassword: this.state.oldPassword,
      newPassword: this.state.newPassword
    };

    let showNotification = {};
    try {
      const response = await fetch(this.apiPath, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data)
      })
        .then(response => {
          if (response.status == INVALID_DATA_POST) {
            showNotification = {
              title: enMsg.oldPasswordTitleFail,
              message: enMsg.oldPasswordMsgFail,
              type: "danger"
            };
          } else if (
            response.status == SECURITY_ERROR ||
            response.status == FORBIDDEN_STATUS
          ) {
            showNotification = {
              title: enMsg.oldPasswordTitleFail,
              message: enMsg.oldPasswordMsgFail,
              type: "danger"
            };
            // this.handleCloseDialog();
          } else if (response.ok) {
            showNotification = {
              title: enMsg.successTitle,
              message: enMsg.successPasswordChange,
              type: "success"
            };
            if (!this.state.allowCloseModal) {
              let user = JSON.parse(localStorage.getItem(USER_INFO));
              user.newUser = false;
              localStorage.setItem(USER_INFO, JSON.stringify(user));
              window.location.reload(true);
            }
            this.handleCloseDialog();
            this.resetForm();
          } else {
            showNotification = {
              title: enMsg.oldPasswordTitleFail,
              message: enMsg.oldPasswordMsgFail,
              type: "danger"
            };
          }
          return true;
        })
        .catch(error => {
          showNotification = {
            title: enMsg.failedTitle,
            message: enMsg.networkFailedError,
            type: "danger"
          };

          return response;
        });

      // throw new Error(error);
    } catch (error) {
      showNotification = {
        title: enMsg.failedTitle,
        message: enMsg.networkFailedError,
        type: "danger"
      };
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          id: new Date().getTime(),
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type
        };
        notifiaciton = Object.assign(NotificationOptions, notifiaciton);
        this.notificationID = store.addNotification(notifiaciton);
      }
      userService.showNotification(showNotification)
    }
    this.setState({ loading: false });
  }

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (value.length > MAX_PASSWORD_LENGTH) {
      return true;
    }
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        PaperProps={{
          className: "change-password-dialogue"
        }}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
       
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        open={this.state.open}
        {...this.state.other}
        className={"change-pass-dialog profile-content"}
      >
        <form ref={this.formRef} onSubmit={this.handleSubmitApi} noValidate>
          <DialogTitle className="add-modal-title" id="confirmation-dialog-title">
            Change Password
            {this.state.allowCloseModal
              ?
              <IconButton className="closeButton" aria-label="close" onClick={this.handleCloseDialog}>
                <CloseIcon />
              </IconButton>
              :
              ""
            }
          </DialogTitle>
          <DialogContent /* dividers */>
            <FormGroup>
              <TextField
                label="Old Password"
                InputLabelProps={{ className: "required-label" }}
                name="oldPassword"
                type="password"
                autoComplete="off"
                data-validators="isRequired,isAlpha"
                onChange={this.handleUserInput}
                value={this.state.oldPassword}
                inputProps={{ maxLength: MAX_PASSWORD_LENGTH }}
              />
              <FormErrors
                show={!this.state.oldPasswordValid}
                formErrors={this.state.formErrors}
                fieldName="oldPassword"
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="New Password"
                InputLabelProps={{ className: "required-label" }}
                name="newPassword"
                type="password"
                autoComplete="off"
                data-validators="isRequired,isAlpha"
                onChange={this.handleUserInput}
                value={this.state.newPassword}
                inputProps={{ maxLength: MAX_PASSWORD_LENGTH }}
              />
              <FormErrors
                show={!this.state.newPasswordValid}
                formErrors={this.state.formErrors}
                fieldName="newPassword"
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Confirm Password"
                InputLabelProps={{ className: "required-label" }}
                name="confirmPassword"
                type="password"
                autoComplete="off"
                data-validators="isRequired,isAlpha"
                onChange={this.handleUserInput}
                value={this.state.confirmPassword}
                inputProps={{ maxLength: MAX_PASSWORD_LENGTH }}
              />
              <FormErrors
                show={!this.state.confirmPasswordValid}
                formErrors={this.state.formErrors}
                fieldName="confirmPassword"
              />
            </FormGroup>
            <div className="action-btns guidelines-data">
              <p><b>Guidelines</b></p>
              <p>Use at least {MIN_PASSWORD_LENGTH} letters with alphanumeric and special characters.</p>
              <p>Do not use a space in the password.</p>
              <p>Do not form a password by appending a digit to a word--this type of password is easily guessed.</p>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="form-button-cover form-actions">
              <Button
                className={this.state.loading ? "buttonSuccess btn1" : "btn1"}
                disabled={this.state.loading || !this.validateForm()}
                type="submit"
              >
                {!this.state.allowCloseModal ? "Change Password" : "Update"}
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              {this.state.allowCloseModal ?
                <Button
                  className="back-btn btn2"
                  type="button"
                  onClick={this.handleCloseDialog}
                >
                  Cancel
              </Button>
                :
                <NavLink to="#" onClick={this.handleLogout}>
                  <Button
                    className="back-btn btn2"
                    type="button"
                  >
                    Logout
              </Button>
                </NavLink>
              }
            </div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ChangePassword.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
  // value: PropTypes.string.isRequired
};
