/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import baseRoutes from "base-routes";
import { Link } from "react-router-dom";
import { basePath } from "base-routes";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import { useSelector, useDispatch } from "react-redux";
import HomeIcon from '@material-ui/icons/Home';

const ProviderSidebar = ({ ...props }) => {
    const [rotationVal, setRotationVal] = React.useState(180);
    let selectedAdnetwork = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    selectedAdnetwork = useSelector(state => state.adNetwork);
    selectedAdnetwork = (selectedAdnetwork) ? selectedAdnetwork.adNetworkId : "";
    const [showMenu, setShowMenu] = React.useState(true);
    if (showMenu) {
        if (selectedAdnetwork) {
            setShowMenu(false);
        }
    }
    let activeRouteName = [];
    function activeRoute(routeName) {
        return window.location.pathname.indexOf(routeName) > -1 ? true : false;
    }
    const { classes, routes, logoText, color, logo, image, role } = props;
    const { cusSidebar } = "cus-sidebar";
    var linksMenu = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                var activePro = " ";
                var listItemClasses;
                if (prop.path === "/upgrade-to-pro") {
                    activePro = classes.activePro + " ";
                    listItemClasses = classNames({
                        [" " + classes[color]]: true
                    });
                } else {
                    listItemClasses = classNames({
                        [" " + classes[color] + " cusSelected"]: activeRoute(
                            prop.basePath + /* prop.layout + */ prop.path
                        )
                    });
                }
                const whiteFontClasses = classNames({
                    [" " + classes.whiteFont]: activeRoute(
                        prop.basePath + /* prop.layout + */ prop.path
                    )
                } + ' sidebarLeftNavLink');
                if (prop.showInSideBar == true) {
                    activeRouteName.push(activeRoute(
                        prop.basePath + /* prop.layout + */ prop.path
                    )
                        ? prop.name
                        : "");
                    return (
                        <NavLink
                            to={prop.basePath + /* prop.layout + */ prop.path}
                            className={activePro + classes.item}
                            activeClassName="active"
                            key={key}
                        >
                            <ListItem button className={classes.itemLink + listItemClasses}>
                                {typeof prop.icon === "string" ? (
                                    <Icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    >
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                )}
                                <ListItemText
                                    primary={props.rtlActive ? prop.rtlName : prop.name}
                                    className={classNames(classes.itemText, whiteFontClasses, {
                                        [classes.itemTextRTL]: props.rtlActive
                                    })}
                                    disableTypography={true}
                                />
                            </ListItem>
                        </NavLink>
                    );
                }
            })}
        </List>
    );
    var brand = (
        <div className={classes.logo + " Sidebar-logo"}>
            <Link
                to={baseRoutes.dashboard.path}
                className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: props.rtlActive
                }) + " Sidebar-logoLink"}
            >
                <div className={classes.logoImage + ' Sidebar-logoImage'}>
                    <img src={logo} alt="logo" className={classes.img} />
                </div>
                {logoText}
            </Link>
            <Link
                to={baseRoutes.dashboard.path}
            >
            </Link>
        </div>
    );
    activeRouteName = activeRouteName.filter(val => val !== "");
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    side="right"
                    anchor={props.rtlActive ? "left" : "left"}
                    open={props.open}
                    classes={{
                        paper:
                            classNames({
                                [classes.drawerPaperRTL]: props.rtlActive
                            }) + " sidebar-cover"
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    <div className={classes.sidebarWrapper + ' Sidebar-sidebarWrapper maxmrj_mobile_menu'}>
                        {brand}
                        {/* {linksMenu} */}
                        {<AdminNavbarLinks
                            logoText={logoText}
                            logo={logo}
                            role={role}
                            userDetail={props.userDetail}
                            updateUserInfo={props.updateUserInfo}
                            saveDataToLocalStorage={props.saveDataToLocalStorage}
                            localStorageData={props.localStorageData}
                            sendAdnetworkData={props.sendAdnetworkData}
                            handleDrawerToggle={props.handleDrawerToggle}
                            // onClick={props.handleDrawerToggle}
                        />}
                    </div>
                    {image !== undefined ? (
                        <div className={classes.background + " " + cusSidebar + ' themeBgChange'} />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
};
ProviderSidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    userDetail: PropTypes.object,
    updateUserInfo: PropTypes.func,
    saveDataToLocalStorage: PropTypes.func,
    localStorageData: PropTypes.object,
    sendAdnetworkData: PropTypes.func,
    // style
};
export default withStyles(sidebarStyle)(ProviderSidebar);
