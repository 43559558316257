import { Checkbox, FormControlLabel } from "@material-ui/core";

import React, { useState } from "react";
import PatientSelect from "../PatientSelect";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Filter({
  filter,
  setFilter,
  isPatientSearch,
  setPatientIds,
}) {
  const [isPatientSearchChecked, setIsPatientSearchChecked] = useState(false);
  return (
    <div>
      <div>Filter</div>
      <div>
        <FormControlLabel
          className="tasks-bg"
          control={
            <Checkbox
              checked={filter.includes(0)}
              value={0}
              onClick={(event) => {
                var updatedFilter = [...filter];
                if (event.target.checked) {
                  updatedFilter = [...filter, parseInt(event.target.value)];
                } else {
                  updatedFilter.splice(
                    filter.indexOf(parseInt(event.target.value)),
                    1
                  );
                }
                setFilter(updatedFilter);
              }}
            />
          }
          label="Tasks"
        />
      </div>
      <div>
        <FormControlLabel
          className="transpot-bg"
          control={
            <Checkbox
              checked={filter.includes(1)}
              value={1}
              onClick={(event) => {
                var updatedFilter = [...filter];
                if (event.target.checked) {
                  updatedFilter = [...filter, parseInt(event.target.value)];
                } else {
                  updatedFilter.splice(
                    filter.indexOf(parseInt(event.target.value)),
                    1
                  );
                }
                setFilter(updatedFilter);
              }}
            />
          }
          label="Transport"
        />
      </div>
      <div>
        <FormControlLabel
          className="estimated-bg"
          control={
            <Checkbox
              checked={filter.includes(2)}
              value={2}
              onClick={(event) => {
                var updatedFilter = [...filter];
                if (event.target.checked) {
                  updatedFilter = [...filter, parseInt(event.target.value)];
                } else {
                  updatedFilter.splice(
                    filter.indexOf(parseInt(event.target.value)),
                    1
                  );
                }
                setFilter(updatedFilter);
              }}
            />
          }
          label="Estimated DC"
        />
      </div>

      {isPatientSearch && (
        <>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPatientSearchChecked}
                  // value={3}
                  onClick={(event) => {
                    if (event.target.checked) {
                      setIsPatientSearchChecked(true);
                    } else {
                      setPatientIds([]);
                      setIsPatientSearchChecked(false);
                    }
                  }}
                />
              }
              label="Search By Patient"
            />
          </div>
          {isPatientSearchChecked && (
            <div>
              <PatientSelect setPatientIds={setPatientIds} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
