import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Link, LinearProgress } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { FormErrors } from "../../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import PhoneNumber from "awesome-phonenumber";
import ConfirmModal from "components/Modal/modal";
import Icon from "@material-ui/core/Icon";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import {
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
  SELECTED_PATIENT_LIST,
  MULTIPLE_PATIENT_LIST_CATEGORIES,
  VENDOR_FACILITY,
  MAXMRJ_FACILITY,
} from "__helpers/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
} from "@material-ui/core";
import SelectStatus from "components/SelectStatus/selectStatus";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import CircularProgress from "@material-ui/core/CircularProgress";
import uploadPng from "assets/img/upload.png";
import categoriesImg from "assets/img/icons/categoriesImg.png";
import tasksImg from "assets/img/icons/tasksImg.png";
import rightIcon from "assets/img/icons/right-icon.svg";
import { connect } from "react-redux";
import { reduxLoad } from "js/actions/index";
import { basePath, baseRoutes, dpBaseRoutes } from "base-routes";
import { NavLink } from "react-router-dom";
function mapDispatchToProps(dispatch) {
  return {
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class CategoriesListClass extends React.Component {
  constructor(props) {
    super(props);
    const categoryList = [
      { name: "Health Care", id: "1" },
      { name: "Home Care", id: "2" },
      { name: "Hospice", id: "3" },
      { name: "Assisted Living", id: "4" },
      { name: "Pharmacy", id: "5" },
    ];
    const patientCategoryList = ["1", "3", "4"];

    this.state = {
      loading: false,
      patientName: "unknown",
      patientId: "",
      categoryList: props.categoryListRedux ? props.categoryListRedux : [],
      facilityType: props.facilityType ? props.facilityType : MAXMRJ_FACILITY,
      patientCategoryList: patientCategoryList,
      reduxLoadFlag: false,
      multiPatientMode: this.props.multiPatientMode
        ? this.props.multiPatientMode
        : false,
    };
    // this.openPatientDetail = this.openPatientDetail.bind(this);
    this.selectedPatient = this.selectedPatient.bind(this);
  }

  componentDidMount() {}
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let categoryList = [];
      if (this.props.categoryListRedux) {
        let selectedcategoryList = this.props.categoryListRedux;
        categoryList = selectedcategoryList ? selectedcategoryList : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        categoryList: categoryList,
      });
    }
  }
  componentWillUnmount() {
    Object.getPrototypeOf(this).constructor.STATE = this.state;
  }
  // UNSAFE_componentWillMount() {
  //     this.setState(Object.getPrototypeOf(this).constructor.STATE || {});
  //     if (Object.getPrototypeOf(this).constructor.STATE) {
  //         var stataData = Object.getPrototypeOf(this).constructor.STATE;
  //         Object.keys(stataData).map((v, i) => {
  //             this.setState({
  //                 [v]: stataData[v],
  //             })
  //         });
  //     }
  // }
  componentWillReceiveProps(nextProps) {
    let patientCategoryList = nextProps.patientCategoryList;
    let patientName = nextProps.patientName;
    let patientId = nextProps.patientId;
    let loading = nextProps.loading;
    let multiPatientMode = nextProps.multiPatientMode;
    this.setState({
      patientCategoryList: patientCategoryList,
      loading: loading,
      patientName: patientName,
      patientId: patientId,
      multiPatientMode: multiPatientMode,
      facilityType: nextProps.facilityType
        ? nextProps.facilityType
        : MAXMRJ_FACILITY,
    });
  }
  openPatientDetail = (categoryListId, patientId) => {};
  selectedPatient(categoryId) {
    localStorage.setItem(
      SELECTED_PATIENT_LIST,
      JSON.stringify(this.state.patientId)
    );
    sessionStorage.setItem(
      MULTIPLE_PATIENT_LIST_CATEGORIES,
      JSON.stringify(this.state.patientCategoryList)
    );
  }
  render() {
    const {
      categoryList,
      patientId,
      patientCategoryList,
      multiPatientMode,
    } = this.state;
    const { openPatientPopup } = this.props;
    return (
      <div id="facility-index" className="mb-20">
        <div>
          {patientId && patientId != "" && !multiPatientMode ? (
            <Box
              width={1}
              className="right-box-content categorylist-cover add-border"
            >
              <div className="box-header">
                Available&nbsp;Categories&nbsp;For<br></br>{" "}
                <span className="patientName" onClick={openPatientPopup}>
                  {this.state.patientName}
                </span>
              </div>
              <div className="loaderProgressFixed top65Fixed">
                {this.state.loading ? <LinearProgress color="primary" /> : ""}
              </div>
              <Box
                display="flex"
                justifyContent="center"
                className="middle-section"
              >
                <div className="middle-section-content">
                  {typeof categoryList === "object" &&
                  categoryList.length &&
                  patientCategoryList
                    ? categoryList
                        .sort((a, b) => (a.catagoryId > b.catagoryId ? 1 : -1))
                        .map((cList, key) => {
                          if (cList.parent == 0) {
                            return patientCategoryList.includes(
                              cList.catagoryId
                            ) ? (
                              <NavLink
                                key={key}
                                to={`${basePath}${
                                  dpBaseRoutes.singlePatientInfo.path
                                }?key=${cList.catagoryId}&mode=${btoa(
                                  btoa(this.state.multiPatientMode)
                                )}&facility=${btoa(
                                  btoa(this.state.facilityType)
                                )}`}
                                color="textSecondary"
                                className="link-a"
                                onClick={() =>
                                  this.selectedPatient(cList.categoryId)
                                }
                                // underline="none"
                              >
                                <Box
                                  className={"categorylist activeCategiry"}
                                  key={key}
                                  onClick={this.openPatientDetail.bind(
                                    this,
                                    cList.catagoryId,
                                    this.state.patientId
                                  )}
                                >
                                  {cList.categoryValue
                                    ? cList.categoryValue
                                    : "Unknown"}
                                  <img src={rightIcon} alt="" />
                                </Box>
                              </NavLink>
                            ) : (
                              <Box
                                className={"categorylist inactiveCategiry"}
                                key={key}
                                onClick={this.openPatientDetail.bind(
                                  this,
                                  cList.catagoryId,
                                  this.state.patientId
                                )}
                              >
                                {cList.categoryValue
                                  ? cList.categoryValue
                                  : "Unknown"}
                              </Box>
                            );
                          }
                        })
                    : null}
                </div>
              </Box>
            </Box>
          ) : multiPatientMode ? (
            <Box
              width={1}
              className="right-box-content categorylist-cover add-border"
            >
              <div className="box-header">
                Available&nbsp;Categories&nbsp;For<br></br>{" "}
                <span className="">Bundle</span>
              </div>
              <div className="loaderProgressFixed top65Fixed">
                {this.state.loading ? <LinearProgress color="primary" /> : ""}
              </div>
              <Box
                display="flex"
                justifyContent="center"
                className="middle-section"
              >
                <div className="middle-section-content">
                  {typeof categoryList === "object" &&
                  categoryList.length &&
                  patientCategoryList &&
                  patientCategoryList.length ? (
                    categoryList
                      .sort((a, b) => (a.catagoryId > b.catagoryId ? 1 : -1))
                      .map((cList, key) => {
                        if (cList.parent == 0) {
                          return patientCategoryList.includes(
                            cList.catagoryId
                          ) ? (
                            <NavLink
                              key={key}
                              to={`${basePath}${
                                dpBaseRoutes.singlePatientInfo.path
                              }?key=${cList.catagoryId}&mode=${btoa(
                                btoa(this.state.multiPatientMode)
                              )}`}
                              color="textSecondary"
                              className="link-a"
                              onClick={() =>
                                this.selectedPatient(cList.categoryId)
                              }
                              // underline="none"
                            >
                              <Box
                                className={"categorylist activeCategiry"}
                                key={key}
                                onClick={this.openPatientDetail.bind(
                                  this,
                                  cList.catagoryId,
                                  this.state.patientId
                                )}
                              >
                                {cList.categoryValue
                                  ? cList.categoryValue
                                  : "Unknown"}
                                <img src={rightIcon} alt="" />
                              </Box>
                            </NavLink>
                          ) : (
                            <Box
                              className={"categorylist inactiveCategiry"}
                              key={key}
                              onClick={this.openPatientDetail.bind(
                                this,
                                cList.catagoryId,
                                this.state.patientId
                              )}
                            >
                              {cList.categoryValue
                                ? cList.categoryValue
                                : "Unknown"}
                            </Box>
                          );
                        }
                      })
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      className="middle-section"
                    >
                      <div className="middle-section-content middle-section-content-middle text-center relativeContent">
                        <img src={categoriesImg} alt="" />
                        <div className="text">
                          No shared categories for patients selected
                        </div>
                      </div>
                    </Box>
                  )}
                </div>
              </Box>
            </Box>
          ) : (
            <Box width={1} className="right-box-content categorylistBox">
              <div className="box-header">Find Providers</div>
              {this.state.loading ? <LinearProgress color="primary" /> : ""}
              <Box
                display="flex"
                justifyContent="center"
                className="middle-section"
              >
                <div className="middle-section-content middle-section-content-middle">
                  <img src={categoriesImg} alt="" />
                  <div className="text">
                    Please select patient to view categories
                  </div>
                </div>
              </Box>
            </Box>
          )}
        </div>
      </div>
    );
  }
}
export const CategoriesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesListClass);
export default CategoriesList;
