import { apiPath } from "api";
import Axios from "axios";
import { generalAuthTokenHeader } from "__helpers/auth-header";
const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const loadOptions = async (search, prevOptions, { page, setPage }) => {
  await sleep(1000);

  //   /awesome-api-url/?search=${search}&page=${page}

  const response = await Axios(apiPath.fetchPatientSearch, {
    params: {
      page: page,
      size: 20,
      search,
    },
    headers: generalAuthTokenHeader(),
  });

  return {
    options: response.data,
    hasMore: response.data.length != 0,
    additional: {
      page: page + 1,
    },
  };
};

export default loadOptions;
