/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Box,
  TextField,
  Avatar,
  Checkbox,
  LinearProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";
import PhoneNumber from "awesome-phonenumber";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  genderType,
  EMAIL_REGEX,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ZIPCODE_REGEX,
  ZIP_CODE_LENGTH,
  MIN_AGE_YEARS,
  ALLOWED_IMAGE_EXTENTION,
  DEFAULT_PROFILE_IMG,
  DIGIT_ONLY,
  PHONE_NO_INVALID_LENGTH,
  OK_SUCCESS_STATUS,
  STATUS_CREATED,
  INVALID_DATA_POST,
  SECURITY_ERROR,
} from "__helpers/constants";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import baseRoutes from "base-routes";
import ChangePassword from "./ChangePassword";
import fileExtension from "file-extension";
import { generalAuthTokenHeader } from "__helpers/auth-header";

import { withRouter } from "react-router";

import { connect } from "react-redux";
import { addUSerUInfo, reduxLoad } from "js/actions";
import { USER_INFO } from "__helpers/constants";
import { dpBaseRoutes } from "base-routes";
import { basePath } from "base-routes";
import backArrow from "assets/img/icons/arrow-back.svg";
import BasicProfile from "./BasicProfile";
import InDevelopment from "./InDevlopment";
import commingSoonImg from "assets/img/comming_soon.png";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { MAX_INPUT_LENGTH_RATE } from "__helpers/constants";
import { enGB } from "date-fns/esm/locale";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroller";
import { providerBaseRoutes } from "base-routes";

function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: (addUSerUInfoVal) => dispatch(addUSerUInfo(addUSerUInfoVal)),
    addTimeStamp: (addTimeStampVal) => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    getPatientLanNInsListRedux: state.getPatientLanNInsList,
    hospitalListRedux: state.hospitalList,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const styles = (theme) => ({});

class DataUsesClass extends React.Component {
  constructor(props) {
    super(props);
    /* create Ref for file input  */

    this.state = {
      loading: false,
      userInfo: props.userInfo,
      dataAllowance: [],
      loadMore: false,
      currentItemPage: 0,
      displayItems: 50,
      lastMonthName: "",
      lastMonthDataUses: "",
    };
  }
  componentDidMount = async () => {
    if (this.state.userInfo) {
      let dataUses = await userService.getProviderDataUsage(
        this.state.userInfo.provider.id
      );
      setTimeout(
        function() {
          //this.props.reduxLoad(true);
        }.bind(this),
        500
      );
      this.setState({
        dataAllowance: dataUses,
      });
    }
  };

  componentDidUpdate() {}

  render() {
    return (
      <div className="PaterintCriteria1">
        {this.state.loading ? <LinearProgress color="primary" /> : ""}
        <div className="tab-heading">Data Usages</div>

        <Box
          display="flex"
          flexDirection="row"
          width={1}
          mt={2}
          className={"all-cover"}
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            mb={2}
            width={{ xs: "100%", sm: "33.33%" }}
          >
            <Box pr={3} width={1}>
              <Box className="view-label mb-10 new-font caption-switch">
                <div>Total Data Usages</div>
              </Box>
              <Box className="view-label value">
                {this.state.dataAllowance.totalDataUsages}
              </Box>
            </Box>
          </Box>
          {this.state.dataAllowance ? (
            <>
              <Box
                display="flex"
                justifyContent="flex-start"
                flexWrap="wrap"
                mb={2}
                width={{ xs: "100%", sm: "66.66%" }}
              >
                <Box pr={3} width={{ xs: 1, sm: 1 / 2 }}>
                  <Box className="view-label mb-10 new-font caption-switch">
                    <div>Free Mb Allowance</div>
                  </Box>
                  <Box className="view-label value">
                    {this.state.dataAllowance.freeMbAllowance} MB
                  </Box>
                </Box>
                <Box width={{ xs: 1, sm: 1 / 2 }}>
                  <Box className="view-label mb-10 new-fon new-font caption-switch">
                    <div>Remaining Free Allowance</div>
                  </Box>
                  <Box className="view-label value">
                    {this.state.dataAllowance.remainingFreeAllowance}
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                flexWrap="wrap"
                mb={2}
                width={{ xs: "100%", sm: "66.66%" }}
                mt={3}
              >
                <Box pr={3} width={{ xs: 1, sm: 1 / 2 }}>
                  <Box className="view-label new-font caption-switch">
                    <div>Last Three Month Data Usages</div>
                  </Box>
                  {this.state.dataAllowance &&
                    this.state.dataAllowance.lastThreeMonthDataUsages && (
                      <>
                        {this.state.dataAllowance.lastThreeMonthDataUsages.map(
                          (data, index) => (
                            <>
                              <Box key={index} className="view-label value">
                                <span className="mr-10 new-space2">
                                  {data.month}
                                </span>{" "}
                                {data.dataUsages}
                              </Box>
                            </>
                          )
                        )}
                      </>
                    )}
                </Box>
                <Box pr={3} width={{ xs: 1, sm: 1 / 2 }}>
                  <Box className="view-label new-font caption-switch">
                    <div>Last Month Data Usages</div>
                  </Box>
                  {this.state.dataAllowance &&
                    this.state.dataAllowance.lastThreeMonthDataUsages && (
                      <>
                        {this.state.dataAllowance.lastThreeMonthDataUsages.map(
                          (data, index) => (
                            <>
                              {index === 1 && (
                                <Box key={index} className="view-label value">
                                  <span className="mr-10 new-space2">
                                    {data.month}
                                  </span>{" "}
                                  {data.dataUsages}
                                </Box>
                              )}
                            </>
                          )
                        )}
                      </>
                    )}
                </Box>
              </Box>
            </>
          ) : (
            <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
              No data
            </Box>
          )}
        </Box>
      </div>
    );
  }
}

DataUsesClass.propTypes = {
  classes: PropTypes.object,
};
const DataUses = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataUsesClass);
export default withStyles(styles)(DataUses);
