import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Slide,
    Button,
    TextareaAutosize
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import { FormErrors } from "components/Login/FormErrors"
import 'react-tabs/style/react-tabs.css';
import InfiniteScroll from 'react-infinite-scroller';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { apiPath } from 'api'
import { generalAuthTokenHeader } from "__helpers/auth-header"
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbars } from 'react-custom-scrollbars';
import { findProviderService } from '__helpers/util';
import { userService } from "_services/user.service"
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, Link as MaterialLink, LinearProgress, } from "@material-ui/core";
import notesArrow from "assets/img/icons/notes-arrow.svg";
import enMsg from "__helpers/locale/en/en"
import PatientCriteria from "views/dpViews/SinglePatientDetail/PatientCriteria"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

import { dpBaseRoutes } from "base-routes";
import {
    reduxLoad,
} from "js/actions/index";
import {
    REFERRAL_TYPE, MAX_INPUT_LENGTH, MAX_INPUT_LENGTH_LONG
} from "__helpers/constants"
import moment from 'moment-timezone';
import { localDateFormat } from '__helpers/util';

function mapDispatchToProps(dispatch) {
    return {
        reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
    };
}
const mapStateToProps = state => {
    return {
        userInfoRedux: state.userInfo,
        categoryListRedux: state.categoryList,
        insuranceListRedux: state.insuranceList,
        patientStatusListRedux: state.patientStatusList,
        hospitalListRedux: state.hospitalList,
        timestampRedux: state.timestamp,
        reduxLoadFlag: state.reduxLoadFlag,
    };
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
moment.tz.setDefault('UTC');
class BundlePatientListClass extends React.Component {
    constructor(props) {
        super(props)
        this.referralSent = false;
        this.state = {
            setLoader: this.props.setLoader ? this.props.setLoader : false,
            loading: false,
            referralLoader: false,
            items: this.props.bundlePatientList ? this.props.bundlePatientList : {},
            reservedItems: this.props.bundlePatientList ? this.props.bundlePatientList : {},
            providerInfo: this.props.providerInfo ? this.props.providerInfo : {},
            page: 0,
            searchKeyword: '',
            patientIds: [],
            size: 6,
            fetchMore: false,
            categoryId: this.props.categoryId,
            patientId: localStorage.getItem('_selected_patient_list'),
            patientCriteriaModalStatus: false,
            totalSelectedProviders: 0,
            referralType: 0,
            confirmBoxStatus: false,
            notFound: false,
            insuranceList: this.props.insuranceListRedux,
            categoryList: props.categoryListRedux ? props.categoryListRedux : [],
        }
        this.handleConfirmBox = this.handleConfirmBox.bind(this);
        this.sendReferralMiddle = this.sendReferralMiddle.bind(this);
        this.searchPatient = this.searchPatient.bind(this);
    }
    componentDidMount() {
    }
    searchPatient = (event) => {
        let searchKeyword = (event.target.value).trim();
        this.setState({
            searchKeyword: searchKeyword
        })
    }
    searchPatientList = () => {
        let _patients = this.state.reservedItems;
        let search = this.state.searchKeyword;
        _patients = this.state.reservedItems;
        _patients = _patients.filter(function (patient) {
            return patient.firstName.toLowerCase().match(search) ||
                patient.lastName.toLowerCase().match(search) ||
                ((patient.middleName != null) && patient.middleName.toLowerCase().match(search))
                || (patient.middleName != null) ? (patient.firstName + ' ' + ((patient.middleName != '') ? patient.middleName : '') + ' ' + patient.lastName).toLowerCase().match(search) : (patient.firstName + ' ' + patient.lastName).toLowerCase().match(search);;
        });
        if (search == '') {
            this.setState({
                items: this.state.reservedItems
            })

        } else {
            this.setState({
                items: _patients
            })
        }
    }

    getPatientId = (patientId) => {

        let patientIds = this.state.patientIds;
        let allItem = this.state.items;
        let count = this.state.totalSelectedProviders;
        if (patientIds.includes(patientId)) {
            const index = patientIds.indexOf(patientId);
            if (index > -1) {
                patientIds.splice(index, 1);
                allItem.map(item => {
                    if (item.patientId == patientId) {
                        item['isChecked'] = false;
                        count -= 1;
                    }
                })
            }
        } else {
            allItem.map(item => {
                if (item.patientId == patientId) {
                    item['isChecked'] = true;
                    count += 1;
                }
            })
            patientIds.push(patientId);
        }
        this.setState({
            patientIds: patientIds,
            totalSelectedProviders: count
        })
    }
    handleAllChecked = (event) => {
        let allItem = this.state.items;
        let patientIds = this.state.patientIds;
        if (allItem.length && allItem.length == this.state.totalSelectedProviders) {
            patientIds = [];
            this.setState({
                patientIds: patientIds,
                totalSelectedProviders: 0,
            })
        } else {
            allItem.map(item => {
                if (!patientIds.includes(item.patientId)) {
                    patientIds.push(item.patientId);
                }
                item['isChecked'] = true;
            })
            this.setState({
                patientIds: patientIds,
                totalSelectedProviders: allItem.length,
            })
        }
    }
    fetchMoreData = () => {
        this.setState({
            fetchMore: false,
            criteriaFilter: ''
        }, () => this.fetchProviders())
    }
    handleConfirmBox = () => {
        if (this.state.patientIds.length > 0) {
            let status = this.state.confirmBoxStatus ? false : true
            this.setState({
                confirmBoxStatus: status,
            })
        } else {
            let showNotification = {
                title: 'Referral',
                message: 'Please select at least one patient',
                type: "danger"
            };
            this.setState({
                setLoader: false,
                referralLoader: false,
            })

            userService.showNotification(showNotification);
        }
    }
    sendReferral = (event) => {
        event.preventDefault();
        if (!this.referralSent) {
            this.referralSent = true;
            this.setState({
                referralLoader: true,
                referralSent: true,
            }, () => this.sendReferralMiddle())
        }
    }
    async sendReferralMiddle() {
        if (this.state.patientIds.length > 0) {
            this.setState({
                referralLoader: true,
                setLoader: true,
                loading: true
            })
            let showNotification = '';
            let data = {
                categoryId: parseInt(this.state.categoryId),
                refferalId: '',
                refferalType: this.state.referralType,
                patientId: this.state.patientIds,
                providerId: [this.state.providerInfo.id]

            };
            const response = await fetch(apiPath.sendReferral, {
                method: "POST",
                headers: generalAuthTokenHeader(),
                body: JSON.stringify(data),
                data: JSON.stringify(data)
            })
                .then(response => {
                    if (response.status === 400) {
                        showNotification = {
                            title: 'Referral',
                            message: enMsg.badResponseFromServer,
                            type: "danger"
                        };
                        this.setState({
                            setLoader: false,
                            referralLoader: false,
                            referralSent: false,
                            loading: false
                        })
                        this.referralSent = false;
                    } else if (response.ok) {
                        showNotification = {
                            title: 'Referral',
                            message: "Referral has been sent successfully.",
                            type: "success"
                        };
                    } else {
                        showNotification = {
                            title: 'Referral',
                            message: enMsg.badResponseFromServer,
                            type: "danger"
                        };
                        this.setState({
                            setLoader: false,
                            referralLoader: false,
                            referralSent: false,
                            loading: false
                        })
                        this.referralSent = false;
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.status == 400) {
                        showNotification = {
                            title: "Referral",
                            message: data.title,
                            type: 'danger'
                        };
                    } else if (data.id) {
                        showNotification = {
                            title: 'Referral',
                            message: "Referral has been sent successfully.",
                            type: "success"
                        };
                        this.handleConfirmBox();
                    }
                    setTimeout(() => {
                        window.location.replace(dpBaseRoutes.dashboard.path);
                    }, 2000);

                })
                .catch(error => {
                    showNotification = {
                        title: 'Referral',
                        message: enMsg.clientSideError,
                        type: "danger"
                    };
                    this.setState({
                        setLoader: false,
                        referralLoader: false,
                        referralSent: false,
                        loading: false
                    })
                    this.referralSent = false;
                });
            userService.showNotification(showNotification);
        } else {
            let showNotification = {
                title: 'Referral',
                message: 'Please select at least one provider',
                type: "danger"
            };
            this.setState({
                setLoader: false,
                referralLoader: false,
                loading: false
            })
            this.referralSent = false;
            userService.showNotification(showNotification);
        }
    }


    searchReturn(event) {
        if (event.target.keyCode === 13 || event.key === 'Enter') {
            event.preventDefault();
        }
    }
    renderTag = (searchHistory) => {
        let _categoryId = '';
        const _url = new URLSearchParams(searchHistory);
        let _subCategoryId = _url.get('subCategoryId');
        let searchCriteria = [];
        _categoryId = _subCategoryId.split(',').map(item => Number(item));
        this.state.categoryList.map(el => {
            if (_categoryId && _categoryId.includes(el.id)) {
                searchCriteria.push(el.categoryValue);
            }

        })
        if (searchCriteria.length) {
            return searchCriteria.map(criteria =>
                <div className={`tag ref-tag mr-10 category-value-bundle `}>{criteria}</div>
            )
        }
    }
    render() {
        const {
            patientIds,
            totalSelectedProviders,
            items,
            providerInfo,
        } = this.state;
        return (
            <div>
                <Dialog
                    open={this.state.confirmBoxStatus}
                    maxWidth={'xs'}
                    onClose={this.handleConfirmBox}
                    scroll='paper'
                    TransitionComponent={Transition}
                    aria-labelledby="form-dialog-title"
                    className="add-modal front-modal  sendReferralConfirmationModal"
                >
                    <DialogTitle className="add-modal-title" id="form-dialog-title">
                        Create Bundle
                        <IconButton className="closeButton" aria-label="close" onClick={this.handleConfirmBox}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <form onSubmit={this.sendReferral} noValidate>
                        <DialogContent >
                            <div className="name-bundle-list">
                                <Scrollbars
                                    autoHide={false}
                                    universal={true}
                                    autoHeight={true}
                                    autoHeightMin={0}
                                    autoHeightMax={100}
                                    className="bundleScroll"
                                >
                                    {items.length ? items.map((listItem, index) => {
                                        if (this.state.patientIds.includes(listItem.patientId)) {
                                            return (

                                                <div className="name">{listItem.lastName + ', ' + listItem.firstName}</div>

                                            )
                                        }
                                    })
                                        :
                                        'No Patient Selected'
                                    }
                                </Scrollbars>
                            </div>
                            <Box pr={2} mt={1} display="flex" flexDirection="row" className="bundle-type-patients">
                                <div>
                                    <div className="type">Referral Type</div>
                                    <div className="type-state">{REFERRAL_TYPE[this.state.referralType]}</div>
                                </div>
                                <div>
                                    <div className="type">No of Patients</div>
                                    <div className="type-state">{patientIds && patientIds.length}</div>
                                </div>
                            </Box>


                            <div className="PatientList">
                                <p>Provider</p>

                                <Scrollbars
                                    autoHide={false}
                                    universal={true}
                                    autoHeight={true}
                                    autoHeightMin={0}
                                    autoHeightMax={200}
                                    className="YOnlytScrollbars"
                                >
                                    <Box
                                        className="providerLisingMain"
                                    >
                                        <Box display="flex" pr={0} flexDirection="row" mb={2}>
                                            <div className="list-item-content">
                                                <Box className="list-item-content-data finding-box " width={1} display="flex" flexDirection="row">
                                                    <div className="user-info-tab-finalised">
                                                        <div className="name-bundle ">
                                                            {this.props.providerName}
                                                        </div>
                                                        <div className="date-insurance">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.609" height="14.608" viewBox="0 0 14.609 14.608">
                                                                <path id="phone" d="M5.94,9.324a12.228,12.228,0,0,0,5.347,5.344l1.785-1.788a.813.813,0,0,1,.825-.2,9.326,9.326,0,0,0,2.9.461.811.811,0,0,1,.812.812v2.84a.811.811,0,0,1-.812.812A13.8,13.8,0,0,1,3,3.81.811.811,0,0,1,3.81,3h2.84a.811.811,0,0,1,.812.812,9.326,9.326,0,0,0,.461,2.9.811.811,0,0,1-.2.824Z" transform="translate(-2.999 -2.998)" fill="#01a7a6" />
                                                            </svg>&nbsp;
                                                            <span className="phoneNumber">{providerInfo.phoneNumber ? "(" + providerInfo.phoneNumber.match(/(\d{3})(\d{3})(\d{4})/)[1] + ") " + providerInfo.phoneNumber.match(/(\d{3})(\d{3})(\d{4})/)[2] + "-" + providerInfo.phoneNumber.match(/(\d{3})(\d{3})(\d{4})/)[3] : "N/A"}</span>
                                                        </div>
                                                        <div className="date-insurance">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.609" height="14.608" viewBox="0 0 13.312 15.214">
                                                                <path id="user-minus" d="M6.656,7.607a3.8,3.8,0,1,0-3.8-3.8A3.8,3.8,0,0,0,6.656,7.607Zm2.662.951h-.5a5.172,5.172,0,0,1-4.332,0h-.5A4,4,0,0,0,0,12.551v1.236a1.427,1.427,0,0,0,1.426,1.426H11.886a1.427,1.427,0,0,0,1.426-1.426V12.551A4,4,0,0,0,9.318,8.558Z" fill="#01a7a6" />
                                                            </svg>
                                                            <span className="phoneNumber">{providerInfo.firstName} {providerInfo.lastName}</span>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </div>
                                        </Box>
                                    </Box>
                                </Scrollbars>
                            </div>
                            {
                                (this.state.referralType == 1) ?
                                    <Box className="formModalFields" width={1} display="flex" justifyContent="flex-start" pr={2} mt={0} mb={2}>
                                        <FormControl >
                                            <TextField
                                                rows={4}
                                                multiline={true}
                                                fullWidth={true}
                                                name='providerComment'
                                                label='Comments'
                                                aria-label='Comments'
                                                value={this.state.providerComment}
                                                onChange={this.handleChangeInput}
                                                inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                                                className='comment-TextField'
                                            />
                                        </FormControl>
                                    </Box>
                                    :
                                    ""
                            }
                        </DialogContent>

                        <DialogActions className="modal-actions" justify="center" >
                            <Button type="submit" className="btn1" disabled={this.state.loading}>
                                {this.state.loading && (
                                    <CircularProgress
                                        size={24}
                                        className="buttonProgress"
                                        color="secondary"
                                    />
                                )}
                                Submit
                            </Button>
                            <Button onClick={this.handleConfirmBox} className="cancel-link" >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Box display="flex" flexDirection="row" width={1} className="patient-list-page AwaitingProviderTabPanelCSS  patient-list-page-new patient-info-page-new FindProviderTab bundle-cover" style={{ 'backgroundColor': '#51C1C0' }}>

                    <Box pr={1} pl={1} width={1} className="left-content-box-bundle"  >
                        <div className="left-content-header" >
                            <Box display="flex" pr={2} className="divcontainer-tab refBoxInMiddle" mb={2} >
                                <Box pr={1} className="title-box" >
                                    <h2 className="titlename">{items.length} Possible Patients</h2>
                                </Box>
                                <FormGroup className="box-filter mr-0">
                                    <Box pl={1} pr={1} >
                                        <Tooltip title="Search By Name" placement="top" arrow>
                                            <Paper component="form" className="search-box-cover">
                                                <InputBase
                                                    className="search-box"
                                                    placeholder="Enter search here..."
                                                    onChange={this.searchPatient}
                                                    onKeyPress={this.searchReturn}
                                                />
                                                <IconButton className="" aria-label="search" onClick={this.searchPatientList}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Paper>
                                        </Tooltip>
                                    </Box>
                                </FormGroup>
                                <Box className="action-box-tab" display="flex" flexDirection="row" justifyContent="flex-end">
                                    <Box pl={1} className="action-filter" >
                                        <button className="pointer btn4" onClick={this.handleAllChecked}>{(items && items.length && items.length == totalSelectedProviders) ? "Deselect all" : "Select all"}({this.state.totalSelectedProviders})</button>
                                    </Box>
                                    <Box pl={1} className="action-filter" >
                                        <button className="pointer btn4" onClick={this.handleConfirmBox}>
                                            {this.state.referralLoader && (
                                                <CircularProgress
                                                    size={24}
                                                    className="buttonProgress"
                                                />
                                            )}
                                            Create Bundle
                                        </button>
                                    </Box>
                                </Box>

                            </Box>
                        </div>
                        {(items && items.length) ?
                            <Box width={1} className="left-content-list left-content-list-New">
                                <div className="PatientList">
                                    <Scrollbars style={{ height: "calc(100vh - 350px)" }} universal={true} className="YOnlytScrollbars BundleScroll">
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={this.fetchMoreData}
                                            hasMore={this.state.fetchMore}
                                            loader={<div className="loader" key={0}>Loading ...</div>}
                                            useWindow={false}
                                        >
                                            {items && items.length ? items
                                                .map((listItem, index) => {
                                                    let eastimatedDischargeDate = null;
                                                    if (listItem.eastimatedDischargeDate) {
                                                        eastimatedDischargeDate = moment.utc(listItem.eastimatedDischargeDate).format("MM/DD/YYYY")
                                                    }
                                                    return (
                                                        <Box width={1} display="flex" pr={2} flexDirection="row" mb={2} key={listItem.patientId}>
                                                            <div className="list-item-checkbox">
                                                                <Checkbox
                                                                    className="check-input"
                                                                    // checked={(listItem.isChecked) ? true : false}
                                                                    checked={(patientIds.includes(listItem.patientId)) ? true : false}
                                                                    onClick={e => this.getPatientId(listItem.patientId)}
                                                                />
                                                            </div>
                                                            <div className="list-item-content">
                                                                <Box className="list-item-content-data finding-box bundle-box" width={1} display="flex" flexDirection="row">
                                                                    <Box pr={1} className="">
                                                                        <div className="user-info-tab">
                                                                            <div className="name-bundle">
                                                                                {listItem.lastName + ', ' + listItem.firstName + ' ' + ((listItem.middleName != null) ? listItem.middleName : '')}

                                                                            </div>
                                                                            <div className="date-insurance">
                                                                                Est Discharge:&nbsp;
                                                                                <span className="bundle-val">{eastimatedDischargeDate}</span>
                                                                            </div>

                                                                        </div>
                                                                    </Box>
                                                                    <Box pr={1} pl={1} className="">
                                                                        <div className="user-info-tab">
                                                                            <div className="date-insurance">
                                                                                Insurance:&nbsp;
                                                                                {listItem.insuranceId > 0 ? this.state.insuranceList.map((insList, key) =>
                                                                                    (insList.insuranceId == listItem.insuranceId) && <span key={key} className="">{insList.insuranceName}</span>

                                                                                )
                                                                                    :
                                                                                    'No Insurance'
                                                                                }
                                                                            </div>
                                                                            {listItem.actualDischargeDate &&
                                                                                <div className="date-insurance">
                                                                                    Discharged:&nbsp;
                                                                                <span >{localDateFormat(listItem.actualDischargeDate)}</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </Box>
                                                                    <Box display="flex" flexDirection="row" justifyContent="center" className="AllReferrals-page referral-right-tags">
                                                                        {
                                                                            (listItem.patientCatagoryMapping.length && listItem.patientCatagoryMapping.map(category =>
                                                                                (category.categoryId == this.props.categoryId && category.providerSearchHistoryEntity != null) && this.renderTag(category.providerSearchHistoryEntity.search)
                                                                            ))
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            </div>
                                                        </Box>
                                                    )
                                                }
                                                ) : <div className="no-found-text">Not Found</div>}
                                        </InfiniteScroll>

                                    </Scrollbars>
                                </div>

                            </Box>
                            :
                            <Box pr={3} pl={2} width={1} display="flex" justifyContent="center"  >
                                <div className="no-found-text-bundle">Patient Not Found</div>
                            </Box>
                        }
                    </Box>

                </Box>
            </div>
        )
    }
}

export const BundlePatientList = connect(
    mapStateToProps, mapDispatchToProps
)(BundlePatientListClass);
export default BundlePatientList
