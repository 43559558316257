import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import {
  IconButton,
  Link as MaterialLink,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import { FormErrors } from "../../../components/Login/FormErrors";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import { formatDateToLocalFormat, downloadMedia } from "__helpers/util";

import { userService } from "_services/user.service";
import DateFnsUtils from "@date-io/date-fns";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import fileExtension from "file-extension";
import enMsg from "__helpers/locale/en/en";

import { Scrollbars } from "react-custom-scrollbars";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import Comment from "components/Comments/Comment";
import {
  REFERRAL_TYPE,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ALLOWED_FACESHEET_EXTENTION,
  MAX_INPUT_LENGTH_LONG,
} from "__helpers/constants";
import { localDateFormat } from "__helpers/util";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
class providerCommentModal extends Component {
  inputOpenFileRef = React.createRef(null);
  constructor(props) {
    super(props);
    this.dateUtility = new DateFnsUtils();
    this.state = {
      selectedPatientData: this.props.selectedPatientData
        ? this.props.selectedPatientData
        : "",
      facesheet: this.props.selectedPatientData.refferalPatientEntity[0]
        ? this.props.selectedPatientData.refferalPatientEntity[0].patientEntity
            .patientUploadHistory
        : "",
      patientIdKey: this.props.selectedPatientData.refferalPatientEntity[0]
        ? this.props.selectedPatientData.refferalPatientEntity[0].patientId
        : null,
      downloadingFiles: false,
      selectedPatientKey: this.props.selectedPatientKey,
      comment: "",
      formErrors: {
        comment: "",
        file: "",
      },
      fileName: "",
      file: "",
      fileBlob: "",
      fileValid: false,
      commentValid: false,
      commentsThread: [], //this.props.commentsThread,
      downloadSheets: [],
      downloadSheets: [],
      filesKey: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
  }
  downloadFacesheet = async () => {
    let showNotification = "";
    let faceSheetUrl = "";
    if (
      this.state.facesheet &&
      this.state.facesheet.length &&
      this.state.patientIdKey
    ) {
      this.setState({
        downloadingFiles: true,
      });
      let showNotification = {};
      if (this.state.filesKey == 0) {
        showNotification = {
          title: "Facesheet",
          message:
            "Downloading " +
            this.state.facesheet.length +
            " file(s). This process might take a long time, please be patient.",
          type: "infoLong",
        };
      }
      userService.showNotification(showNotification);
      this.setState({
        downloadingFiles: true,
      });

      let filesKey = this.state.filesKey;
      let downloadSheets = this.state.downloadSheets;
      let fileData = this.state.facesheet[filesKey];
      let urlArr = fileData.uploadUrl.split("/");
      let urlArrEndPoint =
        urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1];
      let sheetData = await userService.fetchFilesBlob(
        apiPath.downloadCommentFile +
          "?key=" +
          urlArrEndPoint +
          "&patientId=" +
          this.state.patientIdKey +
          "&refferalId=" +
          this.props.selectedPatientData.refferalId,
        fileData.uploadName
      );
      console.log(sheetData);
      if (sheetData) {
        let fileObj = {};
        fileObj.name = fileData.uploadName;
        fileObj.data = sheetData;
        downloadSheets.push(fileObj);
        filesKey = filesKey + 1;
        if (filesKey == this.state.facesheet.length) {
          this.setState(
            {
              downloadSheets: downloadSheets,
              filesKey: 0,
              downloadingFiles: false,
            },
            () => this.downloadFiles()
          );
        } else {
          this.setState(
            {
              downloadSheets: downloadSheets,
              filesKey: filesKey,
            },
            () => this.downloadFacesheet()
          );
        }
      } else {
        this.setState({
          downloadingFiles: false,
        });
      }
    } else {
      showNotification = {
        title: "Facesheet",
        message: "No facesheet available.",
        type: "danger",
      };
      userService.showNotification(showNotification);
      this.setState({
        downloadingFiles: false,
      });
    }
    return false;
  };
  downloadFiles() {
    var zip = new JSZip();
    if (this.state.downloadSheets && this.state.downloadSheets.length) {
      this.state.downloadSheets.map((fileData, key) => {
        zip.file(key + "_" + fileData.name, fileData.data);
      });
    }
    zip.generateAsync({ type: "blob" }).then(function(content) {
      // see FileSaver.js
      let fileName = "pateintFiles_" + Date.now() + ".zip";
      saveAs(content, fileName);
    });
    this.setState({
      downloadSheets: [],
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPatientData) {
      this.setState({
        facesheet: nextProps.selectedPatientData.refferalPatientEntity[
          nextProps.selectedPatientKey
        ]
          ? nextProps.selectedPatientData.refferalPatientEntity[
              nextProps.selectedPatientKey
            ].patientEntity.patientUploadHistory
          : "",
        selectedPatientKey: nextProps.selectedPatientKey,
      });
      this.fetchComments(this.props.selectedPatientData.refferalId);
    }
  }

  componentDidMount = () => {
    this.fetchComments(this.props.selectedPatientData.refferalId);
  };
  fetchComments = (referralId) => {};
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let commentValid = this.state.commentValid;
    switch (fieldName) {
      case "comment":
        let commentErr = "";
        if (fieldValue.trim().length == 0) {
          commentValid = false;
          commentErr = "Comment is required";
        } else if (fieldValue.trim().length > MAX_INPUT_LENGTH_LONG) {
          commentValid = false;
          commentErr = "Comment length cannot exceed 255 characters";
        } else {
          commentValid = true;
          commentErr = "";
        }
        fieldValidationErrors.comment = commentErr;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        commentValid: commentValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return this.state.commentValid || this.state.fileValid;
  }
  onFileBrowse = (event) => {
    this.inputOpenFileRef.current.click();
  };
  async handleSubmit(event) {
    event.preventDefault();
    let data = {
      base64Data: this.state.file,
      description: this.state.comment,
      fileName: this.state.fileName,
      receiverId: this.props.selectedPatientData.dischargeplannerEntity.id
        ? this.props.selectedPatientData.dischargeplannerEntity.id
        : "",
      refferalId: this.props.selectedPatientData.refferalId
        ? this.props.selectedPatientData.refferalId
        : "",
    };
    this.setState({ loading: true });
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(apiPath.postCommentByDP, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Comment",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Comment",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.id) {
          this.fetchComments(this.props.selectedPatientData.refferalId);
          this.setState({
            comment: "",
            fileName: "",
            file: "",
            commentValid: false,
            fileValid: false,
          });
          showNotification = {
            title: "Comment",
            message: "Message has been sent to the discharge planners",
            type: "success",
          };
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Comment",
          message: enMsg.clientSideError,
          type: "danger",
        };
      });
    userService.showNotification(showNotification);
    this.setState({ loading: false });
  }
  handleSelectedFile = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    if (typeof file === "undefined" && this.state.fileName == "") {
      this.setState({
        commentValid: false,
        fileValid: false,
      });
      return;
    }
    let fieldValidationErrors = this.state.formErrors;
    let error = "";
    let fileValid = false;
    if (typeof file == "object" && file.size > ALLOWED_PROFILE_IMAGE_SIZE) {
      error = enMsg.allowedFaceSheetSize;
    } else if (
      typeof file == "object" &&
      !ALLOWED_FACESHEET_EXTENTION.includes(file.type)
    ) {
      error = enMsg.allowedFaceSheetType;
    } else {
      fileValid = true;
    }

    if (typeof file !== undefined && fileValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        this.setState({
          fileName: file.name,
          file: result,
        });
        this.fileBlob = file;
        this.filename = new Date().getTime() + "." + fileExtension(file.name);
      });
      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
    if (!fileValid) {
      this.setState({
        fileName: "",
      });
    }

    fieldValidationErrors.file = fileValid ? "" : error;
    this.setState(
      {
        fileValid: fileValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };
  downloadFile = async (fileName, fileUrl) => {
    this.setState({
      loading: true,
    });
    let showNotification = {};
    try {
      downloadMedia(fileUrl, fileName);
    } catch (error) {
      showNotification = {
        title: "Facesheet",
        message: "No facesheet available.",
        type: "danger",
      };
      userService.showNotification(showNotification);
    }
    this.setState({
      loading: false,
    });
    return false;
  };
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  render() {
    const { selectedPatientKey, selectedPatientData } = this.state;
    return (
      <Dialog
        open={this.props.status}
        maxWidth={"sm"}
        onClose={this.props.close}
        TransitionComponent={Transition}
        aria-labelledby="decline-referral-title"
        className="add-modal front-modal CommentModal"
      >
        <DialogTitle className="CommentModal-title" id="decline-referral-title">
          <Box display="flex" flexDirection="row" className="CommentModal-info">
            <div>
              <div className="ref-info">Referral ID</div>
              <div className="ref-val">
                {this.props.selectedPatientData.refferalId}
              </div>
            </div>
            <div>
              <div className="ref-info">Referral Sent</div>
              <div className="ref-val">
                {this.dateUtility.format(
                  new Date(this.props.selectedPatientData.refferalSendTime),
                  "MM/dd/yyyy HH:mm:ss"
                )}
              </div>
            </div>
            <div>
              <div className="ref-info">Accepted</div>
              <div className="ref-val">
                {this.dateUtility.format(
                  new Date(
                    this.props.selectedPatientData.refferalProviderEntity[0].formattedUpdatedDateTime
                  ),
                  "MM/dd/yyyy HH:mm:ss"
                )}
              </div>
            </div>
          </Box>
          <IconButton
            className="closeButton"
            aria-label="close"
            onClick={this.props.close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box className="AllReferrals-page" display="flex" flexDirection="row">
            <div className={`list-item-content-Ref no-shadow`}>
              <Box
                className="AllRef-content"
                display="flex"
                flexDirection="row"
                width={1}
              >
                <Box pr={1}>
                  <div className="patient-info-Ref">
                    {this.props.selectedPatientData.refferalPatientEntity[
                      selectedPatientKey
                    ] && (
                      <div className="name">
                        {this.props.selectedPatientData.refferalPatientEntity[
                          selectedPatientKey
                        ].patientEntity.lastName &&
                          this.props.selectedPatientData.refferalPatientEntity[
                            selectedPatientKey
                          ].patientEntity.lastName + ", "}
                        {
                          this.props.selectedPatientData.refferalPatientEntity[
                            selectedPatientKey
                          ].patientEntity.firstName
                        }
                      </div>
                    )}
                    {this.props.selectedPatientData.refferalPatientEntity[
                      selectedPatientKey
                    ] && (
                      <div className="info-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8.75"
                          height="10"
                          viewBox="0 0 8.75 10"
                        >
                          <path
                            id="calendar-alt"
                            d="M8.516,3.125H.234A.235.235,0,0,1,0,2.891v-.7A.938.938,0,0,1,.938,1.25h.938V.234A.235.235,0,0,1,2.109,0h.781a.235.235,0,0,1,.234.234V1.25h2.5V.234A.235.235,0,0,1,5.859,0h.781a.235.235,0,0,1,.234.234V1.25h.938a.938.938,0,0,1,.938.938v.7A.235.235,0,0,1,8.516,3.125ZM.234,3.75H8.516a.235.235,0,0,1,.234.234V9.063A.938.938,0,0,1,7.813,10H.938A.938.938,0,0,1,0,9.063V3.984A.235.235,0,0,1,.234,3.75ZM2.5,7.734A.235.235,0,0,0,2.266,7.5H1.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,2.5,8.516Zm0-2.5A.235.235,0,0,0,2.266,5H1.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,2.5,6.016ZM5,7.734A.235.235,0,0,0,4.766,7.5H3.984a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,5,8.516Zm0-2.5A.235.235,0,0,0,4.766,5H3.984a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,5,6.016Zm2.5,2.5A.235.235,0,0,0,7.266,7.5H6.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,7.5,8.516Zm0-2.5A.235.235,0,0,0,7.266,5H6.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,7.5,6.016Z"
                            fill="#666"
                          />
                        </svg>
                        &nbsp;
                        {this.props.selectedPatientData.refferalPatientEntity[
                          selectedPatientKey
                        ].patientEntity.actualDischargeDate
                          ? "Discharged: "
                          : "Est Discharge: "}
                        {this.props.selectedPatientData.refferalPatientEntity[
                          selectedPatientKey
                        ].patientEntity.actualDischargeDate
                          ? localDateFormat(
                              this.props.selectedPatientData
                                .refferalPatientEntity[selectedPatientKey]
                                .patientEntity.actualDischargeDate
                            )
                          : this.props.selectedPatientData
                              .refferalPatientEntity[selectedPatientKey]
                              .patientEntity.formattedEstimatedDischargeDate
                          ? this.props.selectedPatientData
                              .refferalPatientEntity[selectedPatientKey]
                              .patientEntity.formattedEstimatedDischargeDate
                          : "N/A"}
                      </div>
                    )}
                  </div>
                </Box>

                <Box pr={1} className="Ref-Hospital-Patients comentmodal">
                  {this.props.selectedPatientData.refferalPatientEntity[
                    selectedPatientKey
                  ] && (
                    <div className="info-text">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.75"
                        height="10"
                        viewBox="0 0 11.127 10"
                      >
                        <path
                          id="medical_bag"
                          data-name="medical bag"
                          d="M6.438,3,5.327,4.111V5.222H3.66A1.181,1.181,0,0,0,2.549,6.333l-.556,5.556A1,1,0,0,0,3.1,13h8.889A1,1,0,0,0,13.1,11.889l-.556-5.556a1.226,1.226,0,0,0-1.111-1.111H9.771V4.111L8.66,3Zm0,1.111H8.66V5.222H6.438Zm.556,2.778H8.1V8.556H9.771V9.667H8.1v1.667H6.994V9.667H5.327V8.556H6.994Z"
                          transform="translate(-1.986 -3)"
                          fill="#666"
                        />
                      </svg>
                      &nbsp; Insurance:{" "}
                      {this.props.insuranceList.map(
                        (insuranceList) =>
                          insuranceList.insuranceId ==
                            this.props.selectedPatientData
                              .refferalPatientEntity[selectedPatientKey]
                              .patientEntity.insuranceId &&
                          insuranceList.insuranceName
                      )}
                    </div>
                  )}
                  {this.props.selectedPatientData.refferalPatientEntity[
                    selectedPatientKey
                  ] &&
                    this.props.selectedPatientData.refferalPatientEntity[
                      selectedPatientKey
                    ].patientEntity.patientStatusId == 3 && (
                      <div className="info-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8.75"
                          height="10"
                          viewBox="0 0 8.75 10"
                        >
                          <path
                            id="calendar-alt"
                            d="M8.516,3.125H.234A.235.235,0,0,1,0,2.891v-.7A.938.938,0,0,1,.938,1.25h.938V.234A.235.235,0,0,1,2.109,0h.781a.235.235,0,0,1,.234.234V1.25h2.5V.234A.235.235,0,0,1,5.859,0h.781a.235.235,0,0,1,.234.234V1.25h.938a.938.938,0,0,1,.938.938v.7A.235.235,0,0,1,8.516,3.125ZM.234,3.75H8.516a.235.235,0,0,1,.234.234V9.063A.938.938,0,0,1,7.813,10H.938A.938.938,0,0,1,0,9.063V3.984A.235.235,0,0,1,.234,3.75ZM2.5,7.734A.235.235,0,0,0,2.266,7.5H1.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,2.5,8.516Zm0-2.5A.235.235,0,0,0,2.266,5H1.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,2.5,6.016ZM5,7.734A.235.235,0,0,0,4.766,7.5H3.984a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,5,8.516Zm0-2.5A.235.235,0,0,0,4.766,5H3.984a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,5,6.016Zm2.5,2.5A.235.235,0,0,0,7.266,7.5H6.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,7.5,8.516Zm0-2.5A.235.235,0,0,0,7.266,5H6.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,7.5,6.016Z"
                            fill="#666"
                          />
                        </svg>
                        &nbsp; Discharged:{" "}
                        {
                          this.props.selectedPatientData.refferalPatientEntity[
                            selectedPatientKey
                          ].patientEntity.formattedEstimatedDischargeDate
                        }
                      </div>
                    )}
                </Box>

                <Box className="referral-right-tags">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    {this.props.searchCriteria &&
                      this.props.searchCriteria.map((criteria, key) => (
                        <div key={key} className={`tag ref-tag mr-10`}>
                          {criteria}
                        </div>
                      ))}
                  </Box>
                </Box>

                {!this.props.selectedPatientData.refferalTransportEntity && (
                  <Box pr={1}>
                    <Button
                      onClick={this.downloadFacesheet}
                      className="btn1 down_face"
                      disabled={this.state.downloadingFiles ? true : false}
                    >
                      Download documents
                      {this.state.downloadingFiles ? (
                        <div class="download_loader">
                          <div class="preloader">
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                            <span class="loader_span"></span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Button>
                  </Box>
                )}

                <Box>
                  <div className="Ref-categorybox">
                    <div className="category-value">
                      {this.props.selectedPatientData.categoryId &&
                        this.props.categoryList.map((el) => {
                          if (
                            el.id == this.props.selectedPatientData.categoryId
                          ) {
                            return el.categoryValue;
                          }
                        })}
                    </div>
                  </div>
                </Box>
              </Box>
            </div>
          </Box>
          {this.props.selectedPatientData.refferalProviderEntity[0]
            .masterRefferalStatusEntity.id == 3 && (
            <div className="comments-section PopComment NoMargin">
              <br />
              <div className="comment-title">
                Comments -{" "}
                {this.props.selectedPatientData.categoryId &&
                  this.props.categoryList.map((el) => {
                    if (el.id == this.props.selectedPatientData.categoryId) {
                      return el.categoryValue;
                    }
                  })}
              </div>
              {this.props.selectedPatientData &&
                this.props.selectedPatientData.refferalPatientEntity[
                  this.state.selectedPatientKey
                ] && (
                  <Comment
                    referralId={
                      this.props.selectedPatientData.refferalId
                        ? this.props.selectedPatientData.refferalId
                        : ""
                    }
                    receiverId={
                      this.props.selectedPatientData.dischargeplannerEntity.id
                        ? this.props.selectedPatientData.dischargeplannerEntity
                            .id
                        : ""
                    }
                    patientId={
                      this.props.selectedPatientData.refferalPatientEntity[
                        this.state.selectedPatientKey
                      ].patientId
                    }
                    prelimComment={
                      selectedPatientData &&
                      selectedPatientData.refferalProviderEntity &&
                      selectedPatientData.refferalProviderEntity.length &&
                      selectedPatientData.refferalProviderEntity[0]
                        .refferalProviderComment &&
                      selectedPatientData.refferalProviderEntity[0]
                        .refferalProviderComment.length
                        ? selectedPatientData.refferalProviderEntity[0]
                            .refferalProviderComment
                        : []
                    }
                  />
                )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          className="modal-actions"
          justify="center"
        ></DialogActions>
      </Dialog>
    );
  }
}

export default providerCommentModal;
