
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import Poppers from "@material-ui/core/Popper";
import {
  Icon,
  styles,
  MenuItem,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
  Hidden,
  Divider,
  withStyles,
  Avatar,
  Select,
  FormGroup,
  InputLabel,
  FormControl,
  Input,
  CircularProgress
} from "@material-ui/core/";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import SelectAllTwoTone from "@material-ui/icons/SelectAllTwoTone";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import dummy from "assets/img/dummy.png";
import { sizeHeight } from "@material-ui/system";
import { Link, useHistory } from "react-router-dom";
import { baseRoutes, basePath } from "base-routes";
import { layout } from "admin-routes";
import { userService } from "_services/user.service";
import { DEFAULT_PROFILE_IMG, NO_USERNAME } from "__helpers/constants";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import { STORED_ADNETWORK, DATA_LOADING } from "__helpers/constants";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import dropdown from "assets/img/dropdown.png";
import { withRouter } from 'react-router'

import { connect } from "react-redux";
import {
  addUSerUInfo,
  categoryList,
  insuranceList,
  patientStatusList,
  hospitalList,
  getPatientLanNInsList,
  addTimeStamp,
  reduxLoad,
  patientFilterList,
  providerStatusList,
  facilityList
} from "../../js/actions/index";
import { ANALYTICS_CSV_DATA } from "__helpers/constants";
import { ANALYTICS_CSV_ADVERTISEMENT_DATA } from "__helpers/constants";
import { empty } from "joi";
import { USER_INFO, PROVIDER } from "__helpers/constants";
import { apiPath } from "api";
import ChangePassword from "views/Profile/ChangePassword";
import TimerLoader from "layouts/TimerLoader";

function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: addUSerUInfoVal => dispatch(addUSerUInfo(addUSerUInfoVal)),
    categoryList: categoryListVal => dispatch(categoryList(categoryListVal)),
    insuranceList: insuranceListVal => dispatch(insuranceList(insuranceListVal)),
    patientStatusList: patientStatusListVal => dispatch(patientStatusList(patientStatusListVal)),
    hospitalList: hospitalListVal => dispatch(hospitalList(hospitalListVal)),
    facilityList: facilityListVal => dispatch(facilityList(facilityListVal)),
    patientFilterList: patientFilterListVal => dispatch(patientFilterList(patientFilterListVal)),
    providerStatusList: providerStatusListVal => dispatch(providerStatusList(providerStatusListVal)),
    getPatientLanNInsList: getPatientLanNInsListVal => dispatch(getPatientLanNInsList(getPatientLanNInsListVal)),
    addTimeStamp: addTimeStampVal => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    userInfo: state.userInfo,
    adNetwork: state.adNetwork,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class GlobalApiFn extends React.Component {
  constructor(props) {
    super(props);
    let adNetworksData = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    let selectedAdnetworkData = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    let selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
    this._isMounted = false;
    let spinner = document.getElementById('loadingSpinner');
    this.state = {
      openNotifcation: false,
      adnetworkId: selectedAdnetwork ? selectedAdnetwork : "",
      adNetworkJson: (adNetworksData) ? adNetworksData : [],
      adnetworkData: (selectedAdnetworkData) ? selectedAdnetworkData : [],
      loadSpinner: false,
      spinner: spinner,
      loading: false,

      articles: [],
      userInfo: [],
      adNetwork: [],
      loadingFlag: false,
      adCategories: [],
      adBrands: [],
      adStatus: [],
      adMedium: [],
      adType: [],
      assetType: [],
      assetDisplayType: [],
      campaignStatus: [],
      advertisements: [],
      campaings: [],
      assets: [],
      vendors: [],
      timestamp: "",
      reduxLoadFlag: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
    Object.getPrototypeOf(this).constructor.STATE = this.state;
  }
  componentDidUpdate() {
    if (this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let selectedAdnetwork = "";
      let adnetworkData = [];
      if (this.props.adNetwork) {
        let selectedAdnetworkData = this.props.adNetwork;
        selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
        adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
      }
      this.setState({
        articles: this.props.articles,
        userInfo: this.props.userInfo,
        adNetwork: this.props.adNetwork,
        loadingFlag: this.props.loadingFlag,
        adCategories: this.props.adCategories,
        adBrands: this.props.adBrands,
        adStatus: this.props.adStatus,
        adMedium: this.props.adMedium,
        adType: this.props.adType,
        assetType: this.props.assetType,
        assetDisplayType: this.props.assetDisplayType,
        campaignStatus: this.props.campaignStatus,
        advertisements: this.props.advertisements,
        campaings: this.props.campaings,
        assets: this.props.assets,
        vendors: this.props.vendors,
        timestamp: this.props.timestamp,
        reduxLoadFlag: this.props.reduxLoadFlag,
        adnetworkId: selectedAdnetwork,
        adnetworkData: adnetworkData,
        openModal: false,
        insuranceList: [],
        categoryList: [],
        hospitalList: [],
        facilityList: [],
        patientStatusList: [],
        getPatientLanNIns: [],
      })
    }
  }
  componentDidMount() {
    this._isMounted = true;
    let dataLoadingFlag = JSON.parse(localStorage.getItem(DATA_LOADING));
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    if (!dataLoadingFlag || !userInfo) {
      setTimeout(function () {
        this.fetchData();
      }.bind(this), 300);
    } else {
      this.state.spinner.setAttribute('hidden', 'true');
    }
    this.setState({
      userInfo: this.props.userInfo,
      loadingFlag: this.props.loadingFlag,
      timestamp: this.props.timestamp,
      reduxLoadFlag: this.props.reduxLoadFlag,
    })
    this.state.spinner.setAttribute('hidden', 'true');

    let cookie = document.cookie.split(';');
    this.fetchGlobalData();
  }
  async getProfileData() {
    this.state.spinner.removeAttribute('hidden', 'true');
    let dataLoadingFlag = JSON.parse(localStorage.getItem(DATA_LOADING));
    // localStorage.setItem(DATA_LOADING, true);
    this.setState({ reduxLoadFlag: false })
    this.props.reduxLoad(false);
    let userResp = await userService.fetchProfile();
    if (userResp && userResp.firstName) {
      // localStorage.setItem(DATA_LOADING, true);
      let firstName = userResp.firstName;
      let lastName = userResp.lastName;
      let imageRef = userResp.imageUrl;
      let createdById = userResp.id;
      let userInfo = {
        firstName: firstName,
        lastName: lastName,
        imageRef: imageRef,
        createdById: createdById,
      };
      this.props.addUSerUInfo(userInfo);
      localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
    } else {
      localStorage.setItem(DATA_LOADING, false);
    }
    // this.props.addUSerUInfo(users);
  }
  async fetchData() {
    this.props.reduxLoad(false);
    let user = JSON.parse(localStorage.getItem(USER_INFO));
    // this.state.spinner.removeAttribute('hidden', 'true');
    this.setState({ reduxLoadFlag: false })
    try {
      let hospitalListLocal = []
      let insuranceList = await userService.fetchGlobalApis(apiPath.getInsurance);
      let categoryList = await userService.fetchGlobalApis(apiPath.getCategory);
      let hospitalList = await userService.fetchGlobalApis(apiPath.getHospitalList);
      let facilityList = await userService.fetchGlobalApis(apiPath.getActiveFacilities);
      let patientStatusList = await userService.fetchGlobalApis(apiPath.getPatientStatus);
      let getPatientLanNIns = await userService.fetchGlobalApis(apiPath.getPatientLanNIns);
      let patientFilterList = await userService.fetchGlobalApis(apiPath.patientFilters);
      let providerStatusList = await userService.fetchGlobalApis(apiPath.providerStatus);
      this.props.categoryList(insuranceList);
      this.props.insuranceList(categoryList);
      this.props.hospitalList(hospitalList);
      this.props.facilityList(facilityList);
      this.props.patientStatusList(patientStatusList);
      this.props.getPatientLanNInsList(getPatientLanNIns);
      this.props.patientFilterList(patientFilterList);
      this.props.providerStatusList(providerStatusList);

      setTimeout(function () {
        this.props.reduxLoad(true);
        // localStorage.setItem(DATA_LOADING, true);
        this.state.spinner.setAttribute('hidden', 'true');
        localStorage.setItem(DATA_LOADING, true);
      }.bind(this), 50);
      if (insuranceList.length &&
        categoryList.length &&
        hospitalList.length &&
        patientStatusList.length &&
        getPatientLanNIns.length) {

      }
    } catch (errors) {
      localStorage.setItem(DATA_LOADING, false);
    }
  }
  async fetchGlobalData() {
    let user = JSON.parse(localStorage.getItem(USER_INFO));
    if (user.usrType == PROVIDER) {
      let providerStatus = await userService.fetchBannerText();
      if (providerStatus) {
        localStorage.setItem('provider_banner_text', JSON.stringify(providerStatus));
      } else {
        localStorage.setItem('provider_banner_text', '');
      }
    }
  }
  handleClose() {
  }
  render() {
    const { classes } = this.props;
    const { openNotifcation, openProfile, adnetworkId, adnetworkData, adNetworkJson } = this.state;
    const user_image = "user-image";
    const styleuser = {
      borderRadius: "50%",
      width: "35px",
      height: "35px"
    };
    const userNameStyle = {
      float: "right",
      display: "block",
      width: "calc(100% - 50px)",
      color: "#000",
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "normal",
      marginTop: "10px"
    };
    const logoutStyle = {
      float: "right",
      display: "block",
      width: "calc(100% - 50px)",
      color: "#2b73cd",
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "normal",
      marginTop: "4px"
    };
    return (
      <div className="display-none storage-observation">
        <TimerLoader />
      </div>
    );
  }
}

GlobalApiFn.propTypes = {
  classes: PropTypes.object
};

const GlobalApi = connect(
  mapStateToProps, mapDispatchToProps
)(GlobalApiFn);
export default withStyles(headerLinksStyle)(GlobalApi);
