/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Box,
  TextField,
  Avatar,
  Checkbox,
  LinearProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";
import PhoneNumber from "awesome-phonenumber";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import cvvimg from "assets/img/icons/cvvimg.png";
import {
  NotificationOptions,
  genderType,
  EMAIL_REGEX,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ZIPCODE_REGEX,
  ZIP_CODE_LENGTH,
  MIN_AGE_YEARS,
  ALLOWED_IMAGE_EXTENTION,
  DEFAULT_PROFILE_IMG,
  DIGIT_ONLY,
  PHONE_NO_INVALID_LENGTH,
  OK_SUCCESS_STATUS,
  STATUS_CREATED,
  INVALID_DATA_POST,
  SECURITY_ERROR,
  CREDIT_CARD_MAX_YEAR,
  MAX_CREDIT_CARD_LENGTH,
} from "__helpers/constants";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import baseRoutes from "base-routes";
import ChangePassword from "./ChangePassword";
import fileExtension from "file-extension";
import { generalAuthTokenHeader } from "__helpers/auth-header";

import { withRouter } from "react-router";

import { connect } from "react-redux";
import { addUSerUInfo, reduxLoad } from "js/actions";
import { USER_INFO } from "__helpers/constants";
import { dpBaseRoutes } from "base-routes";
import { basePath } from "base-routes";
import backArrow from "assets/img/icons/arrow-back.svg";
import BasicProfile from "./BasicProfile";
import InDevelopment from "./InDevlopment";
import commingSoonImg from "assets/img/comming_soon.png";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { MAX_INPUT_LENGTH_RATE, MAX_INPUT_LENGTH } from "__helpers/constants";
import { enGB } from "date-fns/esm/locale";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroller";
import { providerBaseRoutes } from "base-routes";
import MaskInput from "react-maskinput";

import ConfirmModal from "components/Modal/modal";
function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: (addUSerUInfoVal) => dispatch(addUSerUInfo(addUSerUInfoVal)),
    addTimeStamp: (addTimeStampVal) => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    getPatientLanNInsListRedux: state.getPatientLanNInsList,
    hospitalListRedux: state.hospitalList,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const styles = (theme) => ({});
const cardTypeIcons = {
  unknown: "fa fa-credit-card",
  jcb: "fa fa-cc-jcb",
  amex: "fa fa-cc-amex",
  diners_club: "fa fa-cc-diners-club",
  visa: "fa fa-cc-visa",
  mastercard: "fa fa-cc-mastercard",
  discover: "fa fa-cc-discover",
  maestro: "fa fa-cc-mastercard",
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
class SubscriptionModuleClass extends React.Component {
  constructor(props) {
    super(props);
    /* create Ref for file input  */
    let masterLanguages = [];
    if (
      props.getPatientLanNInsListRedux &&
      props.getPatientLanNInsListRedux.masterLanguages
    ) {
      masterLanguages = props.getPatientLanNInsListRedux.masterLanguages;
    }
    let categoryParents = {};
    if (
      typeof props.categoryListRedux === "object" &&
      props.categoryListRedux.length
    ) {
      props.categoryListRedux.map((cList, key) => {
        const { catagoryId, parent } = cList;
        categoryParents[catagoryId] = parent;
      });
    }
    this.state = {
      loading: false,
      userInfo: props.userInfo,
      getPatientLanNInsList: props.getPatientLanNInsListRedux,
      reduxLoadFlag: false,
      selectedInsuranceList: [],
      selectedLanguageList: [],
      selectedInfectious: null,
      selectedMainCatArr: [],
      masterLanguages: [],
      categoryParents: categoryParents,
      categoryList: props.categoryListRedux,
      loadMore: false,
      currentItemPage: 0,
      displayItems: 50,
      cardCvv: "",
      cardMM: "",
      cardYear: "",
      cardNumber: "",
      cardCvvValid: true,
      cardMMValid: true,
      cardYearValid: true,
      cardNumberValid: true,
      formErrors: {
        cardCvv: "",
        cardMM: "",
        cardYear: "",
        cardNumber: "",
      },
      cardTypeIcons: "unknown",
      openConfirmationBox: false,
      dialogMessage: "",
      dialogType: null,
      providerFacility: {},
      cardSaved: false,
      getPaymentStatus: {},
      cardPlaceholder: "xxxx-xxxx-xxxx-xxxx",
      invitations: [],
      confirmModalStatus: false,
      confirmModalMessage: "",
      invitationId: "",
      acceptOrDecline: "",
      cardTextBoxEnable: true,
    };
    // this.selectProfileTab = this.selectProfileTab.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitCategory = this.handleSubmitCategory.bind(this);
    this.loadMoreData = this.loadMoreData.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeInputCardNumber = this.handleChangeInputCardNumber.bind(
      this
    );
    this.handleChangeInputDate = this.handleChangeInputDate.bind(this);
    this.checkCardExp = this.checkCardExp.bind(this);
    this.handleCategorySelection = this.handleCategorySelection.bind(this);

    this.handleConfirmBox = this.handleConfirmBox.bind(this);
    this.handleSubmitCardBox = this.handleSubmitCardBox.bind(this);
    this.handleSubmitCategoryBox = this.handleSubmitCategoryBox.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.acceptOrDeclineInvitation = this.acceptOrDeclineInvitation.bind(this);
  }
  componentDidMount() {
    // this.fetchMoreData();
    this.setState(
      {
        loading: true,
      },
      () => this.fetchFn()
    );
    this.fetchProvidersInvitations();
  }
  fetchProvidersInvitations = () => {
    const response = fetch(apiPath.fetchInvitationForProvider, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        if (data.length) {
          this.setState({
            invitations: data,
          });
        } else {
          this.setState({
            invitations: [],
          });
        }
      })
      .catch((error) => {
        return response;
      });
  };
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;

    let cardCvvValid = this.state.cardCvvValid;
    let cardMMValid = this.state.cardMMValid;
    let cardYearValid = this.state.cardYearValid;
    let cardNumberValid = this.state.cardNumberValid;
    switch (fieldName) {
      case "cardNumber":
        console.log(fieldValue.trim().length);
        if (fieldValue.trim().length) {
          cardNumberValid = fieldValue.trim().length >= 14;
          fieldValidationErrors.cardNumber = cardNumberValid
            ? ""
            : enMsg.cardNumberInvalidMsg;
        } else {
          //cardNumberValid = fieldValue.trim().length > 0;
          cardNumberValid = true;
        }
        break;
      case "cardMM":
        cardMMValid =
          (fieldValue && fieldValue.trim().length > 0) || fieldValue == ""
            ? true
            : true;
        fieldValidationErrors.cardMM = "";
        cardMMValid = true;
        break;
      case "cardYear":
        cardMMValid = fieldValue.trim().length > 0;
        // fieldValidationErrors.cardMM = cardMMValid
        //   ? ""
        //   : enMsg.cardDateRequiredMsg;
        fieldValidationErrors.cardMM = "";
        cardMMValid = true;
        break;
      case "cardCvv":
        if (fieldValue && fieldValue.trim().length) {
          cardCvvValid = fieldValue.length >= 3;
          fieldValidationErrors.cardCvv = cardCvvValid
            ? ""
            : enMsg.cardCvvInvalidMsg;
        } else {
          cardCvvValid = fieldValue.length > 0;
          // fieldValidationErrors.cardCvv = cardCvvValid
          //   ? ""
          //   : enMsg.cardCvvRequiredMsg;
          fieldValidationErrors.cardCvv = "";
          cardCvvValid = true;
        }
        break;
      default:
        break;
    }
    console.log(
      fieldValidationErrors,
      cardCvvValid,
      cardMMValid,
      cardYearValid,
      cardNumberValid
    );
    this.setState(
      {
        formErrors: fieldValidationErrors,
        cardCvvValid: cardCvvValid,
        cardMMValid: cardMMValid,
        cardYearValid: cardYearValid,
        cardNumberValid: cardNumberValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return (
      this.state.cardCvvValid &&
      this.state.cardMMValid &&
      this.state.cardYearValid &&
      this.state.cardNumberValid
    );
  }
  handleChangeInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const re = /^\d+\.?\d{0,2}$/;
    if (value === "" || re.test(value)) {
      value = value;
    } else {
      if (re.test(this.state[name])) value = this.state[name];
      else value = "";
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  handleChangeInputCardNumber = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    value = value.replace(/-/g, "");
    const re = /^\d+\.?\d{0,2}$/;

    if (value === "" || re.test(value)) {
      this.state.cardTextBoxEnable = false;
      this.state.cardCvvValid = false;
      this.state.cardMMValid = false;
      this.state.cardYearValid = false;
      this.state.cardNumberValid = false;
      value = value;
    } else {
      if (re.test(this.state[name])) value = this.state[name];
      else value = 0;
    }
    let cardTypeType = this.cc_brand_id(value);
    if (value == "") {
      this.state.formErrors = {
        cardCvv: "",
        cardMM: "",
        cardYear: "",
        cardNumber: "",
      };
      this.state.cardTextBoxEnable = true;
      this.state.cardCvv = "";
      this.state.cardMM = "";
      this.state.cardYear = "";
      this.state.cardCvvValid = true;
      this.state.cardMMValid = true;
      this.state.cardYearValid = true;
      this.state.cardNumberValid = true;
    }
    this.setState(
      {
        [name]: value,
        cardTypeIcons: cardTypeType,
      },
      () => {
        this.validateField(name, value);
      }
    );

    console.log(
      this.state.cardNumberValid,
      this.state.cardMMValid,
      this.state.cardYearValid,
      this.state.cardNumberValid,
      this.state.formErrors
    );
  };
  handleChangeInputDate = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const re = /^\d+\.?\d{0,2}$/;
    if (value === "" || re.test(value)) {
      value = value;
    } else {
      if (re.test(this.state[name])) value = this.state[name];
      else value = 0;
    }
    this.setState(
      {
        [name]: value,
      },
      () => this.checkCardExp()
    );
  };
  normalizeYear(year) {
    var YEARS_AHEAD = CREDIT_CARD_MAX_YEAR;
    if (year < 100) {
      var nowYear = new Date().getFullYear();
      year += Math.floor(nowYear / 100) * 100;
      if (year > nowYear + YEARS_AHEAD) {
        year -= 100;
      } else if (year <= nowYear - 100 + YEARS_AHEAD) {
        year += 100;
      }
    }
    return year;
  }
  checkCardExp() {
    var dateValue = this.state.cardMM + "/" + this.state.cardYear;
    var match = dateValue.match(/^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/);
    if (!match) {
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors.cardMM = enMsg.cardDateInvalidMsg;
      this.setState({
        cardMMValid: false,
        formErrors: fieldValidationErrors,
      });
      return;
    }
    var exp = new Date(
      this.normalizeYear(1 * match[2]),
      1 * match[1] - 1,
      1
    ).valueOf();
    var now = new Date();
    var currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
    let yearVal = +this.state.cardYear;
    var nowYear = new Date().getFullYear();
    if (this.state.cardYear.trim().length >= 3) {
      if (exp < currMonth) {
        let fieldValidationErrors = this.state.formErrors;
        fieldValidationErrors.cardMM = enMsg.cardDateExpiredMsg;
        this.setState({
          cardMMValid: false,
          formErrors: fieldValidationErrors,
        });
      } else if (yearVal > nowYear + CREDIT_CARD_MAX_YEAR) {
        let fieldValidationErrors = this.state.formErrors;
        fieldValidationErrors.cardMM = enMsg.cardDateMaxMsg;
        this.setState({
          cardMMValid: false,
          formErrors: fieldValidationErrors,
        });
      } else {
        let fieldValidationErrors = this.state.formErrors;
        fieldValidationErrors.cardMM = "";
        fieldValidationErrors.cardYear = "";
        this.setState({
          cardMMValid: true,
          cardYearValid: true,
          formErrors: fieldValidationErrors,
        });
      }
    } else {
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors.cardMM = enMsg.cardDateInvalidMsg;
      this.setState({
        cardMMValid: false,
        formErrors: fieldValidationErrors,
      });
    }
  }
  cc_brand_id(cur_val) {
    // the regular expressions check for possible matches as you type, hence the OR operators based on the number of chars
    // regexp string length {0} provided for soonest detection of beginning of the card numbers this way it could be used for BIN CODE detection also

    //JCB
    let jcb_regex = new RegExp("^(?:2131|1800|35)[0-9]{0,}$"); //2131, 1800, 35 (3528-3589)
    // American Express
    let amex_regex = new RegExp("^3[47][0-9]{0,}$"); //34, 37
    // Diners Club
    let diners_regex = new RegExp("^3(?:0[0-59]{1}|[689])[0-9]{0,}$"); //300-305, 309, 36, 38-39
    // Visa
    let visa_regex = new RegExp("^4[0-9]{0,}$"); //4
    // MasterCard
    let mastercard_regex = new RegExp(
      "^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$"
    ); //2221-2720, 51-55
    let maestro_regex = new RegExp("^(5[06789]|6)[0-9]{0,}$"); //always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
    //Discover
    let discover_regex = new RegExp(
      "^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$"
    );
    ////6011, 622126-622925, 644-649, 65

    // get rid of anything but numbers
    cur_val = cur_val.replace(/\D/g, "");

    // checks per each, as their could be multiple hits
    //fix: ordering matter in detection, otherwise can give false results in rare cases
    var sel_brand = "unknown";
    if (cur_val.match(jcb_regex)) {
      sel_brand = "jcb";
    } else if (cur_val.match(amex_regex)) {
      sel_brand = "amex";
    } else if (cur_val.match(diners_regex)) {
      sel_brand = "diners_club";
    } else if (cur_val.match(visa_regex)) {
      sel_brand = "visa";
    } else if (cur_val.match(mastercard_regex)) {
      sel_brand = "mastercard";
    } else if (cur_val.match(discover_regex)) {
      sel_brand = "discover";
    } else if (cur_val.match(maestro_regex)) {
      if (cur_val[0] == "5") {
        //started 5 must be mastercard
        sel_brand = "mastercard";
      } else {
        sel_brand = "maestro"; //maestro is all 60-69 which is not something else, thats why this condition in the end
      }
    }
    return sel_brand;
  }
  fetchMoreData() {
    const {
      loadMore,
      getPatientLanNInsList,
      currentItemPage,
      displayItems,
      masterLanguages,
    } = this.state;
    let totalItems =
      getPatientLanNInsList.masterLanguages &&
      getPatientLanNInsList.masterLanguages.length
        ? getPatientLanNInsList.masterLanguages.length
        : 0;
    let totalItemList =
      getPatientLanNInsList.masterLanguages &&
      getPatientLanNInsList.masterLanguages.length
        ? getPatientLanNInsList.masterLanguages
        : [];
    let currentItems = masterLanguages.lenght;

    const startIndex = currentItemPage * displayItems;
    const endIndex = startIndex + displayItems;
    let currentItemsList = [];
    totalItemList.map((itemList, key) => {
      if (key >= startIndex && key < endIndex) {
        currentItemsList.push(itemList);
        // currentItemsList = masterLanguages.concat(itemList);
      }
    });
    currentItemsList = masterLanguages.concat(currentItemsList);

    this.setState({
      loadMore: false,
      masterLanguages: currentItemsList,
      currentItemPage: currentItemPage + 1,
    });
  }
  loadMoreData() {
    const {
      loadMore,
      getPatientLanNInsList,
      currentItemPage,
      displayItems,
      masterLanguages,
    } = this.state;
    let totalItems =
      getPatientLanNInsList.masterLanguages &&
      getPatientLanNInsList.masterLanguages.length
        ? getPatientLanNInsList.masterLanguages.length
        : 0;
    let currentItems = masterLanguages.length;

    if (totalItems > currentItems) {
      setTimeout(
        function() {
          this.setState(
            {
              loadMore: false,
            },
            () => this.fetchMoreData()
          );
        }.bind(this),
        1000
      );
    }
  }
  async fetchFn() {
    let providerCategory = await userService.fetchGlobalApis(
      apiPath.providerCategoryUpdate
    );
    let getProviderFacility = await userService.fetchGlobalApis(
      apiPath.getProviders + "/" + this.state.userInfo.createdById
    );
    let getProviderSavedCard = await userService.fetchGlobalApisSilent(
      apiPath.providerSavedCard
    );
    let getPaymentStatus = await userService.fetchGlobalApisSilent(
      apiPath.paymentStatus
    );
    if (getProviderSavedCard && getProviderSavedCard.length) {
      let cardPlaceholder =
        getProviderSavedCard[0] && getProviderSavedCard[0].last4
          ? "xxxx-" + getProviderSavedCard[0].last4
          : "xxxx-xxxx-xxxx-xxxx";
      this.setState({
        cardSaved: true,
        cardPlaceholder: cardPlaceholder,
      });
    }
    this.setState({
      providerFacility: getProviderFacility,
    });
    if (providerCategory) {
      this.fetchData(providerCategory);
    }
    if (getPaymentStatus) {
      this.setState({
        getPaymentStatus: getPaymentStatus,
      });
    }
  }
  fetchData(providerCategory) {
    try {
      let selectedCategoriesArr = [];
      let selectedCheckBoxArr = [];
      let selectedMainCatArr = [];
      let loopFinish = false;
      {
        typeof providerCategory === "object" && providerCategory.length
          ? providerCategory.map((cList, key) => {
              if (cList.catStatus) {
                selectedMainCatArr.push(cList.categoryId);
              }
              this.setState({
                [cList.categoryId]: +cList.value,
              });
            })
          : null;
        setTimeout(
          function() {
            this.setState({
              loading: false,
              selectedMainCatArr: selectedMainCatArr,
            });
          }.bind(this),
          1000
        );
      }
    } catch (error) {}
  }
  stopLoading() {
    this.setState({
      loading: false,
    });
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let getPatientLanNInsList = [];
      let userInfo = {};
      let masterLanguages = [];
      let categoryList = [];
      let categoryParents = {};
      if (this.props.getPatientLanNInsListRedux) {
        let selectedgetPatientLanNInsList = this.props
          .getPatientLanNInsListRedux;
        getPatientLanNInsList = selectedgetPatientLanNInsList
          ? selectedgetPatientLanNInsList
          : [];
        if (getPatientLanNInsList && getPatientLanNInsList.masterLanguages) {
          masterLanguages = getPatientLanNInsList.masterLanguages;
        }
      }
      if (this.props.categoryListRedux) {
        let selectedcategoryList = this.props.categoryListRedux;
        categoryList = selectedcategoryList ? selectedcategoryList : [];
        if (typeof categoryList === "object" && categoryList.length) {
          categoryList.map((cList, key) => {
            const { catagoryId, parent } = cList;
            categoryParents[catagoryId] = parent;
          });
        }
      }
      if (this.props.userInfo) {
        userInfo = this.props.userInfo;
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        getPatientLanNInsList: getPatientLanNInsList,
        categoryList: categoryList,
        userInfo: userInfo,
        categoryParents: categoryParents,
      });
      console.log(this.state.categoryList);
    }
  }
  handleSubmit(event) {
    if (event) event.preventDefault();
    let cardData = "";
    if (this.state.cardNumber) {
      cardData = btoa(
        btoa(
          "cardNumber=" +
            this.state.cardNumber +
            "&cvc=" +
            this.state.cardCvv +
            "&exp_month=" +
            this.state.cardMM +
            "&exp_year=" +
            this.state.cardYear +
            ""
        )
      );
    }

    let selectedMainCatArr = this.state.selectedMainCatArr;
    let categoryData = [];
    if (this.state.categoryList && this.state.categoryList.length) {
      this.state.categoryList.map((cList, key) => {
        if (cList.parent == 0) {
          let localCatObj = {};
          if (selectedMainCatArr.includes(cList.catagoryId)) {
            localCatObj.catStatus = true;
            localCatObj.categoryId = cList.catagoryId;
          } else {
            localCatObj.catStatus = false;
            localCatObj.categoryId = cList.catagoryId;
          }
          categoryData.push(localCatObj);
        }
      });
    }
    let data = {
      paymentToken: cardData,
      subscribeCategories: categoryData,
    };
    let showNotification = {};
    // return
    this.setState({
      loading: true,
    });
    this.setState({
      openConfirmationBox: false,
      dialogMessage: "",
      dialogType: null,
    });
    const response = fetch(apiPath.providerSaveCardAndCategoryUpdate, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          return response.json();
        } else if (response.ok) {
          let cardPlaceholderLock =
            "xxxx-" +
            this.state.cardNumber.substr(this.state.cardNumber.length - 4);
          let cardPlaceholder = "xxxx-xxxx-xxxx-xxxx";
          this.setState({
            cardCvv: "",
            cardMM: "",
            cardYear: "",
            cardNumber: "",
            cardCvvValid: true,
            cardMMValid: true,
            cardYearValid: true,
            cardNumberValid: true,
            cardPlaceholder: cardPlaceholder,
            formErrors: {
              cardCvv: "",
              cardMM: "",
              cardYear: "",
              cardNumber: "",
            },
            cardTypeIcons: "unknown",
            cardSaved: true,
          });
          this.props.refreshBanner();
          this.fetchFn();
          // userService.showNotification(showNotification);
        } else {
          showNotification = {
            title: enMsg.cardSaveFailedTitle,
            message: enMsg.cardSaveFailedDes,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        return response.text();
      })
      .then((data) => {
        if (data && data.title) {
          showNotification = {
            title: enMsg.cardSaveFailedTitle,
            message: data.title,
            type: "dangerLong",
          };
          userService.showNotification(showNotification);
          this.setState({ loading: false });
        } else {
          showNotification = {
            title: "Success",
            message: data,
            type: "success",
          };
          //userService.showNotification(showNotification);
          window.location.replace(
            basePath + providerBaseRoutes.profile.path + "?tab=service"
          );
        }
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.cardSaveFailedTitle,
          message: "Something went wrong.Please try after sometime..",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      });
  }
  handleSubmitCategory(event) {
    if (event) event.preventDefault();
    let selectedMainCatArr = this.state.selectedMainCatArr;
    let data = [];
    if (this.state.categoryList && this.state.categoryList.length) {
      this.state.categoryList.map((cList, key) => {
        if (cList.parent == 0) {
          let localCatObj = {};
          if (selectedMainCatArr.includes(cList.catagoryId)) {
            localCatObj.catStatus = true;
            localCatObj.categoryId = cList.catagoryId;
          } else {
            localCatObj.catStatus = false;
            localCatObj.categoryId = cList.catagoryId;
          }
          data.push(localCatObj);
        }
      });
    }
    let showNotification = {};
    this.setState({
      loading: true,
    });
    this.setState({
      openConfirmationBox: false,
      dialogMessage: "",
      dialogType: null,
    });
    const response = fetch(apiPath.providerCategoryUpdate, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          return response.json();
        } else if (response.ok) {
          this.props.refreshBanner();
        } else {
          showNotification = {
            title: enMsg.categoryFailedTitle,
            message: enMsg.categoryFailedDes,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        return response.text();
      })
      .then((data) => {
        if (data && data.title) {
          showNotification = {
            title: enMsg.categoryFailedTitle,
            message: data.title,
            type: "dangerLong",
          };
          userService.showNotification(showNotification);
          this.setState({ loading: false });
        } else {
          showNotification = {
            title: "Success",
            message: data,
            type: "successLong",
          };
          userService.showNotification(showNotification);
          this.setState({ loading: false });
        }
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.cardSaveFailedTitle,
          message: "Something went wrong.Please try after sometime..",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      });
  }
  handleCategorySelection = (catagoryId) => {
    const name = catagoryId;
    let value = catagoryId;

    let selectedMainCatArr = this.state.selectedMainCatArr;
    if (!selectedMainCatArr.includes(name)) {
      selectedMainCatArr.push(name);
    } else {
      const index = selectedMainCatArr.indexOf(name);
      if (index > -1) {
        selectedMainCatArr.splice(index, 1);
      }
    }
    this.setState({
      selectedMainCatArr: selectedMainCatArr,
    });
  };
  handleConfirmBox() {
    this.setState({
      openConfirmationBox: false,
      dialogMessage: "",
      dialogType: null,
    });
  }
  handleSubmitCategoryBox(event) {
    event.preventDefault();
    if (this.state.selectedMainCatArr && this.state.selectedMainCatArr.length) {
      this.setState({
        openConfirmationBox: true,
        dialogMessage: "Are you sure to Update Categories?",
        dialogType: "category",
      });
    } else {
      this.setState({
        openConfirmationBox: true,
        dialogMessage:
          "DeSelecting all Categories will result in Cancellation, Please contact MAXMRJ",
        dialogType: "category",
      });
    }
  }
  handleSubmitCardBox(event) {
    event.preventDefault();
    this.setState({
      openConfirmationBox: true,
      dialogMessage:
        "Do you want to confirm your credit card information and service categories?",
      dialogType: "card",
    });
  }
  handleConfirm() {
    if (this.state.dialogType && this.state.dialogType == "card") {
      this.handleSubmit();
      // this.handleSubmitCategory();
    } else if (this.state.dialogType && this.state.dialogType == "category") {
      this.handleSubmitCategory();
    } else {
      let showNotification = {
        title: "Request Failed",
        message: "Request failed, please try again or logout and login",
        type: "danger",
      };
      userService.showNotification(showNotification);
    }
  }
  confirmModalOpen = (message, invitationId, acceptOrDecline) => {
    this.setState({
      invitationId: invitationId,
      confirmModalMessage: message,
      confirmModalStatus: true,
      acceptOrDecline: acceptOrDecline,
    });
  };
  confirmModalClose = () => {
    this.setState({
      confirmModalStatus: false,
    });
  };
  async acceptOrDeclineInvitation() {
    this.setState({
      loading: true,
    });
    let showNotification = {};
    const response = await fetch(
      apiPath.acceptORDeclineInvitation +
        "?accept=" +
        this.state.acceptOrDecline +
        "&ids=" +
        this.state.invitationId,
      {
        method: "GET",
        headers: generalAuthTokenHeader(),
      }
    )
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Invitation",
            message: "Bad response from server",
            type: "danger",
          };
          return response.json();
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Invitation",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.text();
      })
      .then((data) => {
        if (data.status == "400" && data.message == "error.cardInfomissing") {
          showNotification = {
            title: "Invitation",
            message: data.title,
            type: "danger",
          };
        } else {
          let messageTitle =
            this.state.acceptOrDecline == 1 ? "accepted" : "declined";
          let messageText =
            this.state.acceptOrDecline == 1
              ? data
              : `Invitation has been ${messageTitle} successfully.`;
          showNotification = {
            title: "Invitation",
            message: messageText,
            type: "success",
          };
          this.fetchProvidersInvitations();
          this.confirmModalClose();
          this.fetchFn();
        }
      })
      .catch((error) => {
        return response;
      });
    this.setState({
      loading: false,
    });
    userService.showNotification(showNotification);
  }
  render() {
    const {
      getPatientLanNInsList,
      masterLanguages,
      categoryList,
      providerFacility,
    } = this.state;
    return (
      <div className="ProviderProfile PaterintCriteria">
        {this.state.loading ? <LinearProgress color="primary" /> : ""}
        <ConfirmModal
          title="Alert"
          message={this.state.confirmModalMessage}
          confirmModalStatus={this.state.confirmModalStatus}
          confirmModalClose={this.confirmModalClose}
          submit={this.acceptOrDeclineInvitation}
          loading={this.state.loading}
        />
        <Dialog
          open={this.state.openConfirmationBox}
          maxWidth={"xs"}
          onClose={this.handleConfirmBox}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal "
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Alert
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleCloseForProviderStatus}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              width={1}
              display="flex"
              justifyContent="flex-start"
              mb={1}
              mt={1}
            >
              <Box pr={2}>{this.state.dialogMessage}</Box>
            </Box>
          </DialogContent>
          <DialogActions className="modal-actions" justify="center">
            <Button type="button" className="btn1" onClick={this.handleConfirm}>
              Confirm
            </Button>
            <Button onClick={this.handleConfirmBox} className="cancel-link">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <div className="SubscriptionDetailsSection front-modal">
          <Box width={1} display="flex" flexDirection="row">
            <Box width={1 / 2} display="flex" flexDirection="column" pr={3}>
              <div className="tab-heading">Payment Information Details</div>
              <div className="service-info-text">
                <p>
                  {/* Pay only for the data you use, ensuring cost-effectiveness and
                  flexibility. Our transparent pricing aligns with your actual
                  data usage. After your free usage amount is used, you’ll be
                  charged at the end of the month based on your data usage.
                  Please enter your payment information. */}
                  Try MAXMRJ free for 30 days; no credit card required.
                </p>
                {/* <p>
                  Note: Transport service is a monthly subscription billed on
                  the 1st of the month.
                </p> */}
              </div>

              <form onSubmit={this.handleSubmitCardBox} noValidate>
                <Box width={1} display="flex" flexDirection="row" mb={2} mt={2}>
                  <Box
                    width={1 / 2}
                    display="flex"
                    flexDirection="column"
                    pr={1}
                    className="pr-sm-0"
                  >
                    <div className="CardNumberIcon">
                      <label>Card Number</label>
                    </div>
                    <div className="cardNumber-input ">
                      <MaskInput
                        onChange={this.handleChangeInputCardNumber}
                        name="cardNumber"
                        mask={"0000-0000-0000-0000"}
                        // showMask
                        value={this.state.cardNumber}
                        // alwaysShowMask
                        size={20}
                        placeholder={this.state.cardPlaceholder}
                        className="custom-input"
                      />
                      <span className="cardtype-icon">
                        <i
                          className={
                            cardTypeIcons[this.state.cardTypeIcons]
                              ? cardTypeIcons[this.state.cardTypeIcons]
                              : cardTypeIcons["unknown"]
                          }
                        ></i>
                      </span>
                    </div>
                    <Box width={1}>
                      <FormErrors
                        show={!this.state.cardNumberValid}
                        formErrors={this.state.formErrors}
                        fieldName="cardNumber"
                      />
                    </Box>
                  </Box>
                  <Box
                    width={1 / 2}
                    display="flex"
                    flexDirection="column"
                    pl={1}
                  >
                    <label>Expiration Date</label>
                    <Box width={1} display="flex" flexDirection="row">
                      <Box width={1 / 2}>
                        <TextField
                          InputLabelProps={{ className: "required-label" }}
                          name="cardMM"
                          value={this.state.cardMM}
                          onChange={this.handleChangeInputDate}
                          data-validators="isRequired"
                          // label="Last Name"
                          placeholder="MM"
                          disabled={this.state.cardTextBoxEnable}
                          inputProps={{
                            maxLength: 2,
                            pattern: "^(d|10).d{2}$",
                          }}
                        />
                      </Box>
                      <Box width={1 / 2} pl={1}>
                        <TextField
                          InputLabelProps={{ className: "required-label" }}
                          name="cardYear"
                          value={this.state.cardYear}
                          onChange={this.handleChangeInputDate}
                          data-validators="isRequired"
                          // label="Last Name"
                          placeholder="YYYY"
                          disabled={this.state.cardTextBoxEnable}
                          inputProps={{
                            maxLength: 4,
                            pattern: "^(d|10).d{2}$",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box width={1}>
                      <FormErrors
                        show={!this.state.cardMMValid}
                        formErrors={this.state.formErrors}
                        fieldName="cardMM"
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  width={1}
                  display="flex"
                  flexDirection="column"
                  mb={2}
                  mt={2}
                >
                  <label>CVV(back of card)</label>
                  <Box width={1} display="flex" flexDirection="row">
                    <Box width={1 / 4} pr={1}>
                      <TextField
                        InputLabelProps={{ className: "required-label" }}
                        name="cardCvv"
                        value={this.state.cardCvv}
                        onChange={this.handleChangeInput}
                        data-validators="isRequired"
                        // label="CVV(back of card)"
                        disabled={this.state.cardTextBoxEnable}
                        placeholder="CVV"
                        inputProps={{ maxLength: 4, pattern: "^(d|10).d{2}$" }}
                      />
                      <Box width={1}>
                        <FormErrors
                          show={!this.state.cardCvvValid}
                          formErrors={this.state.formErrors}
                          fieldName="cardCvv"
                        />
                      </Box>
                    </Box>
                    <Box width={1 / 4} pl={1}>
                      <img className="cvvimg" src={cvvimg} />
                    </Box>
                  </Box>
                </Box>

                <Box width={1}>
                  <div className="form-button-cover form-actions">
                    <>
                      <Button
                        className={
                          this.state.loading ? "buttonSuccess btn1" : "btn1"
                        }
                        disabled={!this.validateForm() || this.state.loading}
                        type="submit"
                      >
                        {this.state.cardSaved
                          ? "Save And Next"
                          : "Save And Next"}
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            className="buttonProgress"
                          />
                        )}
                      </Button>
                    </>

                    <Button className="back-btn btn2" type="button">
                      <Link
                        underline="none"
                        to={providerBaseRoutes.dashboard.path}
                      >
                        Cancel
                      </Link>
                    </Button>
                  </div>
                </Box>
              </form>
              {/* <div className="FacilitiesSection">
                <div className="tab-heading">Facilities Available</div>
                <ul className="facility-name">
                  {providerFacility &&
                  typeof providerFacility.providerFacilityData === "object" &&
                  providerFacility.providerFacilityData.length
                    ? providerFacility.providerFacilityData.map(
                        (fList, key) => {
                          return (
                            <li key={key}>{fList.facility.facility_name}</li>
                          );
                        }
                      )
                    : this.state.loading
                    ? ""
                    : "Facilities not available"}
                </ul>
              </div>
              <div className="FacilitiesSection confirm-wrap">
                {this.state.invitations.length > 0 &&
                  this.state.invitations.map((invitation, key) => (
                    <div key={key}>
                      <p>
                        This action will add {invitation.facility.facilityName}{" "}
                        to your subscription.This pro-rated amount for this
                        month will be charged for this facility.If you cancel,
                        you will need to receive a new invitation link.
                      </p>
                      <Box width={1}>
                        <div className="form-button-cover form-actions">
                          <Button
                            className={
                              this.state.loading ? "buttonSuccess btn1" : "btn1"
                            }
                            type="button"
                            onClick={() =>
                              this.confirmModalOpen(
                                "Do you want to accept the invitation?",
                                invitation.id,
                                1
                              )
                            }
                          >
                            Confirm
                            {this.state.loading && (
                              <CircularProgress
                                size={24}
                                className="buttonProgress"
                              />
                            )}
                          </Button>
                          <Button
                            className="back-btn btn2"
                            type="button"
                            onClick={() =>
                              this.confirmModalOpen(
                                "Do you want to cancel the invitation?",
                                invitation.id,
                                2
                              )
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </Box>
                    </div>
                  ))}
              </div> */}
            </Box>
            <Box width={1 / 2} display="flex" flexDirection="column" pl={3}>
              <div className="tab-heading">Service Categories</div>

              {/* <div className="service-info-text">
                                <p>Subscription fee is $200 per month.</p>
                                <p>Each additional category is $100 per month.</p>
                                <p>Selections are universal across all facilities to which the provider is linked.</p>
                                <p>Contact us for additional pricing options.</p>
                            </div> */}

              <div className="service-info-text">
                <p> Explore our subscription plans on our <a href="https://maxmrj.com/pricing" target="_blank">Pricing Page</a>. Start with the Partner plan, billed monthly. For plan changes or billing options, contact us via our <a href="https://maxmrj.com/pricing/#contact" target="_blank">contact form </a>. </p>
                
              </div>

              <form onSubmit={this.handleSubmitCategoryBox} noValidate>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  mt={2}
                >
                  {typeof categoryList === "object" && categoryList.length
                    ? categoryList.map((cList, key) => {
                        const {
                          categoryValue,
                          catagoryId,
                          status,
                          parent,
                        } = cList;
                        if (parent == 0) {
                          return (
                            <>
                              <Chip
                                key={key}
                                icon={
                                  <Checkbox
                                    key={key}
                                    icon={<CircleUnchecked />}
                                    checkedIcon={<CircleCheckedFilled />}
                                    checked={
                                      this.state.selectedMainCatArr.includes(
                                        catagoryId
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={categoryValue}
                                onClick={() =>
                                  this.handleCategorySelection(catagoryId)
                                }
                                className={
                                  this.state.selectedMainCatArr.includes(
                                    catagoryId
                                  )
                                    ? "chipinput activeCat"
                                    : "chipinput inactiveCat"
                                }
                              />
                            </>
                          );
                        }
                      })
                    : "No category available..."}
                </Box>
                {/* <Box>
                  <div className="form-button-cover form-actions">
                    <Button
                      className={
                        this.state.loading ? "buttonSuccess btn1" : "btn1"
                      }
                      disabled={this.state.loading}
                      type="submit"
                    >
                      Save
                      {this.state.loading && (
                        <CircularProgress
                          size={24}
                          className="buttonProgress"
                        />
                      )}
                    </Button>
                  </div>
                </Box> */}
              </form>

              {/* <div className="service-info-subtext">
                <p className="mb-20">
                  New selections will be charged immediately on a pro-rated
                  basis for the rest of the current month.
                </p>

                <p>A full charge will be placed on the 1st of the month.</p>
              </div> */}
            </Box>
          </Box>
        </div>

        {this.state.getPaymentStatus && this.state.getPaymentStatus.status ? (
          <div className="information-tag">
            <p
              className={`type-1 type-2 ${
                this.state.getPaymentStatus.status == "succeeded"
                  ? "success"
                  : "failed"
              }`}
            >
              {this.state.getPaymentStatus.msg}
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

SubscriptionModuleClass.propTypes = {
  classes: PropTypes.object,
};
const SubscriptionModule = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionModuleClass);
export default withStyles(styles)(SubscriptionModule);
