import React, { Component } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    LinearProgress
} from "@material-ui/core";
import { IconButton, Link as MaterialLink, CircularProgress, TextField } from "@material-ui/core"

import { FormErrors } from "../Login/FormErrors";
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box'
import { formatDateToLocalFormat, downloadMedia } from '__helpers/util';

import { userService } from "_services/user.service"
import DateFnsUtils from "@date-io/date-fns"
import { apiPath } from 'api'
import { generalAuthTokenHeader, generalMimeAuthTokenHeader } from "__helpers/auth-header"
import fileExtension from "file-extension";
import enMsg from "__helpers/locale/en/en";
import { USER_INFO} from "__helpers/constants";
import { Scrollbars } from 'react-custom-scrollbars';

import { REFERRAL_TYPE, ALLOWED_PROFILE_IMAGE_SIZE, ALLOWED_FACESHEET_EXTENTION, MAX_INPUT_LENGTH_LONG } from '__helpers/constants';
import { files } from 'jszip';

class Comment extends Component {
    inputOpenFileRef = React.createRef(null);
    
    constructor(props) {
        super(props)
        this.dateUtility = new DateFnsUtils();
        let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
        let initialSenderId = null;
        let filteredPrelimnComment = this.props.prelimComment && this.props.prelimComment.length ? this.props.prelimComment.filter((comments) => {
            if (initialSenderId != comments.commentedBy) {
                initialSenderId = comments.commentedBy;
                return comments;
            }
        }) : []
        this.state = {
            comment: '',
            formErrors: {
                comment: '',
                file: ''
            },
            fileName: '',
            file: '',
            fileBlob: '',
            fileValid: false,
            commentValid: false,
            commentsThread: [],
            commentLoading: false,
            fileData: null,
            referralId: this.props.referralId ? this.props.referralId : "",
            prelimComment: filteredPrelimnComment,
            loginproviderId:userInfo?userInfo.provider.id:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount = () => {
        this.fetchComments(this.props.referralId);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.referralId != this.state.referralId) {
            this.fetchComments(nextProps.referralId);
        }
    }
    fetchComments = (referralId) => {
        this.setState({
            commentsThread: [],
            commentLoading: true,
        })
        const response = fetch(apiPath.getCommentToDP + '?patientId=' + this.props.patientId + '&refferalId=' + referralId + '&sort=createdDateTime,Desc', {
            method: "GET",
            headers: generalAuthTokenHeader(),
        })
            .then(response => {
                console.log(response)
                if (response.status === 400) {

                } else if (response.ok) {
                } else {

                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                if (data.length) {
                    this.setState({
                        commentsThread: data
                    })
                }
                this.setState({
                    commentLoading: false,
                })

            })
            .catch(error => {
                this.setState({
                    commentLoading: false,
                })
            });
    }
    validateField = (fieldName, fieldValue) => {
        let fieldValidationErrors = this.state.formErrors;
        let commentValid = this.state.commentValid;
        switch (fieldName) {
            case 'comment':
                let commentErr = '';
                if (fieldValue.trim().length == 0) {
                    commentValid = false;
                    commentErr = 'Comment is required'
                } else if (fieldValue.trim().length > MAX_INPUT_LENGTH_LONG) {
                    commentValid = false;
                    commentErr = 'Comment length cannot exceed 255 characters'
                } else {
                    commentValid = true;
                    commentErr = '';
                }
                fieldValidationErrors.comment = commentErr;
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            commentValid: commentValid,
        }, this.validateForm);
    }
    validateForm() {
        return (
            this.state.commentValid ||
            this.state.fileValid
        )
    }
    onFileBrowse = event => {
        this.inputOpenFileRef.current.click();
    };
    async handleSubmit(event) {
        event.preventDefault();
        let dataLock = {
            base64Data: this.state.file,
            description: this.state.comment,
            fileName: this.state.fileName,
            receiverId: this.props.receiverId ? this.props.receiverId : '',
            refferalId: this.props.referralId ? this.props.referralId : '',
            patientId: this.props.patientId ? this.props.patientId : ''
        };
        const data = new FormData()
        if (this.state.fileData) {
            data.append("base64Data", this.state.fileData);
            data.append("fileName", this.state.fileName);
        }
        data.append("description", this.state.comment);
        data.append("receiverId", this.props.receiverId ? this.props.receiverId : '');
        data.append("refferalId", this.props.referralId ? this.props.referralId : '');
        data.append("patientId", this.props.patientId ? this.props.patientId : '');


        this.setState({ loading: true });
        let showNotification = {};
        let modalCloseStatus = 1;
        const response = await fetch(apiPath.postCommentByDP, {
            method: "POST",
            headers: generalMimeAuthTokenHeader(),
            body: data,
            data: data,
        })
            .then(response => {
                if (response.status === 400) {
                    showNotification = {
                        title: 'Comment',
                        message: enMsg.badResponseFromServer,
                        type: "danger"
                    };
                } else if (response.ok) {
                } else {
                    showNotification = {
                        title: 'Comment',
                        message: enMsg.badResponseFromServer,
                        type: "danger"
                    };
                }
                return response.json();
            })
            .then(data => {
                if (data.title) {
                    showNotification = {
                        title: 'Comment',
                        message: data.title,
                        type: "dangerLong"
                    };
                }
                if (data.id) {
                    this.fetchComments(this.props.referralId);
                    this.setState({
                        fileData: null,
                        comment: '',
                        fileName: '',
                        file: '',
                        commentValid: false,
                        fileValid: false
                    })
                    showNotification = {
                        title: 'Comment',
                        message: 'Message has been sent.',
                        type: "success"
                    };
                }

            })
            .catch(error => {
                showNotification = {
                    title: 'Comment',
                    message: enMsg.clientSideError,
                    type: "danger"
                };
            });
        userService.showNotification(showNotification);
        this.setState({ loading: false });
    }
    handleSelectedFile = event => {
        event.preventDefault();
        let file = event.target.files[0];
        if (typeof file === 'undefined' && this.state.fileName == '') {
            this.setState({
                commentValid: false,
                fileValid: false
            })
            return;
        }
        let fieldValidationErrors = this.state.formErrors;
        let error = "";
        let fileValid = false;
        if (typeof file == "object" && file.size > ALLOWED_PROFILE_IMAGE_SIZE) {
            error = enMsg.allowedFaceSheetSize;
        } else if (
            typeof file == "object" &&
            !ALLOWED_FACESHEET_EXTENTION.includes(file.type)
        ) {
            error = enMsg.allowedFaceSheetType;
        } else {
            fileValid = true;
        }

        if (typeof file !== undefined && fileValid) {
            const fileReader = new FileReader();
            fileReader.addEventListener("load", () => {
                let result = fileReader.result;
                this.setState({
                    fileName: file.name,
                    file: result,
                });
                this.fileBlob = file;
                this.filename = new Date().getTime() + "." + fileExtension(file.name);
            });
            if (file) {
                this.setState({
                    fileData: file,
                })
                fileReader.readAsDataURL(file);
            }
        }
        if (!fileValid) {
            this.setState({
                fileName: '',
            })
        }

        fieldValidationErrors.file = fileValid ? "" : error;
        this.setState(
            {
                fileValid: fileValid,
                formErrors: fieldValidationErrors
            },
            this.validateForm
        );
    };
    downloadFile = async (fileName, fileUrl,toId) => {
        console.log(toId);
        let showNotification = {};
        try {
            let showNotification = {
                title: 'Facesheet',
                message: 'Please wait, Downloading files',
                type: "info"
            };
            userService.showNotification(showNotification);
            let urlArr = fileUrl.split('/')
            let urlArrEndPoint = urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1];
            let dataUses = "";
            if(toId == this.state.loginproviderId)
            {
                dataUses = '?patientId=' + this.props.patientId + '&refferalId=' + this.props.referralId+"&key=" + urlArrEndPoint;
            }else
            {
                dataUses = "?key=" + urlArrEndPoint;
            }
            let sheetData = await userService.fetchCommentSheetsData(apiPath.downloadCommentFile + dataUses, fileName);
        } catch (error) {
            showNotification = {
                title: 'Facesheet',
                message: 'No facesheet available.',
                type: "danger"
            };
            userService.showNotification(showNotification);
        }
        this.setState({
            loading: false,
        })
        return false;
    }
    handleChangeInput = event => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        }, () => { this.validateField(name, value) })
    }
    render() {
        return (
            <form onSubmit={this.handleSubmit} noValidate>
                <Box width={1} pr={2} display="flex" flexDirection="row" justifyContent="flex-start" >
                    <Box pr={2} width="60%" className="loader-width-position">
                        <Scrollbars
                            autoHide={false}
                            universal={true}
                            autoHeight={true}
                            autoHeightMin={0}
                            //autoHeightMax={300}
                            className="commentTableScroll HightAndBg autoscroll"
                        >
                            <table >

                                <tbody>
                                    {this.state.commentsThread.length ?
                                        (this.state.commentsThread.map((comment, key) =>
                                            <tr key={key}>
                                                <td>{comment.fromUser.firstName + ' ' + (comment.fromUser.middleName !== null ? comment.fromUser.middleName : '') + ' ' + comment.fromUser.lastName}</td>
                                                <td>
                                                    <div className="commentdescription" >{comment.description}</div>
                                                </td>

                                                {(comment.fileName) ?
                                                    <td>
                                                        <i className="fa fa-download pointer" aria-hidden="true" onClick={() => this.downloadFile(comment.fileName, comment.uploadFileUrl,comment.toId)}></i>
                                                    </td>
                                                    :
                                                    <td>No attachment</td>
                                                }

                                                <td>Posted at:<br /> {comment.formattedCreatedDateTime}</td>
                                            </tr>

                                        ))
                                        :
                                        (this.state.commentLoading)
                                            ?
                                            <LinearProgress
                                                color="primary"
                                            />
                                            :
                                            null
                                    }
                                    {
                                        this.state.prelimComment && this.state.prelimComment.length
                                            ?
                                            (this.state.prelimComment.map((comment, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{comment.createdUser && comment.createdUser.firstName  ? comment.createdUser.firstName + ' ' + comment.createdUser.lastName : "N/A"}</td>
                                                        <td>
                                                            <div className="commentdescription" >{comment.comment}</div>
                                                        </td>

                                                        {(comment.fileName) ?
                                                            <td>
                                                                <i className="fa fa-download pointer" aria-hidden="true" onClick={() => this.downloadFile(comment.fileName, comment.uploadFileUrl,comment.toId)}></i>
                                                            </td>
                                                            :
                                                            <td>No attachment</td>
                                                        }

                                                        <td>Posted at:<br /> {comment.formattedCreatedDateTime ? comment.formattedCreatedDateTime : "N/A"}</td>
                                                    </tr>
                                                )
                                            })
                                                // .reverse()
                                            )
                                            :
                                            null
                                    }
                                    {
                                        this.state.commentsThread.length || this.state.prelimComment.length
                                            ?
                                            null
                                            :
                                            <tr>
                                                <td className="no-td">No Comments!</td>
                                            </tr>
                                    }
                                </tbody>

                            </table>

                        </Scrollbars>
                    </Box>

                    <Box pl={2} width="40%" flexDirection="column" >
                        <Box width={1} display="flex" flexDirection="column" >
                            <TextField
                                placeholder=''
                                multiline={true}
                                rows={3}
                                className='Comment-TextField'
                                name='comment'
                                value={this.state.comment}
                                onChange={this.handleChangeInput}
                            />
                            <FormErrors
                                show={!this.state.commentValid}
                                formErrors={this.state.formErrors}
                                fieldName="comment"
                            />
                        </Box>
                        <p className="wb w-100">{this.state.fileName}</p>
                        <Box width={1} display="flex" flexWrap="wrap" mt={1} >
                            <div className="image-ref-img">
                                <input
                                    type="file"
                                    id="file"
                                    ref={this.inputOpenFileRef}
                                    onChange={event =>
                                        this.handleSelectedFile(event)
                                    }
                                    accept="image/x-png, image/jpg, image/jpeg, application/pdf"
                                    style={{ display: "none" }}
                                />
                            </div>
                            <Button type='button' className='btn1 mr-10' onClick={event => this.onFileBrowse(event)}>
                                <i className="fa fa-upload" aria-hidden="true"></i>&nbsp;Upload&nbsp;File
                            </Button>


                            <Button type='submit' className='btn1' disabled={!this.validateForm() || this.state.loading}>
                                {this.state.loading && (
                                    <CircularProgress
                                        size={24}
                                        className="buttonProgress"
                                    />
                                )}
                                Submit
                            </Button>
                            <div className="w-100">
                                <FormErrors
                                    show={!this.state.fileValid}
                                    formErrors={this.state.formErrors}
                                    fieldName="file"
                                />
                            </div>
                        </Box>
                    </Box>

                </Box>
            </form>

        )
    }
}

export default Comment