import React from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Link as MaterialLink } from "@material-ui/core";
import { FormErrors } from "../../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  Chip,
  Input,
  Checkbox,
  ListSubheader,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import { basePath, dpBaseRoutes } from "base-routes";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FormGroup, FormControl, InputLabel } from "@material-ui/core";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ProviderSection from "views/FindProvider";
import { formatDateToLocalFormat } from "__helpers/util";
import PatientForm from "views/dpViews/PatientDashboard/PatientForm";
import { PATIENT_LIST } from "__helpers/constants";
import Sidebar from "react-sidebar";
import { findProviderService } from "__helpers/util";
import Tooltip from "@material-ui/core/Tooltip";
import infoIconW from "assets/img/icons/informationIcon-w.svg";
import notesArrow from "assets/img/icons/notes-arrow.svg";
import { Scrollbars } from "react-custom-scrollbars";
import CustomInput from "components/CustomInput/CustomInput";
import { USER_INFO } from "__helpers/constants";
const mapStateToProps = (state) => {
  return {
    insuranceListRedux: state.insuranceList,
    hospitalListRedux: state.hospitalList,
    languageAndInsuranceList: state.getPatientLanNInsList,
    categoryListRedux: state.categoryList,
  };
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
class PatientCriteria extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.addressStreetInputRef = React.createRef();
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    console.log(userInfo.dischargePlanner.facility.address);
    this.state = {
      languageAndInsuranceList: this.props.languageAndInsuranceList,
      hospitalList: this.props.hospitalListRedux
        ? this.props.hospitalListRedux
        : [],
      categoryList: this.props.categoryListRedux,
      patientInfo: this.props.patientInfo,
      categoryName: "",
      selectedCategory: "",
      selectedService: [],
      selectSubService: [],
      subService: false,
      subServices: [],
      insurance: "",
      userInsurance:
        this.props.patientInfo && this.props.patientInfo.insuranceId
          ? "" + this.props.patientInfo.insuranceId
          : "0",
      userHospital:
        this.props.patientInfo && this.props.patientInfo.previousHospitalId
          ? this.props.patientInfo.previousHospitalId
          : "",
      hospital: [""],
      language: [""],
      infectiousDisease: "",
      patientCriteria: [],
      patientCriteriaModalStatus: false,
      reduxLoadFlag: false,
      confirmBoxStatus: false,
      readOnly: this.props.readOnly ? this.props.readOnly : false,
      selectedCategoryId: "",
      isInsuranceFiltered: true,
      mile: 50,
      longitude: "",
      latitude: "",
      address: userInfo ? userInfo.dischargePlanner.facility.address : "",
      defaultAddress: userInfo
        ? userInfo.dischargePlanner.facility.address
        : "",
      defaultLatitude: userInfo
        ? userInfo.dischargePlanner.facility.latitude
        : "",
      defaultLongitude: userInfo
        ? userInfo.dischargePlanner.facility.longitude
        : "",
    };
    this.getPatientLastCriteriaSearch = this.getPatientLastCriteriaSearch.bind(
      this
    );
    this.getFacilities = this.getFacilities.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleInsuranceClick = this.handleInsuranceClick.bind(this);
  }
  componentDidMount() {
    this.state.categoryList.map((category) => {
      if (category.parent == 0 && category.id == this.props.selectCategoryId) {
        this.setState({
          selectedCategory: category,
        });
      }
    });
    this.getPatientLastCriteriaSearch();
  }
  handlePlaceSelection = (autocomplete) => {
    // Get the selected place details from the Autocomplete object
    const selectedPlace = autocomplete.getPlace();

    if (selectedPlace && selectedPlace.formatted_address) {
      this.setState({
        latitude: "",
        longitude: "",
      });
      // Extract the address components from the selected place
      const address = selectedPlace.formatted_address;
      const latitude = selectedPlace.geometry.location.lat();
      const longitude = selectedPlace.geometry.location.lng();
      // console.log(selectedPlace.geometry.location);
      // Update the state with the selected address components

      this.setState({
        address: address,
        latitude: latitude,
        longitude: longitude,
      });
      // if (address) this.validateField("address", address);
      // if (latitude) this.validateField("latitude", latitude);
      // if (longitude) this.validateField("longitude", longitude);
    }
  };
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let hospitalList = [];
      if (this.props.hospitalListRedux) {
        let selectedhospitalList = this.props.hospitalListRedux;
        hospitalList = selectedhospitalList ? selectedhospitalList : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        hospitalList: hospitalList,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.patientInfo) {
      this.setState({
        patientInfo: nextProps.patientInfo,
        userInsurance:
          nextProps.patientInfo && nextProps.patientInfo.insuranceId
            ? "" + nextProps.patientInfo.insuranceId
            : "",
        userHospital:
          nextProps.patientInfo && nextProps.patientInfo.previousHospitalId
            ? nextProps.patientInfo.previousHospitalId
            : "",
      });
      if (
        nextProps.patientInfo &&
        nextProps.patientInfo.patientCatagoryMapping &&
        nextProps.patientInfo.patientCatagoryMapping.length > 0
      ) {
        this.setState(
          {
            selectedCategoryId:
              nextProps.patientInfo.patientCatagoryMapping[0].categoryId,
          },
          () => {
            this.getPatientLastCriteriaSearch();
          }
        );
      }
      if (!this.state.isInsuranceFiltered) {
        this.setState({
          // insurance: nextProps.patientInfo && nextProps.patientInfo.insuranceId ? "0" + nextProps.patientInfo.insuranceId : "0",
        });
      }
    }
  }
  getFacilities() {
    const response = fetch(apiPath.getActiveFacilities, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          hospitalList: data,
        });
      })
      .catch((error) => {
        return response;
      });
  }
  async getPatientLastCriteriaSearch() {
    let patientId = localStorage.getItem("_selected_patient_list");
    patientId = patientId.replace(/"/g, "");
    let url;
    let categoryId = this.state.selectCategoryId;
    if (this.props.selectCategoryId) {
      categoryId = this.props.selectCategoryId;
    }
    url =
      apiPath.patientSearchHistory +
      "?categoryId=" +
      this.props.selectCategoryId +
      "&patientId=" +
      patientId +
      "&size=" +
      1;
    const response = await fetch(url, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else if (response.status === 401) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        console.log("filte data", data);
        if (data && data.search) {
          let patientCriteria = data;
          const url = new URLSearchParams(patientCriteria["search"]);
          let languageId = url.get("languageId");
          let infectiousStatus = url.get("infectiousStatus");
          let insuranceId = url.get("insuranceId");
          let subCategoryId = url.get("subCategoryId");
          let hospital = url.get("hospitalId");
          let mileValue = url.get("mileValue");
          let address = url.get("address")
            ? url.get("address")
            : this.state.defaultAddress;
          let latitude = url.get("latitude")
            ? url.get("latitude")
            : this.state.defaultLatitude;
          let longitude = url.get("longitude")
            ? url.get("longitude")
            : this.state.defaultLongitude;
          let categoryId = subCategoryId.split(",");
          let secondLevelCategoryId = [];
          let thirdLevelCategoryId = [];

          // let sampleStr = "21";
          let languageIdArr = languageId ? languageId.split("-") : [""];
          let hospitalArr = hospital ? hospital.split("-") : [];
          let insuranceIdArr = insuranceId ? insuranceId.split("-") : [];

          let localSubCat = [];
          if (categoryId) {
            secondLevelCategoryId = categoryId[0]
              ? categoryId[0].split("-")
              : [];
            thirdLevelCategoryId = categoryId[1]
              ? categoryId[1].split("-")
              : [];
            localSubCat = categoryId.filter((v, key) => {
              if (key != 0) {
                return v;
              }
            });
          }
          let isInsuranceFiltered = false;
          if (!insuranceId) {
            isInsuranceFiltered = true;
          }
          this.setState(
            {
              language: languageIdArr,
              infectiousDisease: infectiousStatus ? infectiousStatus : "",
              // insurance: insuranceIdArr,
              insurance: insuranceId ? insuranceId : "",
              subService: thirdLevelCategoryId.length ? true : false,
              // selectSubService: thirdLevelCategoryId,
              selectSubService: thirdLevelCategoryId,
              selectedService: secondLevelCategoryId,
              // hospital: hospital ? hospitalArr : this.state.patientInfo && this.state.patientInfo.previousHospitalId ? [this.state.patientInfo.previousHospitalId] : [""],
              hospital: hospital ? hospitalArr : [""],
              isInsuranceFiltered: isInsuranceFiltered,
              mile: mileValue ? parseFloat(mileValue) : 50,
              address: address ? address : "",
              latitude: latitude ? latitude : "",
              longitude: longitude ? longitude : "",
            },
            () => {
              this.setService(secondLevelCategoryId);
              this.subServiceFilterRender();
            }
          );
          if (!isInsuranceFiltered) {
            this.setState({
              // insurance: this.state.userInsurance,
            });
          }
        } else {
          this.setState({
            insurance:
              this.state.patientInfo && this.state.patientInfo.insuranceId
                ? this.state.patientInfo.insuranceId
                : "0",
            hospital:
              this.state.patientInfo &&
              this.state.patientInfo.previousHospitalId
                ? [this.state.patientInfo.previousHospitalId]
                : [""],
          });
        }
      })
      .catch((error) => {
        //return response;
      });
  }
  handleSubmit = async (event) => {
    let showNotification = {};
    this.setState({
      loading: false,
      confirmBoxStatus: false,
    });
    if (event) event.preventDefault();
    let url;
    let subCategory = "";
    let categoryId = "";
    if (
      this.state.selectSubService &&
      this.state.selectSubService.length &&
      this.state.subService
    ) {
      subCategory =
        this.state.selectedService.join("-") +
        "," +
        this.state.selectSubService.join("-");
    } else {
      subCategory = this.state.selectedService.join("-");
    }
    if (this.state.selectedService) {
      categoryId = this.props.selectCategoryId;
    }
    url =
      "infectiousStatus=" +
      this.state.infectiousDisease +
      "&insuranceId=" +
      this.state.insurance +
      "&languageId=" +
      this.state.language.join("-") +
      "&hospitalId=" +
      this.state.hospital.join("-") +
      "&saveSearch=" +
      true +
      "&categoryId=" +
      this.props.selectCategoryId +
      "&subCategoryId=" +
      subCategory +
      "&mileValue=" +
      this.state.mile +
      "&address=" +
      this.state.address +
      "&latitude=" +
      this.state.latitude +
      "&longitude=" +
      this.state.longitude;
   
    this.props.setFilterProviderList(url);
    this.setState({ loading: false });
    this.patientCriteriaModalClose();
    showNotification = {
      title: "Patient Criteria",
      message: "Criteria filter applied successfully.",
      type: "success",
    };
 
  };
  getSubCategory = (event) => {
    if (this.state.readOnly) {
      return true;
    }
    const name = event.target.name;
    let value = event.target.value;
    let subService = "";
    let valueLocal = [];
    if (value && typeof value === "object" && value.includes("")) {
      if (this.state[name].includes("")) {
        valueLocal = value.filter((v, i) => {
          if (v) {
            return v;
          }
        });
      } else {
        valueLocal = [""];
      }
    } else {
      valueLocal = value;
    }
    this.setState(
      {
        [name]: valueLocal,
        subService: false,
        subServices: [],
        selectSubService: [],
      },
      () => this.setService(valueLocal)
    );
  };
  setService = (value) => {
    let localChildMenu = [];
    let subService = false;
    this.state.categoryList.map((category) => {
      if (category.parent == 0 && category.id == this.props.selectCategoryId) {
        category.children.map((children) => {
          if (
            value.includes("" + children.id) &&
            children.children.length > 0
          ) {
            // localChildMenu.push(children.children);
            let localChildMenuVals = localChildMenu;
            localChildMenu = [...localChildMenuVals, ...children.children];
            subService = true;
          }
        });
      }
    });
    this.setState({
      subService: subService,
      subServices: localChildMenu,
    });
  };
  categoryFilterRender = () => {
    return (
      <Box display="flex" justifyContent="flex-start" mb={2}>
        <Box width={1 / 2} pr={2}>
          <FormControl className="chip-select">
            <InputLabel>Filter</InputLabel>
            <Select
              id="service"
              color="secondary"
              name="selectedService"
              // // displayEmpty
              multiple
              value={this.state.selectedService}
              onChange={this.getSubCategory}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={"chip-list-div"}>
                  {selected.includes("") ? (
                    <Chip
                      className={"chipval"}
                      label={"None"}
                      style={{ margin: 2 }}
                    />
                  ) : null}
                  <Scrollbars
                    className="PLangList scrollx_hide"
                    autoHide={false}
                    universal={true}
                    autoHeight={true}
                    autoHeightMin={0}
                    autoHeightMax={40}
                    //style={{ height: "60px" }}
                  >
                    {this.state.selectedCategory.children.map((value, key) => {
                      if (
                        selected.includes("" + value.id) ||
                        selected.includes(value.id)
                      ) {
                        return (
                          <Chip
                            className={"chipval"}
                            key={key}
                            label={value.categoryValue}
                            style={{ margin: 2 }}
                          />
                        );
                      }
                    })}
                  </Scrollbars>
                </div>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
                variant: "menu",
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="">None</MenuItem>
              {typeof this.state.selectedCategory === "object" &&
              this.state.selectedCategory.children.length
                ? this.state.selectedCategory.children.map((category, key) => {
                    return (
                      <MenuItem
                        key={"ic_" + category.id}
                        value={"" + category.id}
                      >
                        {category.categoryValue}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Box>
        {this.state.subService && this.subServiceFilterRender()}
      </Box>
    );
  };
  handleChangeInput = (event) => {
    if (this.state.readOnly) {
      return true;
    }
    const name = event.target.name;
    let value = event.target.value;
    console.log(value);
    let valueLocal = [];
    if (value && typeof value === "object" && value.includes("")) {
      if (this.state[name].includes("")) {
        valueLocal = value.filter((v, i) => {
          if (v) {
            return v;
          }
        });
      } else {
        valueLocal = [""];
      }
    } else {
      valueLocal = value;
    }
    if (name == "mile") {
      if (valueLocal <= 0 && valueLocal != "") {
        this.setState({
          [name]: 1,
        });
      } else {
        this.setState({
          [name]: valueLocal,
        });
      }
    } else {
      this.setState({
        [name]: valueLocal,
      });
    }
  };
  handleChangeRadio(valueflag) {
    let localInfectiousDisease =
      this.state.infectiousDisease == "true" ? "" : "true";
    this.setState({
      infectiousDisease: localInfectiousDisease,
    });
  }
  handleInsuranceClick(event) {
    if (event.target.value === undefined) {
      this.setState({
        insurance: "",
      });
    }
  }
  subServiceFilterRender = () => {
    return (
      <Box width={1 / 2} pl={2}>
        <FormControl className="chip-select">
          <InputLabel>Sub Service</InputLabel>
          <Select
            id="sub-service"
            color="secondary"
            name="selectSubService"
            multiple
            value={this.state.selectSubService}
            onChange={this.handleChangeInput}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={"chip-list-div"}>
                <Scrollbars
                  className="PLangList scrollx_hide"
                  autoHide={false}
                  universal={true}
                  autoHeight={true}
                  autoHeightMin={0}
                  autoHeightMax={40}
                >
                  {this.state.subServices.map((value, key) => {
                    if (selected.includes("" + value.id)) {
                      return (
                        <Chip
                          className={"chipval"}
                          key={key}
                          label={value.categoryValue}
                          style={{ margin: 2 }}
                        />
                      );
                    }
                  })}
                </Scrollbars>
              </div>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
              variant: "menu",
              getContentAnchorEl: null,
            }}
          >
            {typeof this.state.subServices === "object" &&
            this.state.subServices.length
              ? this.state.subServices.map((category, key) => {
                  return (
                    <MenuItem
                      key={"ic_" + category.id}
                      value={"" + category.id}
                    >
                      {category.categoryValue}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
      </Box>
    );
  };
  patientCriteriaModalOpen = () => {
    this.setState({
      patientCriteriaModalStatus: true,
    });
    this.getAddress();
  };
  getAddress = () => {
    setTimeout(() => {
      console.log("address from criteria--->"+this.state.address);
      
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("addressMap")
      );
      autocomplete.addListener("place_changed", () => {
        this.handlePlaceSelection(autocomplete);
      });
    }, 2000);
  };
  patientCriteriaModalClose = () => {
    this.setState({
      patientCriteriaModalStatus: false,
    });
  };
  clearAllFilters = () => {
    this.setState({
      language: [],
      infectiousDisease: "",
      insurance: "",
      subService: [],
      selectSubService: [],
      selectedService: [],
      hospital: [""],
      subService: false,
      mile: 50,
      address: this.state.defaultAddress,
      latitude: this.state.defaultLatitude,
      longitude: this.state.defaultLongitude,
    });
  };
  handleConfirmBox = () => {
    this.setState({
      confirmBoxStatus: false,
    });
  };
  openConfirmBox = (event) => {
    if (event) event.preventDefault();
    this.setState({
      confirmBoxStatus: true,
    });
  };
  render() {
    const { insurance } = this.state;
    const { classes } = this.props;
    return (
      <div className="pos-rel FilterDivAwait">
        <div
          className={`notes-div ${this.state.patientCriteriaModalStatus &&
            `change-right-position`}`}
          onClick={
            this.state.patientCriteriaModalStatus
              ? this.patientCriteriaModalClose
              : this.patientCriteriaModalOpen
          }
        >
          <img src={notesArrow} /> Filter&nbsp;Criteria
        </div>
        <Dialog
          open={this.state.confirmBoxStatus}
          maxWidth={"xs"}
          onClose={this.handleConfirmBox}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal setZ"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Filter Criteria Update
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleCloseForProviderStatus}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              width={1}
              display="flex"
              justifyContent="flex-start"
              mb={1}
              mt={1}
            >
              <Box pr={2}>Do you want to update filter criteria ?</Box>
            </Box>
          </DialogContent>
          <DialogActions className="modal-actions" justify="center">
            <Button type="button" className="btn1" onClick={this.handleSubmit}>
              Confirm
            </Button>
            <Button onClick={this.handleConfirmBox} className="cancel-link">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.patientCriteriaModalStatus}
          maxWidth={"sm"}
          onClose={this.patientCriteriaModalClose}
          aria-labelledby="provider-dialog-title"
          className="add-modal front-modal LevelCriteria "
        >
          <Scrollbars style={{ height: "500px" }} universal={true}>
            <form onSubmit={this.openConfirmBox} noValidate>
              <DialogContent>
                <div className="add-modal-title">Patient Level Criteria await</div>
                <Box display="flex" justifyContent="flex-start" mb={2}>
                  <Box width={1 / 2} pr={2}>
                    <FormControl>
                      <InputLabel
                      // shrink={this.state.insurance == "" ? false : true}
                      >
                        Insurance
                      </InputLabel>
                      <Select
                        id="insurance"
                        color="secondary"
                        name="insurance"
                        value={this.state.insurance}
                        onChange={this.handleChangeInput}
                        onClick={this.handleInsuranceClick}
                      >
                        {typeof this.state.languageAndInsuranceList ===
                          "object" &&
                        this.state.languageAndInsuranceList.masterInsurance
                          .length
                          ? this.state.languageAndInsuranceList.masterInsurance
                              .sort((a, b) =>
                                a.insuranceId > b.insuranceId ? 1 : -1
                              )
                              .map((iList, key) => {
                                if (
                                  iList.insuranceId == 0 ||
                                  this.state.insurance == iList.insuranceId ||
                                  this.state.userInsurance == iList.insuranceId
                                ) {
                                  return (
                                    <MenuItem
                                      key={key}
                                      value={iList.insuranceId}
                                    >
                                      {iList.insuranceName}
                                    </MenuItem>
                                  );
                                }
                              })
                          : null}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box width={1 / 2} pl={2}>
                    <FormControl className="chip-select">
                      <InputLabel>Hospital Restriction </InputLabel>
                      <Select
                        id="hospital"
                        color="secondary"
                        name="hospital"
                        multiple
                        value={this.state.hospital}
                        onChange={this.handleChangeInput}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={"chip-list-div"}>
                            {selected.includes("") ? (
                              <Chip
                                className={"chipval"}
                                label={"None"}
                                style={{ margin: 2 }}
                              />
                            ) : null}
                            <Scrollbars
                              className="PLangList scrollx_hide"
                              autoHide={false}
                              universal={true}
                              autoHeight={true}
                              autoHeightMin={0}
                              autoHeightMax={40}
                            >
                              {this.state.hospitalList.map((value, key) => {
                                if (
                                  selected.includes(value.previousHospitalId) ||
                                  selected.includes(
                                    "" + value.previousHospitalId
                                  )
                                ) {
                                  return (
                                    <Chip
                                      className={"chipval"}
                                      key={key}
                                      label={value.hospitalName}
                                      style={{ margin: 2 }}
                                    />
                                  );
                                }
                              })}
                            </Scrollbars>
                          </div>
                        )}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: "menu",
                          getContentAnchorEl: null,
                        }}
                      >
                        {/* <MenuItem value="">Select</MenuItem> */}
                        <MenuItem value="">None</MenuItem>
                        {typeof this.state.hospitalList === "object" &&
                        this.state.hospitalList.length
                          ? this.state.hospitalList.map((hList, key) => {
                              return (
                                <MenuItem
                                  key={key}
                                  value={hList.previousHospitalId}
                                >
                                  {hList.hospitalName}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                      <Tooltip
                        title="Select  if that hospital mandates the patient's care to a specific set of providers"
                        placement="top"
                        arrow
                      >
                        <img className="info-icon pointer" src={infoIconW} />
                      </Tooltip>
                    </FormControl>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="flex-start" mb={3}>
                  <Box width={1 / 2} pr={2}>
                    <FormControl className="chip-select">
                      <InputLabel>Preferred Language</InputLabel>
                      <Select
                        id="insurance"
                        color="secondary"
                        name="language"
                        multiple
                        value={this.state.language}
                        onChange={this.handleChangeInput}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={"chip-list-div"}>
                            {selected.includes("") ? (
                              <Chip
                                className={"chipval"}
                                label={"None"}
                                style={{ margin: 2 }}
                              />
                            ) : null}
                            <Scrollbars
                              className="PLangList scrollx_hide"
                              autoHide={false}
                              universal={true}
                              autoHeight={true}
                              autoHeightMin={0}
                              autoHeightMax={40}
                            >
                              {this.state.languageAndInsuranceList.masterLanguages.map(
                                (value, key) => {
                                  if (
                                    selected.includes(value.languageId) ||
                                    selected.includes("" + value.languageId)
                                  ) {
                                    return (
                                      <Chip
                                        className={"chipval"}
                                        key={key}
                                        label={value.languageName}
                                        style={{ margin: 2 }}
                                      />
                                    );
                                  }
                                }
                              )}
                            </Scrollbars>
                          </div>
                        )}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: "menu",
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="">None</MenuItem>
                        {typeof this.state.languageAndInsuranceList ===
                          "object" &&
                        this.state.languageAndInsuranceList.masterLanguages
                          .length
                          ? this.state.languageAndInsuranceList.masterLanguages.map(
                              (iList, key) => {
                                return (
                                  <MenuItem
                                    key={"insurance_" + iList.languageId}
                                    value={iList.languageId}
                                  >
                                    {iList.languageName}
                                  </MenuItem>
                                );
                              }
                            )
                          : null}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box width={1 / 2} pl={2}>
                    <FormControl>
                      <div className="chip-title">Infectious Disease</div>
                      <Box
                        width={1}
                        className="filter-chips"
                        display="flex"
                        justifyContent="flex-start"
                        flexWrap={"wrap"}
                      >
                        <Chip
                          icon={
                            <Checkbox
                              icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />}
                              checked={
                                this.state.infectiousDisease == "true"
                                  ? true
                                  : false
                              }
                            />
                          }
                          label={"Yes"}
                          onClick={() => this.handleChangeRadio("true")}
                          className={
                            this.state.infectiousDisease == "true"
                              ? "chipinput activeCat"
                              : "chipinput inactiveCat"
                          }
                        />
                      </Box>
                    </FormControl>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={3}>
                  <Box width={1 / 2} pr={2}>
                    <FormControl className="chip-select mile-text-box">
                      <CustomInput
                        labelText="Address"
                        id="addressMap"
                        inputProps={{
                          type: "text",
                          name: "address",
                          autoComplete: "off",
                          required: true,
                          value: this.state.address ? this.state.address : " ",
                          className: "mile_text",
                          min: "1",
                          onChange: (event) => this.handleChangeInput(event),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box width={1 / 2} pr={2}>
                    <FormControl className="chip-select mile-text-box mile-left">
                      {/* <CustomInput
                                            type="number"
                                            name='mile'
                                            label="Mile"
                                            placeholder="Enter mile"
                                            data-validators="isRequired"
                                            value={this.state.mile}
                                            onChange={this.handleChangeInput}
                                            className='comment-TextField'
                                        /> */}
                      <CustomInput
                        labelText="Mile Radius"
                        id="mile"
                        inputProps={{
                          type: "number",
                          name: "mile",
                          autoComplete: "off",
                          required: true,
                          value: this.state.mile,
                          className: "mile_text",
                          min: "1",
                          onChange: (event) => this.handleChangeInput(event),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  width={1}
                  className="CategoryCriteria-text mile-category"
                >
                  Category Criteria -{" "}
                  {this.state.selectedCategory.categoryValue}
                </Box>
                {this.categoryFilterRender()}
              </DialogContent>

              <DialogActions className="modal-actions" justify="center">
                {this.state.readOnly ? (
                  ""
                ) : (
                  <Button type="submit" className="btn1">
                    {this.state.loading && (
                      <CircularProgress size={24} className="buttonProgress" />
                    )}
                    Update
                  </Button>
                )}
                <Button
                  onClick={this.patientCriteriaModalClose}
                  className="cancel-link btn2"
                >
                  Cancel
                </Button>
                {this.state.readOnly ? (
                  ""
                ) : (
                  <div className="ClearAll-link" onClick={this.clearAllFilters}>
                    Clear All
                  </div>
                )}
              </DialogActions>
            </form>
          </Scrollbars>
        </Dialog>
      </div>
    );
  }
}
export const PatientCriteriaClass = connect(mapStateToProps)(PatientCriteria);
export default PatientCriteriaClass;
