import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import SideBar from "./SideBar";
import "./calendar.css";
import Filter from "./SideBar/Filter";
import { Button, LinearProgress } from "@material-ui/core";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import Axios from "axios";
import { apiPath } from "api";
import moment from "moment";
import { userService } from "_services/user.service";
import { Tooltip } from "bootstrap";
import { SELECTED_PATIENT_LIST } from "__helpers/constants";
import { basePath } from "base-routes";
import { dpBaseRoutes } from "base-routes";

import useDeviceDetector from "./DeviceDetector";
import PrintableCalendar from "./PrintableCalendar";

// import "bootstrap/dist/css/bootstrap.min.css";

// ** CalendarColors
const calendarsColor = {
  TASK: "task",
  TRANSPORT: "transport",
  ESTIMATED_DC: "estimated-dc",
};

const formatDate = (date) => {
  return moment(date)
    .local()
    .format("YYYY-MM-DD");
};

const getWeekFirstAndLastDate = (fromDate) => {
  var date = new Date(fromDate.getTime());
  var sunday = new Date(date.setDate(date.getDate() - date.getDay())),
    result = [formatDate(new Date(sunday))];
  result.push(formatDate(new Date(sunday.setDate(sunday.getDate() + 6))));
  return result;
};

const getMonthFirstAndLastDate = (fromDate) => {
  var firstDay = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
  var lastDay = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
  return [formatDate(firstDay), formatDate(lastDay)];
};

const getPreviousMonthFirstDateAndNextMonthLastDate = (fromDate) => {
  var firstDay = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, 1);
  var lastDay = new Date(fromDate.getFullYear(), fromDate.getMonth() + 2, 0);

  return [formatDate(firstDay), formatDate(lastDay)];
};

let tooltipInstance = null;

export default function Calendar({
  isPatientSearch = true,
  patientIdArray = [],
  ...props
}) {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  const [calendarApi, setCalendarApi] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [seletedDate, setSelectedDate] = useState(new Date());
  const [isMontly, setIsMontly] = useState(true);
  const [isWeekly, setIsWeekly] = useState(false);
  const [filter, setFilter] = useState([0, 1, 2]);
  const [patientIds, setPatientIds] = useState(patientIdArray);
  // ** Refs
  const calendarRef = useRef(null);
  const isMobile = useDeviceDetector();
  const contentRef = useRef(null);
  //fetch calendar data
  const fetchCalendarData = async (params) => {
    return new Promise((resolve, reject) => {
      if (params.startDate) {
        setLoading(true);

        Axios.post(
          apiPath.fetchCalendarData,
          {
            endDate: params.endDate,
            filter: filter,
            patients: patientIds,
            startDate: params.startDate,
          },
          {
            headers: generalAuthTokenHeader(),
          }
        )
          .then((response) => {
            // setEvents(response.data);
            setLoading(false);
            return resolve(response);
          })
          .catch((error) => {
            setLoading(false);
            const showNotification = {
              title: "Calendar",
              message: "Bad response from server",
              type: "danger",
            };
            userService.showNotification(showNotification);
            return reject(error);
          });
      }
    });
  };

  useEffect(() => {
    //check is montly
    if (isMontly) {
      let [startDay, endDay] = getPreviousMonthFirstDateAndNextMonthLastDate(
        seletedDate
      );
      setStartDate(startDay);
      setEndDate(endDay);
    }
    //check is weekly
    if (isWeekly) {
      let [startDay, endDay] = getWeekFirstAndLastDate(seletedDate);
      setStartDate(startDay);
      setEndDate(endDay);
    }
  }, [isMontly, isWeekly, seletedDate]);

  // ** refetchEvents
  const refetchEvents = () => {
    if (calendarApi !== null) {
      calendarApi.refetchEvents();
    }
  };

  // useEffect(() => {

  //   if (isWeekly) {
  //     const [startDay, endDay] = getWeekFirstAndLastDate(seletedDate);
  //     setStartDate(startDay);
  //     setEndDate(endDay);
  //   }
  //   //check is montly
  //   if (isMontly) {
  //     const [startDay, endDay] = getMonthFirstAndLastDate(seletedDate);
  //     setStartDate(startDay);
  //     setEndDate(endDay);
  //   }
  // }, []);

  useEffect(() => {}, []);
  // ** Switch calendar to weekly
  const switchCalendarWeekly = () => {
    console.log(calendarApi);
    setIsMontly(false);
    setIsWeekly(true);
    calendarApi.changeView("timeGridWeek");
  };
  // ** Switch calendar to montly
  const switchCalendarMonthly = () => {
    console.log(calendarApi);
    setIsMontly(true);
    setIsWeekly(false);
    calendarApi.changeView("dayGridMonth");
  };

  // ** UseEffect checks for CalendarAPI Update
  useEffect(() => {
    if (calendarApi === null) {
      setCalendarApi(calendarRef.current.getApi());
    }
  }, [calendarApi]);

  const renderEvents = (events) => {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    let result = [];
    events.map((event) => {
      if (event.filter == "TASK") {
        result.push({
          ...event,
          date: moment(event.date)
            .add(offset, "minutes")
            .toDate(),
          title: `Task: ${event.title}`,
        });
      }
      if (event.filter == "TRANSPORT") {
        let startDate = moment(
          `${event.date} ${event.time}`,
          "YYYY-MM-DD HH:mm a"
        )
          .add(offset, "minutes")
          .toDate();
        let endDate = moment(startDate)
          .add(moment.duration(event.refferalTransportEntity.waitTime))

          .toDate();
        result.push({
          ...event,
          title: `Transport: ${event.title} ${
            event.provider ? `By ${event.provider?.providerName}` : ""
          }`,
          date: startDate,
          end: endDate,
        });
      }
      if (event.filter == "ESTIMATED_DC") {
        result.push({
          ...event,
          date: moment(event.date)
            .add(offset, "minutes")
            .toDate(),
          title: `Est DC: ${event.title}`,
        });
      }
    });
    return result;
  };
  const calendarOptions = {
    events: renderEvents(events),
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    initialView: "dayGridMonth",
    headerToolbar: {
      end: "",
      start: "",
    },
    allDaySlot: true,
    scrollTime: "00:00:00",
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: false,
    /*
      Determines the number of weeks displayed in a month view.
      ? Docs: https://fullcalendar.io/docs/fixedWeekCount
    */
    fixedWeekCount: false,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: false,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    //   dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: false,

    eventClassNames({ event: calendarEvent }) {
      //  eslint-disable-next-line no-underscore-dangle
      let taskClass = "";
      if (calendarEvent._def.extendedProps?.patientTask?.changeStatus == 6) {
        taskClass = "bg-task-complete";
      }
      console.log(
        calendarEvent._def.extendedProps?.patientTask?.changeStatus == 6
      );
      const colorName = calendarsColor[calendarEvent._def.extendedProps.filter];
      return [
        // Background Color
        `bg-${colorName} ${taskClass}`,
      ];
    },

    eventClick({ event: clickedEvent, jsEvent, ...rest }) {
      if (isMobile && jsEvent.detail == 1) {
        return;
      }
      handleMouseLeave();

      if (clickedEvent._def.extendedProps.filter == "TASK") {
        // eslint-disable-next-line react/prop-types
        if (props.openTaskModal) {
          // eslint-disable-next-line react/prop-types
          props.openTaskModal(clickedEvent._def.extendedProps.patientTask.id);
          return;
        }
        localStorage.setItem(
          SELECTED_PATIENT_LIST,
          JSON.stringify(
            clickedEvent._def.extendedProps.patientEntity.patientId
          )
        );

        localStorage.setItem(
          "task_id",
          JSON.stringify(clickedEvent._def.extendedProps.patientTask.id)
        );

        // eslint-disable-next-line react/prop-types
        props.history.push(
          basePath +
            dpBaseRoutes.singlePatientInfo.path +
            "?" +
            "&mode=" +
            btoa(btoa(false))
        );
      }
      if (clickedEvent._def.extendedProps.filter == "TRANSPORT") {
        if (props.openTaskModal) {
          // eslint-disable-next-line react/prop-types
          // props.openTaskModal(clickedEvent._def.extendedProps.patientTask.id);
          return;
        }
        localStorage.setItem(
          SELECTED_PATIENT_LIST,
          JSON.stringify(
            clickedEvent._def.extendedProps.patientEntity.patientId
          )
        );
        props.history.push(
          basePath +
            dpBaseRoutes.singlePatientInfo.path +
            "?key=52" +
            "&mode=" +
            btoa(btoa(false))
        );
      }

      if (clickedEvent._def.extendedProps.filter == "ESTIMATED_DC") {
        if (props.openTaskModal) {
          // eslint-disable-next-line react/prop-types
          // props.openTaskModal(clickedEvent._def.extendedProps.patientTask.id);
          return;
        }
        localStorage.setItem(
          SELECTED_PATIENT_LIST,
          JSON.stringify(
            clickedEvent._def.extendedProps.patientEntity.patientId
          )
        );
        props.history.push(
          basePath +
            dpBaseRoutes.singlePatientInfo.path +
            "?" +
            "&mode=" +
            btoa(btoa(false))
        );
      }

      // dispatch(selectEvent(clickedEvent));
      // handleAddEventSidebar();
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      // event.value = grabEventDataFromEventApi(clickedEvent)
      // eslint-disable-next-line no-use-before-define
      // isAddNewEventSidebarActive.value = true
    },

    customButtons: {
      // sidebarToggle: {
      //   text: <Menu className="d-xl-none d-block" />,
      //   click() {
      //     toggleSidebar(true);
      //   },
      // },
    },

    dateClick(info) {
      // const ev = blankEvent;
      // ev.start = info.date;
      // ev.end = info.date;
      // dispatch(selectEvent(ev));
      // handleAddEventSidebar();
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      // dispatch(updateEvent(droppedEvent));
      // toast.success(
      //   <ToastComponent
      //     title="Event Updated"
      //     color="success"
      //     icon={<Check />}
      //   />,
      //   {
      //     autoClose: 2000,
      //     hideProgressBar: true,
      //     closeButton: false,
      //   }
      // );
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      // dispatch(updateEvent(resizedEvent));
      // toast.success(
      //   <ToastComponent
      //     title="Event Updated"
      //     color="success"
      //     icon={<Check />}
      //   />,
      //   {
      //     autoClose: 2000,
      //     hideProgressBar: true,
      //     closeButton: false,
      //   }
      // );
    },

    ref: calendarRef,

    // Get direction from app state (store)
    //  direction: isRtl ? "rtl" : "ltr",
  };
  const getHoverTitle = (event) => {
    console.log(event);
    let result = "No Information";
    if (event.extendedProps.filter == "TASK") {
      result = `${event.extendedProps.patientEntity.lastName}, ${event.extendedProps.patientEntity.firstName}`;
    }
    if (event.extendedProps.filter == "TRANSPORT") {
      result = `${event.extendedProps.patientEntity.lastName}, ${event.extendedProps.patientEntity.firstName}`;
      // result = `${
      //   event.extendedProps.provider
      //     ? event.extendedProps.provider?.providerName
      //     : ""
      // }`;
    }
    if (event.extendedProps.filter == "ESTIMATED_DC") {
      result = `${event.extendedProps.patientEntity.lastName}, ${event.extendedProps.patientEntity.firstName}`;
    }
    return result;
  };
  // actions
  const handleMouseEnter = (info) => {
    handleMouseLeave();
    let hoverTitle = getHoverTitle(info.event._def);

    if (info.event.title) {
      tooltipInstance = new Tooltip(info.el, {
        title: hoverTitle,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body",
      });
      tooltipInstance.show();
    }
  };

  const handleMouseLeave = (info) => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };
  const print = async () => {
    if (calendarRef.current) {
      const printWindow = window.open("", "_blank");

      // Check if the window is null (in case of a popup blocker)
      if (!printWindow) {
        alert("Please allow pop-ups for printing.");
      } else {
        // Render the PrintableCalendar component in the new window
        printWindow.document.body.innerHTML = `
          <html>
          <head>
            <title>Printable Calendar</title>
            <!-- Include any necessary CSS styles here -->
          </head>
          <body>
            <PrintableCalendar
              events={events}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              calendarOptions={calendarOptions}
            />
          </body>
          </html>
        `;

        // Wait for the content to load before triggering the print
        printWindow.onload = () => {
          printWindow.print();
          printWindow.onafterprint = () => {
            printWindow.close();
          };
        };
      }
    }
  };

  useEffect(() => {
    fetchCalendarData({
      startDate,
      endDate,
    }).then((response) => {
      setEvents(response.data);
    });
  }, [startDate, endDate, filter.length, patientIds]);

  return (
    <div>
      {loading && (
        <div className="LinearProgress-ref">
          <LinearProgress />
        </div>
      )}
      <h2 class="titlename calendar-title">Calendar</h2>
      <div className="app-calendar">
        <div className="app-calendar-sidebar ">
          <div>
            <button
              className={`weekly-btn me-1 ${isWeekly ? "active" : ""}`}
              onClick={() => {
                switchCalendarWeekly();
              }}
            >
              Weekly
            </button>
            <button
              className={`monthly-btn ${isMontly ? "active" : ""}`}
              onClick={() => {
                switchCalendarMonthly();
              }}
            >
              Monthly
            </button>
          </div>
          <SideBar
            seletedDate={seletedDate}
            setSelectedDate={setSelectedDate}
            calendarApi={calendarApi}
            isMontly={isMontly}
            isWeekly={isWeekly}
            setIsMontly={setIsMontly}
            setIsWeekly={setIsWeekly}
            setFilter={setFilter}
            filter={filter}
            events={events}
            patientIds={patientIds}
            fetchCalendarData={fetchCalendarData}
          />
          <Filter
            isPatientSearch={isPatientSearch}
            filter={filter}
            setFilter={setFilter}
            setPatientIds={setPatientIds}
          />
          {/* <span onClick={print}>Print</span> */}
        </div>

        <div
          ref={contentRef}
          className="app-calendar-date"
          id="content-to-print"
        >
          {" "}
          <FullCalendar
            eventMouseEnter={handleMouseEnter}
            eventMouseLeave={handleMouseLeave}
            {...calendarOptions}
          />
        </div>
      </div>
    </div>
  );
}
