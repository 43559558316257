/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Box,
  TextField,
  Avatar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";
import PhoneNumber from "awesome-phonenumber";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  genderType,
  EMAIL_REGEX,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ZIPCODE_REGEX,
  ZIP_CODE_LENGTH,
  MIN_AGE_YEARS,
  ALLOWED_IMAGE_EXTENTION,
  DEFAULT_PROFILE_IMG,
  DIGIT_ONLY,
  PHONE_NO_INVALID_LENGTH,
  OK_SUCCESS_STATUS,
  STATUS_CREATED,
  INVALID_DATA_POST,
  SECURITY_ERROR,
  PROVIDER,
} from "__helpers/constants";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import baseRoutes from "base-routes";
import ChangePassword from "./ChangePassword";
import fileExtension from "file-extension";
import { generalAuthTokenHeader } from "__helpers/auth-header";

import { withRouter } from "react-router";

import { connect } from "react-redux";
import { addUSerUInfo, reduxLoad } from "js/actions";
import { USER_INFO } from "__helpers/constants";
import { dpBaseRoutes } from "base-routes";
import { basePath } from "base-routes";
import backArrow from "assets/img/icons/arrow-back.svg";
import BasicProfile from "./BasicProfile";
import OrganizationDetails from "./OrganizationDetails";
import InDevlopment from "./InDevlopment";
import ProfileCriteria from "./PatientCriteria";
import ServiceCategory from "./ServiceCategory";
import SubscriptionModule from "./Subscription";
import DataUses from "./DataUses";
import Invoice from "./Invoice";
import Address from "./OtherAddress";
import Banner from "components/Banner/Banner";

function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: (addUSerUInfoVal) => dispatch(addUSerUInfo(addUSerUInfoVal)),
    addTimeStamp: (addTimeStampVal) => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    loadingFlag: state.loadingFlag,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF",
    },
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  disabledDropdownBlack: {
    color: "#000 !important",
  },
});

class ProfileClass extends React.Component {
  inputOpenFileRef = React.createRef(null);
  maxDate = new Date();
  constructor(props) {
    super(props);
    /* create Ref for file input  */
    this.filename;
    this.profileImageBlob = null;
    let userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const url = new URLSearchParams(this.props.location.search);
    const tabMode = url.get("tab");

    this.state = {
      selectedTab: tabMode ? tabMode : "orginational",
      openModal: false,
      bannerText: "",
      bannerName: "",
      providerStatusId: "",
    };
    this.selectProfileTab = this.selectProfileTab.bind(this);
    this.refreshBanner = this.refreshBanner.bind(this);
  }
  componentDidMount() {
    let bannerText = JSON.parse(localStorage.getItem("provider_banner_text"));
    if (bannerText && bannerText.msg) {
      this.setState({
        bannerText: bannerText.msg,
        bannerName: bannerText.name,
      });
    }
    this.setState({
      providerStatusId: bannerText.id,
    });
    let user = JSON.parse(localStorage.getItem(USER_INFO));
    if (user.newUser) {
      this.setState({
        openModal: true,
      });
    }
  }
  componentDidUpdate() {
    let bannerText = JSON.parse(localStorage.getItem("provider_banner_text"));
    if (
      bannerText &&
      bannerText.name &&
      this.state.bannerName != bannerText.name
    ) {
      this.setState({
        bannerText: bannerText.msg,
        bannerName: bannerText.name,
      });
    }
  }
  handleClose() {}
  selectProfileTab(selectedTab) {
    this.setState({
      selectedTab: selectedTab,
    });
  }
  async refreshBanner() {
    let user = JSON.parse(localStorage.getItem(USER_INFO));
    if (user.usrType == PROVIDER) {
      let providerStatus = await userService.fetchBannerText();
      if (providerStatus) {
        localStorage.setItem(
          "provider_banner_text",
          JSON.stringify(providerStatus)
        );
        if (
          providerStatus &&
          providerStatus.name &&
          this.state.bannerName != providerStatus.name
        ) {
          this.setState({
            bannerText: providerStatus.msg,
            bannerName: providerStatus.name,
          });
        } else {
          this.setState({
            bannerText: "",
            bannerName: "",
          });
        }
      } else {
        localStorage.setItem("provider_banner_text", "");
        this.setState({
          bannerText: "",
          bannerName: "",
        });
      }
    }
  }
  render() {
    const { isOpen, role, hasError } = this.state;
    const { classes } = this.props;
    const stylead = { borderBottom: "" };
    const user_image = "user-image";
    const styleuser = {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
    };
    return (
      <div className="ProviderProfile">
        {this.state.bannerText && <Banner message={this.state.bannerText} />}
        <ChangePassword
          id="change-pssword-dialog"
          keepMounted
          open={this.state.openModal}
          handleChangePasswordDialog={this.handleClose}
          onClose={this.handleClose}
          value={""}
          allowCloseModal={false}
        />
        <Box
          display="flex"
          flexDirection="row"
          width={1}
          mt={1.5}
          className="provider-profile-page"
        >
          <Box className="left-tabs">
            <div>
              <div
                className={
                  this.state.selectedTab == "orginational"
                    ? "tab-link active"
                    : "tab-link inactive"
                }
                onClick={() => this.selectProfileTab("orginational")}
              >
                Organization Details
              </div>
              <div
                className={
                  this.state.selectedTab == "otherAddress"
                    ? "tab-link active"
                    : "tab-link inactive"
                }
                onClick={() => this.selectProfileTab("otherAddress")}
              >
                Additional Locations
              </div>
              <div
                className={
                  this.state.selectedTab == "personal"
                    ? "tab-link active"
                    : "tab-link inactive"
                }
                onClick={() => this.selectProfileTab("personal")}
              >
                User Details
              </div>
              {/* {this.state.providerStatusId !== 3 && */}
              <div
                className={
                  this.state.selectedTab == "billing"
                    ? "tab-link active"
                    : "tab-link inactive"
                }
                onClick={() => this.selectProfileTab("billing")}
              >
                Subscription Details
              </div>
              {/* } */}
              <div
                className={
                  this.state.selectedTab == "service"
                    ? "tab-link active"
                    : "tab-link inactive"
                }
                onClick={() => this.selectProfileTab("service")}
              >
                Service Categories
              </div>
              <div
                className={
                  this.state.selectedTab == "criteria"
                    ? "tab-link active"
                    : "tab-link inactive"
                }
                onClick={() => this.selectProfileTab("criteria")}
              >
                Patient Criteria
              </div>
              <div
                className={
                  this.state.selectedTab == "datauses"
                    ? "tab-link active"
                    : "tab-link inactive"
                }
                onClick={() => this.selectProfileTab("datauses")}
              >
                Data Usages
              </div>
              <div
                className={
                  this.state.selectedTab == "invoices"
                    ? "tab-link active"
                    : "tab-link inactive"
                }
                onClick={() => this.selectProfileTab("invoices")}
              >
                Invoices
              </div>
              <div
                className={
                  this.state.selectedTab == "providers"
                    ? "tab-link active"
                    : "tab-link inactive"
                }
                onClick={() =>
                  window.location.replace(basePath + baseRoutes.provider.path)
                }
              >
                Back to Providers
              </div>
            </div>
          </Box>
          <Box className="right-tabs-cover">
            <div className="right-tabs-content">
              {{
                orginational: <OrganizationDetails />,
                personal: <BasicProfile />,
                billing: (
                  <SubscriptionModule refreshBanner={this.refreshBanner} />
                ),
                service: <ServiceCategory />,
                criteria: <ProfileCriteria />,
                datauses: <DataUses />,
                invoices: <Invoice />,
                otherAddress: <Address />,
              }[this.state.selectedTab] || (
                <div className="tab-heading">Organization Details</div>
              )}
            </div>
          </Box>
        </Box>
      </div>
    );
  }
}

ProfileClass.propTypes = {
  classes: PropTypes.object,
};
const Profile = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileClass);
export default withStyles(styles)(Profile);
