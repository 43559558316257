import React from 'react'
import {
    Button
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
function DialogAction(props) {
    const {validation, loading, dataId, modalClose} = props;
    return (
        <div>
           <Button type="submit" className="btn1" disabled={ validation || loading } >
                {loading && (
                    <CircularProgress
                        size={24}
                        className="buttonProgress"
                    />
                )}
                {dataId ? 'Update' : 'Add'}
            </Button>
            <Button onClick={modalClose} color="primary">
                Cancel
            </Button> 
        </div>
    )
}

export default DialogAction
