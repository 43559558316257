import React, { Component } from "react";

import {
  Box,
  Icon,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TableSortLabel,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import Scrollbars from "react-custom-scrollbars";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import { userService } from "_services/user.service";
import { PATIENT_LIST, TASK_STATUS_OBJ } from "__helpers/constants";
import PatientTasks from "../SinglePatientDetail/PatientTasks";
import tasksImg from "assets/img/icons/tasksImg.png";
import taskAdd from "assets/img/icons/plus.svg";
import layout from "assets/img/layout.png";

import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import logoMrj from "assets/img/asglogo.png";
import enMsg from "__helpers/locale/en/en";

const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};

class TasksList extends Component {
  constructor(props) {
    super(props);
    let userinfo = JSON.parse(localStorage.getItem("_user_info"));
    console.log("userinfo---details", userinfo);
    this.dateUtility = new DateFnsUtils();
    this.state = {
      tasks: "",
      loading: false,
      patientName: "",
      patientId: "",
      patientTaskId: "",
      openTaskModal: false,
      openTemplates: false,
      openPatientTask: false,
      dischargePlannerId: userinfo.dischargePlanner.id,
      facilityId: userinfo.dischargePlanner.facility.facilityId,
      apiPartner: "",
      templates: [],
      sortBy: "taskName",
      sortAsc: true,
      selectedTemplateId: "",
      selectedTemplateTasks: [],
      selectedTemplateIds: [],
      printTaskList: [],
      patientDetail: this.props.patientDetail ? this.props.patientDetail : {},
      patientTaskGroup: this.props.patientTaskGroup
        ? this.props.patientTaskGroup
        : [],
      multiPatientMode: this.props.multiPatientMode
        ? this.props.multiPatientMode
        : false,
    };
    this.statusDescriptions = {
      4: { description: "Incomplete", color: "#0D81B4" },
      5: { description: "Issue", color: "#FF0000 " },
      6: { description: "Complete", color: "#01A7A6" },
    };
    this.handleOpenTemplates = this.handleOpenTemplates.bind(this);
    this.openTaskModal = this.openTaskModal.bind(this);
    this.updatePatientModalClose = this.updatePatientModalClose.bind(this);
    this.loaderStatus = this.loaderStatus.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.handleDownloadPDF = this.handleDownloadPDF.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let tasks = nextProps.tasks;
    let patientName = nextProps.patientName;
    let patientId = nextProps.patientId;
    let loading = nextProps.loading;
    let multiPatientMode = nextProps.multiPatientMode;
    this.setState({
      tasks: tasks,
      loading: loading,
      patientName: patientName,
      patientId: patientId,
      multiPatientMode: multiPatientMode,
      patientDetail: nextProps.patientDetail ? nextProps.patientDetail : {},
      patientTaskGroup: nextProps.patientTaskGroup
        ? nextProps.patientTaskGroup
        : [],
      templatesValue: nextProps.templatesValue ? nextProps.templatesValue : [],
    });
  }

  updatePatientModalClose = () => {
    let patientList = JSON.parse(localStorage.getItem(PATIENT_LIST));
    this.setState({
      patientModalStatus: false,
      openTaskModal: false,
      patientItemList: patientList,
    });
  };

  openTaskModal(taskId) {
    this.setState({
      openTaskModal: true,
      patientTaskId: taskId,
    });
  }

  loaderStatus = (status) => {
    this.setState({
      loading: status,
    });
  };

  async handleOpenTemplates() {
    this.setState({ openTemplates: true });
    let selectedTemplateIds = this.state.selectedTemplateIds;
    selectedTemplateIds.splice(0, selectedTemplateIds.length);
    let showNotification = {};
    const apiUrl =
      apiPath.getTaskTemplateByDPAll + "?facilityId=" + this.state.facilityId;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: generalAuthTokenHeader(),
      });

      if (!response.ok) {
        if (response.status === 400) {
          showNotification = {
            title: "Template",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
        } else {
          showNotification = {
            title: "Template",
            message: "Bad response from server",
            type: "danger",
          };
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Fetch Templates Data", data);

      this.setState({
        templates: data,
      });
    } catch (error) {
      console.error("Error fetching template tasks:", error);
      showNotification = {
        title: "Task Template",
        message: "Error fetching template tasks",
        type: "danger",
      };
    }

    userService.showNotification(showNotification);
  }

  handleCloseTemplates = () => {
    this.setState({ openTemplates: false });
    this.setState({ openPatientTask: false });
  };

  handleTemplateSelect = (templateId) => {
    const name = templateId;
    let value = templateId;

    let selectedTemplateIds = this.state.selectedTemplateIds;
    if (!selectedTemplateIds.includes(name)) {
      selectedTemplateIds.push(name);
    } else {
      const index = selectedTemplateIds.indexOf(name);
      if (index > -1) {
        selectedTemplateIds.splice(index, 1);
      }
    }
    this.setState({
      selectedTemplateIds: selectedTemplateIds,
    });
  };

  handleSaveTemplates = async () => {
    const {
      patientId,
      facilityId,
      dischargePlannerId,
      selectedTemplateIds,
      templates,
    } = this.state;
    this.setState({ openTemplates: false });
    const payload = selectedTemplateIds.map((templateId) => {
      const template = templates.find((t) => t.id === templateId);
      return {
        patientId,
        templateId,
        templateName: template.templateName,
        dischargePlannerId,
        facilityId,
      };
    });
    try {
      const response = await fetch(apiPath.PatientTaskBulk, {
        method: "POST",
        headers: {
          ...generalAuthTokenHeader(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      let showNotification = {};

      if (response.status === 201) {
        const data = await response.json();
        console.log("Save Templates Response", data);
        showNotification = {
          title: "Save Tasks",
          message: "Task Added successfully",
          type: "success",
        };
        this.props.updatePatient();
      } else {
        showNotification = {
          title: "Save Tasks",
          message: "Error saving Tasks",
          type: "danger",
        };
      }

      console.log("Show notification:", showNotification);
      userService.showNotification(showNotification);
    } catch (error) {
      console.error("Error during fetch:", error);
      userService.showNotification({
        title: "Save Tasks",
        message: "Error saving Tasks",
        type: "danger",
      });
    }
  };

  handlePrint = async (patientId) => {
    let selectedTemplateIds = this.state.selectedTemplateIds;
    selectedTemplateIds.splice(0, selectedTemplateIds.length);
    try {
      // Fetch patient tasks specific to the current patient (this.state.patientId)
      let apiUrl = apiPath.getPatientList + "/" + this.state.patientId;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: generalAuthTokenHeader(),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch patient tasks. Status: ${response.status}`
        );
      }
      const data = await response.json();
      this.setState({
        printTaskList: data[0].patientTask,
        printTaskListGroup: data,
        openPatientTask: true, // Open dialog after fetching tasks
      });
    } catch (error) {
      console.error("Error fetching patient tasks:", error);
    }
  };

  async handleDownloadPDF() {
    console.log("Print button clicked");
    console.log("Selected Template IDs:", this.state.selectedTemplateIds);
    let showNotification = {};
    try {
      if (this.state.selectedTemplateIds.length === 0) {
        showNotification = {
          title: "Error",
          message: "No templates selected",
          type: "danger",
        };
        userService.showNotification(showNotification);
        return;
      }
      const formattedTemplateIds = this.state.selectedTemplateIds.map(
        (id) => `${id}`
      );
      const apiUrl = `${apiPath.getPdfDownload}/${this.state.patientId}`;
      console.log("API URL:", apiUrl);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          ...generalAuthTokenHeader(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedTemplateIds),
      });

      if (response.status === 400) {
        showNotification = {
          title: enMsg.sessionExpireTitle,
          message: enMsg.sessionExpire,
          type: "warning",
        };
        this.setState({ loading: false });
        return;
      } else if (response.status === 401 || !response.ok) {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
        this.setState({ loading: false });
        return;
      }
      const blob = await response.blob();
      console.log("Blob size:", blob.size);
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
      showNotification = {
        title: "Preview PDF",
        message: "Preview successful, please check the new tab",
        type: "success",
      };
      this.setState({ openPatientTask: false });
      this.setState({ loading: false });
    } catch (error) {
      console.error("Download PDF error:", error);
      showNotification = {
        title: enMsg.connectionFailed,
        message: enMsg.networkFailedError,
        type: "danger",
      };
      this.setState({ loading: false });
    }
    userService.showNotification(showNotification);
  }
  handleSort = (sortBy) => {
    console.log("clicked");
  
    const { printTaskListGroup, sortAsc } = this.state;
  
    // Toggle sorting order
    const newSortAsc = this.state.sortBy === sortBy ? !sortAsc : true;
  
    const sortedList = printTaskListGroup.sort((a, b) => {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
  
      // Check for undefined values
      if (valueA === undefined || valueB === undefined) return 0;
  
      // String comparison for taskName
      if (sortBy === 'taskName' && typeof valueA === "string" && typeof valueB === "string") {
        const upperValueA = valueA.toUpperCase();
        const upperValueB = valueB.toUpperCase();
        if (upperValueA < upperValueB) return newSortAsc ? -1 : 1;
        if (upperValueA > upperValueB) return newSortAsc ? 1 : -1;
        return 0;
      }
  
      // Default comparison for other fields
      if (valueA < valueB) return newSortAsc ? -1 : 1;
      if (valueA > valueB) return newSortAsc ? 1 : -1;
      return 0;
    });
  
    this.setState({
      printTaskListGroup: sortedList,
      sortBy,
      sortAsc: newSortAsc,
    });
  };
  
  
  render() {
    const {
      task,
      sortBy,
      sortAsc,
      patientTaskGroup,
      selectedTemplateIds,
      printTaskList,
      printTaskListGroup,
      patientId,
      multiPatientMode,
      patientName,
      openPatientTask,
      openTemplates,
      templates,
     
    } = this.state;
    console.log("printTaskList:", printTaskListGroup);


    const filteredTasks = (() => {
      const validTemplates = [];
      const noTemplates = [];
    
      Object.keys(patientTaskGroup).forEach(templateName => {
        if (templateName !== "null" && templateName !== "No Template") {
          validTemplates.push(templateName);
        } else {
          noTemplates.push(templateName);
        }
      });
    
      return { validTemplates, noTemplates };
    })();
    
    return (
     
      <div id="facility-index">
        {patientId && patientId !== "" && !multiPatientMode ? (
          <PatientTasks
            openTaskModal={this.state.openTaskModal}
            updatePatientModalClose={this.updatePatientModalClose}
            patientDetail={this.state.patientDetail}
            updatePatient={this.props.updatePatient}
            loaderStatus={this.loaderStatus}
            patientTaskId={this.state.patientTaskId}
          />
        ) : (
          ""
        )}
        <div>
          {patientId && patientId !== "" && !multiPatientMode ? (
            <Box
              width={1}
              className="right-box-content categorylist-cover tasks add-border"
            >
              <div className="box-header tsks-h">
              <span className="TasksTitle">Tasks</span>
                <span className="patientName selectedtask-name">
                  &nbsp; - {this.state.patientName}
                </span>
                <span
                  onClick={this.handlePrint}
                  className="pointer"
                  style={{
                    position: "absolute",
                    right: "3.5rem",
                    top: "6px",
                    color: "white",
                    padding: "2px",

                    width: "1em",
                    height: "1em",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <PrintOutlinedIcon style={{ fontSize: "1.2rem" }} />
                </span>

                <img
                  src={layout}
                  alt=""
                  className="pointer"
                  onClick={this.handleOpenTemplates}
                  style={{
                    position: "absolute",
                    right: "25px",
                    top: "10px",
                    width: "15px",
                    height: "15px",
                    zIndex: 1,
                  }}
                />

                <img
                  src={taskAdd}
                  alt=""
                  className="pointer task-add"
                  onClick={(e) => this.openTaskModal("")}
                  disabled={this.state.loading}
                />
              </div>
              <div className="loaderProgressFixed top65Fixed">
                {this.state.loading ? <LinearProgress color="primary" /> : ""}
              </div>

              <Box
                display="flex"
                justifyContent="center"
                className="middle-section"
              >
                <div className="middle-section-content">
                  <Scrollbars
                    autoHide={true}
                    universal={true}
                    style={{ height: "calc(100vh - 389px)" }}
                    className="TaskListScroll"
                  >
                    {patientTaskGroup &&
                    typeof patientTaskGroup === "object" &&
                    Object.keys(patientTaskGroup).length ? (
                      (() => {
                        const validTemplates = [];
                        const noTemplates = [];

                        Object.keys(patientTaskGroup).forEach(
                          (templateName) => {
                            if (
                              templateName !== "null" &&
                              templateName !== "No Template"
                            ) {
                              validTemplates.push(templateName);
                            } else {
                              noTemplates.push(templateName);
                            }
                          }
                        );

                        return (
                          <>
                          {noTemplates.map((templateName) => (
                              <div key={templateName}>
                                <span style={{ fontWeight: "bold" }}>
                                </span>
                                {patientTaskGroup[templateName].map((task) => (
                                  <Box
                                    className={`task-name-list task-name ${
                                      TASK_STATUS_OBJ[task.changeStatus]
                                    }`}
                                    key={task.id}
                                  >
                                    <span
                                      className="pointer"
                                      onClick={(e) =>
                                        this.openTaskModal(task.id)
                                      }
                                    >
                                      {task.taskName || "Unknown"}
                                    </span>
                                    <span>
                                      {task.dueDate ? task.dueDateString : ""}
                                    </span>
                                  </Box>
                                ))}
                              </div>
                            ))}
                            {validTemplates.map((templateName) => (
                              <div key={templateName}>
                                <span style={{ fontWeight: "bold" }}>
                                  {templateName}
                                </span>
                                {patientTaskGroup[templateName].map((task) => (
                                  <Box
                                    className={`task-name-list task-name ${
                                      TASK_STATUS_OBJ[task.changeStatus]
                                    }`}
                                    key={task.id}
                                  >
                                    <span
                                      className="pointer"
                                      onClick={(e) =>
                                        this.openTaskModal(task.id)
                                      }
                                    >
                                      {task.taskName || "Unknown"}
                                    </span>
                                    <span>
                                      {task.dueDateString
                                        ? task.dueDateString
                                        : ""}
                                    </span>
                                  </Box>
                                ))}
                              </div>
                            ))}

                          </>
                        );
                      })()
                    ) : (
                      <div className="task-name-list task-name">No Tasks!</div>
                    )}
                  </Scrollbars>
                </div>
              </Box>
            </Box>
          ) : (
            <Box width={1} className="right-box-content tasks">
              <div className="box-header tsks-h">Tasks</div>
              <div>
                {this.state.loading ? <LinearProgress color="primary" /> : ""}
              </div>
              <Box
                display="flex"
                justifyContent="center"
                className="middle-section"
              >
                <div className="middle-section-content middle-section-content-middle">
                  <img src={tasksImg} alt="" />
                  {multiPatientMode ? (
                    <div className="text">
                      Select single patient to view tasks
                    </div>
                  ) : (
                    <div className="text">
                      Please select patient to view task list
                    </div>
                  )}
                </div>
              </Box>
            </Box>
          )}
        </div>
        <Dialog
          open={openPatientTask}
          onClose={this.handleCloseTemplates}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
          PaperProps={{
            style: {
              maxHeight: "90vh",
            },
          }}
        >
         <DialogTitle id="form-dialog-title">
  Print {patientName} Tasks
  {/* {sortBy === "taskName" && (
    <TableSortLabel
      active={true}
      direction={sortAsc ? "asc" : "desc"}
      onClick={() => this.handleSort('taskName')}
    />
  )} */}
</DialogTitle>


<DialogContent>
  {filteredTasks.noTemplates.length > 0 || filteredTasks.validTemplates.length > 0 ? (
    <>
      {filteredTasks.noTemplates.map(templateName => (
        <div key={templateName}>
          <span style={{ fontWeight: "bold" }}></span>
          {patientTaskGroup[templateName].map(task => (
            <Box
              key={task.id}
              width={1}
              className="categorylist-cover"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedTemplateIds.includes(task.id)}
                      color="primary"
                      onClick={() => this.handleTemplateSelect(task.id)}
                    />
                  }
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>{task.taskName || "No Task Available"} - </div>
                      <span
                        style={{
                          color: this.statusDescriptions[task.changeStatus]?.color,
                          fontWeight: "bold",
                          marginLeft: 8,
                        }}
                      >
                        {this.statusDescriptions[task.changeStatus]?.description || "No Status Available"}
                      </span>
                    </div>
                  }
                />
              </Box>
              <div
                style={{
                  marginTop: 8,
                  marginBottom: 15,
                  wordBreak: "break-word",
                }}
              >
                {task.taskDescription ? (
                  <>
                    {task.taskDescription.length > 50
                      ? `${task.taskDescription.substring(0, 50)}`
                      : task.taskDescription}
                    {task.taskDescription.length > 100 && (
                      <>
                        <br />
                        {task.taskDescription.substring(50, 100)}
                        {task.taskDescription.length > 100 && "..."}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </Box>
          ))}
        </div>
      ))}
      {filteredTasks.validTemplates.map(templateName => (
        <div key={templateName}>
          <span style={{ fontWeight: "bold" }}></span>
          {patientTaskGroup[templateName].map(task => (
            <Box
              key={task.id}
              width={1}
              className="categorylist-cover"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedTemplateIds.includes(task.id)}
                      color="primary"
                      onClick={() => this.handleTemplateSelect(task.id)}
                    />
                  }
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>{task.taskName || "No Task Available"} - </div>
                      <span
                        style={{
                          color: this.statusDescriptions[task.changeStatus]?.color,
                          fontWeight: "bold",
                          marginLeft: 8,
                        }}
                      >
                        {this.statusDescriptions[task.changeStatus]?.description || "No Status Available"}
                      </span>
                    </div>
                  }
                />
              </Box>
              <div
                style={{
                  marginTop: 8,
                  marginBottom: 15,
                  wordBreak: "break-word",
                }}
              >
                {task.taskDescription ? (
                  <>
                    {task.taskDescription.length > 50
                      ? `${task.taskDescription.substring(0, 50)}`
                      : task.taskDescription}
                    {task.taskDescription.length > 100 && (
                      <>
                        <br />
                        {task.taskDescription.substring(50, 100)}
                        {task.taskDescription.length > 100 && "..."}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </Box>
          ))}
        </div>
      ))}
    </>
  ) : (
    "No tasks available"
  )}
</DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseTemplates} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDownloadPDF} color="primary">
              Print
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openTemplates}
          onClose={this.handleCloseTemplates}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
          PaperProps={{
            style: {
              maxHeight: "90vh", // Adjust as needed
            },
          }}
        >
          <DialogTitle id="form-dialog-title">Select Template</DialogTitle>
          <DialogContent>
            {templates.length > 0 ? (
              templates
                .sort((a, b) => (a.templateName > b.templateName ? 1 : -1))
                .map((template) => (
                  <Box width={1} className="categorylist-cover">
                    <FormControlLabel
                      key={template.id}
                      control={
                        <Checkbox
                          checked={
                            this.state.selectedTemplateIds.includes(template.id)
                              ? true
                              : false
                          }
                          color="primary"
                          onClick={() => this.handleTemplateSelect(template.id)}
                        />
                      }
                      label={template.templateName}
                    />
                  </Box>
                ))
            ) : (
              <div>No templates available</div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseTemplates} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSaveTemplates} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default TasksList;
