import { Box, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React from "react";

export default function FaxDetails(props) {
  console.log(props.faxDetails);
  return (
    <Dialog
      open={props.open}
      maxWidth={"sm"}
      onClose={props.toggle}
      //TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      className="add-modal front-modal "
    >
      <DialogTitle className="add-modal-title" id="form-dialog-title">
        Fax Details
        <IconButton
          className="closeButton"
          aria-label="close"
          onClick={props.toggle}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {/* {this.state.loading && <LinearProgress size={24} />} */}
      <Box p={2}>
        {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
        <div className="w-100">
          <span className="div-fourty">DC Date:</span>
          <span className="div-sixty">
            {" "}
            {moment
              .utc(props.faxDetails?.dcDate)
              // .add(offset, "minutes")
              .format("MM/DD/yyyy")}
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">DC Location:</span>
          <span className="div-sixty">
            {" "}
            {props.faxDetails?.dcLocation}
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">DC Phone:</span>
          <span className="div-sixty">
            {props.faxDetails?.dcPhone}
          </span>
        </div>
        <div className="w-100">
          <span className="div-fourty">DC PCP Notes:</span>
          <span className="div-sixty">{props.faxDetails?.dcPcpNotes}</span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Fax Status:</span>
          <span className="div-sixty">{props.faxDetails?.faxStatus}</span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Fax Number:</span>
          <span className="div-sixty">
            {props.faxDetails?.faxRecipientNumber}
          </span>
        </div>
      </Box>
    </Dialog>
  );
}
