import { userService } from "_services/user.service";
export function authHeader() {
  // return authorization header with basic auth credentials
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "https://asgcp-int.adpersistence.com",
      Authorization: user.accessToken,
    };
  } else {
    return {};
  }
}

export function authHeaderMIMETYPE() {
  // return authorization header with basic auth credentials
  let providerLoginByAdmin = JSON.parse(localStorage.getItem("provider-user"));
  let user = JSON.parse(localStorage.getItem("user"));
  if (providerLoginByAdmin && providerLoginByAdmin.accessToken) {
    user = JSON.parse(localStorage.getItem("provider-user"));
  }

  if (user && user.accessToken) {
    return {
      Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      // "Content-Type": "application/json",
      Authorization: user.accessToken,
    };
  } else {
    return {};
  }
}

export function clientTokenHeader() {
  // return authorization header with basic auth credentials
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "http://54.176.19.244:9092",
  };
}
export function loginTokenHeader() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}
export function generalMimeAuthTokenHeader() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    let accessToken = user.accessToken;
    return {
      // 'Accept': 'application/json',
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    };
  } else {
    userService.logout();
    window.location.reload(true);
    return {};
  }
}
export function generalAuthTokenHeader() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    let accessToken = user.accessToken;
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    };
  } else {
    userService.logout();
    window.location.reload(true);
    return {};
  }
}
export function providerGeneralAuthTokenHeader() {
  let providerLoginByAdmin = JSON.parse(localStorage.getItem("provider-user"));
  let user = JSON.parse(localStorage.getItem("user"));
  if (providerLoginByAdmin && providerLoginByAdmin.accessToken) {
    user = JSON.parse(localStorage.getItem("provider-user"));
  }
  if (user && user.accessToken) {
    let accessToken = user.accessToken;
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    };
  } else {
    userService.logout();
    window.location.reload(true);
    return {};
  }
}
export function generalOctetAuthTokenHeader() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    let accessToken = user.accessToken;
    return {
      Accept: "application/octet-stream",
      "Content-Type": "application/octet-stream",
      Authorization: "Bearer " + accessToken,
    };
  } else {
    userService.logout();
    window.location.reload(true);
    return {};
  }
}
export function multimediaAuthTokenHeader() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.refreshToken && user.accessToken && user.jti) {
    let accessToken = user.accessToken;
    return {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    };
  } else {
    return {};
  }
}
export function reauthorizeTokenHeader() {
  // return authorization header with basic auth credentials
  let clientAuthToken = localStorage.getItem("clientAuthToken");
  let user = JSON.parse(localStorage.getItem("user"));
  if (clientAuthToken && user && user.accessToken) {
    return {
      Accept: "application/json",
      // "Access-Control-Allow-Origin": "https://asgcp-int.adpersistence.com",
      "Content-Type": "application/json",
      "x-App-Authorization": clientAuthToken,
      Authorization: user.accessToken,
    };
  } else {
    return {};
  }
}
