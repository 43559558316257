/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Box,
  TextField,
  Avatar
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "components/Login/FormErrors";
import { apiPath } from "api";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";
import InfiniteScroll from 'react-infinite-scroller';
import enMsg from "__helpers/locale/en/en";
import {
  EMAIL_REGEX,
  ZIP_CODE_LENGTH,
  DIGIT_ONLY,
} from "__helpers/constants";
import { baseRoutes, providerBaseRoutes } from "base-routes";
import ChangePassword from "./ChangePassword";
import { Scrollbars } from 'react-custom-scrollbars';
import { generalAuthTokenHeader } from "__helpers/auth-header";

import { connect } from "react-redux";
import {
  addUSerUInfo,
  reduxLoad,
} from "js/actions";

import Select from "@material-ui/core/Select";
import ConfirmModal from 'components/Modal/modal'
import MenuItem from "@material-ui/core/MenuItem";
function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: addUSerUInfoVal => dispatch(addUSerUInfo(addUSerUInfoVal)),
    addTimeStamp: addTimeStampVal => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
    loadingFlag: state.loadingFlag,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};

const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF"
    }
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  disabledDropdownBlack: {
    color: "#000 !important"
  }
});

class InviteProviderClass extends React.Component {
  inputOpenFileRef = React.createRef(null);
  maxDate = new Date();
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      provider: '',
      size: 10,
      fetchMore: false,
      outsideNetworkProviders: [],
      page: 0,
      formErrors: {
        firstName: "",
        lastName: "",
        email: "",
      },
      firstNameValid: false,
      lastNameValid: false,
      emailValid: true,
      providerValid: false,
      loading: false,
      showNotification: {},
      reduxLoadFlag: false,
      confirmModalStatus: false,
      confirmModalMessage: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.confirmModalOpen = this.confirmModalOpen.bind(this);
    this.handleOtherNetworkProviderSubmit = this.handleOtherNetworkProviderSubmit.bind(this);
  }

  handleZipCodeInput = e => {
    const value = e.target.value;
    if (value.length > ZIP_CODE_LENGTH) {
      return true;
    }
    if (value.trim() == "" || value.match(DIGIT_ONLY)) {
      this.setState({ zipCode: value }, () => {
        this.validateField("zipCode", value);
      });
    }
  };
  confirmModalOpen = (message) => {
    if (this.state.provider) {
      let fromErros = this.state.formErrors;
      fromErros.provider = "";
      this.setState({
        confirmModalMessage: message,
        confirmModalStatus: true,
        providerValid: false,
        fromErros: fromErros,
      });
    } else {
      let fromErros = this.state.formErrors;
      fromErros.provider = "Please select provider to invite";
      this.setState({
        providerValid: false,
        fromErros: fromErros,
      })
    }
  }
  confirmModalClose = () => {
    this.setState({
      confirmModalStatus: false
    })
  }
  // to stop the warning of calling setState of unmounted component
  handleUserInput = e => {
    let fromErros = this.state.formErrors;
    fromErros.provider = "";
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      fromErros: fromErros,
    }, () => {
      this.validateField(name, value);
    });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let firstNameValid = this.state.firstNameValid;
    let lastNameValid = this.state.lastNameValid;
    let emailValid = this.state.emailValid;

    switch (fieldName) {
      case "firstName":
        firstNameValid = value.trim().length > 0 ? true : false;
        fieldValidationErrors.firstName = firstNameValid
          ? ""
          : enMsg.firstNameRequired;
        break;
      case "lastName":
        lastNameValid = value.trim().length > 0 ? true : false;
        fieldValidationErrors.lastName = lastNameValid
          ? ""
          : enMsg.lastNameRequired;
        break;
      case "email":
        emailValid = value.match(EMAIL_REGEX);
        fieldValidationErrors.email = emailValid ? "" : enMsg.inValidEmail;
        break;
      default:
        firstNameValid = true;
        lastNameValid = true;
        emailValid = true;
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        firstNameValid: firstNameValid,
        lastNameValid: lastNameValid,
        emailValid: emailValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    return (
      this.state.firstNameValid &&
      this.state.lastNameValid &&
      this.state.emailValid
    );
  }
  componentDidMount() {
    this.fetchOutsideNetworkProviders();
  }
  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
  }
  fetchOutsideNetworkProviders = async () => {
    let showNotification = {};
    let url = apiPath.providersFromOtherNetwork + '?page=' + this.state.page + '&size=' + this.state.size;
    const response = await fetch(url, {
      method: "GET",
      headers: generalAuthTokenHeader()
    })
      .then(response => {
        if (response.ok) {
        }
        else {
          showNotification = {
            title: "Task Fetchddd",
            message: "Bad response from server",
            type: "danger"
          };
        }
        return response.json();
      })
      .then(data => {
        let outsideNetworkProviders = this.state.outsideNetworkProviders;
        let fetchMore = false;
        let page = this.state.page;
        if (data && data.length) {
          this.setState({
            loading: false,
            outsideNetworkProviders: data,
            fetchMore: fetchMore,
            page: page,
          });
        } else {
          showNotification = {
            title: "Task Fetchsss",
            message: "Bad response from server",
            type: "danger"
          };
          this.setState({
            loading: false,
          });
        }
      })
      .catch(error => {
        showNotification = {
          title: "Task Fetchaaaa",
          message: "Bad response from server",
          type: "danger"
        };
        return response;
      });
    userService.showNotification(showNotification);
  }
  handleChange(event) {
    this.setState({ middleName: event.target.value });
  }

  async handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();
    let showNotification = {};
    let success = false;
    const data = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,

    };
    try {
      const response = await fetch(apiPath.providerSignupLink, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data)
      })
        .then(response => {
          if (response.status === 400) {
            showNotification = {
              title: 'Invitation Link',
              message: enMsg.badResponseFromServer,
              type: "danger"
            };
          } else if (response.ok) {
          } else {
            showNotification = {
              title: 'Invitation Link',
              message: enMsg.badResponseFromServer,
              type: "danger"
            };
          }
          return response.json();
        })
        .then(data => {

          if (data.status == '400' && data.message == 'error.idexists') {
            showNotification = {
              title: "Invitation Link",
              message: 'Email is already exist.',
              type: 'danger'
            };
          } else if (data.response) {
            success = true;
            showNotification = {
              title: 'Invitation Link',
              message: 'Link has been sent to the provider.',
              type: "success"
            };
          }
        })
        .catch(error => {
          showNotification = {
            title: 'Invitation Link',
            message: enMsg.networkFailedError,
            type: "danger"
          };
          return response;
        });
    } catch (error) {
      showNotification = {
        title: 'Invitation Link',
        message: enMsg.networkFailedError,
        type: "danger"
      };
    }
    userService.showNotification(showNotification);
    this.setState({ loading: false });

    if (success) {
      setTimeout(function () {
        window.location.reload(true);
      }, 3000);
    }
  }
  handleChange(valueF, nameF) {
    if (nameF == "gender") {
      this.setState({ gender: valueF });
    } else {
      this.setState({ role: valueF });
    }
    const name = nameF;
    const value = valueF;

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }
  async handleOtherNetworkProviderSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();
    let showNotification = {};
    let success = false;
    const data = {
      ids: [this.state.provider],
    };
    try {
      const response = await fetch(apiPath.sendLinkToOtherProviderDp, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data)
      })
        .then(response => {
          if (response.status === 400) {
            showNotification = {
              title: 'Invitation Link',
              message: enMsg.badResponseFromServer,
              type: "danger"
            };
            return response.json();
          } else if (response.ok) {
          } else {
            showNotification = {
              title: 'Invitation Link',
              message: enMsg.badResponseFromServer,
              type: "danger"
            };
          }
          return response.text();
        })
        .then(data => {
          if (data.title) {
            showNotification = {
              title: "Provider",
              message: data.title,
              type: 'dangerLong'
            };
          } else if (data) {
            success = true;
            showNotification = {
              title: 'Invitation Link',
              message: 'Link has been sent to the provider.',
              type: "success"
            };
            this.confirmModalClose();
          }
        })
        .catch(error => {
          showNotification = {
            title: 'Invitation Link',
            message: enMsg.networkFailedError,
            type: "danger"
          };
          return response;
        });
    } catch (error) {
      showNotification = {
        title: 'Invitation Link',
        message: enMsg.networkFailedError,
        type: "danger"
      };
    }
    userService.showNotification(showNotification);
    this.setState({ loading: false });

    if (success) {
      setTimeout(function () {
        window.location.reload(true);
      }, 3000);
    }
  }

  handleOpen() {
    setOpen(true);
  }

  handleClose() {
    setOpen(false);
  }
  fetchMoreData = () => {
    this.setState({
      fetchMore: false,
    }, () => this.fetchOutsideNetworkProviders())
  }
  render() {
    const { outsideNetworkProviders, provider } = this.state;
    const { classes } = this.props;
    const stylead = { borderBottom: "" };
    const user_image = "user-image";
    const styleuser = {
      borderRadius: "50%",
      width: "35px",
      height: "35px"
    };
    return (
      <div>
        <ConfirmModal
          title='Alert'
          message={this.state.confirmModalMessage}
          confirmModalStatus={this.state.confirmModalStatus}
          confirmModalClose={this.confirmModalClose}
          submit={this.handleOtherNetworkProviderSubmit}
          loading={this.state.loading}
        />

        <Box display="flex" width={1} className="front-modal invite-form">
          <Box width={1 / 2} pr={2} >
            <div className="tab-heading">Add Existing Provider Outside of your Network</div>
            <div className="mt-20">Select an existing Provider outside of your network to send an invitation link.</div>
            <div>&nbsp;</div>
            <form >

              <Box width={1 / 2} display="flex" mb={4} mt={2} >
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.fetchMoreData}
                  hasMore={this.state.fetchMore}
                  loader={<div className="loader" key={0}>Loading ...</div>}
                  useWindow={false}
                  className="inviteSc"
                >
                  <FormControl>
                    <InputLabel className={"required-label"}>Provider</InputLabel>

                    <Select
                      color="secondary"
                      name="provider"
                      value={provider}
                      onChange={event => this.handleUserInput(event)}
                    >
                      <MenuItem value="">
                        Select Provider
                    </MenuItem>
                      {outsideNetworkProviders.length && outsideNetworkProviders.map((provider, key) =>

                        <MenuItem
                          key={key}
                          value={provider.providerId}
                        >
                          {provider.providerName}
                        </MenuItem>
                      )
                      }
                    </Select>

                    <FormErrors
                      show={!this.state.providerValid}
                      formErrors={this.state.formErrors}
                      fieldName="provider"
                    />
                  </FormControl>
                </InfiniteScroll>
              </Box>

              <div className="form-button-cover form-actions">
                <Button
                  className={this.state.loading ? "buttonSuccess btn1" : "btn1"}
                  disabled={this.state.loading}
                  type="button"
                  onClick={() => this.confirmModalOpen('Do you really want to send the invitation?')}
                >
                  Save
                {this.state.loading && (
                    <CircularProgress
                      size={24}
                      className="buttonProgress"
                    />
                  )}
                </Button>
                <Button
                  className="back-btn btn2"
                  type="button"
                >
                  <Link
                    underline="none"
                    to={providerBaseRoutes.dashboard.path}
                  >
                    Cancel
                </Link>
                </Button>
              </div>

            </form>
          </Box>
          <Box width={1 / 2} pl={2}>
            <div className="tab-heading">Add Brand New Provider</div>
            <div className="mt-20">Input the following Provider Information to add them to your MAXMRJ Network! </div>
            <div>The link generated will only last for 72 hours</div>

            <form onSubmit={this.handleSubmit}>
              <Box width={1} display="flex" mb={4} mt={2} flexDirection="column">
                <Box width={1} display="flex" >
                  <Box width={1 / 2} pr={2}>
                    <FormGroup>
                      <CustomInput
                        labelText="First Name"
                        labelProps={{ className: "required-label" }}
                        id="comp-name"
                        inputProps={{
                          type: "text",
                          name: "firstName",
                          autoComplete: "off",
                          // required: true,
                          value: this.state.firstName,
                          inputRef: input => (this.firstName = input),
                          onChange: event => this.handleUserInput(event)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                      <FormErrors
                        show={!this.state.firstNameValid}
                        formErrors={this.state.formErrors}
                        fieldName="firstName"
                      />
                    </FormGroup>
                  </Box>
                  <Box width={1 / 2} pl={2} >
                    <FormGroup>
                      <CustomInput
                        color="primary"
                        labelText="Last Name"
                        labelProps={{ className: "required-label" }}
                        id="city"
                        inputProps={{
                          type: "text",
                          name: "lastName",
                          autoComplete: "off",
                          required: true,
                          value: this.state.lastName,
                          inputRef: input => (this.lastName = input),
                          onChange: event => this.handleUserInput(event)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                      <FormErrors
                        show={!this.state.lastNameValid}
                        formErrors={this.state.formErrors}
                        fieldName="lastName"
                      />
                    </FormGroup>
                  </Box>

                </Box>
                <Box width={1 / 2} pr={2} >
                  <FormGroup >
                    <CustomInput
                      labelText="Email"
                      labelProps={{ className: "required-label" }}
                      id="email"
                      inputProps={{
                        type: "email",
                        name: "email",
                        autoComplete: "off",
                        required: true,
                        value: this.state.email,
                        inputRef: input => (this.email = input),
                        onChange: event => this.handleUserInput(event)
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <FormErrors
                      show={!this.state.emailValid}
                      formErrors={this.state.formErrors}
                      fieldName="email"
                    />
                  </FormGroup>
                </Box>
              </Box>
              <div className="form-button-cover form-actions">
                <Button
                  className={this.state.loading ? "buttonSuccess btn1" : "btn1"}
                  disabled={this.state.loading || !this.validateForm()}
                  type="submit"
                >
                  Save
                  {this.state.loading && (
                    <CircularProgress
                      size={24}
                      className="buttonProgress"
                    />
                  )}
                </Button>
                <Button
                  className="back-btn btn2"
                  type="button"
                >
                  <Link
                    underline="none"
                    to={providerBaseRoutes.dashboard.path}
                  >
                    Cancel
                  </Link>
                </Button>
              </div>
            </form>

          </Box>
        </Box>
      </div>
    );
  }
}

InviteProviderClass.propTypes = {
  classes: PropTypes.object
};
const InviteProvider = connect(
  mapStateToProps, mapDispatchToProps
)(InviteProviderClass);
export default withStyles(styles)(InviteProvider);
