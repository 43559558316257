/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  /* BrowserRouter as */ Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Admin from "layouts/Admin.jsx";
import ProviderAdmin from "layouts/Provider.jsx";
import ProviderUserAdmin from "layouts/ProviderUser.jsx";
import DPAdmin from "layouts/DP.jsx";
import PageLoader from "layouts/PageLoader";
import Login from "components/Login/Login.jsx";
import Signup from "components/Signup/Signup.jsx";
import Calendar from "views/dpViews/Calendar";

import "assets/css/material-dashboard-react.css?v=1.7.0";
import ForgotPassword from "components/Login/ForgotPassword";
import ResetPassword from "components/Login/ResetPassword";
import Pcc3LeggedAuth from "components/3LeggedAuth/Pcc3LeggedAuth";
import {
  basePath,
  baseRoutes,
  dpBaseRoutes,
  providerUserBaseRoutes,
  providerBaseRoutes,
} from "base-routes";
import Page404 from "components/Login/Page404";
import { PrivateRoute } from "components/PrivateRoute";

import "react-notifications-component/dist/theme.css";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Provider } from "react-redux";
import store from "./js/store/index";
import {
  USER_INFO,
  ADMIN_USER,
  DP_USER,
  PROVIDER_USER,
  PROVIDER,
} from "__helpers/constants";
import { userService } from "_services/user.service";

let user = JSON.parse(localStorage.getItem(USER_INFO));
// console.log(user);
var cookieVals = readCookie("_max_mrj");
if (!cookieVals) {
  userService.logout();
}
const Notification = () => {
  return (
    <Provider store={store}>
      <div>
        <NotificationContainer />
        {/* <Router history={hist}>
          <Switch>
            <PrivateRoute path={baseRoutes.dashboard.path} component={PageLoader} />
            <Route path={baseRoutes.login.path} component={Login} />
            <Route
              path={baseRoutes.forgotPassword.path}
              component={ForgotPassword}
            />
            <Route
              path={baseRoutes.resetPassword.path}
              component={ResetPassword}
            />
            <PrivateRoute path={baseRoutes.admin.path} component={PageLoader} />
            <Redirect from="/" to={baseRoutes.dashboard.path} unusedData={"unusedData"} />
            <Route component={Page404} />
          </Switch>
        </Router> */}
        {user && user.usrType ? (
          user.usrType == ADMIN_USER ? (
            <Router history={hist}>
              <Switch>
                <PrivateRoute
                  path={baseRoutes.dashboard.path}
                  component={Admin}
                />
                <Route path={baseRoutes.login.path} component={Login} />
                <Route
                  path={baseRoutes.forgotPassword.path}
                  component={ForgotPassword}
                />
                <Route
                  path={baseRoutes.resetPassword.path}
                  component={ResetPassword}
                />
                <PrivateRoute path={baseRoutes.admin.path} component={Admin} />
                <Redirect
                  from="/"
                  to={baseRoutes.dashboard.path}
                  unusedData={"unusedData"}
                />
                <Route component={Page404} />
              </Switch>
            </Router>
          ) : user.usrType == DP_USER ? (
            <Router history={hist}>
              <Switch>
                <PrivateRoute
                  path={dpBaseRoutes.dashboard.path}
                  component={DPAdmin}
                />

                <Route path={baseRoutes.login.path} component={Login} />
                <Route
                  path={baseRoutes.forgotPassword.path}
                  component={ForgotPassword}
                />
                <Route
                  path={baseRoutes.resetPassword.path}
                  component={ResetPassword}
                />

                <PrivateRoute
                  path={dpBaseRoutes.admin.path}
                  component={DPAdmin}
                />

                {/* <Redirect
                  from="/"
                  to={dpBaseRoutes.dashboard.path}
                  unusedData={"unusedData"}
                /> */}
                <PrivateRoute
                  path={dpBaseRoutes.calendar.path}
                  component={Calendar}
                />
                <Route component={Page404} />
              </Switch>
            </Router>
          ) : user.usrType == PROVIDER_USER ? (
            <Router history={hist}>
              <Switch>
                <PrivateRoute
                  path={providerUserBaseRoutes.dashboard.path}
                  component={ProviderUserAdmin}
                />
                <Route path={baseRoutes.login.path} component={Login} />
                <Route
                  path={baseRoutes.forgotPassword.path}
                  component={ForgotPassword}
                />
                <Route
                  path={baseRoutes.resetPassword.path}
                  component={ResetPassword}
                />
                <PrivateRoute
                  path={providerUserBaseRoutes.admin.path}
                  component={ProviderUserAdmin}
                />
                <Redirect
                  from="/"
                  to={providerUserBaseRoutes.dashboard.path}
                  unusedData={"unusedData"}
                />
                <Route component={Page404} />
              </Switch>
            </Router>
          ) : user.usrType == PROVIDER ? (
            <Router history={hist}>
              <Switch>
                <PrivateRoute
                  path={providerBaseRoutes.dashboard.path}
                  component={ProviderAdmin}
                />
                <Route path={baseRoutes.login.path} component={Login} />
                <Route
                  path={baseRoutes.forgotPassword.path}
                  component={ForgotPassword}
                />
                <Route
                  path={baseRoutes.resetPassword.path}
                  component={ResetPassword}
                />
                <PrivateRoute
                  path={providerBaseRoutes.admin.path}
                  component={ProviderAdmin}
                />
                <Redirect
                  from="/"
                  to={providerBaseRoutes.dashboard.path}
                  unusedData={"unusedData"}
                />
                <Route component={Page404} />
              </Switch>
            </Router>
          ) : (
            <Router history={hist}>
              <Switch>
                <Route path={baseRoutes.login.path} component={Login} />
                <Route
                  path={baseRoutes.forgotPassword.path}
                  component={ForgotPassword}
                />
                <Route
                  path={baseRoutes.resetPassword.path}
                  component={ResetPassword}
                />
                <Route component={Page404} />
              </Switch>
            </Router>
          )
        ) : (
          <Router history={hist}>
            <Switch>
              <PrivateRoute
                path={baseRoutes.dashboard.path}
                component={PageLoader}
              />
              <Route path={baseRoutes.login.path} component={Login} />
              <Route
                path={baseRoutes.forgotPassword.path}
                component={ForgotPassword}
              />
              <Route
                path={baseRoutes.resetPassword.path}
                component={ResetPassword}
              />
              <Route
                path={baseRoutes.pcc3LeggedAuth.path}
                component={Pcc3LeggedAuth}
              />
              <PrivateRoute
                path={baseRoutes.admin.path}
                component={PageLoader}
              />
              <Redirect
                from="/"
                to={baseRoutes.dashboard.path}
                unusedData={"unusedData"}
              />
              <Route component={Page404} />
            </Switch>
          </Router>
        )}
        {/* <PageLoader /> */}
        <React.Fragment key={new Date().getTime()}></React.Fragment>
      </div>
    </Provider>
  );
};
const hist = createBrowserHistory();
if (navigator.storage && navigator.storage.persist)
  navigator.storage.persisted().then((persistent) => {
    if (persistent)
      console.log("Storage will not be cleared except by explicit user action");
    else
      console.log("Storage may be cleared by the UA under storage pressure.");
    // if (sessionStorage && !sessionStorage.getItem('size')) {
    //   var i = 0;
    //   try {
    //     // Test up to 10 MB
    //     for (i = 250; i <= 10000; i += 250) {
    //       sessionStorage.setItem('test', new Array((i * 1024) + 1).join('a'));
    //     }
    //   } catch (e) {
    //     // sessionStorage.removeItem('test');
    //     // sessionStorage.setItem('size', i - 250);
    //     alert(i - 250);
    //   }
    // }
  });
function createCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  createCookie(name, "", -1);
}
ReactDOM.render(<Notification />, document.getElementById("root"));
