/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import DashboardIcons from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import SettingIcon from "@material-ui/icons/Settings";
import DownloadForOfflineIcon from "@material-ui/icons/CloudDownload";
import InvoiceIcon from "@material-ui/icons/Receipt";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import ViewDayIcon from "@material-ui/icons/ViewDay";
// import MenuBookIcon from "@material-ui/icons/MenuBook";

import WorkIcon from "@material-ui/icons/Work";
import {
  basePath,
  baseRoutes,
  dpBaseRoutes,
  providerUserBaseRoutes,
  providerBaseRoutes,
} from "base-routes";
import Profile from "views/Profile/Profile";
import InDevelopment from "views/InDevelopment/InDevlopment";
import TermsComponent from "views/CmsPages/TermsComponent";
import BAAComponent from "views/CmsPages/BAAComponent";

import FacilityList from "views/Facility/index";
import DischargePlannerList from "views/DischargePlanner/index";
import ProviderList from "views/Provider/index";

import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ReceiptIcon from "@material-ui/icons/Receipt";

// discharge planner component
import patientList from "views/dpViews/PatientDashboard/PatientDashboard";
import calendar from "views/dpViews/Calendar";
import pcc3LeggedAuth from "components/3LeggedAuth/Pcc3LeggedAuth";
import singlePatientInfo from "views/dpViews/SinglePatientDetail/SinglePatientDetail";

import ProviderUsers from "views/ProviderUser/index";
import FindProvider from "views/FindProvider/index";
import DPProfile from "views/dpViews/DPProfile/DPProfile";

// provider & provider user component
import ProviderPatientDashboard from "views/providerViews/PatientDashboard/ProviderPatientDashboard";
import ProviderDashboard from "views/providerViews/PatientDashboard/ProviderPatientDashboard";
import ProviderProfile from "views/providerViews/ProviderProfile/ProviderProfile";
import AdminProviderProfile from "views/providerViews/AdminProviderProfile/AdminProviderProfile.jsx";
import Notification from "views/Notifications/Notifications";
import BundlePatient from "views/BundlePatient/index";
import ReferralReports from "views/referralReports/index";
import Invoice from "views/invoice/index";

import profile from "views/Provider/profile";
import TaskTemplate from "views/dpViews/SinglePatientDetail/TaskTemplate";

// import userIcon from "assets/img/icons/users.svg";

const dashboardRoutes = [
  {
    path: baseRoutes.profile.path,
    name: baseRoutes.profile.pathName,
    icon: DashboardIcons,
    component: Profile,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: baseRoutes.facilityList.path,
    name: baseRoutes.facilityList.pathName,
    icon: DashboardIcons,
    component: FacilityList,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: baseRoutes.dischargePlanner.path,
    name: baseRoutes.dischargePlanner.pathName,
    icon: MeetingRoomIcon,
    component: DischargePlannerList,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: baseRoutes.provider.path,
    name: baseRoutes.provider.pathName,
    icon: ReceiptIcon,
    component: ProviderList,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: baseRoutes.providerUsers.path,
    name: baseRoutes.providerUsers.pathName,
    icon: PeopleIcon,
    component: ProviderUsers,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: baseRoutes.referralReports.path,
    name: baseRoutes.referralReports.pathName,
    icon: DownloadForOfflineIcon,
    component: ReferralReports,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: baseRoutes.invoice.path,
    name: baseRoutes.invoice.pathName,
    icon: InvoiceIcon,
    component: Invoice,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: baseRoutes.setting.path,
    name: baseRoutes.setting.pathName,
    icon: SettingIcon,
    component: InDevelopment,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: "/find-provider",
    name: "Dashboard",
    icon: DashboardIcons,
    component: FindProvider,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: baseRoutes.providerProfile.path,
    name: baseRoutes.providerProfile.pathName,
    icon: PeopleIcon,
    component: AdminProviderProfile,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: baseRoutes.providerUser.path,
    name: baseRoutes.providerUser.pathName,
    icon: PeopleIcon,
    component: profile,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: "/",
    name: baseRoutes.dashboard.pathName,
    icon: DashboardIcons,
    component: FacilityList,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false,
  },
];

export default dashboardRoutes;

export const dpRoutes = [
  {
    path: baseRoutes.profile.path,
    name: baseRoutes.profile.pathName,
    icon: DashboardIcons,
    component: DPProfile,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: dpBaseRoutes.pcc3LeggedAuth.path,
    name: dpBaseRoutes.pcc3LeggedAuth.pathName,
    icon: DashboardIcons,
    component: pcc3LeggedAuth,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: dpBaseRoutes.calendar.path,
    name: dpBaseRoutes.calendar.pathName,
    icon: DashboardIcons,
    component: calendar,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: dpBaseRoutes.patientList.path,
    name: dpBaseRoutes.patientList.pathName,
    icon: DashboardIcons,
    component: patientList,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: dpBaseRoutes.singlePatientInfo.path,
    name: dpBaseRoutes.singlePatientInfo.pathName,
    icon: DashboardIcons,
    component: singlePatientInfo,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: dpBaseRoutes.TaskTemplate.path,
    name: dpBaseRoutes.TaskTemplate.pathName,
    icon: DashboardIcons,
    component: TaskTemplate,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: dpBaseRoutes.notification.path,
    name: dpBaseRoutes.notification.pathName,
    icon: DashboardIcons,
    component: Notification,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: baseRoutes.terms.path,
    name: baseRoutes.terms.pathName,
    icon: SettingIcon,
    component: TermsComponent,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: baseRoutes.baa.path,
    name: baseRoutes.baa.pathName,
    icon: SettingIcon,
    component: BAAComponent,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: dpBaseRoutes.bundlePatient.path,
    name: dpBaseRoutes.bundlePatient.pathName,
    icon: SettingIcon,
    component: BundlePatient,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: providerBaseRoutes.profile.path,
    name: providerBaseRoutes.profile.pathName,
    icon: DashboardIcons,
    component: ProviderProfile,
    layout: "/Provider",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: "/",
    name: dpBaseRoutes.dashboard.pathName,
    icon: DashboardIcons,
    component: patientList,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
];
export const providerRoutes = [
  {
    path: providerBaseRoutes.profile.path,
    name: providerBaseRoutes.profile.pathName,
    icon: DashboardIcons,
    component: ProviderProfile,
    layout: "/Provider",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: providerBaseRoutes.patientList.path,
    name: providerBaseRoutes.patientList.pathName,
    icon: DashboardIcons,
    component: ProviderPatientDashboard,
    layout: "/Provider",
    basePath: basePath,
    showInSideBar: true,
  },

  {
    path: providerBaseRoutes.notification.path,
    name: providerBaseRoutes.notification.pathName,
    icon: DashboardIcons,
    component: Notification,
    layout: "/Provider",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: baseRoutes.terms.path,
    name: baseRoutes.terms.pathName,
    icon: SettingIcon,
    component: TermsComponent,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: baseRoutes.baa.path,
    name: baseRoutes.baa.pathName,
    icon: SettingIcon,
    component: BAAComponent,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: "/",
    name: providerBaseRoutes.dashboard.pathName,
    icon: DashboardIcons,
    component: ProviderPatientDashboard,
    layout: "/Provider",
    basePath: basePath,
    showInSideBar: false,
  },
];
export const providerUserRoutes = [
  {
    path: baseRoutes.profile.path,
    name: baseRoutes.profile.pathName,
    icon: DashboardIcons,
    component: Profile,
    layout: "/Provider",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: providerUserBaseRoutes.patientList.path,
    name: providerUserBaseRoutes.patientList.pathName,
    icon: DashboardIcons,
    component: ProviderDashboard,
    layout: "/Provider",
    basePath: basePath,
    showInSideBar: true,
  },
  {
    path: providerBaseRoutes.notification.path,
    name: providerBaseRoutes.notification.pathName,
    icon: DashboardIcons,
    component: Notification,
    layout: "/Provider",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: baseRoutes.terms.path,
    name: baseRoutes.terms.pathName,
    icon: SettingIcon,
    component: TermsComponent,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: baseRoutes.baa.path,
    name: baseRoutes.baa.pathName,
    icon: SettingIcon,
    component: BAAComponent,
    layout: "/DP",
    basePath: basePath,
    showInSideBar: false,
  },
  {
    path: "/",
    name: providerUserBaseRoutes.dashboard.pathName,
    icon: DashboardIcons,
    component: ProviderDashboard,
    layout: "/Provider",
    basePath: basePath,
    showInSideBar: false,
  },
];
