import { Box, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React from "react";

export default function TransportDetails(props) {
  const getFormattedAddress = (address) => {
    if (address) {
      const addressObject = JSON.parse(address);
      return addressObject.formatted_address || addressObject.address;
    }
  };
  console.log(props.transportDetails);
  return (
    <Dialog
      open={props.open}
      maxWidth={"sm"}
      onClose={props.toggle}
      //TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      className="add-modal front-modal "
    >
      <DialogTitle className="add-modal-title" id="form-dialog-title">
        Transport Details
        <IconButton
          className="closeButton"
          aria-label="close"
          onClick={props.toggle}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {/* {this.state.loading && <LinearProgress size={24} />} */}
      <Box p={2}>
        {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
        <div className="w-100">
          <span className="div-fourty">Pickup Date:</span>
          <span className="div-sixty">
            {" "}
            {moment
              .utc(props.transportDetails?.transportDate)
              // .add(offset, "minutes")
              .format("MM/DD/yyyy")}
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Pickup Time:</span>
          <span className="div-sixty">
            {" "}
            {props.transportDetails?.transportPickupTime}
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Pickup Address:</span>
          <span className="div-sixty">
            {props.transportDetails?.transportPickUpAddress}
          </span>
        </div>

        <div className="w-100 d-new-flex">
          <span className="div-fourty">Drop off Address:</span>
          <span className="div-sixty">
            <a
              href={`http://maps.google.com/maps?q=${props.transportDetails?.transportDropLatitude},${props.transportDetails?.transportDropLongitude}`}
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              className="text-black"
            >
              {getFormattedAddress(
                props.transportDetails?.transportDropMapObject
              )}
            </a>
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Room:</span>
          <span className="div-sixty">{props.transportDetails?.room}</span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Floor:</span>
          <span className="div-sixty">{props.transportDetails?.steFloor}</span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Needs:</span>
          <span className="div-sixty">
            {props.transportDetails?.transportNeeds}
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Wait Time:</span>
          <span className="div-sixty">{props.transportDetails?.waitTime}</span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Companinon Needed:</span>
          <span className="div-sixty">
            {props.transportDetails?.companionNeeded ? "Yes" : "No"}
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Physician to see:</span>
          <span className="div-sixty">
            {props.transportDetails?.physicianToSee}
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Physician phone:</span>
          <span className="div-sixty">
            {props.transportDetails?.physicianPhone}
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Reason for appointment:</span>
          <span className="div-sixty">
            {props.transportDetails?.reasonForAppointment}
          </span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Payor: </span>
          <span className="div-sixty">{props.transportDetails?.payor}</span>
        </div>

        <div className="w-100">
          <span className="div-fourty">Appointment details: </span>
          <span className="div-sixty">{props.transportDetails?.comment}</span>
        </div>
      </Box>
    </Dialog>
  );
}
