/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import {
    USER_INFO,
    AUTO_LOGOUT_TIME
} from "__helpers/constants";
import IdleTimer from 'react-idle-timer';
import { userService } from "_services/user.service";
import { FormErrors } from "components/Login/FormErrors";
import {
    IconButton,
    Link as MaterialLink,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Box,
    Link,
    LinearProgress,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import backArrow from "assets/img/icons/arrow-back.svg";
import logo from "assets/img/asglogo.png";
import { baseRoutes, basePath, providerBaseRoutes, providerUserBaseRoutes, dpBaseRoutes } from "base-routes";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            timeout: AUTO_LOGOUT_TIME,
            logoutTime: 15,
            time: {},
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            modalOpen: props.open ? props.open : false,
            formErrors: {
                disAgreeService: "",
            },
            disAgreeService: false,
        }
        this.disAgreeService = this.disAgreeService.bind(this);
        this.agreeService = this.agreeService.bind(this);
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            modalOpen: nextProps.open ? nextProps.open : false,
        });
    }
    agreeService() {
        this.setState({
            loading: true,
        })
        setTimeout(function () {
            this.setState({
                loading: false,
            })
            this.props.handleTNCAgree();
        }.bind(this), 3000);
    }
    disAgreeService() {
        let formErrors = this.state.formErrors;
        formErrors.disAgreeService = "Please Approve Terms And Service to proceed";
        this.setState({
            disAgreeService: true,
            formErrors: formErrors,
        })
    }
    handleLogout = () => {
        userService.logout();
        window.location.replace(baseRoutes.login.path);
        return true;
    };
    render() {
        return (
            <div className="logout-section">
                <Dialog
                    PaperProps={{
                        className: "change-password-dialogue"
                    }}
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="lg"
                    PaperProps={{
                        className: "change-password-dialogue"
                    }}
                    onEntering={this.handleEntering}
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.modalOpen}
                    {...this.state.other}
                    className={"change-pass-dialog profile-content add-modal CMSModal"}
                // fullScreen
                >

                    <DialogTitle className="add-modal-title" id="confirmation-dialog-title">
                        <div className="tab-heading">
                            <Box display="flex" flexDirection="row" className="cms-title-cover">
                                <img src={logo} alt="logo" className="cms-logo" />
                                <div className="cms-title">Terms and Conditions</div>

                                <Link to="#" onClick={this.handleLogout} className="cms-link">
                                    <span><i className="fa fa-power-off"></i></span>&nbsp;<span>Logout</span>
                                </Link>
                            </Box>
                        </div>
                        {this.state.loading && (
                            <LinearProgress
                                size={24}
                            />
                        )}
                    </DialogTitle>
                    <DialogContent /* dividers */ className="cms-text">
                        <div className="tnc-cover">
                            <p dir="ltr" id="docs-internal-guid-06e2298e-7fff-9f86-7b7d-bead55039fbd">
                                <b>Effective Date: August 24th, 2020</b>
                            </p>

                            <p dir="ltr">
                            Please read these Terms and Conditions (collectively with MAX MRJ’s Privacy Notice located at 
<a href="https://www.maxmrj.com/privacy-policy"> https://www.maxmrj.com/privacy-policy</a> and the “Terms and Conditions”) fully and carefully before using
www.maxmrj.com or mobile apps (the “Platform”) and the services, features, content or applications offered by
MAX MRJ, Inc. (“MAX”, “we”, “us” or “our”) (together with the Platform, the “Services”). These Terms and
Conditions set forth the legally binding terms and conditions for your use of the Platform and the Services.

                            </p>
                            <ol type="1">
                                <li dir="ltr">
                                    <p dir="ltr">
                                        ACCEPTANCE OF TERMS.
                                    </p>
                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            By registering for and/or using the Services in any manner, including but not limited to visiting,
                                            browsing, downloading, or installing the Platform, you agree to these Terms and Conditions
                                            (including, for clarity, the Privacy Notice) and all other operating rules, policies and procedures that
                                            may be published from time-to-time on the Platform by us, each of which is incorporated by
                                            reference and each of which may be updated from time to time without notice to you.

                                            </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Certain of the Services may be subject to additional terms and conditions specified by us from time
to time; your use of such Services is subject to those additional terms and conditions, which are
incorporated into these Terms and Conditions by this reference.

                                            </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            These Terms and Conditions apply to all users of the Services, including, without limitation, users
who are contributors of content, information, and other materials or services, registered or
otherwise.

                                            </p>
                                        </li>
                                    </ol>
                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                    ARBITRATION NOTICE AND CLASS ACTION WAIVER: Except for certain types of disputes described in
the Arbitration section below, you agree that disputes between you and MAX will be resolved by binding,
individual arbitration, and you waive your right to participate in a class-action lawsuit or class-wide
arbitration unless you opt-out of the Agreement to Arbitrate (see Section 21 "Agreement to Arbitrate") no
later than 30 days after the date you first use the Platform or Services, or by May 31st, 2020, whichever is
later. Unless you opt-out: (1) you will only be permitted to pursue claims against MAX on an individual
basis, not as a plaintiff or class member in any class or representative action or proceeding, and (2) you
will only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on an individual
basis.

                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                    DISCLOSURES; DESCRIPTION OF SERVICES; LIMITATIONS
                                    </p>
                                    <ol type="a" >
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Platform disclosure to those seeking Providers (“Clients”): MAX is not the employer of the
company, service, or person (the “Providers”) it refers to you (the “Client”) or whom you find on this
Platform. Depending on the arrangement between the Providers and you, you may have employer
responsibilities. The Provider may be your employee or an independent contractor depending on
the relationship you have with him or her. If you direct and control the manner and means by which
Providers perform work you may have employer responsibilities, including employment taxes and
workers’ compensation, under state and federal law. Clients agree to abide by all applicable local,
state, and federal employment and wage and hour laws and regulations. For additional information,
contact your local Employment Development Department and the Internal Revenue Service.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Description of Services. MAX is a Platform. We offer various Services to help its users find,
coordinate, and maintain quality services and products (“Provider Services”). “Provider Services”
may include, without limitation: home health, hospice, home care, pharmacy services, assisted
living, medical equipment, consumables, supplies, products, services in general. The Services we
offer include, among others:
                                        </p>
                                        </li>
                                        <ol type="i">
                                            <li dir="ltr">
                                                <p dir="ltr">
                                                    We enable the gathering information about providers
                                            </p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr">
                                                We Validate providers are part of the Client’s network of providers
                                            </p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr">
                                                We enable Clients seeking Providers to find matching providers on the Platform, and we
enable Providers to update their profiles on the Platform and accept referrals from Clients.
                                            </p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr">
                                                We provide search and browse functionality on the Platform to allow Clients and Providers
to narrow the pool of Clients or Providers they are interested in meeting based on their
requirements and preferences.

                                            </p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr">
                                                We provide a communications platform to allow Clients and Providers to communicate
without sharing personal contact information.

                                            </p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr">
                                                We do not provide any tools to perform any or other work. Instead, we provide the Platform
and information to help Clients and Providers make more informed decisions on choosing
each other and coordinating what work is to be done, rate, and schedule among each other,
collecting requirements with matching criteria, filtering by preferences, and a
communication platform.
                                            </p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr">
                                                We offer through Stripe, Inc., (“Stripe”), a third party, a service that facilitates the payment
of MAX’s subscription fee by Clients and Providers via a credit card. These payment
processing services are provided by Stripe and are subject to the <b>Stripe Connected 
Account Agreement</b>, which includes the <b>Stripe Terms of Service</b> (collectively, the "Stripe
Services Agreement"). By agreeing to these Terms, individuals who use the payment
service also agree to be bound by the Stripe Services Agreement, as the same may be
modified by Stripe from time to time. As a condition of MAX enabling payment processing
services through Stripe, you agree to provide MAX accurate and complete information
about you, and you authorize MAX to share it and transaction information related to your
use of the payment processing services provided by Stripe. MAX assumes no liability or
responsibility for any payments you may make through this service, and all such payments
are non-refundable.

                                            </p>
                                            </li>
                                        </ol>
                                    </ol>
                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                    LIMITATIONS OF SERVICES
                                    </p>
                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Accessing Our Platform: You are responsible for making all arrangements necessary for you to
have Internet connectivity and access to our Platform. We reserve the right to amend, restrict or
remove any portion of our Platform, as well as any products or services that we offer on our
Platform, in our sole discretion and without notice to you. We will not be liable if, for any reason, all
or any part of our Platform is unavailable at any time or for any reason.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            We offer a variety of Services to help our users find, coordinate, and maintain products and
services. However, we do not employ or control any Providers; instead, MAX is a Provider
Platform. As such, except as set forth below, Clients are responsible for compliance with all
applicable employment and other laws in connection with any employment relationship they
establish (such as applicable payroll, tax, workers’ compensation, and minimum wage laws). As a
Platform, we have no control over the quality, timing, or legality of the services actually delivered by
Providers, nor of the integrity, responsibility or actions of Clients or Providers and we do not
recommend Clients or Providers nor make any representations about the suitability, reliability,
timeliness, or accuracy of the services provided by Providers or the integrity, responsibility or
actions of Clients or Providers whether in public, private or offline interactions. We do, however,
reserve the right to refuse to continue offering Providers or Clients access to the profiles of the
other or referrals for any reason or no reason at all.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Client and Provider content is primarily user-generated, and we do not control or vet
user-generated content for accuracy. MAX does not assume any responsibility for the accuracy or
reliability of any information provided by Providers or Clients on or off this Platform. In addition,
ratings of Clients and Providers are solely within the control of the individual providing feedback
and ratings of the rated individual. We may offer certain Registered Users the opportunity to verify
certain information such as their email address or cell phone number. If we indicate that a
Registered User has verified certain information, it means that the user has complied with the
process we have established for verifying such information. However, we do not guarantee, nor do
we represent or warrant as to the accuracy of such information.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                                <b>
                                                MAX is not responsible for the conduct, whether online or offline, of any Client, Provider, or
other user of the Platform or Services. Moreover, MAX does not assume and expressly
disclaims any liability that may result from the use of information provided on our Platform.
All users, including both Clients and Providers, hereby expressly agree not to hold MAX (or
its officers, directors, shareholders, employees, subsidiaries, other affiliates, successors,
assignees, agents, representatives, advertisers, marketing partners, licensors, independent
contractors, recruiters, or corporate partners or resellers, hereinafter "Affiliates") liable for
the actions or inactions of any Client, Provider or other third party or for any information,
instruction, advice or services which originated through the Platform, and, MAX and its
Affiliates expressly disclaims any liability whatsoever for any damage, suits, claims, and/or
controversies that have arisen or may arise, whether known or unknown therefrom.</b>
                                            </p>
                                        </li>
                                    </ol>
                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                    CLIENT’s AND PROVIDER’S REPRESENTATION AND WARRANT. By using this Platform and Services
you acknowledge, represent, and warrant the following:

                                    </p>


                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Provider shall report as income all compensation received pursuant to this Agreement and pay all
state, local, or federal taxes thereon.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Client and Provider assume responsibility for and shall at all times comply with all applicable laws,
ordinances, statutes, and rules applicable to Clients and Providers contemplated herein. Client and
Provider shall be responsible for any business expenses incurred in connection with the
performance of Providers, including but not limited to all costs for the purchase and maintenance of
materials, supplies, equipment, tools, training, communication devices, transportation, and
business permits or licenses where required by state or local law, ordinance, or regulation. The
possible financial risk due to the Client’s non-payment. Provider’s management skills and
performance of products and services are factors associated with financial success and are not
controlled in any way by the Platform. In the event a Client fails to make payment for a portion of or
all of the services rendered by Provider, Platform will have no responsibility to make up any
difference in the shortfall of fees due to Provider attributable to Client's non-payment.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Provider shall be responsible for any damage, harm or injury to property, client, person or persons
caused by Provider’s Services. Provider acknowledges he or she shall pay for any and all
damages incurred to Client’s property or personal belongings.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            HIPAA: MAX may perform or assist in performing a function or activity on Client’s or Provider’s
behalf that involves the use, download, upload, transfer, and disclosure of Protected Health
Information (as defined in 45 C.F.R. 164.501; hereinafter, “PHI”). The parties hereto shall use or
disclose such PHI as required by the Health Insurance Portability and Accountability Act of 1996
(“HIPAA”), the Standards for Privacy of Individually Identifiable Health Information (the “Privacy
Rule”) and the Standards for Security of Electronic Protected Health Information (the “Security
Rule”) promulgated thereunder, and the Health Information Technology for Economic and Clinical
Health Act (Division A, Title XIII and Division B, Title IV, of the American Recovery and
Reinvestment Act of 2009, Pub. L. 111-5) (the “HITECH Act”). Capitalized terms used but not
otherwise defined in this Section shall have the same meaning given to such terms in HIPAA, the
HITECH Act, or any implementing regulations promulgated thereunder, including but not limited to
the Privacy Rule and the Security Rule.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                                Business Associate Agreement:
                                            {/* <a href="https://maxmrj.com/business-associate-agreement/">
                                                    https://maxmrj.com/business-associate-agreement/
                                            </a> */}
                                            </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                                CCPA
                                        </p>
                                            <ol type="i">
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    This applies solely to the extent that (1) MAX’s Services are not exempt from the California
Consumer Privacy Act of 2018 (“CCPA”) under California Civil Code sections
1798.145(c)(1)(A) and (c)(1)(B) pertaining to medical information, PHI, providers of health
care, and covered entities; (2) you are a “business” within the meaning of the CCPA; and
(3) MAX is processing the personal information of California residents.

                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    For purposes of this section, “Customer Personal Information” means any “personal
information” (as defined in the CCPA) contained within the data that MAX “processes” (as
defined in the CCPA) in connection with performing the Service under the Agreement.

                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    MAX is a “service provider” (as such term is defined under the CCPA) under this
Agreement. MAX will not “sell” (as defined in the CCPA) any Customer Personal
Information. MAX will not retain, use, disclose or otherwise process Customer Personal
Information for any purpose other than for performing the Services, or as otherwise
permitted by the CCPA. MAX may create and derive from its provision of the Services
anonymized and/or aggregated data that does not identify you or any consumer, and use,
publicize or share with third parties such data to improve MAX’s products and services and
for MAX’s other lawful business purposes. Client, Provider, and MAX acknowledge and
agree that MAX’s access to Customer Personal Information is not part of the consideration
exchanged by the parties in respect of the Agreement. MAX certifies that it understands its
obligations under this section and MAX shall comply with them.

                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    Client and Provider are solely responsible for (1) identifying whether the CCPA applies to
you; (2) providing any notices of Client or Provider privacy practices that CCPA may
require; and (3) identifying and responding to consumer requests to exercise CCPA rights
to access, delete, or opt-out of the sale of personal information (collectively, “CCPA
Inquiries”), including for verifying the identity of consumers submitting CCPA Inquiries and
for evaluating the scope and legality of CCPA Requests. MAX will provide reasonable
assistance to you in responding to such CCPA Inquiries. MAX will treat any CCPA Inquiries
that you submit to MAX as presumptively valid under the CCPA.

                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    With respect to CCPA Requests for which you require MAX to provide assistance, you
shall: (a) notify MAX within 10 days of your receipt of the CCPA Inquiries by emailing
info@maxmrj.com, and (b) provide MAX with the consumer’s email address or such other
information that would permit MAX to meet the request. You shall be solely responsible and
liable for responding to the individual’s CCPA Inquiries, including without limitation the
content and timing of the response, in compliance with the CCPA. Additionally:
                                            </p>
                                                    <ol>
                                                        <li dir="ltr">
                                                            <p dir="ltr">
                                                            In response to CCPA Inquiries for access to Customer Personal Information that
you submit to MAX, within 10 business days of MAX’s receipt of such request from
you, MAX will provide you with information that contains the Customer Personal
Information that MAX has about the individual via a secure method of transfer. MAX
reserves the right to withhold from any Customer Personal Information that the
CCPA does not require to be provided in response to CCPA Inquiries.

                                                </p>
                                                        </li>
                                                        <li dir="ltr">
                                                            <p dir="ltr">
                                                            In response to a CCPA Inquiries for the deletion of Customer Personal Information
that Client or Provider submit to MAX, except as otherwise required by applicable
law or permitted by the CCPA, within 10 business days of MAX’s receipt of such
request from you, MAX will delete the Customer Personal Information, to the extent
MAX maintains such Customer Personal Information about the individual. Client and
Provider agree that MAX may delete such Customer Personal Information by
anonymizing and/or aggregating the information such that the information does not
identify, and is not reasonably capable of identifying, the individual.

                                                </p>
                                                        </li>

                                                    </ol>
                                                </li>



                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    Client and Provider agree that you will not direct or otherwise cause MAX to share any
Customer Personal Information with any third party in a manner that may constitute a “sale”
as such term is defined in the CCPA.
                                            </p>
                                                </li>


                                            </ol>

                                        </li>



                                        <li dir="ltr">
                                            <p dir="ltr">
                                            TCPA: Regarding compliance with the Telephone Consumer Protection Act of 1991, located at 47
U.S.C. §§ 227 et seq., including the implementing regulations therefore located at 47 C.F.R.
64.1200 et seq. (“TCPA”) and the Telemarketing Sales Rule authorized by the Telemarketing and
Consumer Fraud and Abuse Prevention Act, located at 15 U.S.C. §§ 6101-6108 (“TSR”) and the
Controlling the Assault of Non-Solicited Pornography and Marketing Act of 2003, located at 15
U.S.C §§ 7701-7713 (“CAN-SPAM Act”). As between Client, Provider, and MAX, Client and
Provider agree to comply and be solely responsible for complying with all laws governing any
messages sent or received in connection with Client and Provider access and use the Services,
including the TCPA, TSR, and CAN-SPAM Act. Specifically, Client and Provider agree to be
responsible for, without limitation, obtaining any legally required consent(s) from any and all third
parties (including your patients or consumers) to send and receive any chat messages, text
message and/or emails, voice calls, video calls or any other form of communication using the
Services and honoring any requests revoking such consent or otherwise “opting-out” of receiving
any such communications. Client and Provider agree to be liable for and to indemnify, defend and
hold harmless MAX from and against any and all damages, liabilities, judgments, fees, fines, costs
and expenses (including reasonable attorneys’ fees) incurred by MAX arising from any claims,
demands or legal actions made against MAX resulting from your failure to comply with this section.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Provider consents to receive information, which may constitute Protected Health Information, about
a Client in order to perform his or her services. Provider understands and agrees to comply with all
applicable state and federal laws regarding the privacy and confidentiality of Client’s financial and
health information. Accordingly, Provider shall treat all such information as confidential and shall
use appropriate safeguards to prevent misuse or disclosure of such information to any third party,
except as required by law.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Platform provides referrals for Provider Services. Platform allows Clients to refer to Providers
                                        </p>
                                        </li>
                                    </ol>


                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                    RELATIONSHIP BETWEEN THE CLIENT AND THE PROVIDER. Clients may determine to continue or
terminate services with a Provider at any time for any reason, including but not limited to the following
based on Provider actions: Failure to provide products or services as requested by Client and
agreed-upon by Provider; Arriving later than requested by Client; Leaving earlier than requested by Client;
Failing or refusing to perform agreed-upon Provider Services as requested by Client; Client accusations of
theft, or other improper conduct.

                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                    NON-SOLICITATION / NON-INTERFERENCE WITH CONTRACT. By using the Platform to seek
Providers Services for Clients by seeking a Provider to perform Provider Services, you agree not to
interfere with the contractual relationship between MAX and the Client, or MAX and the Provider,
respectively.

                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                    ELIGIBILITY TO USE PLATFORM AND SERVICES. By requesting to use, registering to use and/or using
the Platform or the Services, you represent and warrant you have the right, authority and capacity to enter
into these Terms and you commit to abide by all of the terms and conditions hereof. You also represent,
warrant, and promise the following:
                                    </p>
                                </li>
                                <ol type="a">
                                    <li dir="ltr">
                                        <p dir="ltr">
                                        You are at least 18 years of age. If not, do not register to use the Platform or Services.
                                        </p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr">
                                        If you are registering to be a Provider, you are legally permitted to operate within the United States.
                                        </p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr">
                                        You will abide by, and not use our Platform in any way that violates, all applicable local, state, or
federal law or regulation.

                                        </p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr">
                                        You will not impersonate or attempt to impersonate us, our employees, another customer or user,
or any other company, person, or entity.

                                        </p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr">
                                        You will not do anything that could disable, overburden, damage, or impair our Platform or interfere
with any person’s use or entity’s use of our Platform.

                                        </p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr">
                                        You will not use any robot, spider, or other automatic devices, process, or means to access our
Platform for any unlawful purpose or in violation of these Terms of Use.
                                        </p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr">
                                        You will not introduce any viruses, trojan horses, worms, logic bombs, or other material that is
malicious or technologically harmful.

                                        </p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr">
                                        You will not co-brand or frame our Platform or hyper-link to it without first obtaining the express
prior written permission of an authorized representative of MAX.
                                        </p>
                                    </li>
                                </ol>
                                <li dir="ltr">
                                    <p dir="ltr">
                                    REGISTRATION AND CONTENT
                                    </p>
                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                                Registration. To use the Services, you must register for an account on the Platform (an “Account”).
You must provide accurate and complete information and keep your Account information updated.
You may not: (i) select or use as a username a name of another person with the intent to
impersonate that person; (ii) use as a username a name subject to any rights of a person other
than you without appropriate authorization; or (iii) use, as a username, a name that is otherwise
offensive, vulgar or obscene. You are solely responsible for the activity that occurs on your
Account, and for keeping your Account password secure. You may never use another person’s
user account or registration information for the Services without permission. You must notify us
immediately of any change in your eligibility to use the Services (including any changes to or
revocation of any licenses from state authorities), breach of security or unauthorized use of your
Account. You should never publish, distribute, or post login information for your Account. You shall
have the ability to delete your Account, either directly or through a request made to one of our
employees or affiliates. To register for an Account, during the registration process, Providers must
review, execute, and submit to MAX the Provider Referral Agreement.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                                Content.
                                        </p>
                                            <ol type="i">
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    Definition. For purposes of these Terms and Conditions, the term “Content” includes,
without limitation, information, reviews, data, text, photographs, videos, audio clips, written
posts and comments, communication, software, scripts, graphics, and interactive features
generated, provided, or otherwise made accessible on or through the Services. For the
purposes of this Agreement, “Content” also includes any Content added, created, uploaded,
submitted, distributed, or posted to the Services by users (collectively “User Contributions”).
                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    User Content: MAX shall not be responsible for any User Contributions, whether publicly
posted or privately transmitted. You represent that all User Content provided by you is
accurate, complete, up-to-date, and in compliance with all applicable laws, rules, and
regulations. You acknowledge that all Content, including User Content, accessed by you
using the Services is at your own risk and you will be solely responsible for any damage or
loss to you or any other party resulting therefrom. We do not guarantee that any Content
you access on or through the Services is or will continue to be accurate.

                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    User Contributions: We may from time-to-time provide interactive services, such as
message boards, customer comments, reviews, and feedback, blog posts, or other
interactive features that allow users to post, submit, publish, blog, display or transmit User
Contributions on or through our Platform. All User Contributions must comply with the
Content Standards set out in these Terms and Conditions. You understand and agree that
MAX may, in its sole discretion, review, edit, and delete any Content, in each case in whole
or in part, that in the sole judgment of MAX violates these Terms or which MAX determines
in its sole discretion might be offensive, illegal, or that might violate the rights, harm, or
threaten the safety of users of the Platform or others. Any User Contribution you post will be
considered non-confidential and non-proprietary, to the extent permitted by law. MAX does
not solicit nor does it wish to receive any confidential, secret, or proprietary information or
other materials from you through the Platform or mail or e-mail addresses, or in any other
way. By providing User Contribution, you grant us and our successors the right to use,
reproduce, modify, perform, display, distribute, delete, or disclose to third parties any such
material. You promise you own or control all rights in and to the User Contributions and
have the right to grant such license to us. You agree that you will have no claim or other
recourse against us for infringement of any proprietary rights with respect to your User
Contributions. You acknowledge and agree that you waive any moral (or similar) rights that
you may have in any territory regarding such User Contributions, including but not limited
to, the right to be attributed as the author of the User Contributions.
                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    No Guarantee: If you provide User Contributions to be published or displayed on public
areas of our Platform, or transmitted to other users of the Platform or any third parties, you
accept that your User Contributions are posted on and transmitted to others at your own
risk. Additionally, we cannot control the actions of other users of our Platform or any third
parties with whom you may choose to share your User Contributions. Therefore, we cannot
and do not guarantee that your User Contributions will not be viewed by unauthorized
persons.
                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    Accuracy of User Contributions: User Contributions, including ratings of others, must be
accurate and comply with all applicable laws. You understand and acknowledge that you
are responsible for any User Contributions you submit or contribute, and you, not us, have
full responsibility for such content, including its legality, reliability, accuracy, and
appropriateness. We are not responsible, or liable to any third-party, for the content or
accuracy of any User Contributions posted by you or any other user of our Platform.

                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    Opinions, advice, statements, offers, or other information or content made available on the
Platform or through the Platform, but not directly by MAX, are those of their respective
authors. Such authors are solely responsible for such Content. MAX does not: (i) guarantee
the accuracy, completeness, or usefulness of any information on the Platform or available
through the Service, or (ii) adopt, endorse or accept responsibility for the accuracy or
reliability of any opinion, advice, or statements made by any party that appears on the
Platform or through the Service. Under no circumstances will MAX or its Affiliates be
responsible for any loss or damage resulting from (a) your reliance on information or other
content posted on the Platform or transmitted to or by any user of the Platform or Service;
or (b) reviews or comments made about you on the Platform by other users.
                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                    You agree MAX has no obligation to remove any reviews or other information posted on the
Platform about you or any other person or entity. You may not terminate your registration
and re-register in order to prevent a review from being associated with your Account. The
author of a review can always remove or request the removal of a review they have written.
                                            </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                        <b>Disclaimer of Liability. MAX disclaims any liability whatsoever for any misstatements
and/or misrepresentations made by any users of the Platform or Services of MAX.
Users hereby represent, understand, and agree to hold MAX harmless for any
misstatements and/or misrepresentations made by or on behalf of them on this
Platform or in any other venue.
</b>
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>

                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Exclusive Use. If you are a Client, you may use your Account only to find for yourself, your entity,
your clients, or other individuals for whom you are otherwise the legal guardian and authorized
representative. If you are a Provider, you may use your Account only to update your information,
make it available to Clients, and accept or reject referrals. You are responsible for all activity on
and use of your Account, and you may not assign or otherwise transfer your Account to any other
person or entity.
                                        </p>
                                        </li>
                                    </ol>
                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                    PROCESSING OF PERSONAL DATA. Your personal data will be treated in accordance with MAX’s
Privacy Notice, available at
                                        <a href="https://maxmrj.com/privacy-policy">
                                            https://maxmrj.com/privacy-policy
                                        </a>
                                        .
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        RULES OF CONDUCT.
                                    </p>
                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            As a condition of use and/or submittal of User Contributions, you promise not to use the Services
for any purpose that is prohibited by these Terms and Conditions. You are responsible for all of
your activity, and all activities connected to your Account in connection with the Services (including
without limitation your communications and collection of data from other users of the Services).
                                            </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            These content standards apply to all User Contributions and the use of interactive services if
offered. User Contributions must in their entirety comply with all applicable local, state, and federal
laws and regulations. Without limiting the foregoing, User Contributions must not: (i) Contain any
material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful,
inflammatory or otherwise objectionable; (ii) Promote sexually explicit or pornographic material,
violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or
age; (iii) Infringe any patent, trademark, trade secret, copyright or other intellectual property or
other rights of any third-party; (iv) Infringe the legal rights (including the right of publicity and
privacy) of others or contain any material that could give rise to any civil or criminal liability under
applicable laws; (v) Promote any illegal activity, or advocate, promote or assist any unlawful act;
(vi) Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm
or annoy any other person; (vii) Impersonate any person, or misrepresent your identity or affiliation
with any person or organization; (viii) Involve commercial activities or sales; (ix) Be likely to deceive
or give the impression that they emanate from or are endorsed by us, or any other person or entity
                                            </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            You shall not: (i) take any action that imposes or may impose (as determined by us in our sole
discretion) an unreasonable or disproportionately large load on our (or our third-party providers’)
infrastructure; (ii) interfere or attempt to interfere with the proper working of the Services or any
activities conducted on the Services; (iii) bypass, circumvent or attempt to bypass or circumvent
any measures we may use to prevent or restrict access to the Services (or other accounts,
computer systems or networks connected to the Services); (iv) run any form of auto-responder or
“spam” on the Services; (v) use manual or automated software, devices, or other processes to
“crawl” or “spider” any page of the Platform; (vi) harvest or scrape any Content from the Services;
or (vii) otherwise take any action in violation of our guidelines and policies.

                                            </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            You shall not (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer or
otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the
Services (including without limitation any application), except to the limited extent applicable laws
specifically prohibit such restriction, (ii) modify, translate, or otherwise create derivative works of
any part of the Services, or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights
that you receive hereunder. You shall abide by all applicable local, state, national and international
laws, and regulations.
                                            </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            We also reserve the right to access, read, preserve, and disclose any information as we
reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or
governmental request, (ii) enforce these Terms and Conditions, including investigation of potential
violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv)
respond to user support requests, or (v) protect the rights, property or safety of us, our users and
the public.

                                            </p>
                                        </li>
                                    </ol>
                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                    THIRD-PARTY SERVICES. The Services may permit you to link to other websites, services or resources
on the Internet, and other websites, services or resources may contain links to the Services. When you
access third party resources on the Internet, you do so at your own risk. These other resources are not
under our control, and you acknowledge that we are not responsible or liable for the content, functions,
accuracy, legality, appropriateness or any other aspect of such websites or resources. The inclusion of
any such link does not imply our endorsement or any association between us and their operators. You
further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any
damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such
content, goods or services available on or through any such website or resource.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        PAYMENTS AND BILLING.
                                    </p>
                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Paid Services. Certain of our Services may be subject to payments now or in the future (the “Paid
Services”). Please note that any payment terms presented to you in the process of using or signing
up for a Paid Service are deemed part of this Agreement.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Paid Services. Certain of our Services may be subject to payments now or in the future (the “Paid
Services”). Please note that any payment terms presented to you in the process of using or signing
up for a Paid Service are deemed part of this Agreement.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Recurring Billing. Some of the Paid Services may consist of an initial period, for which there is no
charge or a one-time or initial charge, followed by recurring period charges as agreed to by you. By
choosing a recurring payment plan, you acknowledge that such Services have an initial and
recurring payment feature and you accept responsibility for all recurring charges prior to
cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., WEEKLY OR MONTHLY) WITHOUT
FURTHER AUTHORIZATION FROM YOU UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF
WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR
WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES
SUBMITTED BEFORE WE REASONABLY COULD ACT. TO TERMINATE YOUR
AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO YOUR ACCOUNT OR
NOTIFY US IN WRITING VIA US MAIL.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Current Information Required. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE
INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL
INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE
(SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD
EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US IF YOUR PAYMENT METHOD
IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL
BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR
USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT
WWW.MAXMRJ.COM. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION,
YOU AGREE WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES
UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES
AS SET FORTH ABOVE.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Change in Amount Authorized. If the amount to be charged to your Billing Account varies from the
amount you pre-authorized or was invoiced (other than due to the imposition or change in the
amount of state sales taxes, if any), you have the right to receive, and we shall provide, a notice of
the amount to be charged and the date of the charge before the scheduled date of the transaction.
Any agreement you have with your payment provider will govern your use of your Payment
Method. You agree that we may accumulate charges incurred and submit them as one or more
aggregate charges during or at the end of each billing cycle.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Reaffirmation of Authorization. Your non-termination or continued use of a Paid Service reaffirms
that we are authorized to charge your Payment Method for that Paid Service. We may submit those
charges for payment, and you will be responsible for such charges. This does not waive our or the
Provider’s right to seek payment directly from you. Your charges may be payable in advance, in
arrears, per usage, or as otherwise described when you initially selected to use the Paid Service.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Cancellation Policy/Fees. You may cancel your subscription at any time. If you cancel, you will not
be billed for any additional terms of service, and service will continue until the end of the current
Subscription Term. If you cancel, you will not receive a refund for any service already paid for. If
you have purchased a subscription for a specific term, such termination will be effective on the last
day of the then-current term. Your subscription may provide that a Renewal Term will begin
automatically unless either party provides notice of termination at least thirty (30) days prior to the
commencement of the next Renewal Term. If you fail to comply with any provision of this
Agreement, MAX may terminate this Agreement immediately and retain any fees previously paid
by you. Upon termination of this Agreement, you must cease any further use of the Services. If at
any time You are not happy with the Services, Your sole remedy is to cease using the Services
and follow this termination process. You agree that MAX collects interest at the lesser of 1.5% per
month or the highest amount permitted by law on any amounts not paid when due.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            TERMINATION. We may terminate your access to all or any part of the Services at any time, with
or without cause, with or without notice, effective immediately, which may result in the forfeiture
and destruction of all information associated with your membership. If you wish to terminate your
Account, you may do so by following the instructions on the Platform or through the Services. Any
fees paid hereunder are non-refundable. All provisions of these Terms and Conditions which by
their nature should survive termination shall survive termination, including without limitation
ownership provisions, warranty disclaimers, indemnity and limitations of liability. Upon termination,
MAX shall be under no obligation to provide you with a copy of any Content posted by or about you
on the Platform. If we terminate your registration, we have no obligation to notify you of the reason,
if any, for the termination of your registration. MAX does not have the authority to terminate the
relationship between any Client and Provider, consumer and Provider. <b>Following any termination
of any individual's use of the Platform or the Services, MAX reserves the right to send a
notice thereof to other Users with whom we believe the deregistered individual has
corresponded.</b> Our decision to terminate an individual's registration and/or to notify other
Registered Users with whom we believe the individual has corresponded does not constitute, and
should not be interpreted or used as information bearing on, the individual's character, general
reputation, personal characteristics, or mode of living.

                                        </p>
                                        </li>
                                    </ol>
                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                    . CONSENT TO ELECTRONIC COMMUNICATION. By using the Platform or Services, you agree to allow
MAX to communicate with you electronically, and you consent to electronic delivery of notices, documents,
or products from MAX via the Platform, mobile application, online messaging platform, or email. You also
agree to check your MAX Account, alerts, and messages, and the email account used to register on the
Platform on a reasonably regular basis to stay apprised of important notices and information about your
Account.

                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                    CONSENT TO GEOLOCATION. By using the Platform or Services, you agree to allow MAX to use the
location of Platform usage.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                    CONSENT TO EMERGENCY MEDICAL. If at any time, due to such circumstances as an injury or sudden
illness, medical treatment is necessary, Client authorizes MAX or the Provider to take whatever
emergency measures they deem necessary for the protection of the Client. Client understands this may
involve contacting a doctor, interpreting and carrying out his or her instructions, and transporting Client to
a hospital or doctor’s office, including the possible use of an ambulance. Client also authorizes the
providers of emergency services to bill Client for the costs of such care. Client agrees to be responsible for
all costs related to such emergency and further agrees to indemnify and reimburse MAX or Provider any
costs or fees incurred related to such emergency care.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                    WARRANTY AND OTHER DISCLAIMERS.
                                    </p>
                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            We have no special relationship with or fiduciary duty to you. You acknowledge that we have no
duty to take any action regarding:

                                        </p>
                                            <ol type="i">
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                        which users gain access to the Services;
                                                </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                        what Content you access via the Services; or
                                                </p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr">
                                                        how you may interpret or use the Content.
                                                </p>
                                                </li>
                                            </ol>
                                        </li>

                                        <li dir="ltr">
                                            <p dir="ltr">
                                            You release us from all liability for you having acquired or not acquired Content through the
Services. We make no representations concerning any Content contained in or accessed through
the Services, and we will not be responsible or liable for the accuracy, copyright compliance, or
legality of material or Content contained in or accessed through the Services.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            THE SERVICES AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE” AND WITHOUT
WARRANTY OF ANY KIND, EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE
IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS
FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF
PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE,
AND OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS, AND CONTENT
PROVIDERS DO NOT WARRANT THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE
AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE
CORRECTED; (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE
SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV) THE
RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS. YOUR USE OF
THE SERVICES IS SOLELY AT YOUR OWN RISK. SOME STATES DO NOT ALLOW
LIMITATIONS ON IMPLIED WARRANTIES, SO THE FOREGOING LIMITATIONS MAY NOT
APPLY TO YOU.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            WE DO NOT GUARANTEE THAT THE SERVICES WILL FUNCTION WITHOUT INTERRUPTION
OR ERRORS. IN PARTICULAR, THE OPERATION OF THE SERVICES MAY BE INTERRUPTED
DUE TO MAINTENANCE, UPDATES, OR SYSTEM OR NETWORK FAILURES, AND SUCH
FAILURES MAY RESULT IN ERRORS OR DATA LOSS. WE DISCLAIM ALL LIABILITY FOR
DAMAGES CAUSED BY ANY SUCH INTERRUPTION OR ERRORS IN FUNCTIONING, OR BY
THE LOSS OF ANY DATA OR INFORMATION YOU PROVIDE TO MAX. FURTHERMORE, WE
DISCLAIM ALL LIABILITY FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR
POOR USE CONDITIONS OF THE SERVICES DUE TO INAPPROPRIATE EQUIPMENT,
DISTURBANCES RELATED TO INTERNET SERVICE PROVIDERS, TO THE SATURATION OF
THE INTERNET NETWORK, AND FOR ANY OTHER REASON.
                                        </p>
                                        </li>
                                    </ol>
                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                    INDEMNIFICATION. By using the Platform and Services and agreeing to these Terms and Conditions,
you agree you shall defend, indemnify, and hold harmless MAX, its affiliates and each of our and their
respective employees, contractors, directors, suppliers, and representatives from all liabilities, claims, and
expenses, including reasonable attorneys’ fees, that arise from or relate to your use or misuse of, or
access to, the Platform, the Services, Content, or otherwise from your User Contributions, your violation of
these Terms and Conditions, or infringement by you, or any third party using your Account or identity in the
Services, of any intellectual property or other right of any person or entity, or any relationship or agreement
formed with a Client or Provider using the Platform or Services. We reserve the right to assume the
exclusive defense and control of any matter otherwise subject to indemnification by you, in which event
you agree you will assist and cooperate, as reasonably required, with us in asserting any available
defenses. Users further agree to hold harmless MAX and its Affiliates from any claim arising from a third
party’s use of information or materials of any kind that users post to the Platform.

                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                    ASSUMPTION OF RISK. Users assume all risks when using the Platform and the Services, including but
not limited to all of the risks associated with any online or offline interactions with or between users of the
Platform or the Services. <b> MAX has no liability for non-MAX Actions.</b> IN NO EVENT WILL MAX BE
LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL,
COMPENSATORY, AND/OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THE CONDUCT
OF YOU OR ANYONE ELSE IN CONNECTION WITH THE USE OF THE PLATFORM OR THE
SERVICES OR ANY AGREEMENT OR RELATIONSHIP FORMED USING THE PLATFORM OR
SERVICE, INCLUDING WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR
ANY OTHER DAMAGES RESULTING FROM ANYONE'S RELIANCE ON INFORMATION OR OTHER
CONTENT POSTED ON THE PLATFORM, OR TRANSMITTED TO OR BY ANY USERS OR ANY
OTHER INTERACTIONS WITH OTHER REGISTERED USERS OF THE PLATFORM OR SERVICES,
WHETHER ONLINE OR OFFLINE. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING
FROM THE CONDUCT OF USERS WHO HAVE REGISTERED UNDER FALSE PRETENSES OR WHO
ATTEMPT TO DEFRAUD OR HARM YOU.
                                    </p>
                                </li>
                                <li dir="ltr">
                                    <p dir="ltr">
                                        TRANSPORTATION
                                    </p>
                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Informed Consent: Client understands that while receiving transportation services from a Provider,
Client could sustain serious personal injuries, illness, property damage, or even death and that
there may be other risks not known to me or not reasonably foreseeable at this time. Client further
understands and agrees that any injury, illness, property damage, disability, or death that Client
may sustain by any means is Client’s sole responsibility.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Release and Waiver Of Liability: Client, on behalf of himself or herself, and his or her personal
representatives, heirs, executors, administrators, agents, and assigns, HEREBY RELEASE,
WAIVE, DISCHARGE, AND COVENANT NOT TO SUE MAX, its directors, officers, employees,
agents, and Providers for any and all liability, including any and all claims, demands, causes of
action (known or unknown), suits, or judgments of any and every kind (including attorneys' fees),
arising from any injury, property damage, or death that Client may suffer as a result of receiving
transportation services from a Provider, REGARDLESS OF WHETHER THE INJURY, DAMAGE
OR DEATH IS CAUSED BY THE NEGLIGENCE OF THE RELEASEES OR OTHERWISE.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Assumption of Risk: Client understands there are potential dangers incidental to receiving
transportation services from a Provider, some of which may be dangerous and which may expose
the Client to the risk of personal injuries, property damage, or even death. Client understands that
these potential risks include, but are not limited to: travel; negligent or willful acts of others; and
other risks that are unknown at this time. YOU KNOWINGLY AND VOLUNTARILY ASSUME ALL
SUCH RISKS, BOTH KNOWN AND UNKNOWN, EVEN IF ARISING FROM THE NEGLIGENCE
OF RELEASEES, and assume full responsibility for receiving transportation services from a
Provider.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Indemnity: Client, on behalf of the Client to whom services are to be rendered to, and his or her
personal representatives, heirs, executors, administrators, agents, and assigns, agree to hold
harmless, defend and indemnify the releasees from any and all liability, including any and all
claims, demands, causes of action (known or unknown), suits, or judgments of any and every kind
(including attorneys' fees), arising from any injury, property damage or death that Client may suffer
as a result of receiving transportation services from a Provider, REGARDLESS OF WHETHER
THE INJURY, DAMAGE OR DEATH IS CAUSED BY THE NEGLIGENCE OF THE RELEASEES
OR OTHERWISE.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Means of transportation: Client may request transportation services and may authorize the use of a
vehicle by a Provider. Client agrees to maintain and validate automobile liability insurance on the
vehicle and maintain the vehicle in good working order.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                                <b>IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION
AGREEMENT AND A WAIVER OF CLASS-ACTION RIGHTS AS DETAILED IN SECTION 21.</b>
                                            </p>
                                        </li>
                                    </ol>
                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                        <b>ARBITRATION CLAUSE AND CLASS ACTION WAIVER – IMPORTANT – PLEASE REVIEW AS THIS
AFFECTS YOUR LEGAL RIGHTS:</b>
                                    </p>
                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                                <b>ARBITRATION; CLASS ACTION WAIVER.</b> YOU AGREE THAT ALL DISPUTES BETWEEN YOU
AND MAX OR ITS OFFICERS, DIRECTORS OR EMPLOYEES IN THEIR CAPACITY AS SUCH
(WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD PARTY) WITH REGARD TO YOUR
RELATIONSHIP WITH MAX, OR ANY CLIENT OR PROVIDER, INCLUDING WITHOUT
LIMITATION DISPUTES RELATED TO THESE TERMS AND CONDITIONS, YOUR USE OF THE
SERVICES, AND/OR RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY
BINDING, INDIVIDUAL ARBITRATION IN ACCORDANCE WITH THE STREAMLINED
ARBITRATION RULES AND PROCEDURES OF JAMS, INC. THEN IN EFFECT, AND YOU AND
MAX HEREBY EXPRESSLY WAIVE TRIAL BY JURY; PROVIDED, HOWEVER, THAT TO THE
EXTENT THAT YOU HAVE IN ANY MANNER VIOLATED OR THREATENED TO VIOLATE
MAX’S INTELLECTUAL PROPERTY RIGHTS, WE MAY SEEK INJUNCTIVE OR OTHER
APPROPRIATE RELIEF IN ANY STATE OR FEDERAL COURT IN THE STATE OF CALIFORNIA.
DISCOVERY AND RIGHTS TO APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED
THAN IN A LAWSUIT, AND OTHER RIGHTS THAT YOU AND MAX WOULD HAVE IN COURT
MAY NOT BE AVAILABLE IN ARBITRATION. AS AN ALTERNATIVE, YOU MAY BRING YOUR
CLAIM IN YOUR LOCAL “SMALL CLAIMS” COURT, IF PERMITTED BY THAT SMALL CLAIMS
COURT'S RULES AND IF WITHIN SUCH COURT’S JURISDICTION, UNLESS SUCH ACTION IS
TRANSFERRED, REMOVED OR APPEALED TO A DIFFERENT COURT. YOU MAY BRING
CLAIMS ONLY ON YOUR OWN BEHALF. NEITHER YOU NOR MAX WILL PARTICIPATE IN A
CLASS ACTION OR CLASS-WIDE ARBITRATION FOR ANY CLAIMS COVERED BY THIS
AGREEMENT TO ARBITRATE. YOU ARE GIVING UP YOUR RIGHT TO PARTICIPATE AS A
CLASS REPRESENTATIVE OR CLASS MEMBER ON ANY CLASS CLAIM YOU MAY HAVE
AGAINST MAX INCLUDING ANY RIGHT TO CLASS ARBITRATION OR ANY CONSOLIDATION
OF INDIVIDUAL ARBITRATIONS.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            You also agree not to participate in claims brought in a private attorney general or representative
capacity, or consolidated claims involving another person’s account, if MAX is a party to the
proceeding. This dispute resolution provision will be governed by the Federal Arbitration Act and
not by any state law concerning arbitration. In the event JAMS, Inc. is unwilling or unable to set a
hearing date within one hundred and sixty (160) days of filing the case, then either MAX or you can
elect to have the arbitration administered instead by the American Arbitration Association.
Judgment on the award rendered by the arbitrator may be entered in any court having competent
jurisdiction. The arbitration shall be conducted in the English language. Any provision of applicable
law notwithstanding, the arbitrator will not have the authority to award damages, remedies, or
awards that conflict with these Terms and Conditions. You agree that regardless of any statute or
law to the contrary, any claim or cause of action arising out of, related to or connected with the use
of the Services or these Terms and Conditions must be filed within one (1) year after such claim of
action arose or be forever banned.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                                <b>30-Day Opt-Out Period.</b>  If you do not wish to be bound by the arbitration and class-action waiver
provisions in this Section 21, you must notify MAX in writing within 30 days of the date that you first
accept these Terms and Conditions (unless a longer period is required by applicable law). Your
written notification must be mailed to MAX at the following address: 65 Washington St., PMB #298,
Santa Clara, CA 95050. If you do not notify MAX in accordance with this Section 21(C), you agree
to be bound by the arbitration and class-action waiver provisions of these Terms and Conditions,
including such provisions in any Terms and Conditions, revised after the date of your first
acceptance. Such notification must include: (i) your name; (ii) your email and mailing addresses
and (iii) a statement that you do not wish to resolve disputes with MAX through arbitration. If MAX
makes any changes to the Arbitration and Class Action Waiver section of these Terms and
Conditions (other than a change to the address at which we will receive notices of dispute, opt-out
notices, or rejections of future changes to the Arbitration and Clause Action Waiver section), you
may reject any such change by sending MAX written notice within 30 days of the change to the
address set forth in this Section 21C. This notification affects these Terms and Conditions only; if
you previously entered into other arbitration agreements with MAX or enter into other such
agreements in the future, your notification that you are opting out of the arbitration provision in
these Terms and Conditions shall not affect other arbitration agreements between you and MAX.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                                <b>Severability.</b> If the prohibition against class actions and other claims brought on behalf of third
parties contained above is found to be unenforceable, then all of the preceding language in this
Arbitration and Class Action Waiver section will be null and void. This arbitration agreement will
survive the termination of your relationship with MAX.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            LIMITATION OF LIABILITY. IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES,
AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER
CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR
EQUITABLE THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY LOST PROFITS, DATA
LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL,
INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF
ANY KIND WHATSOEVER (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN
HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), (III) WORKERS’
COMPENSATION BENEFITS, OR (IV) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE
AGGREGATE) THE GREATER OF (A) FEES PAID TO US FOR THE PARTICULAR SERVICES
DURING THE IMMEDIATELY PREVIOUS THREE (3) MONTH PERIOD OR (B) $100.00.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            GOVERNING LAW AND JURISDICTION. These Terms and Conditions shall be governed by and
construed in accordance with the laws of the State of California, including its conflicts of law rules,
and the United States of America. You agree that any dispute arising from or relating to the subject
matter of these Terms and Conditions shall be governed by the exclusive jurisdiction and venue of
the state and federal courts of Santa Clara County, California.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            MODIFICATION. We reserve the right, in our sole discretion, to modify or replace any of these
Terms and Conditions, or change, suspend, or discontinue the Services (including without
limitation, the availability of any feature, database, or content) at any time by posting a notice on
the Platform or by sending you a notice through the Services, via e-mail or by another appropriate
means of electronic communication. We may also impose limits on certain features and services or
restrict your access to parts or all of the Services without notice or liability. While we will timely
provide notice of modifications, it is also your responsibility to check these Terms and Conditions
periodically for changes. Your continued use of the Services following notification of any changes
to these Terms and Conditions constitutes acceptance of those changes, which will apply to your
continued use of the Services going forward. Your use of the Services is subject to the Terms and
Conditions in effect at the time of such use.
                                        </p>
                                        </li>
                                    </ol>
                                </li>

                                <li dir="ltr">
                                    <p dir="ltr">
                                        MISCELLANEOUS.
                                    </p>
                                    <ol type="a">
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Notices and Restrictions. The Services may contain Content specifically provided by us, our
partners or our users, and such Content is protected by copyrights, trademarks, service marks,
patents, trade secrets or other proprietary rights and laws. You shall abide by and maintain all
copyright notices, information, and restrictions contained in any Content accessed through the
Services.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            User License. Subject to these Terms and Conditions, we grant each user of the Services a
worldwide, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download
and display locally) Content solely for purposes of using the Services. Use, reproduction,
modification, distribution, or storage of any Content for other than purposes of using the Services is
expressly prohibited without prior written permission from us. You shall not sell, license, rent, or
otherwise use or exploit any Content for commercial use or in any way that violates any third-party
right.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Entire Agreement and Severability. These Terms and Conditions and the Privacy Notice are the
entire agreement between you and us with respect to the Services offered through your use or
access of this Platform or Services, and supersede all prior or contemporaneous communications
and proposals (whether oral, written or electronic) between you and us with respect to the
Services. If any provision of these Terms and Conditions is found to be unenforceable or invalid,
that provision will be limited or eliminated to the minimum extent necessary so that these Terms
and Conditions will otherwise remain in full force and effect and enforceable. The failure of either
party to exercise in any respect any right provided for herein shall not be deemed a waiver of any
further rights hereunder.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Force Majeure. We shall not be liable for any failure to perform our obligations hereunder where
such failure results from any cause beyond our reasonable control, including, without limitation,
mechanical, electronic or communications failure or degradation.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Assignment. These Terms and Conditions are personal to you and are not assignable,
transferable, or sublicensable by you except with our prior written consent. We may not assign,
transfer, or delegate any of our rights and obligations hereunder without consent.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Agency. No agency, partnership, joint venture, or employment relationship is created as a result of
these Terms and Conditions, registration on this Platform, or by acceptance of any referral, and
neither party has any authority of any kind to bind the other in any respect.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Notices. Unless otherwise specified in these Terms and Conditions, all notices under these Terms
and Conditions will be in writing and will be deemed to have been duly given when received, if
personally delivered or sent by certified or registered mail, return receipt requested; when receipt is
electronically confirmed, if transmitted by facsimile or email; or the day after it is sent, if sent for
next day delivery by recognized overnight delivery service. Electronic notices should be sent to
info@maxmrj.com.

                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            No Waiver. Our failure to enforce any part of these Terms and Conditions shall not constitute a
waiver of our right to later enforce that or any other part of these Terms and Conditions. Waiver of
compliance in any particular instance does not mean that we will waive compliance in the future. In
order for any waiver of compliance with these Terms and Conditions to be binding, we must
provide you with written notice of such waiver through one of our authorized representatives.
                                        </p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr">
                                            Headings. The section and paragraph headings in these Terms and Conditions are for
convenience only and shall not affect their interpretation.

                                        </p>
                                        </li>
                                    </ol>
                                </li>

                            </ol>
                            <p dir="ltr">
                            Contact: If you have any questions or need further information regarding the Platform or Services provided by
MAX, you may contact us at the following address: MAX MRJ, 65 Washington St., PMB #298, Santa Clara, CA
95050
                            </p>

                        </div>
                        <DialogActions className="cms-links">
                            <FormErrors
                                show={this.state.disAgreeService}
                                formErrors={this.state.formErrors}
                                fieldName="disAgreeService"
                                className="cms-error"
                            />
                            <Button className="aggree-link btn btn1" onClick={() => this.agreeService()}>I&nbsp;Agree</Button>
                            <Button className="aggree-link btn btn2" onClick={() => this.disAgreeService()}>I&nbsp;Disagree</Button>

                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetail: PropTypes.object
};

export default withStyles(dashboardStyle)(Dashboard);