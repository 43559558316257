/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import { createBrowserHistory } from "history";
import {
  /* BrowserRouter as */ Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import Admin from "./Admin.jsx";
import ProviderAdmin from "./Provider.jsx";
import ProviderUserAdmin from "./ProviderUser.jsx";
import DPAdmin from "./DP.jsx";
import Login from "components/Login/Login.jsx";
import Signup from "components/Signup/Signup.jsx";

import "assets/css/material-dashboard-react.css?v=1.7.0";
import ForgotPassword from "components/Login/ForgotPassword";
import ResetPassword from "components/Login/ResetPassword";
import { basePath, baseRoutes, dpBaseRoutes, providerUserBaseRoutes, providerBaseRoutes } from "base-routes";
import Page404 from "components/Login/Page404";
import { PrivateRoute } from "components/PrivateRoute";
import {
    USER_INFO,
    ADMIN_USER,
    DP_USER,
    PROVIDER_USER,
    PROVIDER,
} from "__helpers/constants";
import { CircularProgress, LinearProgress } from "@material-ui/core";

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        };
    }
    componentDidMount() {
        let user = JSON.parse(localStorage.getItem(USER_INFO));
    }
    render() {
        let user = JSON.parse(localStorage.getItem(USER_INFO));
        const hist = createBrowserHistory();
        return (
            <div>
                <LinearProgress
                    size={24}
                    className="buttonProgress"
                />
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetail: PropTypes.object
};

export default withStyles(dashboardStyle)(Dashboard);
