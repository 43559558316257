/* In All function we will call token refersh api ,
in case of token authrization, we will store timestamp on all api call excep logout,
then comapare this timestamp agains the token life time variable*/

// import config from 'config';
import {
  authHeader,
  reauthorizeTokenHeader,
  generalAuthTokenHeader,
  generalOctetAuthTokenHeader,
} from "__helpers/auth-header";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import sortJsonArray from "sort-json-array";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  NotificationOptions,
  FORBIDDEN_STATUS,
  INVALID_DATA_POST,
  NO_DATA_FOUND,
  SECURITY_ERROR,
  SECURITY_ERROR_CODE,
  CLIENT_ID,
  CLIENT_SECRET,
  GRAND_TYPE,
  CLIENT_TOKEN_LIFETIME,
  DEFAULT_PROFILE_IMG,
  REDUX_STATE_DATA,
  USER_INFO,
  PATIENT_LIST,
  DP_DASHBOARD_FILTERS,
  PCC_AUTH_DATA,
} from "__helpers/constants";
import fetch from "isomorphic-fetch";
import { clientTokenHeader } from "__helpers/auth-header";
import { DATA_LOADING } from "__helpers/constants";
import { OK_SUCCESS_STATUS } from "__helpers/constants";
import { v4 as uuidv4 } from "uuid";
import { SELECTED_PATIENT_LIST } from "__helpers/constants";
import { providerGeneralAuthTokenHeader } from "__helpers/auth-header";

export const userService = {
  login,
  logout,
  // getAll,
  fetchProfile,
  fetchSheetsData,
  fetchFilesBlob,
  fetchCommentSheetsData,
  fetchGlobalApis,
  fetchGlobalApisSilent,
  fetchGlobalApisProvider,
  fetchGlobalApisSilentProvider,
  // updateUserInfo,
  // generateClientAouth,
  // refreshClientAouth,
  // refreshClientAouthImmediate,
  // refreshClientAdNetwork,
  // fetchUsrInfo,
  showNotification,
  fetchBannerText,
  getStateList,
  getProviderDataUsage,
  fetchsReferralReportData,
};
var originalSetItem = localStorage.setItem;
localStorage.setItem = function(key, value) {
  var event = new Event("itemInserted");
  event.value = value; // Optional..
  event.key = key; // Optional..
  document.dispatchEvent(event);
  originalSetItem.apply(this, arguments);
};
var localStorageSetHandler = function(e) {
  // if(document.querySelector(".storage-observation"))
  //   document.querySelector(".storage-observation").textContent = e.value;
};
document.addEventListener("itemInserted", localStorageSetHandler, false);

function login(username, password) {
  const data = {
    username: username,
    password: password,
    grantType: "password",
    scope: "user",
  };
  // const requestOptions = {
  //   method: "POST",
  //   headers: clientTokenHeader(),
  //   // headers: { Accept: "application/json", "Content-Type": "application/json" },
  //   body: JSON.stringify(data),
  //   // body: JSON.stringify({ username, password })
  // };
  // return fetch(`${apiPath.login}`, requestOptions)
  try {
    const response = fetch(`${apiPath.login}`, {
      method: "POST",
      headers: clientTokenHeader(),
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
          logout();
        } else if (response.ok) {
          let accessToken = response.headers.get("Authorization");
          if (accessToken !== undefined) {
            let userData = window.btoa(data.username + ":" + data.password);
            let user = {
              authdata: window.btoa(userData),
              accessToken: accessToken,
            };
            localStorage.setItem("user", JSON.stringify(user));
            window.location.reload(true);
            return;
          }
        } else {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
          let error = new Error(response.statusText);
          logout();
        }
        return response.text();
      })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.loginFailedTitle,
          message: enMsg.networkFailedError,
          type: "danger",
        };
        logout();
        return response;
      });
  } catch (error) {
    logout();
    showNotification = {
      title: enMsg.loginFailedTitle,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }

  // .then(handleResponse)
  // .then(userDataResp => {
  //   // login successful if there's a user in the response
  //   if (userDataResp) {
  //     // store user details and basic auth credentials in local storage
  //     // to keep user logged in between page refreshes
  //     let userData = window.btoa(username + ":" + password);
  //     user.authdata = window.btoa(userData);
  //     localStorage.setItem("user", JSON.stringify(user));

  //     let userData = window.btoa(data.username + ":" + data.password);
  //     // userData = window.btoa(userData);
  //     let user = {
  //       authdata: window.btoa(userData),
  //       accessToken: accessToken
  //     };
  //   }
  //   return user;
  // });
}

async function logout(data) {
  // return;
  // remove user from local storage to log user out

  let keysToRemove = [
    "user",
    "userDetail",
    "clientAuthToken",
    "tokenTimeStamp",
    "tokenTimeStamp",
    "clientAuthToken",
    "selectedReferral",
    DATA_LOADING,
    REDUX_STATE_DATA,
    USER_INFO,
    PATIENT_LIST,
    SELECTED_PATIENT_LIST,
    DP_DASHBOARD_FILTERS,
    PCC_AUTH_DATA,
  ];

  let isUser = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  if (isUser) {
    console.log("UserFound............");
    let isPccLogin = JSON.parse(localStorage.getItem(PCC_AUTH_DATA))
      ? JSON.parse(localStorage.getItem(PCC_AUTH_DATA))
      : null;
    console.log("isPCCLogin....", isPccLogin);
    if (isPccLogin !== null) {
      console.log("If Part Revoke Invoked");
      await revokePcc();
      await appLogout();
    } else {
      console.log("Logout Api Else Part");
      const logoutHeader = generalAuthTokenHeader();
      appLogout();
    }
  }

  // setTimeout(function() {
  //   window.location.reload(true);
  // }, 2000);
  // localStorage.clear();
}

async function revokePcc() {
  let isPccLogin = JSON.parse(localStorage.getItem(PCC_AUTH_DATA))
    ? JSON.parse(localStorage.getItem(PCC_AUTH_DATA))
    : null;
  const data = {
    accessToken: isPccLogin.accessTokan,
  };

  const logoutHeader = generalAuthTokenHeader();
  try {
    const response = await fetch(`${apiPath.revokeApi}`, {
      method: "POST",
      headers: logoutHeader,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Revoke Response-------->", response);
        }
      })
      .catch((error) => {
        console.log("revoke error", error);
      });
  } catch (error) {
    console.log("logout error", error);
  }
}

async function appLogout() {
  let keysToRemove = [
    "user",
    "userDetail",
    "clientAuthToken",
    "tokenTimeStamp",
    "tokenTimeStamp",
    "clientAuthToken",
    "selectedReferral",
    DATA_LOADING,
    REDUX_STATE_DATA,
    USER_INFO,
    PATIENT_LIST,
    SELECTED_PATIENT_LIST,
    DP_DASHBOARD_FILTERS,
    PCC_AUTH_DATA,
  ];
  const logoutHeader = generalAuthTokenHeader();
  try {
    const response = await fetch(`${apiPath.logoutApi}`, {
      method: "POST",
      headers: logoutHeader,
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
          localStorage.clear();
        }
      })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log("logout error", error);
      });
  } catch (error) {
    console.log("logout error", error);
  }
}
async function fetchProfile() {
  let showNotification = {};
  let apiResponse = [];
  try {
    const response = await fetch(apiPath.profileAccount, {
      method: "GET",
      headers: providerGeneralAuthTokenHeader(),
    })
      .then((response) => {
        if (
          response.status === SECURITY_ERROR ||
          response.status === FORBIDDEN_STATUS
        ) {
          showNotification = {
            title: enMsg.sessionExpireTitle,
            message: enMsg.sessionExpire,
            type: "warning",
          };
          setTimeout(function() {
            logout();
            window.location.reload(true);
          }, 1000);
          return Promise.reject("");
        } else if (response.status === INVALID_DATA_POST) {
          showNotification = {
            title: enMsg.profileFetchErrorTitle,
            message: response.statusText,
            type: "danger",
          };
        } else if (response.ok) {
          return response.json();
        } else {
          showNotification = {
            title: enMsg.profileFetchErrorTitle,
            message: response.statusText,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.errorCode === SECURITY_ERROR) {
          setTimeout(function() {
            logout();
            window.location.reload(true);
          }, 2000);
          return Promise.reject("");
        }
        apiResponse = data;
        return apiResponse;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function fetchBannerText() {
  let showNotification = {};
  let apiResponse = [];
  try {
    const response = await fetch(apiPath.bannerInfo, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        apiResponse = data;
        return apiResponse;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function fetchFilesBlob(apiUrl, fileName) {
  let showNotification = {};
  let apiResponse = false;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalOctetAuthTokenHeader(),
    })
      .then((response) => {
        if (
          response.status === SECURITY_ERROR ||
          response.status === FORBIDDEN_STATUS
        ) {
          showNotification = {
            title: enMsg.sessionExpireTitle,
            message: enMsg.sessionExpire,
            type: "warning",
          };
          setTimeout(function() {
            // logout();
            // window.location.reload(true);
          }, 1000);
          return Promise.reject("");
        } else if (response.status === INVALID_DATA_POST) {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        } else if (response.ok) {
          return response.blob();
        } else {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.detail) {
          showNotification = {
            title: enMsg.connectionFailed,
            message: data.detail,
            type: "danger",
          };
        } else {
          const blob = new Blob([data]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.target = "_blank";
          a.rel = "noopener noreferrer";
          // a.click();
          apiResponse = data;
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function fetchCommentSheetsData(apiUrl, fileName) {
  let showNotification = {};
  let apiResponse = false;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalOctetAuthTokenHeader(),
    })
      .then((response) => {
        if (
          response.status === SECURITY_ERROR ||
          response.status === FORBIDDEN_STATUS
        ) {
          showNotification = {
            title: enMsg.sessionExpireTitle,
            message: enMsg.sessionExpire,
            type: "warning",
          };
          setTimeout(function() {
            // logout();
            // window.location.reload(true);
          }, 1000);
          return Promise.reject("");
        } else if (response.status === INVALID_DATA_POST) {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        } else if (response.ok) {
          return response.blob();
        } else {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.detail) {
          showNotification = {
            title: enMsg.connectionFailed,
            message: data.detail,
            type: "danger",
          };
        } else {
          const blob = new Blob([data]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.target = "_blank";
          a.rel = "noopener noreferrer";
          a.click();
          apiResponse = true;
        }
        return true;
      })
      .catch((error) => {
        console.log(error);
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function fetchSheetsData(apiUrl, patientId) {
  let showNotification = {};
  let apiResponse = false;
  try {
    const response = await fetch(apiUrl + "/" + patientId, {
      method: "GET",
      headers: generalOctetAuthTokenHeader(),
    })
      .then((response) => {
        if (
          response.status === SECURITY_ERROR ||
          response.status === FORBIDDEN_STATUS
        ) {
          showNotification = {
            title: enMsg.sessionExpireTitle,
            message: enMsg.sessionExpire,
            type: "warning",
          };
          setTimeout(function() {
            // logout();
            // window.location.reload(true);
          }, 1000);
          return Promise.reject("");
        } else if (response.status === INVALID_DATA_POST) {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        } else if (response.ok) {
          return response.blob();
        } else {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        let fileName = "pateintFiles_" + Date.now() + ".zip";
        const blob = new Blob([data]);
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        a.click();
        apiResponse = true;
        return true;
      })
      .catch((error) => {
        console.log(error);
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function fetchGlobalApis(apiUrl) {
  let showNotification = {};
  let apiResponse = [];
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (
          response.status === SECURITY_ERROR ||
          response.status === FORBIDDEN_STATUS
        ) {
          showNotification = {
            title: enMsg.sessionExpireTitle,
            message: enMsg.sessionExpire,
            type: "warning",
          };
          setTimeout(function() {
            // logout();
            // window.location.reload(true);
          }, 1000);
          return Promise.reject("");
        } else if (response.status === INVALID_DATA_POST) {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        } else if (response.ok) {
          return response.json();
        } else {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.errorCode === SECURITY_ERROR) {
          setTimeout(function() {
            logout();
            window.location.reload(true);
          }, 2000);
          return Promise.reject("");
        }
        apiResponse = data;
        return apiResponse;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function fetchGlobalApisProvider(apiUrl) {
  let showNotification = {};
  let apiResponse = [];
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: providerGeneralAuthTokenHeader(),
    })
      .then((response) => {
        if (
          response.status === SECURITY_ERROR ||
          response.status === FORBIDDEN_STATUS
        ) {
          showNotification = {
            title: enMsg.sessionExpireTitle,
            message: enMsg.sessionExpire,
            type: "warning",
          };
          setTimeout(function() {
            // logout();
            // window.location.reload(true);
          }, 1000);
          return Promise.reject("");
        } else if (response.status === INVALID_DATA_POST) {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        } else if (response.ok) {
          return response.json();
        } else {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.errorCode === SECURITY_ERROR) {
          setTimeout(function() {
            logout();
            window.location.reload(true);
          }, 2000);
          return Promise.reject("");
        }
        apiResponse = data;
        return apiResponse;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function fetchGlobalApisSilent(apiUrl) {
  let showNotification = {};
  let apiResponse = [];
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (
          response.status === SECURITY_ERROR ||
          response.status === FORBIDDEN_STATUS
        ) {
          showNotification = {
            title: enMsg.sessionExpireTitle,
            message: enMsg.sessionExpire,
            type: "warning",
          };
          setTimeout(function() {
            // logout();
            // window.location.reload(true);
          }, 1000);
          return Promise.reject("");
        } else if (response.status === INVALID_DATA_POST) {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        } else if (response.ok) {
          return response.json();
        } else {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.errorCode === SECURITY_ERROR) {
          setTimeout(function() {
            logout();
            window.location.reload(true);
          }, 2000);
          return Promise.reject("");
        }
        apiResponse = data;
        return apiResponse;
      })
      .catch((error) => {
        // showNotification = {
        //   title: enMsg.connectionFailed,
        //   message: enMsg.networkFailedError,
        //   type: "danger"
        // };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function fetchGlobalApisSilentProvider(apiUrl) {
  let showNotification = {};
  let apiResponse = [];
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: providerGeneralAuthTokenHeader(),
    })
      .then((response) => {
        if (
          response.status === SECURITY_ERROR ||
          response.status === FORBIDDEN_STATUS
        ) {
          showNotification = {
            title: enMsg.sessionExpireTitle,
            message: enMsg.sessionExpire,
            type: "warning",
          };
          setTimeout(function() {
            // logout();
            // window.location.reload(true);
          }, 1000);
          return Promise.reject("");
        } else if (response.status === INVALID_DATA_POST) {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        } else if (response.ok) {
          return response.json();
        } else {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.errorCode === SECURITY_ERROR) {
          setTimeout(function() {
            logout();
            window.location.reload(true);
          }, 2000);
          return Promise.reject("");
        }
        apiResponse = data;
        return apiResponse;
      })
      .catch((error) => {
        // showNotification = {
        //   title: enMsg.connectionFailed,
        //   message: enMsg.networkFailedError,
        //   type: "danger"
        // };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}

function updateUserInfo(firstName, lastName, imageRef, ...jsonResponse) {
  if (jsonResponse[0]) {
    jsonResponse = jsonResponse[0];
  }
  jsonResponse = Object.assign(
    { firstName: firstName, lastName: lastName, imageRef: imageRef },
    jsonResponse
  );
  // localStorage.setItem("userDetail", JSON.stringify(jsonResponse));
  return jsonResponse;
}
async function refreshClientAdNetwork() {
  let user = JSON.parse(localStorage.getItem("user"));
  let clientAuthToken = localStorage.getItem("clientAuthToken");
  let tokenTimeStamp = localStorage.getItem("tokenTimeStamp");
  let currentTimeStamp = new Date().getTime();

  let uniqueId = localStorage.getItem("uniqueId");

  if (
    !uniqueId ||
    uniqueId == "" ||
    uniqueId == null ||
    uniqueId == undefined
  ) {
    uniqueId = uuidv4();
    localStorage.setItem("uniqueId", uniqueId);
  }

  // if (
  //   user && user.accessToken && clientAuthToken &&
  //   currentTimeStamp - tokenTimeStamp < CLIENT_TOKEN_LIFETIME
  // ) {
  //   return user;
  // }
  let showNotification = {};
  try {
    // for latter use - do not remote this code
    // apiPath = apiPath.replace(ADNETWORK_ID_SLUG, this.state.selectedAd);
    // apiPath = apiPath.replace(CAMPAIGN_ID_SLUG, this.state.campaignId);
    const data = {
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
      grantType: GRAND_TYPE,
      scope: null,
      deviceUid: uniqueId,
    };

    const response = await fetch(apiPath.refreshClientToken, {
      method: "PUT",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json"
      // },
      headers: reauthorizeTokenHeader(),
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (
          response.status === FORBIDDEN_STATUS ||
          response.status === INVALID_DATA_POST
        ) {
          showNotification = {
            title: enMsg.failedTitle,
            message: enMsg.invalidData400,
            type: "danger",
          };
          logout();
        } else if (response.status === NO_DATA_FOUND) {
          showNotification = {
            title: enMsg.failedTitle,
            message: enMsg.noDataFound,
            type: "danger",
          };
          logout();
        } else if (response.ok) {
          let newAccessToken = response.headers.get("Authorization");
          if (newAccessToken) {
            let tokenTimeStamp = new Date().getTime();
            localStorage.setItem("clientAuthToken", newAccessToken);
            // user.accessToken = newAccessToken
            // localStorage.setItem("user", JSON.stringify(user));
            // localStorage.setItem("tokenTimeStamp", tokenTimeStamp);
          }
          return response.text();
        } else {
          showNotification = {
            title: enMsg.failedTitle,
            message: enMsg.invalidData400,
            type: "danger",
          };
          logout();
        }
      })
      .then((response) => {
        return user;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
        logout();
      });

    // throw new Error(error);
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return user;
}
const createNotification = (showNotification) => {
  if (
    showNotification !== undefined &&
    showNotification.title !== undefined &&
    showNotification.message !== undefined &&
    showNotification.type !== undefined
  ) {
    let notificationID = store.removeNotification();
    let notifiaciton = {
      title: showNotification.title,
      message: showNotification.message,
      type: showNotification.type,
    };
    switch (notifiaciton.type) {
      case "info":
        NotificationManager.info(
          notifiaciton.message,
          notifiaciton.title,
          5000
        );
        break;
      case "success":
        NotificationManager.success(
          notifiaciton.message,
          notifiaciton.title,
          5000
        );
        break;
      case "warning":
        NotificationManager.warning(
          notifiaciton.message,
          notifiaciton.title,
          5000
        );
        break;
      case "danger":
        NotificationManager.error(
          notifiaciton.message,
          notifiaciton.title,
          5000
        );
        break;
    }
  }
  return;
};

function showNotification(showNotification) {
  if (
    showNotification !== undefined &&
    showNotification.title !== undefined &&
    showNotification.message !== undefined &&
    showNotification.type !== undefined
  ) {
    let notificationID = store.removeNotification();
    let notifiaciton = {
      title: showNotification.title,
      message: showNotification.message,
      type: showNotification.type,
    };
    switch (notifiaciton.type) {
      case "info":
        NotificationManager.info(
          notifiaciton.message,
          notifiaciton.title,
          5000
        );
        break;
      case "success":
        NotificationManager.success(
          notifiaciton.message,
          notifiaciton.title,
          5000
        );
        break;
      case "warning":
        NotificationManager.warning(
          notifiaciton.message,
          notifiaciton.title,
          5000
        );
        break;
      case "danger":
        NotificationManager.error(
          notifiaciton.message,
          notifiaciton.title,
          5000
        );
        break;
      case "quickInfoAlert":
        NotificationManager.info(
          notifiaciton.message,
          notifiaciton.title,
          5000
        );
        break;
      case "successCallBack":
        NotificationManager.error(
          notifiaciton.message,
          notifiaciton.title,
          5000,
          () => {
            window.history.back();
          }
        );
        break;
      case "dangerLong":
        NotificationManager.error(
          notifiaciton.message,
          notifiaciton.title,
          8000
        );
        break;
      case "successLong":
        NotificationManager.success(
          notifiaciton.message,
          notifiaciton.title,
          8000
        );
        break;
      case "infoLong":
        NotificationManager.info(
          notifiaciton.message,
          notifiaciton.title,
          8000
        );
        break;
    }
  }
  return;
}

async function getStateList() {
  let showNotification = {};
  let apiResponse = [];
  try {
    const response = await fetch(apiPath.getStateList, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        apiResponse = data;
        return apiResponse;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function getProviderDataUsage(providerId) {
  let showNotification = {};
  let apiResponse = [];
  try {
    const response = await fetch(apiPath.providerDataUse + "/" + providerId, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        apiResponse = data;
        return apiResponse;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
async function fetchsReferralReportData(apiUrl) {
  let showNotification = {};
  let apiResponse = false;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalOctetAuthTokenHeader(),
    })
      .then((response) => {
        if (
          response.status === SECURITY_ERROR ||
          response.status === FORBIDDEN_STATUS
        ) {
          showNotification = {
            title: enMsg.sessionExpireTitle,
            message: enMsg.sessionExpire,
            type: "warning",
          };
          setTimeout(function() {
            // logout();
            // window.location.reload(true);
          }, 1000);
          return Promise.reject("");
        } else if (response.status === INVALID_DATA_POST) {
          console.log("xcxc");
          showNotification = {
            title: enMsg.ReferralReport,
            message: "Data Not Available",
            type: "warning",
          };
          return response.json();
        } else if (response.ok) {
          return response.blob();
        } else {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.errorKey) {
          if (data.status === INVALID_DATA_POST) {
            showNotification = {};
            showNotification = {
              title: enMsg.ReferralReport,
              message: "Data Not Available",
              type: "warning",
            };
          } else {
            showNotification = {
              title: enMsg.connectionFailed,
              message: data.title,
              type: "danger",
            };
          }
        } else {
          const fileName = "admin_referral_report_" + Date.now() + ".xlsx";
          const blob = new Blob([data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          link.click();
          apiResponse = true;
        }
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      });
  } catch (error) {
    showNotification = {
      title: enMsg.connectionFailed,
      message: enMsg.networkFailedError,
      type: "danger",
    };
  }
  createNotification(showNotification);
  return apiResponse;
}
window.addEventListener("itemInserted", function(e) {
  document.querySelector(".storage-observation").textContent = e.key;
  document.querySelector(".storage-observation").textContent += e.oldValue;
  document.querySelector(".storage-observation").textContent += e.newValue;
  document.querySelector(".storage-observation").textContent += e.url;
  document.querySelector(".storage-observation").textContent += JSON.stringify(
    e.storageArea
  );
});
