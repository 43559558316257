import React from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
} from "@material-ui/core";
import { FormErrors } from "../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import CircularProgress from "@material-ui/core/CircularProgress";

import { basePath, dpBaseRoutes } from "base-routes";
import { FormGroup, FormControl, InputLabel } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ProviderSection from "views/FindProvider";
import DialogAction from "components/DialogActions/DialogAction";
import backArrow from "assets/img/icons/arrow-back.svg";
import notesArrow from "assets/img/icons/notes-arrow.svg";
import { formatDateToLocalFormat } from "__helpers/util";
import PatientForm from "views/dpViews/PatientDashboard/PatientForm";
import { PATIENT_LIST, MULTIPLE_PATIENT_LIST } from "__helpers/constants";
import Sidebar from "react-sidebar";
import PatientCriteria from "views/dpViews/SinglePatientDetail/PatientCriteria";
import { findProviderService } from "__helpers/util";
import Scrollbars from "react-custom-scrollbars";
import BundlePatientList from "./patientList";
import {
  SELECTED_PATIENT_LIST,
  EMAIL_REGEX,
  PRIORITY_LIST_OBJ,
} from "__helpers/constants";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Downloader from "js-file-downloader";
import ConfirmModal from "components/Modal/modal";
import { downloadMedia } from "__helpers/util";
import moment from "moment-timezone";
import PatientTasks from "views/dpViews/SinglePatientDetail/PatientTasks";
import { TASK_STATUS_OBJ } from "__helpers/constants";
import { daysBetweenTwoDate } from "__helpers/util";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const mapStateToProps = (state) => ({
  patientStatusList: state.patientStatusList,
  insuranceList: state.insuranceList,
  categoryListRedux: state.categoryList,
});
moment.tz.setDefault("UTC");
// moment.tz.setDefault('America/Toronto');
const tooltipStyles = {
  tooltip: {
    width: "auto !important",
    // height: "36px",
    borderRadius: "18px",
    boxShadow: "0 20px 80px 0",
  },
};
const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

class BundlePatientIndex extends React.Component {
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    let patientList = JSON.parse(sessionStorage.getItem(PATIENT_LIST));
    let selectedPatients = JSON.parse(
      sessionStorage.getItem(MULTIPLE_PATIENT_LIST)
    );
    let patientId = JSON.parse(localStorage.getItem(SELECTED_PATIENT_LIST));
    this.dateUtility = new DateFnsUtils();
    this.state = {
      loading: false,
      spinner: spinner,
      bundleInfo: [],
      multiPatientMode: false,
      multiSelectedPatients: selectedPatients ? selectedPatients : [],
      patientItemList: patientList ? patientList : [],
      categoryId: "",
      categoryName: "",
      selectedPatientId: patientId ? patientId : null,
    };
    this.fetchBundleInfo = this.fetchBundleInfo.bind(this);
    this.selectPatient = this.selectPatient.bind(this);
  }
  componentDidMount() {
    const url = new URLSearchParams(this.props.location.search);
    const providerId = atob(atob(url.get("pid")));
    const categoryId = atob(atob(url.get("cid")));
    const multiPatientMode = atob(atob(url.get("mode")));
    this.setState({
      categoryId: categoryId,
      multiPatientMode: multiPatientMode == "true" ? true : false,
    });
    this.props.categoryListRedux.map((category) => {
      if (category.catagoryId == categoryId) {
        this.setState({
          categoryName: category.categoryValue,
        });
      }
    });
    this.fetchBundleInfo(providerId, categoryId);
  }
  async fetchBundleInfo(providerId, categoryId) {
    try {
      let showNotification = {};
      // let apiUrl = apiPath.bundleCreate + "?categoryId=1&providerId=1104";
      let apiUrl =
        apiPath.bundleCreate +
        "?categoryId=" +
        categoryId +
        "&providerId=" +
        providerId;
      this.setState({
        loading: true,
      });
      if (this.state.reloadData) {
        this.setState({
          patientList: [],
          patientCategoryList: [],
          reloadData: false,
        });
      }
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: generalAuthTokenHeader(),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server2",
              type: "danger",
            };
          } else if (response.ok) {
          } else if (response.status === 401) {
            userService.logout();
            window.location.reload(true);
          } else {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server1",
              type: "danger",
            };
          }
          return response.json();
        })
        .then((data) => {
          if (data != "") {
            this.setState({
              bundleInfo: data,
              loading: false,
            });
          }
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: "Patient Detail",
            message: "Bad response from server3",
            type: "danger",
          };
          this.setState({ loading: false });
          return response;
        });
      userService.showNotification(showNotification);
    } catch (error) {
      this.setState({ loading: true });
    }
  }
  selectPatient(selectedPatientId) {
    this.setState({
      selectedPatientId: selectedPatientId,
    });
    localStorage.setItem(
      SELECTED_PATIENT_LIST,
      JSON.stringify(selectedPatientId)
    );

    window.location.href =
      basePath +
      dpBaseRoutes.singlePatientInfo.path +
      "?key=" +
      this.state.categoryId +
      "&mode=" +
      btoa(btoa(this.state.multiPatientMode));
  }
  setPatientData = () => {
    const url = new URLSearchParams(this.props.location.search);
    const selectCategoryId = url.get("key");
    this.setState({
      selectCategoryId: selectCategoryId,
    });
    let selectCategoryIndex = 0;
    this.state.patientList.patientCatagoryMapping
      .sort((a, b) => (a.categoryId > b.categoryId ? 1 : -1))
      .map((category, index) => {
        if (category.categoryId == selectCategoryId) {
          selectCategoryIndex = index;
        }
      });
    let estDate = null;
    this.setState({
      selectCategoryIndex: selectCategoryIndex,
    });
    if (this.state.patientList.formattedEstimatedDischargeDate) {
      estDate = this.dateUtility.format(
        new Date(this.state.patientList.formattedEstimatedDischargeDate),
        "MM/dd/yyyy"
      );
    }
    let dobDate = null;
    if (this.state.patientList.formattedPatientDob) {
      dobDate = this.dateUtility.format(
        new Date(this.state.patientList.formattedPatientDob),
        "MM/dd/yyyy"
      );
    }
    this.setState({
      estimatedDischargeDate: estDate,
      patientDOB: dobDate,
    });
  };
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  render() {
    return (
      <div id="single-patient-info" className="find-provider-tabs">
        <div className="loaderProgressFixed">
          {this.state.loading ? <LinearProgress color="primary" /> : ""}
        </div>
        <div className="ProviderFind">
          <Link
            to={`${basePath}${dpBaseRoutes.singlePatientInfo.path}?key=${
              this.state.categoryId
            }&mode=${btoa(btoa(this.state.multiPatientMode))}`}
            color="textSecondary"
            className="link-a"
            underline="none"
          >
            <h2 className="titlename">
              <img src={backArrow} /> Patient Demographics
            </h2>
          </Link>
          <Box
            display="flex"
            flexDirection="row"
            width={1}
            mt={1.5}
            className="provider-find-page siglePatientPageMain"
          >
            <Box className="left-list">
              <div className="left-title-info">
                {this.state.multiPatientMode
                  ? this.state.multiSelectedPatients.length +
                    " Patient Selected"
                  : "1 Patient Selected"}
              </div>
              <div className="bundle-header">
                Bundle Creation <br></br>
                <span>{this.state.bundleInfo.providerName}</span>
              </div>
              <Scrollbars
                className="Scrollbars-bundle-patient-info-cover"
                style={{ height: "calc(100vh - 271px)" }}
                universal={true}
                autoHide={false}
              >
                <Box
                  width={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className="patient-info-cover"
                >
                  {typeof this.state.patientItemList === "object" &&
                  this.state.patientItemList.length
                    ? this.state.multiPatientMode
                      ? this.state.patientItemList.map((pList, key) => {
                          let eastimatedDischargeDate = null;
                          let patientDob = null;
                          eastimatedDischargeDate = pList.eastimatedDischargeDate
                            ? moment
                                .utc(
                                  pList.eastimatedDischargeDate,
                                  "YYYY-MM-DD"
                                )
                                .format("MM/DD/YYYY")
                            : "N/A";
                          let localActualEstMoment = pList.actualDischargeDate
                            ? moment
                                .utc(pList.actualDischargeDate)
                                .format("MM/DD/YYYY")
                            : null;
                          let patientDischargedWarning =
                            localActualEstMoment &&
                            daysBetweenTwoDate(
                              localActualEstMoment,
                              new Date()
                            ) >= 30
                              ? true
                              : false;
                          if (pList.patientDob) {
                            patientDob = moment
                              .utc(pList.patientDob)
                              .format("MM/DD/YYYY");
                          }
                          if (
                            this.state.multiSelectedPatients.includes(
                              pList.patientId
                            )
                          ) {
                            return (
                              <Box
                                key={key}
                                className={`patient-info list-item-content selectedlist`}
                              >
                                <div
                                  className={`${
                                    pList.notificationCount !== 0
                                      ? "task-count toshift"
                                      : "task-count"
                                  }`}
                                >
                                  {pList.patientTask && pList.patientTask.length
                                    ? pList.patientTask.length
                                    : ""}
                                </div>
                                <div className="notification-count">
                                  {pList.notificationCount
                                    ? pList.notificationCount
                                    : ""}
                                </div>
                                <div className="name">
                                  {pList.lastName}, {pList.firstName}{" "}
                                  {pList.middleName}
                                </div>
                                <div className="DC">DOB: {patientDob}</div>
                                <div className="DC">
                                  DC:{" "}
                                  {localActualEstMoment
                                    ? localActualEstMoment
                                    : eastimatedDischargeDate}
                                  {patientDischargedWarning ? (
                                    <CustomTooltip title="This patient is discharged more than 30 days ago.">
                                      <WarningIcon />
                                    </CustomTooltip>
                                  ) : null}
                                </div>
                                <div
                                  className={`status ${
                                    pList.patientStatus &&
                                    pList.patientStatus.patientStatusValue
                                      ? pList.patientStatus.patientStatusValue
                                      : "noStatus"
                                  }`}
                                >
                                  {pList.patientStatus.patientStatusValue}
                                </div>
                              </Box>
                            );
                          }
                        })
                      : this.state.patientItemList.map((pList, key) => {
                          let eastimatedDischargeDate = null;
                          let patientDob = null;
                          let localActualEstMoment = pList.actualDischargeDate
                            ? moment
                                .utc(pList.actualDischargeDate)
                                .format("MM/DD/YYYY")
                            : null;
                          let patientDischargedWarning =
                            localActualEstMoment &&
                            daysBetweenTwoDate(
                              localActualEstMoment,
                              new Date()
                            ) >= 30
                              ? true
                              : false;
                          eastimatedDischargeDate = pList.eastimatedDischargeDate
                            ? moment
                                .utc(
                                  pList.eastimatedDischargeDate,
                                  "YYYY-MM-DD"
                                )
                                .format("MM/DD/YYYY")
                            : "N/A";
                          if (pList.patientDob) {
                            patientDob = moment
                              .utc(pList.patientDob)
                              .format("MM/DD/YYYY");
                          }
                          return (
                            <Box
                              key={key}
                              className={`patient-info list-item-content ${
                                this.state.selectedPatientId == pList.patientId
                                  ? "selectedlist"
                                  : ""
                              }`}
                              onClick={() =>
                                this.selectPatient(pList.patientId)
                              }
                            >
                              <div
                                className={`${
                                  pList.notificationCount !== 0
                                    ? "task-count toshift"
                                    : "task-count"
                                }`}
                              >
                                {pList.patientTask && pList.patientTask.length
                                  ? pList.patientTask.length
                                  : ""}
                              </div>
                              <div className="notification-count">
                                {pList.notificationCount
                                  ? pList.notificationCount
                                  : ""}
                              </div>
                              <div className="name">
                                {pList.lastName}, {pList.firstName}{" "}
                                {pList.middleName}
                              </div>
                              <div className="DC">DOB: {patientDob}</div>
                              <div className="DC">
                                DC:{" "}
                                {localActualEstMoment
                                  ? localActualEstMoment
                                  : eastimatedDischargeDate}
                                {patientDischargedWarning ? (
                                  <CustomTooltip title="This patient is discharged more than 30 days ago.">
                                    <WarningIcon />
                                  </CustomTooltip>
                                ) : null}
                              </div>
                              <div
                                className={`status ${
                                  pList.patientStatus &&
                                  pList.patientStatus.patientStatusValue
                                    ? pList.patientStatus.patientStatusValue
                                    : "noStatus"
                                }`}
                              >
                                {pList.patientStatus.patientStatusValue}
                              </div>
                            </Box>
                          );
                        })
                    : "Patient Not Found"}
                </Box>
              </Scrollbars>
            </Box>
            <Box className="right-details-tabpanel rightTabPanelMain">
              <div className="details-section details-section-bundle">
                <div className="name-status-tag">
                  <div className="name">
                    {this.state.bundleInfo.providerName}
                  </div>
                </div>

                <Box
                  display="flex"
                  flexDirection="row"
                  width={1}
                  mt={2}
                  mb={1}
                  className={"details-all-cover"}
                >
                  <Box
                    width="100%"
                    className={"section details-cover"}
                    display="flex"
                    flexDirection="column"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      width={1}
                      mt={1}
                      className="details-info details-info-bundle"
                    >
                      <Box
                        pr={2}
                        display="flex"
                        flexDirection="row"
                        className="bundle-address"
                      >
                        <div className="svgi">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="20"
                            viewBox="0 0 14 20"
                          >
                            <path
                              id="map_marker"
                              data-name="map marker"
                              d="M12,11.5A2.5,2.5,0,1,1,14.5,9,2.5,2.5,0,0,1,12,11.5ZM12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Z"
                              transform="translate(-4.999 -1.998)"
                              fill="#01a7a6"
                            />
                          </svg>
                        </div>
                        &nbsp;&nbsp;
                        <div>
                          <div className="info-label">Address</div>
                          <div className="info-value mb-0">
                            {this.state.bundleInfo.organizationEntity &&
                            this.state.bundleInfo.organizationEntity[0] &&
                            this.state.bundleInfo.organizationEntity[0]
                              .addressStreet ? (
                              // this.state.bundleInfo.organizationEntity[0].addressStreet
                              <div>
                                <p>
                                  {this.state.bundleInfo.organizationEntity &&
                                  this.state.bundleInfo.organizationEntity
                                    .length &&
                                  this.state.bundleInfo.organizationEntity[0]
                                    .addressStreet
                                    ? this.state.bundleInfo
                                        .organizationEntity[0].addressStreet
                                    : null}
                                </p>
                                <p>
                                  {this.state.bundleInfo.organizationEntity &&
                                  this.state.bundleInfo.organizationEntity
                                    .length &&
                                  this.state.bundleInfo.organizationEntity[0]
                                    .addressSuite
                                    ? this.state.bundleInfo
                                        .organizationEntity[0].addressSuite
                                    : null}
                                </p>
                                <p>
                                  {this.state.bundleInfo.organizationEntity &&
                                  this.state.bundleInfo.organizationEntity
                                    .length &&
                                  this.state.bundleInfo.organizationEntity[0]
                                    .city
                                    ? this.state.bundleInfo
                                        .organizationEntity[0].city
                                    : null}
                                </p>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </div>
                      </Box>

                      <Box pl={1} pr={1} display="flex" flexDirection="row">
                        <div className="svgi">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.001"
                            height="20"
                            viewBox="0 0 20.001 20"
                          >
                            <path
                              id="phone"
                              d="M7.025,11.659a16.742,16.742,0,0,0,7.321,7.317l2.444-2.448a1.114,1.114,0,0,1,1.129-.272,12.769,12.769,0,0,0,3.969.631A1.111,1.111,0,0,1,23,18v3.889A1.111,1.111,0,0,1,21.889,23,18.89,18.89,0,0,1,3,4.109,1.111,1.111,0,0,1,4.11,3H8A1.11,1.11,0,0,1,9.11,4.109a12.769,12.769,0,0,0,.631,3.969A1.111,1.111,0,0,1,9.47,9.206Z"
                              transform="translate(-2.999 -2.998)"
                              fill="#01a7a6"
                            />
                          </svg>
                        </div>
                        &nbsp;&nbsp;
                        <div>
                          <div className="info-label">Phone</div>
                          {this.state.bundleInfo != "" && (
                            <div className="info-value mb-0">
                              {this.state.bundleInfo.user.phoneNumber
                                ? "(" +
                                  this.state.bundleInfo.user.phoneNumber.match(
                                    /(\d{3})(\d{3})(\d{4})/
                                  )[1] +
                                  ") " +
                                  this.state.bundleInfo.user.phoneNumber.match(
                                    /(\d{3})(\d{3})(\d{4})/
                                  )[2] +
                                  "-" +
                                  this.state.bundleInfo.user.phoneNumber.match(
                                    /(\d{3})(\d{3})(\d{4})/
                                  )[3]
                                : "N/A"}
                            </div>
                          )}
                        </div>
                      </Box>
                      <Box pl={2} display="flex" flexDirection="row">
                        <div className="svgi">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="account_circle"
                              data-name="account circle"
                              d="M12,19.2a7.19,7.19,0,0,1-6-3.22c.026-1.987,4-3.08,6-3.08s5.972,1.093,6,3.08A7.19,7.19,0,0,1,12,19.2ZM12,5A3,3,0,1,1,9,8,3,3,0,0,1,12,5Zm0-3A10,10,0,1,0,22,12,10,10,0,0,0,12,2Z"
                              transform="translate(-1.999 -1.998)"
                              fill="#01a7a6"
                            />
                          </svg>
                        </div>
                        &nbsp;&nbsp;
                        <div>
                          <div className="info-label">Main Contact</div>
                          <div className="info-value mb-0">
                            {this.state.bundleInfo != "" &&
                              this.state.bundleInfo.user &&
                              this.state.bundleInfo.user.firstName +
                                " " +
                                this.state.bundleInfo.user.lastName}
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
              <Link
                to={`${basePath}${dpBaseRoutes.singlePatientInfo.path}?key=${
                  this.state.categoryId
                }&mode=${btoa(btoa(this.state.multiPatientMode))}`}
                color="textSecondary"
                className="link-a"
                underline="none"
              >
                <h2 className="titlename">
                  <img src={backArrow} /> {this.state.categoryName}
                </h2>
              </Link>

              <div className="ProviderSection">
                {this.state.bundleInfo != "" && this.state.categoryId && (
                  <BundlePatientList
                    bundlePatientList={this.state.bundleInfo.patientBundle}
                    providerInfo={this.state.bundleInfo.user}
                    categoryId={this.state.categoryId}
                    providerName={this.state.bundleInfo.providerName}
                  />
                )}
              </div>
            </Box>
          </Box>
        </div>
      </div>
    );
  }
}
export const BundlePatient = connect(mapStateToProps)(BundlePatientIndex);
export default BundlePatient;
