import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { FormErrors } from "../../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import PhoneNumber from "awesome-phonenumber";
import ConfirmModal from "components/Modal/modal";
import Icon from "@material-ui/core/Icon";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import {
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
  MAX_INPUT_LENGTH_RATE,
  MAX_INPUT_LENGTH_LONG,
  MAX_INPUT_LENGTH,
  PRIORITY_LIST,
  TASK_STATUS,
} from "__helpers/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  Slide,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";
import SelectStatus from "components/SelectStatus/selectStatus";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  addDaysToDate,
  subsDaysToDate,
  daysBetweenTwoDate,
} from "__helpers/util";
import Scrollbars from "react-custom-scrollbars";
import Typography from "views/lock/Typography/Typography";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Template Id",
  },
  {
    id: "templateName",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Template Name",
  },

  {
    id: "templateStatus",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Template Status",
  },
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
class TaskTemplate extends React.Component {
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    this.dateUtility = new DateFnsUtils();
    let initialDays = 30;
    let endDate = new Date();
    let startDate = subsDaysToDate(endDate, initialDays);
    let userinfo = JSON.parse(localStorage.getItem("_user_info"));
    this.addressInputRef = React.createRef();
    this.state = {
      action: "",
      loading: false,
      modalStatus: false,
      confirmModalStatus: false,
      templateName: "",
      taskName: "",
      taskPriority: "2",
      taskDescription: "",
      patientId: "",
      dischargePlannerId: userinfo.dischargePlanner.id,
      facilityId: userinfo.dischargePlanner.facility.facilityId,
      templateStatus: true,
      confirmModalMessage: "",

      dataId: "",
      tempaltes: [],
      // tasks: [],
      tasks: [
        {
          id: 1,
          taskName: "Task 1",
          taskPriority: "High",
          taskDescription: "Description 1",
        },
        {
          id: 2,
          taskName: "Task 2",
          taskPriority: "Medium",
          taskDescription: "Description 2",
        },
        // Add more tasks as needed
      ],
      scrollInterval: null, // Interval ID for scrolling
      scrollSpeed: 10, // Speed of scrolling in pixels per interval
      sortBy: "taskName",
      sortAsc: true,
      perPage: DEFAULT_PER_PAGE_RECORDS,
      total: "",
      currentPage: 0,
      orderBy: "id",
      spinner: spinner,
      isDelete: false,
      searchKeyword: "",
      apiPartner: "",
      formErrors: {
        taskName: "",
        templateName: "",
        taskDescription: "",
        taskPriority: "",
        tasks: [],
        tempaltes: [],
      },
      taskNameValid: false,
      templateNameValid: false,
      taskDescriptionValid: true,
      taskPriorityValid: true,
      tempaltes: [],
      taskUpdate: false,
      showHelpContent: true,
      
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.updateStatusTaskTemplate = this.updateStatusTaskTemplate.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.fetchTempaltes = this.fetchTempaltes.bind(this);
    this.confirmModalSubmit = this.confirmModalSubmit.bind(this);
    this.overallSaveTasks = this.overallSaveTasks.bind(this);
    this.search = this.search.bind(this);
    this.contentRef = React.createRef();
    this.toggleHelpContent = this.toggleHelpContent.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.patientDetail && nextProps.patientDetail.patientId) {
      this.setState({
        patientDetail: nextProps.patientDetail,
        patientTaskId: nextProps.patientTaskId,
        taskName: "",
        priority: "",
        description: "",
        changeStatus: "",
        notesStatus: "",
        gender: "",
        dueDate: null,
        tasks: [],
        tempaltes: [],
      });
      if (nextProps.patientTaskId) {
        this.setState({
          dueDate: null,
          taskNameValid: true,
          priorityValid: true,
          descriptionValid: true,
          changeStatusValid: true,
          notesStatusValid: true,
          dueDateValid: true,
        });
      } else {
        this.setState({
          taskNameValid: false,
          priorityValid: true,
          descriptionValid: true,
          changeStatusValid: true,
          notesStatusValid: true,
          dueDateValid: true,
        });
      }
      if (nextProps.openTaskModal) {
        if (nextProps.patientTaskId && nextProps.patientTaskId != "") {
          this.setState(
            {
              taskUpdate: true,
            },
            () => this.fetchTasks()
          );
        } else {
          this.setState({
            taskUpdate: false,
          });
        }
      }
    }
  }

  componentDidMount() {
    this.getApiPartner();
    this.fetchTempaltes();
    document.addEventListener("mousemove", this.handleMouseMove);
  }
  async getApiPartner() {
    let apiPartnerList = await userService.fetchGlobalApisSilent(
      apiPath.getApiPartnerList
    );
    if (apiPartnerList && apiPartnerList.length) {
      this.setState({
        partnerList: apiPartnerList,
      });
    }
  }
  async fetchTempaltes(searchKeyword) {
    this.state.spinner.removeAttribute("hidden", "true");
    let showNotification = {};
    let apiUrl = "";
    if (searchKeyword) {
      apiUrl =
        apiPath.getTaskTemplateByDP +
        "?facilityId=" +
        this.state.facilityId +
        "&page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "&search=" +
        searchKeyword;
    } else {
      apiUrl =
        apiPath.getTaskTemplateByDP +
        "?facilityId=" +
        this.state.facilityId +
        "&page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "";
    }
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Template",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          this.setState({
            total: response.headers.get("X-Total-Count"),
          });
        } else if (response.status === 401) {
          userService.logout();
          window.location.reload(true);
        } else {
          showNotification = {
            title: "Template",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetch Templates Data", data);
        this.setState({
          tempaltes: data,
        });
      })
      .catch((error) => {
        showNotification = {
          title: "Task Template",
          message: "Bad response from server",
          type: "danger",
        };
        return response;
      });

    userService.showNotification(showNotification);
    this.state.spinner.setAttribute("hidden", "true");
  }

  componentWillUnmount() {
    // Clean up event listener when component unmounts
    document.removeEventListener("mousemove", this.handleMouseMove);
    this.clearScrollInterval();
  }
  handleMouseMove = (event) => {
    const { scrollInterval } = this.state;
    const contentContainer = this.contentRef.current;

    if (!contentContainer) return;

    const rect = contentContainer.getBoundingClientRect();
    const mouseY = event.clientY;

    // Start scrolling up if mouse is near top edge
    if (mouseY < rect.top + 50) {
      this.startScrolling(-1);
    }
    // Start scrolling down if mouse is near bottom edge
    else if (mouseY > rect.bottom - 50) {
      this.startScrolling(1);
    } else {
      this.clearScrollInterval();
    }
  };

  startScrolling = (direction) => {
    if (!this.state.scrollInterval) {
      const intervalId = setInterval(() => {
        this.scrollContent(direction);
      }, 50); // Adjust scroll speed as needed
      this.setState({ scrollInterval: intervalId });
    }
  };

  scrollContent = (direction) => {
    const contentContainer = this.contentRef.current;

    if (contentContainer) {
      contentContainer.scrollTop += direction * this.state.scrollSpeed;
    }
  };

  clearScrollInterval = () => {
    if (this.state.scrollInterval) {
      clearInterval(this.state.scrollInterval);
      this.setState({ scrollInterval: null });
    }
  };

  modalOpen = async (templateId) => {
    if (!isNaN(templateId)) {
      try {
        const response = await fetch(
          apiPath.TaskTemplatesBulkEdit + "/" + templateId,
          {
            method: "GET",
            headers: generalAuthTokenHeader(),
          }
        );

        if (response.ok) {
          const tasks = await response.json();
          this.setState({
            templateName: tasks[0]?.templateName || "",
            tasks: tasks.map((task) => ({
              taskName: task.taskName,
              taskPriority: task.taskPriority,
              taskDescription: task.taskDescription,
              templateStatus: task.templateStatus,
              patientId: task.patientId,
              dischargePlannerId: task.dischargePlannerId,
              id: task.id,
              taskTemplateDetailsId: task.taskTemplateDetailsId,
              templateId: task.templateId,
            
            })),
            modalStatus: true,
            taskNameValid: true,
            templateNameValid: true,
            taskPriorityValid: true,
            taskDescriptionValid: true,
            dataId: templateId,
            action: "EDIT", // Set action to EDIT
          });
        } else {
          throw new Error("Failed to fetch tasks");
        }
      } catch (error) {
        console.log(error);
        console.error("Error fetching tasks:", error);
        this.setState({ modalStatus: false });
      }
    } else {
      this.setState({
        tasks:[],
        templateName: "",
        modalStatus: true,
        dataId: null,
        action: "CREATE",
      });
      this.addNewTask();
    }
  };

  modalClose = () => {
    this.setState({
      modalStatus: false,
      templateName: "",
      tasks: [],
      sortAsc: false,
    });
  };
  handleChangeInputInt = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  handleChangeInput = (event, index = null) => {
    const { name, value } = event.target;
    if (index !== null) {
      this.setState((prevState) => {
        const tasks = [...prevState.tasks];
        tasks[index][name] = value;
        return { tasks };
      });
    } else {
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    }
  };

  handleChangeBox() {
    let addAutoAdmit = this.state.addAutoAdmit;
    this.setState({
      addAutoAdmit: !addAutoAdmit,
    });
  }

  updateStatusTaskTemplate(data, isOnlyStatusChange) {
    let showNotification = {};
    this.setState({ loading: true });
    const response = fetch(apiPath.StatusUpdate, {
      method: "PUT",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
          let error = new Error(response.statusText);
        }
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        if (data.id) {
          this.fetchTempaltes(this.state.searchKeyword);
          showNotification = {
            title: "Success",
            message: isOnlyStatusChange
              ? "Status updated successfully"
              : "Template updated successfully",
            type: "success",
          };

          // userService.showNotification(showNotification);
          this.modalClose();
          this.setState({ loading: false });
          return true;
        }
        if (data.title) {
          showNotification = {
            title: "Template",
            message: data.title,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.loginFailedTitle,
          message: enMsg.inValidCredentials,
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      });
  }

  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;

    let taskNameValid = this.state.taskNameValid;
    let templateNameValid = this.state.templateNameValid;
    let taskPriorityValid = this.state.taskPriorityValid;
    let taskDescriptionValid = this.state.taskDescriptionValid;

    switch (fieldName) {
      case "taskName":
        taskNameValid = fieldValue.trim().length > 0;
        fieldValidationErrors.taskName = taskNameValid
          ? ""
          : enMsg.taskNameRequiredMsg;
        break;
      case "templateName":
        templateNameValid = fieldValue.trim().length > 0;
        fieldValidationErrors.taskName = templateNameValid
          ? ""
          : enMsg.taskNameRequiredMsg;
        break;
      case "taskPriority":
        taskPriorityValid = fieldValue == "" ? false : true;
        fieldValidationErrors.priority = taskPriorityValid
          ? ""
          : enMsg.priorityRequiredMsg;
        break;
      case "taskDescription":
        //descriptionValid = (fieldValue && fieldValue.trim().length > 0) || fieldValue == "" ? true : false;
        //fieldValidationErrors.description = descriptionValid ? '' : enMsg.descriptionRequiredMsg;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        taskNameValid: taskNameValid,
        templateNameValid: templateNameValid,
        taskPriorityValid: taskPriorityValid,
        taskDescriptionValid: taskDescriptionValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return (
      this.state.taskNameValid &&
      this.state.templateNameValid &&
      this.state.taskDescriptionValid &&
      this.state.taskPriorityValid
    );
  }
  confirmModalOpen = (message, dataId, isDelete) => {
    this.setState({
      dataId: dataId,
      confirmModalMessage: message,
      confirmModalStatus: true,
      isDelete: isDelete,
    });
  };
  confirmModalClose = () => {
    this.setState({
      confirmModalStatus: false,
    });
  };
  async confirmModalSubmit() {
    if (!this.state.isDelete) {
      Promise.all(
        this.state.tempaltes.map((template) => {
          if (template.id == this.state.dataId) {
            const data = {
              id: this.state.dataId,
              templateName: template.templateName,
              templateStatus: !template.templateStatus,
              patientId: template.patientId,
              dischargePlannerId: template.dischargePlannerId,
              facilityId: template.facilityId,
            };
            this.updateStatusTaskTemplate(data, true);
            this.confirmModalClose();
          }
        })
      );
    } else {
      this.setState({ loading: true });
      let showNotification = {};
      const data = {
        id: this.state.dataId,
      };
      const response = await fetch(
        apiPath.deleteTaskTemplate + "/" + this.state.id,
        {
          method: "DELETE",
          headers: generalAuthTokenHeader(),
          body: JSON.stringify(data),
          data: JSON.stringify(data),
        }
      ).then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Delete",
            message: "Something went wrong.Please try after sometime.",
            type: "danger",
          };
        } else if (response.status === 200) {
          showNotification = {
            title: "Deletion",
            message: "Template deleted successfully",
            type: "success",
          };
          this.fetchTempaltes();
        } else if (response.ok) {
          showNotification = {
            title: "Deletion",
            message: "Template deleted successfully",
            type: "success",
          };
          this.fetchTempaltes();
        } else {
          showNotification = {
            title: "Delete",
            message: "Something went wrong.Please try after sometime.",
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        this.fetchTempaltes();
      });
      this.setState({ loading: false });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        currentPage: newPage,
      },
      () => this.fetchTempaltes()
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        perPage: parseInt(event.target.value),
        currentPage: 0,
      },
      () => this.fetchTempaltes(this.state.searchKeyword)
    );
  };

  tableSortHandler = (property) => (event) => {
    this.setState(
      {
        orderBy: property,
        direction: !this.state.direction,
      },
      () => this.fetchTempaltes(this.state.searchKeyword)
    );
  };

  search(event) {
    let searchKeyword = event.target.value.trim();
    if (searchKeyword.length > 0) {
      this.setState({ currentPage: 0 }, () =>
        this.fetchTempaltes(searchKeyword)
      );
    } else {
      this.fetchTempaltes();
    }
    this.setState({ searchKeyword: searchKeyword });
    return true;
  }

  deleteTaskRow = (index) => {
    const updatedTasks = [...this.state.tasks];
    updatedTasks.splice(index, 1);
    this.setState({ tasks: updatedTasks });
  };

  deleteTask = async (index, taskTemplateDetailsId) => {
    if (!taskTemplateDetailsId) {
      const updatedTasks = [...this.state.tasks];
      updatedTasks.splice(index, 1);
      this.setState({ tasks: updatedTasks });
      return;
    }

    this.setState({ loading: true });

    try {
      const response = await fetch(
        `${apiPath.deleteTaskTemplateEdit}/${taskTemplateDetailsId}`,
        {
          method: "DELETE",
          headers: generalAuthTokenHeader(),
        }
      );
      let showNotification;
      if (response.status === 400) {
        showNotification = {
          title: "Delete",
          message: "Something went wrong. Please try again later.",
          type: "danger",
        };
      } else if (response.status === 200 || response.ok) {
        showNotification = {
          title: "Deletion",
          message: "Template deleted successfully",
          type: "success",
        };
        this.modalClose();
        const updatedTasks = [...this.state.tasks];
        updatedTasks.splice(index, 1);
        this.setState({ tasks: updatedTasks });
        this.modalClose();
        this.fetchTempaltes();
      } else {
        showNotification = {
          title: "Delete",
          message: "Something went wrong. Please try again later.",
          type: "danger",
        };
      }

      userService.showNotification(showNotification);
    } catch (error) {
      userService.showNotification({
        title: "Delete",
        message: "Something went wrong. Please try again later.",
        type: "danger",
      });
    } finally {
      this.setState({ loading: false });
      this.confirmModalClose();
    }
  };

  addNewTask = () => {
    const newTask = {
      taskName: "",
      taskPriority: "2", // Default priority
      taskDescription: "",
    };

    this.setState((prevState) => ({
      tasks: [...prevState.tasks, newTask],
    }));
  };

  editTask = (index) => {
    this.setState({
      action: "EDIT", // Set action to EDIT
    });
  };

  overallSaveTasks = async () => {
    const {
      tasks,
      taskName,
      taskPriority,
      taskDescription,
      dischargePlannerId,
      templateName,
      action,
      dataId,
      facilityId,
    } = this.state;

    try {
      // Validate mandatory fields for the template
      if (!templateName.trim()) {
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            templateName: !templateName.trim()
              ? "Template Name is required"
              : "",
          },
        });
        throw new Error("Please fill all mandatory fields.");
      }

      // Validate tasks array is not empty
      if (tasks.length === 0) {
        throw new Error("Please add at least one task.");
      }

      // Validate each task's mandatory fields
      let taskErrors = tasks.map((task, index) => {
        return {
          taskName: !task.taskName.trim() ? "Task Name is required" : "",
          taskPriority: !task.taskPriority ? "Priority is required" : "",
        };
      });

      let hasErrors = taskErrors.some(
        (error) => error.taskName || error.taskPriority
      );
      if (hasErrors) {
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            tasks: taskErrors,
          },
        });
        throw new Error("Please fill all mandatory fields in tasks.");
      }

      let taskData = tasks.map((task, index) => {
        return {
          taskTemplateDetailsId: task.taskTemplateDetailsId
            ? task.taskTemplateDetailsId
            : null,
          templateId: this.state.dataId,
          templateName: task.templateName || templateName,
          taskName: task.taskName,
          templateDetailStatus: true,
          dischargePlannerId,
          facilityId,
          changeStatus: null,
          statusDescription: "",
          taskPriority: task.taskPriority,
          taskDescription: task.taskDescription,
          action,
          sequenceNo: index +1, // Use existing sequence number
        };
      });

      // If it's a new task, add it to taskData separately
      if (!dataId && taskName && taskPriority && templateName) {
        const newTask = {
          taskTemplateDetailsId: null,
          templateId: this.state.dataId,
          templateName: templateName,
          taskName: taskName,
          templateDetailStatus: true,
          dischargePlannerId: dischargePlannerId,
          facilityId: facilityId,
          changeStatus: null,
          statusDescription: "",
          taskPriority: taskPriority,
          taskDescription: taskDescription,
          action: action,
          sequenceNo: tasks.length + 1, // New task gets the next sequence number
        };
        taskData.push(newTask);
      }

      console.log("Payload sent to server:", taskData);

      // Send taskData to the server
      const response = await fetch(apiPath.TaskTemplatesBulk, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(taskData),
      });

      if (response.status === 201) {
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        this.fetchTempaltes();
        this.modalClose();

        userService.showNotification({
          title: this.state.taskUpdate
            ? enMsg.taskUpdateTitle
            : enMsg.taskCreateTitle,
          message: this.state.taskUpdate
            ? enMsg.taskUpdateDes
            : enMsg.taskCreateDes,
          type: "success",
        });
        this.fetchTempaltes();
      } else {
        const errorData = await response.json(); // Parse error response data
        userService.showNotification({
          title: enMsg.taskFailedTitle,
          message: errorData.title || "Bad response from server",
          type: "danger",
        });
      }
    } catch (error) {
      console.error("Error updating tasks:", error.message);
      userService.showNotification({
        title: enMsg.taskFailedTitle,
        message: error.message || "Bad response from server",
        type: "danger",
      });
    } finally {
      this.confirmModalClose();
      this.setState({ loading: false });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { dataId, taskName, priority, description, dischargePlannerId } =
      this.state;

    try {
      if (dataId) {
        const data = {
          patientId: null,
          taskName,
          templateId: dataId,
          changeStatus: null,
          statusDescription: "",
          taskPriority: priority,
          taskDescription: description,
          dischargePlannerId,
        };
        await this.updateTemplate(data);
      } else {
        // Creating new template
        await this.createTemplate();
      }
    } catch (error) {
      console.error("Error handling submit:", error);
      let showNotification = {
        title: enMsg.taskFailedTitle,
        message: "Error handling submit",
        type: "danger",
      };
      userService.showNotification(showNotification);
      this.setState({ loading: false });
    }
  };

  async createTemplate() {
    let showNotification = {};
    const {
      tasks,
      dataId,
      facilityId,
      taskName,
      taskPriority,
      taskDescription,
      dischargePlannerId,
      templateName,
    } = this.state;
    const normalizedTemplateName = templateName.trim().toLowerCase();
    const isTemplateNameValid = await this.validateTemplateName(
      normalizedTemplateName
    );
    if (!isTemplateNameValid) {
      showNotification = {
        title: enMsg.taskFailedTitle,
        message: "Template name is already in use or invalid",
        type: "danger",
      };
      userService.showNotification(showNotification);
      return; // Exit function early if validation fails
    }
    const data = [
      {
        taskName,
        dataId,
        templateDetailStatus: true,
        taskTemplateDetailsId: this.state.taskTemplateDetailsId,
        dischargePlannerId,
        changeStatus: null,
        statusDescription: "",
        taskPriority,
        taskDescription,
        templateName,
        facilityId,
      },
    ];

    const response = await fetch(apiPath.TaskTemplatesBulk, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.taskFailedTitle,
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.status === 201) {
          showNotification = {
            title: this.state.taskUpdate
              ? enMsg.taskUpdateTitle
              : enMsg.taskCreateTitle,
            message: this.state.taskUpdate
              ? enMsg.taskUpdateDes
              : enMsg.taskCreateDes,
            type: "success",
          };
          this.props.updatePatient();
          this.props.updatePatientModalClose();
        } else {
          showNotification = {
            title: enMsg.taskFailedTitle,
            message: "Bad response from server",
            type: "danger",
          };
        }
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        if (data.status == 400) {
          showNotification = {
            title: enMsg.taskFailedTitle,
            message: "Bad response from server",
            type: "danger",
          };
        }
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.taskFailedTitle,
          message: "Bad response from server",
          type: "danger",
        };
      });
    userService.showNotification(showNotification);
    this.setState({ loading: false });
  }

  async updateTemplate() {
    let showNotification = {};
    const { tasks, dischargePlannerId, templateName } = this.state;
    try {
      // Check if tasks array is empty or not
      if (tasks.length === 0) {
        throw new Error("Tasks array is empty");
      }

      // Update sequence numbers based on the current order in the UI
      const updatedTasks = tasks.map((task, index) => ({
        ...task,
        sequenceNo: index + 1, // Update sequence number based on the current order
      }));

      // Construct data payload based on tasks array
      const data = updatedTasks.map((task) => ({
        taskName: task.taskName,
        templateId: this.state.dataId,
        templateDetailStatus: true,
        taskTemplateDetailsId: task.taskTemplateDetailsId,
        facilityId: this.state.facilityId,
        dischargePlannerId,
        changeStatus: null,
        statusDescription: "",
        taskPriority: task.taskPriority,
        taskDescription: task.taskDescription,
        templateName,
        action: "",
        sequenceNo: task.sequenceNo,
      }));

      // Log the data payload for debugging
      console.log("Task Data Payload:", JSON.stringify(data, null, 2));

      // Send updated task data to the server
      const response = await fetch(apiPath.TaskTemplatesBulkUpdate, {
        method: "PUT",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
      });

      console.log("Response Status:", response.status);

      if (response.ok) {
        // If the response is OK, parse the response data
        const responseData = await response.json();
        console.log("Response Data:", responseData); // Log the response data
        showNotification = {
          title: this.state.taskUpdate
            ? enMsg.taskUpdateTitle
            : enMsg.taskCreateTitle,
          message: this.state.taskUpdate
            ? enMsg.taskUpdateDes
            : enMsg.taskCreateDes,
          type: "success",
        };
        this.fetchTempaltes();
        this.modalClose();
      } else {
        // If the response is not OK, parse the error response data
        const errorData = await response.json();
        console.error("Error Response Data:", errorData); // Log the error data
        showNotification = {
          title: enMsg.taskFailedTitle,
          message: errorData.detail || "Bad response from server",
          type: "danger",
        };
      }
    } catch (error) {
      console.error("Error updating tasks:", error.message);
      showNotification = {
        title: enMsg.taskFailedTitle,
        message: error.message || "Bad response from server",
        type: "danger",
      };
    } finally {
      this.modalClose();
      this.fetchTempaltes();
      this.setState({ loading: false });
      userService.showNotification(showNotification);
    }
  }

  handleSort = (sortBy) => {
    const {
      tasks,
      sortBy: currentSortBy,
      sortAsc: currentSortAsc,
    } = this.state;

    // Determine the new sort direction
    const newSortAsc = sortBy === currentSortBy ? !currentSortAsc : true;

    // Sort tasks based on sortBy and sort direction
    const sortedTasks = [...tasks].sort((a, b) => {
      const valueA = a[sortBy].toLowerCase();
      const valueB = b[sortBy].toLowerCase();

      if (newSortAsc) {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    // Update state with sorted tasks and new sort parameters
    this.setState({
      tasks: sortedTasks,
      sortBy,
      sortAsc: newSortAsc,
    });
  };
  onDragStart = (event, taskIndex) => {
    event.dataTransfer.setData("text/plain", taskIndex.toString());
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDrop = (event, dropIndex) => {
    const draggedTaskIndex = parseInt(
      event.dataTransfer.getData("text/plain"),
      10
    );
    const tasks = [...this.state.tasks];
    const draggedTask = tasks[draggedTaskIndex];

    // Remove the dragged task from its original position
    tasks.splice(draggedTaskIndex, 1);

    // Insert the dragged task into the new position
    tasks.splice(dropIndex, 0, draggedTask);

    // Update sequence numbers based on the new order
    const updatedTasks = tasks.map((task, index) => {
      return {
        ...task,
        sequenceNo: index + 1, // Update sequence number based on new order (1-based index)
      };
    });

    // Update state with the new task order and sequence numbers
    this.setState({ tasks: updatedTasks });
  };

  toggleHelpContent() {
    this.setState((prevState) => ({
      showHelpContent: !prevState.showHelpContent,
    }));
  }

  render() {
    const { tasks, dataId, sortBy, sortAsc } = this.state;
    return (
      <div id="facility-index">
        <ConfirmModal
          title="Alert"
          message={this.state.confirmModalMessage}
          confirmModalStatus={this.state.confirmModalStatus}
          confirmModalClose={this.confirmModalClose}
          submit={this.confirmModalSubmit}
          loading={this.state.loading}
        />
        <Dialog
          open={this.state.modalStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            {this.state.dataId ? "Edit Template" : "Add Template"}
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.handleSubmit} noValidate>
            <DialogContent>
              <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Box style={{ width: '100%', paddingRight: '2rem' }}>
                      {this.state.showHelpContent && (
                    <div className="help-content" style={{ fontStyle: 'italic' }}>
                        <p>Create tasks for your task template.  To re-order tasks, simply drag and drop the task to the new position.</p>
                      </div>
                    )}
                <Box display="flex" justifyContent="flex-start" mb={1} mt={1}>
                  <Box width={1} pr={2}>
                    <TextField
                      InputLabelProps={{ className: "required-label" }}
                      name="templateName"
                      label="Template Name"
                      value={this.state.templateName}
                      onChange={(event) => this.handleChangeInput(event)}
                      // inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                    />
                    <FormErrors
                      show={!this.state.templateNameValid}
                      formErrors={this.state.formErrors}
                      fieldName="templateName"
                    />
                  </Box>
                  </Box>
                  </Box>
                  </Box>
            </DialogContent>

            {tasks &&
              tasks.map((task, index) => (
                <DialogContent
                  key={task.id}
                  draggable="true"
                  onDragStart={(event) => this.onDragStart(event, index)}
                  onDragOver={(event) => this.onDragOver(event)}
                  onDrop={(event) => this.onDrop(event, index)}
                  ref={this.contentRef}
                  style={{
                    border: "1px solid #ccc",
                    margin: "10px",
                    padding: "10px",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    mb={1}
                    mt={1}
                  ></Box>

                  <Box display="flex" justifyContent="flex-start" mb={1}>
                    <Box width={1 / 2} pr={2}>
                      <TextField
                        InputLabelProps={{ className: "required-label" }}
                        name="taskName"
                        label="Task Name"
                        value={task.taskName}
                        onChange={(event) =>
                          this.handleChangeInput(event, index)
                        }
                      />

                      <FormErrors
                        show={!this.state.taskNameValid}
                        formErrors={this.state.formErrors}
                        fieldName="taskName"
                      />
                    </Box>

                    {/* {sortBy === 'taskName'  && dataId && (
                  
              <TableSortLabel
                active={true} // Set TableSortLabel as active if sorting by taskName
                direction={sortAsc ? 'asc' : 'desc'}
                onClick={() => this.handleSort('taskName')}
              />)} */}
                    <Box width={1 / 2} pl={2}>
                      <FormControl>
                        <InputLabel className={"required-label"}>
                          Priority
                        </InputLabel>
                        <Select
                          id="taskPriority"
                          color="secondary"
                          name="taskPriority"
                          value={task.taskPriority}
                          onChange={(event) =>
                            this.handleChangeInput(event, index)
                          }
                        >
                          <MenuItem value={""}>Select</MenuItem>
                          {PRIORITY_LIST
                            // .sort((a, b) => a.insuranceId > b.insuranceId ? 1 : -1)
                            .map((pList, key) => {
                              return (
                                <MenuItem key={key} value={pList.id}>
                                  {pList.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormErrors
                          show={!this.state.taskPriorityValid}
                          formErrors={this.state.formErrors}
                          fieldName="priority"
                        />
                      </FormControl>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={1}>
                    <Box width={1 / 2} pr={2}>
                      <TextField
                        label="Description"
                        rows={4}
                        multiline={true}
                        fullWidth={true}
                        aria-label="validators"
                        name="taskDescription"
                        value={task.taskDescription}
                        onChange={(event) =>
                          this.handleChangeInput(event, index)
                        }
                        inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                        className="comment-TextField"
                      />
                      <FormErrors
                        show={!this.state.taskDescriptionValid}
                        formErrors={this.state.formErrors}
                        fieldName="description"
                      />
                    </Box>
                    {dataId && (
                      <Box width={1 / 3} pl={1}>
                        <DialogActions
                          className="modal-actions"
                          justify="center"
                        >
                          <Button
                            type="submit"
                            className="btn1"
                            disabled={
                              !this.validateForm() || this.state.loading
                            }
                          >
                            {this.state.loading && (
                              <CircularProgress
                                size={24}
                                className="buttonProgress"
                                color="secondary"
                              />
                            )}
                            {task.taskTemplateDetailsId ? "Update" : "Save"}
                          </Button>
                          <Box width={1 / 3} pl={1}>
                            <DialogActions
                              className="modal-actions"
                              justify="center"
                            >
                              <span
                                className="pemoveAllLink"
                                //onClick={() => this.deleteTask(index)}

                                onClick={() =>
                                  this.deleteTask(
                                    index,
                                    task.taskTemplateDetailsId
                                  )
                                }
                              >
                                <IconButton edge="end" aria-label="delete all">
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </DialogActions>
                          </Box>
                        </DialogActions>
                      </Box>
                    )}
                    {!dataId && index > 0 && (
                      <Box width={1 / 3} pl={1}>
                        <DialogActions
                          className="modal-actions"
                          justify="center"
                        >
                          <span
                            className="pemoveAllLink"
                            onClick={() => this.deleteTaskRow(index)}

                            //onClick={() => this.deleteTask(index, task.taskTemplateDetailsId)}
                          >
                            <IconButton edge="end" aria-label="delete all">
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </DialogActions>
                      </Box>
                    )}
                  </Box>
                </DialogContent>
              ))}

            <DialogActions className="modal-actions" justify="center">
              <Button
                onClick={this.addNewTask}
                className="btn1"
                color="secondary"
                style={{ marginRight: 8 }}
              >
                Add New Task
              </Button>
              <Button
                className="btn1"
                color="secondary"
                onClick={this.overallSaveTasks}
                style={{ marginLeft: 8 }}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <GridContainer>
          <Scrollbars
            //style={{ height: "600px" }}
            autoHide={false}
            universal={true}
            autoHeight={true}
            autoHeightMin={0}
            autoHeightMax={600}
          >
            <Card className={"advert-cover"}>
              <GridItem xs={12} sm={12} md={12}>
                <CardHeader
                  color="success"
                  className={"cusCardheader cusCardTitle"}
                >
                  <h4>Task Template</h4>
                </CardHeader>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div className="search-btn-cover">
                  <input
                    type="text"
                    title="Search by Template name"
                    placeholder="Search by Template name"
                    className="search-btn"
                    onChange={this.search}
                  />
                  <i className="material-icons">search</i>
                </div>
                <div className="button-cover">
                  <Button className="pointer btn1" onClick={this.modalOpen}>
                    Add New Template
                  </Button>
                </div>
              </GridItem>
              <div className={"custom-container"}>
                <div className="tableResponsive">
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={
                              headCell.disablePadding ? "none" : "default"
                            }
                          >
                            {headCell.sorting ? (
                              <TableSortLabel
                                active={this.state.orderBy == headCell.id}
                                direction={
                                  this.state.direction ? "asc" : "desc"
                                }
                                onClick={this.tableSortHandler(headCell.id)}
                              >
                                {headCell.label}
                              </TableSortLabel>
                            ) : (
                              headCell.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.tempaltes.length ? (
                        this.state.tempaltes.map((tempalte) => (
                          <TableRow key={tempalte.id}>
                            <TableCell>{tempalte.id}</TableCell>
                            <TableCell>{tempalte.templateName}</TableCell>
                            <TableCell>
                              <SwitchToggle
                                id={tempalte.id}
                                status={tempalte.templateStatus}
                                clickHandler={() =>
                                  this.confirmModalOpen(
                                    "Do you want to change the status?",
                                    tempalte.id,
                                    false
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <span
                                className="edit-action"
                                onClick={() => this.modalOpen(tempalte.id)}
                              >
                                <Icon
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                />
                              </span>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan="7">
                            <div className="not-found-text">
                              Templates not found
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={PER_PAGE_OPTIONS}
                  component="div"
                  count={parseInt(this.state.total)}
                  rowsPerPage={this.state.perPage}
                  page={this.state.currentPage}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </div>
            </Card>
          </Scrollbars>
        </GridContainer>
      </div>
    );
  }
}
export default TaskTemplate;
