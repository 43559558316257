import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  FormGroup,
  FormControl,
  InputLabel,
  IconButton,
  LinearProgress,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
  TableContainer,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  PATIENT_ORDERBY,
  DEFAULT_PER_PAGE_RECORDS,
  IMPORT_PATIENT_ORDERBY,
  IMPORT_PATIENT_FILTERBY,
  PER_PAGE_OPTIONS,
} from "__helpers/constants";
import { userService } from "_services/user.service";
import { apiPath } from "api";
import {
  generalAuthTokenHeader,
  generalMimeAuthTokenHeader,
} from "__helpers/auth-header";
import { formatDateToLocalFormat } from "__helpers/util";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment-timezone";
import baseRoutes from "base-routes";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const headCells = [
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Added",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Patient Name(DOB)",
  },
  {
    id: "dcDate",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "DC Date",
  },
  {
    id: "patientStatus",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Status",
  },
  // { id: 'address', numeric: false, disablePadding: false, sorting: true, label: 'Address' },
  {
    id: "admissionDate",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Date Admitted",
  },
];
const tooltipStyles = {
  tooltip: {
    width: "auto !important",
    // height: "36px",
    borderRadius: "18px",
    boxShadow: "0 20px 80px 0",
    zIndex: 9999,
  },
};
const CustomTooltip = withStyles(tooltipStyles)(Tooltip);
export default class ImportPatientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadMore: false,
      reduxLoadFlag: props.reduxLoadFlag,
      showModal: props.showModal,
      perPage: DEFAULT_PER_PAGE_RECORDS,
      perPageAssigned: DEFAULT_PER_PAGE_RECORDS,
      total: 0,
      totalAssigned: 0,
      totalPatientShowing: 0,
      totalPatientShowingAssigned: 0,
      currentPage: 0,
      currentPageAssigned: 0,
      sorting: "LAST_NAME_ASC",
      filter: "",
      loadingPatient: true,
      loadingPatientAssigned: true,
      patientSearchKeyword: "",
      patientList: [],
      assignedPatientList: [],
      selectionCount: 0,
      selectedPatientId: "",
      multiPatientMode: false,
      multiplePatientObj: [],
      multiplePatientList: [],
      removePatientList: [],
      selectRemovePatient: [],
      selectedPatientData: {},
      patientFilterList: props.patientFilterListRedux
        ? props.patientFilterListRedux
        : [],
    };
    this.fetchPatients = this.fetchPatients.bind(this);
    this.fetchAssignedPatients = this.fetchAssignedPatients.bind(this);
    this.search = this.search.bind(this);
    this.searchReturn = this.searchReturn.bind(this);
    this.loadMorePatient = this.loadMorePatient.bind(this);
    this.handlePatientSorting = this.handlePatientSorting.bind(this);
    this.assignPatients = this.assignPatients.bind(this);
    this.removePatients = this.removePatients.bind(this);
  }
  componentDidMount() {
    // this.fetchPatients();
  }
  async fetchPCCPatients() {
    let pccPstients = await userService.fetchGlobalApisSilent(
      apiPath.pccPatientsUpdate
    );
    if (pccPstients) {
      this.fetchPatients();
    }
  }
  async fetchPatients() {
    this.setState({
      loadingPatient: true,
    });
    let showNotification = {};
    let apiUrl = "";
    let searchKeyword = this.state.patientSearchKeyword;
    if (searchKeyword && searchKeyword.length > 0) {
      apiUrl =
        apiPath.importPatientList +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        (this.state.filter && this.state.filter != ""
          ? "&filterBy=" + this.state.filter
          : "") +
        "&orderBy=" +
        (this.state.sorting && this.state.sorting != ""
          ? this.state.sorting
          : "1") +
        "&search=" +
        searchKeyword;
    } else {
      apiUrl =
        apiPath.importPatientList +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        (this.state.filter && this.state.filter != ""
          ? "&filterBy=" + this.state.filter
          : "") +
        "&orderBy=" +
        (this.state.sorting && this.state.sorting != ""
          ? this.state.sorting
          : "1") +
        "";
    }
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Patient List",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          let totalPatientCount = response.headers.get("X-Total-Count");
          this.setState({
            total: totalPatientCount,
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Patient List",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        // let localPatientList = this.state.patientList;
        // if (this.state.currentPage == 0) {
        //     localPatientList = data;
        // } else {
        //     localPatientList = localPatientList.concat(data);
        // }
        // let totalPatientShowing = localPatientList.length;
        // let loadMore = false;
        // let loadingState = false;
        // let currentPage = this.state.currentPage;
        // if (this.state.total > totalPatientShowing) {
        //     loadMore = true;
        //     loadingState = true;
        //     currentPage = currentPage + 1;
        // }
        // // sessionStorage.setItem(PATIENT_LIST, JSON.stringify(localPatientList));
        // this.setState({
        //     patientList: localPatientList,
        //     totalPatientShowing: totalPatientShowing,
        //     loadMore: loadMore,
        //     currentPage: currentPage,
        //     loadingState: loadingState,
        //     loadingPatient: false,
        // })
        this.setState({
          patientList: data,
          // totalPatientShowing: totalPatientShowing,
          // loadMore: loadMore,
          // currentPage: currentPage,
          // loadingState: loadingState,
          loadingPatient: false,
        });
        if (data.detail) {
          showNotification = {
            title: "Patient List",
            message: data.detail,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        // window.dispatchEvent(new Event('resize'));
      })
      .catch((error) => {
        showNotification = {
          title: "Patient List",
          message: "Bad response from server",
          type: "danger",
        };
        this.setState({
          loadingPatient: false,
        });
        userService.showNotification(showNotification);
        return response;
      });
  }
  async fetchAssignedPatients() {
    this.setState({
      loadingPatientAssigned: true,
    });
    let showNotification = {};
    let apiUrl = "";
    let searchKeyword = this.state.patientSearchKeyword;
    if (searchKeyword && searchKeyword.length > 0) {
      if (this.state.selectRemovePatient.length) {
        apiUrl =
          apiPath.assignedPatientList +
          "?page=" +
          this.state.currentPageAssigned +
          "&selectedIdList=" +
          this.state.selectRemovePatient +
          "&size=" +
          this.state.perPageAssigned +
          (this.state.filter && this.state.filter != ""
            ? "&filterBy=" + this.state.filter
            : "") +
          "&orderBy=" +
          (this.state.sorting && this.state.sorting != ""
            ? this.state.sorting
            : "1") +
          "&search=" +
          searchKeyword;
      } else {
        apiUrl =
          apiPath.assignedPatientList +
          "?page=" +
          this.state.currentPageAssigned +
          "&size=" +
          this.state.perPageAssigned +
          (this.state.filter && this.state.filter != ""
            ? "&filterBy=" + this.state.filter
            : "") +
          "&orderBy=" +
          (this.state.sorting && this.state.sorting != ""
            ? this.state.sorting
            : "1") +
          "&search=" +
          searchKeyword;
      }
    } else {
      if (this.state.selectRemovePatient.length) {
        apiUrl =
          apiPath.assignedPatientList +
          "?page=" +
          this.state.currentPageAssigned +
          "&selectedIdList=" +
          this.state.selectRemovePatient +
          "&size=" +
          this.state.perPageAssigned +
          (this.state.filter && this.state.filter != ""
            ? "&filterBy=" + this.state.filter
            : "") +
          "&orderBy=" +
          (this.state.sorting && this.state.sorting != ""
            ? this.state.sorting
            : "1") +
          "";
      } else {
        apiUrl =
          apiPath.assignedPatientList +
          "?page=" +
          this.state.currentPageAssigned +
          "&size=" +
          this.state.perPageAssigned +
          (this.state.filter && this.state.filter != ""
            ? "&filterBy=" + this.state.filter
            : "") +
          "&orderBy=" +
          (this.state.sorting && this.state.sorting != ""
            ? this.state.sorting
            : "1") +
          "";
      }
    }
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Patient List",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          let totalPatientCount = response.headers.get("X-Total-Count");
          this.setState({
            totalAssigned: totalPatientCount,
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Patient List",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          assignedPatientList: data,
          loadingPatientAssigned: false,
        });
        if (data.detail) {
          showNotification = {
            title: "Patient List",
            message: data.detail,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        // window.dispatchEvent(new Event('resize'));
      })
      .catch((error) => {
        showNotification = {
          title: "Patient List",
          message: "Bad response from server",
          type: "danger",
        };
        this.setState({
          loadingPatient: false,
        });
        userService.showNotification(showNotification);
        return response;
      });
  }
  handlePatientSorting = (event) => {
    const name = event.target.name;
    const sortByValue = event.target.value;
    this.setState(
      {
        [name]: sortByValue,
        currentPage: 0,
        currentPageAssigned: 0,
        selectedPatientId: "",
        selectedPatientData: {},
        selectionCount: 0,
        multiPatientMode: false,
        multiplePatientList: [],
      },
      () => {
        this.fetchPatients();
        this.fetchAssignedPatients();
      }
    );
  };
  search(event) {
    event.preventDefault();
    if (event.target.keyCode === 13) {
      return;
    }
    let searchKeyword = event.target.value.trim();
    this.setState({
      patientSearchKeyword: searchKeyword,
      currentPage: 0,
      currentPageAssigned: 0,
      selectedPatientId: "",
      patientName: "",
      patientCategoryList: [],
      multiPatientMode: false,
      multiplePatientList: [],
      removePatientList: [],
    });
    return true;
  }
  searchReturn(event) {
    if (event.target.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();
      this.fetchPatients();
      this.fetchAssignedPatients();
    }
  }
  loadMorePatient() {
    let localPatientList = this.state.patientList;
    this.setState(
      {
        loadMore: false,
      },
      () => this.fetchPatients()
    );
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      showModal: nextProps.showModal,
      reduxLoadFlag: nextProps.reduxLoadFlag,
      patientFilterList: nextProps.patientFilterList,
      removePatientList: nextProps.selectRemovePatient.length
        ? [...nextProps.selectRemovePatient]
        : [],
      selectRemovePatient: nextProps.selectRemovePatient,
    });
    if (nextProps.showModal && !this.state.showModal) {
      this.setState(
        {
          showModal: nextProps.showModal,
          perPage: DEFAULT_PER_PAGE_RECORDS,
          total: 0,
          totalAssigned: 0,
          currentPage: 0,
          currentPageAssigned: 0,
          sorting: "LAST_NAME_ASC",
          filter: "",
          patientSearchKeyword: "",
          patientList: [],
          assignedPatientList: [],
          selectedPatientId: "",
          multiPatientMode: false,
          multiplePatientObj: [],
          multiplePatientList: [],
          // removePatientList: [],
          selectedPatientData: {},
          loadingPatient: true,
        },
        () => {
          this.fetchPCCPatients();
          this.fetchAssignedPatients();
        }
      );
    }
  }
  selectPatientList = (patientId) => {
    let selectedPatients = this.state.multiplePatientList;
    if (selectedPatients.includes(patientId)) {
      const index = selectedPatients.indexOf(patientId);
      if (index > -1) {
        selectedPatients.splice(index, 1);
      }
    } else {
      selectedPatients.push(patientId);
    }
    // if (selectedPatients && selectedPatients.length) {
    //     this.setState({
    //         multiplePatientList: selectedPatients,
    //         selectedPatientId: "",
    //         multiPatientMode: true,
    //     })
    // } else {
    //     this.setState({
    //         multiplePatientList: [],
    //         selectedPatientId: patientId,
    //         multiPatientMode: false,
    //     })
    // }
    this.setState({
      multiplePatientList: selectedPatients,
    });
  };
  removePatientList = (patientId) => {
    let selectedPatients = this.state.removePatientList;
    if (selectedPatients.includes(patientId)) {
      const index = selectedPatients.indexOf(patientId);
      if (index > -1) {
        selectedPatients.splice(index, 1);
      }
    } else {
      selectedPatients.push(patientId);
    }
    this.setState({
      removePatientList: selectedPatients,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState(
      {
        currentPage: newPage,
      },
      () => this.fetchPatients()
    );
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        perPage: parseInt(event.target.value),
        currentPage: 0,
      },
      () => this.fetchPatients()
    );
  };
  handleChangePageAssigned = (event, newPage) => {
    this.setState(
      {
        currentPageAssigned: newPage,
      },
      () => this.fetchAssignedPatients()
    );
  };
  handleChangeRowsPerPageAssigned = (event) => {
    this.setState(
      {
        perPageAssigned: parseInt(event.target.value),
        currentPageAssigned: 0,
      },
      () => this.fetchAssignedPatients()
    );
  };
  async removePatients() {
    let showNotification = {};
    let removePatientList = this.state.removePatientList;
    if (removePatientList && removePatientList.length) {
      const data = {
        id: removePatientList,
      };
      // console.log(data)
      // return;
      this.setState({
        loading: true,
      });
      const response = await fetch(apiPath.unassignPatientToDash, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Remove Patients",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
            showNotification = {
              title: "Remove Patients",
              message: "Patients Removed to dashboard successfully",
              type: "success",
            };
            this.setState({
              removePatientList: [],
              selectRemovePatient: [],
            });
            this.props.handleRemovedSuccess();
            this.fetchPatients();
            this.fetchAssignedPatients();
          } else {
            showNotification = {
              title: "Remove Patients",
              message: "Bad response from server. ",
              type: "danger",
            };
          }
          this.setState({ loading: false });
          return response.json();
        })
        .then((data) => {
          // console.log(data)
          if (data.title) {
            showNotification = {
              title: "Remove Patients",
              message: data.title,
              type: "danger",
            };
          }
          userService.showNotification(showNotification);
        })
        .catch((error) => {
          console.log(error);
          showNotification = {
            title: "Remove Patients",
            message: "Something went wrong.Please try after sometime.." + error,
            type: "danger",
          };
          userService.showNotification(showNotification);
          this.setState({ loading: false });
        });
    } else {
      showNotification = {
        title: "Remove Patients",
        message: "Please select patients to remove",
        type: "warning",
      };
      userService.showNotification(showNotification);
    }
  }
  async assignPatients() {
    let showNotification = {};
    let multiplePatientList = this.state.multiplePatientList;
    if (multiplePatientList && multiplePatientList.length) {
      const data = {
        id: multiplePatientList,
      };
      this.setState({
        loading: true,
      });
      const response = await fetch(apiPath.assignPatientToDash, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Assign Patients",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
            showNotification = {
              title: "Assign Patients",
              message: "Patients assigned to dashboard successfully",
              type: "success",
            };
            this.setState({
              multiplePatientList: [],
            });
            this.props.handleAssignedSuccess();
            this.fetchPatients();
            this.fetchAssignedPatients();
          } else {
            showNotification = {
              title: "Assign Patients",
              message: "Bad response from server. ",
              type: "danger",
            };
          }
          this.setState({ loading: false });
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.title) {
            showNotification = {
              title: "Assign Patients",
              message: data.title,
              type: "danger",
            };
          }
          userService.showNotification(showNotification);
        })
        .catch((error) => {
          console.log(error);
          showNotification = {
            title: "Assign Patients",
            message: "Something went wrong.Please try after sometime.." + error,
            type: "danger",
          };
          userService.showNotification(showNotification);
          this.setState({ loading: false });
        });
    } else {
      showNotification = {
        title: "Assign Patients",
        message: "Please select patients to assign",
        type: "warning",
      };
      userService.showNotification(showNotification);
    }
  }
  render() {
    const { handleCancel } = this.props;
    const {
      sorting,
      filter,
      showModal,
      patientList,
      selectedPatientId,
      loading,
      loadingPatient,
      loadingPatientAssigned,
      assignedPatientList,
      removePatientList,
      multiPatientMode,
      multiplePatientList,
      total,
      totalAssigned,
    } = this.state;
    return (
      <div>
        <Dialog
          open={showModal}
          maxWidth={"md"}
          onClose={handleCancel}
          scroll="body"
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal modalFitOnScreen new-modal patient-list-page"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Add Patients to MAX MRJ Dashboard
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={handleCancel}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="top_headimport adjustpadd">
              <Box pl={1} pr={1} className="searchbyname-md">
                <Tooltip title="Search By Name" placement="top" arrow>
                  <Paper component="form" className="search-box-cover">
                    <InputBase
                      className="search-box"
                      placeholder="Search Patient..."
                      value={this.state.patientSearchKeyword}
                      onChange={this.search}
                      onKeyPress={this.searchReturn}
                    />
                    <IconButton
                      className=""
                      aria-label="search"
                      onClick={() => {
                        this.fetchPatients();
                        this.fetchAssignedPatients();
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Tooltip>
              </Box>
              <Box pl={1} pr={1} className="box-filter filter-label">
                <FormGroup>
                  <FormControl variant="outlined">
                    <InputLabel>Filter By</InputLabel>
                    <Select
                      id="filter"
                      color="secondary"
                      name="filter"
                      className="filter-select"
                      value={filter}
                      // displayEmpty
                      onChange={this.handlePatientSorting}
                    >
                      <MenuItem value={""}>{"None"}</MenuItem>
                      {/* {this.state.patientFilterList && this.state.patientFilterList.length
                                                ? this.state.patientFilterList.map((fList, key) => {
                                                    return (
                                                        <MenuItem
                                                            key={key}
                                                            value={fList.id}
                                                        >
                                                            {fList.filterName}
                                                        </MenuItem>
                                                    );
                                                })
                                                : null} */}
                      {IMPORT_PATIENT_FILTERBY
                        ? Object.keys(IMPORT_PATIENT_FILTERBY).map((key) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {IMPORT_PATIENT_FILTERBY[key]}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Box>
              <Box pl={1} className="box-filter filter-orderby">
                <FormGroup>
                  <FormControl variant="outlined" className={"mat_select"}>
                    <InputLabel htmlFor="filter">Order By</InputLabel>
                    <Select
                      id="sorting"
                      color="secondary"
                      name="sorting"
                      className="filter-select"
                      value={sorting}
                      onChange={this.handlePatientSorting}
                    >
                      {IMPORT_PATIENT_ORDERBY
                        ? Object.keys(IMPORT_PATIENT_ORDERBY).map((key) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {IMPORT_PATIENT_ORDERBY[key]}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Box>
              <Box pl={1} pr={1}>
                <button
                  className="pointer btn1 import_btn1 remove-from-btn"
                  disabled={loading ? true : false}
                  // disabled={!multiplePatientList || multiplePatientList.length == 0 ? true : false }
                  onClick={() => this.removePatients()}
                >
                  Remove from MAX MRJ
                </button>
              </Box>
              <Box pl={1} pr={1}>
                <button
                  className="pointer btn1 import_btn1"
                  disabled={loading ? true : false}
                  // disabled={!multiplePatientList || multiplePatientList.length == 0 ? true : false }
                  onClick={() => this.assignPatients()}
                >
                  Import to MAX MRJ
                </button>
              </Box>
            </div>
            <div className="tableResponsive">
              <p>
                Imported patients: (Selected{" "}
                {removePatientList && removePatientList.length
                  ? removePatientList.length
                  : 0}{" "}
                / {totalAssigned})
              </p>
              {/* <Scrollbars className="patientlist-scroll" style={{ height: "300px" }} universal={true} autoHide={false}> */}
              {/* <TableContainer className="patientlist-scroll" style={{ maxHeight: 150 }}> */}
              <TableContainer
                className="patientlist-scroll patients-list"
                style={{ maxHeight: 150 }}
              >
                {this.state.loadingPatientAssigned ||
                this.state.loading ||
                !this.state.reduxLoadFlag ? (
                  <div className="">
                    <LinearProgress />
                  </div>
                ) : (
                  <div className=""></div>
                )}
                <Table stickyHeader className="importpateint_table">
                  <TableRow className="green_row">
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                      >
                        {headCell.sorting ? (
                          <TableSortLabel
                            active={this.state.orderBy == headCell.id}
                            direction={this.state.direction ? "asc" : "desc"}
                            onClick={this.tableSortHandler(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableBody>
                    {this.state.assignedPatientList.length ? (
                      this.state.assignedPatientList.map((pList, key) => {
                        // let localDobMoment = moment.utc(pList.birthDate).format("MM/DD/YYYY")
                        let localDobMoment = formatDateToLocalFormat(
                          pList.birthDate
                        );
                        let localAdmissionDateMoment = pList.admissionDate
                          ? moment.utc(pList.admissionDate).format("MM/DD/YYYY")
                          : "N/A";
                        let localDCMoment = pList.dischargeDate
                          ? moment.utc(pList.dischargeDate).format("MM/DD/YYYY")
                          : "N/A";
                        return (
                          <TableRow key={key}>
                            <TableCell>
                              {pList.referralSent ? (
                                <CustomTooltip
                                  title="Active referral patient can not be removed"
                                  placement="top"
                                  arrow
                                >
                                  <div>
                                    <Checkbox
                                      className="check-input"
                                      disabled={true}
                                    />
                                  </div>
                                </CustomTooltip>
                              ) : (
                                <Checkbox
                                  className="check-input"
                                  checked={
                                    this.state.removePatientList.includes(
                                      pList.id
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    this.removePatientList(pList.id)
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {pList.lastName}, {pList.firstName}{" "}
                              {pList.middleName ? pList.middleName : ""} (
                              {localDobMoment})
                            </TableCell>
                            <TableCell>{localDCMoment}</TableCell>
                            <TableCell>{pList.patientStatus}</TableCell>
                            <TableCell>{localAdmissionDateMoment}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : loadingPatientAssigned ? (
                      <TableRow>
                        <TableCell colspan="7">
                          <div
                            className="not-found-text"
                            style={{ textAlign: "center" }}
                          >
                            loading, please wait...
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colspan="7">
                          <div
                            className="not-found-text"
                            style={{ textAlign: "center" }}
                          >
                            Patients not found
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={PER_PAGE_OPTIONS}
                component="div"
                count={parseInt(this.state.totalAssigned)}
                rowsPerPage={this.state.perPageAssigned}
                page={this.state.currentPageAssigned}
                onChangePage={this.handleChangePageAssigned}
                onChangeRowsPerPage={this.handleChangeRowsPerPageAssigned}
              />
            </div>
            <div className="tableResponsive">
              <p>
                Available patients to import: (Selected{" "}
                {multiplePatientList && multiplePatientList.length
                  ? multiplePatientList.length
                  : 0}{" "}
                / {total})
              </p>
              {/* <Scrollbars className="patientlist-scroll" style={{ height: "300px" }} universal={true} autoHide={false}> */}
              {/* <TableContainer className="patientlist-scroll" style={{ maxHeight: 200 }}> */}
              <TableContainer className="patientlist-scroll patients-list">
                {this.state.loadingPatient ||
                this.state.loading ||
                !this.state.reduxLoadFlag ? (
                  <div className="">
                    <LinearProgress />
                  </div>
                ) : (
                  <div className=""></div>
                )}
                <Table stickyHeader className="importpateint_table">
                  <TableRow className="green_row">
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                      >
                        {headCell.sorting ? (
                          <TableSortLabel
                            active={this.state.orderBy == headCell.id}
                            direction={this.state.direction ? "asc" : "desc"}
                            onClick={this.tableSortHandler(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableBody>
                    {this.state.patientList.length ? (
                      this.state.patientList.map((pList, key) => {
                        // let localDobMoment = moment.utc(pList.birthDate).format("MM/DD/YYYY")
                        let localDobMoment = formatDateToLocalFormat(
                          pList.birthDate
                        );
                        let localAdmissionDateMoment = pList.admissionDate
                          ? moment.utc(pList.admissionDate).format("MM/DD/YYYY")
                          : "N/A";
                        let localDCMoment = pList.dischargeDate
                          ? moment.utc(pList.dischargeDate).format("MM/DD/YYYY")
                          : "N/A";
                        return (
                          <TableRow key={key}>
                            <TableCell>
                              <Checkbox
                                className="check-input"
                                checked={
                                  pList.id == selectedPatientId ||
                                  this.state.multiplePatientList.includes(
                                    pList.id
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  this.selectPatientList(pList.id)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {pList.lastName}, {pList.firstName}{" "}
                              {pList.middleName ? pList.middleName : ""} (
                              {localDobMoment})
                            </TableCell>
                            <TableCell>{localDCMoment}</TableCell>
                            <TableCell>{pList.patientStatus}</TableCell>
                            <TableCell>{localAdmissionDateMoment}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : loadingPatient ? (
                      <TableRow>
                        <TableCell colspan="7">
                          <div
                            className="not-found-text"
                            style={{ textAlign: "center" }}
                          >
                            loading, please wait...
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colspan="7">
                          <div
                            className="not-found-text"
                            style={{ textAlign: "center" }}
                          >
                            Patients not found
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={PER_PAGE_OPTIONS}
                component="div"
                count={parseInt(this.state.total)}
                rowsPerPage={this.state.perPage}
                page={this.state.currentPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
