import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import axios from "axios";
import moment from "moment";
import CardBody from "components/Card/CardBody.jsx";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Button,
  TablePagination,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  DialogTitle,
  IconButton,
  TextField,
  MenuItem,
  InputLabel,
  Slide,
  Box,
  FormControl,
  Checkbox,
  Chip,
} from "@material-ui/core";

import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import Icon from "@material-ui/core/Icon";
import { EMAIL_REGEX, ONLY_AlPHABETS_REGEX } from "__helpers/constants";
import CloseIcon from "@material-ui/icons/Close";

import { FormErrors } from "../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import ConfirmModal from "components/Modal/modal";

import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";

import { connect } from "react-redux";
import { reduxLoad } from "js/actions/index";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function mapDispatchToProps(dispatch) {
  return {
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
    providerStatusList: state.providerStatusList,
  };
};
class ReferralReportsClass extends Component {
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    this.dateUtility = new DateFnsUtils();
    this.state = {
      loading: false,
      startDate: null,
      endDate: null,
      facilityList: [],
      facilities: [],
      providersList: [],
      providers: [],
      startMaxDate: new Date(),
      facilityValid: false,
      startDateValid: false,
      endDateValid: false,
    };
  }
  componentDidUpdate() {}
  componentDidMount() {
    const getFacilitiesPromise = fetch(apiPath.getActiveFacilities, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          facilities: data,
        });
      })
      .catch((error) => {
        console.error("Error fetching providers:", error);
      });

    const getProvidersPromise = fetch(apiPath.getActiveProviders, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          // Handle the case when response status is 400
        } else if (response.ok) {
          // Handle the case when response is OK
        } else {
          // Handle other response statuses
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          providers: data,
        });
      })
      .catch((error) => {
        // Handle the error
        console.error("Error fetching providers:", error);
        // Show notification or perform any necessary actions
      });

    Promise.all([getFacilitiesPromise, getProvidersPromise])
      .then(() => {
        // Both API requests are completed
        // You can perform any additional actions here
      })
      .catch((error) => {
        // Handle the error
        console.error("Error fetching data:", error);
        // Show notification or perform any necessary actions
      });
  }
  handleStartDateChange = (date) => {
    console.log(date);
    const currentDate = new Date();
    const { endDate } = this.state;
    // if (date > currentDate) {
    //   // Handle the case when start date is greater than current date
    //   let showNotification = {
    //     title: 'Start Date',
    //     message: 'Start date cannot be greater than the current date.',
    //     type: "danger"
    // };
    // userService.showNotification(showNotification);
    //   return;
    // }

    // Update the state with the selected start date
    this.setState({ startDateValid: true });
    this.setState({
      startDate: date,
    });

    if (endDate) {
      if (date > endDate) {
        this.setState({ endDateValid: false });
        this.setState({ endDate: null });
      }
    }
  };

  handleEndDateChange = (date) => {
    const currentDate = new Date();
    const { startDate } = this.state;

    if (date > currentDate) {
      // Handle the case when end date is greater than current date
      let showNotification = {
        title: "End Date",
        message: "End date cannot be greater than the current date.",
        type: "danger",
      };
      userService.showNotification(showNotification);
      return;
    }

    if (startDate && date < startDate) {
      // Handle the case when end date is earlier than start date
      let showNotification = {
        title: "End Date",
        message: "End date cannot be earlier than the start date.",
        type: "danger",
      };
      userService.showNotification(showNotification);
      return;
    }

    // Update the state with the selected end date
    this.setState({ endDateValid: true });
    this.setState({
      endDate: date,
    });
  };

  handleDownloadReport = async () => {
    const { startDate, endDate, facilityList, providersList } = this.state;

    let showNotification = {};
    try {
      let showNotification = {
        title: "Referral Report",
        message: "Please wait, Downloading files",
        type: "info",
      };
      userService.showNotification(showNotification);
      // Create an array to store query parameters for each facilityId
      const facilityIdsQueryParams = facilityList.map(
        (facility) => `facilityIds=${facility}`
      );

      // Create an array to store query parameters for each providerId
      const providersIdsQueryParams = providersList.map(
        (provider) => `providerIds=${provider}`
      );

      // Create an array to store the startDate and endDate query parameters
      const dateQueryParams = [];
      if (startDate) {
        dateQueryParams.push(
          `startDate=${moment(startDate).format("YYYY-MM-DD")}`
        );
      }
      if (endDate) {
        dateQueryParams.push(`endDate=${moment(endDate).format("YYYY-MM-DD")}`);
      }
      // Combine all query parameters into a single array
      const queryParams = [
        ...facilityIdsQueryParams,
        ...providersIdsQueryParams,
        ...dateQueryParams,
      ];

      // Construct the query string by joining the queryParams array with '&'
      const queryString = queryParams.join("&");

      // Pass the queryString as query parameters to the API endpoint
      const url = `${apiPath.referralReportDownload}?${queryString}`;

      let sheetData = await userService.fetchsReferralReportData(url);
    } catch (error) {
      showNotification = {
        title: "Referral Report",
        message: "No Referral Report available.",
        type: "danger",
      };
      userService.showNotification(showNotification);
    }
    this.setState({
      loading: false,
    });
    return false;
  };
  handleChangeInputList = (event) => {
    const selectedFacilities = event.target.value;
    this.setState({
      facilityList: selectedFacilities,
    });
  };

  handleChangeProvidersInputList = (event) => {
    //const selectedProvider = event.target.value;
    console.log();
    const { name, value } = event.target;
    if (name === "providersList" || name === "facilityList") {
      let updatedValue = value.filter((item) => item !== "");

      this.setState({
        [name]: updatedValue,
      });
      if (name === "providersList") {
        let updatedValue = value.filter((item) => item !== "");
        this.setState({ facilityValid: true });
        if (updatedValue.length == 0) {
          this.setState({ facilityValid: false });
        }
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  validateForm() {
    return this.state.endDateValid && this.state.startDateValid;
  }
  render() {
    const { startDate, endDate } = this.state;

    return (
      <div id="provider-index">
        <GridContainer>
          <Card>
            <CardHeader color="success" className={"cusCardTitle"}>
              <h4>Referral Reports</h4>
            </CardHeader>
            <CardBody>
              {/* Date picker */}
              <Box className="box-width">
                <Box className="box-width-left">
                  <InputLabel htmlFor="facility-select-label">
                    Facilities *
                  </InputLabel>
                  <Select
                    onChange={this.handleChangeProvidersInputList}
                    inputProps={{
                      name: "facilityList",
                      // id: 'facility-select-label',
                    }}
                    value={
                      this.state.facilityList.length
                        ? this.state.facilityList
                        : [""]
                    }
                    multiple
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 250,
                        },
                      },
                      variant: "menu",
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="">Select Facilities</MenuItem>
                    {this.state.facilities.length ? (
                      this.state.facilities.map((facility) => (
                        <MenuItem value={facility.facilityId}>
                          {facility.facility_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value=" ">Not Available</MenuItem>
                    )}
                  </Select>
                </Box>
                <Box className="box-width-left">
                  <InputLabel htmlFor="facility-select-label">
                    Provider
                  </InputLabel>
                  <Select
                    onChange={this.handleChangeProvidersInputList}
                    inputProps={{
                      name: "providersList",
                      // id: 'facility-select-label',
                    }}
                    value={
                      this.state.providersList.length
                        ? this.state.providersList
                        : [""]
                    }
                    multiple
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 250,
                        },
                      },
                      variant: "menu",
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="">Select Provider</MenuItem>
                    {this.state.providers.length ? (
                      this.state.providers.map((provider) => (
                        <MenuItem value={provider.id}>
                          {provider.providerName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value=" ">Not Available</MenuItem>
                    )}
                  </Select>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-start" mb={2}>
                <Box pr={2} width="auto">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      // variant="inline"
                      label="Start Date"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="start-date-picker-dialog"
                      InputLabelProps={{}}
                      InputProps={{ autoComplete: "off" }}
                      name="startDate"
                      animateYearScrolling={true}
                      value={this.state.startDate}
                      maxDate={this.state.startMaxDate}
                      //minDate={this.minDate}
                      maxDateMessage={enMsg.adtMaxStartDateMessage}
                      onChange={this.handleStartDateChange}
                      //disableToolbar={true}
                      //onError={this.handleStartDateError}
                      // onOpen={}
                      // autoOk={true}
                      // open={this.state.openStartDateDialog}
                      // onFocus={this.datePickerFocus}
                      className="KeyboardDatePicker"
                      //invalidDateMessage={enMsg.invalidDate}
                      closeAfterSelect={true}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        className: "date-picker-span",
                      }}
                    />
                    {!this.state.startDateValid ? (
                      <span>
                        <p className="MuiFormHelperText-root.Mui-error red-text">
                          Please select this field.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </MuiPickersUtilsProvider>
                </Box>

                <Box width="auto">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      // variant="inline"
                      label="End Date"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="end-date-picker-dialog"
                      InputLabelProps={
                        {
                          // className: "required-label",
                        }
                      }
                      InputProps={{ autoComplete: "off" }}
                      name="endDate"
                      animateYearScrolling={true}
                      value={this.state.endDate}
                      maxDate={this.state.startMaxDate}
                      minDate={this.state.startDate}
                      //maxDateMessage={enMsg.adtMaxStartDateMessage}
                      onChange={this.handleEndDateChange}
                      //disableToolbar={true}
                      //onError={this.handleStartDateError}
                      // onOpen={}
                      // autoOk={true}
                      // open={this.state.openStartDateDialog}
                      // onFocus={this.datePickerFocus}
                      className="KeyboardDatePicker"
                      //invalidDateMessage={enMsg.invalidDate}
                      // closeAfterSelect={true}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        className: "date-picker-span",
                      }}
                    />
                    {!this.state.endDateValid ? (
                      <span>
                        <p className="MuiFormHelperText-root.Mui-error red-text">
                          Please select this field.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </MuiPickersUtilsProvider>
                </Box>
              </Box>

              {/* EndDate picker */}

              <Button
                variant="contained"
                color="primary"
                onClick={this.handleDownloadReport}
                className="btn-new-change"
                disabled={!this.validateForm()}
              >
                Download Report
              </Button>
            </CardBody>
          </Card>
        </GridContainer>
      </div>
    );
  }
}

export const ReferralReports = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralReportsClass);
export default ReferralReports;
