/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import { USER_INFO, AUTO_LOGOUT_TIME } from "__helpers/constants";
import IdleTimer from "react-idle-timer";
import { userService } from "_services/user.service";
import {
  IconButton,
  Link as MaterialLink,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import baseRoutes from "base-routes";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: AUTO_LOGOUT_TIME,
      logoutTime: 15,
      time: {},
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem(USER_INFO));
  }

  _onAction(e) {
    this.setState({
      // isTimedOut: false,
      // showModal: false,
    });
  }

  _onActive(e) {
    console.log("User is active");
    if (this.state.logoutTime <= 0) {
      window.location.replace(baseRoutes.login.path);
      userService.logout();
    }
    this.setState({
      // isTimedOut: false,
      // showModal: false,
    });
  }

  _onIdle(e) {
    console.log("User is idle");
    if (this.state.logoutTime <= 0) {
      // Manually call onActive if logoutTime is 0
      this.onActive();
    } else {
      this.setState({
        showModal: true,
        timeout: 1000 * 15 * 1,
        isTimedOut: true,
        logoutTime: 15,
      });
      this.timer = 0;
      this.startTimer();
      //this.idleTimer.reset();
    }
  }

  startTimer() {
    if (this.timer === 0 && this.state.logoutTime > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let seconds = this.state.logoutTime - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      logoutTime: seconds,
    });
    if (seconds <= 0) {
      clearInterval(this.timer);
      this.onActive(); // Call onActive when logoutTime reaches 0
    }
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  handleCloseModal = () => {
    this.setState(
      {
        showModal: false,
        timeout: AUTO_LOGOUT_TIME,
        isTimedOut: false,
        logoutTime: 15,
      },
      () => this.clearTimer()
    );
    if (this.state.logoutTime <= 0) {
      window.location.replace(baseRoutes.login.path);
      userService.logout();
    } else {
      this.idleTimer.reset();
    }
  };

  clearTimer() {
    this.idleTimer.reset();
    clearInterval(this.timer);
  }

  render() {
    return (
      <div className="logout-section">
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        <Dialog
          open={this.state.showModal}
          maxWidth={"xs"}
          onClose={this.handleCloseModal}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal logout-section"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Auto Logout
          </DialogTitle>
          <DialogContent>
            <Box
              width={1}
              display="flex"
              justifyContent="flex-start"
              mb={1}
              mt={1}
            >
              <Box pr={2}>
                You will be logged out after <b>{this.state.logoutTime}</b> sec. Do you want to stay?
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className="modal-actions" justify="center">
            <Button
              type="button"
              className="btn1"
              onClick={this.handleCloseModal}
            >
              Stay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  userDetail: PropTypes.object,
};

export default withStyles(dashboardStyle)(Dashboard);