import React, { Component } from "react";
import {
    Button,
    FormGroup,
    FormControl,
    InputLabel,
    TextField,
    CircularProgress,
    Slide
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from "@material-ui/core"
import "assets/css/login.css";
import logo from "assets/img/front-logo.png";
import {
    MIN_PASSWORD_LENGTH,
    PASSWORD_PATTERN,
    DIGIT_ONLY,
    OPT_LENGTH,
    MAX_PASSWORD_LENGTH
} from "__helpers/constants";
import baseRoutes from "base-routes";
import enMsg from "__helpers/locale/en/en";
import { NotificationOptions } from "__helpers/constants";
import { apiPath } from "api";
import fetch from "isomorphic-fetch";

import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { basePath } from "base-routes";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { userService } from "_services/user.service";
import { PCC_AUTH_DATA } from "__helpers/constants";
import { generalAuthTokenHeader } from "__helpers/auth-header";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
export default class Pcc3LeggedAuth extends React.Component {
    constructor(props) {
        super(props);
        let spinner = document.getElementById('loadingSpinner');
        const resetUrl = new URLSearchParams(this.props.location.search);
        const authCode = resetUrl.get('code')
        const stateId = resetUrl.get('state')
        this.state = {
            authCode: authCode,
            stateId: stateId,
            loading: false,
            spinner: spinner,
            msg1: "Please don't refresh or leave this window.",
            msg2: "We are authorising your session",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount = () => {
        const spinner = document.getElementById('loadingSpinner');
        if (spinner && !spinner.hasAttribute('hidden')) {
            spinner.setAttribute('hidden', 'true');
        }
        const resetUrl = new URLSearchParams(this.props.location.search);
        const authCode = resetUrl.get('code')
        const stateId = resetUrl.get('state')
        if (!authCode || !stateId) {
            let showNotification = {
                title: "Unauthorize Access",
                message: "Unauthorize Access, Please close this popup and try again.",
                type: "warning"
            };
            this.setState({
                msg1: "Unauthorize Access, Please try again or contact MAXMRJ Support.",
                msg2: "You can close this window, this popup will auto close in 5 seconds.",
            })
            userService.showNotification(showNotification)
            setTimeout(function () {
                var authPopup = window.self;
                authPopup.opener = window.self;
                authPopup.close();
            }, 3000);
            // this.state.spinner.removeAttribute('hidden', 'true');
        } else {
            this.state.spinner.removeAttribute('hidden', 'true');
            this.handleSubmit();
        }
    };
    async handleSubmit() {
        // let auth3LeggedDataStr = localStorage.getItem(PCC_AUTH_DATA);
        // let auth3LeggedData = JSON.parse(auth3LeggedDataStr);
        // console.log("auth3LeggedData", auth3LeggedData)
        let showNotification = {};
        // if (auth3LeggedData && auth3LeggedData.clientId) {
        this.setState({ loading: true });
        // let authHeader = "Basic " + btoa("client_id:" + atob(auth3LeggedData.clientId));
        // const data = new FormData();
        // data.append("grant_type", "authorization_code");
        // // data.append("redirect_uri", apiPath.basePath+auth3LeggedData.redirectUrl);
        // data.append("redirect_uri", "https://stage.maxmrj.com/maxmrj/pccauth");
        // data.append("code", this.state.authCode);
        let data = {
            "autoCode": this.state.authCode,
            "state": this.state.stateId,
        }
        try {
            const response = await fetch(apiPath.post3LeggedApi, {
                method: "POST",
                headers: generalAuthTokenHeader(),
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (response.ok) {
                        showNotification = {
                            title: "Authorized",
                            message: "Authorised successfully.",
                            type: "success"
                        };
                        this.setState({
                            msg1: "Authorised successfully.",
                            msg2: "You can close this window, this popup will auto close in 5 seconds.",
                        })
                    }
                    return response.json();
                })
                .then(data => {
                    this.state.spinner.setAttribute('hidden', 'true');
                    if (data.errorKey) {
                        showNotification = {
                            title: data.errorKey,
                            message: data.title,
                            type: "danger"
                        };
                        this.setState({
                            msg1: data.title+", Please try again or contact MAXMRJ Support.",
                            msg2: "You can close this window, this popup will auto close in 5 seconds.",
                        })
                    }
                    if (data.accessTokan){
                        let auth3LeggedData = {
                            accessTokan: data.accessTokan,
                            expiresIn: data.expiresIn,
                            curTime: new Date(),
                        }
                        localStorage.setItem(PCC_AUTH_DATA, JSON.stringify(auth3LeggedData));
                    }
                    setTimeout(function () {
                        var authPopup = window.self;
                        authPopup.opener = window.self;
                        authPopup.close();
                    }, 3000);
                    userService.showNotification(showNotification)
                    console.log(data);
                    return true;
                })
                .catch(error => {
                    console.log(error)
                    showNotification = {
                        title: "PCC Login Error",
                        message: "User does not have access to the Facility.",
                        type: "danger"
                    };
                    userService.showNotification(showNotification)
                    this.setState({ loading: false });
                    // return response;
                });
        } catch (error) {
            console.log(error)
            showNotification = {
                title: "PCC Login Error",
                message: "User does not have access to the Facility.",
                type: "danger"
            };
            userService.showNotification(showNotification)
            this.setState({ loading: false });
        }
        // }else{
        //     showNotification = {
        //         title: "Authorization Failled",
        //         message: "Authorization failled, please try again.",
        //         type: "danger"
        //     };
        //     userService.showNotification(showNotification)
        // }
    }
    render() {
        return (
            <div id="facility-index" className="autherize_session">
                {/* <CircularProgress /> */}
                <div className="autherize-session-part">
                    <div className="autherize-session">
                        <p className="mb-15">{this.state.msg1}</p>
                        <br></br>
                        <p>{this.state.msg2}</p>
                    </div>
                </div>
            </div>
        );
    }
}
