/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  DialogTitle,
  IconButton,
  TextField,
  MenuItem,
  InputLabel,
  Slide,
  Box,
  FormControl,
  Checkbox,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";
import PhoneNumber from "awesome-phonenumber";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  genderType,
  EMAIL_REGEX,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ZIPCODE_REGEX,
  ZIP_CODE_LENGTH,
  MIN_AGE_YEARS,
  ALLOWED_IMAGE_EXTENTION,
  DEFAULT_PROFILE_IMG,
  DIGIT_ONLY,
  PHONE_NO_INVALID_LENGTH,
  OK_SUCCESS_STATUS,
  STATUS_CREATED,
  INVALID_DATA_POST,
  SECURITY_ERROR,
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
  ONLY_AlPHABETS_REGEX,
} from "__helpers/constants";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import baseRoutes from "base-routes";
import ChangePassword from "./ChangePassword";
import fileExtension from "file-extension";
import { providerGeneralAuthTokenHeader } from "__helpers/auth-header";

import { withRouter } from "react-router";

import { connect } from "react-redux";
import { addUSerUInfo, reduxLoad } from "js/actions";
import { USER_INFO } from "__helpers/constants";
import { dpBaseRoutes } from "base-routes";
import { basePath } from "base-routes";
import backArrow from "assets/img/icons/arrow-back.svg";
import BasicProfile from "./BasicProfile";
import InDevelopment from "./InDevlopment";
import commingSoonImg from "assets/img/comming_soon.png";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { MAX_INPUT_LENGTH_RATE } from "__helpers/constants";
import { enGB } from "date-fns/esm/locale";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroller";
import { providerBaseRoutes } from "base-routes";
import DateFnsUtils from "@date-io/date-fns";
import Icon from "@material-ui/core/Icon";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { formatFileSize } from "__helpers/util";

function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: (addUSerUInfoVal) => dispatch(addUSerUInfo(addUSerUInfoVal)),
    addTimeStamp: (addTimeStampVal) => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    getPatientLanNInsListRedux: state.getPatientLanNInsList,
    hospitalListRedux: state.hospitalList,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const styles = (theme) => ({});
const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Id",
  },

  {
    id: "subscriptionAmount",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Amount",
  },
  {
    id: "subscriptionType",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Type",
  },
  {
    id: "totalDataUsagesKb",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Total Data Usages",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Start Date",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Action",
  },
];
class InvoicesClass extends React.Component {
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    this.dateUtility = new DateFnsUtils();
    this.state = {
      loading: false,
      providerInvoices: [],
      spinner: spinner,
      perPage: DEFAULT_PER_PAGE_RECORDS,
      total: "",
      currentPage: 0,
      orderBy: "id",
    };
    this.fetchProviderInvoices = this.fetchProviderInvoices.bind(this);
  }
  componentDidMount() {
    this.fetchProviderInvoices();
  }
  handleChangePage = (event, newPage) => {
    this.setState(
      {
        currentPage: newPage,
      },
      () => this.fetchProviderInvoices()
    );
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        perPage: parseInt(event.target.value),
        currentPage: 0,
      },
      () => this.fetchProviderInvoices(this.state.searchKeyword)
    );
  };
  tableSortHandler = (property) => (event) => {
    this.setState(
      {
        orderBy: property,
        direction: !this.state.direction,
      },
      () => this.fetchProviderInvoices(this.state.searchKeyword)
    );
  };
  modalOpen = (dataId) => {
    if (!isNaN(dataId)) {
      Promise.all(
        this.state.providerInvoices.map((invoice) => {
          if (invoice.id == dataId) {
            this.setState({
              // facility: provider.facility.facilityId,
              providerName: invoice.providerName,
              emailId: invoice.emailId,
              name: invoice.firstName + " " + invoice.lastName,
              startDate: moment(invoice.startDate)
                .local()
                .format("MM/DD/YYYY"),
              endDate: moment(invoice.endDate)
                .local()
                .format("MM/DD/YYYY"),
              status: invoice.status,
              subscriptionAmount: invoice.subscriptionAmount,
              subscriptionType: invoice.subscriptionType.replace(/_/g, " "),
              description: invoice.description,
              receiptUrl: invoice.receiptUrl,
            });
          }
        })
      );
    }
    this.setState({
      modalStatus: true,
    });
  };

  modalClose = () => {
    this.setState({
      modalStatus: false,
    });
  };
  async fetchProviderInvoices() {
    this.state.spinner.removeAttribute("hidden", "true");
    let showNotification = {};
    let apiUrl =
      apiPath.getProviderInvoicesByProviderId +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "";

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: providerGeneralAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider Invoice",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          this.setState({
            total: response.headers.get("X-Total-Count"),
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Provider Invoice",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          providerInvoices: data,
        });
      })
      .catch((error) => {
        showNotification = {
          title: "Provider Invoice",
          message: "Bad response from server",
          type: "danger",
        };
        return response;
      });

    userService.showNotification(showNotification);
    this.state.spinner.setAttribute("hidden", "true");
  }
  render() {
    return (
      <div className="ProviderProfile PaterintCriteria1">
        {this.state.loading ? <LinearProgress color="primary" /> : ""}
        <div className="tab-heading">Invoice</div>
        <Dialog
          open={this.state.modalStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Invoice Details
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
              <Box pr={1} width={1 / 2}>
                <InputLabel className="view-label">Provider Name</InputLabel>
                <InputLabel className="view-label value">
                  {this.state.providerName}
                </InputLabel>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start" mb={2} mt={1}>
              <Box pr={1} width={1 / 2}>
                <InputLabel className="view-label">
                  Primary Contact Name
                </InputLabel>
                <InputLabel className="view-label value">
                  {this.state.name}
                </InputLabel>
              </Box>
              <Box pr={1} width={1 / 2}>
                <InputLabel className="view-label">Email</InputLabel>
                <InputLabel className="view-label value">
                  {this.state.emailId}
                </InputLabel>
              </Box>
            </Box>

            <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
              <Box pr={1} width={1 / 2}>
                <InputLabel className="view-label">Start Date</InputLabel>
                <InputLabel className="view-label value">
                  {this.state.startDate}
                </InputLabel>
              </Box>
              <Box pr={1} width={1 / 2}>
                <InputLabel className="view-label">End Date</InputLabel>
                <InputLabel className="view-label value">
                  {this.state.endDate}
                </InputLabel>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
              <Box pr={1} width={1 / 2}>
                <InputLabel className="view-label">
                  Subscription Amount
                </InputLabel>
                <InputLabel className="view-label value">
                  ${this.state.subscriptionAmount}
                </InputLabel>
              </Box>
              <Box pr={1} width={1 / 2}>
                <InputLabel className="view-label">
                  Subscription Type
                </InputLabel>
                <InputLabel className="view-label value">
                  {this.state.subscriptionType}
                </InputLabel>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
              <Box pr={1} width={1 / 2}>
                <InputLabel className="view-label">Details</InputLabel>
                <InputLabel className="view-label value">
                  {this.state.description}
                </InputLabel>
              </Box>
            </Box>
            {this.state.receiptUrl ? (
              <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                <Box pr={1} width={1 / 2}>
                  <InputLabel className="view-label">Receipt</InputLabel>
                  <InputLabel className="view-label value">
                    <a
                      href={this.state.receiptUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                  </InputLabel>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions
            className="modal-actions"
            justify="center"
          ></DialogActions>
        </Dialog>
        <Card className={"advert-cover"}>
          <GridItem xs={12} sm={12} md={12}>
            <CardHeader
              color="success"
              className={"cusCardheader cusCardTitle"}
            >
              <h4>Invoice</h4>
            </CardHeader>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <div className={"custom-container"}>
            <div className="tableResponsive">
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                      >
                        {headCell.sorting ? (
                          <TableSortLabel
                            active={this.state.orderBy == headCell.id}
                            direction={this.state.direction ? "asc" : "desc"}
                            onClick={this.tableSortHandler(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.providerInvoices.length ? (
                    this.state.providerInvoices.map((providerInvoice) => (
                      <TableRow key={providerInvoice.id}>
                        <TableCell>{providerInvoice.id}</TableCell>
                        <TableCell>
                          ${providerInvoice.subscriptionAmount}
                        </TableCell>
                        <TableCell>
                          {providerInvoice.subscriptionType.replace(/_/g, " ")}
                        </TableCell>
                        <TableCell>
                          {formatFileSize(providerInvoice.totalDataUsagesKb)}
                        </TableCell>
                        <TableCell>
                          {moment(providerInvoice.startDate)
                            .local()
                            .format("MM/DD/YYYY")}
                        </TableCell>
                        <TableCell>
                          <span
                            className="edit-action"
                            onClick={() => this.modalOpen(providerInvoice.id)}
                          >
                            <Icon className="fa fa-eye" aria-hidden="true" />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colspan="7">
                        <div className="not-found-text">
                          Provider invoice not found
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>

            <TablePagination
              rowsPerPageOptions={PER_PAGE_OPTIONS}
              component="div"
              count={parseInt(this.state.total)}
              rowsPerPage={this.state.perPage}
              page={this.state.currentPage}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        </Card>
      </div>
    );
  }
}

InvoicesClass.propTypes = {
  classes: PropTypes.object,
};
const Invoices = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesClass);
export default withStyles(styles)(Invoices);
