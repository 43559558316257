/*eslint-disable*/
import React from "react";
import ReactDOM from "react-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Box,
  TextField,
  Avatar,
  Checkbox,
  LinearProgress,
} from "@material-ui/core";

import { providerBaseRoutes } from "base-routes";
import { userService } from "_services/user.service";

import {
  EMAIL_REGEX,
  ONLY_NUMBER_REGEX,
  ONLY_AlPHABETS_REGEX,
  URL_PATTERN,
  MAX_INPUT_LENGTH_LONG_TEXT,
} from "__helpers/constants";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";
import enMsg from "__helpers/locale/en/en";

import { apiPath } from "api";

import { USER_INFO, ZIP_CODE_LENGTH, DIGIT_ONLY } from "__helpers/constants";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import { basePath } from "base-routes";
import { FormErrors } from "components/Login/FormErrors";
import baseRoutes from "base-routes";
const mat_select = "material-select disabled-dropdown-black";
const cust_label = "custom-label";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF",
    },
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  disabledDropdownBlack: {
    color: "#000 !important",
  },
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(props.location.search);
    const id = queryParams.get("id");
    console.log(id);
    this.dateUtility = new DateFnsUtils();
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    this.addressStreetInputRef = React.createRef();
    this.state = {
      id: id,
    };
  }
  componentDidMount() {
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    //let { id } = Link();
    console.log(Link);
    this.fetchOrganizationalDetail();
    // this.setState(
    //   {
    //     providerId: userInfo.provider.id,
    //   },
    //   () => {
    //     this.fetchOrganizationalDetail();
    //   }
    // );
  }

  fetchOrganizationalDetail = () => {
    console.log("888888888888888");
    const response = fetch(apiPath.adminProviderToken + "/" + this.state.id, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          //   console.log(data.access_token);
          let accessToken = data.access_token;
          let jti = data.jti;
          let tokenType = data.token_type;
          let user = {
            jti: jti,
            accessToken: accessToken,
            tokenType: tokenType,
            id: this.state.id,
          };
          let banner = {
            msg: "",
            name: "Charge-Paid",
            id: 4,
          };

          localStorage.setItem("provider-user", JSON.stringify(user));
          localStorage.setItem("provider_banner_text", JSON.stringify(banner));
          window.location.replace(basePath + baseRoutes.providerProfile.path);
        } else {
        }
      })
      .catch((error) => {
        return response;
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <div id="provider-index">
        <GridContainer>
          <Card className={"advert-cover"}>
            <GridItem xs={12} sm={12} md={12}>
              <CardHeader
                color="success"
                className={"cusCardheader cusCardTitle"}
              >
                <h4>Providers organization details</h4>
              </CardHeader>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}></GridItem>
            <div className={"custom-container"}>
              <div className="tableResponsive"></div>
            </div>
          </Card>
        </GridContainer>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(styles)(Profile);
