import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  TextField,
  CircularProgress,
  Slide
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from "@material-ui/core"
import "assets/css/login.css";
import logo from "assets/img/front-logo.png";
import { FormErrors } from "./FormErrors";
import {
  MIN_PASSWORD_LENGTH,
  PASSWORD_PATTERN,
  DIGIT_ONLY,
  OPT_LENGTH,
  MAX_PASSWORD_LENGTH
} from "__helpers/constants";
import baseRoutes from "base-routes";
import enMsg from "__helpers/locale/en/en";
import { NotificationOptions } from "__helpers/constants";
import { apiPath } from "api";
import fetch from "isomorphic-fetch";

import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { basePath } from "base-routes";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { userService } from "_services/user.service";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    const resetUrl = new URLSearchParams(this.props.location.search);
    const tokenKey = resetUrl.get('key')
    let openModal = true;
    if (!tokenKey) {
      openModal = false;
    }
    this._isMounted = false;
    this.state = {
      password: "",
      confirmPassword: "",
      tokenKey: this.tokenKey ? this.tokenKey : "",
      openModal: this.openModal ? true : true,
      formErrors: {
        password: "",
        confirmPassword: "",
      },
      apiPath: apiPath.resetPassword,
      passwordValid: false,
      confirmPasswordValid: false,
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = () => {
    this._isMounted = true;
    const spinner = document.getElementById('loadingSpinner');
    if (spinner && !spinner.hasAttribute('hidden')) {
      spinner.setAttribute('hidden', 'true');
    }
    const resetUrl = new URLSearchParams(this.props.location.search);
    const tokenKey = resetUrl.get('key');
    if (!tokenKey) {
      let showNotification = {
        title: "Unauthorize Access",
        message: "You are not allowed to access this page.",
        type: "danger"
      };
      userService.showNotification(showNotification)
      this.setState({
        openModal: false,
      });
      setTimeout(function () {
        userService.logout();
        window.location.replace(baseRoutes.login.path);
        this.props.history.push(baseRoutes.login.path);
      }, 3000);
    } else {
      this.setState({
        tokenKey: tokenKey,
      });
    }
  };
  validateForm() {
    return (
      this.state.passwordValid &&
      this.state.confirmPasswordValid
    );
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (value.length > MAX_PASSWORD_LENGTH) {
      return true;
    }
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let passwordValid = this.state.passwordValid;
    let confirmPasswordValid = this.state.confirmPasswordValid;
    switch (fieldName) {
      case "password":
        passwordValid = true;
        let errorMessage = "";

        if (value.length < MIN_PASSWORD_LENGTH) {
          passwordValid = false;
          errorMessage = enMsg.shortPassword;
        } else if (value.length > MAX_PASSWORD_LENGTH) {
          passwordValid = false;
          errorMessage = enMsg.longPassword;
        } else if (!value.match(PASSWORD_PATTERN)) {
          errorMessage = enMsg.passwordPatternValidation;
          passwordValid = false;
        }
        fieldValidationErrors.password = errorMessage;
        break;

      case "confirmPassword":
        fieldValidationErrors.confirmPassword = "";
        confirmPasswordValid = true;
        if (
          value.length &&
          this.state.password.length &&
          this.state.password !== value
        ) {
          confirmPasswordValid = false;
          fieldValidationErrors.confirmPassword =
            enMsg.confirmPasswordValidation;
        }
        break;
      default:
        passwordValid = true;
        confirmPasswordValid = true;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        passwordValid: passwordValid,
        confirmPasswordValid: confirmPasswordValid,
      },
      this.validateForm
    );
  }

  async handleSubmit(event) {
    this.setState({ loading: true });

    event.preventDefault();

    const data = {
      email: this.state.email,
      newPassword: this.state.password,
      key: this.state.tokenKey
    };
    let showNotification = {};
    try {
      const response = await fetch(this.state.apiPath, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      })
        .then(response => {
          if (
            response.status == 400 ||
            response.status == 403 ||
            response.status == 404 ||
            response.status == 500
          ) {
            showNotification = {
              title: enMsg.failedResetPasswordTitle,
              message: enMsg.failedResetPassword,
              type: "danger"
            };
            // this.props.history.push(basePath + baseRoutes.login.path);
            this.setState({ loading: false });
            // userService.showNotification(showNotification)
          } else if (response.ok) {
            showNotification = {
              title: enMsg.successResetPasswordTitle,
              message: enMsg.successResetPassword,
              type: "success"
            };
            // userService.showNotification(showNotification);
            setTimeout(function () {
              userService.logout();
              window.location.replace(baseRoutes.login.path);
              this.props.history.push(baseRoutes.login.path);
            }, 3000);
            return response.text();
          } else {
            showNotification = {
              title: enMsg.failedResetPasswordTitle,
              message: enMsg.failedResetPassword,
              type: "danger"
            };
            // this.props.history.push(basePath + baseRoutes.login.path);
            this.setState({ loading: false });
            // userService.showNotification(showNotification);
          }
          return response.json();
        })
        .then(data => {
          if(data && data.title){
            showNotification = {
              title: enMsg.failedResetPasswordTitle,
              message: data.title,
              type: "dangerLong"
            };
          }
          userService.showNotification(showNotification);
          return true;
        })
        .catch(error => {
          showNotification = {
            title: enMsg.failUpdateTitle,
            message: enMsg.networkFailedError,
            type: "danger"
          };
          userService.showNotification(showNotification)
          this.setState({ loading: false });
          // return response;
        });
    } catch (error) {
      showNotification = {
        title: enMsg.failUpdateTitle,
        message: enMsg.networkFailedError,
        type: "danger"
      };
      userService.showNotification(showNotification)
      this.setState({ loading: false });
    }
    // if (
    //   showNotification !== undefined &&
    //   showNotification.title !== undefined &&
    //   showNotification.message !== undefined &&
    //   showNotification.type !== undefined
    // ) {
    //   this.notificationID = store.removeNotification(this.notificationID);
    //   if (this.notificationID == undefined) {
    //     let notifiaciton = {
    //       title: showNotification.title,
    //       message: showNotification.message,
    //       type: showNotification.type
    //     };
    //     notifiaciton = Object.assign(NotificationOptions, notifiaciton);
    //     this.notificationID = store.addNotification(notifiaciton);
    //   }
    //   userService.showNotification(showNotification)
    // }
    // this.setState({ loading: true });
  }
  cancelResetPassword = e => {
    window.location.replace(baseRoutes.login.path);
    this.props.history.push(baseRoutes.login.path);
  }

  render() {
    return (
      <div className="login-outer-cover">
        <div className="login-cover">
          <div className="login-inner-cover">
            <ReactNotification key="forgot-password-notification" />
            <div className="logo-content loginLogoMain">
              <div className="loginLogoInner">
                <img src={logo} alt="logo" />
              </div>
            </div>
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={this.state.openModal}
              {...this.state.other}
              TransitionComponent={Transition}
              maxWidth={'lg'}
              keepMounted
              className={"change-pass-dialog profile-content addBlockPopup add-modal"}
            >
              <DialogTitle id="customized-dialog-title">
                <div className="green-header warning-header" style={{ top: "0" }}>Set New password</div>

              </DialogTitle>
              <DialogContent className="addBlockContent">
                <form
                  onSubmit={this.handleSubmit}
                  id="reset-paasword-form"
                  noValidate
                >
                  <FormGroup>
                    <TextField
                      style={{ width: "100%" }}
                      label="New Password*"
                      type="password"
                      name="password"
                      value={this.state.password}
                      data-validators="isRequired,isAlpha"
                      onChange={this.handleChange}
                      inputProps={{ maxLength: MAX_PASSWORD_LENGTH }}
                    />
                    <FormErrors
                      show={!this.state.passwordValid}
                      formErrors={this.state.formErrors}
                      fieldName="password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      style={{ width: "100%" }}
                      label="Confirm Password*"
                      type="password"
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      data-validators="isRequired,isAlpha"
                      onChange={this.handleChange}
                      inputProps={{ maxLength: MAX_PASSWORD_LENGTH }}
                    />
                    <FormErrors
                      show={!this.state.confirmPasswordValid}
                      formErrors={this.state.formErrors}
                      fieldName="confirmPassword"
                    />
                  </FormGroup>
                  <div className="action-btns guidelines-data">
                    <p><b>Guidelines</b></p>
                    <p>Use at least {MIN_PASSWORD_LENGTH} letters with alphanumeric and special characters.</p>
                    <p>Do not use a space in the password.</p>
                    <p>Do not form a password by appending a digit to a word--this type of password is easily guessed.</p>
                  </div>
                  <div className="form-button-cover d-flex justify-space-evenly resetbtns">
                    <Button
                      // variant="contained"
                      className={this.state.loading ? "buttonSuccess btn1" : "btn1"}
                      disabled={this.state.loading || !this.validateForm()}
                      type="submit"
                    >
                      Set New Password
                      {this.state.loading && (
                        <CircularProgress
                          size={24}
                          className="buttonProgress"
                        />
                      )}
                    </Button>
                    <Button
                      // variant="contained"
                      className="btn2"
                      type="button"
                      onClick={this.cancelResetPassword}
                    >
                      Close
                    </Button>
                  </div>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
