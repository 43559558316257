/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Box,
  TextField,
  Avatar,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";
import PhoneNumber from "awesome-phonenumber";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  genderType,
  EMAIL_REGEX,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ZIPCODE_REGEX,
  ZIP_CODE_LENGTH,
  MIN_AGE_YEARS,
  ALLOWED_IMAGE_EXTENTION,
  DEFAULT_PROFILE_IMG,
  DIGIT_ONLY,
  PHONE_NO_INVALID_LENGTH,
  OK_SUCCESS_STATUS,
  STATUS_CREATED,
  INVALID_DATA_POST,
  SECURITY_ERROR,
} from "__helpers/constants";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import { baseRoutes, providerBaseRoutes } from "base-routes";
import ChangePassword from "./ChangePassword";
import fileExtension from "file-extension";
import { generalAuthTokenHeader } from "__helpers/auth-header";

import { withRouter } from "react-router";

import { connect } from "react-redux";
import { addUSerUInfo, reduxLoad } from "js/actions";
import { USER_INFO } from "__helpers/constants";
import { dpBaseRoutes } from "base-routes";
import { basePath } from "base-routes";
import backArrow from "assets/img/icons/arrow-back.svg";
import Banner from "components/Banner/Banner";
function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: (addUSerUInfoVal) => dispatch(addUSerUInfo(addUSerUInfoVal)),
    addTimeStamp: (addTimeStampVal) => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    loadingFlag: state.loadingFlag,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF",
    },
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  disabledDropdownBlack: {
    color: "#000 !important",
  },
});

class ProfileClass extends React.Component {
  inputOpenFileRef = React.createRef(null);
  maxDate = new Date();
  constructor(props) {
    super(props);
    /* create Ref for file input  */
    this.filename;
    this.profileImageBlob = null;
    let userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.state = {
      firstName: userDetail ? (userDetail.fName ? userDetail.fName : "") : "",
      middleName: userDetail
        ? userDetail.middleName
          ? userDetail.middleName
          : ""
        : "",
      lastName: userDetail ? (userDetail.lName ? userDetail.lName : "") : "",
      primaryPhone: userDetail
        ? userDetail.primaryPhone
          ? userDetail.primaryPhone
          : ""
        : "",
      email: userDetail ? (userDetail.email ? userDetail.email : "") : "",
      profileImage: userDetail
        ? userDetail.imageRef
          ? userDetail.imageRef
          : DEFAULT_PROFILE_IMG
        : DEFAULT_PROFILE_IMG,
      providerName: "",
      notifyEmail: "",
      notifyPhone: "",
      //startDate: new Date(),
      formErrors: {
        firstName: "",
        middleName: "",
        lastName: "",
        primaryPhone: "",
        email: "",
        profileImage: "",
        providerName: "",
        notifyEmail: "",
        notifyPhone: "",
      },
      firstNameValid: false,
      middleNameValid: true,
      lastNameValid: false,
      primaryPhoneValid: false,
      emailValid: true,
      formValid: true,
      profileImageValid: true,
      loading: false,
      showNotification: {},
      isOpen: false,
      openChangePasswordDialog: false,
      loginType: "",
      reduxLoadFlag: false,
      profileImageName: "",
      profileImage: "",
      isProfileImage: false,
      providerNameValid: false,
      notifyEmailValid: true,
      notifyPhoneValid: true,
      bannerText: "",
      isMobileNotification: true,
      isEmailNotification: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchUsrInfo = this.fetchUsrInfo.bind(this);
    this.handleSelectedFile = this.handleSelectedFile.bind(this);
    this.handleZipCodeInput = this.handleZipCodeInput.bind(this);
    this.handleChangePasswordDialog = this.handleChangePasswordDialog.bind(
      this
    );
    this.updateProfileImage = this.updateProfileImage.bind(this);
  }

  onFileBrowse = (event) => {
    this.inputOpenFileRef.current.click();
  };

  handleChangePasswordDialog = () => {
    const value = this.state.openChangePasswordDialog;
    this.setState({
      openChangePasswordDialog: !value,
    });
  };

  datePickerFocus = (isOpen) => {
    this.setState({ isOpen: true });
  };

  handleSelectedFile = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    let fieldValidationErrors = this.state.formErrors;
    let error = "";
    let profileImageValid = false;
    if (typeof file == "object" && file.size > ALLOWED_PROFILE_IMAGE_SIZE) {
      error = enMsg.allowedProfileImageSize;
    } else if (
      typeof file == "object" &&
      !ALLOWED_IMAGE_EXTENTION.includes(file.type)
    ) {
      error = enMsg.allowedProfileImageType;
    } else {
      profileImageValid = true;
    }

    if (typeof file !== undefined && profileImageValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        this.setState({
          profileImage: result,
          profileImageName: file.name,
          profileImage: result,
          isProfileImage: true,
        });
        this.profileImageBlob = file;
        this.filename = new Date().getTime() + "." + fileExtension(file.name);
      });
      if (file) {
        fileReader.readAsDataURL(file);
      }
    }

    fieldValidationErrors.profileImage = profileImageValid ? "" : error;
    this.setState(
      {
        profileImageValid: profileImageValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };

  handlephoneNumber = (phoneNumber) => {
    const phoneNumberValidation = new PhoneNumber(phoneNumber).toJSON();
    let phoneNumberValid = this.state.primaryPhoneValid;
    let error = "";
    let data = [];

    if (phoneNumber.trim().length) {
      switch (phoneNumberValidation.possibility) {
        // case "invalid-country-code":
        //     error = enMsg.phoneInvalidCountryCode;
        //     break;
        case "too-long":
          error = enMsg.phoneTooLong;
          break;
        case "too-short":
          error = enMsg.phoneTooShort;
          break;
        case "unknown":
          error = enMsg.phoneUnknown;
          break;
        default:
          phoneNumberValid = true;
      }
    }
    data["is_valid"] = phoneNumberValid;
    data["error"] = error;
    return data;
  };

  handleZipCodeInput = (e) => {
    const value = e.target.value;
    if (value.length > ZIP_CODE_LENGTH) {
      return true;
    }
    if (value.trim() == "" || value.match(DIGIT_ONLY)) {
      this.setState({ zipCode: value }, () => {
        this.validateField("zipCode", value);
      });
    }
  };
  // to stop the warning of calling setState of unmounted component
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let firstNameValid = this.state.firstNameValid;
    let middleNameValid = this.state.middleNameValid;
    let lastNameValid = this.state.lastNameValid;
    let emailValid = this.state.emailValid;
    let primaryPhoneValid = this.state.primaryPhoneValid;
    let providerNameValid = this.state.providerNameValid;
    let notifyEmailValid = this.state.notifyEmailValid;
    let notifyPhoneValid = this.state.notifyPhoneValid;

    switch (fieldName) {
      case "firstName":
        firstNameValid = value.trim().length > 0 ? true : false;
        fieldValidationErrors.firstName = firstNameValid
          ? ""
          : enMsg.firstNameRequired;
        break;
      case "providerName":
        providerNameValid = value.trim().length > 0 ? true : false;
        fieldValidationErrors.providerName = providerNameValid
          ? ""
          : "Provider name required";
        break;

      case "lastName":
        lastNameValid = value.trim().length > 0 ? true : false;
        fieldValidationErrors.lastName = lastNameValid
          ? ""
          : enMsg.lastNameRequired;
        break;
      case "middleName":
        if (value.trim().length) {
          middleNameValid = value.length >= 3;
          fieldValidationErrors.middleName = middleNameValid
            ? ""
            : "Please enter min 3 chars.";
        }
        middleNameValid = true;
        break;
      case "email":
        emailValid = value.match(EMAIL_REGEX);
        fieldValidationErrors.email = emailValid ? "" : enMsg.inValidEmail;
        break;
      case "notifyEmail":
        notifyEmailValid = value.match(EMAIL_REGEX);
        fieldValidationErrors.notifyEmail = notifyEmailValid
          ? ""
          : enMsg.inValidEmail;
        break;
      case "primaryPhone":
        let phoneNumber = this.handlephoneNumber(value);
        primaryPhoneValid = phoneNumber.is_valid;
        fieldValidationErrors.primaryPhone = phoneNumber.error;
        if (phoneNumber) {
          if (value.trim().length != 10) {
            primaryPhoneValid = false;
            fieldValidationErrors.primaryPhone =
              "Please enter valid 10 digit US number";
          }
          if (value.trim().includes("+")) {
            primaryPhoneValid = false;
            fieldValidationErrors.primaryPhone =
              "Please enter valid US number without code (eg: +1)";
          }
        }
        break;
      case "notifyPhone":
        let phoneNumberNotify = this.handlephoneNumber(value);
        notifyPhoneValid = phoneNumberNotify.is_valid;
        fieldValidationErrors.notifyPhone = phoneNumberNotify.error;
        if (phoneNumberNotify) {
          if (value.trim().length != 10) {
            notifyPhoneValid = false;
            fieldValidationErrors.notifyPhone =
              "Please enter valid 10 digit US number";
          }
          if (value.trim().includes("+")) {
            notifyPhoneValid = false;
            fieldValidationErrors.notifyPhone =
              "Please enter valid US number without code (eg: +1)";
          }
        }
        break;
      default:
        firstNameValid = true;
        middleNameValid = true;
        lastNameValid = true;
        emailValid = true;
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        firstNameValid: firstNameValid,
        middleNameValid: middleNameValid,
        lastNameValid: lastNameValid,
        emailValid: emailValid,
        notifyEmailValid: notifyEmailValid,
        primaryPhoneValid: primaryPhoneValid,
        notifyPhoneValid: notifyPhoneValid,
        providerNameValid: providerNameValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    return (
      this.state.firstNameValid &&
      this.state.middleNameValid &&
      this.state.lastNameValid &&
      this.state.emailValid &&
      this.state.primaryPhoneValid &&
      this.state.notifyEmailValid &&
      this.state.notifyPhoneValid &&
      this.state.providerNameValid
    );
  }
  componentWillUnmount() {
    const req = null;
    const protocol = process.env.NODE_ENV === "production" ? "https" : "http";
  }
  handleChange(event) {
    this.setState({ middleName: event.target.value });
  }
  handleDateChange = (value) => {
    const name = "dob";
    let dobValid = true;

    if (typeof value == "object" && this.dateUtility.isValid(value)) {
      value = this.dateUtility.format(value, "MM/dd/yyyy");
    } else if (value != null && !this.dateUtility.isValid(value)) {
      dobValid = false;
    }
    // dobValid: dobValid,
    this.setState({
      [name]: value,
      dobValid: dobValid,
    });
  };
  componentDidMount() {
    this.props.reduxLoad(false);
    this.fetchUsrInfo();
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
  }
  async fetchUsrInfo() {
    const {
      email,
      firstName,
      middleName,
      lastName,
      phoneNumber,
      phoneCode,
      imageUrl,
      login,
      provider,
      smsNotify,
      emailNotify,
      forEmailNotify,
      forSmsPhoneNumber,
    } = await userService.fetchProfile();
    let phoneCodeLocal = phoneCode ? phoneCode : "+1";
    let providerNameLocal =
      provider && provider.providerName ? provider.providerName : "";
    this.setState({
      email: email ? email : "",
      firstName: firstName ? firstName : "",
      middleName: middleName ? middleName : "",
      lastName: lastName ? lastName : "",
      primaryPhone: phoneNumber ? phoneNumber : "",
      profileImage:
        imageUrl && imageUrl != "string" ? imageUrl : DEFAULT_PROFILE_IMG,
      loginType: login ? login : "",
      providerName: providerNameLocal ? providerNameLocal : "",
      isMobileNotification: smsNotify ? smsNotify : false,
      isEmailNotification: emailNotify ? emailNotify : false,
      notifyEmail: forEmailNotify ? forEmailNotify : email,
      notifyPhone: forSmsPhoneNumber ? forSmsPhoneNumber : phoneNumber,
    });
    if (email) this.validateField("email", email);
    if (firstName) this.validateField("firstName", firstName);
    if (middleName) this.validateField("middleName", middleName);
    if (lastName) this.validateField("lastName", lastName);
    if (phoneNumber)
      this.validateField("primaryPhone", this.state.primaryPhone);
    if (providerNameLocal)
      this.validateField("providerName", this.state.providerName);

    this.props.reduxLoad(true);
  }

  async updateProfileImage(event) {
    let showNotification = {};
    let apiSuccess = true;
    if (this.profileImageBlob) {
      try {
        const data = new FormData();
        data.append("file", this.profileImageBlob);
        data.append("filename", this.filename);

        const response = await fetch(apiPath.profileImage, {
          method: "POST",
          headers: authHeaderMIMETYPE(),
          body: data,
        })
          .then((response) => {
            if (response.status === INVALID_DATA_POST) {
            } else if (response.status === STATUS_CREATED) {
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.profileUpdated,
                type: "success",
              };
            } else {
              showNotification = {
                title: enMsg.failUpdateTitle,
                message: response.errorMessage
                  ? response.errorMessage
                  : enMsg.networkFailedError,
                type: "danger",
              };
              apiSuccess = false; // set false on error
            }

            return response.json();
          })
          .then((response) => {
            if (response.errorCode) {
              showNotification = {
                title: enMsg.failUpdateTitle,
                message: response.errorMessage
                  ? response.errorMessage
                  : enMsg.invalidData400,
                type: "danger",
              };
              apiSuccess = false; // set false on error
            } else if (response.imageRef) {
              const userDetail = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                profileImage: response.imageRef,
              };
              userService.updateUserInfo(
                this.state.firstName,
                this.state.lastName,
                this.state.profileImage,
                data
              );
              this.props.updateUserInfo(userDetail);
            }
          })
          .catch((error) => {
            showNotification = {
              title: enMsg.failUpdateTitle,
              message: enMsg.networkFailedError,
              type: "danger",
            };
            apiSuccess = false; // set false on error
            return response;
          });

        // throw new Error(error);
      } catch (error) {
        showNotification = {
          title: enMsg.failUpdateTitle,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      }
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type,
        };
        notifiaciton = Object.assign(NotificationOptions, notifiaciton);
        this.notificationID = store.addNotification(notifiaciton);
      }
      userService.showNotification(showNotification);
      //window.location.reload();
    }

    return apiSuccess;
  }

  async handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();
    // first call updateProfileImage function to upload profile image, if result is success then
    let showNotification = {};
    // let result = await this.updateProfileImage();
    let result = true;
    if (this.state.loginType && this.state.loginType != "") {
      /* use this function for live API */
      let phoneNumber = this.state.primaryPhone;
      // var phoneNumberLocal = phoneNumber.trim().split("+1");
      let profileImage = this.state.isProfileImage
        ? this.state.profileImage
        : null;
      const data = {
        email: this.state.email,
        login: this.state.loginType,
        firstName: this.state.firstName,
        middleName: this.state.middleName,
        lastName: this.state.lastName,
        phoneNumber: phoneNumber,
        phoneCode: "+1",
        imageUrl: profileImage,
        providerName: this.state.providerName,
        forSmsPhoneCode: "+1",
        forSmsPhoneNumber: this.state.notifyPhone,
        smsNotify: this.state.isMobileNotification,
        forEmailNotify: this.state.notifyEmail,
        emailNotify: this.state.isEmailNotification,
      };
      // this.setState({ loading: false });
      // return;
      try {
        const response = await fetch(apiPath.postProfile, {
          method: "POST",
          headers: generalAuthTokenHeader(),
          body: JSON.stringify(data),
        })
          .then((response) => {
            const contentType = response.headers.get("content-type");
            if (response.status === INVALID_DATA_POST) {
              if (
                contentType &&
                contentType.indexOf("application/json") !== -1
              ) {
                return response.json();
              } else {
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger",
                };
              }
            } else if (response.ok || response.status === OK_SUCCESS_STATUS) {
              // const { token } = await response.json();
              this.props.reduxLoad(false);
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.profileUpdated,
                type: "success",
              };

              this.setState({ reduxLoadFlag: false });
              let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
              userInfo.firstName = this.state.firstName;
              userInfo.lastName = this.state.lastName;
              userInfo.imageRef = this.state.profileImage;

              userInfo.provider.providerName = this.state.providerName;

              this.props.addUSerUInfo(userInfo);
              this.props.reduxLoad(true);
              localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
              window.location.replace(
                basePath + providerBaseRoutes.profile.path + "?tab=billing"
              );
            } else {
              if (
                contentType &&
                contentType.indexOf("application/json") !== -1
              ) {
                return response.json();
              } else {
                showNotification = {
                  title: enMsg.failUpdateTitle,
                  message: response.errorMessage
                    ? response.errorMessage
                    : enMsg.networkFailedError,
                  type: "danger",
                };
              }
              let error = new Error(response.statusText);
            }
            return response.text();
          })
          .then((response) => {})
          .catch((error) => {
            showNotification = {
              title: enMsg.failUpdateTitle,
              message: enMsg.networkFailedError,
              type: "danger",
            };
            return response;
          });
      } catch (error) {
        showNotification = {
          title: enMsg.failUpdateTitle,
          message: enMsg.networkFailedError,
          type: "danger",
        };
      }
    } else {
      showNotification = {
        title: "Unauthorize Access",
        message: "You are not allowed to access this page.",
        type: "warning",
      };
    }
    userService.showNotification(showNotification);
    this.setState({ loading: false });
  }
  handleChange(valueF, nameF) {
    if (nameF == "gender") {
      this.setState({ gender: valueF });
    } else {
      this.setState({ role: valueF });
    }
    const name = nameF;
    const value = valueF;

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleOpen() {
    setOpen(true);
  }

  handleClose() {
    setOpen(false);
  }
  handleNotification(notiType) {
    if (notiType == "mobile") {
      let isMobileNotification = this.state.isMobileNotification ? false : true;
      this.setState({
        isMobileNotification: isMobileNotification,
      });
    } else {
      let isEmailNotification = this.state.isEmailNotification ? false : true;
      this.setState({
        isEmailNotification: isEmailNotification,
      });
    }
  }
  render() {
    const {
      isOpen,
      role,
      hasError,
      isMobileNotification,
      isEmailNotification,
    } = this.state;
    const { classes } = this.props;
    const stylead = { borderBottom: "" };
    const user_image = "user-image";
    const styleuser = {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
    };
    return (
      <div>
        <Box>
          {/* <NotificationContainer/> */}
          <div className="tab-heading tab-heading-text">User Details</div>

          <div className="service-info-text">
            <p>
              Please review and update your information. If you wish to receive
              SMS and email notifications, be sure you enter and enable the
              applicable check box.
            </p>
          </div>
          <GridContainer justify={"center"}>
            <GridItem md={12} xs={12}>
              {/* <div className="green-header">Login</div> */}
              <div className="profile-content">
                <div className="profile">
                  <form onSubmit={this.handleSubmit}>
                    <Box display="flex" justifyContent="flex-start">
                      <Box pr={2} width={1 / 3}>
                        <FormGroup>
                          <CustomInput
                            labelText="First Name"
                            labelProps={{ className: "required-label" }}
                            id="comp-name"
                            inputProps={{
                              type: "text",
                              name: "firstName",
                              autoComplete: "off",
                              // required: true,
                              value: this.state.firstName,
                              inputRef: (input) => (this.firstName = input),
                              onChange: (event) => this.handleUserInput(event),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <FormErrors
                            show={!this.state.firstNameValid}
                            formErrors={this.state.formErrors}
                            fieldName="firstName"
                          />
                        </FormGroup>
                      </Box>
                      <Box pl={2} pr={2} width={1 / 3}>
                        <FormGroup>
                          <CustomInput
                            labelText="Middle Name"
                            // labelProps={{ className: "required-label" }}
                            id="comp-name"
                            inputProps={{
                              type: "text",
                              name: "middleName",
                              autoComplete: "off",
                              // required: true,
                              value: this.state.middleName,
                              inputRef: (input) => (this.middleName = input),
                              onChange: (event) => this.handleUserInput(event),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <FormErrors
                            show={!this.state.middleNameValid}
                            formErrors={this.state.formErrors}
                            fieldName="middleName"
                          />
                        </FormGroup>
                      </Box>
                      <Box pl={2} width={1 / 3}>
                        <FormGroup>
                          {/* <InputLabel>Password</InputLabel> */}
                          <CustomInput
                            color="primary"
                            labelText="Last Name"
                            labelProps={{ className: "required-label" }}
                            id="city"
                            inputProps={{
                              type: "text",
                              name: "lastName",
                              autoComplete: "off",
                              required: true,
                              value: this.state.lastName,
                              inputRef: (input) => (this.lastName = input),
                              onChange: (event) => this.handleUserInput(event),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <FormErrors
                            show={!this.state.lastNameValid}
                            formErrors={this.state.formErrors}
                            fieldName="lastName"
                          />
                        </FormGroup>
                      </Box>
                    </Box>

                    <Box display="flex" justifyContent="flex-start">
                      <Box pr={2} width={1 / 3}>
                        <FormGroup>
                          <CustomInput
                            labelText="Provider Name"
                            labelProps={{
                              className: "required-label readonly",
                            }}
                            id="comp-name"
                            inputProps={{
                              type: "text",
                              name: "providerName",
                              autoComplete: "off",
                              readOnly: true,
                              // required: true,
                              value: this.state.providerName,
                              inputRef: (input) => (this.providerName = input),
                              onChange: (event) => this.handleUserInput(event),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <FormErrors
                            show={!this.state.providerNameValid}
                            formErrors={this.state.formErrors}
                            fieldName="providerName"
                          />
                        </FormGroup>
                      </Box>
                      <Box pl={2} pr={2} width={1 / 3}>
                        <FormGroup className={"profile-email"}>
                          {/* <InputLabel>Password</InputLabel> */}
                          <CustomInput
                            labelText="Email"
                            labelProps={{
                              className: "required-label readonly",
                            }}
                            id="email"
                            inputProps={{
                              type: "email",
                              name: "email",
                              readOnly: true,
                              autoComplete: "off",
                              required: true,
                              value: this.state.email,
                              inputRef: (input) => (this.email = input),
                              onChange: (event) => this.handleUserInput(event),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <FormErrors
                            show={!this.state.emailValid}
                            formErrors={this.state.formErrors}
                            fieldName="email"
                          />
                        </FormGroup>
                      </Box>
                      <Box pl={2} width={1 / 3}>
                        <FormGroup>
                          <CustomInput
                            labelText="Primary  Phone"
                            labelProps={{ className: "required-label" }}
                            id="primaryPhone"
                            inputProps={{
                              type: "text",
                              name: "primaryPhone",
                              autoComplete: "off",
                              value: this.state.primaryPhone,
                              onChange: (event) => this.handleUserInput(event),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <FormErrors
                            show={!this.state.primaryPhoneValid}
                            formErrors={this.state.formErrors}
                            fieldName="primaryPhone"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-start">
                      <Box pr={2} width={1 / 2}>
                        <FormGroup
                          aria-label="position"
                          row
                          className="check-input"
                        >
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={isMobileNotification ? true : false}
                                color="primary"
                                className="check-input"
                                onClick={() =>
                                  this.handleNotification("mobile")
                                }
                              />
                            }
                            label="Do you want to recieve the SMS notifications ?"
                            labelPlacement="end"
                          />
                        </FormGroup>
                        <FormGroup>
                          <CustomInput
                            labelText="Primary Phone"
                            labelProps={{ className: "required-label" }}
                            id="notifyPhone"
                            inputProps={{
                              type: "text",
                              name: "notifyPhone",
                              // disabled: true,
                              autoComplete: "off",
                              value: this.state.notifyPhone,
                              onChange: (event) => this.handleUserInput(event),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <FormErrors
                            show={!this.state.notifyPhoneValid}
                            formErrors={this.state.formErrors}
                            fieldName="notifyPhone"
                          />
                        </FormGroup>
                      </Box>
                      <Box pl={2} pr={2} width={1 / 2}>
                        <FormGroup
                          aria-label="position"
                          row
                          className="check-input"
                        >
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={isEmailNotification ? true : false}
                                color="primary"
                                className="check-input"
                                onClick={() => this.handleNotification("email")}
                              />
                            }
                            label="Do you want to recieve the email notifications ?"
                            labelPlacement="end"
                          />
                        </FormGroup>
                        <FormGroup className={"profile-email"}>
                          {/* <InputLabel>Password</InputLabel> */}
                          <CustomInput
                            labelText="Email"
                            labelProps={{ className: "required-label" }}
                            id="email"
                            inputProps={{
                              type: "email",
                              name: "notifyEmail",
                              // readOnly: true,
                              // disabled: true,
                              autoComplete: "off",
                              required: true,
                              value: this.state.notifyEmail,
                              // inputRef: input => (this.email = input),
                              onChange: (event) => this.handleUserInput(event),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <FormErrors
                            show={!this.state.notifyEmailValid}
                            formErrors={this.state.formErrors}
                            fieldName="notifyEmail"
                          />
                        </FormGroup>
                      </Box>
                    </Box>

                    <Box display="flex" justifyContent="flex-start">
                      <Box width={1}>
                        <div
                          onClick={this.handleChangePasswordDialog}
                          className="pointer change-pass-text text-select"
                        >
                          change password
                        </div>
                      </Box>
                    </Box>

                    <Box width={1} style={{ display: "none" }}>
                      <FormGroup>
                        <CustomInput
                          labelText="Current Password"
                          id="curr_pass"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </FormGroup>
                    </Box>
                    <Box width={1} style={{ display: "none" }}>
                      <FormGroup>
                        <CustomInput
                          labelText="New Password"
                          id="new_pass"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </FormGroup>
                    </Box>
                    <Box width={1} style={{ display: "none" }}>
                      <FormGroup>
                        <CustomInput
                          labelText="Confirm Passowrd"
                          id="conf_pass"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </FormGroup>
                    </Box>

                    <div className="form-button-cover form-actions">
                      <Button
                        className={
                          this.state.loading ? "buttonSuccess btn1" : "btn1"
                        }
                        disabled={this.state.loading || !this.validateForm()}
                        type="submit"
                      >
                        Save And Next
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            className="buttonProgress"
                          />
                        )}
                      </Button>
                      <Button className="back-btn btn2" type="button">
                        <Link
                          underline="none"
                          to={providerBaseRoutes.dashboard.path}
                        >
                          Cancel
                        </Link>
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </Box>
        <ChangePassword
          id="change-pssword-dialog"
          keepMounted
          open={this.state.openChangePasswordDialog}
          handleChangePasswordDialog={this.handleChangePasswordDialog}
          onClose={this.handleClose}
          value={""}
          allowCloseModal={true}
        />
      </div>
    );
  }
}

ProfileClass.propTypes = {
  classes: PropTypes.object,
};
const Profile = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileClass);
export default withStyles(styles)(Profile);
