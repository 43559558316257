import {
  ZIP_CODE_LENGTH,
  OPT_LENGTH,
  CREDIT_CARD_MAX_YEAR,
} from "__helpers/constants";

const enMsg = {
  loginFailedTitle: "Login Failed",
  signUpFailedTitle: "Signup Failed",
  successSignUpTitle: "Signup successfully",
  connectionFailed: "Connection Failed",
  networkFailedError: "Could not connect, there is some network issue.",
  inValidCredentials: "ID/Email or Password is incorrect",
  inValidEmail: "Please enter a valid email address.",
  inValidUser: "Please enter a valid user ID/Email address.",
  shortPassword: "Password is too short. Minimum 10 digit, maximum 15 digits.",
  longPassword: "Password is too long, Minimum 10 digit, maximum 15 digits.",
  successSignUp: "Account created successfully, please login to continue.",
  passwordPatternValidation:
    "Use one capital letter, one small letter, one number and one special characters (!, @, #, $, %).",
  confirmPasswordValidation:
    "Confirm password is not matching with new password",
  confirmNewPasswordValidation:
    "Confirm password is not matching with new password",
  lastNameRequired: "Please enter the Last Name",
  firstNameRequired: "Please enter the First Name",
  zipCodeMinLengthError: `Please enter ${ZIP_CODE_LENGTH} digits zipcode.`,
  invalidZipCode: "Please enter valid zipcode",
  phoneInvalidCountryCode: "Please add country code i.e +1",
  phoneTooLong: "Phone number is too long",
  phoneTooShort: "Phone number is too short",
  phoneUnknown: "Invalid phone number",
  fetchCampaignFailed: "Fetch Campaign Failed",
  fetchAdNetworkFailed: "Fetch Adnetwork Failed",
  fetchCampaignSuccess: "Fetch Campaign success",
  fetchAdNetworkSuccess: "Fetch Adnetwork success",
  fetchvendorsFailed: "Fetch Vendors Failed",
  fetchAssetsFailed: "Fetch Assets Failed",
  invalidData400: "Something went wrong",
  companyNameRequired: "Please enter the Company Name.",
  descriptionRequired: "Please enter the Description.",
  campaignStatusRequried: "Please select campaign status.",
  adNetworkRequired: "Please select AdNetwork.",
  successTitle: "Success",
  failedTitle: "Failed",
  campaignCreated: "Campaign created successfully. ",
  campaignUpdated: "Campaign updated successfully. ",
  advertisementCreated: "Advertisement created successfully. ",
  advertisementUpdated: "Advertisement updated successfully. ",
  assetsCreated: "Assets created successfully. ",
  assetsUpdated: "Assets updated successfully. ",
  invalidData: "Given data is not valid",
  addCamaignFaild: "Campaign Failed",
  fetchAdvertisementDetailFailed: "Advertisement is not exist",
  OTPLength: `OTP must be ${OPT_LENGTH} digit long`,
  successResetPasswordTitle: "Password Reset",
  successResetPassword: "Password has reset successfully",
  failedResetPasswordTitle: "Password Reset",
  failedResetPassword: "Password reset failed",
  optSent: "OTP Sent",
  emailNotExist: "Given email address is not registered",
  requestFailed: "Request Failed",
  fetchUserDatatitle: "Fetch user profile data Failed",
  incompleteProfile: "Incomplete Profile",
  roleRequired: "Please select role",
  invalidDate:
    "Invalid date format, please enter a valid Date in MM/DD/YYYY format.",
  failUpdateTitle: "Update Profile Failed",
  profileUpdated: "Profile updated successfully.",
  addressRequired: "Please enter the Address.",
  cityRequired: "Please enter the City.",
  stateRequired: "Please enter the State.",
  businessNameeRequired: "Please enter the Business Type.",
  createAdnetworkFailed: "Create Adnetwork Failed.",
  adnetworkCreate: "Adnetwork created successfully. ",
  allowedProfileImageSize: "Maximum 5 MB Image is allowed.",
  allowedFaceSheetSize: "Maximum 5 MB File is allowed.",
  allowedProfileImageType: "Only png, jpeg & jpg file type allowed.",
  allowedFaceSheetType: "Only png, jpeg, jpg & pdf file type allowed.",
  passwordRequired: "Please enter password.",
  campaginDeleted: "Capmagin has deleted successfully.",
  vendorDeleted: "Vendor has deleted successfully.",
  assetDeleted: "Asset has deleted successfully.",
  adNetworkIdRequired: "Please select AdNetwork.",
  vendorNameRequired: "Please enter the Vendor Name.",
  infoUrlRequired: "Please enter the Info Url.",
  invalidinfoUrl: "Please enter valid url.",
  vendorCreated: "Vendor successfully created.",
  vendorUpdated: "Vendor successfully updated.",
  campaignIdRequried: "Please select Campaign.",
  advertiseNameRequired: "Please enter Advertisement Name.",
  // descriptionRequired: "Please enter Description.",
  categoriesRequired: "Please select Categories.",
  brandsRequired: "Please select Brands.",
  invalidPurchaseUrl: "Please enter valid url.",
  adMediumRequired: "Please select Ad Medium.",
  startDateRequired: "Please select Start Date.",
  expirationDateRequired: "Please select Expiration Date.",
  advertisementStatusRequired: "Please select Status.",
  advertisementTypeRequired: "Please select Advertisement Type.",
  savingsTypeRequired: "Please select Savings Type",
  savingsRequired: "Please enter savings",
  redemptionTypeRequired: "Please select Redemption Type",
  redemptionCodeRequired: "Please enter Redemption Code",
  assetsRequired: "Please select assets",

  adNetworkIdRequiredAssets: "Please select AdNetwork.",
  fileNameRequiredAssets: "Please enter File Name.",
  descriptionRequiredAssets: "Please enter asset description.",
  assetUrlRequiredAssets: "Please enter the Asset Url.",
  invalidassetUrlAssets: "Please enter valid url.",
  assetTypeRequiredAssets: "Please select Assets Type.",
  displayTypeRequiredAssets: "Please select Display Type.",
  nameRequiredAssets: "Please enter Name.",
  noAssetsFound: "No Assets Found",
  noVendorFound: "No Vendor Found",
  fetchingData: "Loading..",
  noDataFound: "No Data found.",
  successPasswordChange: "Password has changed successfully.",
  attachVendorsSuccess: "Advertisement updated with Vendors successfully",
  attachAssetsSuccess: "Advertisement updated with Assets successfully",
  resetLinkOTPsend: "Reset Password Mail/OPT sent successfully.",
  adtMinExpiryDateMessage: "Expiration Date must not be before Start Date",
  adtMaxStartDateMessage: "Start Date must not be after Expiration Date",
  dobMaxDate: "Date of birth must not be from future",
  estMinDate: "Estimate Date Must be Future Date",
  alertForChangeStatus: "Are you sure want to change the status?",
  alertForDeleteCampaign: "Are you sure want to delete this campaign?",
  alertForDeleteAsset: "Are you sure want to delete this asset?",
  alertForDeleteAdNetwork: "Are you sure want to delete this adNetwork?",
  alertForDeleteVendor: "Are you sure want to delete this vendor?",
  campaginStatusUpdated: "Status has changed successfully.",
  sessionExpireTitle: "Logged Out",
  sessionExpire: "Your session has been expired, please login.",
  selectAdnetworkTitle: "Select AdNetwork",
  selectAdnetwork: "Please select adnetwork to load data.",

  // User Profile
  profileFetchErrorTitle: "Profile Data Failed",
  profileFetchErrorMsg: "Unable to fetch profile data.",

  // Forgot Password success
  forgotPasswordTitle: "Forgot Password",
  forgotPasswordMsg: "Reset link sent to registred mail id.",

  // Forgot Password failed
  forgotPasswordTitleFail: "Forgot Password",
  forgotPasswordMsgFail: "Entered email is not found.",

  // Password not match..set new password
  oldPasswordTitleFail: "Change Password",
  oldPasswordMsgFail: "Entered old password is invalid.",

  // FACILITY FORM ERROR MESSAGES
  facilityNameRequiredErrorMsg: "Facility name is required",
  addressRequiredErrorMsg: "Address is required",
  primaryPhoneRequiredMsg: "Primary phone number is required",

  userNameRequiredMsg: "Username is required",
  firstNameRequiredMsg: "First name is required",
  lastNameRequiredMsg: "Last name is required",
  emailRequiredMsg: "Email is required",
  phoneNumbeRequiredMsg: "Phone number is required",
  facilityDropDownRequiredMsg: "Facility is required",
  hospitalDropDownRequiredMsg: "Hospital is required",
  accessDropdownRequiredMsg: "Access is required",
  statusRequiredMsg: "Status is required",

  tokenExpiredTitle: "Session expired",
  tokenExpiredMessage: "Session has been expired",

  providerNameRequiredMsg: "Provider name is required",
  invalidNumber: "Please enter valid number",

  badResponseFromServer: "Bad response from server",
  clientSideError: "Something went wrong.Please try after sometime.",

  phoneNumberDigit: "Phone number should have only 10 digits",
  providerRequiredMsg: "Provider is required",

  //provider
  addressStreetRequiredMsg: "Address street is required",
  titleRequiredMsg: "Title street is required",

  // add patient form error
  middleNameRequiredMsg: "Middle name invalid",
  genderRequiredMsg: "Gender required",
  insuranceRequiredMsg: "Insurance required",
  hospitalRequiredMsg: "Hospital required",
  categoryRequiredMsg: "Categories required",
  estDateRequiredMsg: "Estimate date is required",
  dobRequiredMsg: "Date of Birth is required",
  facesheetRequiredMsg: "Invalid facesheet",
  patientImageRequiredMsg: "Invalid patient image",

  updatePatientFailedTitle: "Update Patient Failed",
  updatePatientFailedDesc: "Unable to update patient at this moment.",
  commenLengthMsg: "Comment cannot exceed 255 characters.",

  categoryServiceReq: "Please enter rate",
  categoryFailedTitle: "Category Service Failled",
  categoryFailedDes: "Unable to save categories at this moment",
  categorySuccessTitle: "Category Service Success",
  categorySuccessDes: "Categories saved successfully.",

  criteriaFailedTitle: "Patient Criteria Failed",
  criteriaFailedDes: "Unable to save patient criteria at this moment",
  criteriaSuccessTitle: "Patient Criteria Success",
  criteriaSuccessDes: "Patient Criteria saved successfully.",

  reasonRequiredMsg: "Reason is required.",

  //tasks
  taskNameRequiredMsg: "Task name is required",
  priorityRequiredMsg: "Task priority is required",
  changeStatusRequiredMsg: "Task status is required",
  dueDateRequiredMsg: "Task due date invalid",
  taskMinDate: "Task Date Must be Future Date",
  taskUpdateTitle: "Task Update",
  taskUpdateDes: "Task updated successfully",
  taskCreateTitle: "Task Create",
  taskCreateDes: "Task created successfully",
  taskFailedTitle: "Task Failed",
  taskFailedDes: "Unable to save data",

  //card validation
  cardNumberRequiredMsg: "Card number required",
  cardNumberInvalidMsg: "Card number invalid",
  cardMMRequiredMsg: "Card month required",
  cardYearRequiredMsg: "Card year required",
  cardCvvRequiredMsg: "CVV required",
  cardCvvInvalidMsg: "CVV Invalid",
  cardDateRequiredMsg: "Card expiration date required",
  cardDateInvalidMsg: "Please enter a valid expiration date in MM/YYYY",
  cardDateMaxMsg:
    "Card expiration year should be lower than " +
    (new Date().getFullYear() + CREDIT_CARD_MAX_YEAR),
  cardDateExpiredMsg: "Card date expired.",

  cardSaveFailedTitle: "Credit Card Failed",
  cardSaveFailedDes: "Card information not saved.",
  cardSaveSuccessDes: "Card information saved successfully.",

  tcRequired: "Please accpet Terms and Conditions to proceed.",
  baaRequired: "Please accept Business Associate Agreement.",

  termsServiceTitle: "Terms services",
  tcFailedTitle: "Terms and Conditions",
  baaFailedTitle: "Business Associate Agreement",
  tcFailedDes: "Please accpet Terms and Conditions to proceed.",
  baaFailedDes: "Please accept Business Associate Agreement to proceed.",

  patientSummaryRequiredMsg: "Patient executive required",

  maxDate: "Date must not be from future",
  minDate: "Date must not be from past,",
  freeMbAllowanceRequiredMsg: "Free Mb Allowance is required",
  maxAllowance: "Free Mb Allowance  must not be less then 1000 MB",
  ReferralReport: "Referral Reports",
};
export default enMsg;
