/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  DialogTitle,
  IconButton,
  TextField,
  MenuItem,
  InputLabel,
  Slide,
  Box,
  FormControl,
  Checkbox,
  Chip,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";
import PhoneNumber from "awesome-phonenumber";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  genderType,
  EMAIL_REGEX,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ZIPCODE_REGEX,
  ZIP_CODE_LENGTH,
  MIN_AGE_YEARS,
  ALLOWED_IMAGE_EXTENTION,
  DEFAULT_PROFILE_IMG,
  DIGIT_ONLY,
  PHONE_NO_INVALID_LENGTH,
  OK_SUCCESS_STATUS,
  STATUS_CREATED,
  INVALID_DATA_POST,
  SECURITY_ERROR,
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
  ONLY_AlPHABETS_REGEX,
} from "__helpers/constants";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import baseRoutes from "base-routes";
import ChangePassword from "./ChangePassword";
import fileExtension from "file-extension";
import { providerGeneralAuthTokenHeader } from "__helpers/auth-header";

import { withRouter } from "react-router";

import { connect } from "react-redux";
import { addUSerUInfo, reduxLoad } from "js/actions";
import { USER_INFO } from "__helpers/constants";
import { dpBaseRoutes } from "base-routes";
import { basePath } from "base-routes";
import backArrow from "assets/img/icons/arrow-back.svg";
import BasicProfile from "./BasicProfile";
import InDevelopment from "./InDevlopment";
import commingSoonImg from "assets/img/comming_soon.png";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { MAX_INPUT_LENGTH_RATE } from "__helpers/constants";
import { enGB } from "date-fns/esm/locale";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroller";
import { providerBaseRoutes } from "base-routes";
import DateFnsUtils from "@date-io/date-fns";
import Icon from "@material-ui/core/Icon";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { formatFileSize } from "__helpers/util";
import Button from "components/CustomButtons/Button";
import Typography from "views/lock/Typography/Typography";

function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: (addUSerUInfoVal) => dispatch(addUSerUInfo(addUSerUInfoVal)),
    addTimeStamp: (addTimeStampVal) => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    getPatientLanNInsListRedux: state.getPatientLanNInsList,
    hospitalListRedux: state.hospitalList,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const styles = (theme) => ({});
const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Id",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Title",
  },
  {
    id: "addressStreet",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Address",
  },
  {
    id: "latitude",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Latitude",
  },
  {
    id: "longitude",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Longitude",
  },

  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Action",
  },
];
class AddressClass extends React.Component {
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    this.dateUtility = new DateFnsUtils();
    this.addressStreetInputRef = React.createRef();
    this.state = {
      loading: false,
      providerAddress: [],
      spinner: spinner,
      perPage: DEFAULT_PER_PAGE_RECORDS,
      total: "",
      currentPage: 0,
      orderBy: "id",
      formErrors: {
        addressStreet: "",
        latitude: "",
        longitude: "",
      },
      title: "",
      latitude: "",
      longitude: "",
      state: "",
      city: "",
      addressStreet: "",
      addressSuite: "",
      zipcode: "",
      dataId: "",
      titleValid: false,
      addressStreetValid: false,
      latitudeValid: false,
      longitudeValid: false,
      deletemodalStatus: false,
      deleteItemId: "",
      selectedAddress: "",

    };
    this.fetchproviderAddress = this.fetchproviderAddress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
  }
  componentDidMount() {
    this.fetchproviderAddress();
  }
  handleChangePage = (event, newPage) => {
    this.setState(
      {
        currentPage: newPage,
      },
      () => this.fetchproviderAddress()
    );
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        perPage: parseInt(event.target.value),
        currentPage: 0,
      },
      () => this.fetchproviderAddress(this.state.searchKeyword)
    );
  };
  tableSortHandler = (property) => (event) => {
    this.setState(
      {
        orderBy: property,
        direction: !this.state.direction,
      },
      () => this.fetchproviderAddress(this.state.searchKeyword)
    );
  };
  //google address
  handlePlaceSelection = (autocomplete) => {
    // Get the selected place details from the Autocomplete object
    const selectedPlace = autocomplete.getPlace();

    if (selectedPlace && selectedPlace.formatted_address) {
      this.setState({
        city: "",
        state: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        
      });
      // Extract the address components from the selected place
      const address = selectedPlace.formatted_address;
      const city = selectedPlace.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const state = selectedPlace.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.short_name;
      const zipcode = selectedPlace.address_components.find((component) =>
        component.types.includes("postal_code")
      )?.long_name;
      const latitude = selectedPlace.geometry.location.lat();
      const longitude = selectedPlace.geometry.location.lng();
      // console.log(selectedPlace.geometry.location);
      // Update the state with the selected address components

      this.setState({
        addressStreet: address,
        city: city,
        state: state,
        zipcode: zipcode,
        latitude: latitude,
        longitude: longitude,
        selectedAddress:address,
      });

      if (city) this.validateField("city", city);
      if (state) this.validateField("state", state);
      if (zipcode) this.validateField("zipcode", zipcode);
      if (latitude) this.validateField("latitude", latitude);
      if (longitude) this.validateField("longitude", longitude);
    }
  };
  getAddress = () => {
    setTimeout(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("addressMap")
      );
      autocomplete.addListener("place_changed", () => {
        this.handlePlaceSelection(autocomplete);
      });
    }, 2000);
  };
  //form
  validateForm() {
    console.log(this.state.addressStreetValid, "cc");
    return (
      this.state.titleValid &&
      this.state.addressStreetValid &&
      this.state.addressSuiteValid &&
      this.state.cityValid &&
      this.state.stateValid &&
      this.state.zipcodeValid &&
      this.state.latitudeValid &&
      this.state.longitude
    );
  }
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let addressStreetValid = this.state.addressStreetValid;
    let titleValid = this.state.titleValid;
    let addressSuiteValid = this.state.addressSuiteValid;
    let cityValid = this.state.cityValid;
    let stateValid = this.state.stateValid;
    let zipcodeValid = this.state.zipcodeValid;
    let latitudeValid = this.state.latitudeValid;
    let longitudeValid = this.state.longitudeValid;
    switch (fieldName) {
      case "title":
        let pNameErrMsgs = "";
        if (fieldValue.trim().length == 0) {
          titleValid = false;
          pNameErrMsgs = enMsg.titleRequiredMsg;
        } else if (fieldValue.trim().length > 100) {
          titleValid = false;
          pNameErrMsgs = "Title cannot exceed 100 characters.";
        } else {
          titleValid = true;
          pNameErrMsgs = "";
        }
        fieldValidationErrors.title = titleValid ? "" : pNameErrMsgs;
        break;
      case "addressStreet":
        let pNameErrMsg = "";
        if (fieldValue.trim().length == 0) {
          addressStreetValid = false;
          pNameErrMsg = enMsg.addressStreetRequiredMsg;
        } else if (fieldValue.trim().length > 100) {
          addressStreetValid = false;
          pNameErrMsg = "Address cannot exceed 100 characters.";
        } else {
          addressStreetValid = true;
          pNameErrMsg = "";
        }
        fieldValidationErrors.addressStreet = addressStreetValid
          ? ""
          : pNameErrMsg;
        break;
      case "addressSuite":
        if (fieldValue.trim().length == 0) {
          addressSuiteValid = true;
          fieldValidationErrors.addressSuite = "";
        } else {
          addressSuiteValid = true;
          fieldValidationErrors.addressSuite = "";
        }
        break;
      case "latitude":
        latitudeValid = fieldValue && fieldValue != "" ? true : false;
        fieldValidationErrors.latitude = !latitudeValid
          ? "Latitude is required"
          : "";
        break;
      case "longitude":
        longitudeValid = fieldValue && fieldValue != "" ? true : false;
        fieldValidationErrors.longitude = !longitudeValid
          ? "Longitude is required"
          : "";
        break;
      case "city":
        if (fieldValue == "") {
          cityValid = true;
          fieldValidationErrors.city = "";
        } else {
          cityValid = true;
          fieldValidationErrors.city = "";
        }

        break;

      case "state":
        if (fieldValue == "") {
          stateValid = true;
          fieldValidationErrors.state = "";
        } else {
          stateValid = true;
          fieldValidationErrors.state = "";
        }
        break;

      case "zipcode":
        if (fieldValue == "") {
          zipcodeValid = true;
          fieldValidationErrors.zipcode = "";
        } else {
          zipcodeValid = true;
          fieldValidationErrors.zipcode = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        titleValid: titleValid,
        addressStreetValid: addressStreetValid,
        latitudeValid: latitudeValid,
        longitudeValid: longitudeValid,
        cityValid: cityValid,
        addressSuiteValid: addressSuiteValid,
        stateValid: stateValid,
        zipcodeValid: zipcodeValid,
      },
      this.validateForm
    );
  };
  handleChangeInput = (event, id = "") => {
    const name = event.target.name;
    let value = event.target.value;
    console.log("xxxx-", event, id);

    this.setState(
      {
        [name]: value,
        selectedAddress:""
      },
      () => {
        this.validateField(name, value);
       
      }
    );
  };
  handleChangeInputOther = (event, id = "") => {
    const name = event.target.name;
    let value = event.target.value;
    console.log("xxxx-", event, id);

    this.setState(
      {
        [name]: value,
       
      },
      () => {
        this.validateField(name, value);
       
      }
    );
  };
  modalOpen = (dataId, isView) => {
    this.getAddress();
    if (!isNaN(dataId)) {
      this.state.providerAddress.map((address) => {
        if (address.id == dataId) {
          this.setState({
            // facility: provider.facility.facilityId,
            addressStreet: address.addressStreet ? address.addressStreet : "",
            addressSuite: address.addressSuite ? address.addressSuite : "",
            latitude: address.latitude ? address.latitude : "",
            longitude: address.longitude ? address.longitude : "",
            state: address.state ? address.state : "",
            city: address.city ? address.city : "",
            zipcode: address.zipcode ? address.zipcode : "",
            title: address.title ? address.title : "",
            dataId: dataId,
            addressStreetValid: true,
            titleValid: true,
            latitudeValid: true,
            longitudeValid: true,
            stateValid: true,
            cityValid: true,
            addressSuiteValid: true,
            zipcodeValid: true,
            
          });
        }
      });
    } else {
      this.setState({
        addressStreet: "",
        latitude: "",
        longitude: "",
        state: "",
        city: "",
        addressSuite: "",
        zipcode: "",
        addressStreetValid: false,
        titleValid: false,
        latitudeValid: false,
        longitudeValid: false,
        stateValid: true,
        cityValid: true,
        addressSuiteValid: true,
        zipcodeValid: true,
        title:""
      });
    }
    this.setState({
      modalStatus: true,
      isView: isView,
    });
  };

  modalClose = () => {
    this.setState({
      modalStatus: false,
    });
  };
  deletemodalOpen = (dataId) => {
    this.setState({
      deletemodalStatus: true,
      deleteItemId: dataId,
    });
  };
  deletemodalClose = () => {
    this.setState({
      deletemodalStatus: false,
      deleteItemId: "",
    });
  };

  async fetchproviderAddress() {
    this.state.spinner.removeAttribute("hidden", "true");
    let showNotification = {};
    let apiUrl =
      apiPath.getProviderOtherAddressList +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "";

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: providerGeneralAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider Invoice",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          this.setState({
            total: response.headers.get("X-Total-Count"),
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Provider Invoice",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          providerAddress: data,
        });
      })
      .catch((error) => {
        showNotification = {
          title: "Provider Invoice",
          message: "Bad response from server",
          type: "danger",
        };
        return response;
      });

    userService.showNotification(showNotification);
    this.state.spinner.setAttribute("hidden", "true");
  }
  handleSubmit(event) {
    event.preventDefault();
   

    if (event.key === 'Enter') {
      return; // Stop submission if Enter key is pressed
    }
    // Check if the form is valid
  if (!this.validateForm()) {
    return; // Stop submission if form validation fails
  }

 


    if (this.state.dataId) {
      const data = {
        title: this.state.title,
        addressStreet: this.state.addressStreet,
        addressSuite: this.state.addressSuite,
        state: this.state.state,
        city: this.state.city,
        zipcode: this.state.zipcode,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        id: this.state.dataId,
      };
      this.updateAddress(data);
      
    } else {
      this.createAddress();
    }
    
  }
  async createAddress() {
    let showNotification = {};
    const data = {
      title: this.state.title,
      addressStreet: this.state.addressStreet,
      addressSuite: this.state.addressSuite,
      state: this.state.state,
      city: this.state.city,
      zipcode: this.state.zipcode,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };
    this.setState({
      loading: true,
    });
    const response = await fetch(apiPath.getProviderOtherAddressList, {
      method: "POST",
      headers: providerGeneralAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Address",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Address",
            message: "Bad response from server.",
            type: "danger",
          };
        }
        // userService.showNotification(showNotification);
        // this.modalClose();
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        if (data.id) {
          showNotification = {
            title: "Success",
            message: "Address created successfully",
            type: "success",
          };
          userService.showNotification(showNotification);
          this.modalClose();
          this.fetchproviderAddress();
          return true;
        }
        if (data.title) {
          showNotification = {
            title: "Address",
            message: data.title,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
      })
      .catch((error) => {
        showNotification = {
          title: "Address",
          message: "Something went wrong.Please try after sometime..",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
        this.modalClose();
      });
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission when Enter is pressed
    }
  }
  updateAddress(data) {
    let showNotification = {};
    this.setState({ loading: true });
    const response = fetch(apiPath.getProviderOtherAddressList, {
      method: "PUT",
      headers: providerGeneralAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
          let error = new Error(response.statusText);
        }
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        if (data.id) {
          this.fetchproviderAddress();
          showNotification = {
            title: "Success",
            message: "Address updated successfully",
            type: "success",
          };
          this.state.dataId = "";
          userService.showNotification(showNotification);
          this.modalClose();
          this.setState({ loading: false });
          return true;
        }
        if (data.title) {
          showNotification = {
            title: "Address",
            message: data.title,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.loginFailedTitle,
          message: enMsg.inValidCredentials,
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      });
  }
  async deleteAddress() {
    let showNotification = {};
    this.setState({ loading: true });
    let apiUrl =
      apiPath.getProviderOtherAddressList + "/" + this.state.deleteItemId;

    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: providerGeneralAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
        } else if (response.status === 200) {
          this.fetchproviderAddress();
          showNotification = {
            title: "Success",
            message: "Address Delete successfully",
            type: "success",
          };
          this.state.deleteItemId = "";
          userService.showNotification(showNotification);
          this.deletemodalClose();
          this.setState({ loading: false });
          return true;
        } else {
          showNotification = {
            title: enMsg.loginFailedTitle,
            message: enMsg.inValidCredentials,
            type: "danger",
          };
          let error = new Error(response.statusText);
        }
        this.setState({ loading: false });
        return response.json();
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.loginFailedTitle,
          message: enMsg.inValidCredentials,
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({ loading: false });
      });
  }
  render() {
    return (
      <div className="ProviderProfile PaterintCriteria1">
        {this.state.loading ? <LinearProgress color="primary" /> : ""}
        <div className="tab-heading">Additional Locations</div>
        <div className="button-cover">
          <button className="pointer btn1" onClick={this.modalOpen}>
            Add Address
          </button>
        </div>
        <Dialog
          open={this.state.deletemodalStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Delete
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.deletemodalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
              <Box pr={1} width={1}>
                Are you sure you want to delete this item?
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            className="modal-actions .modal-actions"
            justify="center"
          >
            <Button className="btn1" onClick={this.deleteAddress}>
              Confirm
            </Button>
            <Button
              className="back-btn btn2"
              type="button"
              onClick={this.deletemodalClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.modalStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          aria-labelledby="provider-dialog-title"
          className="add-modal"
          TransitionComponent={Transition}
        >
          <DialogTitle className="add-modal-title" id="provider-dialog-title">
            {this.state.isView
              ? "View Address"
              : this.state.dataId
              ? "Edit Address"
              : "Add Address"}
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.handleSubmit} onKeyDown={this.handleKeyDown}noValidate>
            {this.state.isView ? (
              <DialogContent>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={1}>
                  <Box pr={1} width={1}>
                    <InputLabel className="view-label">
                      Provider Name
                    </InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.addressStreet}
                    </InputLabel>
                  </Box>
                </Box>
              </DialogContent>
            ) : (
              <div>
                <DialogContent>
                  <Box display="flex" justifyContent="flex-start" mb={0} mt={2}>
                    <Box pr={1} width={1}>
                      <TextField
                        InputLabelProps={{ className: "required-label" }}
                        name="title"
                        value={this.state.title}
                        onChange={this.handleChangeInputOther}
                        data-validators="isRequired"
                        label="Title"
                      />
                      <FormErrors
                        show={!this.state.titleValid}
                        formErrors={this.state.formErrors}
                        fieldName="title"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={0} mt={2}>
                    <Box pr={1} width={1}>
                      <TextField
                        id="addressMap"
                        InputLabelProps={{ className: "required-label" }}
                        name="addressStreet"
                        value={this.state.addressStreet}
                        onChange={this.handleChangeInput}
                        data-validators="isRequired"
                        label="Address Street"
                        inputRef={this.addressStreetInputRef}
                        
                        helperText={
                          !this.state.selectedAddress
                            ? "⚠️  select an address from the dropdown to auto-fill below fields." 
                            : ""
                        }
                        FormHelperTextProps={{
                          style: { color: !this.state.selectedAddress ? 'red' : 'inherit' }
                        }}
                      />
                      <FormErrors
                        show={!this.state.addressStreetValid}
                        formErrors={this.state.formErrors}
                        fieldName="addressStreet"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={0} mt={2}>
                    <Box pr={1} width={1}>
                      <TextField
                        name="addressSuite"
                        value={this.state.addressSuite}
                        onChange={this.handleChangeInputOther}
                        data-validators="isRequired"
                        label="Address Suite"
                      />
                      <FormErrors
                        show={!this.state.addressSuiteValid}
                        formErrors={this.state.formErrors}
                        fieldName="addressSuite"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={0} mt={2}>
                    <Box pr={1} width={1}>
                      <TextField
                        name="city"
                        value={this.state.city}
                        onChange={this.handleChangeInputOther}
                        data-validators="isRequired"
                        label="City"
                        disabled={ !this.state.selectedAddress}
                        helperText={
                          !this.state.selectedAddress
                            ? "⚠️  select an address from the dropdown to auto-fill City." 
                            : ""
                        }
                        FormHelperTextProps={{
                          style: { color: !this.state.selectedAddress ? 'red' : 'inherit' }
                        }}
                      />
                      <FormErrors
                        show={!this.state.cityValid}
                        formErrors={this.state.formErrors}
                        fieldName="city"
                      />
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="flex-start" mb={0} mt={2}>
                    <Box pr={1} width={1}>
                      <TextField
                        name="state"
                        value={this.state.state}
                        onChange={this.handleChangeInputOther}
                        data-validators="isRequired"
                        label="State"
                        disabled={ !this.state.selectedAddress}
                        helperText={
                          !this.state.selectedAddress
                            ? "⚠️  select an address from the dropdown to auto-fill State." 
                            : ""
                        }
                        FormHelperTextProps={{
                          style: { color: !this.state.selectedAddress ? 'red' : 'inherit' }
                        }}
                      />
                      <FormErrors
                        show={!this.state.stateValid}
                        formErrors={this.state.formErrors}
                        fieldName="state"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={0} mt={2}>
                    <Box pr={1} width={1}>
                      <TextField
                        name="zipcode"
                        value={this.state.zipcode}
                        onChange={this.handleChangeInputOther}
                        data-validators="isRequired"
                        label="Zipcode"
                      />
                      <FormErrors
                        show={!this.state.zipcodeValid}
                        formErrors={this.state.formErrors}
                        fieldName="zipcode"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={0} mt={2}>
                    <Box pr={1} width={1}>
                      <TextField
                        name="latitude"
                        InputLabelProps={{ className: "required-label" }}
                        value={this.state.latitude}
                        onChange={this.handleChangeInputOther}
                        data-validators="isRequired"
                        label="Latitude"
                        disabled={true}
                        helperText={
                          !this.state.selectedAddress
                            ? "⚠️  select an address from the dropdown to auto-fill Latitude." 
                            : ""
                        }
                        FormHelperTextProps={{
                          style: { color: !this.state.selectedAddress ? 'red' : 'inherit' }
                        }}
                      />
                      <FormErrors
                        show={!this.state.latitudeValid}
                        formErrors={this.state.formErrors}
                        fieldName="latitude"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={0} mt={2}>
                    <Box pr={1} width={1}>
                      <TextField
                        name="longitude"
                        InputLabelProps={{ className: "required-label" }}
                        value={this.state.longitude}
                        onChange={this.handleChangeInputOther}
                        data-validators="isRequired"
                        label="Longitude"
                        disabled={true}
                        helperText={
                          !this.state.selectedAddress
                            ? "⚠️  select an address from the dropdown to auto-fill Longitude." 
                            : ""
                        }
                        FormHelperTextProps={{
                          style: { color: !this.state.selectedAddress ? 'red' : 'inherit' }
                        }}
                      />
                      <FormErrors
                        show={!this.state.longitudeValid}
                        formErrors={this.state.formErrors}
                        fieldName="longitude"
                      />
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="flex-start" mb={0}></Box>
                </DialogContent>
                <DialogActions className="modal-actions" justify="center">
                  <div className="form-button-cover form-actions">
                    <Button
                      type="submit"
                      className={
                        this.state.loading ? "buttonSuccess btn1" : "btn1"
                      }
                      disabled={!this.validateForm() || this.state.loading}
                    >
                      {this.state.loading && (
                        <CircularProgress
                          size={24}
                          className="buttonProgress"
                        />
                      )}
                      {this.state.dataId ? "Update" : "Add"}
                    </Button>
                    <Button
                      className="btn2"
                      onClick={this.modalClose}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </div>
                </DialogActions>
              </div>
            )}
          </form>
        </Dialog>
        <Card className={"advert-cover"}>
          <GridItem xs={12} sm={12} md={12}>
            <CardHeader
              color="success"
              className={"cusCardheader cusCardTitle"}
            >
              <h4>Address</h4>
            </CardHeader>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <div className={"custom-container"}>
            <div className="tableResponsive">
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                      >
                        {headCell.sorting ? (
                          <TableSortLabel
                            active={this.state.orderBy == headCell.id}
                            direction={this.state.direction ? "asc" : "desc"}
                            onClick={this.tableSortHandler(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.providerAddress.length ? (
                    this.state.providerAddress.map((address) => (
                      <TableRow key={address.id}>
                        <TableCell>{address.id}</TableCell>
                        <TableCell>{address.title}</TableCell>
                        <TableCell>{address.addressStreet}</TableCell>
                        <TableCell>{address.latitude}</TableCell>
                        <TableCell>{address.longitude}</TableCell>

                        <TableCell>
                          <span
                            className="edit-action"
                            onClick={() => this.modalOpen(address.id)}
                          >
                            <Icon
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            />
                          </span>
                          <span
                            className="edit-action"
                            onClick={() => this.deletemodalOpen(address.id)}
                          >
                            <Icon className="fa fa-trash" aria-hidden="true" />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colspan="7">
                        <div className="not-found-text">Address not found</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>

            <TablePagination
              rowsPerPageOptions={PER_PAGE_OPTIONS}
              component="div"
              count={parseInt(this.state.total)}
              rowsPerPage={this.state.perPage}
              page={this.state.currentPage}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        </Card>
      </div>
    );
  }
}

AddressClass.propTypes = {
  classes: PropTypes.object,
};
const Address = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressClass);
export default withStyles(styles)(Address);
