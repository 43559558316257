/* eslint-disable prettier/prettier */
import axios from "axios";
import React, { useState } from "react";

import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import loadOptions from "./loadOptions";

const CaretDownIcon = () => {
  return (
    <svg
      class="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path d="M7 10l5 5 5-5z"></path>
    </svg>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

// eslint-disable-next-line react/display-name
export default ({ setPatientIds }) => {
  const [value, onChange] = useState(null);
  const [page, setPage] = useState(0);

  const wrappedLoadOptions = (...args) => {
    // setNumberOfRequests(increase);

    return loadOptions(...args);
  };
  return (
    <AsyncPaginate
      debounceTimeout={500}
      value={value}
      loadOptions={wrappedLoadOptions}
      // menuIsOpen={true}
      onChange={(value) => {
        onChange(value);
        setPatientIds([value.patientId]);
      }}
      getOptionLabel={(value) => value.fullName}
      getOptionValue={(value) => value.patientId}
      additional={{
        page: page,
      }}
      classNamePrefix="select"
      components={{ DropdownIndicator }}
      styles={{
        dropdownIndicator: (provided, state) => ({
          ...provided,
          transform: state.selectProps.menuIsOpen && "rotate(180deg)",
        }),
      }}
      menuPlacement={"top"}
    />
  );
};
