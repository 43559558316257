/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, Link as MaterialLink, } from "@material-ui/core"
import { userService } from "_services/user.service"
import enMsg from "__helpers/locale/en/en"
import { apiPath } from 'api'
import { generalAuthTokenHeader } from "__helpers/auth-header"

import { connect } from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Slide,
	LinearProgress,
	Tooltip
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { basePath, baseRoutes, dpBaseRoutes, providerUserBaseRoutes, providerBaseRoutes } from "base-routes";
import {
	FormGroup,
	FormControl,
	InputLabel,
} from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import backArrow from "assets/img/icons/arrow-back.svg";
import {
	PATIENT_LIST, DEFAULT_PER_PAGE_RECORDS,
	ADMIN_USER,
	USER_INFO,
	DP_USER,
	PROVIDER_USER,
	PROVIDER,
} from "__helpers/constants"
import Scrollbars from "react-custom-scrollbars"
import DateFnsUtils from '@date-io/date-fns';
import InfiniteScroll from 'react-infinite-scroller';
import userOne from "assets/img/icons/user1.svg";
import userGroup from "assets/img/icons/users2.svg";

import ConfirmModal from 'components/Modal/modal'
import update from 'immutability-helper'; // ES6
import Comment from 'components/Comments/Comment'
import { localDateFormat } from "__helpers/util";

const styles = {
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	}
};
const mapStateToProps = state => ({
	categoryListRedux: state.categoryList,
});
const tooltipStyles = {
	tooltip: {
		width: "auto !important",
		// height: "36px",
		borderRadius: "18px",
		boxShadow: "0 20px 80px 0",
	}
};
const CustomTooltip = withStyles(tooltipStyles)(Tooltip);
class Notifications extends React.Component {
	constructor(props) {

		super(props);
		this.dateUtility = new DateFnsUtils();
		this.state = {
			tl: false,
			tc: false,
			tr: false,
			bl: false,
			bc: false,
			br: false,
			notifications: '',
			currentPage: 0,
			perPage: DEFAULT_PER_PAGE_RECORDS,
			loading: false,
			loadMore: false,
			total: '',
			totalNotificationShowing: '',
			loadingNotification: true,
			selectedNotificationData: '',
			orderBy: 'createdDateTime',
			confirmModalMessage: '',
			confirmModalStatus: false,
			loginUserInfo: {},
			categories: this.props.categoryListRedux ? this.props.categoryListRedux : []
		};
		this.fetchNotifications = this.fetchNotifications.bind(this);
		this.rescindReferral = this.rescindReferral.bind(this);
		this.loadMoreNotificaion = this.loadMoreNotificaion.bind(this)
	}
	componentDidMount() {
		this.fetchNotifications();
		let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
		this.setState({
			loginUserInfo: userInfo
		})

	}
	// to stop the warning of calling setState of unmounted component
	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}

	async fetchNotifications() {
		let apiUrl = '';
		let showNotification = {};
		apiUrl = apiPath.notificationList + '?page=' + this.state.currentPage + '&size=' + this.state.perPage + '&sort=' + this.state.orderBy + ',desc';
		const response = await fetch(apiUrl, {
			method: "GET",
			headers: generalAuthTokenHeader()
		})
			.then(response => {
				if (response.status === 400) {
					showNotification = {
						title: 'Notification',
						message: 'Bad response from server',
						type: "danger"
					};
				} else if (response.ok) {
					let totalPatientCount = response.headers.get('X-Total-Count');
					this.setState({
						total: totalPatientCount,
					})
				} else if (response.status === 401) {
				}
				else {
					showNotification = {
						title: 'Notification',
						message: 'Bad response from server',
						type: "danger"
					};
				}
				return response.json();
			})
			.then(data => {
				let localNotifications = this.state.notifications;
				if (this.state.currentPage == 0) {
					localNotifications = data;
				} else {
					localNotifications = localNotifications.concat(data);
				}
				let totalNotificationShowing = localNotifications.length;
				let loadMore = false;
				let loadingState = false;
				let currentPage = this.state.currentPage;
				if (this.state.total > totalNotificationShowing) {
					loadMore = true;
					loadingState = true;
					currentPage = currentPage + 1;
				}
				this.setState({
					notifications: localNotifications,
					totalNotificationShowing: totalNotificationShowing,
					loadMore: loadMore,
					currentPage: currentPage,
					loadingState: loadingState,
					loadingNotification: false,
					loading: false,
				})
				userService.showNotification(showNotification);
			})
			.catch(error => {
				showNotification = {
					title: 'Notification',
					message: 'Bad response from server',
					type: "danger"
				};
				this.setState({
					loadingNotification: false,
				})
				return response;
			});
		userService.showNotification(showNotification);
	}
	showNotification(place) {
		var x = [];
		x[place] = true;
		this.setState(x);
		this.alertTimeout = setTimeout(
			function () {
				x[place] = false;
				this.setState(x);
			}.bind(this),
			6000
		);
	}
	selectNotification = (id) => {
		this.readNotification(id);
		this.state.notifications.map(notification => {
			if (notification.id == id) {
				this.setState({
					selectedNotificationData: notification,
				})
			}
		})
	}
	async readNotification(notificationId) {
		let apiUrl = '';
		apiUrl = await apiPath.readNotification + '?notificationId=' + notificationId;
		const response = fetch(apiUrl, {
			method: "GET",
			headers: generalAuthTokenHeader()
		})
			.then(response => {
				if (response.status === 400) {
				} else if (response.ok) {
					let notiList = [...this.state.notifications];
					let updatedNotiList = [];
					if (notiList && notiList.length) {
						updatedNotiList = notiList.filter((v, i) => {
							if (v.id === notificationId) {
								v.readStatus = true;
							}
							return v;
						});
					}
					this.setState({
						notifications: updatedNotiList,
					})
				} else if (response.status === 401) {
				}
				return response.json();
			})
			.then(data => {
				console.log(data)
				return true
			})
			.catch(error => {
				return error;
			});
	}
	confirmModalOpen = (message) => {
		this.setState({
			confirmModalMessage: message,
			confirmModalStatus: true,
		})
	}
	confirmModalClose = () => {
		this.setState({
			confirmModalStatus: false
		})
	}
	async rescindReferral() {

		this.setState({
			loading: true,
		});
		let showNotification = {};
		let url = apiPath.rescindReferralDp + "?refferalId=" + this.state.selectedNotificationData.refferalId;
		const response = await fetch(url, {
			method: "GET",
			headers: generalAuthTokenHeader(),
		})
			.then(response => {
				if (response.status === 400) {
					showNotification = {
						title: 'Rescind Status',
						message: 'Bad response from server',
						type: "danger"
					};
				} else if (response.ok) {
					showNotification = {
						title: 'Rescind Status',
						message: 'Referral rescind successfully',
						type: "success"
					};

					this.setState({
						selectedNotificationData: update(this.state.selectedNotificationData, {
							refferalEntity:
							{
								refferalStatus:
								{
									$set: 1
								}
							}
						}
						)

					})

				} else {
					showNotification = {
						title: 'Rescind Status',
						message: 'Bad response from server',
						type: "danger"
					};
				}
				return response.json();
			})
			.then(data => {
				if (data.status == 400 && data.message == 'error.rescind') {
					showNotification = {
						title: "Rescind Status",
						message: 'Referral already rescinded',
						type: 'danger'
					};
				}
				// userService.showNotification(showNotification);
				return true;
			})
			.catch(error => {
				return true;
			});
		this.confirmModalClose();

		userService.showNotification(showNotification);
		this.setState({
			loading: false,
		})
	}
	loadMoreNotificaion() {
		this.setState({
			loadMore: false,
			loading: true
		}, () => this.fetchNotifications())
	}
	render() {
		const { classes } = this.props;
		const { loginUserInfo, loading } = this.state;
		return (
			<div id="single-patient-info">
				<div className="ProviderFind NotificationsPage">
					<ConfirmModal
						title='Alert'
						message={this.state.confirmModalMessage}
						confirmModalStatus={this.state.confirmModalStatus}
						confirmModalClose={this.confirmModalClose}
						submit={this.rescindReferral}
						loading={this.state.loading}
					/>
					<Link
						// to={
						// 	loginUserInfo.usrType == ADMIN_USER
						// 		?
						// 		baseRoutes.dashboard.path
						// 		:
						// 		loginUserInfo.usrType == DP_USER
						// 			?
						// 			dpBaseRoutes.dashboard.path
						// 			:
						// 			loginUserInfo.usrType == PROVIDER_USER
						// 				?
						// 				providerUserBaseRoutes.dashboard.path
						// 				:
						// 				providerBaseRoutes.dashboard.path
						// }
						to={
							{
								pathname: loginUserInfo.usrType == ADMIN_USER
									?
									baseRoutes.dashboard.path
									:
									loginUserInfo.usrType == DP_USER
										?
										dpBaseRoutes.dashboard.path
										:
										loginUserInfo.usrType == PROVIDER_USER
											?
											providerUserBaseRoutes.dashboard.path
											:
											providerBaseRoutes.dashboard.path,
								state: {
									resetDashboard: false,
								}
							}
						}
						color="textSecondary"
						className="link-a"
						underline="none"
					>
						<h2 className="titlename"><img src={backArrow} /> {dpBaseRoutes.notification.pathName}</h2>
					</Link>
					<Box display="flex" flexDirection="row" width={1} mt={1.5} className="notifications-page" >
						<Box className="notifications-left-list" >
							{
								(this.state.loading)
									?
									<div><LinearProgress /></div>
									:
									<div></div>
							}
							<Scrollbars className="notification-scrollbox" style={{ height: "calc(100vh - 163px)" }} universal={true} autoHide={false}>
								{this.state.notifications && typeof this.state.notifications === "object" && this.state.notifications.length
									?
									<InfiniteScroll
										pageStart={0}
										loadMore={this.loadMoreNotificaion}
										threshold={50}
										hasMore={this.state.loadMore}
										loader=
										{
											<div className="loader notification-loader" key={0}>
												Loading ...
											</div>
										}
										useWindow={false}
										getScrollParent={() => this.scrollParentRef}

									>
										{this.state.notifications.map(notification =>
											<Box width={1} display="flex" flexDirection="column" justifyContent="center" className={`${this.state.selectedNotificationData.id == notification.id ? 'notification-list-cover pointer selected-notification' : 'notification-list-cover pointer'}`} onClick={() => this.selectNotification(notification.id)}>
												<Box className={`notification-list-content`}>
													{(this.state.loginUserInfo.usrType == 'ROLE_DISCHANRGE_PLANNER') &&
														(notification.notificationType == 'Comment' ?
															<div className={notification.readStatus ? "patient-notification-title" : "notification-title"}>
																The {(notification.refferalEntity.refferalProviderEntity[0]) && notification.refferalEntity.refferalProviderEntity[0].provider.providerName} has added a new comment.
															</div>
															:
															<div className={notification.readStatus ? "patient-notification-title" : "notification-title"}>
																The {(notification.refferalEntity.refferalProviderEntity[0]) && notification.refferalEntity.refferalProviderEntity[0].provider.providerName} (Ref.ID {notification.refferalId})
															</div>)
													}
													{(this.state.loginUserInfo.usrType == 'ROLE_PROVIDER' || this.state.loginUserInfo.usrType == 'ROLE_USER_PROVIDER') &&
														(notification.notificationType == 'Comment' ?
															<div className={notification.readStatus ? "patient-notification-title" : "notification-title"}>
																{notification.refferalEntity.dischargeplannerEntity.facilityNameData} has commented on the referral for {notification.refferalEntity.refferalPatientEntity[0].patientEntity.firstName + ' ' + notification.refferalEntity.refferalPatientEntity[0].patientEntity.lastName} in the &nbsp;
																{
																	this.state.categories.map(category =>
																		(category.catagoryId == notification.refferalEntity.categoryId) ? category.categoryValue : ''
																	)
																}
																&nbsp; category
																{/* The {(notification.fromUser) && notification.fromUser.firstName+' '+notification.fromUser.lastName} has added a new comment. */}
															</div>
															:
															(notification.notificationType == 'Rescind') ?
																<div className={notification.readStatus ? "patient-notification-title" : "notification-title"}>
																	{notification.refferalId} was rescinded by {notification.refferalEntity.dischargeplannerEntity.facilityNameData}
																</div>
																:
																<div className={notification.readStatus ? "patient-notification-title" : "notification-title"}>
																	{notification.refferalId} is ready for acceptance with {notification.refferalEntity.refferalPatientEntity.length} Patients from {(notification.fromUser) && notification.fromUser.firstName + ' ' + notification.fromUser.lastName}. This is a {notification.refferalEntity.refferalType == 1 ? 'Prelim' : 'Rapid'} Referral
																	{/* The {(notification.fromUser) && notification.fromUser.firstName+' '+notification.fromUser.lastName} (Ref.ID {notification.refferalId}) */}
																</div>)
													}
													{/* {notification.notificationType =='Comment' ? 
														<div className="notification-title">
															The {(notification.fromUser) && notification.fromUser.firstName+' '+notification.fromUser.lastName} has added a new comment.
														</div>
														:
														<div className="notification-title">
															The {(notification.refferalEntity.refferalProviderEntity[0]) && notification.refferalEntity.refferalProviderEntity[0].provider.providerName} (Ref.ID {notification.refferalId})
														</div>
													} */}
													{notification.refferalEntity.refferalPatientEntity.length > 1 ?
														<div className={notification.readStatus ? "patient-notification-user" : "notification-user"}>
															<img src={userGroup} />
															Bundled Patients
														</div>
														:
														<div className={notification.readStatus ? "patient-notification-user" : "notification-user"}>
															<img src={userOne} />
															{/* <img src={userGroup} /> */}
															{notification.refferalEntity.refferalPatientEntity[0].patientEntity.lastName},
															{' ' + notification.refferalEntity.refferalPatientEntity[0].patientEntity.firstName + ' '}
															{(notification.refferalEntity.refferalPatientEntity[0].patientEntity.middleName ? notification.refferalEntity.refferalPatientEntity[0].patientEntity.middleName : '') + ' '}
														</div>
													}

													<Box width={1} display="flex" flexDirection="row" className="notification-status-date">
														{/* <div className={`notification-status ${notification.refferalEntity.refferalProviderEntity[0].masterRefferalStatusEntity.id == 7 || notification.refferalEntity.refferalProviderEntity[0].masterRefferalStatusEntity.id == 5  ? 'Declined-clr' : 'Accepted-clr'}`}>{notification.notificationType}</div> */}
														<div className={`notification-status ${notification.notificationType + '-clr'}`}>{(notification.notificationType != 'Comment') ? notification.notificationType : ''}</div>

														<div className="notification-date">{notification.notificationCreatedDateTime}
															{/* {this.dateUtility.format(
																new Date(notification.createdDateTime),
																"MM/dd/yyyy HH:mm:ss"
															)} */}
														</div>
													</Box>
												</Box>
											</Box>
										)
										}
									</InfiniteScroll>
									:
									(this.state.loadingNotification)
										?
										<div className="no-data-div">
											<div>
												Loading...
											</div>
										</div>
										:
										<div className="no-data-div"> <div>No New Notifications!</div></div>
								}
							</Scrollbars>
						</Box>
						<Box className="notifications-right-details" >
							{this.state.selectedNotificationData && this.state.selectedNotificationData.notificationType == 'Comment' && this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0] && this.state.selectedNotificationData.refferalEntity.masterRefferalStatusEntity.id != 2 &&
								<div className="details-section details-section-notifications">

									<div className="name-status-tag">
										<div className="name">{this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.lastName}, {this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.firstName} {this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.middleName}</div>
									</div>

									<Box display="flex" flexDirection="row" width={1} mt={2} mb={1} className={"details-all-cover"} >
										<Box width="50%" className={"section details-cover"} display="flex" flexDirection="column">
											<div className="section-heading"> DETAILS </div>
											<Box display="flex" flexDirection="row" width={1} mt={1} className="details-info" >
												<Box pr={2}>
													<div className="info-label">
														{this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.actualDischargeDate ? "Discharged" : "Expected Discharge"}
													</div>
													<div className="info-value">
														{
															this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.actualDischargeDate
																?
																localDateFormat(this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.actualDischargeDate)
																:
																this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.formattedEstimatedDischargeDate
																	?
																	this.dateUtility.format(
																		new Date(this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.formattedEstimatedDischargeDate),
																		"MM/dd/yyyy"
																	)
																	: "N/A"
														}
													</div>

													<div className="info-label">
														Insurance
													</div>
													<div className="info-value">
														{(this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.masterInsurance) ? this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.masterInsurance.insuranceName : 'None'}
													</div>

												</Box>

												<Box pl={2}>
													<div className="info-label">
														Date of birth
													</div>
													<div className="info-value">
														{
															this.dateUtility.format(
																new Date(this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.formattedPatientDob),
																"MM/dd/yyyy"
															)
														}
													</div>

													<div className="info-label">
														Gender
													</div>
													<div className="info-value">
														{this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.gender}
													</div>
												</Box>
											</Box>
										</Box>

										<Box width="50%" className={"section-category-cover"} display="flex" flexDirection="column" >
											<div className="section-heading"> Category </div>
											{this.state.selectedNotificationData.refferalEntity && this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0] && this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.patientCatagoryMapping && this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientEntity.patientCatagoryMapping.map((category, index) => {
												if (category.refferalId == this.state.selectedNotificationData.refferalId) {
													return (
														<Box
															className="itemx menu-itemx owl-item-box"
															key={index}
															border={1}
															id="refidoOwl"
														>
															<p className="category-value">{category.masterCategories.categoryValue}</p>
															{/* <p className={`category-status ${el && el.refferalEntity && el.refferalEntity.masterRefferalStatusEntity ? el.refferalEntity.masterRefferalStatusEntity.refferalStatusValue : 'Finding'}`}>
																{
																	el && el.refferalEntity && el.refferalEntity.masterRefferalStatusEntity
																		?
																		el.refferalEntity.masterRefferalStatusEntity.refferalStatusValue
																		:
																		"Finding"
																}
															</p> */}
														</Box>
													)
												}
											})}

										</Box>

									</Box>

								</div>
							}
							<div className="notifications-details-section">
								{this.state.selectedNotificationData && this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0] ?
									<div className="notification-scrollbox" style={{ height: this.state.selectedNotificationData.notificationType == 'Comment' ? "calc(100vh - 367px)" : "calc(100vh - 193px)" }} >
										<Box display="flex" flexDirection="row" width={1} mb={1} className={"notification-header-info"} >

											<Box display="flex" flexDirection="row" className="notification-header-info-1" >
												<div>
													<div className="ref-info">Referral ID</div>
													<div className="ref-val">{this.state.selectedNotificationData.refferalId}</div>
												</div>
												<div>
													<div className="ref-info">Referral Sent</div>
													{
														this.state.selectedNotificationData.refferalEntity.refferalSendTime
													}
												</div>
												<div>
													<div className="ref-info">Total Patient</div>
													<div className="ref-val">{this.state.selectedNotificationData.refferalEntity.refferalPatientEntity.length}</div>
												</div>
											</Box>
											{(this.state.loginUserInfo.usrType == 'ROLE_DISCHANRGE_PLANNER') &&
												<div className="notification-header-info-2">
													{/* <Box pl={1} className="action-filter">
														<button className="pointer btn4">View Referral Patients</button>
													</Box> */}
													<Box pl={1} className="action-filter" style={{ display: "" }}>
														<button className="pointer btn4" onClick={() => this.confirmModalOpen('Do you want to rescind the referral?')} disabled={(this.state.selectedNotificationData.refferalEntity.refferalStatus == 1) ? 'disabled' : ''}>Rescind Referral</button>
													</Box>
												</div>
											}
										</Box>
										<Box>
											<Box display="flex" flexDirection="column" mt={2} mb={2}>

												<Box className="list-item-content-notification no-shadow " width={1} display="flex" flexDirection="row">


													<div className="user-info-notification">
														<div className="name">
															{this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].provider.providerName}
														</div>

														<div className="date-insurance">
															<svg xmlns="http://www.w3.org/2000/svg" width="14.609" height="10" viewBox="0 0 14.609 14.608">
																<path id="phone" d="M5.94,9.324a12.228,12.228,0,0,0,5.347,5.344l1.785-1.788a.813.813,0,0,1,.825-.2,9.326,9.326,0,0,0,2.9.461.811.811,0,0,1,.812.812v2.84a.811.811,0,0,1-.812.812A13.8,13.8,0,0,1,3,3.81.811.811,0,0,1,3.81,3h2.84a.811.811,0,0,1,.812.812,9.326,9.326,0,0,0,.461,2.9.811.811,0,0,1-.2.824Z" transform="translate(-2.999 -2.998)" fill="#01a7a6" />
															</svg>
															<span className="phoneNumber">
																{this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].provider.user.phoneNumber ? "(" + this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].provider.user.phoneNumber.match(/(\d{3})(\d{3})(\d{4})/)[1] + ") " + this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].provider.user.phoneNumber.match(/(\d{3})(\d{3})(\d{4})/)[2] + "-" + this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].provider.user.phoneNumber.match(/(\d{3})(\d{3})(\d{4})/)[3] : "N/A"}
															</span>
														</div>
														{this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].provider.user.address &&
															<div className="date-insurance">
																<svg xmlns="http://www.w3.org/2000/svg" width="10.226" height="10" viewBox="0 0 10.226 14.609">
																	<path id="map_marker" data-name="map marker" d="M10.112,8.937a1.826,1.826,0,1,1,1.826-1.826A1.826,1.826,0,0,1,10.112,8.937Zm0-6.939A5.113,5.113,0,0,0,5,7.111c0,3.835,5.113,9.5,5.113,9.5s5.113-5.661,5.113-9.5A5.113,5.113,0,0,0,10.112,2Z" transform="translate(-4.999 -1.998)" fill="#01a7a6" />
																</svg>
																<span>{this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].provider.user.address}</span>
															</div>
														}
													</div>

													<Box display="flex" justifyContent="">
														<div className={`notification-info-status ${this.state.selectedNotificationData.notificationType}`}>{(this.state.selectedNotificationData.notificationType == 'Comment') ? 'Referral Accepted' : this.state.selectedNotificationData.notificationType} on {
															this.dateUtility.format(
																new Date(this.state.selectedNotificationData.notificationCreatedDateTime),
																"MM/dd/yyyy"
															)}</div>
													</Box>
													<Box className="NotificationComents" width={1 / 3} display="flex" justifyContent="center">
														{
															this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment && this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[0]
																?
																<CustomTooltip title={this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[0].comment}>
																	<div className="DpComment">
																		<b>
																			{this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[0].createdUser && this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[0].createdUser.firstName}
																		</b>: {this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[0].comment}
																	</div>
																</CustomTooltip>
																: null
														}
														{
															this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment && this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[1]
																?
																<CustomTooltip title={this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[1].comment}>
																	<div className="ProviderComment">
																		<b>
																			{this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[1].createdUser && this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[1].createdUser.firstName}
																		</b>: {this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment[1].comment}
																	</div>
																</CustomTooltip>
																: null
														}
														<div className={`notification-msg`}>{this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].comment}</div>
													</Box>
												</Box>

											</Box>
											<div className="comments-section">
												{this.state.selectedNotificationData && this.state.selectedNotificationData.notificationType == 'Comment' && 
												this.state.selectedNotificationData.refferalEntity.refferalStatus != "1" && 
												this.state.selectedNotificationData.refferalEntity.masterRefferalStatusEntity.id != 2 &&
													this.state.selectedNotificationData.refferalEntity.refferalStatus == this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].providerStatusId &&
													<Comment
														referralId={this.state.selectedNotificationData.refferalId}
														// receiverId = {this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].providerId}
														receiverId={this.state.selectedNotificationData.fromUser.id}
														patientId={this.state.selectedNotificationData.refferalEntity.refferalPatientEntity[0].patientId}
														prelimComment={
															this.state.selectedNotificationData.refferalEntity &&
																this.state.selectedNotificationData.refferalEntity.refferalProviderEntity &&
																this.state.selectedNotificationData.refferalEntity.refferalProviderEntity.length &&
																this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment &&
																this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment.length
																?
																this.state.selectedNotificationData.refferalEntity.refferalProviderEntity[0].refferalProviderComment
																:
																[]
														}
													/>
												}
											</div>
										</Box>
									</div>
									:
									<Box display="flex" justifyContent="center" width={1} className="notification-scrollbox" style={{ height: "calc(100vh - 193px)" }}  >
										<div className="select-referral-text" >
											Select Notification<br></br>
											to View Details
										</div>
									</Box>
								}
							</div>

						</Box>
					</Box>
				</div>
			</div>

		);
	}
}

// Notifications.propTypes = {
// 	classes: PropTypes.object
// };
export const Notificationss = connect(
	mapStateToProps
)(Notifications);

export default withStyles(styles)(Notificationss);
