/*eslint-disable*/
import React from "react";
import ReactDOM from "react-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

import { FormErrors } from "../../../components/Login/FormErrors";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Box,
  TextField,
  Avatar,
  Checkbox,
  LinearProgress,
} from "@material-ui/core";

import { providerBaseRoutes } from "base-routes";
import { userService } from "_services/user.service";

import { MAX_INPUT_LENGTH_LONG_TEXT } from "__helpers/constants";

import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";
import enMsg from "__helpers/locale/en/en";

import { apiPath } from "api";

import { USER_INFO, ZIP_CODE_LENGTH, DIGIT_ONLY } from "__helpers/constants";
import { generalAuthTokenHeader } from "__helpers/auth-header";
const mat_select = "material-select disabled-dropdown-black";
const cust_label = "custom-label";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF",
    },
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  disabledDropdownBlack: {
    color: "#000 !important",
  },
};

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.dateUtility = new DateFnsUtils();
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    this.addressStreetInputRef = React.createRef();
    this.state = {
      stateList: "",
      addressStreet: "",
      addressSuite: "",
      city: "",
      state: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      formErrors: {
        addressStreet: "",
        addressSuite: "",
        city: "",
        state: "",
        zipcode: "",
        latitude: "",
        longitude: "",
      },
      addressStreetValid: false,
      addressSuiteValid: true,
      cityValid: false,
      stateValid: false,
      zipcodeValid: false,
      latitudeValid: false,
      longitudeValid: false,
      userInfo: userInfo,
    };
  }
  componentDidMount() {
    this.getStateList();
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    this.setState(
      {
        providerId: userInfo.provider.id,
      },
      () => {
        this.fetchOrganizationalDetail();
      }
    );

    const autocomplete = new window.google.maps.places.Autocomplete(
      this.addressStreetInputRef.current
    );
    console.log(autocomplete);
    // Add event listener for place selection
    autocomplete.addListener("place_changed", () => {
      this.handlePlaceSelection(autocomplete);
    });

    // Store the Autocomplete object in the component's state
    this.setState({ addressAutocomplete: autocomplete });
  }
  handlePlaceSelection = (autocomplete) => {
    // Get the selected place details from the Autocomplete object
    const selectedPlace = autocomplete.getPlace();

    if (selectedPlace && selectedPlace.formatted_address) {
      this.setState({
        city: "",
        state: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        cityValid: false,
        stateValid: false,
        zipcodeValid: false,
      });
      // Extract the address components from the selected place
      const addressStreet = selectedPlace.formatted_address;
      const city = selectedPlace.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const state = selectedPlace.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.short_name;
      const zipcode = selectedPlace.address_components.find((component) =>
        component.types.includes("postal_code")
      )?.long_name;
      const latitude = selectedPlace.geometry.location.lat();
      const longitude = selectedPlace.geometry.location.lng();
      // console.log(selectedPlace.geometry.location);
      // Update the state with the selected address components

      this.setState({
        addressStreet: addressStreet,
        city: city,
        state: state,
        zipcode: zipcode,
        latitude: latitude,
        longitude: longitude,
      });
      if (addressStreet) this.validateField("addressStreet", addressStreet);
      if (city) this.validateField("city", city);
      if (state) this.validateField("state", state);
      if (zipcode) this.validateField("zipcode", zipcode);
      if (latitude) this.validateField("latitude", latitude);
      if (longitude) this.validateField("longitude", longitude);
    }
  };
  getStateList = async () => {
    let stateList = await userService.getStateList();
    this.setState({
      stateList: stateList,
    });
  };
  handleZipCodeInput = (e) => {
    const value = e.target.value;
    if (value.length > ZIP_CODE_LENGTH) {
      return true;
    }
    if (value.trim() == "" || value.match(DIGIT_ONLY)) {
      this.setState({ zipcode: value }, () => {
        this.validateField("zipcode", value);
      });
    }
  };
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let addressStreetValid = this.state.addressStreetValid;
    let addressSuiteValid = this.state.addressSuiteValid;
    let cityValid = this.state.cityValid;
    let stateValid = this.state.stateValid;
    let zipcodeValid = this.state.zipcodeValid;
    let latitudeValid = this.state.latitudeValid;
    let longitudeValid = this.state.longitudeValid;
    switch (fieldName) {
      case "addressStreet":
        if (fieldValue.trim().length == 0) {
          addressStreetValid = false;
          fieldValidationErrors.addressStreet = "Address street is required";
        } else {
          addressStreetValid = true;
          fieldValidationErrors.addressStreet = "";
        }
        break;
      case "addressSuite":
        if (fieldValue.trim().length == 0) {
          addressSuiteValid = true;
          fieldValidationErrors.addressSuite = "";
        } else {
          addressSuiteValid = true;
          fieldValidationErrors.addressSuite = "";
        }
        break;
      case "city":
        if (fieldValue == "") {
          cityValid = false;
          fieldValidationErrors.city = "City is required";
        } else {
          cityValid = true;
          fieldValidationErrors.city = "";
        }

        break;

      case "state":
        if (fieldValue == "") {
          stateValid = false;
          fieldValidationErrors.state = "State is required";
        } else {
          stateValid = true;
          fieldValidationErrors.state = "";
        }
        break;

      case "zipcode":
        if (fieldValue == "") {
          zipcodeValid = false;
          fieldValidationErrors.zipcode = "Zipcode is required";
        } else {
          zipcodeValid = true;
          fieldValidationErrors.zipcode = "";
        }
        break;
      case "latitude":
        if (fieldValue == "") {
          latitudeValid = true;
          fieldValidationErrors.latitude = "Latitude is required";
        } else {
          latitudeValid = true;
          fieldValidationErrors.latitude = "";
        }
        break;
      case "longitude":
        if (fieldValue == "") {
          longitudeValid = true;
          fieldValidationErrors.longitude = "Longitude is required";
        } else {
          longitudeValid = true;
          fieldValidationErrors.longitude = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        addressStreetValid: addressStreetValid,
        addressSuiteValid: addressSuiteValid,
        cityValid: cityValid,
        stateValid: stateValid,
        zipcodeValid: zipcodeValid,
        latitudeValid: latitudeValid,
        longitudeValid: longitudeValid,
      },
      this.validateForm
    );
  };

  validateForm() {
    return (
      this.state.addressStreetValid &&
      this.state.addressSuiteValid &&
      this.state.cityValid &&
      this.state.stateValid &&
      this.state.zipcodeValid &&
      this.state.latitudeValid &&
      this.state.longitudeValid
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      addressStreet: this.state.addressStreet,
      addressSuite: this.state.addressSuite,
      city: this.state.city,
      phone: this.state.phone,
      state: this.state.state,
      zipcode: this.state.zipcode,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };
    if (this.state.oldUserId != this.state.userid) {
      // data.userLoginId = this.state.userid;
    }
    this.saveOrganizationalData(data);
  };
  saveOrganizationalData = async (data) => {
    this.setState({ loading: true });
    let showNotification = {};
    try {
      const response = await fetch(apiPath.getPatientAddress, {
        method: "PUT",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
      })
        .then((response) => {
          console.log(response.status);
          if (response.status === 400) {
            showNotification = {
              title: "Provider User",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
          } else if (response.ok) {
            showNotification = {
              title: "Provider User",
              message: "Address saved successfully",
              type: "success",
            };
          } else {
            showNotification = {
              title: "Details",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
          }
          return response.json();
        })
        .then((data) => {
          if (data.status == "400" && data.message == "error.userManagement") {
            modalCloseStatus = 0;
            showNotification = {
              title: "Details",
              message: "User login id is already exist.",
              type: "danger",
            };
          } else if (data.providerId) {
            showNotification = {
              title: "Details",
              message: "Address saved successfully",
              type: "success",
            };
            return true;
          }
        })
        .catch((error) => {
          showNotification = {
            title: "Details",
            message: enMsg.networkFailedError,
            type: "danger",
          };
          return response;
        });
    } catch (error) {
      showNotification = {
        title: "Details",
        message: enMsg.networkFailedError,
        type: "danger",
      };
    }
    userService.showNotification(showNotification);
    this.setState({ loading: false });
  };
  fetchOrganizationalDetail = () => {
    const response = fetch(apiPath.getPatientAddress, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          this.setState({
            addressStreet: data.addressStreet,
            addressSuite: data.addressSuite,
            city: data.city,
            email: data.email,
            state: data.state,
            zipcode: data.zipcode,
            longitude: data.longitude,
            latitude: data.latitude,
          });
          if (data.addressStreet)
            this.validateField("addressStreet", data.addressStreet);
          if (data.addressSuite)
            this.validateField("addressSuite", data.addressSuite);
          if (data.city) this.validateField("city", data.city);
          if (data.email) this.validateField("email", data.email);
          if (data.state) this.validateField("state", data.state);
          if (data.zipcode) this.validateField("zipcode", data.zipcode);
          if (data.longitude) this.validateField("longitude", data.longitude);
          if (data.latitude) this.validateField("latitude", data.latitude);
        }
      })
      .catch((error) => {
        return response;
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className="front-modal">
        {this.state.loading ? <LinearProgress color="primary" /> : ""}
        <div className="tab-heading">Address Details</div>
        <form noValidate>
          <Box
            display="flex"
            flexDirection="row"
            width={1}
            mt={2}
            className={"org-form-cover"}
          >
            <Box
              mt={3}
              width={1 / 3}
              className={""}
              display="flex"
              flexDirection="column"
            >
              <div>
                <TextField
                  name="addressStreet"
                  value={this.state.addressStreet}
                  InputLabelProps={{ className: "required-label" }}
                  label="Address Street"
                  onChange={this.handleChangeInput}
                  inputRef={this.addressStreetInputRef} // Ref for the input field
                  tabIndex="2"
                />
                <FormErrors
                  show={!this.state.addressStreetValid}
                  formErrors={this.state.formErrors}
                  fieldName="addressStreet"
                />
              </div>
              <div>
                <TextField
                  name="addressSuite"
                  value={this.state.addressSuite}
                  label="Address Suite #"
                  onChange={this.handleChangeInput}
                  tabIndex="11"
                />
                <FormErrors
                  show={!this.state.addressSuiteValid}
                  formErrors={this.state.formErrors}
                  fieldName="addressSuite"
                />
              </div>
              <div>
                <TextField
                  name="city"
                  value={this.state.city}
                  InputLabelProps={{ className: "required-label" }}
                  label="City"
                  onChange={this.handleChangeInput}
                  tabIndex="3"
                />
                <FormErrors
                  show={!this.state.cityValid}
                  formErrors={this.state.formErrors}
                  fieldName="city"
                />
              </div>
              <div>
                <TextField
                  name="state"
                  value={this.state.state}
                  InputLabelProps={{ className: "required-label" }}
                  label="State"
                  onChange={this.handleChangeInput}
                  tabIndex="3"
                />
                <FormErrors
                  show={!this.state.stateValid}
                  formErrors={this.state.formErrors}
                  fieldName="state"
                />
              </div>
              {/* <div>
                <FormControl>
                  <InputLabel className={"required-label"}>State</InputLabel>
                  <Select
                    value={this.state.state ? this.state.state : ""}
                    onChange={this.handleChangeInput}
                    inputProps={{
                      name: "state",
                      id: "facility-select-label",
                    }}
                    displayEmpty
                    InputLabelProps={{ className: "required-label" }}
                    tabIndex="4"
                  >
                    {this.state.stateList.length ? (
                      this.state.stateList.map((st, key) => (
                        <MenuItem value={st.id} key={key}>
                          {st.stateName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value=" ">Not Available</MenuItem>
                    )}
                  </Select>
                  <FormErrors
                    show={!this.state.stateValid}
                    formErrors={this.state.formErrors}
                    fieldName="state"
                  />
                </FormControl>
              </div> */}

              <div>
                <TextField
                  name="zipcode"
                  value={this.state.zipcode}
                  InputLabelProps={{ className: "required-label" }}
                  label="Zipcode"
                  onChange={this.handleChangeInput}
                  tabIndex="3"
                />
                <FormErrors
                  show={!this.state.zipcodeValid}
                  formErrors={this.state.formErrors}
                  fieldName="zipcode"
                />
              </div>
              <div>
                <TextField
                  name="latitude"
                  value={this.state.latitude}
                  label="Latitude"
                  onChange={this.handleChangeInput}
                  tabIndex="5"
                  InputProps={{
                    readOnly: true, // Make the field read-only based on the field name
                  }}
                />
                <FormErrors
                  show={!this.state.latitudeValid}
                  formErrors={this.state.formErrors}
                  fieldName="latitude"
                />
              </div>
              <div>
                <TextField
                  name="longitude"
                  value={this.state.longitude}
                  label="Longitude"
                  onChange={this.handleChangeInput}
                  tabIndex="5"
                  InputProps={{
                    readOnly: true, // Make the field read-only based on the field name
                  }}
                />
                <FormErrors
                  show={!this.state.longitudeValid}
                  formErrors={this.state.formErrors}
                  fieldName="longitude"
                />
              </div>
            </Box>
          </Box>
          <Box width={1} display="flex" flexDirection="row">
            <Box
              width={1 / 3}
              className="form-button-cover form-actions"
              tabIndex="17"
            >
              <Button
                className={this.state.loading ? "buttonSuccess btn1" : "btn1"}
                disabled={this.state.loading || !this.validateForm()}
                onClick={this.handleSubmit}
                type="button"
              >
                Save
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button className="back-btn btn2" type="button" tabIndex="18">
                <Link underline="none" to={providerBaseRoutes.dashboard.path}>
                  Cancel
                </Link>
              </Button>
            </Box>
          </Box>
        </form>
      </div>
    );
  }
}

UserDetails.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(styles)(UserDetails);
