import React from "react";
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import "./style.css";

const PlacesAutocomplete = ({ setAddress }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description, ...rest }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    console.log(description, rest);
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then(async (results) => {
      //debugger;
      const parameter = {
        // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
        placeId: results[0].place_id,
        // Specify the return data that you want (optional)
      };

      getDetails(parameter)
        .then((details) => {
          console.log("Details: ", details);
          const { lat, lng } = getLatLng(results[0]);
          setAddress({
            lat,
            lng,
            address: description,
            ...rest,
            ...details,
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div className="google-auto-complete" ref={ref}>
      <FormControl>
        <InputLabel className={"required-label"}>Drop off Address</InputLabel>
        <Input value={value} onChange={handleInput} disabled={!ready} />
      </FormControl>

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};
export default PlacesAutocomplete;
