/*!

All API path using in the application
*/

import {
  ADNETWORK_ID_SLUG,
  CAMPAIGN_ID_SLUG,
  ASSET_ID_SLUG,
  VENDOR_ID_SLUG,
  ADVERTISEMENT_ID_SLUG,
} from "__helpers/constants";

const liveApiEndPoint = "https://app.maxmrj.com:20443";
//const liveApiEndPoint = "http://localhost:30443";

export const apiPath = {
  basePath: `${liveApiEndPoint}`,
  basePathFull: "https://app.maxmrj.com",
  //basePathFull: "http://localhost/maxmrj",
  login: `${liveApiEndPoint}/api/auth/login`,
  getFacilities: `${liveApiEndPoint}/services/admin/api/facilities`,
  forgotPassword: `${liveApiEndPoint}/services/maxauth/api/account/reset-password/init`,
  resetPassword: `${liveApiEndPoint}/services/maxauth/api/account/reset-password/finish`,
  fetchProfile: `${liveApiEndPoint}/services/maxauth/api/account`,
  profileAccount: `${liveApiEndPoint}/services/maxauth/api/account`,
  postProfile: `${liveApiEndPoint}/services/admin/api/account`,
  profileChangePassword: `${liveApiEndPoint}/services/maxauth/api/account/change-password`,
  getDischargePlanners: `${liveApiEndPoint}/services/admin/api/discharge-planner`,
  getProviders: `${liveApiEndPoint}/services/admin/api/provider`,
  getProvidersFilter: `${liveApiEndPoint}/services/admin/api/providerFilter`,
  logoutApi: `${liveApiEndPoint}/api/auth/logout`,
  revokeApi: `${liveApiEndPoint}/services/externalpatients/api/three-leeged-revok`,

  // imported patients apis
  pccPatientsUpdate: `${liveApiEndPoint}/services/externalpatients/api/pcc-patients-autoupdate`,
  getApiPartnerList: `${liveApiEndPoint}/services/externalpatients/api/api-partner-list`,
  importPatientList: `${liveApiEndPoint}/services/externalpatients/api/pcc-patients`,
  assignedPatientList: `${liveApiEndPoint}/services/externalpatients/api/pcc-assigned-patients`,
  assignPatientToDash: `${liveApiEndPoint}/services/externalpatients/api/assign-pcc-patients`,
  unassignPatientToDash: `${liveApiEndPoint}/services/externalpatients/api/do-unassign-pcc-patients`,

  // push note
  getNoteType: `${liveApiEndPoint}/services/externalpatients/api/note-type-get`,
  pushNote: `${liveApiEndPoint}/services/externalpatients/api/push-notes-to-pcc`,
  get3LeggedValues: `${liveApiEndPoint}/services/externalpatients/api/three-leeged-require-value`,
  auth3LeggedLogin: `https://connect.pointclickcare.com/auth/login?`,
  auth3LeggedGetToken: `https://connect.pointclickcare.com/auth/token`,
  post3LeggedApi: `${liveApiEndPoint}/services/externalpatients/api/three-leeged-get-accesstoken`,

  // provider profile cate
  getProviderProfileCategory: `${liveApiEndPoint}/services/admin/api/provider-category-list`,
  getProviderSubscription: `${liveApiEndPoint}/services/admin/api/provider-subscription`,
  saveProviderProfileCategory: `${liveApiEndPoint}/services/admin/api/provider-category-admin`,

  // providerFacilityHospital: `${liveApiEndPoint}/services/admin/api/provider-facility-hospital`,
  providerFacilityHospital: `${liveApiEndPoint}/services/provider/api/provider-facility-hospital`,
  getActiveFacilities: `${liveApiEndPoint}/services/admin/api/active-facilities`,
  getProviderUsers: `${liveApiEndPoint}/services/admin/api/provideruser`,
  getActiveProviders: `${liveApiEndPoint}/services/admin/api/active-provider`,
  getInsurance: `${liveApiEndPoint}/services/admin/api/categories`,
  getCategory: `${liveApiEndPoint}/services/admin/api/insuranceList`,
  getHospitalList: `${liveApiEndPoint}/services/admin/api/previousHospitalList`,
  getPatientStatus: `${liveApiEndPoint}/services/admin/api/patientStatus`,
  getPatientLanNIns: `${liveApiEndPoint}/services/admin/api/languages-insurance`,

  getPatientList: `${liveApiEndPoint}/services/admin/api/patient`,
  createPatient: `${liveApiEndPoint}/services/admin/api/patient`,
  updatePatient: `${liveApiEndPoint}/services/admin/api/patient`,
  uploadPatientFiles: `${liveApiEndPoint}/services/admin/api/uploadFileMultipart`,
  uploadPatientFiles1: `${liveApiEndPoint}/services/admin/api/uploadFileMultipart1`,
  getPatientInfo: `${liveApiEndPoint}/services/admin/api/patient`,
  changePatientStatus: `${liveApiEndPoint}/services/dischargeplanner/api/patient-status`,
  deleteFaceSheeet: `${liveApiEndPoint}/services/admin/api/face-sheet`,
  providerDataUse: `${liveApiEndPoint}/services/admin/api/provider-data-usage`,
  referralReportDownload: `${liveApiEndPoint}/services/admin/api/download-admin-referral-reports`,
  getProviderInvoices: `${liveApiEndPoint}/services/admin/api/provider-invoices`,
  getProviderInvoicesByProviderId: `${liveApiEndPoint}/services/admin/api/provider-invoices-list`,

  providerCategory: `${liveApiEndPoint}/services/provider/api/provider-category`,
  findProvider: `${liveApiEndPoint}/services/provider/api/search`,

  awaitProvider: `${liveApiEndPoint}/services/provider/api/awaitSearch`,

  patientSearchHistory: `${liveApiEndPoint}/services/dischargeplanner/api/patient-search-history`,
  providerCriteria: `${liveApiEndPoint}/services/provider/api/provider-lan-insurance`,
  findAwaitingProvider: `${liveApiEndPoint}/services/dischargeplanner/api/sendrefferallist`,
  sendReferral: `${liveApiEndPoint}/services/dischargeplanner/api/sendrefferal`,
  updateReferral: `${liveApiEndPoint}/services/dischargeplanner/api/updateSendreferal`,
  sendProviderDetail: `${liveApiEndPoint}/services/dischargeplanner/api/sendmail`,
  changeProviderStatus: `${liveApiEndPoint}/services/dischargeplanner/api/update-ref-provider-status`,
  providerReferralList: `${liveApiEndPoint}/services/provider/api/referral-list`,
  getReferralDetail: `${liveApiEndPoint}/services/provider/api/referral-one`,
  rescindReferralDp: `${liveApiEndPoint}/services/dischargeplanner/api/resicnd-referrel`,
  providerAcceptReferral: `${liveApiEndPoint}/services/provider/api/referral-accepted`,
  providerDeclineReferral: `${liveApiEndPoint}/services/provider/api/referral-declined`,
  notificationList: `${liveApiEndPoint}/services/provider/api/notification-list`,
  removeReferralFromDashboard: `${liveApiEndPoint}/services/provider/api/remove-refferal-dashboard`,
  isNewNotification: `${liveApiEndPoint}/services/provider/api/is-new-notification`,
  readNotification: `${liveApiEndPoint}/services/provider/api/notification-update`,
  patientStatusDelete: `${liveApiEndPoint}/services/dischargeplanner/api/patient-status`,
  getPatientAddress: `${liveApiEndPoint}/services/dischargeplanner/api/planner-address`,
  providerPageStageList: `${liveApiEndPoint}/services/provider/api/provider-profile-status`,
  getProviderOtherAddressList: `${liveApiEndPoint}/services/provider/api/other-locations`,
  getProviderFilterList: `${liveApiEndPoint}/services/provider/api/provider-referral-filter-list`,

  // tasks
  patientTasks: `${liveApiEndPoint}/services/dischargeplanner/api/patient-task`,
  patientTasksBulk: `${liveApiEndPoint}/services/dischargeplanner/api/list-patient-task`,
  patientTasksList: `${liveApiEndPoint}/services/dischargeplanner/api/patient-task/patient`,

  postCommentByDP: `${liveApiEndPoint}/services/dischargeplanner/api/referral-comment`,
  getCommentToDP: `${liveApiEndPoint}/services/dischargeplanner/api/referral-comment`,
  getPatientTasks: `${liveApiEndPoint}/services/dischargeplanner/api/patient-task/patient`,
  postPrelimComment: `${liveApiEndPoint}/services/provider/api/prelim-comment`,

  patientFilters: `${liveApiEndPoint}/services/admin/api/patient-filter`,
  providerStatus: `${liveApiEndPoint}/services/admin/api/status-bar`,

  providerSaveCard: `${liveApiEndPoint}/services/provider/api/save-card`,
  providerSavedCard: `${liveApiEndPoint}/services/provider/api/saved-card`,
  providerCategoryUpdate: `${liveApiEndPoint}/services/provider/api/subscription`,
  providerSaveCardAndCategoryUpdate: `${liveApiEndPoint}/services/provider/api/save-card-and-category`,

  bannerInfo: `${liveApiEndPoint}/services/provider/api/banner-info`,
  paymentStatus: `${liveApiEndPoint}/services/provider/api/payment-status`,
  cmsServiceSave: `${liveApiEndPoint}/services/admin/api/term-condition`,
  multiSelectPatientCat: `${liveApiEndPoint}/services/admin/api/categories-list-by-patient`,

  bundleCreate: `${liveApiEndPoint}/services/provider/api/bundle-create`,

  downloadFiles: `${liveApiEndPoint}/services/admin/api/patient-doc`,
  downloadCommentFile: `${liveApiEndPoint}/services/admin/api/download`,
  adminProviderToken: `${liveApiEndPoint}/services/admin/api/admin-provider-token`,

  // notes
  patientNotes: `${liveApiEndPoint}/services/dischargeplanner/api/patient-notes`,
  getStateList: `${liveApiEndPoint}/services/admin/api/state-list`,
  saveOrganizationDetail: `${liveApiEndPoint}/services/provider/api/organization`,

  // reports
  downloadReport: `${liveApiEndPoint}/services/dischargeplanner/api/executive-summery`,
  downloadReportPdf: `${liveApiEndPoint}/services/dischargeplanner/api/executive-summery-pdf`,
  providerSignupLink: `${liveApiEndPoint}/services/dischargeplanner/api/provider-signup-link`,
  providersFromOtherNetwork: `${liveApiEndPoint}/services/dischargeplanner/api/out-network-provider-list`,
  sendLinkToOtherProviderDp: `${liveApiEndPoint}/services/dischargeplanner/api/send-link-to-add-dp-network`,
  acceptORDeclineInvitation: `${liveApiEndPoint}/services/provider/api/accept-declined-to-associat-facility`,
  fetchInvitationForProvider: `${liveApiEndPoint}/services/provider/api/fetch-facility-to-link`,

  //calendar APIs
  fetchCalendarData: `${liveApiEndPoint}/services/dischargeplanner/api/planner/calendar`,

  fetchPatientSearch: `${liveApiEndPoint}/services/dischargeplanner/api/patient/list`,

  // task templates
  postTasksTemplate: `${liveApiEndPoint}/services/dischargeplanner/api/patient-task-template`,
  getTaskTemplateByDP:`${liveApiEndPoint}/services/dischargeplanner/api/patient-task-template/dp`,
  getTaskTemplateByDPAll:`${liveApiEndPoint}/services/dischargeplanner/api/patient-task-template-all/dp`,
  getTaskTemplateByPT:`${liveApiEndPoint}/services/dischargeplanner/api/patient-task-template/patient`,
  getTaskTemplateById:`${liveApiEndPoint}/services/dischargeplanner/api/patient-task-template`,
  updateTaskTemplate:`${liveApiEndPoint}/services/dischargeplanner/api/patient-task-template`,
  deleteTaskTemplate:`${liveApiEndPoint}/services/dischargeplanner/api/patient-task-template`,
  TaskTemplatesBulk: `${liveApiEndPoint}/services/dischargeplanner/api/list-patient-task-template`,
  PatientTaskBulk: `${liveApiEndPoint}/services/dischargeplanner/api/bulk-patient-task`,
  deleteTaskTemplateEdit:`${liveApiEndPoint}/services/dischargeplanner/api/patient-task-template-delete`,
  TaskTemplatesBulk: `${liveApiEndPoint}/services/dischargeplanner/api/list-patient-task-template`,
  TaskTemplatesBulkEdit: `${liveApiEndPoint}/services/dischargeplanner/api/list-patient-task-template`,
  TaskTemplatesBulkUpdate: `${liveApiEndPoint}/services/dischargeplanner/api/list-patient-task-template`,
  StatusUpdate: `${liveApiEndPoint}/services/dischargeplanner/api/patient-task-template-status`,
  getPdfDownload:`${liveApiEndPoint}/services/dischargeplanner/api/task-list-pdf`,
  PatientTaskBulk:`${liveApiEndPoint}/services/dischargeplanner/api/bulk-patient-task`,
  GetGroupPatient: `${liveApiEndPoint}/services/dischargeplanner/api/group-patient-task/patient`,
  updateFaxFinalized: `${liveApiEndPoint}/services/dischargeplanner/api/update-fax-finalized`,

};
